const apiVersion = 'v1';

const getDemandResponseEventsConfiguration = async () => {
  try {
    const demandResponseConfigurationResponse = await window.axios.get(
      `/${apiVersion}/viewsupport/demand-response-events/configs`
    );
    /*
        const data = {
          sitesWithDemandResponseStrategies: [
            {
              159: 'The Works',
            },
            {
              25: '5959 Topanga Canyon',
            },
            {
              69: '4695 Chabot',
            },
          ],
          eventTypes: [
            {
              key: 'SIMPLE-level',
              name: 'Simple levels',
              units: 'None',
              description: 'Simple levels - 0,2,1,3',
            },
            {
              key: 'ELECTRICITY_PRICE-price',
              name: 'Price of electricity - price',
              units: 'currency/kWh',
              description: 'Cost of electricity in absolute terms',
            },
            {
              key: 'ELECTRICITY_PRICE-priceRelative',
              name: 'Price of electricity - relative price',
              units: 'currency/kWh',
              description: 'Delta change to the existing price of electricity',
            },
            {
              key: 'ELECTRICITY_PRICE-priceMultiplier',
              name: 'Price of electricity - price multiplier',
              units: 'None',
              description: 'Multiplier to the existing cost',
            },
          ],
        };
        */
    return demandResponseConfigurationResponse.data.data;
  } catch (error) {
    console.error('getDemandResponseEventsConfiguration', error);
    return Promise.reject(error);
  }
};

const createDemandResponseEvent = async (demandResponseEvent: unknown) => {
  try {
    console.debug('demandResponseEvent', demandResponseEvent);
    const demandResponseConfigurationResponse = await window.axios.post(
      `/${apiVersion}/viewsupport/demand-response-events`,
      demandResponseEvent
    );
    const { data } = demandResponseConfigurationResponse;
    return data;
  } catch (error) {
    console.error(
      'createDemandResponseEvent with data',
      demandResponseEvent,
      error
    );
    Promise.reject(error);
  }
};

export default {
  getDemandResponseEventsConfiguration,
  createDemandResponseEvent,
};
