import { markRaw } from 'vue';

import VAV138Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite107/VAV138Drawing.vue';
import VAV139Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite107/VAV139Drawing.vue';
import VAV140Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite107/VAV140Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      console.error(error);
    }
  }
};

const Suite107ControlsList = [
  {
    id: 0,
    name: 'VAV 1-39',
    controlId: 2153,
    component: markRaw(VAV139Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-39',
      },
    },
    style: {
      position: 'absolute',
      top: '13%',
      right: '24.5%',
      width: '54%',
      height: '54%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '35%',
      left: '45%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 1-40',
    controlId: 2154,
    component: markRaw(VAV140Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-40',
      },
    },
    style: {
      position: 'absolute',
      top: '23.4%',
      left: '-17.7%',
      width: '87%',
      height: '87%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '60%',
      left: '10%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 1-38',
    controlId: 2152,
    component: markRaw(VAV138Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-38',
      },
    },
    style: {
      position: 'absolute',
      top: '52.7%',
      right: '23.65%',
      width: '57.5%',
      height: '57.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '70%',
      right: '35%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default Suite107ControlsList;
