// These routes are old fashioned and will be replaced by the new route system.
// They should be nested hence a router-view in the parent component.

const SubMeterEgaugeIndexRoute = {
  path: '/monitors/egauge',
  name: 'SubMeterEgaugeIndex',
  component: () => import('@/modules/monitors/egauges/_components/Index'),
  meta: { permission: 'meters:read' },
};

const SubMeterEgaugeCreateRoute = {
  path: '/monitors/egauge/create/:id?',
  name: 'SubMeterEgaugeCreate',
  component: () => import('@/modules/monitors/egauges/_components/Create'),
  meta: { permission: 'meters:create' },
};

const SubMeterEgaugeEditRoute = {
  path: '/monitors/egauge/site/:siteId/edit/:egaugeId',
  name: 'SubMeterEgaugeEdit',
  component: () => import('@/modules/monitors/egauges/_components/Edit'),
  meta: { permission: 'meters:update' },
};

const SubMeterEgaugeDataRecoveryRoute = {
  path: '/monitors/egauge/datarecovery',
  name: 'SubMeterEgaugeDataRecovery',
  component: () =>
    import('@/modules/monitors/egauges/_components/EgaugeDataRecovery'),
  meta: { permission: 'meters:update' },
};

export default [
  SubMeterEgaugeIndexRoute,
  SubMeterEgaugeCreateRoute,
  SubMeterEgaugeEditRoute,
  SubMeterEgaugeDataRecoveryRoute,
];
