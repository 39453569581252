const apiVersion = 'v1';

const getAllUsers = async () => {
  try {
    const response = await window.axios.get(`/${apiVersion}/users/all`);
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getOrphanAuth0Users = async () => {
  try {
    const response = await window.axios.get(`/${apiVersion}/orphanusers`);
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteUser = async (auth0Id) => {
  try {
    const response = await window.axios.delete(`/${apiVersion}/users/delete/${auth0Id}`);
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteOrphanedAuth0User = async (auth0Id) => {
  try {
    const response = await window.axios.delete(`/${apiVersion}/orphanusers/delete/${auth0Id}`);
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const createUser = async (formData) => {
  try {
    const response = await window.axios.post(`/${apiVersion}/users/auth0`, formData);
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const inviteUser = async (formData) => {
  try {
    const response = await window.axios.post(`/${apiVersion}/users/invite`, formData);
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const reInviteUser = async (auth0Id) => {
  try {
    const response = await window.axios.post(`/${apiVersion}/users/reinvite`, { auth0Id });
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateUser = async (formData) => {
  try {
    const response = await window.axios.put(`/${apiVersion}/users/update`, formData);
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUser = async (auth0Id) => {
  try {
    const response = await window.axios.get(`/${apiVersion}/users/auth0/${auth0Id}`);
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const refreshAuth0RoleCache = async () => {
  try {
    const response = await window.axios.post(`/${apiVersion}/auth0roles/cache`, {});
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const unsubscribeUser = async (token) => {
  try {
    const response = await window.axios.post(`/${apiVersion}/reports/schedules/unsubscribe`, { token });
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const reSubscribeUser = async (token) => {
  try {
    const response = await window.axios.post(`/${apiVersion}/reports/schedules/resubscribe`, { token });
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getAllUsers,
  getOrphanAuth0Users,
  deleteUser,
  deleteOrphanedAuth0User,
  createUser,
  inviteUser,
  reInviteUser,
  updateUser,
  getUser,
  refreshAuth0RoleCache,
  unsubscribeUser,
  reSubscribeUser,
};
