<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg1"
    viewBox="532.61 398.95 138.51 35.96"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="m 532.60914,398.94945 0.25,32.65039 h 40.66992 v 2.99024 l 97.5918,0.32031 v -35.96094 h -32.58008 c -1.59497,8.51037 -9.04101,8.96094 -9.04101,8.96094 v -8.96094 h -20.07032 c -0.75,8.36501 -8.53906,8.96094 -8.53906,8.96094 v -8.96094 h -24.89062 c -1.15063,9.39498 -8.53907,8.96094 -8.53907,8.96094 v -8.96094 h -24.80078 c -0.81,8.9169 -8.9707,8.97071 -8.9707,8.97071 l 0.21484,-8.97071 z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
