export const getEditorSettingsForStrategy = async (siteId, strategyName) => {
  const settings = await window.axios.get(
    `/v1/strategy/site/${siteId}/${strategyName}/settings`
  );
  if (settings && settings.data) {
    return settings.data.data;
  } else {
    return null;
  }
};

export const getEditorSettingsForInput = async (siteId, inputType) => {
  const settings = await window.axios.get(
    `/v1/strategy-inputs/${siteId}/${inputType}/settings`
  );
  if (settings && settings.data) {
    return settings.data.data;
  } else {
    return null;
  }
};

export const getStrategy = async (siteId, strategyName) => {
  const strategy = await window.axios.get(
    `/v1/strategy/site/${siteId}/${strategyName}`
  );
  if (strategy && strategy.data) {
    return strategy.data.data;
  } else {
    return null;
  }
};

export const getStrategyById = async (siteId, strategyId) => {
  const strategy = await window.axios.get(
    `/v1/strategy/site/${siteId}/${strategyId}`
  );
  if (strategy && strategy.data) {
    return strategy.data.data;
  } else {
    return null;
  }
};

export const getStrategies = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/strategy/site/${siteId}`);
    let strategies = result.data.data;
    return strategies;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getInputTypes = async (siteId) => {
  const inputs = await window.axios.get(`/v1/strategy-inputs/site/${siteId}`);
  if (inputs && inputs.data) {
    return inputs.data.data;
  } else {
    return null;
  }
};

export const getInputList = async (siteId, inputType) => {
  const inputUrl = `/v1/strategy-inputs/${siteId}/${inputType}`;
  const inputs = await window.axios.get(inputUrl);
  /*
  const inputs = {data: {
    data: [
      {
        id: 768,
        deviceName: '4A3-CDNK-Ext Lights 01',
        friendlyName: 'Ext Lights 01',
        name: "Ext Lights 01 (Ext Lights 01)"
      },
      {
        id: 769,
        deviceName: '4A3-CDNK-Conf Suite Lights',
        friendlyName: 'Conf Suite Lights',
        name: "Conf Suite Lights (Ext Lights 01)"
      },
    ],
  }};
  //*/
  console.debug(`Got inputs from ${inputUrl}`, inputs);
  if (inputs && inputs.data) {
    return inputs.data.data;
  } else {
    return [];
  }
};

export const saveStrategy = async (
  siteId,
  strategyName,
  strategyDescription,
  inputTypeName,
  frequency,
  editorData
) => {
  let strategyPayload = {
    siteId: siteId,
    strategyName: strategyName,
    strategyDescription: strategyDescription,
    inputTypeName: inputTypeName,
    strategyFrequency: frequency,
    strategyContent: editorData,
  };
  try {
    await window.axios.post(
      `/v1/strategy/site/${siteId}/create`,
      strategyPayload
    );
    // TODO: read invalid data and display in the editor
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const updateStrategy = async (
  strategyId,
  siteId,
  strategyName,
  strategyDescription,
  inputTypeName,
  frequency,
  editorData
) => {
  let strategyPayload = {
    strategyId: strategyId,
    siteId: siteId,
    strategyName: strategyName,
    strategyDescription: strategyDescription,
    inputTypeName: inputTypeName,
    strategyFrequency: frequency,
    strategyContent: editorData,
  };
  try {
    await window.axios.put(`/v1/strategy/update`, strategyPayload);
    // TODO: read invalid data and display in the editor
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const activateStrategy = async (strategy) => {
  let strategyPayload = {
    name: strategy.name,
    siteId: strategy.siteId,
    isActive: strategy.isActive,
  };
  try {
    await window.axios.put(
      `/v1/strategy/${strategy.name}/activate`,
      strategyPayload
    );
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const deleteStrategy = async (strategy) => {
  let result = null;
  var config = {
    data: {
      siteId: strategy.siteId,
      name: strategy.name,
    },
  };
  try {
    result = await window.axios.delete(`/v1/strategy/delete`, config);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const validateRule = async (
  siteId,
  inputTypeName,
  strategyName,
  strategyDescription,
  frequency,
  editorData
) => {
  let strategyPayload = {
    inputTypeName: inputTypeName,
    strategyName: strategyName,
    strategyDescription: strategyDescription,
    strategyContent: editorData,
    strategyFrequency: frequency,
  };
  try {
    let result = await window.axios.post(
      `/v1/strategy/validate-rule`,
      strategyPayload
    );
    return result.data.data;
    // TODO: read invalid data and display in the editor
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getSites = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/strategy/site/sites');
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getTemplates = async (inputType) => {
  let result = null;
  try {
    result = await window.axios.get(
      `/v1/strategytemplate/for-input/${inputType}`
    );
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getTemplateSettings = async (siteId, templateId) => {
  let result = null;
  try {
    result = await window.axios.get(
      `/v1/strategy/site/${siteId}/strategytemplate/${templateId}/settings`
    );
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export default {
  getSites,
  saveStrategy,
  updateStrategy,
  activateStrategy,
  deleteStrategy,
  getInputTypes,
  getInputList,
  getStrategies,
  getStrategy,
  getStrategyById,
  getEditorSettingsForInput,
  getEditorSettingsForStrategy,
  validateRule,
  getTemplates,
  getTemplateSettings,
};
