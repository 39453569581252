<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 238 178"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      fill-opacity="0.3"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="M127 156H237V0.5H129H63.5V38H36.5V59.7324H37V102H10.5V131.255H1.5V168.5H0.5V177L3.5 177V176H11.5V170L19.5 167.5L29 163.5L40 157.5V153H127V156Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="handleClick"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
defineProps<{
  color?: string;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};

const handleClick = (event: MouseEvent) => {
  emits('click', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
