<template>
  <v-container grid-list-xl>
    <p class="text-h5 pt-3">Create Non-Routine Event</p>
    <Form ref="obs" v-slot="{}">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="nonRoutineEvent.name"
                label="Non-Routine Event Name"
                hint="Name for this Non-Routine Event"
                persistent-hint
                rules="required"
                ref="nameText"
              ></validated-text-field
            ></v-col>
            <v-col sm="6">
              <validated-select-field
                :items="nonRoutineEventTypes"
                item-value="id"
                item-title="name"
                rules="required"
                v-model="nonRoutineEvent.nreTypeId"
                label="Non-Routine Event Type"
                hint="What type of Non-Routine Event is this?"
                persistent-hint
              ></validated-select-field>
            </v-col>
          </v-row>
          <v-row
            ><v-col>
              <start-end-date-picker
                name="date range"
                rules="required|valid_date_range"
                v-model="dateRange"
                @change="handleSetDateRange"
                date-format="yyyy-MM-dd"
                ref="startEndDatePicker"
              ></start-end-date-picker> </v-col
          ></v-row>
          <v-row>
            <v-textarea
              rows="2"
              label="Comments"
              clearable
              clear-icon="mdi-close-circle"
              variant="outlined"
              auto-grow
              v-model="nonRoutineEvent.comment"
              class="pr-3 pl-3"
            >
            </v-textarea>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                :disabled="!isValidNonRoutineEvent"
                >Save</v-btn
              >
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-form></Form
    >
    <span class="px-4 text-caption text-primary">
      {{ $appOldVersion }}
    </span>
  </v-container>
</template>

<script>
import { Form } from 'vee-validate';
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker';
import ValidatedTextField from '@/components/Fields/ValidatedTextField';
import ValidatedSelectField from '@/components/Fields/ValidatedSelectField';
import api from '../_api';

export default {
  name: 'NonRoutineEventCreate',
  components: {
    'validated-text-field': ValidatedTextField,
    'validated-select-field': ValidatedSelectField,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    'start-end-date-picker': StartEndDatePicker,
  },

  data() {
    return {
      valid: true,
      siteId: null,
      nonRoutineEvent: {
        name: null,
        nonRoutineEventTypeId: null,
        startDateTime: null,
        endDateTime: null,
        comment: null,
      },
      nonRoutineEventTypes: [],
      selectedNonRoutineEventType: null,
      dateRange: {},
      isValidNonRoutineEvent: true,
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    this.dateRange = {
      startDateTime: new Date().toISOString().substring(0, 10),
      endDateTime: new Date().toISOString().substring(0, 10),
    };
    try {
      await this.getLookups(this.siteId);
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }
      this.nonRoutineEvent.siteId = this.siteId;

      await api.createNonRoutineEvent(this.nonRoutineEvent);
      this.$toast.show('Non-routine Event created', null, 'success');
      this.$router.push({ name: 'NonRoutineEvents' });
    },

    async getLookups() {
      this.nonRoutineEventTypes = await api.getNonRoutineEventTypes();
    },

    handleSetDateRange(dateRange) {
      this.nonRoutineEvent.startDateTime = dateRange.startDateTime;
      this.nonRoutineEvent.endDateTime = dateRange.endDateTime;

      this.dateRange = {
        startDateTime: dateRange.startDateTime,
        endDateTime: dateRange.endDateTime,
      };
    },

    handleCancelCrud() {
      this.$router.push({ name: 'NonRoutineEvents' });
    },
  },
};
</script>
