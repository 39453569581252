import { markRaw } from 'vue';

import VAV118Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite100/VAV118Drawing.vue';
import VAV119Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite100/VAV119Drawing.vue';
import VAV120Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite100/VAV120Drawing.vue';
import VAV121Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite100/VAV121Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      console.error(error);
    }
  }
};

const Suite100ControlsList = [
  {
    id: 0,
    name: 'VAV 1-14',
    controlId: 2146,
    component: markRaw(VAV121Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-14',
      },
    },
    style: {
      position: 'absolute',
      top: '-0.5%',
      right: '16.18%',
      width: '71.2%',
      height: '72%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '10%',
      left: '41.8%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 1-11',
    controlId: 2143,
    component: markRaw(VAV118Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-11',
      },
    },
    style: {
      position: 'absolute',
      top: '8.8%',
      right: '19.8%',
      width: '75.48%',
      height: '75.48%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '40%',
      left: '39%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 1-12',
    controlId: 2144,
    component: markRaw(VAV119Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-12',
      },
    },
    style: {
      position: 'absolute',
      top: '56.5%',
      left: '-3.6%',
      width: '38.2%',
      height: '38.2%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '70%',
      left: '7%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 1-13',
    controlId: 2145,
    component: markRaw(VAV120Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-13',
      },
    },
    style: {
      position: 'absolute',
      top: '52%',
      right: '16.7%',
      width: '48.5%',
      height: '48.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '75%',
      right: '39%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default Suite100ControlsList;
