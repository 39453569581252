<template>
  <v-card class="measure-bar">
    <!-- Select Site -->
    <v-app-bar elevation="1" class="px-13">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        Measures
      </v-app-bar-title>
      <v-spacer />
      <v-text-field
        class="mt-1 mr-5"
        v-model="searchTerm"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        bg-color="white"
        variant="outlined"
        density="compact"
        :placeholder="placeholder"
        :clearable="true"
        @click:clear="handleSearchInput"
        @update:modelValue="handleSearchInput"
      />

      <template v-slot:append>
        <!-- Add Button -->
        <v-btn
          v-if="userCanUpdate || userCanCreate || userCanDelete"
          class="rounded-2 mr-3"
          color="primary"
          variant="flat"
          size="large"
          @click="handleAddAnalyticsMeasure"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold"
          >
            {{ $route.meta.buttonLabel }}
          </div>
        </v-btn>
      </template>
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'MeasureBar',

  data() {
    return {
      searchTerm: '',
    };
  },

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Measure';
    },

    userCanCreate() {
      return this.$can('create', 'measures');
    },
    userCanDelete() {
      return this.$can('delete', 'measures');
    },
    userCanUpdate() {
      return this.$can('update', 'measures');
    },
  },

  methods: {
    ...mapMutations({
      setMeasure: 'AnalyticsStore/AnalyticsMeasuresStore/MEASURE_SEARCH_TERM',
    }),

    handleAddAnalyticsMeasure() {
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({ name: 'AnalyticsMandVMeasuresNew' });
    },

    handleSearchInput(value) {
      if (typeof value === 'object') {
        this.setMeasure('');
      } else {
        this.setMeasure(value);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.measure-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
