import NonRoutineEventsPortfolio from '@/Core.Service.Domain/Analytics/NonRoutineEvents/Views';
import NRECreateView from '@/modules/analytics/m_and_v/nonroutineevents/_components/Create';
import NREEditView from '@/modules/analytics/m_and_v/nonroutineevents/_components/Edit';
import NREEnergyAdjustmentChart from '@/modules/analytics/m_and_v/nonroutineevents/_components/EnergyAdjustmentChart';

const NonRoutineEventsRoute = {
  path: 'non-routine-events/all',
  name: 'NonRoutineEvents',
  component: NonRoutineEventsPortfolio,
  meta: {
    placeholder: 'Search for a Non-Routine Event',
    analyticsTypeName: 'AnalyticsNonRoutineEvent',
    label: 'Non-Routine Events',
    buttonLabel: 'New Non-Routine Event',
    toolTip: 'Non-Routine Event',
    permission: 'non-routine-events:create',
  },
};

const NRECreateRoute = {
  path: 'non-routine-events/create-nre/site/:siteId',
  name: 'NRECreate',
  component: NRECreateView,
  meta: { permission: 'non-routine-events:create' },
};

const NREEditRoute = {
  path: 'non-routine-events/edit-nre/:nonRoutineEventId',
  name: 'NREEdit',
  component: NREEditView,
  meta: { permission: 'non-routine-events:update' },
};

const NREEnergyAdjustmentChartRoute = {
  path: 'non-routine-events/energy-adjust-chart-route/nre/:nonRoutineEventId/version/:version',
  name: 'NREEnergyAdjustmentChart',
  component: NREEnergyAdjustmentChart,
  meta: { permission: 'non-routine-events:read' },
};

export {
  NonRoutineEventsRoute,
  NRECreateRoute,
  NREEditRoute,
  NREEnergyAdjustmentChartRoute,
};
