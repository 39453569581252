<template>
  <div>
    <StrategiesBar />
    <router-view />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import StrategiesBar from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategiesBar.vue';

import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

const store = useStore();

const isLoading = computed(() => {
  return store.getters[
    'StrategiesStore/StrategiesStore/selectedSiteStrategiesIsLoading'
  ];
});
</script>
