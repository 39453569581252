<template>
  <v-banner single-line transition="slide-y-transition">
    <v-icon color="warning" size="x-large" class="mr-2">
      mdi-map-marker-alert
    </v-icon>
    <span class="text-primary font-weight-bold text-h6">
      {{ message }}
    </span>
  </v-banner>
</template>

<script>
export default {
  name: 'BannerAlert',

  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>
