import Reports from '@/Core.Service.Domain/ViewSupport/Reports/Views';
import ReportDetails from '@/modules/reports/_components/Details';
import ReportFilterParams from '@/modules/reports/_components/Details';
import ScheduledReports from '@/Core.Service.Domain/ViewSupport/Reports/Views/ScheduledReports.vue';

import { h, resolveComponent } from 'vue';

const routesList = [];

const ReportFilterParamsRoute = {
  path: '/portfolio/reports/:reportName/site/:siteId',
  name: 'PortfolioReportRoute',
  component: ReportFilterParams,
};

const ReportControlsParamsRoute = {
  path: '/portfolio/reports/:reportName/site/:siteId/controlFriendlyName/:controlFriendlyName',
  name: 'ControlsReportRoute',
  component: ReportFilterParams,
};

const ReportsRoute = {
  path: '/reports',
  name: 'ReportsIndex',
  redirect: { name: 'Reports' },
  component: {
    render: () => h(resolveComponent('router-view')),
  },
  meta: { permission: 'reports:read' },
  children: [
    {
      path: 'reports-list',
      name: 'Reports',
      component: Reports,
      meta: { permission: 'reports:read' },
    },
    {
      path: 'scheduled-reports',
      name: 'ScheduledReports',
      component: {
        render: () => h(resolveComponent('router-view')),
      },
      redirect: { name: 'ScheduledReportsList' },
      meta: {
        buttonLabel: 'Create Scheduled Report',
      },
      children: [
        {
          path: 'list',
          name: 'ScheduledReportsList',
          component: ScheduledReports,
          meta: {
            permission: 'scheduled-reports:read',
            buttonLabel: 'Create Scheduled Report',
          },
        },
        {
          path: 'create',
          name: 'CreateScheduledReport',
          component: () =>
            import(
              '@/Core.Service.Domain/ViewSupport/Reports/Views/ScheduledReports/Create.vue'
            ),
          meta: {
            permission: 'scheduled-reports:read',
            buttonLabel: 'Create Scheduled Report',
          },
        },
        {
          path: 'edit/:id',
          name: 'EditScheduledReport',
          component: () =>
            import(
              '@/Core.Service.Domain/ViewSupport/Reports/Views/ScheduledReports/Edit.vue'
            ),
          meta: {
            permission: 'scheduled-reports:read',
            buttonLabel: 'Edit Scheduled Report',
          },
        },
      ],
    },
    {
      path: ':reportName',
      name: 'ReportDetailsRoute',
      component: ReportDetails,
    },
  ],
};

routesList.push(
  ReportsRoute,
  ReportFilterParamsRoute,
  ReportControlsParamsRoute
);

export default routesList;
