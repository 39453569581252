import { h, resolveComponent } from 'vue';
import MessagesCenterCommonView from '@/Core.Service.Domain/MessageCenter/MessageCenter.Common/Views/MessagesCenterCommonView.vue';
import MessageCenterCreateMessage from '@/Core.Service.Domain/MessageCenter/Management/Components/MessageCenterCreateMessage.vue';
import MessageCenterEditMessage from '@/Core.Service.Domain/MessageCenter/Management/Components/MessageCenterEditMessage.vue';

import MessageCenterUsers from '@/Core.Service.Domain/MessageCenter/Users/Views';

const MessagesCenterRoutes = [
  {
    path: 'message-center',
    name: 'MessageCenter',
    component: MessagesCenterCommonView,
    redirect: { name: 'MessageCenterCreate' },
    meta: {
      permission: 'message-center:read',
    },
    children: [
      {
        path: 'create',
        name: 'MessageCenterCreate',
        component: MessageCenterCreateMessage,
        meta: {
          permission: 'message-center:update',
        },
      },
      {
        path: 'edit-message/:id',
        name: 'MessageCenterEdit',
        component: MessageCenterEditMessage,
        meta: {
          permission: 'message-center:update',
        },
      },
      {
        path: 'my-messages',
        name: 'MessageCenterMyMessages',
        meta: {
          permission: 'message-center:read',
        },
        component: {
          render: () => h(resolveComponent('router-view')),
        },
      },
      {
        path: 'users',
        name: 'MessageCenterUsers',
        component: MessageCenterUsers,
        meta: {
          permission: 'message-center:read',
        },
      },
    ],
  },
];

export default MessagesCenterRoutes;
