import API from '@/Core.Service.Domain/ViewSupport/Portfolio/API';

const getPortfolioSites = async (context) => {
  try {
    const portfolioSites = await API.getPortfolioSites();
    context.commit('PORTFOLIO_SITES', portfolioSites);
  } catch (error) {
    Promise.reject(error);
  }
};

export default {
  getPortfolioSites,
};
