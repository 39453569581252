import AginovaMonitorsPortfolio from '@/Core.Service.Domain/Monitors/Aginova/Views';

import AginovaMonitorsList from '@/Core.Service.Domain/Monitors/Aginova/Components/AginovaMonitorsList.vue';

import EditAginovaMonitor from '@/Core.Service.Domain/Monitors/Aginova/Views/EditAginovaMonitor';

// Old Aginova Login routes. These views should be refactored to be part of the Aginova Domain.
const AginovaLoginIndexRoute = {
  path: 'aginova-login',
  name: 'AginovaLoginIndex',
  component: () =>
    import('@/modules/administration/aginova_login/_components/Index'),
  meta: { permission: 'meters:read' },
};

const AginovaLoginCreateRoute = {
  path: 'aginova-login/create',
  name: 'AginovaLoginCreate',
  component: () =>
    import('@/modules/administration/aginova_login/_components/Create'),
  meta: { permission: 'meters:create' },
};

const AginovaLoginEditRoute = {
  path: 'aginova-login/:aginovaLoginId/edit',
  name: 'AginovaLoginEdit',
  component: () =>
    import('@/modules/administration/aginova_login/_components/Edit'),
  meta: { permission: 'meters:update' },
};
// End of Aginova Login routes.

const EditAginovaMonitorRoute = {
  path: 'edit-Aginova-monitor/:id',
  name: 'EditAginovaMonitor',
  component: EditAginovaMonitor,
  meta: {
    monitorsTypeName: 'TitleBar',
    label: 'Edit Aginova Monitor',
    icon: 'mdi-alpha-a-box',
    toolTip: 'Edit Aginova Monitor',
    permission: 'meters:read',
  },
};

const AginovaMonitorsListRoute = {
  path: 'list-by-site',
  name: 'AginovaMonitorsList',
  component: AginovaMonitorsList,
  meta: {
    placeholder: 'Search for a Monitor',
    monitorsTypeName: 'AginovaMonitors',
    label: 'Aginova Monitors',
    toolTip: 'Aginova Monitor',
    permission: 'meters:read',
  },
};

const AginovaMonitorsRoute = {
  path: 'Aginova',
  name: 'AginovaMonitors',
  component: AginovaMonitorsPortfolio,
  children: [AginovaMonitorsListRoute, EditAginovaMonitorRoute],
};

const AginovaDataRecoveryRoute = {
  path: 'aginova-datarecovery',
  name: 'AginovaDataRecovery',
  component: () =>
    import(
      '@/Core.Service.Domain/Monitors/Aginova/Components/AginovaDataRecovery.vue'
    ),
  meta: { permission: 'meters:update' },
};

export { AginovaMonitorsRoute };

export {
  AginovaLoginIndexRoute,
  AginovaLoginCreateRoute,
  AginovaLoginEditRoute,
  AginovaDataRecoveryRoute,
};
