<template>
  <v-container fluid class="ml-n3">
    <BannerAlertVue
      v-if="!ROIPredictionsList.length"
      :message="bannerMessage"
    />
    <v-data-table
      v-else
      :headers="headers"
      :items="ROIPredictionsList"
      :items-per-page="10"
      :header-props="headerProps"
      class="elevation-1"
      no-data-text="No measures found"
    >
      <!--Headers slot-->
      <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="column.class"
            @click="() => toggleSort(column)"
          >
            {{ column.title }}
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)" size="sm" />
            </template>
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <!--Last Modified slot-->
          <td>
            <span class="text-primary text-subtitle-1 text-sm-subtitle-1">
              {{
                dateTimeToUSFormatFactory(item.lastModifiedDateTime, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </span>
          </td>
          <!--Comment slot-->
          <td>
            <span
              class="text-primary text-subtitle-1 text-sm-subtitle-1 cut-text"
            >
              {{ item.roiPredictionComment }}
            </span>
          </td>
          <!--Unit of Measure slot-->
          <td>
            <v-tooltip location="right">
              <template v-slot:activator="{ props: activatorProps }">
                <v-icon
                  :color="getUnitOfMeasure(item).color"
                  v-bind="activatorProps"
                  style="cursor: pointer"
                >
                  {{ getUnitOfMeasure(item).icon }}
                </v-icon>
              </template>
              <span>
                {{ getUnitOfMeasure(item).unit }}
              </span>
            </v-tooltip>
          </td>
          <!--Actions buttons slot-->
          <td>
            <ActionButtonsVue
              :buttonsList="actionButtons"
              :outlined="true"
              :item="item"
              class="ml-n2"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';

import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';

export default {
  name: 'ROIPredictionsList',

  components: {
    ActionButtonsVue,
    BannerAlertVue,
  },

  computed: {
    ...mapGetters({
      ROIPredictionsList: 'SitesStore/ROIPredictionsStore/roiPredictionsBySite',
    }),

    bannerMessage() {
      return `No ROI Predictions exists for this site`;
    },
  },

  data(instance) {
    return {
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          title: 'Last Modified Date',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'lastModifiedDateTime',
          sortable: true,
        },
        {
          title: 'Comments',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'roiPredictionComment',
          sortable: true,
        },
        {
          title: 'Unit of Measure',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'unitOfMeasure',
          sortable: true,
        },
        {
          title: 'Summary',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'id',
          sortable: true,
        },
      ],
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },
      filteredItems: [],
      actionButtons: [
        {
          icon: 'chart-donut',
          toolTip: `ROI Prediction's Summary`,
          action: async (item) => {
            const { siteId } = this.$route.params;
            const summaryId = item.id;

            try {
              instance.itemIsLoading = item.id;
              await instance.getROIPredictionBySiteBySummaryId({
                summaryId,
              });
              instance.itemIsLoading = null;
              this.$router.push({
                name: 'ROIPredictionSummary',
                params: {
                  siteId,
                  summaryId,
                },
              });
            } catch (error) {
              console.error(error);
            }
          },
          loading: (item) => item.id === instance.itemIsLoading,
          disabled: (item) =>
            !item.hasPrediction || instance.itemIsLoading !== null,
        },
      ],
      itemIsLoading: null,
    };
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['measureCode', 'measureName', 'measureType'],
        this.ROIPredictionsList
      );
    },
  },

  methods: {
    ...mapActions({
      getROIPredictionBySiteBySummaryId:
        'SitesStore/ROIPredictionsStore/getROIPredictionBySiteBySummaryId',
    }),
    dateTimeToUSFormatFactory,

    getUnitOfMeasure(item) {
      const khwRegex = /kwh/i;
      const thermsRegex = /therm/i;

      if (khwRegex.test(item.unitOfMeasure)) {
        return {
          unit: 'kWh',
          icon: 'mdi-flash',
          color: 'intechBlue',
        };
      } else if (thermsRegex.test(item.unitOfMeasure)) {
        return {
          unit: 'Therms',
          icon: 'mdi-fire',
          color: 'heatColor',
        };
      }
    },
  },
};
</script>
