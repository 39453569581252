<template>
  <validation-observer ref="obs" v-slot="{ invalid }">
    <v-form @submit.prevent="submit">
      <v-container fluid>
        <v-card class="pa-4 rounded-2">
          <v-row>
            <v-col sm="6">
              <v-select
                :items="inputTypes"
                item-value="inputKey"
                item-title="typeName"
                :rules="[(v) => !!v || 'Input type is required']"
                v-model="selectedInput"
                label="Strategy Trigger"
                hint="What input type should trigger this strategy?"
                @update:modelValue="handleSelectInputType"
                persistent-hint
                return-object
                variant="outlined"
                density="compact"
              />
            </v-col>
            <v-col sm="6">
              <v-select
                :items="frequencies"
                item-value="id"
                item-title="text"
                :rules="[(v) => !!v || 'Execute frequency is required']"
                v-model="selectedFrequency"
                label="Execution Frequency"
                hint="How often should the rule be executed?"
                persistent-hint
                variant="outlined"
                density="compact"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-text-field
                autofocus
                v-model="strategyName"
                label="Strategy Name"
                :rules="[(v) => !!v || 'Strategy Name is required']"
                required
                variant="outlined"
                density="compact"
              />
            </v-col>
            <v-col sm="6">
              <v-text-field
                v-model="strategyDescription"
                label="Strategy Description"
                :rules="[(v) => !!v || 'Strategy Description is required']"
                variant="outlined"
                density="compact"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-select
                :items="templates"
                item-value="id"
                item-title="name"
                v-model="selectedTemplate"
                label="Existing Templates"
                hint="Optional. Loads an existing template into the editor; otherwise start from scratch"
                persistent-hint
                return-object
                variant="outlined"
                density="compact"
              />
            </v-col>
            <v-col sm="2">
              <v-btn
                color="secondary"
                @click="loadTemplate"
                :disabled="!selectedTemplate || selectedTemplate.id == 0"
              >
                Load
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-card border>
                <v-card-text
                  id="divRuleEditor"
                  ref="divRuleEditor"
                ></v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card border>
                <v-expansion-panels v-if="selectedInput" flat>
                  <v-expansion-panel>
                    <v-expansion-panel-title>
                      <p class="text-h6 text-primary">
                        <v-icon color="primary"
                          >mdi-beaker-alert-outline</v-icon
                        >
                        Click here to test your strategy with different input
                        values. This will not save or affect the actual
                        strategy.
                      </p>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <v-row>
                        <v-card
                          v-if="isComponentNameValid"
                          class="mx-3"
                          width="100%"
                          flat
                        >
                          <component
                            :is="inputName"
                            :InputData="device"
                            :DataSources="dataSources"
                            @update:model="handleDeviceData"
                          />
                        </v-card>
                        <v-card v-else class="mx-3" width="100%">
                          <v-card-text>
                            <span class="text-subtitle-1">
                              Unknown Input Name: {{ inputName }}
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-row>

                      <v-row v-if="isComponentNameValid">
                        <v-col cols="12">
                          <span class="text-subtitle-1 text-primary">
                            Enter input values abovee, then click "Evaluate
                            Strategy" to see what would happen during execution.
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-btn @click="evaluateStrategy">
                            Evaluate Strategy
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                :disabled="invalid || ruleInvalid"
                class="mr-5"
              >
                Save
              </v-btn>

              <v-btn color="primary" @click="validateRule">Validate</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-form>
    <StepperOverlay
      :showOverlay="showOverlay"
      :replayMessages="messages"
      :isLoading="isLoading"
      :stepperTitle="`Evaluating the strategy`"
      @close="showOverlay = false"
    />
    <span class="px-4 text-caption text-primary">
      {{ $appOldVersion }}
    </span>
  </validation-observer>
</template>

<style lang="scss">
div.ceRule {
  min-height: 20vh;
}
</style>

<script>
import api from './_api';
import API from '@/Core.Service.Domain/Strategies/Strategies/API';
import { Form } from 'vee-validate';

import StepperOverlay from '@/Core.Service.Domain/Strategies/Strategies/Components/StepperOverlay.vue';
import InputIaqVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/IndoorAirQuality.vue';
import InputFiveMinutesTotalEnergyWithWeatherVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/FiveMinutesTotalEnergyWithWeather.vue';
import InputPowerControlVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/PowerControl.vue';
import InputPricingDemandManagementVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/PricingDemandManagement.vue';
import InputSolarPredictionVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/SolarPrediction.vue';
import InputThermostatVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/Thermostat.vue';
import InputDemandResponseV1Vue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/DemandResponseV1.vue';
import InputEnergyEndUsePowerV3Vue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/EnergyEndUsePowerV3.vue';
import DeviceStatusV1 from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/DeviceStatusV1.vue';

import { capitalizeKeys } from '@/Core.Patterns/Adapter';

export default {
  name: 'CreateStrategyView',
  components: {
    'validation-observer': Form,
    StepperOverlay,
    IndoorAirQuality: InputIaqVue,
    FiveMinutesTotalEnergyWithWeather:
      InputFiveMinutesTotalEnergyWithWeatherVue,
    PowerControl: InputPowerControlVue,
    PricingDemandManagement: InputPricingDemandManagementVue,
    SolarPrediction: InputSolarPredictionVue,
    Thermostat: InputThermostatVue,
    DemandResponseV1: InputDemandResponseV1Vue,
    EnergyEndUsePowerV3: InputEnergyEndUsePowerV3Vue,
    DeviceStatusV1,
  },
  computed: {
    inputName() {
      if (this.selectedInput) {
        const typeParts = this.selectedInput.inputKey.split('.');
        return typeParts[typeParts.length - 1];
      }
      return 'Not yet selected';
    },
    isComponentNameValid() {
      const componentNames = [
        'IndoorAirQuality',
        'FiveMinutesTotalEnergyWithWeather',
        'PowerControl',
        'PricingDemandManagement',
        'SolarPrediction',
        'Thermostat',
        'DemandResponseV1',
        'EnergyEndUsePowerV3',
        'DeviceStatusV1',
      ];
      return componentNames.includes(this.inputName);
    },
  },
  async created() {
    //load static codeeffects assets
    let codeEffectsScript = document.createElement('script');
    codeEffectsScript.setAttribute('src', '/js/codeeffects.editor.js');
    document.head.appendChild(codeEffectsScript);

    let cssLink1 = document.createElement('link');
    cssLink1.setAttribute('rel', 'stylesheet');
    cssLink1.setAttribute('href', '/js/codeeffects.common.e360.custom.css');
    document.head.appendChild(cssLink1);

    let cssLink2 = document.createElement('link');
    cssLink2.setAttribute('rel', 'stylesheet');
    cssLink2.setAttribute('href', '/js/codeeffects.gray.e360.custom.css');
    document.head.appendChild(cssLink2);

    this.siteId = this.$route.params.siteId;
    this.inputTypes = await api.getInputTypes(this.siteId);
  },

  data() {
    return {
      ruleInvalid: true,
      siteId: 0,
      inputTypes: [],
      ruleEditor: null,
      strategyName: '',
      frequencies: [
        { id: 'EveryFiveMinutes', text: 'Every 5 minutes' },
        { id: 'EveryTenMinutes', text: 'Every 10 minutes' },
        { id: 'EveryFifteenMinutes', text: 'Every 15 minutes' },
        { id: 'EveryThirtyMinutes', text: 'Every 30 minutes' },
        { id: 'EveryHour', text: 'Every hour' },
        { id: 'EveryDay', text: 'Every day' },
        { id: 'OnDemand', text: 'On Demand / External Trigger' },
      ],
      selectedFrequency: 'EveryFifteenMinutes',
      strategyDescription: '',
      selectedInput: null,
      templates: [
        {
          id: 0,
          name: 'Please select a Strategy Trigger first',
          inputType: null,
        },
      ],
      selectedTemplate: null,
      device: {},
      deviceData: {},
      dataSources: [],
      showOverlay: false,
      isLoading: true,
      messages: [],
    };
  },

  methods: {
    async submit() {
      // TODO: validation
      let valid = await this.$refs.obs.validate();
      if (!valid) {
        return;
      }
      let ruleContent = this.ruleEditor.extract();
      await api.saveStrategy(
        this.siteId,
        this.strategyName,
        this.strategyDescription,
        this.selectedInput.inputKey,
        this.selectedFrequency,
        ruleContent
      );
      this.$toast.show('Successfully created your strategy.', null, 'success');
      this.$router.push({ name: 'StrategiesList' });
    },

    async validateRule() {
      let valid = await this.$refs.obs.validate();
      if (!valid) {
        return;
      }
      let ruleContent = this.ruleEditor.extract();
      let validationResult = await api.validateRule(
        this.siteId,
        this.selectedInput.inputKey,
        this.strategyName,
        this.strategyDescription,
        this.selectedFrequency,
        ruleContent
      );

      console.log(validationResult);
      if (validationResult.empty) {
        this.$toast.show('Rule cannot be empty.', null, 'error');
        this.ruleInvalid = true;
        return;
      }

      if (!validationResult.valid) {
        this.ruleEditor.loadInvalids(validationResult.clientInvalidData);
        this.ruleInvalid = true;
      } else {
        this.$toast.show('Rule is valid and can be saved.', null, 'success');
        this.ruleInvalid = false;
      }
    },

    async handleSelectInputType(input) {
      // loads templates - don't wait for it so we can load multiple things in parallel
      api.getTemplates(input.inputKey).then((data) => {
        this.templates = data;
        this.selectedTemplate = null;
      });

      this.editorSettings = await api.getEditorSettingsForInput(
        this.siteId,
        input.inputKey
      );
      this.loadEditorSettings();

      // loads the inputs available for this type
      const inputs = await api.getInputList(this.siteId, input.inputKey);
      const dataSources = this.editorSettings.dataSources;
      if (inputs.length > 0) {
        dataSources.push({ Key: '_selfList', Value: inputs });
      }
      this.dataSources = capitalizeKeys(dataSources);
    },

    handleCancelCrud() {
      this.$router.push({ name: 'StrategiesList' });
    },

    async loadTemplate() {
      this.editorSettings = await api.getTemplateSettings(
        this.siteId,
        this.selectedTemplate.id
      );
      this.loadEditorSettings();
    },

    loadEditorSettings() {
      // there's a window.$rule, so we'll assign it to a local variable to make things easier
      const $rule = window['$rule'];
      //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
      this.ruleEditor = $rule.Context.getControl('divRuleEditor');
      if (this.ruleEditor != null) this.ruleEditor.dispose();
      $rule.Context.clear();

      //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
      this.ruleEditor = $rule.init(this.editorSettings.editorData);
      this.ruleEditor.clear();
      this.ruleEditor.loadSettings(this.editorSettings.sourceData);
      if (this.editorSettings.ruleData)
        this.ruleEditor.loadRule(this.editorSettings.ruleData);
    },
    async evaluateStrategy() {
      // const valid = await this.$refs.obs.validate();
      // if (!valid) {
      //   return;
      // }
      this.messages = [];
      try {
        const strategyId = 0;
        const ruleContent = this.ruleEditor.extract();
        this.showOverlay = true;
        this.isLoading = true;
        const response = await API.replayStrategy(strategyId, {
          ruleContent,
          inputType: this.selectedInput.inputKey,
          inputData: {
            ...this.deviceData,
            SiteId: this.siteId,
            StrategyName: this.strategyName,
          },
        });
        if (response.length == 0) {
          this.messages = [
            { message: 'No action taken -- likely no condition matched.' },
          ];
        } else {
          this.messages = response;
        }
      } catch (error) {
        console.error(`error evaluating strategy`, error);
        this.messages = [{ message: 'Error evaluating the strategy.' }];
      } finally {
        this.isLoading = false;
      }
    },

    handleDeviceData(data) {
      console.log(`handleDeviceData`, data);
      this.deviceData = { ...data };
    },
  },
};
</script>
<style>
.ceMenu {
  z-index: 9999 !important;
}

.v-expansion-panel {
  border-top: none !important;
}
</style>
