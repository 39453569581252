<template>
  <v-tooltip location="left">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
        variant="flat"
        v-bind="activatorProps"
        v-on:click="openMessagesCenter"
        color="primary"
        class="mr-15 rounded-2"
      >
        <v-badge
          v-if="messagesCount > 0"
          :content="messagesCount"
          :model-value="messagesCount > 0"
          location="top start"
          offset-x="-15"
          offset-y="2"
          color="accentOrange"
        >
          <v-icon start :class="{ bell: messagesCount > 0 }"> mdi-bell </v-icon>
        </v-badge>
        <span> Messages </span>
      </v-btn>
    </template>
    <span>
      You have {{ messagesCount }} unread message{{ getCountMessageInPlural }}
    </span>
  </v-tooltip>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const messagesCount = computed(
  () => store.getters['MessageCenterUsersStore/getUnreadMessagesCount']
);

const getCountMessageInPlural = computed(() =>
  messagesCount.value > 1 ? 's' : ''
);

const emits = defineEmits(['openMessagesCenter']);
const openMessagesCenter = () => {
  emits('openMessagesCenter');
  router.push({ name: 'MessageCenterMyMessages' });
};
</script>
<style lang="scss" scoped>
@mixin ring-animation($transform-prefix) {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.bell {
  display: flex;
  width: 40px;
  height: 40px;
  margin-left: -10px;
  margin-right: -3px;
  color: $aliceBlue;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  @include ring-animation(-webkit-transform);
}

@-moz-keyframes ring {
  @include ring-animation(-moz-transform);
}

@keyframes ring {
  @include ring-animation(transform);
}

.v-badge :deep(.v-badge__badge) {
  width: auto;
  letter-spacing: -1px;
  padding: 2px 5px;
  padding-left: 4px;
}

.v-icon {
  font-size: 1.3rem !important;
}

.v-btn :deep(.v-btn__content) {
  margin-top: -2px;
}
</style>
