import StrategiesPortfolio from '@/Core.Service.Domain/Strategies/Strategies/Views/StrategiesView.vue';
import StrategiesListVue from '@/Core.Service.Domain/Strategies/Strategies/Views/StrategiesListView.vue';
import CreateStrategy from '@/modules/strategies/Create.vue';
import EditStrategy from '@/modules/strategies/Edit.vue';
import StrategyExecutionHistory from '@/Core.Service.Domain/Strategies/Strategies/Views/StrategiesExecutionHistoryListView.vue';

// These are old views. They should be refactored to be part of the new Strategies Domain.
const StrategiesCreateRoute = {
  path: 'create-strategy/site/:siteId',
  name: 'StrategiesCreate',
  component: CreateStrategy,
  meta: { permission: 'strategies:create' },
};

const StrategiesEditRoute = {
  path: 'edit-strategy/:strategyId/site/:siteId',
  name: 'StrategiesEdit',
  component: EditStrategy,
  meta: { permission: 'strategies:update' },
};
// End of old views.

const StrategiesListRoute = {
  path: 'all',
  name: 'StrategiesList',
  component: StrategiesListVue,
  children: [
    {
      path: 'delete-strategy/:name',
      name: 'DeleteStrategy',
    },
  ],
  meta: {
    label: 'Strategies',
    toolTip: 'Strategy',
    permission: 'strategies:read',
  },
};

const StrategyExecutionHistoryRoute = {
  path: 'strategy-execution-history/:strategyId/:strategyName',
  name: 'StrategyExecutionHistory',
  component: StrategyExecutionHistory,
  meta: {
    label: 'Strategy Execution History',
    toolTip: 'Strategy Execution History',
    permission: 'strategies:read-audit-log',
  },
};

const StrategyExecutionHistoryReplayRoute = {
  path: 'edit-strategy-replay/:logId',
  name: 'StrategyExecutionHistoryReplay',
  component: () =>
    import(
      '@/Core.Service.Domain/Strategies/Strategies/Components/EditStrategyReplay'
    ),
  meta: { permission: 'strategies:create' },
};

export const StrategiesRoute = {
  path: 'strategies',
  name: 'Strategies',
  component: StrategiesPortfolio,
  children: [
    StrategiesListRoute,
    StrategiesCreateRoute,
    StrategiesEditRoute,
    StrategyExecutionHistoryRoute,
    StrategyExecutionHistoryReplayRoute,
  ],
  meta: {
    label: 'Strategies',
    toolTip: 'Strategy',
    permission: 'strategies:read',
  },
  redirect: { name: 'StrategiesList' },
};
