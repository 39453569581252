<template>
  <div>
    <v-row>
      <v-col
        v-for="powerControl in powerControlsList"
        :key="powerControl.controlId"
      >
        <PowerControlCardVue
          :key="powerControl.controlId"
          :PowerControlInfo="powerControl"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import PowerControlCardVue from '@/Core.Service.Domain/Controls/PowerControls/Components/PowerControlCard';

export default {
  name: 'PowerControlsList',

  components: {
    PowerControlCardVue,
  },

  props: {
    PowerControlsList: { type: Array },
  },

  computed: {
    ...mapGetters({
      searchTerm: 'ControlsStore/getControlSearchTerm',
    }),

    powerControlsList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems, 'controlInfo.friendlyName')
        : sortAlphabetically(
            this.PowerControlsList,
            'controlInfo.friendlyName'
          );
    },
  },

  data() {
    return {
      filteredItems: [],
    };
  },

  watch: {
    searchTerm() {
      const mappedItems = this.PowerControlsList.map((item) => {
        if (!item.controlInfo) return null;
        const mappedItem = item;
        mappedItem.friendlyName = item.controlInfo.friendlyName;
        mappedItem.name = item.controlInfo.name;
        mappedItem.siteLocationName = item.controlInfo.siteLocationName;
        return mappedItem;
      }).filter((item) => item);

      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['friendlyName', 'name', 'siteLocationName'],
        mappedItems
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-col {
  flex-grow: 0;
}
</style>
