<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg11829"
    viewBox="231.14 250.32 36.05 76.79"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
      id="interactive-group"
    >
      <path
        d="m 231.14,250.31984 v 26.50586 c 8.96511,1.36426 9.16016,8.96485 9.16016,8.96485 l -8.96485,0.19531 c 0,0 -0.12567,-0.15193 -0.19531,-0.23438 v 4.19336 c 0.0837,-0.0994 0.21484,-0.25781 0.21484,-0.25781 l 8.96485,0.19531 c 0,0 -0.1931,7.60059 -9.15821,8.96485 -0.007,10e-4 -0.0141,0.003 -0.0215,0.004 v 28.25586 l 36.05469,-0.19531 -0.38867,-76.5918 z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-group {
  transition: fill-opacity 0.2s;
}
#interactive-group:hover {
  fill-opacity: 0.5;
}
</style>
