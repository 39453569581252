<template>
  <v-container fluid>
    <v-row v-if="isLoading">
      <v-col>
        <ProgressLinearLoaderVue :isLoading="isLoading" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BannerAlertVue
          v-if="!utilityCompaniesList.length && !isLoading"
          :message="bannerMessage"
        />
        <v-row>
          <v-col>
            <UtilityCompaniesBar />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <UtilityCompaniesList />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import UtilityCompaniesBar from '@/Core.Service.Domain/Sites/UtilityCompanies/Components/UtilityCompaniesBar.vue';
import UtilityCompaniesList from '@/Core.Service.Domain/Sites/UtilityCompanies/Components/UtilityCompaniesList.vue';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

export default {
  name: 'UtitliyCompaniesPortfolio',

  components: {
    UtilityCompaniesBar,
    UtilityCompaniesList,
    ProgressLinearLoaderVue,
    BannerAlertVue,
  },

  computed: {
    ...mapGetters({
      utilityCompaniesList: 'SitesStore/UtilityCompaniesStore/utilityCompanies',
      isLoading: 'SitesStore/UtilityCompaniesStore/isUtilityCompaniesLoading',
      defaultSite: 'session/defaultSite',
    }),

    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },
  },

  created() {
    this.setAllUtilityCompanies();
    this.setUtilityCompanyTypes();
  },

  methods: {
    ...mapActions({
      setAllUtilityCompanies:
        'SitesStore/UtilityCompaniesStore/setAllUtilityCompanies',
      setUtilityCompanyTypes:
        'SitesStore/UtilityCompaniesStore/setUtilityCompanyTypes',
    }),
  },
};
</script>
