<template>
  <v-card width="100%" class="mt-2">
    <v-data-table
      :headers="headers"
      :items="notificationsList"
      :items-per-page="10"
      :search="searchTerm"
      :header-props="headerProps"
      class="elevation-1"
      no-data-text="No notifications found"
    >
      <!--Headers slot-->
      <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="column.class"
            @click="() => toggleSort(column)"
          >
            {{ column.title }}
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)" size="sm" />
            </template>
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr :style="`opacity:${item.isRead ? '0.5' : '1'}`">
          <!--Type slot-->
          <td>
            <v-tooltip location="right">
              <template v-slot:activator="{ props: activatorProps }">
                <v-icon
                  v-bind="activatorProps"
                  style="cursor: pointer"
                  :color="handleNotificationType(item).color"
                >
                  {{ handleNotificationType(item).icon }}
                </v-icon>
              </template>
              <span>
                Notification type: {{ handleNotificationType(item).type }}
              </span>
            </v-tooltip>
          </td>
          <!--Notification slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.notificationValue || '-' }}
            </span>
          </td>
          <!--Created At slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.createdDateTimeOffset || '-' }}
            </span>
          </td>
          <!--Read slot-->
          <td>
            <ActionButtonsVue
              :buttonsList="actionButtons"
              :item="item"
              :outlined="item.isRead"
              :color="'accentOrange'"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';

import API from '@/Core.Service.Domain/ViewSupport/Notifications/API';

import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

export default {
  name: 'NotificationsList',

  components: {
    ActionButtonsVue,
  },

  computed: {
    ...mapGetters({
      searchTerm:
        'ViewSupportStore/NotificationsStore/getNotificationsSearchTerm',
      defaultSite: 'session/defaultSite',
    }),
  },

  watch: {
    defaultSite() {
      this.handleLoadNotifications();
    },
  },

  created() {
    this.handleLoadNotifications();
    eventBus.$on('markAllAsRead', () => {
      this.handleMarkAllAsRead();
    });
  },

  data(instance) {
    return {
      search: '',
      notificationsList: [],
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          title: 'Type',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'notificationType',
          sortable: true,
        },
        {
          title: 'Notification',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'notificationValue',
          sortable: true,
        },
        {
          title: 'Created At',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'createdDateTimeOffset',
          sortable: true,
        },
        {
          title: 'Read/Unread',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'isRead',
          sortable: true,
        },
      ],
      actionButtons: [
        {
          icon: (item) =>
            item.isRead ? 'email-outline' : 'email-open-outline',
          toolTip: (item) => (item.isRead ? 'Mark as Unread' : 'Mark as Read'),
          action: async (item) => {
            instance.itemIsLoading = item.id;
            await instance.handleNotificationToggleRead(item);
            instance.itemIsLoading = null;
          },
          loading: (item) => item.id === instance.itemIsLoading,
        },
      ],
      itemIsLoading: null,
      isMarkAllLoading: false,
    };
  },

  methods: {
    ...mapActions({
      updateNotification:
        'ViewSupportStore/NotificationsStore/updateNotification',
    }),
    ...mapMutations({
      notificationsIsLoading:
        'ViewSupportStore/NotificationsStore/NOTIFICATIONS_IS_LOADING',
    }),

    async handleLoadNotifications() {
      try {
        this.notificationsIsLoading(true);
        const { siteId } = this.defaultSite;
        this.notificationsList = await API.getAllNotifications({
          siteId,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.notificationsIsLoading(false);
      }
    },

    handleNotificationType(notification) {
      const types = [
        {
          type: 'Email',
          color: 'greenGraph',
          icon: 'mdi-email-check-outline',
        },
        {
          type: 'SMS',
          color: 'purple',
          icon: 'mdi-message-alert-outline',
        },
        {
          type: 'PowerControlSettingNotification',
          color: 'intechBlue',
          icon: 'mdi-power-settings',
        },
        {
          type: 'ThermostatSetting',
          color: 'warning',
          icon: 'mdi-thermometer',
        },
      ];
      const type = types.find(
        (type) => type.type === notification.notificationType
      );
      return type || { type: 'Unknown', color: 'primary', icon: 'mdi-help' };
    },

    async handleNotificationToggleRead(notification) {
      try {
        notification.isRead = !notification.isRead;
        await this.updateNotification(notification);
        this.handleLoadNotifications();
      } catch (error) {
        console.error(error);
      }
    },

    async handleMarkAllAsRead() {
      eventBus.$emit('isMarkAllLoading', true);
      try {
        const { siteId } = this.defaultSite;
        await API.markAllNotificationsAsRead({
          siteId,
        });
        this.handleLoadNotifications();
      } catch (error) {
        console.error(error);
      } finally {
        eventBus.$emit('isMarkAllLoading', false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-table .v-table__wrapper > table > thead > tr > th {
  border: none !important;
}

.v-table {
  color: $primary !important;
}
</style>
