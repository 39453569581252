<template>
  <v-alert
    v-if="innerMessages.length > 0"
    text
    elevation="3"
    border="top"
    type="error"
    closable
    @update:modelValue="close"
  >
    <div v-for="(item, index) in innerMessages" v-bind:key="index">
      {{ item.message }} {{ item.Message }}
    </div>
  </v-alert>
</template>

<script>
export default {
  props: {
    messages: { type: [Array, Object], required: true },
  },
  data() {
    return {
      innerMessages: [],
    };
  },
  created() {
    if (this.messages) {
      this.innerMessages = this.messages;
    }
  },
  methods: {
    //this will reset the alert when close button clicked
    close() {
      this.innerMessages = [];
    },
  },
  watch: {
    messages(newVal) {
      if (newVal) {
        this.innerMessages = newVal;
      }
    },
  },
};
</script>
