<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="MeasuresList"
      :items-per-page="10"
      :search="searchTerm"
      :header-props="headerProps"
      class="elevation-1"
      no-data-text="No measures found"
    >
      <!--Headers slot-->
      <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="column.class"
            @click="() => toggleSort(column)"
          >
            {{ column.title }}
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)" size="sm" />
            </template>
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <!--Code slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.measureCode }}
            </span>
          </td>
          <!--Name slot-->
          <td>
            <span class="text-primary text-subtitle-1 cut-text">
              {{ item.measureName }}
            </span>
          </td>
          <!--Type slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.measureType }}
            </span>
          </td>
          <!--Actions buttons slot-->
          <td>
            <ActionButtonsVue
              :buttonsList="actionButtons"
              :outlined="true"
              :disabled="!userCanUpdate || !userCanCreate || !userCanDelete"
              :item="item"
              class="ml-n2"
            />
          </td>
          <!--Assigned to Project slot-->
          <td>
            <v-tooltip
              location="right"
              v-if="item.analtyicProjectResourceModels.length"
            >
              <template v-slot:activator="{ props }">
                <v-icon
                  color="primary"
                  v-bind="props"
                  style="cursor: pointer"
                  @click="
                    handleAssignedProject(
                      item.analtyicProjectResourceModels[0].id
                    )
                  "
                >
                  mdi-check-all
                </v-icon>
              </template>
              <span>
                Assigned to: {{ item.analtyicProjectResourceModels[0].name }}
              </span>
            </v-tooltip>
            <v-btn
              v-else
              :disabled="!userCanUpdate || !userCanCreate || !userCanDelete"
              variant="outlined"
              color="primary"
              @click="handleAssignProject"
            >
              Assign
              <v-icon end> mdi-chart-gantt </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteMeasure
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :measureInfo="MeasureInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';

import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import ConfirmDialogDeleteMeasure from '@/Core.Service.Domain/Analytics/Measures/Components/ConfirmDialogDeleteMeasure.vue';

export default {
  name: 'MeasuresList',

  props: {
    MeasuresList: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ActionButtonsVue,
    ConfirmDialogDeleteMeasure,
  },

  computed: {
    ...mapGetters({
      searchTerm: 'AnalyticsStore/AnalyticsMeasuresStore/getMeasureSearchTerm',
    }),

    userCanCreate() {
      return this.$can('create', 'measures');
    },
    userCanDelete() {
      return this.$can('delete', 'measures');
    },
    userCanUpdate() {
      return this.$can('update', 'measures');
    },
  },

  data(instance) {
    return {
      showConfirmDialog: false,
      MeasureInfo: {},
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          title: 'Code',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'measureCode',
          sortable: true,
        },
        {
          title: 'Name',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'measureName',
          sortable: true,
        },
        {
          title: 'Type',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'measureType',
          sortable: true,
        },
        {
          title: 'Assigned to Project',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'analtyicProjectResourceModels[0]',
          sortable: true,
        },
        {
          title: 'Actions',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'actions',
          sortable: false,
        },
      ],
      filteredItems: [],
      actionButtons: [
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          action: (item) => {
            if (
              !instance.userCanUpdate ||
              !instance.userCanCreate ||
              !instance.userCanDelete
            )
              return;
            instance.$router.push({
              name: 'AnalyticsMandVMeasuresDetails',
              params: {
                measuresId: item.id,
              },
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          disabled: (item) => item.analtyicProjectResourceModels.length > 0,
          action: (item) => {
            if (
              !instance.userCanUpdate ||
              !instance.userCanCreate ||
              !instance.userCanDelete ||
              item.analtyicProjectResourceModels.length > 0
            )
              return;
            instance.MeasureInfo = item;
            instance.showConfirmDialog = true;
          },
        },
      ],
    };
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['measureCode', 'measureName', 'measureType'],
        this.MeasuresList
      );
    },
  },

  methods: {
    replaceTab(text) {
      return text.replace(/\t/g, '');
    },

    handleAssignProject() {
      if (!this.userCanUpdate || !this.userCanCreate || !this.userCanDelete)
        return;
      this.$router.push({
        name: 'AnalyticsProjects',
      });
    },

    handleAssignedProject(id) {
      if (!this.userCanUpdate || !this.userCanCreate || !this.userCanDelete)
        return;
      this.$router.push({
        name: 'AnalyticsMandVProjectDetails',
        params: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-table .v-table__wrapper > table > thead > tr > th {
  border: none !important;
}

.v-table {
  color: $primary !important;
}
</style>
