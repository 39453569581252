<template>
  <v-container>
    <v-expansion-panels variant="accordion">
      <v-expansion-panel
        v-for="(accItem, i) in accordionItems"
        :key="i"
        :disabled="!modelHasLength(accItem)"
        elevation="0"
        class="rounded-2"
      >
        <v-expansion-panel-title>
          <span
            class="font-weight-bold text-primary"
            :class="modelHasLength(accItem) ? 'text-primary' : 'text-disabled'"
          >
            {{ accItem.label }}
          </span>
        </v-expansion-panel-title>
        <v-expansion-panel-text v-for="(m, j) in accItem.model" :key="j">
          <v-row>
            <v-col cols="6">
              <span class="text-primary text-body-2">
                {{ accItem.output(m, j).col1 }}
              </span>
            </v-col>
            <v-col cols="6">
              <span class="text-primary text-body-2">
                {{ accItem.output(m).col2 }}
              </span>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  name: 'PortfolioAccordion',

  props: {
    siteDetails: {
      type: Object,
      required: true,
    },
  },

  methods: {
    modelHasLength(accItem) {
      return accItem.model.length > 0;
    },
  },

  data(instance) {
    return {
      accordionItems: [
        {
          label: 'Hours of Operation',
          model: instance.siteDetails.startStopTimeResourceModels,
          output: (item) => ({
            col1: item.dayOfWeekName,
            col2: `${item.siteStartTime} - ${item.siteEndTime}`,
          }),
        },
        {
          label: 'Building Details',
          model: [
            instance.siteDetails.siteTypeName,
            instance.siteDetails.occupants,
            instance.siteDetails.year,
            instance.siteDetails.squareFootage,
            instance.siteDetails.floors,
          ],
          output: (item, i) => {
            const labels = [
              'Site Type',
              'Occupancy',
              'Year Build',
              'Square Footage',
              'Floors',
            ];
            return { col1: labels[i], col2: item };
          },
        },
        {
          label: 'Site Location',
          model: [
            instance.siteDetails.address,
            instance.siteDetails.latitude,
            instance.siteDetails.longitude,
            instance.siteDetails.weatherStationName,
          ],
          output: (item, i) => {
            const labels = [
              'Address',
              'Latitude',
              'Longitude',
              'Weather Station',
            ];
            return { col1: labels[i], col2: item };
          },
        },
        {
          label: 'Site Utility Companies',
          model: instance.siteDetails.utilityCompanyNames,
          output: (item) => ({ col1: item, col2: '' }),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.v-expansion-panel-text:nth-child(even) .v-row {
  background-color: $aliceBlue !important;
}
</style>
