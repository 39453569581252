<template>
  <v-card class="simple-title-bar">
    <v-app-bar elevation="1" class="px-13">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        <slot></slot>
      </v-app-bar-title>
    </v-app-bar>
  </v-card>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
.simple-title-bar {
  z-index: 1;
}
</style>
