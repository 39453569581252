<template>
  <v-card
    :id="`strategy_${StrategyInfo.id}`"
    class="rounded-2"
    height="100%"
    :width="cardWidth"
    elevation="24"
  >
    <!--Card Header-->
    <v-toolbar color="primary" height="88" flat>
      <v-container>
        <v-row>
          <v-col class="d-flex" cols="10">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-toolbar-title
                  class="text-aliceBlue font-weight-bold ml-1 text-h5 mt-2"
                  v-bind="props"
                >
                  <v-icon color="white" class="mt-n2" start size="small">
                    mdi-lightbulb-multiple-outline
                  </v-icon>
                  {{ StrategyInfo.name }}
                </v-toolbar-title>
              </template>
              <span>{{ StrategyInfo.name }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span
                  class="text-aliceBlue text-caption ml-3 mt-n4 cut-text"
                  v-bind="props"
                >
                  {{ StrategyInfo.description }}
                </span>
              </template>
              <span>{{ StrategyInfo.description }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <!--Card Body-->
    <StrategyTemplateCardItemsList :StrategyInfo="StrategyInfo" />
    <!--Card Footer-->
    <v-divider />
    <v-card-actions v-if="userCanUpdate">
      <ActionButtonsVue :buttonsList="actionButtons" />
    </v-card-actions>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteStrategyTemplate
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :strategyInfo="StrategyInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-card>
</template>

<script>
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import StrategyTemplateCardItemsList from '@/Core.Service.Domain/Strategies/StrategyTemplates/Components/StrategyTemplateCardItemsList';
import ConfirmDialogDeleteStrategyTemplate from '@/Core.Service.Domain/Strategies/StrategyTemplates/Components/ConfirmDialogDeleteStrategyTemplate.vue';

export default {
  name: 'StrategyTemplateCard',

  components: {
    ActionButtonsVue,
    StrategyTemplateCardItemsList,
    ConfirmDialogDeleteStrategyTemplate,
  },

  props: {
    StrategyInfo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    userCanUpdate() {
      return this.$can('update', 'controls');
    },
  },

  data(instance) {
    return {
      showConfirmDialog: false,
      cardWidth: 360,
      isDirty: {
        isActive: false,
      },

      StrategyStatus: {
        isActive: instance.StrategyInfo.isActive,
      },

      actionButtons: [
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          action: () => {
            // this route will be replaced when the new edit page is ready
            instance.$router.push({
              name: 'StrategyTemplatesEdit',
              params: {
                strategyTemplateId: instance.StrategyInfo.id,
              },
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          action: () => {
            instance.showConfirmDialog = true;
          },
        },
      ],
    };
  },
};
</script>
