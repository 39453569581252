import NotificationsView from '@/Core.Service.Domain/ViewSupport/Notifications/Views';

const routesList = [];

const NotificationsRoute = {
  path: '/portfolio/all-notifications/:siteId?',
  name: 'AllNotifications',
  component: NotificationsView,
  meta: { permission: 'portfolios:read' },
};

routesList.push(NotificationsRoute);

export default routesList;
