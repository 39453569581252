<template>
  <v-card class="rounded-2" height="100%" :width="cardWidth" elevation="24">
    <!--Card Header-->
    <v-toolbar color="primary" height="88" flat>
      <v-container>
        <v-row>
          <v-col class="d-flex" cols="10">
            <v-tooltip location="right">
              <template v-slot:activator="{ props }">
                <v-toolbar-title
                  class="text-aliceBlue font-weight-bold ml-1 text-h5 mt-2"
                  v-bind="props"
                >
                  <v-icon color="white" class="mt-n2" start size="small">
                    mdi-chart-gantt
                  </v-icon>
                  {{ ProjectInfo.projectName }}
                </v-toolbar-title>
              </template>
              <span>{{ ProjectInfo.projectName }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span
                  class="text-aliceBlue text-caption ml-3 mt-n4 cut-text"
                  v-bind="props"
                >
                  {{ ProjectInfo.siteName }}
                </span>
              </template>
              <span>{{ ProjectInfo.siteName }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <!--Card Body-->
    <ProjectCardItemsList :projectInfo="ProjectInfo" class="ml-n2" />
    <!--Card Footer-->
    <v-divider />
    <v-card-actions>
      <ActionButtonsVue :buttonsList="actionButtons" />
    </v-card-actions>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteProject
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :projectInfo="ProjectInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-card>
</template>

<script>
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import ProjectCardItemsList from '@/Core.Service.Domain/Analytics/Projects/Components/ProjectCardItemsList';

import ConfirmDialogDeleteProject from '@/Core.Service.Domain/Analytics/Projects/Components/ConfirmDialogDeleteProject';

export default {
  name: 'ProjectCard',

  components: {
    ActionButtonsVue,
    ProjectCardItemsList,
    ConfirmDialogDeleteProject,
  },

  props: {
    ProjectInfo: {
      type: Object,
      required: true,
    },
  },

  data(instance) {
    return {
      showConfirmDialog: false,
      cardWidth: 360,
      actionButtons: [
        {
          icon: 'finance',
          toolTip: `See Analytics for this Project`,
          action: () => {
            // this route will be replaced when the new edit page is ready
            const { id } = instance.ProjectInfo;
            instance.$router.push({
              name: 'AnalyticsProjectImpactReport',
              params: {
                id,
              },
            });
          },
        },
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          action: () => {
            // this route will be replaced when the new edit page is ready
            const { id } = instance.ProjectInfo;
            instance.$router.push({
              name: 'AnalyticsMandVProjectDetails',
              params: {
                id,
              },
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          action: () => {
            instance.showConfirmDialog = true;
          },
        },
      ],
    };
  },
};
</script>
