export const isErrorMessage = (message: { messageType: string }) => {
  return message.messageType === 'Error';
};

export const isNumberGreaterThanZero = (value: number) => {
  return isNumber(value) && value > 0;
};

export const isNumber = (value: number) => {
  return !isNaN(value) && hasValue(value);
};

export const hasValue = (value: string | number | Date | null | undefined) => {
  return value !== '' && value !== null && value !== undefined;
};

export const isDate = (value: string | number | Date) => {
  // Check if the value is in the "HH:mm" format (e.g., "20:00")
  const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
  if (timePattern.test(value as string)) {
    // Create a Date object with today's date and the given time
    const [hours, minutes] = (value as string).split(':');
    const today = new Date();
    today.setHours(parseInt(hours), parseInt(minutes), 0, 0); // Set hours and minutes to today's date
    return !isNaN(today.getTime()); // Check if the date object is valid
  }

  // For regular date inputs, use the original logic
  const date = new Date(value);
  return date instanceof Date && !isNaN(date.getTime()) && hasValue(value);
};

export const isValid24HourFormat = (value: string) => {
  const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return regex.test(value) && hasValue(value);
};
