<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg11829"
    viewBox="672.23 283.09 86.89 151.95"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="m 672.3607,283.22023 v 151.68946 l 51.34961,-0.32031 v -29.7793 h -0.54492 l -0.54492,-1.25 H 721.265 c 0.80879,0.70324 1.35547,1.25 1.35547,1.25 v 8.9707 c 0,0 -8.16591,1.09802 -9.07031,-8.9707 -0.0412,-0.45816 -0.0605,-0.87434 -0.0586,-1.25195 l -21.88476,-0.008 -0.12696,-48.48047 h 23.34961 v -11.65429 c -0.31182,0.0471 -0.65876,0.0729 -1.04883,0.0742 -7.45583,0.0246 -9.08203,-8.01758 -9.08203,-8.01758 0,-0.68906 9.08203,-0.90235 9.08203,-0.90235 0,0 0.46624,-0.83262 1.04938,3.6e-4 l -5.5e-4,-2.27965 h -3.10351 l 0.15429,-17.16992 4.59961,-0.0605 c 0.004,-0.0441 -1.9e-4,-0.0869 0.01,-0.13086 2.28251,-10.06872 9.07031,-8.96875 9.07031,-8.96875 v 8.96875 l -0.01,0.01 0.67578,-0.01 0.35351,-7.83008 h 32.41992 v -23.87891 z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
