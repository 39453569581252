<template>
  <div>
    <BannerAlertVue
      v-if="!selectedSiteAnalyticsModels.length && !isLoading"
      :message="bannerMessage"
    />
    <ModelsListVue v-else :ModelsList="selectedSiteAnalyticsModels" />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ModelsListVue from '@/Core.Service.Domain/Analytics/Models/Components/ModelsList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

export default {
  name: 'ModelsPortfolio',

  components: { ModelsListVue, ProgressLinearLoaderVue, BannerAlertVue },

  computed: {
    ...mapGetters({
      selectedSiteAnalyticsModels:
        'AnalyticsStore/AnalyticsModelsStore/selectedSiteAnalyticsModels',
      analyticsModelsCurrentRoute:
        'AnalyticsStore/AnalyticsModelsStore/analyticsModelsCurrentRoute',
      isLoading:
        'AnalyticsStore/AnalyticsModelsStore/selectedSiteAnalyticsModelsIsLoading',
      defaultSite: 'session/defaultSite',
    }),

    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },
  },
};
</script>
