import {
  IMessageCenterMessage,
  IMessageCenterMessageEdit,
  IMessageCenterMessageAssociation,
  IMessageCenterMessageDisassociation,
} from '@/Core.Service.Domain/MessageCenter/Management/types';

const apiVersion = 'v1';

const getAllMessagesFromMessageCenter = async () => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/MessageCenterManagement`
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllUsersForMessageCenter = async () => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/UserMessageCenterManagement`
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMessageById = async (messageId: number) => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/MessageCenterManagement/${messageId}`
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const messageCenterCreateMessage = async (message: IMessageCenterMessage) => {
  try {
    const response = await window.axios.post(
      `/${apiVersion}/MessageCenterManagement`,
      message
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const messageCenterEditMessage = async (message: IMessageCenterMessageEdit) => {
  try {
    const response = await window.axios.put(
      `/${apiVersion}/MessageCenterManagement`,
      message
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteMessage = async (messageId: number) => {
  try {
    const response = await window.axios.delete(
      `/${apiVersion}/MessageCenterManagement`,
      {
        data: { MessageCenterMessageId: messageId },
      }
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const associateUserToMessage = async (
  messageData: IMessageCenterMessageAssociation
) => {
  const { UserIds, MessageCenterMessageId } = messageData;
  try {
    const response = await window.axios.post(
      `/${apiVersion}/MessageCenterManagement/UserMessageCenterMessage`,
      { MessageCenterMessageId, UserIds }
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const disassociateUserToMessage = async (
  messageData: IMessageCenterMessageDisassociation
) => {
  const { UserIds, MessageCenterMessageId } = messageData;
  try {
    const response = await window.axios.delete(
      `/${apiVersion}/MessageCenterManagement/UserMessageCenterMessage`,
      { data: { MessageCenterMessageId, UserIds } }
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMessagesTypesList = async () => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/MessageCenterMessageType`
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getAllMessagesFromMessageCenter,
  getAllUsersForMessageCenter,
  getMessageById,
  messageCenterCreateMessage,
  messageCenterEditMessage,
  deleteMessage,
  associateUserToMessage,
  disassociateUserToMessage,
  getMessagesTypesList,
};
