<template>
  <svg
    version="1.1"
    viewBox="433.49 274.26 242.23 185.97"
    id="svg1"
    sodipodi:docname="6822-01-suite101-cropped.svg"
    inkscape:version="1.4 (86a8ad7, 2024-10-11)"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs1" />
    <desc id="desc1">Produce by Acme CAD Converter</desc>
    <g id="g1">
      <title id="title1">Layer 1</title>
      <path
        id="svg_69"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m469.42001,342.69l0,-59.47l0,59.47z"
      />
      <path
        id="svg_70"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m470.16,317.70001l0,-34.48001l0,34.48001z"
      />
      <path
        id="svg_71"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m468.17001,341.44l0,-58.22l0,58.22z"
      />
      <path
        id="svg_72"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m469.42001,283.22l201.69998,0"
      />
      <path
        id="svg_145"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.10999,280.98001l0,1l0,-1z"
      />
      <path
        id="svg_146"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.10999,281.98001l33.19,0"
      />
      <path
        id="svg_147"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m493.44,281.98001l69.42999,0"
      />
      <path
        id="svg_148"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.87,281.98001l0,-1l0,1z"
      />
      <path
        id="svg_149"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.87,280.98001l5.97998,0"
      />
      <path
        id="svg_150"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m568.84998,280.98001l0,1l0,-1z"
      />
      <path
        id="svg_151"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m568.84998,281.98001l89.76001,0"
      />
      <path
        id="svg_152"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m658.60999,281.98001l0,-1l0,1z"
      />
      <path
        id="svg_153"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m658.60999,280.98001l5.98004,0"
      />
      <path
        id="svg_154"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m664.59003,280.98001l0,1l0,-1z"
      />
      <path
        id="svg_471"
        fill="black"
        fill-rule="nonzero"
        d="m467.25,341.88l1.67001,0l0,0.60999l-1.67001,0l0,-0.60999z"
      />
      <path
        id="svg_472"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,342.19l0,-1.19l0.61002,0l0,1.19l-0.61002,0z"
      />
      <path
        id="svg_473"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,340.23001l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_474"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,337.89001l0,-1.56003l0.61002,0l0,1.56003l-0.61002,0z"
      />
      <path
        id="svg_475"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,335.54999l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_476"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,333.20999l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_477"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,330.88l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_478"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,328.54001l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_479"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,326.20001l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_480"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,323.85999l0,-1.54999l0.61002,0l0,1.54999l-0.61002,0z"
      />
      <path
        id="svg_481"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,321.53l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_482"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,319.19l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_483"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,316.85001l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_484"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,314.51001l0,-1.55002l0.61002,0l0,1.55002l-0.61002,0z"
      />
      <path
        id="svg_485"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,312.17999l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_486"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,309.84l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_487"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,307.5l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_488"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,305.16l0,-1.55002l0.61002,0l0,1.55002l-0.61002,0z"
      />
      <path
        id="svg_489"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,302.82999l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_490"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,300.48999l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_491"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,298.14999l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_492"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,295.81l0,-1.54999l0.61002,0l0,1.54999l-0.61002,0z"
      />
      <path
        id="svg_493"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,293.48001l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_494"
        fill="black"
        fill-rule="nonzero"
        d="m468.60999,291.14001l0,-1.18002l0.61002,0l0,1.18002l-0.61002,0z"
      />
      <path
        id="svg_495"
        fill="black"
        fill-rule="nonzero"
        d="m468.92001,290.26001l-1.67001,0l0,-0.61002l1.67001,0l0,0.61002z"
      />
      <path
        id="svg_722"
        fill="white"
        fill-rule="nonzero"
        d="m558.90997,317.32999l0,8.38l14.08002,0l0,-8.38l-14.08002,0z"
      />
      <path
        id="svg_745"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,283.22l0,151.69l0,-151.69z"
      />
      <path
        id="svg_746"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m672.35999,283.22l0,151.69l0,-151.69z"
      />
      <path
        id="svg_1409"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.75,313.13l-1.34003,0"
      />
      <path
        id="svg_1410"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.87,313.13l-21.03998,0"
      />
      <path
        id="svg_1411"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m590.59003,313.13l-1.28003,0"
      />
      <path
        id="svg_1412"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m580.77002,313.13l-21.35004,0"
      />
      <path
        id="svg_1413"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.28998,313.13l-20.52997,0"
      />
      <path
        id="svg_1414"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.64001,313.13l-19.30002,0"
      />
      <path
        id="svg_1415"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.98999,314.38l-2.14001,0"
      />
      <path
        id="svg_1416"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.87,314.38l-23.13,0"
      />
      <path
        id="svg_1417"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m626.57001,326.35001l-41.51001,0"
      />
      <path
        id="svg_1418"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.08002,326.35001l-11.26001,0"
      />
      <path
        id="svg_1419"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.82001,326.35001l-15.97003,0"
      />
      <path
        id="svg_1420"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.82001,326.60001l-1.45001,0"
      />
      <path
        id="svg_1421"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m546.67999,326.60001l-1.37,0"
      />
      <path
        id="svg_1422"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m544.62,326.60001l-1.37,0"
      />
      <path
        id="svg_1423"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m542.57001,326.60001l-1.37,0"
      />
      <path
        id="svg_1424"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m540.51001,326.60001l-1.37,0"
      />
      <path
        id="svg_1425"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m538.45001,326.60001l-1.37,0"
      />
      <path
        id="svg_1426"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m536.40002,326.60001l-1.38,0"
      />
      <path
        id="svg_1427"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m534.34003,326.60001l-1.45001,0"
      />
      <path
        id="svg_1428"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.89001,326.60001l-0.06,0"
      />
      <path
        id="svg_1429"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.83002,326.60001l-0.04999,0"
      />
      <path
        id="svg_1430"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.78003,326.60001l-0.06006,0.00998"
      />
      <path
        id="svg_1431"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.71997,326.60999l-0.04999,0.02002"
      />
      <path
        id="svg_1432"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.66998,326.63l-0.06,0.01001"
      />
      <path
        id="svg_1433"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.60999,326.64001l-0.04999,0.03"
      />
      <path
        id="svg_1434"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.56,326.67001l-0.04999,0.01999"
      />
      <path
        id="svg_1435"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.51001,326.69l-0.04004,0.03"
      />
      <path
        id="svg_1436"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.46997,326.72l-0.04999,0.03"
      />
      <path
        id="svg_1437"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.41998,326.75l-0.03998,0.04001"
      />
      <path
        id="svg_1438"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.38,326.79001l-0.03998,0.03"
      />
      <path
        id="svg_1439"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.34003,326.82001l-0.04004,0.03998"
      />
      <path
        id="svg_1440"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.29999,326.85999l-0.02997,0.05002"
      />
      <path
        id="svg_1441"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.27002,326.91l-0.04004,0.04001"
      />
      <path
        id="svg_1442"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.22998,326.95001l-0.01996,0.04999"
      />
      <path
        id="svg_1443"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.21002,327l-0.03003,0.04999"
      />
      <path
        id="svg_1444"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.17999,327.04999l-0.02002,0.05002"
      />
      <path
        id="svg_1445"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.15997,327.10001l-0.01996,0.04999"
      />
      <path
        id="svg_1446"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.14001,327.14999l-0.01001,0.06"
      />
      <path
        id="svg_1447"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.13,327.20999l-0.01001,0.05002l0.01001,-0.05002z"
      />
      <path
        id="svg_1448"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.12,327.26001l-0.01001,0.04999l0.01001,-0.04999z"
      />
      <path
        id="svg_1449"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,327.31l0,0.06l0,-0.06z"
      />
      <path
        id="svg_1450"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,327.59l-24.40002,0"
      />
      <path
        id="svg_1451"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,327.59l-13.87,0"
      />
      <path
        id="svg_1452"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.52002,327.59l-1.53003,0"
      />
      <path
        id="svg_1453"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m573.75,327.59l-8.92999,0"
      />
      <path
        id="svg_1454"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.82001,327.59l-14.96002,0"
      />
      <path
        id="svg_1455"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.82001,327.34l-0.95001,0"
      />
      <path
        id="svg_1456"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m547.17999,327.34l-1.37,0"
      />
      <path
        id="svg_1457"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m545.12,327.34l-1.37,0"
      />
      <path
        id="svg_1458"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m543.07001,327.34l-1.38,0"
      />
      <path
        id="svg_1459"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m541.01001,327.34l-1.37,0"
      />
      <path
        id="svg_1460"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m538.95001,327.34l-1.37,0"
      />
      <path
        id="svg_1461"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m536.90002,327.34l-1.38,0"
      />
      <path
        id="svg_1462"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m534.84003,327.34l-0.96002,0"
      />
      <path
        id="svg_1463"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.88,327.34l-0.03003,0.01001"
      />
      <path
        id="svg_1464"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.84998,327.35001l-0.02997,0"
      />
      <path
        id="svg_1465"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.82001,327.35001l-0.03003,0.00998"
      />
      <path
        id="svg_1466"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.78998,327.35999l-0.02997,0.01001"
      />
      <path
        id="svg_1467"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.76001,327.37l-0.02002,0.02002"
      />
      <path
        id="svg_1468"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.73999,327.39001l-0.02997,0.00998"
      />
      <path
        id="svg_1469"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.71002,327.39999l-0.02002,0.03"
      />
      <path
        id="svg_1470"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.69,327.42999l-0.02002,0.02002"
      />
      <path
        id="svg_1471"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.66998,327.45001l-0.01996,0.01999"
      />
      <path
        id="svg_1472"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.65002,327.47l-0.01001,0.03"
      />
      <path
        id="svg_1473"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.64001,327.5l-0.02002,0.03"
      />
      <path
        id="svg_1474"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.62,327.53l-0.01001,0.03l0.01001,-0.03z"
      />
      <path
        id="svg_1475"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,327.56l0,0.03l0,-0.03z"
      />
      <path
        id="svg_1476"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,327.59l0,0.03l0,-0.03z"
      />
      <path
        id="svg_1477"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,352.53l-24.40002,0"
      />
      <path
        id="svg_1478"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,352.53l-23.94,0"
      />
      <path
        id="svg_1479"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,353.76999l-4.97998,0"
      />
      <path
        id="svg_1480"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,353.76999l-17.95001,0"
      />
      <path
        id="svg_1481"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m599.89001,353.76999l-24.90002,0"
      />
      <path
        id="svg_1482"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,369.98001l-4.97998,0"
      />
      <path
        id="svg_1483"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m626.57001,371.22l-6.22998,0"
      />
      <path
        id="svg_1484"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m580.77002,314.38l-23.51001,0"
      />
      <path
        id="svg_1485"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.28998,314.38l-22.66998,0"
      />
      <path
        id="svg_1486"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.64001,314.38l-15.86002,0"
      />
      <path
        id="svg_1487"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.53,314.38l-2.19,0"
      />
      <path
        id="svg_1488"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.87,313.13l0.17999,-1.75"
      />
      <path
        id="svg_1489"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m613.04999,311.38l0.51001,-1.67999"
      />
      <path
        id="svg_1490"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m613.56,309.70001l0.83002,-1.55002"
      />
      <path
        id="svg_1491"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m614.39001,308.14999l1.10999,-1.35999"
      />
      <path
        id="svg_1492"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m615.5,306.79001l1.35999,-1.12"
      />
      <path
        id="svg_1493"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m616.85999,305.67001l1.56,-0.83002"
      />
      <path
        id="svg_1494"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m618.41998,304.84l1.67999,-0.51001"
      />
      <path
        id="svg_1495"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m620.09998,304.32999l1.75,-0.16998"
      />
      <path
        id="svg_1496"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m621.84998,313.13l-0.44,0"
      />
      <path
        id="svg_1497"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m621.40997,313.13l0,-8.95999l0,8.95999z"
      />
      <path
        id="svg_1498"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m621.84998,304.16l0,8.97l0,-8.97z"
      />
      <path
        id="svg_1499"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m621.84998,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1500"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.34998,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1501"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.87,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1502"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.38,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1503"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m590.59003,313.13l-1.28003,0"
      />
      <path
        id="svg_1504"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.92999,314.38l-2.19,0"
      />
      <path
        id="svg_1505"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m580.77002,313.13l0.16998,-1.75"
      />
      <path
        id="svg_1506"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m580.94,311.38l0.51001,-1.67999"
      />
      <path
        id="svg_1507"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m581.45001,309.70001l0.83002,-1.55002"
      />
      <path
        id="svg_1508"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m582.28003,308.14999l1.10999,-1.35999"
      />
      <path
        id="svg_1509"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m583.39001,306.79001l1.35999,-1.12"
      />
      <path
        id="svg_1510"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m584.75,305.67001l1.56,-0.83002"
      />
      <path
        id="svg_1511"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m586.31,304.84l1.67999,-0.51001"
      />
      <path
        id="svg_1512"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m587.98999,304.32999l1.75,-0.16998"
      />
      <path
        id="svg_1513"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m589.73999,313.13l-0.42999,0"
      />
      <path
        id="svg_1514"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m589.31,313.13l0,-8.95999l0,8.95999z"
      />
      <path
        id="svg_1515"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m589.73999,304.16l0,8.97l0,-8.97z"
      />
      <path
        id="svg_1516"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m589.73999,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1517"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m590.23999,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1518"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m580.77002,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1519"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m580.27002,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1520"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m559.45001,314.38l-2.19,0"
      />
      <path
        id="svg_1521"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.28998,313.13l0.17004,-1.75"
      />
      <path
        id="svg_1522"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.46002,311.38l0.50995,-1.67999"
      />
      <path
        id="svg_1523"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.96997,309.70001l0.83002,-1.55002"
      />
      <path
        id="svg_1524"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m549.79999,308.14999l1.10999,-1.35999"
      />
      <path
        id="svg_1525"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m550.90997,306.79001l1.36005,-1.12"
      />
      <path
        id="svg_1526"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m552.27002,305.67001l1.56,-0.83002"
      />
      <path
        id="svg_1527"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m553.83002,304.84l1.67999,-0.51001"
      />
      <path
        id="svg_1528"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m555.51001,304.32999l1.75,-0.16998"
      />
      <path
        id="svg_1529"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m557.26001,313.13l-0.44,0"
      />
      <path
        id="svg_1530"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m556.82001,313.13l0,-8.95999l0,8.95999z"
      />
      <path
        id="svg_1531"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m557.26001,304.16l0,8.97l0,-8.97z"
      />
      <path
        id="svg_1532"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m557.26001,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1533"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m557.76001,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1534"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.28998,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1535"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m547.78998,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1536"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m496.09,283.22l0,35.97l0,-35.97z"
      />
      <path
        id="svg_1537"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m494.60001,283.22l0,34.48001l0,-34.48001z"
      />
      <path
        id="svg_1538"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m497.34,283.22l0,37.22l0,-37.22z"
      />
      <path
        id="svg_1539"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.53,319.19l-30.10999,0"
      />
      <path
        id="svg_1540"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.53,317.70001l-2.19,0"
      />
      <path
        id="svg_1541"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m494.60001,317.70001l-25.17999,0"
      />
      <path
        id="svg_1542"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m497.34,320.44l-27.91998,0"
      />
      <path
        id="svg_1551"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m493.44,281.98001l-0.42999,0"
      />
      <path
        id="svg_1554"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m493.44,281.98001l0,1.23999l0,-1.23999z"
      />
      <path
        id="svg_1556"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m527.76001,283.22l0,29.91l0,-29.91z"
      />
      <path
        id="svg_1557"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m558.16998,283.22l0,29.91l0,-29.91z"
      />
      <path
        id="svg_1558"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m559.41998,283.22l0,29.91l0,-29.91z"
      />
      <path
        id="svg_1559"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m526.51001,283.22l0,29.91l0,-29.91z"
      />
      <path
        id="svg_1560"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m527.81,314.38l-2.19,0"
      />
      <path
        id="svg_1561"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.64001,313.13l0.16998,-1.75"
      />
      <path
        id="svg_1562"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.81,311.38l0.51001,-1.67999"
      />
      <path
        id="svg_1563"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m517.32001,309.70001l0.83002,-1.55002"
      />
      <path
        id="svg_1564"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m518.15002,308.14999l1.12,-1.35999"
      />
      <path
        id="svg_1565"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m519.27002,306.79001l1.35999,-1.12"
      />
      <path
        id="svg_1566"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m520.63,305.67001l1.54999,-0.83002"
      />
      <path
        id="svg_1567"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m522.17999,304.84l1.69,-0.51001"
      />
      <path
        id="svg_1568"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m523.87,304.32999l1.75,-0.16998"
      />
      <path
        id="svg_1569"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m525.62,313.13l-0.44,0"
      />
      <path
        id="svg_1570"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m525.17999,313.13l0,-8.95999l0,8.95999z"
      />
      <path
        id="svg_1571"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m525.62,304.16l0,8.97l0,-8.97z"
      />
      <path
        id="svg_1572"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m525.62,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1573"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m526.12,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1574"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.64001,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1575"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.14001,313.13l0,1.25l0,-1.25z"
      />
      <path
        id="svg_1576"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,283.22l0,8.76999l0,-8.76999z"
      />
      <path
        id="svg_1577"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,314.38l0,7.03l0,-7.03z"
      />
      <path
        id="svg_1578"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,322.64999l0,1.42001l0,-1.42001z"
      />
      <path
        id="svg_1579"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,332.60999l0,14.23001l0,-14.23001z"
      />
      <path
        id="svg_1580"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.53998,281.98001l0,8.76999l0,-8.76999z"
      />
      <path
        id="svg_1581"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.53998,314.38l0,9.25l0,-9.25z"
      />
      <path
        id="svg_1582"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.53998,332.60999l0,16.63l0,-16.63z"
      />
      <path
        id="svg_1583"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m626.57001,326.35001l0,2.09l0,-2.09z"
      />
      <path
        id="svg_1584"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m626.57001,337.41l0,18.03l0,-18.03z"
      />
      <path
        id="svg_1585"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m626.57001,368.45001l0,2.76999l0,-2.76999z"
      />
      <path
        id="svg_1586"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,327.59l0,1.28l0,-1.28z"
      />
      <path
        id="svg_1587"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,337.41l0,15.12l0,-15.12z"
      />
      <path
        id="svg_1588"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,353.76999l0,1.67001l0,-1.67001z"
      />
      <path
        id="svg_1589"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,368.45001l0,1.53l0,-1.53z"
      />
      <path
        id="svg_1590"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m620.34003,353.76999l0,16.21002l0,-16.21002z"
      />
      <path
        id="svg_1591"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m620.34003,371.22l0,2.54999l0,-2.54999z"
      />
      <path
        id="svg_1592"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m620.34003,382.73999l0,2.99002l0,-2.99002z"
      />
      <path
        id="svg_1593"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,353.76999l0,20l0,-20z"
      />
      <path
        id="svg_1594"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.14001,353.76999l0,29.97l0,-29.97z"
      />
      <path
        id="svg_1595"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m599.89001,353.76999l0,29.97l0,-29.97z"
      />
      <path
        id="svg_1596"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,382.29999l0,1.44l0,-1.44z"
      />
      <path
        id="svg_1597"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.98999,290.75l4.5,0"
      />
      <path
        id="svg_1598"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m637.03003,290.75l1.50995,0"
      />
      <path
        id="svg_1599"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.78998,291.98999l5.70001,0"
      />
      <path
        id="svg_1600"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m637.46002,291.98999l2.32001,0"
      />
      <path
        id="svg_1601"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m628.48999,290.75l0.16998,-1.75"
      />
      <path
        id="svg_1602"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m628.65997,289l0.51001,-1.69"
      />
      <path
        id="svg_1603"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m629.16998,287.31l0.83002,-1.54999"
      />
      <path
        id="svg_1604"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m630,285.76001l1.12,-1.36002"
      />
      <path
        id="svg_1605"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m631.12,284.39999l1.35999,-1.12"
      />
      <path
        id="svg_1606"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m632.47998,283.28l1.55005,-0.82999"
      />
      <path
        id="svg_1607"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m634.03003,282.45001l1.67999,-0.51001"
      />
      <path
        id="svg_1608"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m635.71002,281.94l1.75,-0.17001"
      />
      <path
        id="svg_1609"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m637.46002,290.75l-0.42999,0"
      />
      <path
        id="svg_1610"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m637.03003,290.75l0,-8.97l0,8.97z"
      />
      <path
        id="svg_1611"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m637.46002,281.76999l0,8.98001l0,-8.98001z"
      />
      <path
        id="svg_1612"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m637.46002,290.75l0,1.23999l0,-1.23999z"
      />
      <path
        id="svg_1613"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m637.96002,290.75l0,1.23999l0,-1.23999z"
      />
      <path
        id="svg_1614"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m628.48999,290.75l0,1.23999l0,-1.23999z"
      />
      <path
        id="svg_1615"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m627.98999,290.75l0,1.23999l0,-1.23999z"
      />
      <path
        id="svg_1616"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,321.41l31.33997,0"
      />
      <path
        id="svg_1617"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,322.64999l31.33997,0"
      />
      <path
        id="svg_1618"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,346.84l31.33997,0"
      />
      <path
        id="svg_1619"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,348.09l31.33997,0"
      />
      <path
        id="svg_1620"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.53998,314.38l1.24005,0"
      />
      <path
        id="svg_1621"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,332.60999l1.75995,-0.16998"
      />
      <path
        id="svg_1622"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m641.53998,332.44l1.67999,-0.51999"
      />
      <path
        id="svg_1623"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m643.21997,331.92001l1.55005,-0.82001"
      />
      <path
        id="svg_1624"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m644.77002,331.10001l1.35999,-1.12"
      />
      <path
        id="svg_1625"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m646.13,329.98001l1.12,-1.36002"
      />
      <path
        id="svg_1626"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m647.25,328.62l0.83002,-1.54999"
      />
      <path
        id="svg_1627"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.08002,327.07001l0.51001,-1.69"
      />
      <path
        id="svg_1628"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.59003,325.38l0.16998,-1.75"
      />
      <path
        id="svg_1629"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,323.63l0,0.44l0,-0.44z"
      />
      <path
        id="svg_1630"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,324.07001l8.96997,0"
      />
      <path
        id="svg_1631"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.76001,323.63l-8.97998,0"
      />
      <path
        id="svg_1632"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,323.63l-1.24005,0"
      />
      <path
        id="svg_1633"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,323.13l-1.24005,0"
      />
      <path
        id="svg_1634"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,332.60999l-1.24005,0"
      />
      <path
        id="svg_1635"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,333.10999l-1.24005,0"
      />
      <path
        id="svg_1636"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,348.09l0,1.14999l0,-1.14999z"
      />
      <path
        id="svg_1637"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,349.23999l-1.24005,0"
      />
      <path
        id="svg_1638"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,348.73999l-1.24005,0"
      />
      <path
        id="svg_1639"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m526.51001,313.13l-0.89001,0"
      />
      <path
        id="svg_1640"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m558.16998,313.13l-0.90997,0"
      />
      <path
        id="svg_1641"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m590.59003,283.22l0,29.91l0,-29.91z"
      />
      <path
        id="svg_1642"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.83002,283.22l0,29.91l0,-29.91z"
      />
      <path
        id="svg_1643"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.75,283.22l0,29.91l0,-29.91z"
      />
      <path
        id="svg_1644"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.98999,281.98001l0,8.76999l0,-8.76999z"
      />
      <path
        id="svg_1645"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.98999,291.98999l0,22.39001l0,-22.39001z"
      />
      <path
        id="svg_1646"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,358.04001l0,14.22998l0,-14.22998z"
      />
      <path
        id="svg_1647"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.53998,358.04001l0,16.63l0,-16.63z"
      />
      <path
        id="svg_1648"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,372.26999l31.33997,0"
      />
      <path
        id="svg_1649"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,373.51999l31.33997,0"
      />
      <path
        id="svg_1650"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,358.04001l1.75995,-0.17001"
      />
      <path
        id="svg_1651"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m641.53998,357.87l1.67999,-0.51001"
      />
      <path
        id="svg_1652"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m643.21997,357.35999l1.55005,-0.82999"
      />
      <path
        id="svg_1653"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m644.77002,356.53l1.35999,-1.12"
      />
      <path
        id="svg_1654"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m646.13,355.41l1.12,-1.36002"
      />
      <path
        id="svg_1655"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m647.25,354.04999l0.83002,-1.54999"
      />
      <path
        id="svg_1656"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.08002,352.5l0.51001,-1.69"
      />
      <path
        id="svg_1657"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.59003,350.81l0.16998,-1.75"
      />
      <path
        id="svg_1658"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,349.06l0,0.44l0,-0.44z"
      />
      <path
        id="svg_1659"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,349.5l8.96997,0"
      />
      <path
        id="svg_1660"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.76001,349.06l-8.97998,0"
      />
      <path
        id="svg_1661"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,358.04001l-1.24005,0"
      />
      <path
        id="svg_1662"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,358.54001l-1.24005,0"
      />
      <path
        id="svg_1663"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,373.51999l0,1.15002l0,-1.15002z"
      />
      <path
        id="svg_1664"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,374.67001l-1.24005,0"
      />
      <path
        id="svg_1665"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,374.17999l-1.24005,0"
      />
      <path
        id="svg_1666"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,383.47l0,14.23001l0,-14.23001z"
      />
      <path
        id="svg_1667"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.53998,383.47l0,15.48001l0,-15.48001z"
      />
      <path
        id="svg_1668"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m531.35999,397.70001l2.33002,0"
      />
      <path
        id="svg_1669"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m542.65997,397.70001l24.36005,0"
      />
      <path
        id="svg_1670"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576,397.70001l24.46002,0"
      />
      <path
        id="svg_1671"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.96997,385.73001l15.85004,0"
      />
      <path
        id="svg_1672"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.33002,385.48001l1.22998,0"
      />
      <path
        id="svg_1673"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m535.25,385.48001l1.37,0"
      />
      <path
        id="svg_1674"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m537.29999,385.48001l1.37,0"
      />
      <path
        id="svg_1675"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m539.35999,385.48001l1.37,0"
      />
      <path
        id="svg_1676"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m541.41998,385.48001l1.37,0"
      />
      <path
        id="svg_1677"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m543.46997,385.48001l1.37006,0"
      />
      <path
        id="svg_1678"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m545.53003,385.48001l1.37,0"
      />
      <path
        id="svg_1679"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m547.59003,385.48001l1.22998,0"
      />
      <path
        id="svg_1680"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.82001,385.73001l21.02997,0"
      />
      <path
        id="svg_1681"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.82001,385.73001l25.52002,0"
      />
      <path
        id="svg_1682"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.85999,383.73999l14.96002,0"
      />
      <path
        id="svg_1683"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.88,383.98999l0.96002,0"
      />
      <path
        id="svg_1684"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m535.52002,383.98999l1.38,0"
      />
      <path
        id="svg_1685"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m537.58002,383.98999l1.37,0"
      />
      <path
        id="svg_1686"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m539.64001,383.98999l1.37,0"
      />
      <path
        id="svg_1687"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m541.69,383.98999l1.38,0"
      />
      <path
        id="svg_1688"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m543.75,383.98999l1.37,0"
      />
      <path
        id="svg_1689"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m545.81,383.98999l1.37,0"
      />
      <path
        id="svg_1690"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m547.87,383.98999l0.95001,0"
      />
      <path
        id="svg_1691"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.82001,383.73999l8.92999,0"
      />
      <path
        id="svg_1692"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m574.98999,383.73999l10.85999,0"
      />
      <path
        id="svg_1693"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.39001,383.73999l24.70001,0"
      />
      <path
        id="svg_1694"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m531.85999,384.62a1.109,1.109 0 0 0 1.10999,1.11002"
      />
      <path
        id="svg_1695"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m609.42999,397.70001l19.63,0"
      />
      <path
        id="svg_1696"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.03998,397.70001l33.08002,0"
      />
      <path
        id="svg_1697"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.60999,398.95001l1.51001,0"
      />
      <path
        id="svg_1698"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m542.65997,398.95001l22.04004,0"
      />
      <path
        id="svg_1699"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576,398.95001l22.14001,0"
      />
      <path
        id="svg_1700"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m599.38,398.95001l1.51001,0"
      />
      <path
        id="svg_1701"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m609.42999,398.95001l17.31,0"
      />
      <path
        id="svg_1702"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m627.98999,398.95001l1.51001,0"
      />
      <path
        id="svg_1703"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.03998,398.95001l33.08002,0"
      />
      <path
        id="svg_1704"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,383.47l1.75995,-0.17001"
      />
      <path
        id="svg_1705"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m641.53998,383.29999l1.67999,-0.50998"
      />
      <path
        id="svg_1706"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m643.21997,382.79001l1.55005,-0.83002"
      />
      <path
        id="svg_1707"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m644.77002,381.95999l1.35999,-1.12"
      />
      <path
        id="svg_1708"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m646.13,380.84l1.12,-1.35999"
      />
      <path
        id="svg_1709"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m647.25,379.48001l0.83002,-1.55002"
      />
      <path
        id="svg_1710"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.08002,377.92999l0.51001,-1.69"
      />
      <path
        id="svg_1711"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.59003,376.23999l0.16998,-1.75"
      />
      <path
        id="svg_1712"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,374.48999l0,0.44l0,-0.44z"
      />
      <path
        id="svg_1713"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,374.92999l8.96997,0"
      />
      <path
        id="svg_1714"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.76001,374.48999l-8.97998,0"
      />
      <path
        id="svg_1715"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,383.47l-1.24005,0"
      />
      <path
        id="svg_1716"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,383.97l-1.24005,0"
      />
      <path
        id="svg_1717"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m531.35999,433.60001l0,-35.89999l0,35.89999z"
      />
      <path
        id="svg_1718"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.60999,433.60001l0,-34.64999l0,34.64999z"
      />
      <path
        id="svg_1719"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m542.65997,398.95001l-0.16998,1.75"
      />
      <path
        id="svg_1720"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m542.48999,400.70001l-0.51001,1.67999"
      />
      <path
        id="svg_1721"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m541.97998,402.38l-0.82996,1.54999"
      />
      <path
        id="svg_1722"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m541.15002,403.92999l-1.12,1.36002"
      />
      <path
        id="svg_1723"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m540.03003,405.29001l-1.36005,1.12"
      />
      <path
        id="svg_1724"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m538.66998,406.41l-1.54999,0.82999"
      />
      <path
        id="svg_1725"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m537.12,407.23999l-1.67999,0.51001"
      />
      <path
        id="svg_1726"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m535.44,407.75l-1.75,0.17001"
      />
      <path
        id="svg_1727"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.69,398.95001l0.42999,0"
      />
      <path
        id="svg_1728"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m534.12,398.95001l0,8.95999l0,-8.95999z"
      />
      <path
        id="svg_1729"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.69,407.92001l0,-8.97l0,8.97z"
      />
      <path
        id="svg_1730"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.69,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1731"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.19,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1732"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m542.65997,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1733"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m543.15997,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1734"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.70001,431.60001l0,-32.64999l0,32.64999z"
      />
      <path
        id="svg_1735"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m565.95001,431.60001l0,-32.64999l0,32.64999z"
      />
      <path
        id="svg_1736"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576,398.95001l-0.17999,1.75"
      />
      <path
        id="svg_1737"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m575.82001,400.70001l-0.51001,1.67999"
      />
      <path
        id="svg_1738"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m575.31,402.38l-0.83002,1.54999"
      />
      <path
        id="svg_1739"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m574.47998,403.92999l-1.10999,1.36002"
      />
      <path
        id="svg_1740"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m573.37,405.29001l-1.35999,1.12"
      />
      <path
        id="svg_1741"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m572.01001,406.41l-1.54999,0.82999"
      />
      <path
        id="svg_1742"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m570.46002,407.23999l-1.69,0.51001"
      />
      <path
        id="svg_1743"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m568.77002,407.75l-1.75,0.17001"
      />
      <path
        id="svg_1744"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m567.02002,398.95001l0.44,0"
      />
      <path
        id="svg_1745"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m567.46002,398.95001l0,8.95999l0,-8.95999z"
      />
      <path
        id="svg_1746"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m567.02002,407.92001l0,-8.97l0,8.97z"
      />
      <path
        id="svg_1747"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m567.02002,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1748"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m566.52002,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1749"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1750"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.5,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1751"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.14001,434.59l0,-35.63998l0,35.63998z"
      />
      <path
        id="svg_1752"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m599.38,434.59l0,-35.63998l0,35.63998z"
      />
      <path
        id="svg_1753"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m609.42999,398.95001l-0.16998,1.75"
      />
      <path
        id="svg_1754"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m609.26001,400.70001l-0.51001,1.67999"
      />
      <path
        id="svg_1755"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.75,402.38l-0.83002,1.54999"
      />
      <path
        id="svg_1756"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m607.91998,403.92999l-1.10999,1.36002"
      />
      <path
        id="svg_1757"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m606.81,405.29001l-1.37,1.12"
      />
      <path
        id="svg_1758"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.44,406.41l-1.54999,0.82999"
      />
      <path
        id="svg_1759"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.89001,407.23999l-1.67999,0.51001"
      />
      <path
        id="svg_1760"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.21002,407.75l-1.75,0.17001"
      />
      <path
        id="svg_1761"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.46002,398.95001l0.42999,0"
      />
      <path
        id="svg_1762"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.89001,398.95001l0,8.95999l0,-8.95999z"
      />
      <path
        id="svg_1763"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.46002,407.92001l0,-8.97l0,8.97z"
      />
      <path
        id="svg_1764"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.46002,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1765"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m599.96002,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1766"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m609.42999,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1767"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m609.92999,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1768"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m565.95001,398.95001l1.07001,0"
      />
      <path
        id="svg_1769"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m626.73999,436.34l0,-37.38998l0,37.38998z"
      />
      <path
        id="svg_1770"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m627.98999,436.34l0,-37.38998l0,37.38998z"
      />
      <path
        id="svg_1771"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.03998,398.95001l-0.16998,1.75"
      />
      <path
        id="svg_1772"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m637.87,400.70001l-0.51001,1.67999"
      />
      <path
        id="svg_1773"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m637.35999,402.38l-0.82996,1.54999"
      />
      <path
        id="svg_1774"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m636.53003,403.92999l-1.12006,1.36002"
      />
      <path
        id="svg_1775"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m635.40997,405.29001l-1.35999,1.12"
      />
      <path
        id="svg_1776"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m634.04999,406.41l-1.54999,0.82999"
      />
      <path
        id="svg_1777"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m632.5,407.23999l-1.69,0.51001"
      />
      <path
        id="svg_1778"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m630.81,407.75l-1.75,0.17001"
      />
      <path
        id="svg_1779"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m629.06,398.95001l0.44,0"
      />
      <path
        id="svg_1780"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m629.5,398.95001l0,8.95999l0,-8.95999z"
      />
      <path
        id="svg_1781"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m629.06,407.92001l0,-8.97l0,8.97z"
      />
      <path
        id="svg_1782"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m629.06,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1783"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m628.57001,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1784"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.03998,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1785"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m638.53998,398.95001l0,-1.25l0,1.25z"
      />
      <path
        id="svg_1786"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m531.85999,327.34l0,57.28l0,-57.28z"
      />
      <path
        id="svg_1787"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,327.37l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1788"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,329.42001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1789"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,331.48001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1790"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,333.54001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1791"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,335.59l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1792"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,337.64999l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1793"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,339.70999l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1794"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,341.76001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1795"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,343.82001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1796"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,345.88l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1797"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,347.92999l0,1.38l0,-1.38z"
      />
      <path
        id="svg_1798"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,349.98999l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1799"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,352.04999l0,1.37003l0,-1.37003z"
      />
      <path
        id="svg_1800"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,354.10001l0,1.38l0,-1.38z"
      />
      <path
        id="svg_1801"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,356.16l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1802"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,358.22l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1803"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,360.28l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1804"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,362.32999l0,1.37003l0,-1.37003z"
      />
      <path
        id="svg_1805"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,364.39001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1806"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,366.45001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1807"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,368.5l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1808"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,370.56l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1809"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,372.62l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1810"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,374.67001l0,1.37997l0,-1.37997z"
      />
      <path
        id="svg_1811"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,376.73001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1812"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,378.79001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1813"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,380.84l0,1.38l0,-1.38z"
      />
      <path
        id="svg_1814"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,382.89999l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1815"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,384.26999l0,0.07001l0,-0.07001z"
      />
      <path
        id="svg_1816"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.10999,384.34l0.01001,0.06l-0.01001,-0.06z"
      />
      <path
        id="svg_1817"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.12,384.39999l0.01001,0.07001l-0.01001,-0.07001z"
      />
      <path
        id="svg_1818"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.13,384.47l0.01001,0.07001"
      />
      <path
        id="svg_1819"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.14001,384.54001l0.01996,0.06"
      />
      <path
        id="svg_1820"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.15997,384.60001l0.02002,0.07001"
      />
      <path
        id="svg_1821"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.17999,384.67001l0.02002,0.06"
      />
      <path
        id="svg_1822"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.20001,384.73001l0.02997,0.06998"
      />
      <path
        id="svg_1823"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.22998,384.79999l0.03003,0.06"
      />
      <path
        id="svg_1824"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.26001,384.85999l0.03998,0.05002"
      />
      <path
        id="svg_1825"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.29999,384.91l0.04004,0.06"
      />
      <path
        id="svg_1826"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.34003,384.97l0.03998,0.06"
      />
      <path
        id="svg_1827"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.38,385.03l0.03998,0.04999"
      />
      <path
        id="svg_1828"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.41998,385.07999l0.04999,0.05002"
      />
      <path
        id="svg_1829"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.46997,385.13l0.05005,0.04001"
      />
      <path
        id="svg_1830"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.52002,385.17001l0.04999,0.04999"
      />
      <path
        id="svg_1831"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.57001,385.22l0.04999,0.04001"
      />
      <path
        id="svg_1832"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.62,385.26001l0.06,0.03998"
      />
      <path
        id="svg_1833"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.67999,385.29999l0.06,0.03"
      />
      <path
        id="svg_1834"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.73999,385.32999l0.06,0.03"
      />
      <path
        id="svg_1835"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.79999,385.35999l0.06,0.03003"
      />
      <path
        id="svg_1836"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.85999,385.39001l0.07001,0.01999"
      />
      <path
        id="svg_1837"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.92999,385.41l0.06,0.03"
      />
      <path
        id="svg_1838"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.98999,385.44l0.07001,0.01001"
      />
      <path
        id="svg_1839"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.06,385.45001l0.06,0.01999"
      />
      <path
        id="svg_1840"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.12,385.47l0.07001,0.01001"
      />
      <path
        id="svg_1841"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.19,385.48001l0.07001,0"
      />
      <path
        id="svg_1842"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.26001,385.48001l0.07001,0"
      />
      <path
        id="svg_1843"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.85999,327.59l0,56.14999l0,-56.14999z"
      />
      <path
        id="svg_1844"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,327.62l0,0.95999l0,-0.95999z"
      />
      <path
        id="svg_1845"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,329.26999l0,1.37003l0,-1.37003z"
      />
      <path
        id="svg_1846"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,331.32999l0,1.37003l0,-1.37003z"
      />
      <path
        id="svg_1847"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,333.38l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1848"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,335.44l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1849"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,337.5l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1850"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,339.54999l0,1.37003l0,-1.37003z"
      />
      <path
        id="svg_1851"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,341.60999l0,1.37003l0,-1.37003z"
      />
      <path
        id="svg_1852"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,343.67001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1853"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,345.72l0,1.38l0,-1.38z"
      />
      <path
        id="svg_1854"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,347.78l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1855"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,349.84l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1856"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,351.89001l0,1.37997l0,-1.37997z"
      />
      <path
        id="svg_1857"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,353.95001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1858"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,356.01001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1859"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,358.07001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1860"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,360.12l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1861"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,362.17999l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1862"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,364.23999l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1863"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,366.29001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1864"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,368.35001l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1865"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,370.41l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1866"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,372.45999l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1867"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,374.51999l0,1.37003l0,-1.37003z"
      />
      <path
        id="svg_1868"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,376.57999l0,1.37003l0,-1.37003z"
      />
      <path
        id="svg_1869"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,378.63l0,1.38l0,-1.38z"
      />
      <path
        id="svg_1870"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,380.69l0,1.37l0,-1.37z"
      />
      <path
        id="svg_1871"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,382.75l0,0.95999l0,-0.95999z"
      />
      <path
        id="svg_1872"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,383.70999l0,0.03l0,-0.03z"
      />
      <path
        id="svg_1873"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,383.73999l0,0.03l0,-0.03z"
      />
      <path
        id="svg_1874"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.60999,383.76999l0.01001,0.03l-0.01001,-0.03z"
      />
      <path
        id="svg_1875"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.62,383.79999l0.02002,0.03"
      />
      <path
        id="svg_1876"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.64001,383.82999l0.01001,0.03"
      />
      <path
        id="svg_1877"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.65002,383.85999l0.01996,0.02002"
      />
      <path
        id="svg_1878"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.66998,383.88l0.02002,0.03"
      />
      <path
        id="svg_1879"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.69,383.91l0.02002,0.01999"
      />
      <path
        id="svg_1880"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.71002,383.92999l0.02997,0.01001"
      />
      <path
        id="svg_1881"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.73999,383.94l0.02002,0.01999"
      />
      <path
        id="svg_1882"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.76001,383.95999l0.02997,0.01001"
      />
      <path
        id="svg_1883"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.78998,383.97l0.03003,0.01001"
      />
      <path
        id="svg_1884"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.82001,383.98001l0.02997,0.00998"
      />
      <path
        id="svg_1885"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m533.84998,383.98999l0.03003,0"
      />
      <path
        id="svg_1886"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m573.75,327.59l0,56.14999l0,-56.14999z"
      />
      <path
        id="svg_1887"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m574.98999,327.59l0,24.94l0,-24.94z"
      />
      <path
        id="svg_1888"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m574.98999,353.76999l0,29.97l0,-29.97z"
      />
      <path
        id="svg_1889"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,327.59l0,24.94l0,-24.94z"
      />
      <path
        id="svg_1890"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.91998,327.59l0,24.94l0,-24.94z"
      />
      <path
        id="svg_1891"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.84998,326.35001a0.989,0.989 0 0 0 -0.98999,0.98999"
      />
      <path
        id="svg_1892"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,337.41l-1.75,-0.17001"
      />
      <path
        id="svg_1893"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.57001,337.23999l-1.67999,-0.50998"
      />
      <path
        id="svg_1894"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m621.89001,336.73001l-1.54999,-0.83002"
      />
      <path
        id="svg_1895"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m620.34003,335.89999l-1.36005,-1.12"
      />
      <path
        id="svg_1896"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m618.97998,334.78l-1.12,-1.35999"
      />
      <path
        id="svg_1897"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m617.85999,333.42001l-0.82996,-1.55002"
      />
      <path
        id="svg_1898"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m617.03003,331.87l-0.51001,-1.67999"
      />
      <path
        id="svg_1899"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m616.52002,330.19l-0.17004,-1.75"
      />
      <path
        id="svg_1900"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,328.44l0,0.42999l0,-0.42999z"
      />
      <path
        id="svg_1901"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,328.87l-8.96002,0"
      />
      <path
        id="svg_1902"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m616.34998,328.44l8.97003,0"
      />
      <path
        id="svg_1903"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,328.44l1.25,0"
      />
      <path
        id="svg_1904"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,327.94l1.25,0"
      />
      <path
        id="svg_1905"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,337.41l1.25,0"
      />
      <path
        id="svg_1906"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,337.91l1.25,0"
      />
      <path
        id="svg_1907"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,355.44l1.25,0"
      />
      <path
        id="svg_1908"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.32001,368.45001l1.25,0"
      />
      <path
        id="svg_1909"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,373.76999l-1.75,0.17001"
      />
      <path
        id="svg_1910"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m617.34003,373.94l-1.68005,0.51001"
      />
      <path
        id="svg_1911"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m615.65997,374.45001l-1.56,0.82999"
      />
      <path
        id="svg_1912"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m614.09998,375.28l-1.35999,1.11002"
      />
      <path
        id="svg_1913"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.73999,376.39001l-1.10999,1.35999"
      />
      <path
        id="svg_1914"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m611.63,377.75l-0.83002,1.56"
      />
      <path
        id="svg_1915"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m610.79999,379.31l-0.51001,1.67999"
      />
      <path
        id="svg_1916"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m610.28998,380.98999l-0.16998,1.75"
      />
      <path
        id="svg_1917"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,382.73999l0,-0.44l0,0.44z"
      />
      <path
        id="svg_1918"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,382.29999l-8.96002,0"
      />
      <path
        id="svg_1919"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m610.12,382.73999l8.97003,0"
      />
      <path
        id="svg_1920"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,383.23999l1.25,0"
      />
      <path
        id="svg_1921"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,382.73999l1.25,0"
      />
      <path
        id="svg_1922"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,373.76999l1.25,0"
      />
      <path
        id="svg_1923"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m619.09003,373.26999l1.25,0"
      />
      <path
        id="svg_1924"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m585.84998,383.73999l0.17004,-1.75"
      />
      <path
        id="svg_1925"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m586.02002,381.98999l0.51001,-1.69"
      />
      <path
        id="svg_1926"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m586.53003,380.29999l0.82996,-1.54999"
      />
      <path
        id="svg_1927"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m587.35999,378.75l1.12,-1.35999"
      />
      <path
        id="svg_1928"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m588.47998,377.39001l1.36005,-1.11002"
      />
      <path
        id="svg_1929"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m589.84003,376.28l1.54999,-0.82999"
      />
      <path
        id="svg_1930"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.39001,375.45001l1.67999,-0.52002"
      />
      <path
        id="svg_1931"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.07001,374.92999l1.75,-0.16998"
      />
      <path
        id="svg_1932"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.82001,383.73999l-0.42999,0"
      />
      <path
        id="svg_1933"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.39001,383.73999l0,-8.97l0,8.97z"
      />
      <path
        id="svg_1934"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.82001,374.76001l0,8.97998l0,-8.97998z"
      />
      <path
        id="svg_1935"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.82001,383.73999l0,1.99002l0,-1.99002z"
      />
      <path
        id="svg_1936"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.32001,383.73999l0,1.99002l0,-1.99002z"
      />
      <path
        id="svg_1937"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m585.84998,383.73999l0,1.99002l0,-1.99002z"
      />
      <path
        id="svg_1938"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m585.34998,383.73999l0,1.99002l0,-1.99002z"
      />
      <path
        id="svg_1939"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m585.06,327.59l-0.17999,1.75"
      />
      <path
        id="svg_1940"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m584.88,329.34l-0.51001,1.69"
      />
      <path
        id="svg_1941"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m584.37,331.03l-0.83002,1.54999"
      />
      <path
        id="svg_1942"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m583.53998,332.57999l-1.10999,1.36002"
      />
      <path
        id="svg_1943"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m582.42999,333.94l-1.35999,1.12"
      />
      <path
        id="svg_1944"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m581.07001,335.06l-1.54999,0.83002"
      />
      <path
        id="svg_1945"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.52002,335.89001l-1.69,0.50998"
      />
      <path
        id="svg_1946"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m577.83002,336.39999l-1.75,0.17001"
      />
      <path
        id="svg_1947"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.08002,327.59l0.44,0"
      />
      <path
        id="svg_1948"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.52002,327.59l0,8.97l0,-8.97z"
      />
      <path
        id="svg_1949"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.08002,336.57001l0,-8.98001l0,8.98001z"
      />
      <path
        id="svg_1950"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.08002,327.59l0,-1.23999l0,1.23999z"
      />
      <path
        id="svg_1951"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m575.58002,327.59l0,-1.23999l0,1.23999z"
      />
      <path
        id="svg_1952"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m585.06,327.59l0,-1.23999l0,1.23999z"
      />
      <path
        id="svg_1953"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m585.56,327.59l0,-1.23999l0,1.23999z"
      />
      <path
        id="svg_1954"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,420.89001l0,-30l0,30z"
      />
      <path
        id="svg_1955"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.01001,405.91l0,-0.98999l0,0.98999z"
      />
      <path
        id="svg_1956"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.01001,404.23001l0,-1.37003l0,1.37003z"
      />
      <path
        id="svg_1957"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.01001,402.17001l0,-1.37003l0,1.37003z"
      />
      <path
        id="svg_1958"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.01001,400.12l0,-1.37l0,1.37z"
      />
      <path
        id="svg_1959"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.01001,398.06l0,-1.37l0,1.37z"
      />
      <path
        id="svg_1960"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.01001,396l0,-1.37l0,1.37z"
      />
      <path
        id="svg_1961"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.01001,393.95001l0,-1.37003l0,1.37003z"
      />
      <path
        id="svg_1962"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.01001,391.89001l0,-1l0,1z"
      />
      <path
        id="svg_1963"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.26999,405.91l0,-14.88l0,14.88z"
      />
      <path
        id="svg_1964"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.76001,405.91l0,-0.95999l0,0.95999z"
      />
      <path
        id="svg_1965"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.76001,404.26001l0,-1.37l0,1.37z"
      />
      <path
        id="svg_1966"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.76001,402.20999l0,-1.37l0,1.37z"
      />
      <path
        id="svg_1967"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.76001,400.14999l0,-1.37l0,1.37z"
      />
      <path
        id="svg_1968"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.76001,398.09l0,-1.37l0,1.37z"
      />
      <path
        id="svg_1969"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.76001,396.04001l0,-1.38l0,1.38z"
      />
      <path
        id="svg_1970"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.76001,393.98001l0,-1.37003l0,1.37003z"
      />
      <path
        id="svg_1971"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.76001,391.92001l0,-0.96002l0,0.96002z"
      />
      <path
        id="svg_1972"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m479.01001,420.89001l-1.75,0.17999"
      />
      <path
        id="svg_1973"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.26001,421.07001l-1.68002,0.50998"
      />
      <path
        id="svg_1974"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m475.57999,421.57999l-1.54999,0.83002"
      />
      <path
        id="svg_1975"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m474.03,422.41l-1.35999,1.10999"
      />
      <path
        id="svg_1976"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m472.67001,423.51999l-1.12003,1.36002"
      />
      <path
        id="svg_1977"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m471.54999,424.88l-0.82999,1.56"
      />
      <path
        id="svg_1978"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m470.72,426.44l-0.51001,1.67999"
      />
      <path
        id="svg_1979"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m470.20999,428.12l-0.16998,1.75"
      />
      <path
        id="svg_1980"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m479.01001,429.87l0,-0.44l0,0.44z"
      />
      <path
        id="svg_1981"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m479.01001,429.42999l-8.96002,0"
      />
      <path
        id="svg_1982"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m470.04001,429.87l8.97,0"
      />
      <path
        id="svg_1983"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m479.01001,420.89001l1.25,0"
      />
      <path
        id="svg_1984"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m479.01001,420.39999l1.25,0"
      />
      <path
        id="svg_1985"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,410.14999l35.77997,0"
      />
      <path
        id="svg_1986"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,410.39999l1.09,0"
      />
      <path
        id="svg_1987"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m482.04001,410.39999l1.37,0"
      />
      <path
        id="svg_1988"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m484.09,410.39999l1.37,0"
      />
      <path
        id="svg_1989"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m486.14999,410.39999l1.37,0"
      />
      <path
        id="svg_1990"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m488.20999,410.39999l1.37,0"
      />
      <path
        id="svg_1991"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m490.26001,410.39999l1.37,0"
      />
      <path
        id="svg_1992"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m492.32001,410.39999l1.37,0"
      />
      <path
        id="svg_1993"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m494.38,410.39999l1.37,0"
      />
      <path
        id="svg_1994"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m496.42999,410.39999l1.38,0"
      />
      <path
        id="svg_1995"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m498.48999,410.39999l1.37,0"
      />
      <path
        id="svg_1996"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.54999,410.39999l1.37003,0"
      />
      <path
        id="svg_1997"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m502.60999,410.39999l1.37003,0"
      />
      <path
        id="svg_1998"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m504.66,410.39999l1.37,0"
      />
      <path
        id="svg_1999"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m506.72,410.39999l1.37,0"
      />
      <path
        id="svg_2000"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m508.78,410.39999l1.37,0"
      />
      <path
        id="svg_2001"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m510.82999,410.39999l1.37003,0"
      />
      <path
        id="svg_2002"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m512.89001,410.39999l1.37,0"
      />
      <path
        id="svg_2003"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m514.95001,410.39999l1.08997,0"
      />
      <path
        id="svg_2004"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,411.64999l0.81,0"
      />
      <path
        id="svg_2005"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m481.76001,411.64999l1.37,0"
      />
      <path
        id="svg_2006"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m483.81,411.64999l1.37,0"
      />
      <path
        id="svg_2007"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m485.87,411.64999l1.37,0"
      />
      <path
        id="svg_2008"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m487.92999,411.64999l1.37,0"
      />
      <path
        id="svg_2009"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m489.98001,411.64999l1.37,0"
      />
      <path
        id="svg_2010"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m492.04001,411.64999l1.37,0"
      />
      <path
        id="svg_2011"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m494.10001,411.64999l1.37,0"
      />
      <path
        id="svg_2012"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m496.14999,411.64999l1.38,0"
      />
      <path
        id="svg_2013"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m498.20999,411.64999l1.37,0"
      />
      <path
        id="svg_2014"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.26999,411.64999l1.37003,0"
      />
      <path
        id="svg_2015"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m502.32001,411.64999l1.38,0"
      />
      <path
        id="svg_2016"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m504.38,411.64999l1.37,0"
      />
      <path
        id="svg_2017"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m506.44,411.64999l1.37,0"
      />
      <path
        id="svg_2018"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m508.5,411.64999l1.37,0"
      />
      <path
        id="svg_2019"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m510.54999,411.64999l1.37003,0"
      />
      <path
        id="svg_2020"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m512.60999,411.64999l1.37,0"
      />
      <path
        id="svg_2021"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m514.66998,411.64999l1.37,0"
      />
      <path
        id="svg_2022"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.71997,411.64999l0.81006,0"
      />
      <path
        id="svg_2023"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,413.14001l38.77002,0"
      />
      <path
        id="svg_2024"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.03998,410.14999l0,-39.60001l0,39.60001z"
      />
      <path
        id="svg_2025"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m517.53003,411.64999l0,-41.10001l0,41.10001z"
      />
      <path
        id="svg_2026"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.03998,358.54001l0,-7.44l0,7.44z"
      />
      <path
        id="svg_2027"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m519.03003,413.14001l0,-14.90002l0,14.90002z"
      />
      <path
        id="svg_2028"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m519.03003,398.23999a0.783,0.783 0 0 0 -0.78003,-0.78"
      />
      <path
        id="svg_2029"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m517.28003,358.54001l0,-8.69l0,8.69z"
      />
      <path
        id="svg_2030"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.03998,351.10001l-46.61996,0"
      />
      <path
        id="svg_2031"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m517.28003,349.85001l-47.86002,0"
      />
      <path
        id="svg_2032"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.03998,397.45999l2.21002,0"
      />
      <path
        id="svg_2033"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.03998,358.54001l1.24005,0"
      />
      <path
        id="svg_2034"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.03998,370.54999l1.24005,0"
      />
      <path
        id="svg_2035"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.78,314.38l0,19.66l0,-19.66z"
      />
      <path
        id="svg_2036"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.53,314.38l0,20.89999l0,-20.89999z"
      />
      <path
        id="svg_2037"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.78,334.04001l5.98001,0"
      />
      <path
        id="svg_2038"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.53,335.28l5.98001,0"
      />
      <path
        id="svg_2039"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m506.76001,334.04001l0,5.75998l0,-5.75998z"
      />
      <path
        id="svg_2040"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m506.76001,348.78l0,1.07001l0,-1.07001z"
      />
      <path
        id="svg_2041"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m505.51001,335.28l0,4.51999l0,-4.51999z"
      />
      <path
        id="svg_2042"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m505.51001,348.34l0,1.51001l0,-1.51001z"
      />
      <path
        id="svg_2043"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m505.51001,339.79999l-1.75,0.17001"
      />
      <path
        id="svg_2044"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m503.76001,339.97l-1.68002,0.51999"
      />
      <path
        id="svg_2045"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m502.07999,340.48999l-1.54999,0.82001"
      />
      <path
        id="svg_2046"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.53,341.31l-1.35999,1.12"
      />
      <path
        id="svg_2047"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.17001,342.42999l-1.12003,1.36002"
      />
      <path
        id="svg_2048"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m498.04999,343.79001l-0.82999,1.54999"
      />
      <path
        id="svg_2049"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m497.22,345.34l-0.51001,1.69"
      />
      <path
        id="svg_2050"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m496.70999,347.03l-0.16998,1.75"
      />
      <path
        id="svg_2051"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m505.51001,348.78l0,-0.44l0,0.44z"
      />
      <path
        id="svg_2052"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m505.51001,348.34l-8.96002,0"
      />
      <path
        id="svg_2053"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m496.54001,348.78l8.97,0"
      />
      <path
        id="svg_2054"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m505.51001,348.78l1.25,0"
      />
      <path
        id="svg_2055"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m505.51001,349.28l1.25,0"
      />
      <path
        id="svg_2056"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m505.51001,339.79999l1.25,0"
      />
      <path
        id="svg_2057"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m505.51001,339.29999l1.25,0"
      />
      <path
        id="svg_2058"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.26999,405.91l2.99002,0"
      />
      <path
        id="svg_2059"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m444.42001,383.20001l0,31.15997l0,-31.15997z"
      />
      <path
        id="svg_2060"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,383.23001l0,29.88998l0,-29.88998z"
      />
      <path
        id="svg_2061"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m469.42001,383.20001l0,-64.01001l0,64.01001z"
      />
      <path
        id="svg_2062"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m468.17001,381.95001l0,-62.76001l0,62.76001z"
      />
      <path
        id="svg_2063"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m470.66,384.45001l0,-33.35001l0,33.35001z"
      />
      <path
        id="svg_2064"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.26999,391.03a8.595,8.595 0 0 0 -6.60999,-7.54999"
      />
      <path
        id="svg_2065"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.76001,390.95999l-0.02002,-0.19998"
      />
      <path
        id="svg_2066"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.73999,390.76001l-0.01999,-0.20001"
      />
      <path
        id="svg_2067"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.72,390.56l-0.03,-0.20001"
      />
      <path
        id="svg_2068"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.69,390.35999l-0.03,-0.19998"
      />
      <path
        id="svg_2069"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.66,390.16l0,-0.03l0,0.03z"
      />
      <path
        id="svg_2070"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.51999,389.45999l-0.01999,-0.07999"
      />
      <path
        id="svg_2071"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.5,389.38l-0.06,-0.20001"
      />
      <path
        id="svg_2072"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.44,389.17999l-0.04999,-0.19"
      />
      <path
        id="svg_2073"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.39001,388.98999l-0.06003,-0.19"
      />
      <path
        id="svg_2074"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.32999,388.79999l-0.06,-0.19"
      />
      <path
        id="svg_2075"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.26999,388.60999l-0.06998,-0.18997"
      />
      <path
        id="svg_2076"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.20001,388.42001l-0.07001,-0.19"
      />
      <path
        id="svg_2077"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.13,388.23001l-0.03,-0.08002"
      />
      <path
        id="svg_2078"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.82999,387.51999l-0.01999,-0.03"
      />
      <path
        id="svg_2079"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.81,387.48999l-0.09,-0.17999"
      />
      <path
        id="svg_2080"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.72,387.31l-0.09,-0.17999"
      />
      <path
        id="svg_2081"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.63,387.13l-0.09,-0.17001"
      />
      <path
        id="svg_2082"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.54001,386.95999l-0.10001,-0.17999"
      />
      <path
        id="svg_2083"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.44,386.78l-0.11002,-0.17001"
      />
      <path
        id="svg_2084"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.32999,386.60999l-0.09998,-0.16998"
      />
      <path
        id="svg_2085"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.23001,386.44l-0.07001,-0.11002"
      />
      <path
        id="svg_2086"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.76001,385.76999l-0.11002,-0.14999"
      />
      <path
        id="svg_2087"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.64999,385.62l-0.13,-0.14999"
      />
      <path
        id="svg_2088"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.51999,385.47l-0.12997,-0.16"
      />
      <path
        id="svg_2089"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.39001,385.31l-0.13,-0.14999"
      />
      <path
        id="svg_2090"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.26001,385.16l-0.13,-0.14999"
      />
      <path
        id="svg_2091"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.13,385.01001l-0.14001,-0.14001"
      />
      <path
        id="svg_2092"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m475.98999,384.87l-0.13,-0.13998"
      />
      <path
        id="svg_2093"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m475.35999,384.26999l-0.09998,-0.09"
      />
      <path
        id="svg_2094"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m475.26001,384.17999l-0.15002,-0.13"
      />
      <path
        id="svg_2095"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m475.10999,384.04999l-0.15997,-0.12997"
      />
      <path
        id="svg_2096"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m474.95001,383.92001l-0.16,-0.12003"
      />
      <path
        id="svg_2097"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m474.79001,383.79999l-0.16,-0.12"
      />
      <path
        id="svg_2098"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m474.63,383.67999l-0.16,-0.10999"
      />
      <path
        id="svg_2099"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m474.47,383.57001l-0.17001,-0.12"
      />
      <path
        id="svg_2100"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m474.29999,383.45001l-0.03,-0.02002"
      />
      <path
        id="svg_2101"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.69,383.07999l-0.08002,-0.03998"
      />
      <path
        id="svg_2102"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.60999,383.04001l-0.16998,-0.10001"
      />
      <path
        id="svg_2103"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.44,382.94l-0.17999,-0.09"
      />
      <path
        id="svg_2104"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.26001,382.85001l-0.18002,-0.09"
      />
      <path
        id="svg_2105"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.07999,382.76001l-0.17999,-0.09"
      />
      <path
        id="svg_2106"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m472.89999,382.67001l-0.19,-0.08002"
      />
      <path
        id="svg_2107"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m472.70999,382.59l-0.17999,-0.07999"
      />
      <path
        id="svg_2108"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m472.53,382.51001l-0.07999,-0.03"
      />
      <path
        id="svg_2109"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m471.81,382.25l-0.03,-0.01001"
      />
      <path
        id="svg_2110"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m471.78,382.23999l-0.20001,-0.06"
      />
      <path
        id="svg_2111"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m471.57999,382.17999l-0.18997,-0.04999"
      />
      <path
        id="svg_2112"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m471.39001,382.13l-0.19,-0.05002"
      />
      <path
        id="svg_2113"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m471.20001,382.07999l-0.20001,-0.04999"
      />
      <path
        id="svg_2114"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,390.89001a11.587,11.587 0 0 0 -9.60001,-10.46002"
      />
      <path
        id="svg_2115"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m479.01001,420.89001l0,-14.98001l0,14.98001z"
      />
      <path
        id="svg_2179"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m435.45001,451.56l0,-37.20001l0,37.20001z"
      />
      <path
        id="svg_2180"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.20001,451.56l0,-22.60999l0,22.60999z"
      />
      <path
        id="svg_2181"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.20001,428.20001l0,-15.08002l0,15.08002z"
      />
      <path
        id="svg_2182"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m435.45001,414.35999l8.97,0"
      />
      <path
        id="svg_2183"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.20001,413.12l8.97,0"
      />
      <path
        id="svg_2184"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,398.79001l-1.75,0.17999"
      />
      <path
        id="svg_2185"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m441.42001,398.97l-1.68002,0.51001"
      />
      <path
        id="svg_2186"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m439.73999,399.48001l-1.54999,0.82999"
      />
      <path
        id="svg_2187"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m438.19,400.31l-1.36002,1.11002"
      />
      <path
        id="svg_2188"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m436.82999,401.42001l-1.12,1.35999"
      />
      <path
        id="svg_2189"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m435.70999,402.78l-0.82999,1.56"
      />
      <path
        id="svg_2190"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.88,404.34l-0.51001,1.67999"
      />
      <path
        id="svg_2191"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.37,406.01999l-0.16998,1.75"
      />
      <path
        id="svg_2192"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,407.76999l0,-0.44l0,0.44z"
      />
      <path
        id="svg_2193"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,407.32999l-8.96002,0"
      />
      <path
        id="svg_2194"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.20001,407.76999l8.97,0"
      />
      <path
        id="svg_2195"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,407.76999l1.25,0"
      />
      <path
        id="svg_2196"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,408.26999l1.25,0"
      />
      <path
        id="svg_2197"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,398.79001l-1.75,-0.17001"
      />
      <path
        id="svg_2198"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m441.42001,398.62l-1.68002,-0.51001"
      />
      <path
        id="svg_2199"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m439.73999,398.10999l-1.54999,-0.82999"
      />
      <path
        id="svg_2200"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m438.19,397.28l-1.36002,-1.10999"
      />
      <path
        id="svg_2201"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m436.82999,396.17001l-1.12,-1.36002"
      />
      <path
        id="svg_2202"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m435.70999,394.81l-0.82999,-1.56"
      />
      <path
        id="svg_2203"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.88,393.25l-0.51001,-1.67999"
      />
      <path
        id="svg_2204"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.37,391.57001l-0.16998,-1.75"
      />
      <path
        id="svg_2205"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,389.82001l0,0.44l0,-0.44z"
      />
      <path
        id="svg_2206"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,390.26001l-8.96002,0"
      />
      <path
        id="svg_2207"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.20001,389.82001l8.97,0"
      />
      <path
        id="svg_2208"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,389.82001l1.25,0"
      />
      <path
        id="svg_2209"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,389.32001l1.25,0"
      />
      <path
        id="svg_2210"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m444.42001,383.20001l25,0"
      />
      <path
        id="svg_2211"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m441.62,381.95001l21.70001,0"
      />
      <path
        id="svg_2212"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m464.56,381.95001l3.61002,0"
      />
      <path
        id="svg_2213"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,384.45001l27.48999,0"
      />
      <path
        id="svg_2214"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,384.45001l1.25,0"
      />
      <path
        id="svg_2215"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,384.94l1.25,0"
      />
      <path
        id="svg_2216"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.79999,384.94l0,4.38l0,-4.38z"
      />
      <path
        id="svg_2217"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m443.17001,383.23001l-9.43002,0"
      />
      <path
        id="svg_2265"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m467.73999,289.17001l0,-5.95001l0,5.95001z"
      />
      <path
        id="svg_2276"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m506.76001,334.04001l0.22998,-19.66"
      />
      <path
        id="svg_2508"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m507.69,433.60001l-23.58002,0"
      />
      <path
        id="svg_2509"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.76001,433.60001l-0.5,0"
      />
      <path
        id="svg_2510"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,433.60001l0,-2l0,2z"
      />
      <path
        id="svg_2511"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,431.60001l-14.86002,0"
      />
      <path
        id="svg_2512"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,430.60001l-1.25,0"
      />
      <path
        id="svg_2513"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,430.10999l-1.25,0"
      />
      <path
        id="svg_2514"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m465.39999,431.60001l0,10.19998l0,-10.19998z"
      />
      <path
        id="svg_2515"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m465.39999,441.79999l-3.54999,1.89001"
      />
      <path
        id="svg_2516"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m461.98001,444l-9.97,4.39999"
      />
      <path
        id="svg_2517"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m440.84,451.56a100.786,100.786 0 0 0 11.03,-3.47"
      />
      <path
        id="svg_2518"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m440.84,451.56l-6.63998,0"
      />
      <path
        id="svg_2519"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.20001,451.56l0,2.82001l0,-2.82001z"
      />
      <path
        id="svg_2551"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.73999,434.59l-20.45001,0"
      />
      <path
        id="svg_2552"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,436.59l-19.83002,0"
      />
      <path
        id="svg_2553"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m651.28998,434.59l0,2l0,-2z"
      />
      <path
        id="svg_2554"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m651.28998,436.59l-27.41998,0"
      />
      <path
        id="svg_2555"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.87,436.59l0,-2l0,2z"
      />
      <path
        id="svg_2556"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.87,434.59l-20.44,0"
      />
      <path
        id="svg_2557"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.87,436.59l-20.44,0"
      />
      <path
        id="svg_2558"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.42999,434.59l0,2l0,-2z"
      />
      <path
        id="svg_2559"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.42999,436.59l-27.42999,0"
      />
      <path
        id="svg_2560"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576,436.59l0,-2l0,2z"
      />
      <path
        id="svg_2561"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576,434.59l-2.59003,0"
      />
      <path
        id="svg_2562"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576,436.59l-4.08002,0"
      />
      <path
        id="svg_2563"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m573.40997,434.59l0,-2.98999l0,2.98999z"
      />
      <path
        id="svg_2564"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m571.91998,436.59l0,-2.98999l0,2.98999z"
      />
      <path
        id="svg_2565"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m573.40997,431.60001l-17.84998,0"
      />
      <path
        id="svg_2566"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m571.91998,433.60001l-16.35999,0"
      />
      <path
        id="svg_2567"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m555.56,431.60001l0,2l0,-2z"
      />
      <path
        id="svg_2568"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m555.56,433.60001l-27.42999,0"
      />
      <path
        id="svg_2569"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.13,433.60001l0,-2l0,2z"
      />
      <path
        id="svg_2570"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.13,431.60001l-20.44,0"
      />
      <path
        id="svg_2571"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.13,433.60001l-20.44,0"
      />
      <path
        id="svg_2572"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m507.69,431.60001l0,2l0,-2z"
      />
      <path
        id="svg_2573"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m461.85001,443.69l0.13,0.31"
      />
      <path
        id="svg_2574"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m452.01001,448.39999l-0.14001,-0.31"
      />
      <path
        id="svg_2581"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m435.69,452.79999l0,4.74002l0,-4.74002z"
      />
      <path
        id="svg_2585"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m466.89001,433.60001l0,8.32999l0,-8.32999z"
      />
      <path
        id="svg_2586"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m462.47,445.14999l-9.95001,4.39001"
      />
      <path
        id="svg_2587"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m462.95999,446.29001l-9.94,4.39999"
      />
      <path
        id="svg_2588"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.87,440.45999l-10.91,5.83002"
      />
      <path
        id="svg_2589"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.37,440.16l-10.64999,5.69"
      />
      <path
        id="svg_2590"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m472.63,438.85999l-10.45999,5.59003"
      />
      <path
        id="svg_2591"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m441,452.79999l-5.31,0"
      />
      <path
        id="svg_2592"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m441,452.79999a102.033,102.033 0 0 0 11.38,-3.56998"
      />
      <path
        id="svg_2593"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m445.73001,459.04001l-11.53,0"
      />
      <path
        id="svg_2594"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m445.73001,458.54001l-11.03,0"
      />
      <path
        id="svg_2595"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m444.23001,457.54001l-8.54001,0"
      />
      <path
        id="svg_2613"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,433.60001l-13.37,0"
      />
      <path
        id="svg_2617"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m466.89001,436.09l94.14996,0"
      />
      <path
        id="svg_2618"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.03998,436.09l0,2.98999l0,-2.98999z"
      />
      <path
        id="svg_2619"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.03998,439.07999l14.96002,0"
      />
      <path
        id="svg_2621"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m472.63,436.09l0,2.76999l0,-2.76999z"
      />
      <path
        id="svg_2622"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.87,436.09l0,4.37l0,-4.37z"
      />
      <path
        id="svg_2623"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.37,436.09l0,4.07001l0,-4.07001z"
      />
      <path
        id="svg_2624"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m461.98001,444l0.97998,2.29001"
      />
      <path
        id="svg_2625"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m453.01999,450.69l-1.00998,-2.28"
      />
      <path
        id="svg_2626"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m445.73001,453.14001l0,5.89999l0,-5.89999z"
      />
      <path
        id="svg_2627"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m445.23001,452.76001l0,6.28l0,-6.28z"
      />
      <path
        id="svg_2628"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m444.23001,451.94l0,5.60001l0,-5.60001z"
      />
      <path
        id="svg_2629"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.20001,454.38l0,4.66l0,-4.66z"
      />
      <path
        id="svg_2630"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.70001,451.56l0,6.98001l0,-6.98001z"
      />
      <path
        id="svg_2631"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m445.73001,453.14001a102.033,102.033 0 0 0 7.28998,-2.45001"
      />
      <path
        id="svg_2632"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m445.23001,452.76001a101.534,101.534 0 0 0 7.60999,-2.54001"
      />
      <path
        id="svg_2633"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m447.63,449.92999l1.66,0"
      />
      <path
        id="svg_2634"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m449.29001,449.92999l0,1.53l0,-1.53z"
      />
      <path
        id="svg_2635"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m449.29001,451.45999l-1.66,0"
      />
      <path
        id="svg_2636"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m447.63,451.45999l0,-1.53l0,1.53z"
      />
      <path
        id="svg_2637"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.20001,459.04001l0,0.94l0,-0.94z"
      />
      <path
        id="svg_2638"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m434.20001,459.98001l3.07999,0"
      />
      <path
        id="svg_2639"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m437.28,459.98001l0,-0.94l0,0.94z"
      />
      <path
        id="svg_2706"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m494.54001,431.60001l5.44,0"
      />
      <path
        id="svg_3000"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m437.01999,455.70999l1.55002,0"
      />
      <path
        id="svg_3001"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m438.57001,455.70999l0,1.83002l0,-1.83002z"
      />
      <path
        id="svg_3002"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m438.57001,457.54001l-1.55002,0"
      />
      <path
        id="svg_3003"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m437.01999,457.54001l0,-1.83002l0,1.83002z"
      />
      <path
        id="svg_3526"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m507.69,433.60001l-27.42999,0"
      />
      <path
        id="svg_3527"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m507.69,434.85001l-27.42999,0"
      />
      <path
        id="svg_3528"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m507.69,431.60001l-27.42999,0"
      />
      <path
        id="svg_3529"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.76001,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3530"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m484.10999,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3531"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m484.60999,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3532"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m503.07999,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3533"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m503.57001,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3534"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m507.19,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3535"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m507.69,436.09l-27.42999,0"
      />
      <path
        id="svg_3536"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m507.69,436.09l0,-4.48999l0,4.48999z"
      />
      <path
        id="svg_3537"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,436.09l0,-4.48999l0,4.48999z"
      />
      <path
        id="svg_3538"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m555.56,433.60001l-27.42999,0"
      />
      <path
        id="svg_3539"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m555.56,434.85001l-27.42999,0"
      />
      <path
        id="svg_3540"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m555.56,431.60001l-27.42999,0"
      />
      <path
        id="svg_3541"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.63,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3542"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m531.97998,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3543"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.47998,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3544"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m550.95001,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3545"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m551.44,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3546"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m555.06,433.60001l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3547"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m555.56,436.09l-27.42999,0"
      />
      <path
        id="svg_3548"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m555.56,436.09l0,-4.48999l0,4.48999z"
      />
      <path
        id="svg_3549"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.13,436.09l0,-4.48999l0,4.48999z"
      />
      <path
        id="svg_3550"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.42999,436.59l-27.42999,0"
      />
      <path
        id="svg_3551"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.42999,437.84l-27.42999,0"
      />
      <path
        id="svg_3552"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.42999,434.59l-27.42999,0"
      />
      <path
        id="svg_3553"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.5,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3554"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.84998,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3555"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m580.34998,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3556"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.82001,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3557"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m599.31,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3558"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.92999,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3559"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.42999,439.07999l-27.42999,0"
      />
      <path
        id="svg_3560"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.42999,439.07999l0,-4.48999l0,4.48999z"
      />
      <path
        id="svg_3561"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576,439.07999l0,-4.48999l0,4.48999z"
      />
      <path
        id="svg_3562"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m651.28998,436.59l-27.41998,0"
      />
      <path
        id="svg_3563"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m651.28998,437.84l-27.41998,0"
      />
      <path
        id="svg_3564"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m651.28998,434.59l-27.41998,0"
      />
      <path
        id="svg_3565"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m624.37,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3566"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m627.71997,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3567"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m628.21997,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3568"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m646.67999,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3569"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m647.17999,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3570"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m650.79999,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3571"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m651.28998,439.07999l-27.41998,0"
      />
      <path
        id="svg_3572"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m651.28998,439.07999l0,-4.48999l0,4.48999z"
      />
      <path
        id="svg_3573"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.87,439.07999l0,-4.48999l0,4.48999z"
      />
      <path
        id="svg_3577"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.60999,436.59l0,1.25l0,-1.25z"
      />
      <path
        id="svg_3585"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,439.07999l0,-4.48999l0,4.48999z"
      />
      <path
        id="svg_3811"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m469.23001,342.69l2.87,0"
      />
      <path
        id="svg_3812"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m469.42001,343.92999l1.42999,0"
      />
      <path
        id="svg_3813"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m472.10001,342.69l0,7.16l0,-7.16z"
      />
      <path
        id="svg_3814"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m470.85001,343.92999l0,5.92001l0,-5.92001z"
      />
      <path
        id="svg_3844"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m471.95001,439.22l0.94,1.76001"
      />
      <path
        id="svg_3845"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m467.76999,441.45999l1.14001,1.64999"
      />
      <path
        id="svg_3852"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m659.25,345.26999l3.27002,0"
      />
      <path
        id="svg_3853"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m662.52002,346.81l0,-3.07999l0,3.07999z"
      />
      <path
        id="svg_3854"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m659.25,346.81l0,-3.07999l0,3.07999z"
      />
      <path
        id="svg_4957"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m479.01001,430.10999l0,1.49002l0,-1.49002z"
      />
      <path
        id="svg_4958"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m480.26001,430.10999l0,1.49002l0,-1.49002z"
      />
      <path
        id="svg_4959"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m488.04001,410.14999l0,2.99002l0,-2.99002z"
      />
      <path
        id="svg_4960"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m497.13,410.14999l0,2.99002l0,-2.99002z"
      />
      <path
        id="svg_4961"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m477.26999,393.95001l2.99002,0"
      />
      <path
        id="svg_4962"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m470.16,320.44l13.19998,0"
      />
      <path
        id="svg_4963"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m483.35999,320.44l0,5.76999l0,-5.76999z"
      />
      <path
        id="svg_4964"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m483.35999,326.20999l-13.19998,0"
      />
      <path
        id="svg_4965"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m470.16,326.20999l0,-5.76999l0,5.76999z"
      />
      <path
        id="svg_4966"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m483.35999,323.32001l-1.53998,0"
      />
      <path
        id="svg_4967"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m481.04001,323.32001l-1.55002,0"
      />
      <path
        id="svg_4968"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m478.70999,323.32001l-1.56,0"
      />
      <path
        id="svg_4969"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.37,323.32001l-1.56,0"
      />
      <path
        id="svg_4970"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m474.03,323.32001l-1.56,0"
      />
      <path
        id="svg_4971"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m471.70001,323.32001l-1.54001,0"
      />
      <path
        id="svg_4972"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m475.37,322.13l0,-0.35001l0,0.35001z"
      />
      <path
        id="svg_4973"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m474.35001,322.13l1.01999,0"
      />
      <path
        id="svg_4974"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m474.35001,321.78l0,0.35001l0,-0.35001z"
      />
      <path
        id="svg_4975"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m475.37,321.78l-1.01999,0"
      />
      <path
        id="svg_4976"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.60001,324.62l-0.07001,0.30002"
      />
      <path
        id="svg_4977"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.53,324.92001l-0.17999,0.22"
      />
      <path
        id="svg_4978"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.35001,325.14001l-0.25,0.07999"
      />
      <path
        id="svg_4979"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.60999,325.22l-0.23999,-0.07999"
      />
      <path
        id="svg_4980"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.37,325.14001l-0.19,-0.22"
      />
      <path
        id="svg_4981"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.17999,324.92001l-0.06,-0.30002"
      />
      <path
        id="svg_4982"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.12,323.14001l0.14001,-0.43002"
      />
      <path
        id="svg_4983"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.26001,322.70999l0.34998,-0.16998"
      />
      <path
        id="svg_4984"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.10001,322.54001l0.35001,0.16998"
      />
      <path
        id="svg_4985"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.45001,322.70999l0.14999,0.43002"
      />
      <path
        id="svg_4986"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.60001,324.62l0,-1.47998l0,1.47998z"
      />
      <path
        id="svg_4987"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.60999,325.22l2.49002,0"
      />
      <path
        id="svg_4988"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m473.12,323.14001l0,1.47998l0,-1.47998z"
      />
      <path
        id="svg_4989"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.10001,322.54001l-2.49002,0"
      />
      <path
        id="svg_4990"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.94,325.57001l0,-4.17001l0,4.17001z"
      />
      <path
        id="svg_4991"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m472.78,325.57001l4.16,0"
      />
      <path
        id="svg_4992"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m472.78,321.39999l0,4.17001l0,-4.17001z"
      />
      <path
        id="svg_4993"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m476.94,321.39999l-4.16,0"
      />
      <path
        id="svg_4994"
        fill="black"
        fill-rule="nonzero"
        d="m468.48999,289.95999l0,-0.94998l0.61002,0l0,0.94998l-0.61002,0z"
      />
      <path
        id="svg_4995"
        fill="black"
        fill-rule="nonzero"
        d="m468.48999,288.23001l0,-1.56l0.61002,0l0,1.56l-0.61002,0z"
      />
      <path
        id="svg_4996"
        fill="black"
        fill-rule="nonzero"
        d="m468.48999,285.89001l0,-1.56003l0.61002,0l0,1.56003l-0.61002,0z"
      />
      <path
        id="svg_4997"
        fill="black"
        fill-rule="nonzero"
        d="m468.48999,283.54999l0,-0.94998l0.61002,0l0,0.94998l-0.61002,0z"
      />
      <path
        id="svg_4998"
        fill="black"
        fill-rule="nonzero"
        d="m468.79001,282.29999l1.60999,0l0,0.60001l-1.60999,0l0,-0.60001z"
      />
      <path
        id="svg_4999"
        fill="black"
        fill-rule="nonzero"
        d="m471.17999,282.29999l1.55002,0l0,0.60001l-1.55002,0l0,-0.60001z"
      />
      <path
        id="svg_5000"
        fill="black"
        fill-rule="nonzero"
        d="m473.51001,282.29999l1.56,0l0,0.60001l-1.56,0l0,-0.60001z"
      />
      <path
        id="svg_5001"
        fill="black"
        fill-rule="nonzero"
        d="m475.85001,282.29999l1.56,0l0,0.60001l-1.56,0l0,-0.60001z"
      />
      <path
        id="svg_5002"
        fill="black"
        fill-rule="nonzero"
        d="m478.19,282.29999l1.56,0l0,0.60001l-1.56,0l0,-0.60001z"
      />
      <path
        id="svg_5003"
        fill="black"
        fill-rule="nonzero"
        d="m480.51999,282.29999l1.56,0l0,0.60001l-1.56,0l0,-0.60001z"
      />
      <path
        id="svg_5004"
        fill="black"
        fill-rule="nonzero"
        d="m482.85999,282.29999l1.61002,0l0,0.60001l-1.61002,0l0,-0.60001z"
      />
      <path
        id="svg_5005"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m494.60001,283.97l1.48999,0"
      />
      <path
        id="svg_5006"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m470.16,320.44l0,22.25l0,-22.25z"
      />
      <path
        id="svg_5007"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,283.97l0,1.64999l-0.60999,0l0,-1.64999l0.60999,0z"
      />
      <path
        id="svg_5008"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,286.39999l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5009"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,288.73999l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5010"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,291.07999l0,1.56003l-0.60999,0l0,-1.56003l0.60999,0z"
      />
      <path
        id="svg_5011"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,293.41l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5012"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,295.75l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5013"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,298.09l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5014"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,300.42999l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5015"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,302.76001l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5016"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,305.10001l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5017"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,307.44l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5018"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,309.78l0,1.54999l-0.60999,0l0,-1.54999l0.60999,0z"
      />
      <path
        id="svg_5019"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,312.10999l0,1.56003l-0.60999,0l0,-1.56003l0.60999,0z"
      />
      <path
        id="svg_5020"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,314.45001l0,1.56l-0.60999,0l0,-1.56l0.60999,0z"
      />
      <path
        id="svg_5021"
        fill="black"
        fill-rule="nonzero"
        d="m495.64999,316.79001l0,1.66l-0.60999,0l0,-1.66l0.60999,0z"
      />
      <path
        id="svg_5022"
        fill="black"
        fill-rule="nonzero"
        d="m495.35001,318.75l-0.89001,0l0,-0.60999l0.89001,0l0,0.60999z"
      />
      <path
        id="svg_5023"
        fill="black"
        fill-rule="nonzero"
        d="m493.67999,318.75l-1.56,0l0,-0.60999l1.56,0l0,0.60999z"
      />
      <path
        id="svg_5024"
        fill="black"
        fill-rule="nonzero"
        d="m491.34,318.75l-1.56,0l0,-0.60999l1.56,0l0,0.60999z"
      />
      <path
        id="svg_5025"
        fill="black"
        fill-rule="nonzero"
        d="m489,318.75l-1.56,0l0,-0.60999l1.56,0l0,0.60999z"
      />
      <path
        id="svg_5026"
        fill="black"
        fill-rule="nonzero"
        d="m486.67001,318.75l-1.56003,0l0,-0.60999l1.56003,0l0,0.60999z"
      />
      <path
        id="svg_5027"
        fill="black"
        fill-rule="nonzero"
        d="m484.32999,318.75l-1.56,0l0,-0.60999l1.56,0l0,0.60999z"
      />
      <path
        id="svg_5028"
        fill="black"
        fill-rule="nonzero"
        d="m481.98999,318.75l-1.56,0l0,-0.60999l1.56,0l0,0.60999z"
      />
      <path
        id="svg_5029"
        fill="black"
        fill-rule="nonzero"
        d="m479.64999,318.75l-1.54999,0l0,-0.60999l1.54999,0l0,0.60999z"
      />
      <path
        id="svg_5030"
        fill="black"
        fill-rule="nonzero"
        d="m477.32001,318.75l-1.56,0l0,-0.60999l1.56,0l0,0.60999z"
      />
      <path
        id="svg_5031"
        fill="black"
        fill-rule="nonzero"
        d="m474.98001,318.75l-1.56,0l0,-0.60999l1.56,0l0,0.60999z"
      />
      <path
        id="svg_5032"
        fill="black"
        fill-rule="nonzero"
        d="m472.64001,318.75l-1.56003,0l0,-0.60999l1.56003,0l0,0.60999z"
      />
      <path
        id="svg_5033"
        fill="black"
        fill-rule="nonzero"
        d="m470.29999,318.75l-0.87997,0l0,-0.60999l0.87997,0l0,0.60999z"
      />
      <path
        id="svg_5034"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m502.42999,296.56l0,-13.34l0,13.34z"
      />
      <path
        id="svg_5035"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m502.42999,283.22l-4.84,0"
      />
      <path
        id="svg_5036"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m497.59,283.22l0,13.34l0,-13.34z"
      />
      <path
        id="svg_5037"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m497.59,296.56l4.84,0"
      />
      <path
        id="svg_5038"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.60999,296.56l-0.00998,-1.60999l0.00998,1.60999z"
      />
      <path
        id="svg_5039"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.60001,294.17999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5040"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.60001,291.84l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5041"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.60001,289.5l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5042"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.60001,287.16l0,-1.55002l0,1.55002z"
      />
      <path
        id="svg_5043"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m500.60001,284.82999l0,-1.60999l0,1.60999z"
      />
      <path
        id="svg_5044"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m498.60999,286.73999l-0.37,0"
      />
      <path
        id="svg_5045"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m498.60999,287.82999l0,-1.09l0,1.09z"
      />
      <path
        id="svg_5046"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m498.23999,287.82999l0.37,0"
      />
      <path
        id="svg_5047"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m498.23999,286.73999l0,1.09l0,-1.09z"
      />
      <path
        id="svg_5048"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m501.28,285.42001l0.32999,0.06998"
      />
      <path
        id="svg_5049"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m501.60999,285.48999l0.23001,0.20001"
      />
      <path
        id="svg_5050"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m501.84,285.69l0.09,0.26001"
      />
      <path
        id="svg_5051"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m501.92999,288.62l-0.09,0.26001"
      />
      <path
        id="svg_5052"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m501.84,288.88l-0.23001,0.19998"
      />
      <path
        id="svg_5053"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m501.60999,289.07999l-0.32999,0.07001"
      />
      <path
        id="svg_5054"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.69,289.14999l-0.45001,-0.16"
      />
      <path
        id="svg_5055"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.23999,288.98999l-0.19,-0.37"
      />
      <path
        id="svg_5056"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.04999,285.95001l0.19,-0.38"
      />
      <path
        id="svg_5057"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.23999,285.57001l0.45001,-0.14999"
      />
      <path
        id="svg_5058"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m501.28,285.42001l-1.59,0"
      />
      <path
        id="svg_5059"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m501.92999,288.62l0,-2.66998l0,2.66998z"
      />
      <path
        id="svg_5060"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.69,289.14999l1.59,0"
      />
      <path
        id="svg_5061"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m499.04999,285.95001l0,2.66998l0,-2.66998z"
      />
      <path
        id="svg_5062"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m502.29999,285.04999l-4.45999,0"
      />
      <path
        id="svg_5063"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m502.29999,289.51999l0,-4.47l0,4.47z"
      />
      <path
        id="svg_5064"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m497.84,289.51999l4.45999,0"
      />
      <path
        id="svg_5065"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m497.84,285.04999l0,4.47l0,-4.47z"
      />
      <path
        id="svg_5066"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m497.59,319.19l0,-35.97l0,35.97z"
      />
      <path
        id="svg_5067"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.59998,296.56l0,-13.34l0,13.34z"
      />
      <path
        id="svg_5068"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.59998,283.22l-4.83997,0"
      />
      <path
        id="svg_5069"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m527.76001,283.22l0,13.34l0,-13.34z"
      />
      <path
        id="svg_5070"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m527.76001,296.56l4.83997,0"
      />
      <path
        id="svg_5071"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m530.77002,296.56l0,-1.60999l0,1.60999z"
      />
      <path
        id="svg_5072"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m530.77002,294.17999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5073"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m530.77002,291.84l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5074"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m530.77002,289.5l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5075"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m530.77002,287.16l0,-1.55002l0,1.55002z"
      />
      <path
        id="svg_5076"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m530.77002,284.82999l0,-1.60999l0,1.60999z"
      />
      <path
        id="svg_5077"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.78003,286.73999l-0.37006,0"
      />
      <path
        id="svg_5078"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.78003,287.82999l0,-1.09l0,1.09z"
      />
      <path
        id="svg_5079"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.40997,287.82999l0.37006,0"
      />
      <path
        id="svg_5080"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.40997,286.73999l0,1.09l0,-1.09z"
      />
      <path
        id="svg_5081"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m531.45001,285.42001l0.32001,0.06998"
      />
      <path
        id="svg_5082"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m531.77002,285.48999l0.23999,0.20001"
      />
      <path
        id="svg_5083"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.01001,285.69l0.08997,0.26001"
      />
      <path
        id="svg_5084"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.09998,288.62l-0.08997,0.26001"
      />
      <path
        id="svg_5085"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.01001,288.88l-0.23999,0.19998"
      />
      <path
        id="svg_5086"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m531.77002,289.07999l-0.32001,0.07001"
      />
      <path
        id="svg_5087"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m529.85999,289.14999l-0.45001,-0.16"
      />
      <path
        id="svg_5088"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m529.40997,288.98999l-0.19,-0.37"
      />
      <path
        id="svg_5089"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m529.21997,285.95001l0.19,-0.38"
      />
      <path
        id="svg_5090"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m529.40997,285.57001l0.45001,-0.14999"
      />
      <path
        id="svg_5091"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m531.45001,285.42001l-1.59003,0"
      />
      <path
        id="svg_5092"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.09998,288.62l0,-2.66998l0,2.66998z"
      />
      <path
        id="svg_5093"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m529.85999,289.14999l1.59003,0"
      />
      <path
        id="svg_5094"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m529.21997,285.95001l0,2.66998l0,-2.66998z"
      />
      <path
        id="svg_5095"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.46997,285.04999l-4.45996,0"
      />
      <path
        id="svg_5096"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m532.46997,289.51999l0,-4.47l0,4.47z"
      />
      <path
        id="svg_5097"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.01001,289.51999l4.45996,0"
      />
      <path
        id="svg_5098"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m528.01001,285.04999l0,4.47l0,-4.47z"
      />
      <path
        id="svg_5099"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m507.62,283.22l0,-1.23999l0,1.23999z"
      />
      <path
        id="svg_5100"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m516.59003,283.22l0,-1.23999l0,1.23999z"
      />
      <path
        id="svg_5101"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.70001,431.60001l0,-13.33002l0,13.33002z"
      />
      <path
        id="svg_5102"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.70001,418.26999l-5.91003,0"
      />
      <path
        id="svg_5103"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m558.78998,418.26999l0,13.33002l0,-13.33002z"
      />
      <path
        id="svg_5104"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m558.78998,431.60001l5.91003,0"
      />
      <path
        id="svg_5105"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.78998,431.60001l0,-1.60001l0,1.60001z"
      />
      <path
        id="svg_5106"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.78998,429.22l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5107"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.78998,426.88l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5108"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.78998,424.54999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5109"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.78998,422.20999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5110"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.78998,419.87l0,-1.60001l0,1.60001z"
      />
      <path
        id="svg_5111"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.04999,426.67001l0.31,0"
      />
      <path
        id="svg_5112"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.04999,427.57001l0,-0.89999l0,0.89999z"
      />
      <path
        id="svg_5113"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.35999,427.57001l-0.31,0"
      />
      <path
        id="svg_5114"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.35999,426.67001l0,0.89999l0,-0.89999z"
      />
      <path
        id="svg_5115"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.84998,425.57999l-0.25995,0.06003"
      />
      <path
        id="svg_5116"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.59003,425.64001l-0.20001,0.15997"
      />
      <path
        id="svg_5117"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.39001,425.79999l-0.07001,0.22"
      />
      <path
        id="svg_5118"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.32001,428.22l0.07001,0.22"
      />
      <path
        id="svg_5119"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.39001,428.44l0.20001,0.16"
      />
      <path
        id="svg_5120"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.59003,428.60001l0.25995,0.06"
      />
      <path
        id="svg_5121"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.15997,428.66l0.38,-0.13"
      />
      <path
        id="svg_5122"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.53998,428.53l0.15002,-0.31"
      />
      <path
        id="svg_5123"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.69,426.01999l-0.15002,-0.31"
      />
      <path
        id="svg_5124"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.53998,425.70999l-0.38,-0.13"
      />
      <path
        id="svg_5125"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.84998,425.57999l1.31,0"
      />
      <path
        id="svg_5126"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.32001,428.22l0,-2.20001l0,2.20001z"
      />
      <path
        id="svg_5127"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.15997,428.66l-1.31,0"
      />
      <path
        id="svg_5128"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.69,426.01999l0,2.20001l0,-2.20001z"
      />
      <path
        id="svg_5129"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.01001,425.28l3.67999,0"
      />
      <path
        id="svg_5130"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.01001,428.95999l0,-3.67999l0,3.67999z"
      />
      <path
        id="svg_5131"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.69,428.95999l-3.67999,0"
      />
      <path
        id="svg_5132"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.69,425.28l0,3.67999l0,-3.67999z"
      />
      <path
        id="svg_5133"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.82001,383.73999l0,1.99002l0,-1.99002z"
      />
      <path
        id="svg_5134"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.82001,383.73999l0,1.99002l0,-1.99002z"
      />
      <path
        id="svg_5135"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m548.82001,327.59l0,-1.23999l0,1.23999z"
      />
      <path
        id="svg_5136"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.82001,327.59l0,-1.23999l0,1.23999z"
      />
      <path
        id="svg_5137"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.79999,296.56l0,-13.34l0,13.34z"
      />
      <path
        id="svg_5138"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.79999,283.22l-5.38,0"
      />
      <path
        id="svg_5139"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m559.41998,283.22l0,13.34l0,-13.34z"
      />
      <path
        id="svg_5140"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m559.41998,296.56l5.38,0"
      />
      <path
        id="svg_5141"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.44,296.56l0,-1.60999l0,1.60999z"
      />
      <path
        id="svg_5142"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.44,294.17999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5143"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.44,291.84l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5144"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.44,289.5l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5145"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.44,287.16l0,-1.55002l0,1.55002z"
      />
      <path
        id="svg_5146"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m562.44,284.82999l0,-1.60999l0,1.60999z"
      />
      <path
        id="svg_5147"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.45001,286.73999l-0.38,0"
      />
      <path
        id="svg_5148"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.45001,287.82999l0,-1.09l0,1.09z"
      />
      <path
        id="svg_5149"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.07001,287.82999l0.38,0"
      />
      <path
        id="svg_5150"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.07001,286.73999l0,1.09l0,-1.09z"
      />
      <path
        id="svg_5151"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.12,285.42001l0.32001,0.06998"
      />
      <path
        id="svg_5152"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.44,285.48999l0.22998,0.20001"
      />
      <path
        id="svg_5153"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.66998,285.69l0.09003,0.26001"
      />
      <path
        id="svg_5154"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.76001,288.62l-0.09003,0.26001"
      />
      <path
        id="svg_5155"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.66998,288.88l-0.22998,0.19998"
      />
      <path
        id="svg_5156"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.44,289.07999l-0.32001,0.07001"
      />
      <path
        id="svg_5157"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.53003,289.14999l-0.46002,-0.16"
      />
      <path
        id="svg_5158"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.07001,288.98999l-0.19,-0.37"
      />
      <path
        id="svg_5159"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.88,285.95001l0.19,-0.38"
      />
      <path
        id="svg_5160"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.07001,285.57001l0.46002,-0.14999"
      />
      <path
        id="svg_5161"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.12,285.42001l-1.58997,0"
      />
      <path
        id="svg_5162"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.76001,288.62l0,-2.66998l0,2.66998z"
      />
      <path
        id="svg_5163"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m561.53003,289.14999l1.58997,0"
      />
      <path
        id="svg_5164"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m560.88,285.95001l0,2.66998l0,-2.66998z"
      />
      <path
        id="svg_5165"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.13,285.04999l-4.46002,0"
      />
      <path
        id="svg_5166"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m564.13,289.51999l0,-4.47l0,4.47z"
      />
      <path
        id="svg_5167"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m559.66998,289.51999l4.46002,0"
      />
      <path
        id="svg_5168"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m559.66998,285.04999l0,4.47l0,-4.47z"
      />
      <path
        id="svg_5213"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.21002,296.56l0,-13.34l0,13.34z"
      />
      <path
        id="svg_5214"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.21002,283.22l-5.38,0"
      />
      <path
        id="svg_5215"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.83002,283.22l0,13.34l0,-13.34z"
      />
      <path
        id="svg_5216"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.83002,296.56l5.38,0"
      />
      <path
        id="svg_5217"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.84998,296.56l0,-1.60999l0,1.60999z"
      />
      <path
        id="svg_5218"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.84998,294.17999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5219"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.84998,291.84l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5220"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.84998,289.5l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5221"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.84998,287.16l0,-1.55002l0,1.55002z"
      />
      <path
        id="svg_5222"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.84998,284.82999l0,-1.60999l0,1.60999z"
      />
      <path
        id="svg_5223"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.85999,286.73999l-0.38,0"
      />
      <path
        id="svg_5224"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.85999,287.82999l0,-1.09l0,1.09z"
      />
      <path
        id="svg_5225"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.47998,287.82999l0.38,0"
      />
      <path
        id="svg_5226"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.47998,286.73999l0,1.09l0,-1.09z"
      />
      <path
        id="svg_5227"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.53003,285.42001l0.31995,0.06998"
      />
      <path
        id="svg_5228"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.84998,285.48999l0.23004,0.20001"
      />
      <path
        id="svg_5229"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.08002,285.69l0.08997,0.26001"
      />
      <path
        id="svg_5230"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.16998,288.62l-0.08997,0.26001"
      />
      <path
        id="svg_5231"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.08002,288.88l-0.23004,0.19998"
      />
      <path
        id="svg_5232"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.84998,289.07999l-0.31995,0.07001"
      />
      <path
        id="svg_5233"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.94,289.14999l-0.46002,-0.16"
      />
      <path
        id="svg_5234"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.47998,288.98999l-0.19,-0.37"
      />
      <path
        id="svg_5235"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.28998,285.95001l0.19,-0.38"
      />
      <path
        id="svg_5236"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.47998,285.57001l0.46002,-0.14999"
      />
      <path
        id="svg_5237"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.53003,285.42001l-1.59003,0"
      />
      <path
        id="svg_5238"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.16998,288.62l0,-2.66998l0,2.66998z"
      />
      <path
        id="svg_5239"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.94,289.14999l1.59003,0"
      />
      <path
        id="svg_5240"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.28998,285.95001l0,2.66998l0,-2.66998z"
      />
      <path
        id="svg_5241"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.53998,285.04999l-4.45996,0"
      />
      <path
        id="svg_5242"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.53998,289.51999l0,-4.47l0,4.47z"
      />
      <path
        id="svg_5243"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.08002,289.51999l4.45996,0"
      />
      <path
        id="svg_5244"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.08002,285.04999l0,4.47l0,-4.47z"
      />
      <path
        id="svg_5245"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.67999,345.26999l3.27002,0"
      />
      <path
        id="svg_5246"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m566.95001,346.81l0,-3.07999l0,3.07999z"
      />
      <path
        id="svg_5247"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.67999,346.81l0,-3.07999l0,3.07999z"
      />
      <path
        id="svg_5248"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.67999,343.73001l9.57001,0"
      />
      <path
        id="svg_5249"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m573.25,343.73001l0,3.07999l0,-3.07999z"
      />
      <path
        id="svg_5250"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m573.25,346.81l-9.56,0"
      />
      <path
        id="svg_5251"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m563.69,346.81l-0.01001,-3.07999l0.01001,3.07999z"
      />
      <path
        id="svg_5252"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.14001,434.59l0,-13.32999l0,13.32999z"
      />
      <path
        id="svg_5253"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.14001,421.26001l-5.91003,0"
      />
      <path
        id="svg_5254"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.22998,421.26001l0,13.32999l0,-13.32999z"
      />
      <path
        id="svg_5255"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.22998,434.59l5.91003,0"
      />
      <path
        id="svg_5256"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.22998,434.59l0,-1.60001l0,1.60001z"
      />
      <path
        id="svg_5257"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.22998,432.20999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5258"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.22998,429.88l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5259"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.22998,427.54001l-0.01001,-1.56l0.01001,1.56z"
      />
      <path
        id="svg_5260"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.21997,425.20001l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5261"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.21997,422.85999l0,-1.59998l0,1.59998z"
      />
      <path
        id="svg_5262"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.48999,429.66l0.31,0"
      />
      <path
        id="svg_5263"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.48999,430.56l0,-0.89999l0,0.89999z"
      />
      <path
        id="svg_5264"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.79999,430.56l-0.31,0"
      />
      <path
        id="svg_5265"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.79999,429.66l0,0.89999l0,-0.89999z"
      />
      <path
        id="svg_5266"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.28998,428.57001l-0.26996,0.06"
      />
      <path
        id="svg_5267"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.02002,428.63l-0.19,0.16"
      />
      <path
        id="svg_5268"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.83002,428.79001l-0.07001,0.22"
      />
      <path
        id="svg_5269"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.76001,431.20999l0.07001,0.22"
      />
      <path
        id="svg_5270"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.83002,431.42999l0.19,0.16"
      />
      <path
        id="svg_5271"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.02002,431.59l0.26996,0.06"
      />
      <path
        id="svg_5272"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.59998,431.64999l0.37,-0.13"
      />
      <path
        id="svg_5273"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.96997,431.51999l0.16003,-0.31"
      />
      <path
        id="svg_5274"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.13,429.01001l-0.16003,-0.31"
      />
      <path
        id="svg_5275"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.96997,428.70001l-0.37,-0.13"
      />
      <path
        id="svg_5276"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.28998,428.57001l1.31,0"
      />
      <path
        id="svg_5277"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.76001,431.20999l0,-2.19998l0,2.19998z"
      />
      <path
        id="svg_5278"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.59998,431.64999l-1.31,0"
      />
      <path
        id="svg_5279"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.13,429.01001l0,2.19998l0,-2.19998z"
      />
      <path
        id="svg_5280"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.45001,428.26999l3.67999,0"
      />
      <path
        id="svg_5281"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.45001,431.95001l0,-3.68002l0,3.68002z"
      />
      <path
        id="svg_5282"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.13,431.95001l-3.67999,0"
      />
      <path
        id="svg_5283"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.13,428.26999l0,3.68002l0,-3.68002z"
      />
      <path
        id="svg_5284"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m626.73999,434.59l0,-13.32999l0,13.32999z"
      />
      <path
        id="svg_5285"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m626.73999,421.26001l-5.90997,0"
      />
      <path
        id="svg_5286"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m620.83002,421.26001l0,13.32999l0,-13.32999z"
      />
      <path
        id="svg_5287"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m620.83002,434.59l5.90997,0"
      />
      <path
        id="svg_5288"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.83002,434.59l0,-1.60001l0,1.60001z"
      />
      <path
        id="svg_5289"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.83002,432.20999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5290"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.83002,429.88l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5291"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.83002,427.54001l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5292"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.83002,425.20001l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5293"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m623.83002,422.85999l0,-1.59998l0,1.59998z"
      />
      <path
        id="svg_5294"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.09003,429.66l0.31,0"
      />
      <path
        id="svg_5295"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.09003,430.56l0,-0.89999l0,0.89999z"
      />
      <path
        id="svg_5296"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.40002,430.56l-0.31,0"
      />
      <path
        id="svg_5297"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.40002,429.66l0,0.89999l0,-0.89999z"
      />
      <path
        id="svg_5298"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.89001,428.57001l-0.26001,0.06"
      />
      <path
        id="svg_5299"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.63,428.63l-0.19,0.16"
      />
      <path
        id="svg_5300"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.44,428.79001l-0.08002,0.22"
      />
      <path
        id="svg_5301"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.35999,431.20999l0.08002,0.22"
      />
      <path
        id="svg_5302"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.44,431.42999l0.19,0.16"
      />
      <path
        id="svg_5303"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.63,431.59l0.26001,0.06"
      />
      <path
        id="svg_5304"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m624.20001,431.64999l0.38,-0.13"
      />
      <path
        id="svg_5305"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m624.58002,431.51999l0.14996,-0.31"
      />
      <path
        id="svg_5306"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m624.72998,429.01001l-0.14996,-0.31"
      />
      <path
        id="svg_5307"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m624.58002,428.70001l-0.38,-0.13"
      />
      <path
        id="svg_5308"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.89001,428.57001l1.31,0"
      />
      <path
        id="svg_5309"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.35999,431.20999l0,-2.19998l0,2.19998z"
      />
      <path
        id="svg_5310"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m624.20001,431.64999l-1.31,0"
      />
      <path
        id="svg_5311"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m624.72998,429.01001l0,2.19998l0,-2.19998z"
      />
      <path
        id="svg_5312"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.06,428.26999l3.66998,0"
      />
      <path
        id="svg_5313"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m622.06,431.95001l0,-3.68002l0,3.68002z"
      />
      <path
        id="svg_5314"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.72998,431.95001l-3.66998,0"
      />
      <path
        id="svg_5315"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m625.72998,428.26999l0,3.68002l0,-3.68002z"
      />
      <path
        id="svg_5316"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m574.98999,353.76999l6.04004,0"
      />
      <path
        id="svg_5317"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m581.03003,353.76999l0,29.97l0,-29.97z"
      />
      <path
        id="svg_5318"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m581.03003,383.73999l-6.04004,0"
      />
      <path
        id="svg_5319"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m574.98999,383.73999l0,-29.97l0,29.97z"
      />
      <path
        id="svg_5320"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m574.98999,379.62l6.04004,0"
      />
      <path
        id="svg_5321"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,383.73999l0,-1.73999l0,1.73999z"
      />
      <path
        id="svg_5322"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,381.22l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5323"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,378.88l0,-1.55002l0,1.55002z"
      />
      <path
        id="svg_5324"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,376.54999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5325"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,374.20999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5326"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,371.87l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5327"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,369.53l0,-1.54999l0,1.54999z"
      />
      <path
        id="svg_5328"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,367.20001l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5329"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,364.85999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5330"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,362.51999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5331"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,360.17999l0,-1.54999l0,1.54999z"
      />
      <path
        id="svg_5332"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,357.85001l0,-1.56l0,1.56z"
      />
      <path
        id="svg_5333"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m578.01001,355.51001l0,-1.74002l0,1.74002z"
      />
      <path
        id="svg_5334"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.96997,362.32001l-0.31,0"
      />
      <path
        id="svg_5335"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.96997,363.22l0,-0.89999l0,0.89999z"
      />
      <path
        id="svg_5336"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.65997,363.22l0.31,0"
      />
      <path
        id="svg_5337"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.65997,362.32001l0,0.89999l0,-0.89999z"
      />
      <path
        id="svg_5338"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.16998,361.23001l0.27002,0.06"
      />
      <path
        id="svg_5339"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.44,361.29001l0.19,0.16998"
      />
      <path
        id="svg_5340"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.63,361.45999l0.07001,0.22"
      />
      <path
        id="svg_5341"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.70001,363.87l-0.07001,0.22"
      />
      <path
        id="svg_5342"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.63,364.09l-0.19,0.16"
      />
      <path
        id="svg_5343"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.44,364.25l-0.27002,0.06"
      />
      <path
        id="svg_5344"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m577.85999,364.31l-0.37,-0.13"
      />
      <path
        id="svg_5345"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m577.48999,364.17999l-0.15997,-0.31"
      />
      <path
        id="svg_5346"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m577.33002,361.67999l0.15997,-0.32001"
      />
      <path
        id="svg_5347"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m577.48999,361.35999l0.37,-0.12997"
      />
      <path
        id="svg_5348"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.16998,361.23001l-1.31,0"
      />
      <path
        id="svg_5349"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m579.70001,363.87l0,-2.19l0,2.19z"
      />
      <path
        id="svg_5350"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m577.85999,364.31l1.31,0"
      />
      <path
        id="svg_5351"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m577.33002,361.67999l0,2.19l0,-2.19z"
      />
      <path
        id="svg_5352"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m580.01001,360.92999l-3.67999,0"
      />
      <path
        id="svg_5353"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m580.01001,364.60999l0,-3.67999l0,3.67999z"
      />
      <path
        id="svg_5354"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.33002,364.60999l3.67999,0"
      />
      <path
        id="svg_5355"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m576.33002,360.92999l0,3.67999l0,-3.67999z"
      />
      <path
        id="svg_5356"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.45001,343.39001l-0.52002,-0.07001"
      />
      <path
        id="svg_5357"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.92999,343.32001l-0.39001,-0.05002"
      />
      <path
        id="svg_5358"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.53998,343.26999l-0.32996,-0.06"
      />
      <path
        id="svg_5359"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.21002,343.20999l-0.29004,-0.04999"
      />
      <path
        id="svg_5360"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.91998,343.16l-0.21997,-0.06"
      />
      <path
        id="svg_5361"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.70001,343.10001l-0.17999,-0.04001"
      />
      <path
        id="svg_5362"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.52002,343.06l-0.11005,-0.03"
      />
      <path
        id="svg_5363"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.40997,343.03l-0.09998,-0.03"
      />
      <path
        id="svg_5364"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.31,343l-0.09998,-0.01001"
      />
      <path
        id="svg_5365"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.21002,342.98999l-0.13,-0.00998"
      />
      <path
        id="svg_5366"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.08002,342.98001l-0.17999,-0.01001"
      />
      <path
        id="svg_5367"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.90002,342.97l-0.23004,0"
      />
      <path
        id="svg_5368"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.66998,342.97l-0.25,0.01999"
      />
      <path
        id="svg_5369"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.41998,342.98999l-0.27997,0.03"
      />
      <path
        id="svg_5370"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.14001,343.01999l-0.29004,0.07001"
      />
      <path
        id="svg_5371"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.84998,343.09l-0.28998,0.08002"
      />
      <path
        id="svg_5372"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.56,343.17001l-0.27997,0.12"
      />
      <path
        id="svg_5373"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.28003,343.29001l-0.27002,0.14999"
      />
      <path
        id="svg_5374"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.01001,343.44l-0.22998,0.16998"
      />
      <path
        id="svg_5375"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.78003,343.60999l-0.21002,0.19"
      />
      <path
        id="svg_5376"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.57001,343.79999l-0.16003,0.21002"
      />
      <path
        id="svg_5377"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.40997,344.01001l-0.12,0.20999"
      />
      <path
        id="svg_5378"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.28998,344.22l-0.07001,0.20999"
      />
      <path
        id="svg_5379"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.21997,344.42999l-0.02997,0.20001"
      />
      <path
        id="svg_5380"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.19,344.63l0.02997,0.20999"
      />
      <path
        id="svg_5381"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.21997,344.84l0.07001,0.20999"
      />
      <path
        id="svg_5382"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.28998,345.04999l0.12,0.20001"
      />
      <path
        id="svg_5383"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.40997,345.25l0.16003,0.20999"
      />
      <path
        id="svg_5384"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.57001,345.45999l0.21002,0.19"
      />
      <path
        id="svg_5385"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m591.78003,345.64999l0.22998,0.17001"
      />
      <path
        id="svg_5386"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.01001,345.82001l0.27002,0.14999"
      />
      <path
        id="svg_5387"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.28003,345.97l0.27997,0.12"
      />
      <path
        id="svg_5388"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.56,346.09l0.28998,0.09"
      />
      <path
        id="svg_5389"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m592.84998,346.17999l0.29004,0.06"
      />
      <path
        id="svg_5390"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.14001,346.23999l0.27997,0.04001"
      />
      <path
        id="svg_5391"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.41998,346.28l0.26001,0.01001"
      />
      <path
        id="svg_5392"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.67999,346.29001l0.22003,0"
      />
      <path
        id="svg_5393"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m593.90002,346.29001l0.19,-0.01001"
      />
      <path
        id="svg_5394"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.09003,346.28l0.12,-0.01001"
      />
      <path
        id="svg_5395"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.21002,346.26999l0.09998,-0.00998"
      />
      <path
        id="svg_5396"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.31,346.26001l0.09998,-0.02002"
      />
      <path
        id="svg_5397"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.40997,346.23999l0.12006,-0.03"
      />
      <path
        id="svg_5398"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.53003,346.20999l0.16998,-0.04999"
      />
      <path
        id="svg_5399"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.70001,346.16l0.22998,-0.05002"
      />
      <path
        id="svg_5400"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.92999,346.10999l0.28003,-0.06"
      />
      <path
        id="svg_5401"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.21002,346.04999l0.32996,-0.06"
      />
      <path
        id="svg_5402"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.53998,345.98999l0.39001,-0.04999"
      />
      <path
        id="svg_5403"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.92999,345.94l0.41003,-0.04001"
      />
      <path
        id="svg_5404"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.34003,345.89999l0.10999,-0.00998"
      />
      <path
        id="svg_5405"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.59003,346.32999l0.00995,0.04001"
      />
      <path
        id="svg_5406"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.59998,346.37l0.02002,0.03"
      />
      <path
        id="svg_5407"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.62,346.39999l0.01001,0.04001"
      />
      <path
        id="svg_5408"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.63,346.44l0.01001,0.03"
      />
      <path
        id="svg_5409"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.64001,346.47l0.01996,0.01999"
      />
      <path
        id="svg_5410"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.65997,346.48999l0.02002,0.03"
      />
      <path
        id="svg_5411"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.67999,346.51999l0.02002,0.04001"
      />
      <path
        id="svg_5412"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.70001,346.56l0.02997,0.03"
      />
      <path
        id="svg_5413"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.72998,346.59l0.04004,0.04001"
      />
      <path
        id="svg_5414"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.77002,346.63l0.03998,0.04999"
      />
      <path
        id="svg_5415"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.81,346.67999l0.04999,0.04001"
      />
      <path
        id="svg_5416"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.85999,346.72l0.06,0.04999"
      />
      <path
        id="svg_5417"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.91998,346.76999l0.04999,0.04001"
      />
      <path
        id="svg_5418"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.96997,346.81l0.07001,0.04001"
      />
      <path
        id="svg_5419"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.03998,346.85001l0.06,0.04001"
      />
      <path
        id="svg_5420"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.09998,346.89001l0.07001,0.03"
      />
      <path
        id="svg_5421"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.16998,346.92001l0.07001,0.03"
      />
      <path
        id="svg_5422"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.23999,346.95001l0.07001,0.01999"
      />
      <path
        id="svg_5423"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.31,346.97l0.07001,0.01001"
      />
      <path
        id="svg_5424"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.38,346.98001l0.07001,0.00998"
      />
      <path
        id="svg_5425"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.45001,346.98999l0.07001,0"
      />
      <path
        id="svg_5426"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.52002,346.98999l0.07001,0.01001"
      />
      <path
        id="svg_5427"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.59003,347l0.06995,0"
      />
      <path
        id="svg_5428"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.65997,347l0.07001,-0.01001"
      />
      <path
        id="svg_5429"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.72998,346.98999l0.06,0"
      />
      <path
        id="svg_5430"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.78998,346.98999l0.06,0"
      />
      <path
        id="svg_5431"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.84998,346.98999l0.06,0"
      />
      <path
        id="svg_5432"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.90997,346.98999l0.06,0"
      />
      <path
        id="svg_5433"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.96997,346.98999l0.05005,-0.00998"
      />
      <path
        id="svg_5434"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.02002,346.98001l0.04999,0"
      />
      <path
        id="svg_5435"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.07001,346.98001l0.04999,-0.01001"
      />
      <path
        id="svg_5436"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.12,346.97l0.03998,0"
      />
      <path
        id="svg_5437"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.15997,346.97l0.04004,-0.01001"
      />
      <path
        id="svg_5438"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.20001,346.95999l0.03998,-0.00998"
      />
      <path
        id="svg_5439"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.23999,346.95001l0.03003,-0.01001"
      />
      <path
        id="svg_5440"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.27002,346.94l0.02997,-0.01999"
      />
      <path
        id="svg_5441"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.29999,346.92001l0.03003,-0.02002"
      />
      <path
        id="svg_5442"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.33002,346.89999l0.01996,-0.01999"
      />
      <path
        id="svg_5443"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.34998,346.88l0.03003,-0.04001"
      />
      <path
        id="svg_5444"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.38,346.84l0.02002,-0.04001"
      />
      <path
        id="svg_5445"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.40002,346.79999l0.01996,-0.04999"
      />
      <path
        id="svg_5446"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.41998,346.75l0.01001,-0.06"
      />
      <path
        id="svg_5447"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.42999,346.69l0.01001,-0.06"
      />
      <path
        id="svg_5448"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.44,346.63l0.01001,-0.08002"
      />
      <path
        id="svg_5449"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.45001,346.54999l0.01001,-0.09998l-0.01001,0.09998z"
      />
      <path
        id="svg_5450"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.46002,346.45001l0.00995,-0.10001l-0.00995,0.10001z"
      />
      <path
        id="svg_5451"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.46997,346.35001l0.01001,-0.12l-0.01001,0.12z"
      />
      <path
        id="svg_5452"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.47998,346.23001l0.01001,-0.13l-0.01001,0.13z"
      />
      <path
        id="svg_5453"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.48999,346.10001l0,-0.14999l0,0.14999z"
      />
      <path
        id="svg_5454"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.48999,345.95001l0.01001,-0.16l-0.01001,0.16z"
      />
      <path
        id="svg_5455"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.5,345.79001l0,-0.18002l0,0.18002z"
      />
      <path
        id="svg_5456"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.5,345.60999l0.01001,-0.17999l-0.01001,0.17999z"
      />
      <path
        id="svg_5457"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.51001,345.42999l0,-0.19l0,0.19z"
      />
      <path
        id="svg_5458"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.51001,345.23999l0,-0.19998l0,0.19998z"
      />
      <path
        id="svg_5459"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.51001,345.04001l0,-0.20001l0,0.20001z"
      />
      <path
        id="svg_5460"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.51001,344.84l0,-0.20999l0,0.20999z"
      />
      <path
        id="svg_5461"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.51001,344.63l0,-0.20001l0,0.20001z"
      />
      <path
        id="svg_5462"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.51001,344.42999l0,-0.19998l0,0.19998z"
      />
      <path
        id="svg_5463"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.51001,344.23001l0,-0.20001l0,0.20001z"
      />
      <path
        id="svg_5464"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.51001,344.03l0,-0.20001l0,0.20001z"
      />
      <path
        id="svg_5465"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.51001,343.82999l-0.01001,-0.17999l0.01001,0.17999z"
      />
      <path
        id="svg_5466"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.5,343.64999l0,-0.16998l0,0.16998z"
      />
      <path
        id="svg_5467"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.5,343.48001l-0.01001,-0.17001l0.01001,0.17001z"
      />
      <path
        id="svg_5468"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.48999,343.31l0,-0.14999l0,0.14999z"
      />
      <path
        id="svg_5469"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.48999,343.16l-0.01001,-0.13l0.01001,0.13z"
      />
      <path
        id="svg_5470"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.47998,343.03l-0.01001,-0.12l0.01001,0.12z"
      />
      <path
        id="svg_5471"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.46997,342.91l-0.00995,-0.10001l0.00995,0.10001z"
      />
      <path
        id="svg_5472"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.46002,342.81l-0.01001,-0.09"
      />
      <path
        id="svg_5473"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.45001,342.72l-0.01001,-0.07999"
      />
      <path
        id="svg_5474"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.44,342.64001l-0.02002,-0.07001"
      />
      <path
        id="svg_5475"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.41998,342.57001l-0.01001,-0.06"
      />
      <path
        id="svg_5476"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.40997,342.51001l-0.01996,-0.05002"
      />
      <path
        id="svg_5477"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.39001,342.45999l-0.02002,-0.03998"
      />
      <path
        id="svg_5478"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.37,342.42001l-0.02002,-0.03"
      />
      <path
        id="svg_5479"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.34998,342.39001l-0.02997,-0.03003"
      />
      <path
        id="svg_5480"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.32001,342.35999l-0.02002,-0.01999"
      />
      <path
        id="svg_5481"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.29999,342.34l-0.02997,-0.01001"
      />
      <path
        id="svg_5482"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.27002,342.32999l-0.04004,-0.00998"
      />
      <path
        id="svg_5483"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.22998,342.32001l-0.02997,-0.01001"
      />
      <path
        id="svg_5484"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.20001,342.31l-0.04004,-0.01001"
      />
      <path
        id="svg_5485"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.15997,342.29999l-0.04999,0"
      />
      <path
        id="svg_5486"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.10999,342.29999l-0.03998,-0.00998"
      />
      <path
        id="svg_5487"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.07001,342.29001l-0.04999,0"
      />
      <path
        id="svg_5488"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.02002,342.29001l-0.06,-0.01001"
      />
      <path
        id="svg_5489"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.96002,342.28l-0.05005,0"
      />
      <path
        id="svg_5490"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.90997,342.28l-0.06,0"
      />
      <path
        id="svg_5491"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.84998,342.28l-0.06,-0.01001"
      />
      <path
        id="svg_5492"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.78998,342.26999l-0.07001,0"
      />
      <path
        id="svg_5493"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.71997,342.26999l-0.06,0"
      />
      <path
        id="svg_5494"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.65997,342.26999l-0.06995,0"
      />
      <path
        id="svg_5495"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.59003,342.26999l-0.07001,0.01001"
      />
      <path
        id="svg_5496"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.52002,342.28l-0.07001,0"
      />
      <path
        id="svg_5497"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.45001,342.28l-0.07001,0.01001"
      />
      <path
        id="svg_5498"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.38,342.29001l-0.07001,0.01999"
      />
      <path
        id="svg_5499"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.31,342.31l-0.07001,0.01001"
      />
      <path
        id="svg_5500"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.23999,342.32001l-0.07001,0.03"
      />
      <path
        id="svg_5501"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.16998,342.35001l-0.06,0.03"
      />
      <path
        id="svg_5502"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.10999,342.38l-0.07001,0.04001"
      />
      <path
        id="svg_5503"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.03998,342.42001l-0.06,0.03998"
      />
      <path
        id="svg_5504"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.97998,342.45999l-0.06,0.05002"
      />
      <path
        id="svg_5505"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.91998,342.51001l-0.06,0.04999"
      />
      <path
        id="svg_5506"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.85999,342.56l-0.04999,0.06"
      />
      <path
        id="svg_5507"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.81,342.62l-0.04999,0.06"
      />
      <path
        id="svg_5508"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.76001,342.67999l-0.04004,0.06"
      />
      <path
        id="svg_5509"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.71997,342.73999l-0.04999,0.06"
      />
      <path
        id="svg_5510"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.66998,342.79999l-0.02997,0.07001"
      />
      <path
        id="svg_5511"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.64001,342.87l-0.03003,0.06"
      />
      <path
        id="svg_5512"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.60999,342.92999l-0.02997,0.07001"
      />
      <path
        id="svg_5513"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.58002,343l-0.03003,0.07001"
      />
      <path
        id="svg_5514"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.54999,343.07001l-0.01996,0.07001"
      />
      <path
        id="svg_5515"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.53003,343.14001l-0.03003,0.06998"
      />
      <path
        id="svg_5516"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.5,343.20999l-0.02002,0.07001"
      />
      <path
        id="svg_5517"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.47998,343.28l-0.01996,0.07001"
      />
      <path
        id="svg_5518"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.46002,343.35001l-0.02002,0.07001"
      />
      <path
        id="svg_5519"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.44,343.42001l-0.01001,0.07999"
      />
      <path
        id="svg_5520"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.42999,343.5l-0.02002,0.07001"
      />
      <path
        id="svg_5521"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.40997,343.57001l-0.01996,0.07999"
      />
      <path
        id="svg_5522"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.39001,343.64999l-0.01001,0.08002"
      />
      <path
        id="svg_5523"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.38,343.73001l-0.02002,0.07999"
      />
      <path
        id="svg_5524"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.35999,343.81l-0.01001,0.08002"
      />
      <path
        id="svg_5525"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.34998,343.89001l-0.00995,0.09"
      />
      <path
        id="svg_5526"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.34003,343.98001l-0.01001,0.09l0.01001,-0.09z"
      />
      <path
        id="svg_5527"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.33002,344.07001l-0.01001,0.09l0.01001,-0.09z"
      />
      <path
        id="svg_5528"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.32001,344.16l0,0.09l0,-0.09z"
      />
      <path
        id="svg_5529"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.32001,344.25l-0.01001,0.10001l0.01001,-0.10001z"
      />
      <path
        id="svg_5530"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.31,344.35001l0,0.09l0,-0.09z"
      />
      <path
        id="svg_5531"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.31,344.44l-0.01001,0.10001l0.01001,-0.10001z"
      />
      <path
        id="svg_5532"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.29999,344.54001l0,0.10001l0,-0.10001z"
      />
      <path
        id="svg_5533"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.29999,344.64001l0,0.09l0,-0.09z"
      />
      <path
        id="svg_5534"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.29999,344.73001l0,0.09998l0,-0.09998z"
      />
      <path
        id="svg_5535"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.29999,344.82999l0.01001,0.10001l-0.01001,-0.10001z"
      />
      <path
        id="svg_5536"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.31,344.92999l0,0.09l0,-0.09z"
      />
      <path
        id="svg_5537"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.31,345.01999l0.01001,0.09l-0.01001,-0.09z"
      />
      <path
        id="svg_5538"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.32001,345.10999l0,0.09003l0,-0.09003z"
      />
      <path
        id="svg_5539"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.32001,345.20001l0.01001,0.07999l-0.01001,-0.07999z"
      />
      <path
        id="svg_5540"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.33002,345.28l0.01001,0.07999l-0.01001,-0.07999z"
      />
      <path
        id="svg_5541"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.34003,345.35999l0.00995,0.08002"
      />
      <path
        id="svg_5542"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.34998,345.44l0.01001,0.07001"
      />
      <path
        id="svg_5543"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.35999,345.51001l0.01001,0.06998"
      />
      <path
        id="svg_5544"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.37,345.57999l0.02002,0.07001"
      />
      <path
        id="svg_5545"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.39001,345.64999l0.01001,0.06"
      />
      <path
        id="svg_5546"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.40002,345.70999l0.01996,0.06"
      />
      <path
        id="svg_5547"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.41998,345.76999l0.01001,0.06"
      />
      <path
        id="svg_5548"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.42999,345.82999l0.02002,0.06003"
      />
      <path
        id="svg_5549"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.45001,345.89001l0.01996,0.06998"
      />
      <path
        id="svg_5550"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.46997,345.95999l0.01001,0.06"
      />
      <path
        id="svg_5551"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.47998,346.01999l0.02002,0.06"
      />
      <path
        id="svg_5552"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.5,346.07999l0.02002,0.05002"
      />
      <path
        id="svg_5553"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.52002,346.13l0.01996,0.06"
      />
      <path
        id="svg_5554"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.53998,346.19l0.02002,0.04999"
      />
      <path
        id="svg_5555"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.56,346.23999l0.01001,0.05002"
      />
      <path
        id="svg_5556"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.57001,346.29001l0.02002,0.03998"
      />
      <path
        id="svg_5557"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.35999,352.53l-1.01996,0"
      />
      <path
        id="svg_5558"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.34003,352.53l0,-0.37l0,0.37z"
      />
      <path
        id="svg_5559"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.34003,352.16l1.01996,0"
      />
      <path
        id="svg_5560"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.35999,352.16l0,0.37l0,-0.37z"
      />
      <path
        id="svg_5561"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m587,352.53l-1.02002,0"
      />
      <path
        id="svg_5562"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m585.97998,352.53l0,-0.37l0,0.37z"
      />
      <path
        id="svg_5563"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m585.97998,352.16l1.02002,0"
      />
      <path
        id="svg_5564"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m587,352.16l0,0.37l0,-0.37z"
      />
      <path
        id="svg_5565"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.12,351.92999l-8.90002,0"
      />
      <path
        id="svg_5566"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.34003,351.62l-9.34003,0"
      />
      <path
        id="svg_5567"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.35999,352.16a1.334,1.334 0 0 0 -1.01996,-0.54001"
      />
      <path
        id="svg_5568"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m587,351.62a1.334,1.334 0 0 0 -1.02002,0.54001"
      />
      <path
        id="svg_5569"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.34003,352.16a1.334,1.334 0 0 0 -0.22003,-0.23001"
      />
      <path
        id="svg_5570"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m587.21997,351.92999a1.334,1.334 0 0 0 -0.21997,0.23001"
      />
      <path
        id="svg_5571"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,351.07001l0,-1.02002l0,1.02002z"
      />
      <path
        id="svg_5572"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,350.04999l-0.35999,0"
      />
      <path
        id="svg_5573"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.57001,350.04999l0,1.02002l0,-1.02002z"
      />
      <path
        id="svg_5574"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.57001,351.07001l0.35999,0"
      />
      <path
        id="svg_5575"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,342.26001l0,-1.02002l0,1.02002z"
      />
      <path
        id="svg_5576"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,341.23999l-0.35999,0"
      />
      <path
        id="svg_5577"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.57001,341.23999l0,1.02002l0,-1.02002z"
      />
      <path
        id="svg_5578"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.57001,342.26001l0.35999,0"
      />
      <path
        id="svg_5579"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.33002,349.82999l0,-7.34998l0,7.34998z"
      />
      <path
        id="svg_5580"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.02002,350.04999l0,-7.78998l0,7.78998z"
      />
      <path
        id="svg_5581"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.57001,351.07001l-0.39001,-0.45001"
      />
      <path
        id="svg_5582"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.17999,350.62l-0.15997,-0.57001"
      />
      <path
        id="svg_5583"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.02002,342.26001l0.15997,-0.57001"
      />
      <path
        id="svg_5584"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.17999,341.69l0.39001,-0.45001"
      />
      <path
        id="svg_5585"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.57001,350.04999l-0.23999,-0.22"
      />
      <path
        id="svg_5586"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.33002,342.48001l0.23999,-0.22"
      />
      <path
        id="svg_5587"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.40002,346.32001l0.51996,-0.07001"
      />
      <path
        id="svg_5588"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.91998,346.25l0.39001,-0.04999"
      />
      <path
        id="svg_5589"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.31,346.20001l0.34003,-0.06"
      />
      <path
        id="svg_5590"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.65002,346.14001l0.27997,-0.06003"
      />
      <path
        id="svg_5591"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.92999,346.07999l0.22998,-0.04999"
      />
      <path
        id="svg_5592"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.15997,346.03l0.17004,-0.04001"
      />
      <path
        id="svg_5593"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.33002,345.98999l0.12,-0.03"
      />
      <path
        id="svg_5594"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.45001,345.95999l0.08997,-0.03"
      />
      <path
        id="svg_5595"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.53998,345.92999l0.10004,-0.00998"
      />
      <path
        id="svg_5596"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.64001,345.92001l0.13,-0.01001"
      />
      <path
        id="svg_5597"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.77002,345.91l0.19,-0.01001"
      />
      <path
        id="svg_5598"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.96002,345.89999l0.21997,0"
      />
      <path
        id="svg_5599"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m606.17999,345.89999l0.25,0.02002"
      />
      <path
        id="svg_5600"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m606.42999,345.92001l0.28003,0.03"
      />
      <path
        id="svg_5601"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m606.71002,345.95001l0.28998,0.06"
      />
      <path
        id="svg_5602"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m607,346.01001l0.28998,0.09"
      />
      <path
        id="svg_5603"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m607.28998,346.10001l0.29004,0.12"
      />
      <path
        id="svg_5604"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m607.58002,346.22l0.26001,0.14999"
      />
      <path
        id="svg_5605"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m607.84003,346.37l0.23999,0.17001"
      />
      <path
        id="svg_5606"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.08002,346.54001l0.20001,0.19"
      />
      <path
        id="svg_5607"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.28003,346.73001l0.15997,0.20999"
      />
      <path
        id="svg_5608"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.44,346.94l0.13,0.20999"
      />
      <path
        id="svg_5609"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.57001,347.14999l0.07001,0.20999"
      />
      <path
        id="svg_5610"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.64001,347.35999l0.01996,0.20001"
      />
      <path
        id="svg_5611"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.65997,347.56l-0.01996,0.20999"
      />
      <path
        id="svg_5612"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.64001,347.76999l-0.07001,0.20001"
      />
      <path
        id="svg_5613"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.57001,347.97l-0.13,0.20999"
      />
      <path
        id="svg_5614"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.44,348.17999l-0.15997,0.21002"
      />
      <path
        id="svg_5615"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.28003,348.39001l-0.20001,0.18997"
      />
      <path
        id="svg_5616"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m608.08002,348.57999l-0.23999,0.17001"
      />
      <path
        id="svg_5617"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m607.84003,348.75l-0.27002,0.14999"
      />
      <path
        id="svg_5618"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m607.57001,348.89999l-0.28003,0.12"
      />
      <path
        id="svg_5619"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m607.28998,349.01999l-0.28998,0.09"
      />
      <path
        id="svg_5620"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m607,349.10999l-0.28998,0.06003"
      />
      <path
        id="svg_5621"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m606.71002,349.17001l-0.28003,0.03998"
      />
      <path
        id="svg_5622"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m606.42999,349.20999l-0.26001,0.01001"
      />
      <path
        id="svg_5623"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m606.16998,349.22l-0.21997,0"
      />
      <path
        id="svg_5624"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.95001,349.22l-0.17999,-0.01001"
      />
      <path
        id="svg_5625"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.77002,349.20999l-0.13,-0.00998"
      />
      <path
        id="svg_5626"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.64001,349.20001l-0.09003,-0.01001"
      />
      <path
        id="svg_5627"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.54999,349.19l-0.09998,-0.01999"
      />
      <path
        id="svg_5628"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.45001,349.17001l-0.12,-0.03"
      />
      <path
        id="svg_5629"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.33002,349.14001l-0.17999,-0.05002"
      />
      <path
        id="svg_5630"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.15002,349.09l-0.22003,-0.04999"
      />
      <path
        id="svg_5631"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.92999,349.04001l-0.27997,-0.06"
      />
      <path
        id="svg_5632"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.65002,348.98001l-0.34003,-0.06"
      />
      <path
        id="svg_5633"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.31,348.92001l-0.38,-0.05002"
      />
      <path
        id="svg_5634"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.92999,348.87l-0.41998,-0.04999"
      />
      <path
        id="svg_5635"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.51001,348.82001l-0.10004,0"
      />
      <path
        id="svg_5636"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.26001,349.26001l-0.01001,0.03998"
      />
      <path
        id="svg_5637"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.25,349.29999l-0.01001,0.03"
      />
      <path
        id="svg_5638"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.23999,349.32999l-0.01001,0.03"
      />
      <path
        id="svg_5639"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.22998,349.35999l-0.01996,0.03003"
      />
      <path
        id="svg_5640"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.21002,349.39001l-0.01001,0.03"
      />
      <path
        id="svg_5641"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.20001,349.42001l-0.03003,0.03"
      />
      <path
        id="svg_5642"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.16998,349.45001l-0.01996,0.03"
      />
      <path
        id="svg_5643"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.15002,349.48001l-0.03003,0.03998"
      />
      <path
        id="svg_5644"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.12,349.51999l-0.03998,0.04001"
      />
      <path
        id="svg_5645"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.08002,349.56l-0.04004,0.04001"
      />
      <path
        id="svg_5646"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.03998,349.60001l-0.04999,0.04999"
      />
      <path
        id="svg_5647"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.98999,349.64999l-0.04999,0.04001"
      />
      <path
        id="svg_5648"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.94,349.69l-0.06,0.04999"
      />
      <path
        id="svg_5649"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.88,349.73999l-0.06,0.04001"
      />
      <path
        id="svg_5650"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.82001,349.78l-0.07001,0.04001"
      />
      <path
        id="svg_5651"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.75,349.82001l-0.06,0.03"
      />
      <path
        id="svg_5652"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.69,349.85001l-0.07001,0.03"
      />
      <path
        id="svg_5653"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.62,349.88l-0.07001,0.01001"
      />
      <path
        id="svg_5654"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.54999,349.89001l-0.08002,0.01999"
      />
      <path
        id="svg_5655"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.46997,349.91l-0.06995,0.01001"
      />
      <path
        id="svg_5656"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.40002,349.92001l-0.07001,0"
      />
      <path
        id="svg_5657"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.33002,349.92001l-0.07001,0"
      />
      <path
        id="svg_5658"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.26001,349.92001l-0.07001,0"
      />
      <path
        id="svg_5659"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.19,349.92001l-0.07001,0"
      />
      <path
        id="svg_5660"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.12,349.92001l-0.06,0"
      />
      <path
        id="svg_5661"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.06,349.92001l-0.06,0"
      />
      <path
        id="svg_5662"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602,349.92001l-0.06,0"
      />
      <path
        id="svg_5663"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.94,349.92001l-0.04999,-0.01001"
      />
      <path
        id="svg_5664"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.89001,349.91l-0.06,0"
      />
      <path
        id="svg_5665"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.83002,349.91l-0.04999,0"
      />
      <path
        id="svg_5666"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.78003,349.91l-0.04004,-0.01001"
      />
      <path
        id="svg_5667"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.73999,349.89999l-0.03998,0"
      />
      <path
        id="svg_5668"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.70001,349.89999l-0.04004,-0.00998"
      />
      <path
        id="svg_5669"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.65997,349.89001l-0.03998,-0.01001"
      />
      <path
        id="svg_5670"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.62,349.88l-0.03998,-0.01001"
      />
      <path
        id="svg_5671"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.58002,349.87l-0.03003,-0.01999"
      />
      <path
        id="svg_5672"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.54999,349.85001l-0.02997,-0.02002"
      />
      <path
        id="svg_5673"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.52002,349.82999l-0.02002,-0.03"
      />
      <path
        id="svg_5674"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.5,349.79999l-0.02002,-0.03"
      />
      <path
        id="svg_5675"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.47998,349.76999l-0.01996,-0.03998"
      />
      <path
        id="svg_5676"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.46002,349.73001l-0.02002,-0.05002"
      />
      <path
        id="svg_5677"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.44,349.67999l-0.02002,-0.06"
      />
      <path
        id="svg_5678"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.41998,349.62l-0.01001,-0.07001"
      />
      <path
        id="svg_5679"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.40997,349.54999l-0.00995,-0.07999"
      />
      <path
        id="svg_5680"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.40002,349.47l-0.01001,-0.09l0.01001,0.09z"
      />
      <path
        id="svg_5681"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.39001,349.38l-0.01001,-0.10001l0.01001,0.10001z"
      />
      <path
        id="svg_5682"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.38,349.28l-0.01001,-0.12l0.01001,0.12z"
      />
      <path
        id="svg_5683"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.37,349.16l0,-0.13l0,0.13z"
      />
      <path
        id="svg_5684"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.37,349.03l-0.01001,-0.14999l0.01001,0.14999z"
      />
      <path
        id="svg_5685"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.35999,348.88l0,-0.17001l0,0.17001z"
      />
      <path
        id="svg_5686"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.35999,348.70999l-0.01001,-0.16998l0.01001,0.16998z"
      />
      <path
        id="svg_5687"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34998,348.54001l0,-0.18002l0,0.18002z"
      />
      <path
        id="svg_5688"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34998,348.35999l-0.00995,-0.19998l0.00995,0.19998z"
      />
      <path
        id="svg_5689"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,348.16l0,-0.19l0,0.19z"
      />
      <path
        id="svg_5690"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,347.97l0,-0.20999l0,0.20999z"
      />
      <path
        id="svg_5691"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,347.76001l0,-0.20001l0,0.20001z"
      />
      <path
        id="svg_5692"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,347.56l0,-0.20001l0,0.20001z"
      />
      <path
        id="svg_5693"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,347.35999l0,-0.20999l0,0.20999z"
      />
      <path
        id="svg_5694"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,347.14999l0,-0.19l0,0.19z"
      />
      <path
        id="svg_5695"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,346.95999l0.00995,-0.19998l-0.00995,0.19998z"
      />
      <path
        id="svg_5696"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34998,346.76001l0,-0.18002l0,0.18002z"
      />
      <path
        id="svg_5697"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34998,346.57999l0.01001,-0.17999l-0.01001,0.17999z"
      />
      <path
        id="svg_5698"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.35999,346.39999l0,-0.16l0,0.16z"
      />
      <path
        id="svg_5699"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.35999,346.23999l0.01001,-0.14999l-0.01001,0.14999z"
      />
      <path
        id="svg_5700"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.37,346.09l0.01001,-0.13l-0.01001,0.13z"
      />
      <path
        id="svg_5701"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.38,345.95999l0,-0.12l0,0.12z"
      />
      <path
        id="svg_5702"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.38,345.84l0.01001,-0.10001l-0.01001,0.10001z"
      />
      <path
        id="svg_5703"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.39001,345.73999l0.01001,-0.09998"
      />
      <path
        id="svg_5704"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.40002,345.64001l0.01996,-0.07001"
      />
      <path
        id="svg_5705"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.41998,345.57001l0.01001,-0.07001"
      />
      <path
        id="svg_5706"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.42999,345.5l0.02002,-0.06"
      />
      <path
        id="svg_5707"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.45001,345.44l0.01001,-0.04999"
      />
      <path
        id="svg_5708"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.46002,345.39001l0.01996,-0.04001"
      />
      <path
        id="svg_5709"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.47998,345.35001l0.03003,-0.03"
      />
      <path
        id="svg_5710"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.51001,345.32001l0.02002,-0.03"
      />
      <path
        id="svg_5711"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.53003,345.29001l0.02997,-0.02002"
      />
      <path
        id="svg_5712"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.56,345.26999l0.03003,-0.00998"
      />
      <path
        id="svg_5713"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.59003,345.26001l0.02997,-0.01001"
      />
      <path
        id="svg_5714"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.62,345.25l0.03998,-0.01001"
      />
      <path
        id="svg_5715"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.65997,345.23999l0.04004,-0.00998"
      />
      <path
        id="svg_5716"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.70001,345.23001l0.03998,-0.01001"
      />
      <path
        id="svg_5717"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.73999,345.22l0.04999,0"
      />
      <path
        id="svg_5718"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.78998,345.22l0.05005,-0.01001"
      />
      <path
        id="svg_5719"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.84003,345.20999l0.04999,0"
      />
      <path
        id="svg_5720"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.89001,345.20999l0.06,0"
      />
      <path
        id="svg_5721"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.95001,345.20999l0.06,-0.00998"
      />
      <path
        id="svg_5722"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.01001,345.20001l0.06,0"
      />
      <path
        id="svg_5723"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.07001,345.20001l0.06,0"
      />
      <path
        id="svg_5724"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.13,345.20001l0.07001,0"
      />
      <path
        id="svg_5725"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.20001,345.20001l0.06,0"
      />
      <path
        id="svg_5726"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.26001,345.20001l0.07001,0"
      />
      <path
        id="svg_5727"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.33002,345.20001l0.07001,0.00998"
      />
      <path
        id="svg_5728"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.40002,345.20999l0.06995,0.01001"
      />
      <path
        id="svg_5729"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.46997,345.22l0.07001,0.01001"
      />
      <path
        id="svg_5730"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.53998,345.23001l0.07001,0.01999"
      />
      <path
        id="svg_5731"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.60999,345.25l0.07001,0.03"
      />
      <path
        id="svg_5732"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.67999,345.28l0.07001,0.03"
      />
      <path
        id="svg_5733"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.75,345.31l0.06,0.04001"
      />
      <path
        id="svg_5734"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.81,345.35001l0.06,0.04001"
      />
      <path
        id="svg_5735"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.87,345.39001l0.06,0.04999"
      />
      <path
        id="svg_5736"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.92999,345.44l0.06,0.04999"
      />
      <path
        id="svg_5737"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.98999,345.48999l0.04999,0.06"
      />
      <path
        id="svg_5738"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.03998,345.54999l0.05005,0.06"
      />
      <path
        id="svg_5739"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.09003,345.60999l0.04999,0.06003"
      />
      <path
        id="svg_5740"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.14001,345.67001l0.03998,0.06"
      />
      <path
        id="svg_5741"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.17999,345.73001l0.03998,0.06998"
      />
      <path
        id="svg_5742"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.21997,345.79999l0.03003,0.06"
      />
      <path
        id="svg_5743"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.25,345.85999l0.03003,0.07001"
      />
      <path
        id="svg_5744"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.28003,345.92999l0.01996,0.07001"
      />
      <path
        id="svg_5745"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.29999,346l0.03003,0.07001"
      />
      <path
        id="svg_5746"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.33002,346.07001l0.01996,0.07001"
      />
      <path
        id="svg_5747"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.34998,346.14001l0.02002,0.06998"
      />
      <path
        id="svg_5748"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.37,346.20999l0.02002,0.07001"
      />
      <path
        id="svg_5749"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.39001,346.28l0.01996,0.07001"
      />
      <path
        id="svg_5750"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.40997,346.35001l0.02002,0.07001"
      />
      <path
        id="svg_5751"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.42999,346.42001l0.02002,0.07999"
      />
      <path
        id="svg_5752"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.45001,346.5l0.01001,0.07999"
      />
      <path
        id="svg_5753"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.46002,346.57999l0.01996,0.07001"
      />
      <path
        id="svg_5754"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.47998,346.64999l0.01001,0.09"
      />
      <path
        id="svg_5755"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.48999,346.73999l0.01001,0.08002"
      />
      <path
        id="svg_5756"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.5,346.82001l0.01001,0.09"
      />
      <path
        id="svg_5757"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.51001,346.91l0.01001,0.09l-0.01001,-0.09z"
      />
      <path
        id="svg_5758"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.52002,347l0.01001,0.09l-0.01001,-0.09z"
      />
      <path
        id="svg_5759"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.53003,347.09l0.00995,0.09l-0.00995,-0.09z"
      />
      <path
        id="svg_5760"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.53998,347.17999l0,0.10001l0,-0.10001z"
      />
      <path
        id="svg_5761"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.53998,347.28l0.01001,0.09l-0.01001,-0.09z"
      />
      <path
        id="svg_5762"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.54999,347.37l0,0.10001l0,-0.10001z"
      />
      <path
        id="svg_5763"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.54999,347.47l0,0.10001l0,-0.10001z"
      />
      <path
        id="svg_5764"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.54999,347.57001l0,0.09l0,-0.09z"
      />
      <path
        id="svg_5765"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.54999,347.66l0,0.10001l0,-0.10001z"
      />
      <path
        id="svg_5766"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.54999,347.76001l0,0.09l0,-0.09z"
      />
      <path
        id="svg_5767"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.54999,347.85001l-0.01001,0.10001l0.01001,-0.10001z"
      />
      <path
        id="svg_5768"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.53998,347.95001l0,0.09l0,-0.09z"
      />
      <path
        id="svg_5769"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.53998,348.04001l-0.00995,0.09l0.00995,-0.09z"
      />
      <path
        id="svg_5770"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.53003,348.13l-0.01001,0.07999l0.01001,-0.07999z"
      />
      <path
        id="svg_5771"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.52002,348.20999l-0.01001,0.08002l0.01001,-0.08002z"
      />
      <path
        id="svg_5772"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.51001,348.29001l-0.01001,0.07999"
      />
      <path
        id="svg_5773"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.5,348.37l-0.01001,0.07001"
      />
      <path
        id="svg_5774"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.48999,348.44l-0.01001,0.07001"
      />
      <path
        id="svg_5775"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.47998,348.51001l-0.01001,0.06"
      />
      <path
        id="svg_5776"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.46997,348.57001l-0.01996,0.07001"
      />
      <path
        id="svg_5777"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.45001,348.64001l-0.01001,0.06"
      />
      <path
        id="svg_5778"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.44,348.70001l-0.02002,0.06"
      />
      <path
        id="svg_5779"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.41998,348.76001l-0.01001,0.06"
      />
      <path
        id="svg_5780"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.40997,348.82001l-0.01996,0.06"
      />
      <path
        id="svg_5781"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.39001,348.88l-0.02002,0.06"
      />
      <path
        id="svg_5782"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.37,348.94l-0.02002,0.06"
      />
      <path
        id="svg_5783"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.34998,349l-0.01996,0.06"
      />
      <path
        id="svg_5784"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.33002,349.06l-0.02002,0.06"
      />
      <path
        id="svg_5785"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.31,349.12l-0.01001,0.05002"
      />
      <path
        id="svg_5786"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.29999,349.17001l-0.01996,0.04999"
      />
      <path
        id="svg_5787"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.28003,349.22l-0.02002,0.04001"
      />
      <path
        id="svg_5788"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.48999,352.53l1.02002,0"
      />
      <path
        id="svg_5789"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.51001,352.53l0,-0.37l0,0.37z"
      />
      <path
        id="svg_5790"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.51001,352.16l-1.02002,0"
      />
      <path
        id="svg_5791"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.48999,352.16l0,0.37l0,-0.37z"
      />
      <path
        id="svg_5792"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.84998,352.53l1.02002,0"
      />
      <path
        id="svg_5793"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m613.87,352.53l0,-0.37l0,0.37z"
      />
      <path
        id="svg_5794"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m613.87,352.16l-1.02002,0"
      />
      <path
        id="svg_5795"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.84998,352.16l0,0.37l0,-0.37z"
      />
      <path
        id="svg_5796"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.72998,351.92999l8.90002,0"
      />
      <path
        id="svg_5797"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.51001,351.62l9.33997,0"
      />
      <path
        id="svg_5798"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.48999,352.16l0.45001,-0.39001"
      />
      <path
        id="svg_5799"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.94,351.76999l0.57001,-0.14999"
      />
      <path
        id="svg_5800"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.84998,351.62l0.58002,0.14999"
      />
      <path
        id="svg_5801"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m613.42999,351.76999l0.44,0.39001"
      />
      <path
        id="svg_5802"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.51001,352.16l0.21997,-0.23001"
      />
      <path
        id="svg_5803"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m612.63,351.92999l0.21997,0.23001"
      />
      <path
        id="svg_5804"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.91998,351.07001l0,-1.02002l0,1.02002z"
      />
      <path
        id="svg_5805"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.91998,350.04999l0.37,0"
      />
      <path
        id="svg_5806"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.28998,350.04999l0,1.02002l0,-1.02002z"
      />
      <path
        id="svg_5807"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.28998,351.07001l-0.37,0"
      />
      <path
        id="svg_5808"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.91998,342.26001l0,-1.02002l0,1.02002z"
      />
      <path
        id="svg_5809"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.91998,341.23999l0.37,0"
      />
      <path
        id="svg_5810"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.28998,341.23999l0,1.02002l0,-1.02002z"
      />
      <path
        id="svg_5811"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.28998,342.26001l-0.37,0"
      />
      <path
        id="svg_5812"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.52002,349.82999l0,-7.34998l0,7.34998z"
      />
      <path
        id="svg_5813"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.83002,350.04999l0,-7.78998l0,7.78998z"
      />
      <path
        id="svg_5814"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.28998,351.07001a1.334,1.334 0 0 0 0.54004,-1.02002"
      />
      <path
        id="svg_5815"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.83002,342.26001a1.334,1.334 0 0 0 -0.54004,-1.02002"
      />
      <path
        id="svg_5816"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.28998,350.04999a1.334,1.334 0 0 0 0.23004,-0.22"
      />
      <path
        id="svg_5817"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.52002,342.48001a1.334,1.334 0 0 0 -0.23004,-0.22"
      />
      <path
        id="svg_5818"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,332.79999l-2.19,0"
      />
      <path
        id="svg_5819"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,332.79999l0,0z"
      />
      <path
        id="svg_5820"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,332.79999l-1.06,0.13"
      />
      <path
        id="svg_5821"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.67999,332.92999l-1,0.37"
      />
      <path
        id="svg_5822"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,333.04999l-2.19,0"
      />
      <path
        id="svg_5823"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,333.04999l0,0z"
      />
      <path
        id="svg_5824"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,333.04999l-1,0.12003"
      />
      <path
        id="svg_5825"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.73999,333.17001l-0.95001,0.34998"
      />
      <path
        id="svg_5826"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.02002,336.25l0.82996,0.31"
      />
      <path
        id="svg_5827"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.84998,336.56l0.89001,0.10001"
      />
      <path
        id="svg_5828"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,336.66l0.54999,0"
      />
      <path
        id="svg_5829"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.28998,336.66l0,0z"
      />
      <path
        id="svg_5830"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.28998,336.66l0.32001,-0.08002"
      />
      <path
        id="svg_5831"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.60999,336.57999l0.23999,-0.23999"
      />
      <path
        id="svg_5832"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.84998,336.34l0.08002,-0.32001"
      />
      <path
        id="svg_5833"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.92999,336.01999l0,-1.82999l0,1.82999z"
      />
      <path
        id="svg_5834"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.92999,334.19l0,0z"
      />
      <path
        id="svg_5835"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.92999,334.19l-0.08002,-0.32001"
      />
      <path
        id="svg_5836"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.84998,333.87l-0.23999,-0.23999"
      />
      <path
        id="svg_5837"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.60999,333.63l-0.32001,-0.08002"
      />
      <path
        id="svg_5838"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.28998,333.54999l-0.54999,0"
      />
      <path
        id="svg_5839"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,333.54999l0,0z"
      />
      <path
        id="svg_5840"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,333.54999l-0.89001,0.11002"
      />
      <path
        id="svg_5841"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.84998,333.66l-0.82996,0.31"
      />
      <path
        id="svg_5842"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.67999,333.29999l0,3.61002l0,-3.61002z"
      />
      <path
        id="svg_5843"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,337.41l-2.19,0"
      />
      <path
        id="svg_5844"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,337.41l0,0z"
      />
      <path
        id="svg_5845"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,337.41l-1.06,-0.13"
      />
      <path
        id="svg_5846"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.67999,337.28l-1,-0.37"
      />
      <path
        id="svg_5847"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.92999,337.16l-2.19,0"
      />
      <path
        id="svg_5848"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,337.16l0,0z"
      />
      <path
        id="svg_5849"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m596.73999,337.16l-1,-0.12"
      />
      <path
        id="svg_5850"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.73999,337.04001l-0.95001,-0.35001"
      />
      <path
        id="svg_5851"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m594.78998,333.51999l0,3.17001l0,-3.17001z"
      />
      <path
        id="svg_5852"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.92999,336.01999l0,1.14001l0,-1.14001z"
      />
      <path
        id="svg_5853"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.92999,334.19l0,-1.14001l0,1.14001z"
      />
      <path
        id="svg_5854"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m595.02002,333.97l0,2.28l0,-2.28z"
      />
      <path
        id="svg_5855"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.69,335.10999l-0.09998,0.22"
      />
      <path
        id="svg_5856"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.59003,335.32999l-0.22003,0.10001"
      />
      <path
        id="svg_5857"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.37,335.42999l-0.22998,-0.10001"
      />
      <path
        id="svg_5858"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.14001,335.32999l-0.09003,-0.22"
      />
      <path
        id="svg_5859"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.04999,335.10999l0.09003,-0.22998"
      />
      <path
        id="svg_5860"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.14001,334.88l0.22998,-0.09"
      />
      <path
        id="svg_5861"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.37,334.79001l0.22003,0.09"
      />
      <path
        id="svg_5862"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m597.59003,334.88l0.09998,0.22998"
      />
      <path
        id="svg_5863"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.56,335.10999l-0.03998,0.08002"
      />
      <path
        id="svg_5864"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.52002,335.19l-0.09003,0.04001"
      />
      <path
        id="svg_5865"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.42999,335.23001l-0.08997,-0.04001"
      />
      <path
        id="svg_5866"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.34003,335.19l-0.03003,-0.08002"
      />
      <path
        id="svg_5867"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.31,335.10999l0.03003,-0.09"
      />
      <path
        id="svg_5868"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.34003,335.01999l0.08997,-0.03998"
      />
      <path
        id="svg_5869"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.42999,334.98001l0.09003,0.03998"
      />
      <path
        id="svg_5870"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.52002,335.01999l0.03998,0.09"
      />
      <path
        id="svg_5871"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.56,335.67001l-0.03998,0.09"
      />
      <path
        id="svg_5872"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.52002,335.76001l-0.09003,0.03998"
      />
      <path
        id="svg_5873"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.42999,335.79999l-0.08997,-0.03998"
      />
      <path
        id="svg_5874"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.34003,335.76001l-0.03003,-0.09"
      />
      <path
        id="svg_5875"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.31,335.67001l0.03003,-0.09003"
      />
      <path
        id="svg_5876"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.34003,335.57999l0.08997,-0.03"
      />
      <path
        id="svg_5877"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.42999,335.54999l0.09003,0.03"
      />
      <path
        id="svg_5878"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.52002,335.57999l0.03998,0.09003"
      />
      <path
        id="svg_5879"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.56,334.54001l-0.03998,0.09"
      />
      <path
        id="svg_5880"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.52002,334.63l-0.09003,0.03"
      />
      <path
        id="svg_5881"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.42999,334.66l-0.08997,-0.03"
      />
      <path
        id="svg_5882"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.34003,334.63l-0.03003,-0.09"
      />
      <path
        id="svg_5883"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.31,334.54001l0.03003,-0.09"
      />
      <path
        id="svg_5884"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.34003,334.45001l0.08997,-0.03"
      />
      <path
        id="svg_5885"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.42999,334.42001l0.09003,0.03"
      />
      <path
        id="svg_5886"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m598.52002,334.45001l0.03998,0.09"
      />
      <path
        id="svg_5887"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.91998,332.79999l2.20001,0"
      />
      <path
        id="svg_5888"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.12,332.79999l0,0z"
      />
      <path
        id="svg_5889"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.12,332.79999l1.06,0.13"
      />
      <path
        id="svg_5890"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.17999,332.92999l1,0.37"
      />
      <path
        id="svg_5891"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.91998,333.04999l2.20001,0"
      />
      <path
        id="svg_5892"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.12,333.04999l0,0z"
      />
      <path
        id="svg_5893"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.12,333.04999l1,0.12003"
      />
      <path
        id="svg_5894"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.12,333.17001l0.94,0.34998"
      />
      <path
        id="svg_5895"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.84003,336.25l-0.84003,0.31"
      />
      <path
        id="svg_5896"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604,336.56l-0.89001,0.10001"
      />
      <path
        id="svg_5897"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.10999,336.66l-0.54999,0"
      />
      <path
        id="svg_5898"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.56,336.66l0,0z"
      />
      <path
        id="svg_5899"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.56,336.66l-0.32001,-0.08002"
      />
      <path
        id="svg_5900"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.23999,336.57999l-0.22998,-0.23999"
      />
      <path
        id="svg_5901"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.01001,336.34l-0.09003,-0.32001"
      />
      <path
        id="svg_5902"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.91998,336.01999l0,-1.82999l0,1.82999z"
      />
      <path
        id="svg_5903"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.91998,334.19l0,0z"
      />
      <path
        id="svg_5904"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.91998,334.19l0.09003,-0.32001"
      />
      <path
        id="svg_5905"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.01001,333.87l0.22998,-0.23999"
      />
      <path
        id="svg_5906"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.23999,333.63l0.32001,-0.08002"
      />
      <path
        id="svg_5907"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.56,333.54999l0.54999,0"
      />
      <path
        id="svg_5908"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.10999,333.54999l0,0z"
      />
      <path
        id="svg_5909"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.10999,333.54999l0.89001,0.11002"
      />
      <path
        id="svg_5910"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604,333.66l0.84003,0.31"
      />
      <path
        id="svg_5911"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.17999,333.29999l0,3.61002l0,-3.61002z"
      />
      <path
        id="svg_5912"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.91998,337.41l2.20001,0"
      />
      <path
        id="svg_5913"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.12,337.41l0,0z"
      />
      <path
        id="svg_5914"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.12,337.41l1.06,-0.13"
      />
      <path
        id="svg_5915"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.17999,337.28l1,-0.37"
      />
      <path
        id="svg_5916"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m600.91998,337.16l2.20001,0"
      />
      <path
        id="svg_5917"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.12,337.16l0,0z"
      />
      <path
        id="svg_5918"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m603.12,337.16l1,-0.12"
      />
      <path
        id="svg_5919"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.12,337.04001l0.94,-0.35001"
      />
      <path
        id="svg_5920"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m605.06,333.51999l0,3.17001l0,-3.17001z"
      />
      <path
        id="svg_5921"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.91998,336.01999l0,1.14001l0,-1.14001z"
      />
      <path
        id="svg_5922"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.91998,334.19l0,-1.14001l0,1.14001z"
      />
      <path
        id="svg_5923"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m604.84003,333.97l0,2.28l0,-2.28z"
      />
      <path
        id="svg_5924"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.81,335.10999l-0.09998,0.22"
      />
      <path
        id="svg_5925"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.71002,335.32999l-0.22003,0.10001"
      />
      <path
        id="svg_5926"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.48999,335.42999l-0.22998,-0.10001"
      />
      <path
        id="svg_5927"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.26001,335.32999l-0.09003,-0.22"
      />
      <path
        id="svg_5928"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.16998,335.10999l0.09003,-0.22998"
      />
      <path
        id="svg_5929"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.26001,334.88l0.22998,-0.09"
      />
      <path
        id="svg_5930"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.48999,334.79001l0.22003,0.09"
      />
      <path
        id="svg_5931"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m602.71002,334.88l0.09998,0.22998"
      />
      <path
        id="svg_5932"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.54999,335.10999l-0.03998,0.08002"
      />
      <path
        id="svg_5933"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.51001,335.19l-0.09003,0.04001"
      />
      <path
        id="svg_5934"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.41998,335.23001l-0.07996,-0.04001"
      />
      <path
        id="svg_5935"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,335.19l-0.04004,-0.08002"
      />
      <path
        id="svg_5936"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.29999,335.10999l0.04004,-0.09"
      />
      <path
        id="svg_5937"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,335.01999l0.07996,-0.03998"
      />
      <path
        id="svg_5938"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.41998,334.98001l0.09003,0.03998"
      />
      <path
        id="svg_5939"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.51001,335.01999l0.03998,0.09"
      />
      <path
        id="svg_5940"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.54999,335.67001l-0.03998,0.09"
      />
      <path
        id="svg_5941"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.51001,335.76001l-0.09003,0.03998"
      />
      <path
        id="svg_5942"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.41998,335.79999l-0.07996,-0.03998"
      />
      <path
        id="svg_5943"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,335.76001l-0.04004,-0.09"
      />
      <path
        id="svg_5944"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.29999,335.67001l0.04004,-0.09003"
      />
      <path
        id="svg_5945"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,335.57999l0.07996,-0.03"
      />
      <path
        id="svg_5946"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.41998,335.54999l0.09003,0.03"
      />
      <path
        id="svg_5947"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.51001,335.57999l0.03998,0.09003"
      />
      <path
        id="svg_5948"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.54999,334.54001l-0.03998,0.09"
      />
      <path
        id="svg_5949"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.51001,334.63l-0.09003,0.03"
      />
      <path
        id="svg_5950"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.41998,334.66l-0.07996,-0.03"
      />
      <path
        id="svg_5951"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,334.63l-0.04004,-0.09"
      />
      <path
        id="svg_5952"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.29999,334.54001l0.04004,-0.09"
      />
      <path
        id="svg_5953"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.34003,334.45001l0.07996,-0.03"
      />
      <path
        id="svg_5954"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.41998,334.42001l0.09003,0.03"
      />
      <path
        id="svg_5955"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m601.51001,334.45001l0.03998,0.09"
      />
      <path
        id="svg_5956"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m628.44,357.92999l-2.48999,-2.48999"
      />
      <path
        id="svg_5957"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m628.44,357.92999l-2.48999,2.49002"
      />
      <path
        id="svg_5958"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m628.44,365.95001l-2.48999,2.5"
      />
      <path
        id="svg_5959"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m628.44,365.95001l-2.48999,-2.49002"
      />
      <path
        id="svg_5960"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,321.41l21.34998,0"
      />
      <path
        id="svg_5961"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,321.41l0,-5.91l0,5.91z"
      />
      <path
        id="svg_5962"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,315.5l-21.34998,0"
      />
      <path
        id="svg_5963"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,315.5l0,5.91l0,-5.91z"
      />
      <path
        id="svg_5964"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m639.78003,318.5l0.93994,0"
      />
      <path
        id="svg_5965"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m641.5,318.5l1.54999,0"
      />
      <path
        id="svg_5966"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m643.83002,318.5l1.56,0"
      />
      <path
        id="svg_5967"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m646.16998,318.5l1.56,0"
      />
      <path
        id="svg_5968"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m648.51001,318.5l1.56,0"
      />
      <path
        id="svg_5969"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m650.84003,318.5l1.56,0"
      />
      <path
        id="svg_5970"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m653.17999,318.5l1.56,0"
      />
      <path
        id="svg_5971"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m655.52002,318.5l1.56,0"
      />
      <path
        id="svg_5972"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m657.85999,318.48999l1.56,0"
      />
      <path
        id="svg_5973"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m660.19,318.48999l0.94,0"
      />
      <path
        id="svg_5974"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m644.71997,319.76001l0,0.31l0,-0.31z"
      />
      <path
        id="svg_5975"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m643.82001,319.76001l0.89996,0"
      />
      <path
        id="svg_5976"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m643.82001,320.07001l0,-0.31l0,0.31z"
      />
      <path
        id="svg_5977"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m644.71997,320.07001l-0.89996,0"
      />
      <path
        id="svg_5978"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m645.81,317.56l-0.06,-0.26999"
      />
      <path
        id="svg_5979"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m645.75,317.29001l-0.15997,-0.19"
      />
      <path
        id="svg_5980"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m645.59003,317.10001l-0.22003,-0.07001"
      />
      <path
        id="svg_5981"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m643.16998,317.03l-0.21997,0.07001"
      />
      <path
        id="svg_5982"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m642.95001,317.10001l-0.16003,0.19"
      />
      <path
        id="svg_5983"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m642.78998,317.29001l-0.06,0.26999"
      />
      <path
        id="svg_5984"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m642.72998,318.87l0.13,0.37"
      />
      <path
        id="svg_5985"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m642.85999,319.23999l0.31,0.16"
      />
      <path
        id="svg_5986"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m645.37,319.39999l0.31,-0.16"
      />
      <path
        id="svg_5987"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m645.67999,319.23999l0.13,-0.37"
      />
      <path
        id="svg_5988"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m645.81,317.56l0,1.31l0,-1.31z"
      />
      <path
        id="svg_5989"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m643.16998,317.03l2.20001,0"
      />
      <path
        id="svg_5990"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m642.72998,318.87l0,-1.31l0,1.31z"
      />
      <path
        id="svg_5991"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m645.37,319.39999l-2.20001,0"
      />
      <path
        id="svg_5992"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m646.10999,316.72l0,3.67999l0,-3.67999z"
      />
      <path
        id="svg_5993"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m642.42999,316.72l3.67999,0"
      />
      <path
        id="svg_5994"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m642.42999,320.39999l0,-3.67999l0,3.67999z"
      />
      <path
        id="svg_5995"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m646.10999,320.39999l-3.67999,0"
      />
      <path
        id="svg_5998"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,321.41l0,-1.51001l0,1.51001z"
      />
      <path
        id="svg_5999"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,319.22l0,-1.38l0,1.38z"
      />
      <path
        id="svg_6000"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,317.16l0,-1.37l0,1.37z"
      />
      <path
        id="svg_6001"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,315.10001l0,-1.51001l0,1.51001z"
      />
      <path
        id="svg_6002"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,321.41l1.56,0"
      />
      <path
        id="svg_6003"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m663.38,321.41l1.37,0"
      />
      <path
        id="svg_6004"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m665.44,321.41l1.37,0"
      />
      <path
        id="svg_6005"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m667.48999,321.41l1.37,0"
      />
      <path
        id="svg_6006"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m669.54999,321.41l1.57001,0"
      />
      <path
        id="svg_6007"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,321.41l0,-1.51001l0,1.51001z"
      />
      <path
        id="svg_6008"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,319.22l0,-1.38l0,1.38z"
      />
      <path
        id="svg_6009"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,317.16l0,-1.37l0,1.37z"
      />
      <path
        id="svg_6010"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,315.10001l0,-1.51001l0,1.51001z"
      />
      <path
        id="svg_6011"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,313.84l-1.57001,0"
      />
      <path
        id="svg_6012"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m668.85999,313.84l-1.37,0"
      />
      <path
        id="svg_6013"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m666.81,313.84l-1.37,0"
      />
      <path
        id="svg_6014"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m664.75,313.84l-1.37,0"
      />
      <path
        id="svg_6015"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m662.69,313.84l-1.56,0"
      />
      <path
        id="svg_6016"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,313.59l-1.57001,0"
      />
      <path
        id="svg_6017"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m668.85999,313.59l-1.37,0"
      />
      <path
        id="svg_6018"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m666.81,313.59l-1.37,0"
      />
      <path
        id="svg_6019"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m664.75,313.59l-1.37,0"
      />
      <path
        id="svg_6020"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m662.69,313.59l-1.56,0"
      />
      <path
        id="svg_6021"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m664.46002,313.59l0.14996,-1.31"
      />
      <path
        id="svg_6022"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m664.83002,311.54001l0.28998,-0.83002"
      />
      <path
        id="svg_6023"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m665.12,310.70999l0.35999,-0.56998"
      />
      <path
        id="svg_6024"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m665.90002,309.48001l0.00995,-0.03"
      />
      <path
        id="svg_6025"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m665.90997,309.45001l1.06,-1.06"
      />
      <path
        id="svg_6026"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m666.96997,308.39001l0.03003,-0.02002"
      />
      <path
        id="svg_6027"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m667.65997,307.95999l0.57001,-0.35999"
      />
      <path
        id="svg_6028"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m668.22998,307.60001l0.83002,-0.29001"
      />
      <path
        id="svg_6029"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m669.81,307.09l1.31,-0.14999"
      />
      <path
        id="svg_6030"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,313.59l-0.33002,0"
      />
      <path
        id="svg_6031"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m670.78998,313.59l0,-1.76001l0,1.76001z"
      />
      <path
        id="svg_6032"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m670.78998,311.04999l0,-1.56l0,1.56z"
      />
      <path
        id="svg_6033"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m670.78998,308.70999l0,-1.75998l0,1.75998z"
      />
      <path
        id="svg_6034"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,306.94l0,1.76999l0,-1.76999z"
      />
      <path
        id="svg_6035"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,309.48999l0,1.56l0,-1.56z"
      />
      <path
        id="svg_6036"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m671.12,311.82999l0,1.76001l0,-1.76001z"
      />
      <path
        id="svg_6037"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m664.46002,313.59l-0.16003,-1.03"
      />
      <path
        id="svg_6038"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m664.29999,312.56l0,0z"
      />
      <path
        id="svg_6039"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m663.94,311.85999l-0.10999,-0.22998"
      />
      <path
        id="svg_6040"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m663.83002,311.63l-0.73999,-0.73999"
      />
      <path
        id="svg_6041"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m663.09003,310.89001l-0.23004,-0.11002"
      />
      <path
        id="svg_6042"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m662.15997,310.42001l0,0z"
      />
      <path
        id="svg_6043"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m662.15997,310.42001l-1.02997,-0.16"
      />
      <path
        id="svg_6044"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,313.59l0.15997,0"
      />
      <path
        id="svg_6045"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.28998,313.59l0,-1.26999l0,1.26999z"
      />
      <path
        id="svg_6046"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.28998,311.54001l0,-1.28l0,1.28z"
      />
      <path
        id="svg_6047"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,310.26001l0,1.28l0,-1.28z"
      />
      <path
        id="svg_6048"
        stroke-width="0.5"
        stroke="black"
        fill="none"
        d="m661.13,312.32001l0,1.26999l0,-1.26999z"
      />
    </g>
  </svg>
</template>
