import { h, resolveComponent } from 'vue';
import Portfolio from '@/Core.Service.Domain/ViewSupport/Portfolio/Views';

const routesList = [];

const PortfolioRoute = {
  path: '/portfolio',
  name: 'Portfolio',
  component: Portfolio,
  children: [
    {
      path: 'view-site-notifications/:siteId',
      name: 'ViewSiteNotifications',
      component: {
        render: () => h(resolveComponent('router-view')),
      },
    },
  ],
  meta: { permission: 'portfolios:read' },
};

routesList.push(PortfolioRoute);

export default routesList;
