<template>
  <v-container fluid>
    <v-list lines="two">
      <div v-for="(item, i) in itemsList" :key="i">
        <v-list-item>
          <template v-slot:prepend>
            <v-icon color="primary">
              {{ item.icon }}
            </v-icon>
          </template>

          <div>
            <v-list-item-title>
              <span class="text-primary font-weight-bold">
                {{ item.label }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle v-for="(model, j) in item.model" :key="j">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                  <span
                    class="text-primary float-left text-subtitle-2 text-truncate"
                    v-bind="props"
                  >
                    {{ item.output(model, j).col1 }}
                  </span>
                  <span
                    class="float-right text-primary text-subtitle-2 font-weight-bold"
                  >
                    {{ item.output(model, j).col2 }}
                  </span>
                </template>
                <span>{{ item.output(model, j).col1 }}</span>
              </v-tooltip>
            </v-list-item-subtitle>
          </div>
        </v-list-item>
        <v-divider class="ml-14" v-if="i < itemsList.length - 1" inset />
      </div>
    </v-list>
  </v-container>
</template>

<script>
import { removeWordsSeparatedByDotAndReturnTheLast } from '@/Core.Patterns/Strategy';

export default {
  name: 'StrategyTemplateCardItemsList',

  props: {
    StrategyInfo: {
      type: Object,
      required: true,
    },
  },

  data(instance) {
    return {
      itemsList: [
        {
          icon: 'mdi-sine-wave',
          label: 'Execution Frequency',
          model: [instance.StrategyInfo.executionFrequency],
          output: (item) => ({ col1: item, col2: '' }),
        },
        {
          icon: 'mdi-video-input-component',
          label: 'Input Type Name',
          model: [
            removeWordsSeparatedByDotAndReturnTheLast(
              instance.StrategyInfo.inputTypeName
            ),
          ],
          output: (item) => ({ col1: item, col2: '' }),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel:first-child {
  border-top: none;
}
</style>
