<template>
  <v-data-table
    :headers="headers"
    :items="utilityBills"
    :items-per-page="10"
    :loading="!utilityBills.length"
    :header-props="headerProps"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <!--Headers slot-->
    <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
      <tr>
        <th
          v-for="column in columns"
          :key="column.key"
          :class="column.class"
          @click="() => toggleSort(column)"
        >
          {{ column.title }}
          <template v-if="isSorted(column)">
            <v-icon :icon="getSortIcon(column)" size="sm" />
          </template>
        </th>
      </tr>
    </template>
    <!--Items slot-->
    <template v-slot:item="{ item }">
      <tr>
        <!--Register Name slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            {{ item.registerName }}
          </p>
        </td>
        <!--Start Date slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            {{
              dateTimeToUSFormatFactory(item.billStartDate, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            }}
          </p>
        </td>
        <!--End Date slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            {{
              dateTimeToUSFormatFactory(item.billEndDate, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            }}
          </p>
        </td>
        <!--Bill Volume slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            {{ formatNumberToUS(item.billVolume) }}
          </p>
        </td>
        <!--Bill Cost slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            ${{ formatNumberToUS(item.billCost) }}
          </p>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {
  dateTimeToUSFormatFactory,
  formatNumberToUS,
} from '@/Core.Patterns/Factory';
export default {
  name: 'ROIPredictionUtilityBills',

  props: {
    utilityBills: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        {
          title: 'Register Name',
          value: 'registerName',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Start Date',
          value: 'billStartDate',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'End Date',
          value: 'billEndDate',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Bill Volume',
          value: 'billVolume',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Bill Cost',
          value: 'billCost',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
      ],
      headerProps: {
        class: 'text-primary text-subtitle-1 font-weight-bold text-center',
      },
      footerProps: {
        class: 'text-primary text-subtitle-1 font-weight-bold',
      },
    };
  },

  methods: {
    dateTimeToUSFormatFactory,
    formatNumberToUS,
  },
};
</script>
<style lang="scss" scoped>
tbody {
  tr:nth-child(odd) {
    background-color: #f5f5f5;
  }
}
</style>
