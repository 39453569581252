/**
 * @param {Object} obj
 * @param {Array} keys
 * @param {Array} newKeys
 * @returns Object
 * @description Remap object keys replacing old keys with new keys
 * @example RemapObjectKeysAdapter({ a: 1, b: 2, c: 3 }, ['a', 'b', 'c'], ['x', 'y', 'z']);
 * returns { x: 1, y: 2, z: 3 }
 */
export const RemapObjectKeysAdapter = (obj, keys, newKeys) => {
  keys.forEach((key, index) => {
    if (obj[key] !== undefined) {
      obj[newKeys[index]] = obj[key];
      delete obj[key];
    }
  });
  return obj;
};

/**
 *
 * @param {*} str
 * @returns Object
 * @description Capitalize first letter of a string
 * @example capitalizeFirstLetter('hello') returns 'Hello'
 *
 */
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => capitalizeKeys(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = capitalizeFirstLetter(key);
        newObj[newKey] = capitalizeKeys(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
};
