<template>
  <v-card :width="cardWidth" height="100%" class="rounded-2" elevation="24">
    <!--Card Header-->
    <v-toolbar color="primary" height="88">
      <v-container>
        <v-row>
          <v-col class="d-flex">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-toolbar-title
                  class="text-aliceBlue font-weight-bold ml-1 text-h5 mt-1"
                  v-bind="props"
                >
                  {{ siteInfo.name }}
                </v-toolbar-title>
              </template>
              <span>{{ siteInfo.name }}</span>
            </v-tooltip>
            <div v-for="icon in toolBarIcons" :key="icon.icon">
              <!-- Bell button -->
              <v-tooltip
                location="top"
                v-if="icon.isBadge && icon.showIcon"
                v-model="showNotificationsTooltip"
              >
                <template
                  v-slot:activator="{ props }"
                  v-if="notificationsCounter > 0"
                >
                  <v-badge
                    :content="notificationsCounter"
                    location="top start"
                    offset-y="5"
                    offset-x="1"
                    color="heatColor"
                  >
                    <v-btn
                      v-bind="props"
                      :ripple="false"
                      :loading="isNotificationsLoading"
                      @mouseleave="showNotificationsTooltip = false"
                      @mouseover="showNotificationsTooltip = true"
                      @click="() => handleNotificationsBellClick()"
                      :icon="true"
                      variant="text"
                      density="comfortable"
                      class="mr-n2"
                    >
                      <v-icon color="aliceBlue" v-if="!isNotificationsLoading">
                        {{ icon.icon }}
                      </v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span>{{ notificationsCounterMessage }}</span>
              </v-tooltip>
              <!-- Map button -->
              <v-tooltip
                location="top"
                v-else-if="icon.showIcon"
                v-model="showTooltip"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    :ripple="false"
                    :loading="isSiteDetailsLoading"
                    @mouseleave="showTooltip = false"
                    @mouseover="showTooltip = true"
                    @click="() => handleSiteDetailsClick()"
                    :icon="true"
                    variant="text"
                    density="comfortable"
                    class="mr-n2"
                  >
                    <v-icon color="aliceBlue" v-if="!isSiteDetailsLoading">
                      {{ icon.icon }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>View Site Details</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex mt-n5">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span
                  class="text-aliceBlue ml-1 text-caption text-truncate"
                  v-bind="props"
                >
                  {{ siteInfo.streetAddress }}
                </span>
              </template>
              <span>{{ siteInfo.streetAddress }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <!--Card Body-->
    <v-container>
      <v-row>
        <v-col>
          <div class="image-container mx-1">
            <v-img
              :src="siteInfo.siteImageURL || fallBackImage"
              class="rounded ma-1"
              :height="imageHeight"
              cover
            >
              <WeatherWidget
                :weatherData="siteInfo.siteWeatherAndEnergyDataResourceModel"
              />
            </v-img>
          </div>
          <SliderVue :siteInfo="siteInfo" />
        </v-col>
      </v-row>
    </v-container>
    <!--Card Footer-->
    <v-divider />
    <v-card-actions>
      <ActionButtonsVue :buttonsList="actionButtons" />
    </v-card-actions>

    <MapDialogVue
      v-if="showMapDialog"
      :dialog="showMapDialog"
      @update:dialog="(value) => (showMapDialog = value)"
      :siteDetails="siteDetails"
    />

    <NotificationsDialogVue
      v-if="showNotificationsDialog"
      :dialog="showNotificationsDialog"
      @update:dialog="(value) => handleNotificationsDialogUpdate(value)"
      :siteInfo="siteInfo"
    />
  </v-card>
</template>

<script>
import NotificationsDialogVue from '@/Core.Service.Domain/ViewSupport/Notifications/Components/NotificationsDialog.vue';
import API from '@/Core.Service.Domain/ViewSupport/Portfolio/API';
import MapDialogVue from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/MapDialog';
import SliderVue from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/Slider';
import WeatherWidget from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/WeatherWidget.vue';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SiteCard',

  components: {
    SliderVue,
    MapDialogVue,
    ActionButtonsVue,
    NotificationsDialogVue,
    WeatherWidget,
  },

  props: {
    siteInfo: {
      type: Object,
      required: true,
    },
    toolBarIcons: {
      type: Array,
      default: () => [
        {
          icon: 'mdi-bell-outline',
          showIcon: true,
          isBadge: true,
        },
        {
          icon: 'mdi-map-marker-circle',
          showIcon: true,
        },
      ],
    },
  },

  created() {
    this.setNotificationsCounterBySite({
      siteId: this.siteInfo.siteId,
      notificationsCounter: this.siteInfo.notificationsCounter,
    });
  },

  computed: {
    ...mapGetters({
      notificationsCounterBySite:
        'ViewSupportStore/NotificationsStore/getNotificationsCounterBySite',
    }),

    notificationsCounter() {
      return this.filterNotificationsBySiteId();
    },

    notificationsCounterMessage() {
      if (this.notificationsCounter === 0) {
        return 'No New Notifications';
      }
      return this.notificationsCounter > 1
        ? `You have ${this.notificationsCounter} Unread Notifications. Click to view.`
        : `You have ${this.notificationsCounter} Unread Notification. Click to view.`;
    },

    isDaikinSite() {
      return this.siteInfo.siteId === 275;
    },

    actionButtons() {
      return [
        {
          icon: 'chart-timeline-variant-shimmer',
          toolTip: 'Submetering Report',
          action: () => {
            this.handleButtonAction(
              'PortfolioReportRoute',
              this.siteInfo.powerBiReports.SubMeter.name
            );
          },
          show: this.siteInfo.hasSubMeter,
        },
        {
          icon: 'thermometer',
          toolTip: 'Thermostats',
          action: async () => {
            await this.setDefaultSite({
              id: this.siteInfo.siteId,
              name: this.siteInfo.name,
            });
            this.$router.push({
              name: this.isDaikinSite ? 'DaikinThermostats' : 'Thermostats',
            });
          },
          show: this.siteInfo.hasThermostat || this.isDaikinSite,
        },
        {
          icon: 'power-settings',
          toolTip: 'Power Controls',
          action: () => {
            this.setDefaultSite({
              id: this.siteInfo.siteId,
              name: this.siteInfo.name,
            }).then(() => {
              this.$router.push({
                name: 'PowerControls',
              });
            });
          },
          show: this.siteInfo.hasPowerControl,
        },
        {
          icon: 'chart-scatter-plot',
          toolTip: 'Utility Data Report',
          action: () => {
            this.handleButtonAction(
              'PortfolioReportRoute',
              this.siteInfo.hasManualMonitor
                ? this.siteInfo.powerBiReports.UtilityByPeriod.name
                : this.siteInfo.powerBiReports.Utility.name
            );
          },
          show: this.siteInfo.hasUtility,
        },
        {
          icon: 'air-filter',
          toolTip: 'IAQ Report',
          action: () => {
            this.handleButtonAction(
              'PortfolioReportRoute',
              this.siteInfo.powerBiReports.IAQ.name
            );
          },
          show: this.siteInfo.hasIAQ,
        },
        {
          icon: this.isDaikinSite ? 'thermometer-lines' : 'resistor',
          toolTip: this.isDaikinSite ? 'Thermostat Report' : 'Sensor Report',
          action: () => {
            this.handleButtonAction(
              'PortfolioReportRoute',
              this.isDaikinSite
                ? 'Daikin Thermostat Report'
                : this.siteInfo.powerBiReports.Sensor.name
            );
          },
          show: this.siteInfo.hasSensor,
        },
        {
          icon: 'wrench',
          toolTip: 'Upload Manual Utility Data',
          action: () => {
            this.handleButtonAction('SubMeterManualmonitorUpload');
          },
          show: this.siteInfo.hasManualMonitor,
        },
        {
          icon: 'domain',
          toolTip: 'Digital Twin',
          action: async () => {
            await this.setDefaultSite({
              id: this.siteInfo.siteId,
              name: this.siteInfo.name,
            });
            this.$router.push({
              name: 'DigitalTwin',
            });
          },
          show: this.siteInfo.siteId === 364, // This will be replaced with the correct flag
        },
      ];
    },
  },

  data() {
    return {
      fallBackImage:
        'https://energy360sr.blob.core.windows.net/energy-360-site-images/large-building.jpg',
      cardWidth: 370,
      imageHeight: 160,
      showMapDialog: false,
      showTooltip: false,
      showNotificationsDialog: false,
      showNotificationsTooltip: false,
      isNotificationsLoading: false,
      isSiteDetailsLoading: false,
      siteDetails: {},
    };
  },

  methods: {
    ...mapActions({
      setDefaultSite: 'session/setDefaultSite',
      getNotificationsBySiteId:
        'ViewSupportStore/NotificationsStore/getNotificationsBySiteId',
    }),

    ...mapMutations({
      setNotificationsCounterBySite:
        'ViewSupportStore/NotificationsStore/NOTIFICATIONS_COUNTER_BY_SITE',
    }),

    handleButtonAction(name, reportName = '') {
      // This function will be replaced when the new views are ready
      this.$router.push({
        name,
        params: {
          reportName,
          siteId: this.siteInfo?.siteId,
        },
      });
    },

    async handleNotificationsBellClick() {
      this.isNotificationsLoading = true;
      try {
        await this.getNotificationsBySiteId(this.siteInfo.siteId);
        this.showNotificationsTooltip = false;
        this.showNotificationsDialog = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.isNotificationsLoading = false;
      }
    },

    async handleSiteDetailsClick() {
      this.isSiteDetailsLoading = true;
      try {
        this.siteDetails = await API.getPortfolioSiteById(this.siteInfo.siteId);
        this.showTooltip = false;
        this.showMapDialog = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.isSiteDetailsLoading = false;
      }
    },

    filterNotificationsBySiteId() {
      const foundCounter = this.notificationsCounterBySite.find(
        (notification) => notification.siteId === this.siteInfo.siteId
      );
      return foundCounter ? foundCounter.notificationsCounter : 0;
    },

    async handleNotificationsDialogUpdate(value) {
      this.showNotificationsDialog = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-badge ::v-deep(.v-badge__badge) {
  width: auto;
  letter-spacing: -1px;
  padding: 0.5px 5px;
  padding-left: 4px;
}

.v-btn {
  ::v-deep(.v-btn__content) {
    opacity: 1 !important;
  }
  ::v-deep(.v-btn__loader) {
    color: $aliceBlue;
  }
  ::v-deep(.v-btn__underlay),
  ::v-deep(.v-btn__overlay) {
    opacity: 0 !important;
  }
}

span {
  letter-spacing: 0px;
}

.image-container {
  position: relative;
  height: 168px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.v-image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}
</style>
