<template>
  <v-data-table
    :headers="headers"
    :items="siteCbecs"
    :items-per-page="10"
    :loading="!siteCbecs.length"
    :header-props="headerProps"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <!--Headers slot-->
    <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
      <tr>
        <th
          v-for="column in columns"
          :key="column.key"
          :class="column.class"
          @click="() => toggleSort(column)"
        >
          {{ column.title }}
          <template v-if="isSorted(column)">
            <v-icon :icon="getSortIcon(column)" size="sm" />
          </template>
        </th>
      </tr>
    </template>
    <!--Items slot-->
    <template v-slot:item="{ item }">
      <tr>
        <!--Square Footage slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
            :title="item.squareFootageValue"
          >
            {{ formatNumberToUS(item.squareFootage) }}
          </p>
        </td>
        <!--Annual District Heat Consumption slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            {{ formatNumberToUS(item.annualDistrictHeatConsumptionBtu) }}
          </p>
        </td>
        <!--Annual District Heat Expenditures slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            ${{ formatNumberToUS(item.annualDistrictHeatExpenditures) }}
          </p>
        </td>
        <!--Annual Electricity Consumption slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            {{ formatNumberToUS(item.annualElectricityConsumptionBtu) }}
          </p>
        </td>
        <!--Annual Electricity Consumption slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            ${{ formatNumberToUS(item.annualElectricityConsumptionHwh) }}
          </p>
        </td>
        <!--Annual Electricity Expenditures slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            ${{ formatNumberToUS(item.annualElectricityExpenditures) }}
          </p>
        </td>
        <!--Annual Fuel Oil Consumption slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            {{ formatNumberToUS(item.annualFuelOilConsumptionBtu) }}
          </p>
        </td>
        <!--Annual Fuel Oil Expenditures slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            ${{ formatNumberToUS(item.annualFuelOilExpenditures) }}
          </p>
        </td>
        <!--Annual Major Fuel Consumption slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            {{ formatNumberToUS(item.annualMajorFuelConsumptionBtu) }}
          </p>
        </td>
        <!--Annual Major Fuel Expenditures slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            ${{ formatNumberToUS(item.annualMajorFuelExpenditures) }}
          </p>
        </td>
        <!--Annual Natural Gas Consumption slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            {{ formatNumberToUS(item.annualNaturalGasConsumptionBtu) }}
          </p>
        </td>
        <!--Annual Natural Gas Expenditures slot-->
        <td>
          <p
            class="text-primary text-subtitle-1 text-sm-subtitle-1 text-center"
          >
            ${{ formatNumberToUS(item.annualNaturalGasExpenditures) }}
          </p>
        </td>
        <!--HVAC Equipment Upgrade slot-->
        <td>
          <p class="text-center">
            <v-icon :color="handleIconColor(item.hvacEquipmentUpgrade)">
              {{ handleSuccess(item.hvacEquipmentUpgrade) }}
            </v-icon>
          </p>
        </td>
        <!--Lighting Upgrade slot-->
        <td>
          <p class="text-center">
            <v-icon :color="handleIconColor(item.lightingUpgrade)">
              {{ handleSuccess(item.lightingUpgrade) }}
            </v-icon>
          </p>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {
  dateTimeToUSFormatFactory,
  formatNumberToUS,
} from '@/Core.Patterns/Factory';
export default {
  name: 'ROIPredictionCbecs',

  props: {
    siteCbecs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        {
          title: 'Square Footage',
          value: 'squareFootage',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Heat Consumption/Yr',
          value: 'annualDistrictHeatConsumptionBtu',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Heat Cost/Yr',
          value: 'annualDistrictHeatExpenditures',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Electricity Consumption/Yr',
          value: 'annualElectricityConsumptionBtu',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Electricity Cost/Yr',
          value: 'annualElectricityConsumptionHwh',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Electricity Expenditures/Yr',
          value: 'annualElectricityExpenditures',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Fuel Oil Consumption/Yr',
          value: 'annualFuelOilConsumptionBtu',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Fuel Oil Expenditures/Yr',
          value: 'annualFuelOilExpenditures',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Major Fuel Consumption/Yr',
          value: 'annualMajorFuelConsumptionBtu',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Major Fuel Expenditures/Yr',
          value: 'annualMajorFuelExpenditures',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Natural Gas Consumption/Yr',
          value: 'annualNaturalGasConsumptionBtu',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Natural Gas Expenditures/Yr',
          value: 'annualNaturalGasExpenditures',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'HVAC Equipment Upgrade',
          value: 'hvacEquipmentUpgrade',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
        {
          title: 'Lighting Upgrade',
          value: 'lightingUpgrade',
          class: 'text-primary text-subtitle-1 font-weight-bold text-center',
          align: 'end',
        },
      ],
      headerProps: {
        class: 'text-primary text-subtitle-1 font-weight-bold text-center',
      },
      footerProps: {
        class: 'text-primary text-subtitle-1 font-weight-bold',
      },
    };
  },

  methods: {
    dateTimeToUSFormatFactory,
    formatNumberToUS,

    handleSuccess(item) {
      if (item === null) return 'mdi-set-none';
      return item ? 'mdi-check' : 'mdi-minus-circle';
    },

    handleIconColor(item) {
      if (item === null) return 'disabled';
      return item ? 'greenGraph' : 'heatColor';
    },
  },
};
</script>
<style lang="scss" scoped>
tbody {
  tr:nth-child(odd) {
    background-color: #f5f5f5;
  }
}
</style>
