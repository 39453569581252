<template>
  <v-dialog
    :model-value="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="font-weight-bold">
          My Messages
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-5">
        <MessageCenterMessagesList />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts" setup>
import MessageCenterMessagesList from '@/Core.Service.Domain/MessageCenter/Users/Components/MessageCenterMessagesList.vue';

import { useRouter } from 'vue-router';

defineProps(['dialog']);
const emits = defineEmits(['closeDialog']);

const router = useRouter();
const closeDialog = () => {
  emits('closeDialog');
  router.push({ name: 'Portfolio' });
};
</script>
<script lang="ts">
export default {
  name: 'MessageCenterDialog',
};
</script>
