import { ref, markRaw, Raw, defineAsyncComponent } from 'vue';

const BuildingTwo = defineAsyncComponent({
  loader: () =>
    import(
      '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/6822-02.vue'
    ),

  loadingComponent: LoadingComponent,
});
const BuildingThree = defineAsyncComponent({
  loader: () =>
    import(
      '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/6822-03.vue'
    ),

  loadingComponent: LoadingComponent,
});

import LoadingComponent from '@/Core.Service.Domain/Controls/DigitalTwin/Components/ImageLoader.vue';

import {
  IFloor,
  IVAV,
} from '@/Core.Service.Domain/Controls/DigitalTwin/types/index.ts';

import firstFloor from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_1st_Floor/floor.ts';

export const dialog = ref<boolean>(false);
export const childTab = ref<number>(0);
export const selectedVAVId = ref<number>();

export const isClickFromCommonArea = ref<boolean>(false);

export const vav = ref<IVAV>({} as IVAV);

export const floors = ref<IFloor<Raw<unknown>>[]>([
  { ...firstFloor },
  {
    id: 1,
    isDisabled: true,
    name: 'MGMOB - 2nd Floor',
    loadedName: '6822-01',
    ahuId: 2092,
    component: markRaw(BuildingTwo),
    hotspotsList: [],
    childrenSuites: [],
    activeTooltip: null,
  },
  {
    id: 2,
    isDisabled: true,
    name: 'MGMOB - 3rd Floor',
    loadedName: '6822-03',
    ahuId: 2093,
    component: markRaw(BuildingThree),
    hotspotsList: [],
    childrenSuites: [],
    activeTooltip: null,
  },
]);
