<template>
  <div class="ml-3 mt-8">
    <v-row>
      <v-col v-for="model in modelsList" :key="model.id">
        <ModelCardVue :ModelInfo="model" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import ModelCardVue from '@/Core.Service.Domain/Analytics/Models/Components/ModelCard';

export default {
  name: 'ModelsList',

  components: {
    ModelCardVue,
  },

  props: {
    ModelsList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      searchTerm:
        'AnalyticsStore/AnalyticsModelsStore/getAnalyticsModelsSearchTerm',
    }),

    modelsList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems)
        : sortAlphabetically(this.ModelsList);
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['name', 'siteName'],
        this.ModelsList
      );
    },
  },

  data() {
    return {
      filteredItems: [],
      cardsPerRow: 3,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-col {
  flex-grow: 0;
}
</style>
