<template>
  <v-container fluid>
    <v-app-bar
      height="100"
      class="px-10"
      flat
      border
      v-if="getSiteName && getStrategyName"
    >
      <v-row>
        <v-col cols="12">
          <div>
            <span class="ml-6 text-h6 text-primary font-weight-bold">
              {{ `${getSiteName} - ${getStrategyName}` }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            v-model:return-value="date"
            transition="scale-transition"
            max-width="290px"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="date"
                label="Select Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="props"
              />
            </template>
            <v-date-picker
              v-model="date"
              :allowed-dates="allowedDates"
              @input="handleDate"
              no-title
              scrollable
              color="primary"
            >
              <v-btn variant="text" color="primary" @click="menu = false">
                Cancel
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="9">
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="filterRadio" inline>
                <v-radio
                  label="Has Actions"
                  color="greenGraph"
                  value="success"
                  hide-details
                  :disabled="!hasActions"
                  @click="handleFilter"
                ></v-radio>
                <v-radio
                  label="Has Errors"
                  color="heatColor"
                  value="error"
                  hide-details
                  :disabled="!hasErrors"
                  @click="handleFilter"
                ></v-radio>
                <v-radio
                  label="Show All"
                  color="primary"
                  value="all"
                  hide-details
                  @click="handleFilter"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-col v-if="isHistoryLoading">
      <ProgressLinearLoaderVue :isLoading="isHistoryLoading" />
    </v-col>
    <v-row v-else v-for="(strategy, index) in mappedStrategies" :key="index">
      <v-col cols="12" v-for="(history, key) in strategy" :key="key">
        <StrategyExecutionHistoryCard :StrategyTodaysHistory="history" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StrategyExecutionHistoryCard from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategyExecutionHistoryCard.vue';
import { mapActions, mapGetters } from 'vuex';

import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

export default {
  name: 'StrategyExecutionHistoryList',

  components: {
    StrategyExecutionHistoryCard,
    ProgressLinearLoaderVue,
  },

  props: {
    StrategyTodaysHistory: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      searchTerm: 'StrategiesStore/StrategiesStore/getStrategySearchTerm',
      isHistoryLoading: 'StrategiesStore/StrategiesStore/getIsHistoryLoading',
      defaultSite: 'session/defaultSite',
      strategyInfo: 'StrategiesStore/StrategiesStore/getStrategyInfo',
    }),

    getStrategyName() {
      return (
        this.StrategyTodaysHistory[0]?.strategyName ||
        `Please set this Strategy's Name`
      );
    },

    getSiteName() {
      return this.StrategyTodaysHistory[0]?.siteName;
    },
  },

  async created() {
    try {
      if (this.strategyInfo.selectedDate) {
        this.date = this.strategyInfo.selectedDate;
        this.setStrategyHistoryByDate({
          siteId: this.defaultSite.siteId,
          strategyId: this.$route.params.strategyId,
          date: this.strategyInfo.selectedDate,
        });
      } else {
        await this.setStrategyExecutionHistoryList({
          siteId: this.defaultSite.siteId,
          strategyId: this.$route.params.strategyId,
        });
      }

      this.sortHistoryByTime();

      const [history] = [...this.StrategyTodaysHistory];
      this.mappedStrategies = [this.groupByDeviceTypeAndName(history)];

      this.determineFilter();
    } catch (error) {
      console.error('Error fetching strategy history', error);
    }
  },

  watch: {
    StrategyTodaysHistory: {
      handler() {
        this.sortHistoryByTime();

        const [history] = [...this.StrategyTodaysHistory];
        this.mappedStrategies = [this.groupByDeviceTypeAndName(history)];

        this.hasActions = false;
        this.hasErrors = false;

        this.determineFilter();
      },
    },

    filterRadio(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleFilter();
      }
    },
  },

  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      menu: false,
      mappedStrategies: [],
      cardsPerRow: 2,
      filterRadio: 'success',
      hasErrors: false,
      hasActions: false,
    };
  },

  methods: {
    ...mapActions({
      setStrategyExecutionHistoryList:
        'StrategiesStore/StrategiesStore/getStrategyTodaysHistoryByStrategyId',
      setStrategyHistoryByDate:
        'StrategiesStore/StrategiesStore/getStrategyHistoryByDate',
    }),

    handleDate() {
      this.$store.commit('StrategiesStore/StrategiesStore/STRATEGY_INFO', {
        selectedDate: this.date,
      });
      this.$refs.menu.save(this.date);
      this.menu = false;
      this.setStrategyHistoryByDate({
        siteId: this.defaultSite.siteId,
        strategyId: this.$route.params.strategyId,
        date: this.date,
      });
    },

    allowedDates(val) {
      const selectedDate = new Date(val).toISOString().slice(0, 10);
      const todayDate = new Date().toISOString().slice(0, 10);
      return selectedDate <= todayDate;
    },

    sortHistoryByTime() {
      this.StrategyTodaysHistory.forEach((item) => {
        if (item.history && Array.isArray(item.history)) {
          item.history.sort((a, b) => {
            const dateTimeA = new Date(a.date);
            const dateTimeB = new Date(b.date);
            return dateTimeA - dateTimeB;
          });
        }
      });
    },

    groupByDeviceTypeAndName(logs) {
      const { siteName, strategyName } = logs;

      return logs.history.reduce((acc, log) => {
        const key = `${log.device.type}-${log.device.name}`;

        if (!acc[key]) {
          acc[key] = {
            deviceLabel: log.device.name || log.device.type,
            siteName,
            strategyName,
            history: [],
          };
        }

        acc[key].history.push(log);

        return acc;
      }, {});
    },

    handleFilter() {
      if (!this.StrategyTodaysHistory.length) return;

      const filteredHistory = this.StrategyTodaysHistory.flatMap((strategy) =>
        strategy.history.filter((log) =>
          this.filterRadio === 'success'
            ? log.action !== null
            : this.filterRadio === 'error'
            ? log.error !== null
            : true
        )
      );

      const [originalHistory] = [...this.StrategyTodaysHistory];
      const updatedHistory = {
        ...originalHistory,
        history: filteredHistory,
      };

      this.mappedStrategies = [this.groupByDeviceTypeAndName(updatedHistory)];
    },

    determineFilter() {
      const hasActions = this.StrategyTodaysHistory.some((strategy) =>
        strategy.history.some((log) => log.action !== null)
      );
      const hasErrors = this.StrategyTodaysHistory.some((strategy) =>
        strategy.history.some((log) => log.error !== null)
      );

      if (hasActions) {
        this.filterRadio = 'success';
        this.hasActions = true;
      } else if (hasErrors) {
        this.filterRadio = 'error';
        this.hasErrors = true;
      } else {
        this.filterRadio = 'all';
      }
      this.handleFilter();
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  flex-grow: 0;
}

.v-app-bar {
  top: 88px;
  right: 0;
  background-color: $aliceBlue !important;
}
</style>
