<template>
  <div>
    <!--Settings change Warning-->
    <v-alert
      border="start"
      :border-color="`rgb(${WeatherDescription().rgb.join(',')})`"
      color="white"
      elevation="2"
      :icon="false"
      class="text-primary"
    >
      <!-- <v-icon :color="`rgb(${WeatherDescription().rgb.join(',')})`">
        {{ WeatherDescription().icon }}
      </v-icon> -->
      <p>
        <strong>
          {{ WeatherDescription().title }}
        </strong>
      </p>
      <span v-html="WeatherDescription().description" />
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'WeatherAlert',

  props: {
    WeatherDescription: {
      type: Function,
      required: true,
    },
  },
};
</script>
