<template>
  <v-container grid-list-xl style="max-width: 1540px">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
    ></v-progress-linear>

    <form @submit.prevent="submit">
      <v-sheet elevation="2" class="pa-4">
        <v-row>
          <v-col>
            <h1>Model Detail</h1>
          </v-col>
        </v-row>
        <Form ref="obs2" v-slot="{ invalid }">
          <v-row>
            <v-col>
              <v-text-field
                :readonly="!$can('create', 'analytics')"
                v-model="analyticModel.name"
                label="Analysis Model Name"
                :rules="[
                  (v) => !!v || 'Model Name is required',
                  (v) => this.duplicateName(v, 'name'),
                ]"
                name="name"
                required
                ref="modelNameInput"
                variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto" md sm="12">
              <v-select
                :items="resourceTypeOptions"
                :readonly="!$can('delete', 'analytics')"
                item-value="value"
                item-title="text"
                v-model="resourceType"
                label="Resource Type"
                hint="What resource type do you want to analyze"
                persistent-hint
                @change-value="setEndUseOptions"
                ref="resourceTypeSelect"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto" md sm="12">
              <v-select
                :rules="[(v) => !v.length || 'Analysis Type is required']"
                :readonly="!$can('delete', 'analytics')"
                :items="analysisTypes"
                item-value="id"
                item-title="analysisTypeName"
                v-model="analyticModel.analysisType"
                label="Select Analysis Type"
                hint="What type of analysis do you want to perform"
                persistent-hint
                ref="analysisTypeSelect"
                returnObject
              ></v-select>
            </v-col>

            <v-col cols="auto" md="6" sm="12">
              <v-select
                :readonly="!$can('delete', 'analytics')"
                :items="endUseOptions"
                v-model="selectedEndUses"
                label="Select End Use(s)"
                persistent-hint
                hint="Select end uses for analysis"
                @change-value="setEndUses"
                ref="endUseSelect"
                multiple
                :rules="[(v) => !v.length || 'End Uses are required']"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto" md="6" sm="12">
              <!-- <v-select
                rules="occupancy_dayOfWeek"
                :items="factorOptions"
                v-model="selectedFactors"
                label="Select Factor(s)"
                multiple
                persistent-hint
                hint="Select factors for analysis"
                @change="setFactors"
                ref="factorSelect"
              ></v-select> -->
              <v-select
                :items="factorOptions"
                v-model="selectedFactors"
                item-props="disabled"
                item-value="value"
                item-title="text"
                label="Select Factor(s)"
                multiple
                persistent-hint
                hint="Select factors for analysis"
                @change-value="setFactors"
                ref="factorSelect"
              ></v-select>
            </v-col>
            <v-col cols="auto" md="6" sm="12">
              <v-select
                :items="nreOptions"
                item-title="name"
                item-value="id"
                label="Select NRE(s)"
                multiple
                persistent-hint
                hint="Select NRE(s) for analysis"
                ref="nreSelect"
                return-object
                @update:modelValue="handleSetNREs"
              ></v-select>
            </v-col>
          </v-row>
          <v-layout wrap justify-space-between>
            <div>
              <v-list-subheader class="pl-0"
                >Basis for Cooling Degree Days</v-list-subheader
              >
              <v-row>
                <v-slider
                  v-model="analyticModel.degreeDaysBaseCooling"
                  :min="40"
                  :max="120"
                  hide-details
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="analyticModel.degreeDaysBaseCooling"
                      :rules="[
                        (v) => !!v || 'Degree Days Base Cooling is required',
                        (v) =>
                          this.validDegreeDaysRange(
                            v,
                            'degree days base cooling'
                          ),
                      ]"
                      name="degree days base cooling"
                      class="mt-0 pt-0"
                      hide-details
                      type="number"
                      style="width: 60px"
                      required
                    ></v-text-field>
                  </template>
                </v-slider>
              </v-row>
            </div>

            <div>
              <v-list-subheader class="pl-0"
                >Basis for Heating Degree Days</v-list-subheader
              >
              <v-row>
                <v-slider
                  v-model="analyticModel.degreeDaysBaseHeating"
                  :min="40"
                  :max="120"
                  hide-details
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="analyticModel.degreeDaysBaseHeating"
                      :rules="[
                        (v) => !!v || 'Degree Days Base Heating is required',
                        (v) =>
                          this.validDegreeDaysRange(
                            v,
                            'degree days base heating'
                          ),
                      ]"
                      name="degree days base heating"
                      class="mt-0 pt-0"
                      hide-details
                      type="number"
                      style="width: 60px"
                      required
                    ></v-text-field>
                  </template>
                </v-slider>
              </v-row>
            </div>
          </v-layout>
          <start-end-date-picker
            name="date range"
            rules="required|valid_date_range"
            v-model="dateRange"
            @change="setDateRange"
            date-format="yyyy-MM-dd"
            ref="startEndDatePicker"
          ></start-end-date-picker>
          <v-row>
            <label class="pa-3">Comments</label>
            <v-textarea
              rows="2"
              clearable
              clear-icon="mdi-close-circle"
              variant="outlined"
              auto-grow
              v-model="analyticModel.comment"
              class="pr-3"
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-if="$can('create', 'analytics')"
                type="submit"
                color="secondary"
                class="mr-5"
              >
                Save
              </v-btn>
              <v-btn @click="handleCancelCrud">Cancel</v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <stepper-validate
              :invalid="invalid"
              :modelName="analyticModel.name"
              :factors="selectedFactors"
              :startDateTime="dateRange.startDateTime"
              :endDateTime="dateRange.endDateTime"
              :dateRange="dateRange"
              :nameValidationResult="nameValidationResult"
              :dataValidationResult="dataValidationResult"
              @dismissdialog="dismissDialog"
              @fixmodel="fixModelErrors"
              ref="stepper"
            ></stepper-validate>
          </v-row>
        </Form>
      </v-sheet>
    </form>

    <v-sheet>
      <highcharts :options="chartOptions" ref="evalChart" v-if="showChart" />
    </v-sheet>
    <span class="px-4 text-caption text-primary">
      {{ $appOldVersion }}
    </span>
  </v-container>
</template>
<style>
.v-input__icon.v-input__icon--clear {
  margin-top: 2px !important;
}
</style>
<script>
import { Chart } from 'highcharts-vue';
import { Form, defineRule } from 'vee-validate';
import moment from 'moment';
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker';
import StepperValidate from './StepperValidate.vue';
import api from '../../analytics_models/_api';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AnalyticsModelDetailsModule',
  components: {
    highcharts: Chart,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    'start-end-date-picker': StartEndDatePicker,
    'stepper-validate': StepperValidate,
  },

  data() {
    return {
      analyticModels: [],
      analyticModel: {
        analyticFactors: [],
        analyticType: {
          id: 1,
          analyticTypeName: 'MandV',
        },
        analysisType: {
          id: 0,
          analysisTypeName: 'Linear Regression',
        },
        degreeDaysBaseCooling: 65,
        degreeDaysBaseHeating: 65,
        startDateTime: null,
        endDateTime: null,
        nonRoutineEvents: [],
      },
      error: null,
      loading: true,

      name: 'Model Detail',

      dateRange: {},
      dataValidationResult: null,
      nameValidationResult: null,

      nameIndex: 1,
      energyIndex: 2,
      weatherIndex: 3,
      factorIndex: 4,

      isValidModel: true,
      isValidFactor: false,
      siteId: this.$route.params.siteId,
      selectedEndUses: [],
      selectedFormula: '',
      selectedAnalysisTypeId: null,
      resourceType: '',
      selectedFactors: [],

      endUses: [],
      endUseOptions: [],
      analysisTypes: [],
      nreOptions: [],
      formulaOptions: [
        { value: '1', text: 'Production Capacity' },
        { value: '2', text: 'Adjusted Occupancy' },
      ],
      factorOptions: [],
      resourceTypeOptions: [
        { value: 'Electricity', text: 'Electricity' },
        { value: 'Gas', text: 'Gas' },
        { value: 'Water', text: 'Water' },
        { value: 'Solar', text: 'Solar' },
      ],

      startMenu: false,
      endMenu: false,
      showChart: false,

      chartOptions: {
        credits: { enabled: false },
        chart: {
          type: 'line',
          zoomType: 'x',
          borderWidth: 1,
          borderColor: '#efefef',
        },
        title: {
          text: 'Model Predictions',
        },
        subtitle: {
          text: 'R2: N/A - NMBE: N/A - CV(RMSE): N/A',
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          labels: {
            rotation: -45,
            formatter: function () {
              if (!this.value) return '';

              return moment(new Date(this.value)).format('YYYY-M-D');
            },
          },
        },
        yAxis: {
          title: {
            text: 'kWh',
          },
        },
        tooltip: {
          pointFormat: '{point.y}',
          valueSuffix: 'kWh',
          valueDecimals: '4',
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [{}],
      },
    };
  },

  async mounted() {
    this.dateRange = {
      startDateTime: new Date().toISOString().substring(0, 10),
      endDateTime: new Date().toISOString().substring(0, 10),
    };
  },

  async created() {
    this.getAnalyticFactors();
    this.getAnalysisTypes();
    this.getEndUses();
    this.getNREs();
    this.registerCustomValidators();
  },

  computed: {
    ...mapGetters('session', ['jwtName', 'jwtPicture']),
  },

  methods: {
    ...mapActions({}),

    getAvatar() {
      return this.jwtPicture;
    },

    setFormula() {},

    setDateRange(e) {
      this.analyticModel.startDateTime = e.startDateTime;
      this.analyticModel.endDateTime = e.endDateTime;

      this.dateRange = {
        startDateTime: e.startDateTime,
        endDateTime: e.endDateTime,
      };
    },

    submit() {
      this.postModel();
    },

    async postModel() {
      const newAnalyticModel = {
        modelName: this.analyticModel.name,
        siteId: this.siteId,
        siteName: null,
        start: this.analyticModel.startDateTime,
        end: this.analyticModel.endDateTime,
        analysisTypeName: this.analyticModel.analysisType.analysisTypeName,
        analysisTypeId: this.analyticModel.analysisType.id,
        analyticTypeId: this.analyticModel.analyticType.id,
        analyticTypeName: this.analyticModel.analyticType.analyticTypeName,
        degreeDaysBaseCooling: this.analyticModel.degreeDaysBaseCooling,
        degreeDaysBaseHeating: this.analyticModel.degreeDaysBaseHeating,
        comment: this.analyticModel.comment,
        factors: this.analyticModel.analyticFactors,
        endUses: this.analyticModel.endUses,
        nonRoutineEvents: this.analyticModel.nonRoutineEvents,
      };
      await api
        .createAnalyticModel(this.siteId, newAnalyticModel)
        .then((response) => {
          console.log(response);
          this.$toast.show('Model created', null, 'success');
          this.$router.push({ name: 'Models' });
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    //ENDUSES - START
    async getEndUses() {
      await api
        .getEndUses(this.siteId)
        .then((response) => {
          this.endUses = response;
          this.endUseOptions = this.mapEndUseLookup(
            this.endUses,
            this.resourceType
          );
          this.selectedEndUses = this.mapAnalyticsEndUses(
            this.analyticModel.endUses
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.loading = false));
    },

    setEndUses(item) {
      this.analyticModel.endUses = this.mapSelectedEndUses(item);
    },

    mapSelectedEndUses(endUses) {
      const makeEndUse = (selectedItem) => {
        const selectedEndUseOption = this.endUseOptions.find(
          (o) => o.value === selectedItem
        );

        return {
          endUseId: selectedItem,
          endUseName: selectedEndUseOption.text,
          resourceType: 'Electricity', //get this value from the resource type selection
          endUseUnitOfMeasureName: selectedEndUseOption.endUseUnitOfMeasureName,
          selected: true,
          siteId: this.siteId, //temporary
        };
      };

      const eu = endUses.map(makeEndUse);
      return eu;
    },

    mapAnalyticsEndUses(enduses) {
      const extractEndUse = (item) => {
        const endUseToExtract = this.endUseOptions.find(
          (o) => o.text === item.endUseName
        );
        return endUseToExtract.value;
      };

      if (!enduses || enduses?.length === 0) {
        return [];
      }
      const af = enduses.map(extractEndUse);
      return af;
    },

    setEndUseOptions(selectedResource) {
      this.endUseOptions = this.mapEndUseLookup(this.endUses, selectedResource);
    },

    mapEndUseLookup(endUses, resourceTypeName) {
      const makeOptionItems = (item) => {
        if (item.resourceType === resourceTypeName) {
          return {
            value: item.endUseId,
            text: item.endUseName,
            endUseUnitOfMeasureName: item.endUseUnitOfMeasureName,
            resourceType: item.resourceType,
          };
        }
      };

      const eul = endUses.map(makeOptionItems);
      return eul.filter((e) => {
        return e != undefined;
      });
    },
    //ENDUSES - END

    //ANALYSISTYPES - START
    async getAnalysisTypes() {
      api
        .getAnalysisTypes()
        .then((response) => {
          this.analysisTypes = response;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.loading = false));
    },
    //ANALYSISTYPES - END

    //FACTORS - START
    async getAnalyticFactors() {
      api
        .getAnalyticFactors()
        .then((response) => {
          this.factorOptions = this.mapFactorLookup(response);
          this.selectedFactors = [1];
          this.setFactors(this.selectedFactors);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.loading = false));
    },

    mapFactorLookup(factors) {
      const makeOptionItems = (item) => {
        return {
          value: item.id,
          text: item.factorName,
          analyticFactorName: item.analyticFactorName,
          selected: item.selected,
          disable: item.disable,
        };
      };
      const fl = factors.map(makeOptionItems);
      return fl;
    },

    setFactors(ids) {
      this.analyticModel.analyticFactors = this.mapSelectedFactors(ids);
    },

    mapSelectedFactors(factors) {
      const makeFactor = (selectedItem) => {
        const selectedFactorOption = this.factorOptions.find(
          (o) => o.value === selectedItem
        );

        return {
          id: selectedItem,
          analyticFactorName: selectedFactorOption.analyticFactorName,
          factorName: selectedFactorOption.text,
          selected: true,
        };
      };
      const sf = factors.map(makeFactor);
      return sf;
    },

    mapanalyticFactors(factors) {
      const extractFactor = (item) => {
        const factorToExtract = this.factorOptions.find(
          (o) => o.analyticFactorName == item.analyticFactorName
        );
        return factorToExtract.value;
      };

      const af = factors.map(extractFactor);
      return af;
    },
    //FACTORS - END

    //NRES - START
    async getNREs() {
      api
        .getNREs(this.siteId)
        .then((response) => {
          this.nreOptions = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    handleSetNREs(selectedNREs) {
      this.analyticModel.nonRoutineEvents = selectedNREs.map((x) => {
        return { ...x, selected: true };
      });
    },
    //NRES -END

    duplicateName(value, field) {
      let existingNames = this.analyticModels.map((a) => a.name);
      let valid = true;
      valid = existingNames.indexOf(value) === -1;
      if (!valid) {
        return `The ${field} is already used by another model.  Enter a different name.`;
      }

      return valid;
    },

    validDegreeDaysRange(value, field) {
      let withinRange = value > 40 && value < 120;
      if (!withinRange) {
        return `The ${field} must be between 40 and 120.`;
      }

      return withinRange;
    },
    registerCustomValidators() {
      // defineRule('valid_degreeDays_range', (value, [field]) => {
      //   let withinRange = value > 40 && value < 120;
      //   if (!withinRange) {
      //     return `The ${field} must be between 40 and 120.`;
      //   }

      //   return withinRange;
      // });

      defineRule('formula_or_factors', (value, [field]) => {
        let noFactors = this.selectedFactors.length === 0;
        if (!noFactors) {
          return `You have factors selected.  Clear factors before selecting the ${field} formula`;
        }
        return noFactors;
      });
    },

    mapChartSeriesData(predictions) {
      const makeSeriesDataPoint = (item, property) => {
        const unixDate = moment(item.date).valueOf();
        const value = item[property];
        return [unixDate, value];
      };
      const seriesData = [
        {
          name: 'Actual',
          data: predictions.map((p) => makeSeriesDataPoint(p, 'kWh')),
        },
        {
          name: 'Predicted',
          data: predictions.map((a) => makeSeriesDataPoint(a, 'prediction')),
        },
      ];
      return seriesData;
    },

    ///Validate Dialog
    async validateModel() {
      let isValid = await this.$refs.obs2.validate();
      if (!isValid) {
        return;
      }
      this.nameValidationResult = await api.validateAnalyticModelNameForCreate(
        this.siteId,
        this.analyticModel.name
      );
      this.dataValidationResult = await api.validateAnalyticModelDataForRange(
        this.siteId,
        this.analyticModel.startDateTime,
        this.analyticModel.endDateTime
      );
      this.isValidFactor = this.selectedFactors.length > 0;
      this.isValidModel =
        this.dataValidationResult.validation &&
        this.nameValidationResult &&
        this.isValidFactor;
      this.$refs.stepper.validateModel();
    },

    fixModelErrors(validationItem) {
      let step = validationItem.item;
      let newDateRange = validationItem.newDateRange;
      switch (step) {
        case this.nameIndex:
          this.$nextTick().then(() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            this.$refs.modelNameInput.$refs.input.focus();
          });
          break;
        case this.factorIndex:
          this.$nextTick().then(() => {
            this.$refs.factorSelect.$refs.validateSelectField.focus();
          });
          break;
        case this.weatherIndex:
        case this.energyIndex:
          this.$nextTick().then(() => {
            this.dateRange = newDateRange;
            this.$refs.startEndDatePicker.$refs.startPicker.focus();
          });
          break;
        default:
          break;
      }

      this.isValidModel = false;
    },

    dismissDialog() {
      console.log('dialog dismissed');
    },

    handleCancelCrud() {
      this.$router.push({ name: 'Models' });
    },
  },
};
</script>
