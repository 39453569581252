<template>
  <v-card
    class="pa-4 mb-7"
    :class="{ aliceBlue: isSiteLocationEditMode || isSiteLocationAddMode }"
  >
    <v-row>
      <v-col cols="7">
        <v-select
          :items="siteLocationsForMonitor"
          :item-value="(item) => item.id"
          :model-value="MonitorStatus.siteLocationId"
          item-title="siteLocationName"
          hint="What location is this monitor installed in?"
          persistent-hint
          variant="outlined"
          v-if="!isSiteLocationEditMode && !isSiteLocationAddMode"
        >
          <template v-slot:label>
            <span class="text-primary text-caption font-weight-bold">
              Site Location
            </span>
          </template>
        </v-select>
        <v-text-field
          ref="siteLocationNameEditMode"
          :model-value="siteLocation.siteLocationName"
          required
          hint="Edit this site location's name"
          persistent-hint
          append-icon="mdi-square-edit-outline"
          class="text--enabled"
          variant="outlined"
          v-if="isSiteLocationEditMode && !isSiteLocationAddMode"
        >
          <template v-slot:label>
            <span class="text-primary text-caption font-weight-bold">
              Site Location - EDIT SITE LOCATION NAME
            </span>
          </template>
        </v-text-field>
        <v-text-field
          ref="siteLocationNameAddMode"
          v-model="newSiteLocationName"
          required
          hint="Add a new site location's name"
          persistent-hint
          append-icon="mdi-pencil-plus"
          class="text--enabled"
          variant="outlined"
          v-if="!isSiteLocationEditMode && isSiteLocationAddMode"
        >
          <template v-slot:label>
            <span class="text-primary text-caption font-weight-bold">
              Site Location - ADD SITE LOCATION NAME
            </span>
          </template>
        </v-text-field>
      </v-col>
      <!-- Add/Edit Buttons -->
      <v-col>
        <v-btn
          v-if="isSiteLocationEditMode"
          class="rounded-2 mr-2"
          color="primary"
          variant="flat"
          :loading="isEditLoading"
          :disabled="!isMonitorDirty"
          @click="handleSaveEditSiteLocation"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold px-1"
          >
            Save
          </div>
        </v-btn>
        <v-tooltip location="bottom" v-else>
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn
              class="rounded-2 mr-2"
              color="primary"
              v-bind="activatorProps"
              variant="flat"
              :loading="isEditLoading"
              :disabled="isAddButtonDisabled"
              @click="handleAddButtonClick"
            >
              <div
                class="text-subtitle-2 text-white text-truncate font-weight-bold px-1"
              >
                Add
              </div>
            </v-btn>
          </template>
          <span> Add New Site Location </span>
        </v-tooltip>

        <v-tooltip location="bottom">
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn
              class="rounded-2"
              :color="getSiteLocationButtonColor"
              v-bind="handleEditButtonEventBinding(activatorProps)"
              variant="flat"
              @click="handleSiteLocationButtonClick"
              :disabled="isMonitorDirty && !isSiteLocationEditMode"
            >
              <div
                class="text-subtitle-2 text-white text-truncate font-weight-bold px-1"
              >
                {{ getSiteLocationButtonText }}
              </div>
            </v-btn>
          </template>
          <span> Edit Site Location's Name </span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import API from '@/Core.Service.Domain/Monitors/Monitors.Common/API/';

export default {
  name: 'SiteLocationCard',

  props: {
    MonitorStatus: {
      type: Object,
      required: true,
    },
    isMonitorDirty: {
      type: Boolean,
      required: true,
    },
    siteLocation: {
      type: Object,
      required: true,
    },
    isSiteLocationEditMode: {
      type: Boolean,
      required: true,
    },
    isSiteLocationAddMode: {
      type: Boolean,
      required: true,
    },
    siteLocationsForMonitor: {
      type: Array,
      required: true,
    },
  },

  computed: {
    getSiteLocationButtonText() {
      if (this.isSiteLocationEditMode || this.isSiteLocationAddMode) {
        return this.isSiteLocationEditMode ? 'Cancel Edition' : 'Cancel Add';
      } else {
        return 'Edit Name';
      }
    },

    isAddButtonDisabled() {
      return (
        (this.isMonitorDirty && !this.isSiteLocationEditMode) ||
        (this.isSiteLocationAddMode && this.newSiteLocationName === '')
      );
    },

    getSiteLocationButtonColor() {
      return this.isSiteLocationEditMode || this.isSiteLocationAddMode
        ? 'warning'
        : 'primary';
    },
  },

  data: () => ({
    isEditLoading: false,
    newSiteLocationName: '',
    newSiteLocationId: '',
  }),

  methods: {
    async handleSaveEditSiteLocation() {
      this.isEditLoading = true;
      try {
        const updatedSiteLocation = { ...this.siteLocation };
        updatedSiteLocation.siteLocationId = updatedSiteLocation.id;
        delete updatedSiteLocation.id;

        await API.updateMonitorSiteLocation(updatedSiteLocation);
      } catch (error) {
        console.log('error =>', error);
      } finally {
        this.$emit('site-location-edit-mode', false);
        this.isEditLoading = false;
        this.$emit('init');
      }
    },

    async handleSaveAddSiteLocation() {
      this.isEditLoading = true;
      try {
        const newSiteLocation = { ...this.siteLocation };
        newSiteLocation.siteLocationName = this.newSiteLocationName;
        delete newSiteLocation.id;

        const { id } = await API.createMonitorSiteLocation(newSiteLocation);
        this.newSiteLocationId = id;
      } catch (error) {
        console.log('error =>', error);
      } finally {
        this.$emit('site-location-add-mode', false);
        this.isEditLoading = false;
        this.$emit('init');
      }
    },

    handleEnterSiteLocationAddMode() {
      this.$emit('site-location-add-mode', true);
      this.$nextTick(() => {
        this.$refs.siteLocationNameAddMode.focus();
      });
    },

    handleEnterSiteLocationEditMode() {
      this.$emit('site-location-edit-mode', true);
      this.$nextTick(() => {
        this.$refs.siteLocationNameEditMode.focus();
      });
    },

    handleAddButtonClick() {
      if (this.isSiteLocationAddMode) {
        this.handleSaveAddSiteLocation();
      } else {
        this.handleEnterSiteLocationAddMode();
      }
    },

    handleSiteLocationButtonClick() {
      if (this.isSiteLocationEditMode || this.isSiteLocationAddMode) {
        this.$emit('cancel-site-location-edit-mode');
      } else {
        this.handleEnterSiteLocationEditMode();
      }
    },

    handleEditButtonEventBinding(on) {
      return !this.isSiteLocationEditMode && !this.isSiteLocationAddMode
        ? on
        : null;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-select) {
  .v-select__selections {
    .v-select__selection {
      color: $primary !important;
    }
  }
}

:deep(.v-list) {
  .v-list-item__title {
    color: $primary !important;
  }
}
</style>
