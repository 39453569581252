<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 159 67"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="M12.5 20.5L9 21.5L1.5 9.5L6 7V3.5L32 0.5H40L41 4L60.5 0.5H69L69.5 4L89.5 0.5H92.5L112.5 4L113 0.5H119.5V2L133.5 4H158.5V66.5H121V58.5H103.5V66.5H68V18H47V20.5H32H12.5Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
