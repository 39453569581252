class encryptionService {
  static async decrypt(data, passKey) {
    this._passKey = passKey.padEnd(32).substring(0, 32);
    this._passKeyBytes = new TextEncoder().encode(this._passKey);

    const encryptedBytes = new Uint8Array(
      [...atob(data)].map((char) => char.charCodeAt(0))
    );
    const iv = encryptedBytes.slice(0, 16);
    const key = await crypto.subtle.importKey(
      'raw',
      this._passKeyBytes,
      { name: 'AES-CBC' },
      false,
      ['decrypt']
    );

    const decryptedData = await crypto.subtle.decrypt(
      { name: 'AES-CBC', iv: iv },
      key,
      encryptedBytes.slice(16)
    );

    return new TextDecoder().decode(decryptedData);
  }

  static generateSalt() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}

export default encryptionService;
