<template>
  <svg
    viewBox="0 0 113 37"
    xmlns="http://www.w3.org/2000/svg"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
      id="interactive-group"
    >
      <path
        d="M112.5 0.5H1V36H17.5C18.748 30.0771 20.4765 27.8515 26.5 27V36H46C46.7286 29.8951 48.7372 28.072 54.5 27V36H74.5C75.3984 29.7659 77.5784 28.1527 83 27V36H102.5C103.171 31.212 104.671 29.0687 111 27V36H112.5V0.5Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-group {
  transition: fill-opacity 0.2s;
}
#interactive-group:hover {
  fill-opacity: 0.5;
}
</style>
