<template>
  <v-container fluid>
    <PortfolioBar />
    <PortfolioList class="mt-6" :portfolioList="portfolioList" />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </v-container>
</template>

<script>
import PortfolioList from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/PortfolioList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

import PortfolioBar from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/PortfolioBar.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PortfolioView',

  components: { PortfolioList, ProgressLinearLoaderVue, PortfolioBar },

  computed: {
    ...mapGetters({
      defaultSite: 'session/defaultSite',
      portfolioList: 'ViewSupportStore/PortfolioStore/getPortfolioSitesList',
    }),
  },

  data() {
    return {
      isLoading: true,
    };
  },

  async created() {
    try {
      await this.getPortfolioSites();
      if (!this.defaultSite) {
        this.setDefaultSite(this.portfolioList[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      setDefaultSite: 'session/setDefaultSite',
      getPortfolioSites: 'ViewSupportStore/PortfolioStore/getPortfolioSites',
    }),
  },
};
</script>
