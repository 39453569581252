<template>
  <div class="mt-8">
    <v-row>
      <v-col v-for="site in getSites" :key="site.siteId">
        <SiteCardVue :siteInfo="site" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import SiteCardVue from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/SiteCard.vue';

import { ISite } from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/types/Site.ts';

const props = defineProps<{
  portfolioList: Array<ISite>;
}>();

const filteredItems = ref<Array<ISite>>([]);

const store = useStore();
const searchTerm = computed(() => {
  return store.getters['ViewSupportStore/PortfolioStore/getSiteSearchTerm'];
});

const getSites = computed(() => {
  return filteredItems.value.length
    ? sortAlphabetically(filteredItems.value)
    : sortAlphabetically(props.portfolioList);
});

watch(searchTerm, () => {
  filteredItems.value = searchByTermFactory<ISite>(
    searchTerm.value,
    ['streetAddress', 'name'],
    props.portfolioList
  );
});
</script>
<style lang="scss" scoped>
.v-col {
  flex-grow: 0;
}
</style>
