<template>
  <v-card class="strategies-templates-bar">
    <v-app-bar elevation="1" class="px-11">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        Strategy Templates
      </v-app-bar-title>
      <v-text-field
        v-if="!isCreateRoute && !isEditRoute"
        class="mt-1 mr-5"
        v-model="searchTerm"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        bg-color="white"
        variant="outlined"
        density="compact"
        :placeholder="placeholder"
        :clearable="true"
        @click:clear="handleSearchInput"
        @update:modelValue="handleSearchInput"
      />
      <!-- Add Button -->
      <template v-slot:append>
        <v-btn
          v-if="userCanUpdate && !isCreateRoute && !isEditRoute"
          class="rounded-2 mr-3"
          color="primary"
          variant="flat"
          size="large"
          @click="handleAddStrategyTemplate"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold"
          >
            New Strategy Template
          </div>
        </v-btn>
      </template>
      <v-spacer />
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'StrategyTemplatesBar',

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Strategy Template';
    },

    userCanUpdate() {
      return this.$can('update', 'controls');
    },

    isCreateRoute() {
      return this.$route.name === 'StrategyTemplatesCreate';
    },

    isEditRoute() {
      return this.$route.name === 'StrategyTemplatesEdit';
    },
  },

  watch: {
    $route() {
      this.clearSearchTerm();
    },
  },

  data() {
    return {
      searchTerm: '',
      selectedSite: null,
    };
  },

  methods: {
    ...mapMutations({
      setStrategyTemplateSearchTerm:
        'StrategiesStore/StrategyTemplatesStore/STRATEGY_TEMPLATE_SEARCH_TERM',
    }),

    handleAddStrategyTemplate() {
      if (!this.userCanUpdate) return;
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({ name: 'StrategyTemplatesCreate' });
    },

    handleSearchInput(value) {
      if (typeof value === 'object') {
        this.setStrategyTemplateSearchTerm('');
      } else {
        this.setStrategyTemplateSearchTerm(value);
      }
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setStrategyTemplateSearchTerm('');
    },
  },
};
</script>
<style lang="scss" scoped>
.strategies-templates-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
