<template>
  <div>
    <v-row>
      <v-col v-for="thermostat in thermostatsList" :key="thermostat.controlId">
        <ThermostatCardVue
          :key="thermostat.controlId"
          :ThermostatInfo="thermostat"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import ThermostatCardVue from '@/Core.Service.Domain/Controls/Thermostats/Components/ThermostatCard';

export default {
  name: 'ThermostatsList',

  components: {
    ThermostatCardVue,
  },

  computed: {
    ...mapGetters({
      ThermostatsList: 'ControlsStore/ThermostatsStore/getThermostatsList',
      searchTerm: 'ControlsStore/getControlSearchTerm',
    }),

    thermostatsList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems, 'controlInfo.friendlyName')
        : sortAlphabetically(this.ThermostatsList, 'controlInfo.friendlyName');
    },
  },

  data() {
    return {
      filteredItems: [],
    };
  },

  watch: {
    searchTerm() {
      const mappedItems = this.ThermostatsList.map((item) => {
        if (!item.controlInfo) return null;
        const mappedItem = item;
        mappedItem.friendlyName = item.controlInfo.friendlyName;
        mappedItem.name = item.controlInfo.name;
        mappedItem.siteLocationName = item.controlInfo.siteLocationName;
        return mappedItem;
      }).filter((item) => item);

      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['friendlyName', 'name', 'siteLocationName', 'runStatus'],
        mappedItems
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-col {
  flex-grow: 0;
}
</style>
