<template>
  <v-card class="rounded-2" height="100%" :width="cardWidth" elevation="24">
    <!--Card Header-->
    <v-toolbar color="primary" flat>
      <v-container>
        <v-row>
          <v-col class="d-flex" cols="12">
            <v-tooltip location="right">
              <template v-slot:activator="{ props }">
                <v-toolbar-title
                  class="text-aliceBlue font-weight-bold ml-1 text-h5 mt-2"
                  v-bind="props"
                >
                  <v-icon color="white" class="mt-n2" start size="small">
                    mdi-home-lightning-bolt
                  </v-icon>
                  {{ UCompany.name }}
                </v-toolbar-title>
              </template>
              <span>{{ UCompany.name }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <!--Card Body-->
    <UtilityCompaniesItemsList :utilityCompaniesItemsList="UCompany" />
    <!--Card Footer-->
    <v-divider />
    <v-card-actions v-if="userCanUpdate || userCanCreate || userCanDelete">
      <ActionButtonsVue :buttonsList="actionButtons" />
    </v-card-actions>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteUtilityCompany
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :UtilityCompanyInfo="UCompany"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import UtilityCompaniesItemsList from '@/Core.Service.Domain/Sites/UtilityCompanies/Components/UtilityCompaniesItemsList.vue';

import ConfirmDialogDeleteUtilityCompany from '@/Core.Service.Domain/Sites/UtilityCompanies/Components/ConfirmDialogDeleteUtilityCompany.vue';

export default {
  name: 'UtilityCompanyCard',

  components: {
    ActionButtonsVue,
    UtilityCompaniesItemsList,
    ConfirmDialogDeleteUtilityCompany,
  },

  props: {
    UCompany: {
      type: Object,
      required: true,
    },
  },

  computed: {
    userCanCreate() {
      return this.$can('create', 'analytics');
    },
    userCanDelete() {
      return this.$can('delete', 'analytics');
    },
    userCanUpdate() {
      return this.$can('update', 'analytics');
    },
  },

  data(instance) {
    return {
      showConfirmDialog: false,
      cardWidth: 360,
      actionButtons: [
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          action: () => {
            instance.setUtilityCompany(instance.UCompany);
            instance.$router.push({
              name: 'UtilityCompaniesEdit',
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          action: () => {
            instance.showConfirmDialog = true;
          },
        },
      ],
    };
  },

  methods: {
    ...mapMutations({
      setUtilityCompany: 'SitesStore/UtilityCompaniesStore/SET_UTILITY_COMPANY',
    }),
  },
};
</script>
