<template>
  <v-card height="100%" class="py-3">
    <div class="text-center">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-card>
</template>
