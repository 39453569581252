<template>
  <v-container fluid>
    <v-row
      v-for="row in Math.ceil(projectsList.length / cardsPerRow)"
      :key="row"
    >
      <v-col
        v-for="project in projectsList.slice(
          (row - 1) * cardsPerRow,
          row * cardsPerRow
        )"
        :key="project.id"
      >
        <ProjectCardVue :ProjectInfo="project" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import ProjectCardVue from '@/Core.Service.Domain/Analytics/Projects/Components/ProjectCard';

export default {
  name: 'ProjectsList',

  components: {
    ProjectCardVue,
  },

  props: {
    ProjectsList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      searchTerm:
        'AnalyticsStore/AnalyticsProjectsStore/getAnalyticsProjectSearchTerm',
    }),

    projectsList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems, 'projectName')
        : sortAlphabetically(this.ProjectsList, 'projectName');
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['description', 'projectName'],
        this.ProjectsList
      );
    },
  },

  data() {
    return {
      filteredItems: [],
      cardsPerRow: 3,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-col {
  flex-grow: 0;
}
</style>
