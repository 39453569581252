/**
 * @param {Object} obj
 * @param {Array} keys
 * @param {Array} newKeys
 * @returns Object
 * @description Remap object keys replacing old keys with new keys
 * @example RemapObjectKeysAdapter({ a: 1, b: 2, c: 3 }, ['a', 'b', 'c'], ['x', 'y', 'z']);
 * returns { x: 1, y: 2, z: 3 }
 */
export const RemapObjectKeysAdapter = (
  obj: { [x: string]: string },
  keys: string[],
  newKeys: string[]
) => {
  keys.forEach((key, index) => {
    if (obj[key] !== undefined) {
      obj[newKeys[index]] = obj[key];
      delete obj[key];
    }
  });
  return obj;
};

export const stringify = (
  obj: { [key: string]: string },
  prefix: string | undefined
) => {
  const queryString: string = Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      const prefixedKey = prefix ? `${prefix}[${key}]` : key;

      if (Array.isArray(value)) {
        return value
          .map((val, index) => {
            return (
              encodeURIComponent(`${prefixedKey}[${index}]`) +
              '=' +
              encodeURIComponent(val)
            );
          })
          .join('&');
      } else if (typeof value === 'object' && value !== null) {
        return stringify(value, prefixedKey);
      } else {
        return (
          encodeURIComponent(prefixedKey) + '=' + encodeURIComponent(value)
        );
      }
    })
    .join('&');

  return queryString;
};

/**
 *
 * @param {*} str
 * @returns Object
 * @description Capitalize first letter of a string
 * @example capitalizeFirstLetter('hello') returns 'Hello'
 *
 */
export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => capitalizeKeys(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: { [key: string]: any } = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = capitalizeFirstLetter(key);
        newObj[newKey] = capitalizeKeys(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
};
