import MeasuresPortfolio from '@/Core.Service.Domain/Analytics/Measures/Views';

export const MeasuresRoute = {
  path: 'measures',
  name: 'AnalyticsMeasures',
  component: MeasuresPortfolio,
  meta: {
    placeholder: 'Search for a Measure',
    analyticsTypeName: 'AnalyticsMeasure',
    label: 'Measures',
    buttonLabel: 'New Measure',
    toolTip: 'Measure',
  },
};
