<template>
  <v-container fluid>
    <ControlsBar />
    <router-view class="mt-8" />
  </v-container>
</template>

<script>
import ControlsBar from '@/Core.Service.Domain/Controls/Controls.Common/Components/ControlsBar.vue';

export default {
  name: 'ControlsView',

  components: {
    ControlsBar,
  },
};
</script>
