<template>
  <v-container fluid>
    <v-list lines="two">
      <div v-for="(item, i) in itemsList" :key="i">
        <v-list-item>
          <template v-slot:prepend>
            <v-icon color="primary">
              {{ item.icon }}
            </v-icon>
          </template>

          <div>
            <v-list-item-title>
              <span class="text-primary font-weight-bold">
                {{ item.label }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle v-for="(model, j) in item.model" :key="j">
              <span class="float-left text-subtitle-2">
                {{ item.output(model, j).col1 }}
              </span>
              <span
                class="float-right text-primary text-subtitle-2 font-weight-bold"
              >
                {{ item.output(model, j).col2 }}
              </span>
            </v-list-item-subtitle>
          </div>
        </v-list-item>
        <v-divider v-if="i < itemsList.length - 1" inset />
      </div>
    </v-list>
  </v-container>
</template>

<script>
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';

export default {
  name: 'ModelCardItemsList',

  props: {
    modelInfo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    itemsList() {
      const items = [
        {
          icon: 'mdi-map-marker',
          label: 'Site',
          model: [this.modelInfo.siteName],
          output: (item) => ({ col1: item, col2: '' }),
        },
        {
          icon: 'mdi-calendar-multiselect',
          label: 'Date Range',
          model: [this.modelInfo.startDateTime, this.modelInfo.endDateTime],
          output: (item, i) => {
            const labels = ['Start Date', 'End Date'];
            return { col1: labels[i], col2: dateTimeToUSFormatFactory(item) };
          },
        },
        {
          icon: 'mdi-earth',
          label: 'Resource Type',
          model: [this.modelInfo.endUses[0]?.resourceType],
          output: (item) => ({ col1: item, col2: '' }),
        },
        {
          icon: 'mdi-test-tube',
          label: 'Analysis',
          model: [
            this.modelInfo.analysisType.analysisTypeName,
            this.modelInfo.analyticType.analyticTypeName,
          ],
          output: (item, i) => {
            const labels = ['Analysis Type', 'Analytic Type'];
            return { col1: labels[i], col2: item };
          },
        },
      ];
      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel:first-child {
  border-top: none;
}

.v-list-item {
  :deep(.v-list-item__spacer) {
    width: 17px !important;
  }
}
</style>
