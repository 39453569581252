<template>
  <v-progress-linear
    class="progress-linear-loader"
    color="primary"
    :height="height"
    :active="isLoading"
    indeterminate
  />
</template>

<script>
export default {
  name: 'ProgressLinearLoader',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 7,
    },
  },
};
</script>
<style lang="scss">
.progress-linear-loader {
  position: fixed;
  top: 153px !important;
  left: 0px;
  width: 100%;
  z-index: 10 !important;
}
</style>
