<template>
  <v-card
    :id="`strategy_${StrategyInfo.id}`"
    class="rounded-2"
    height="100%"
    :width="cardWidth"
    elevation="24"
  >
    <!--Card Header-->
    <v-toolbar color="primary" height="88">
      <v-container>
        <v-row>
          <v-col class="d-flex" cols="10">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-toolbar-title
                  class="text-aliceBlue font-weight-bold ml-1 text-h5 mt-2"
                  v-bind="props"
                >
                  <v-icon color="white" class="mt-n2" start size="small">
                    mdi-strategy
                  </v-icon>
                  {{ StrategyInfo.name }}
                </v-toolbar-title>
              </template>
              <span>{{ StrategyInfo.name }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <!--Dirty Icon-->
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <v-icon
                  class="mt-2 ml-2 strategy-dirty-icon"
                  color="accentOrange"
                  v-bind="props"
                >
                  {{ isStrategyDirty ? 'mdi-alert-circle' : '' }}
                </v-icon>
              </template>
              <span>
                {{ `You've made changes on ${route.meta.toolTip}'s settings` }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span
                  class="text-aliceBlue text-caption ml-3 mt-n4 cut-text"
                  v-bind="props"
                >
                  {{ StrategyInfo.description }}
                </span>
              </template>
              <span>{{ StrategyInfo.description }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <!--Card Body-->
    <v-container class="pa-4 rounded-2">
      <!--Settings Card-->
      <v-row>
        <v-col>
          <v-card
            variant="outlined"
            height="180px"
            class="pa-4 mb-n2 rounded-2"
          >
            <v-row>
              <v-col>
                <v-alert
                  color="white"
                  border="start"
                  elevation="2"
                  :border-color="isActiveObject.color"
                  :icon="false"
                  class="mb-n2 rounded-2"
                >
                  <v-icon :color="isActiveObject.color" class="mr-4">
                    {{ isActiveObject.icon }}
                  </v-icon>
                  <span class="text-primary font-weight-bold">
                    {{ isActiveMessage }}
                  </span>
                </v-alert>
              </v-col>
            </v-row>
            <!--Settings-->
            <v-row>
              <v-col>
                <span class="text-primary text-subtitle-1 font-weight-bold">
                  Toggle Active:
                </span>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col>
                <v-switch
                  hide-details
                  class="ml-1 mb-n3"
                  :disabled="!userCanUpdate"
                  color="primary"
                  inset
                  v-model="StrategyStatus.isActive"
                  :label="isActiveToggleModes[String(StrategyStatus.isActive) as keyof typeof isActiveToggleModes]"
                />
              </v-col>
            </v-row>
          </v-card>
          <!--Information-->
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <StrategyCardItemsList
                    class="mx-n5 mb-n5"
                    :StrategyInfo="StrategyInfo"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--Set Button-->
      <v-row>
        <v-col cols="6" />
        <v-col cols="6">
          <v-btn
            v-if="userCanUpdate"
            :disabled="!isStrategyDirty"
            :loading="loadingSetButton"
            class="rounded-2"
            color="accentOrange"
            variant="flat"
            block
            size="large"
            @click="handleSet"
          >
            <div
              class="text-subtitle-2 text-white text-truncate font-weight-bold"
            >
              Set
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!--Card Footer-->
    <v-divider />
    <v-card-actions v-if="userCanUpdate">
      <ActionButtonsVue :buttonsList="actionButtons" />
    </v-card-actions>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteStrategy
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :strategyInfo="StrategyInfo"
      @update:dialog="(value: boolean) => (showConfirmDialog = value)"
    />
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import API from '@/Core.Service.Domain/Strategies/Strategies/API';
import { useStore } from 'vuex';

import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons.vue';
import ConfirmDialogDeleteStrategy from '@/Core.Service.Domain/Strategies/Strategies/Components/ConfirmDialogDeleteStrategy.vue';
import StrategyCardItemsList from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategyCardItemsList.vue';

import { IActionButton } from '@/Core.UI.Domain/Components/types/actionButtons';

interface StrategyInfoType {
  siteId: string;
  name: string;
  description: string;
  isActive: boolean;
  id: string;
}

const props = defineProps<{
  StrategyInfo: StrategyInfoType;
}>();

// Reactive variables<Refs>
const loadingSetButton = ref(false);
const showConfirmDialog = ref(false);
const cardWidth = ref(360);
const isDirty = ref({
  isActive: false,
});

const StrategyStatus = ref({
  isActive: props.StrategyInfo.isActive,
});

const router = useRouter();
const route = useRoute();
const actionButtons = ref<IActionButton[]>([
  {
    icon: 'history',
    toolTip: `${route.meta.toolTip} Execution History`,
    action: () => {
      store.commit('StrategiesStore/StrategiesStore/STRATEGY_INFO', {
        strategyName: props.StrategyInfo.name,
        strategyId: props.StrategyInfo.id,
        selectedDate: '',
      });
      router.push({
        name: 'StrategyExecutionHistory',
        params: {
          strategyName: props.StrategyInfo.name,
          strategyId: props.StrategyInfo.id,
        },
      });
    },
    // show: () => this.$can('read-audit-log', 'strategies'),
  },
  {
    icon: 'pencil',
    toolTip: `Edit ${route.meta.toolTip}`,
    action: () => {
      // this route will be replaced when the new edit page is ready
      router.push({
        name: 'StrategiesEdit',
        params: {
          siteId: props.StrategyInfo.siteId,
          strategyId: props.StrategyInfo.id,
        },
      });
    },
  },
  {
    icon: 'trash-can-outline',
    toolTip: `Delete ${route.meta.toolTip}`,
    action: () => {
      showConfirmDialog.value = true;
    },
  },
]);

const isActiveToggleModes = {
  true: 'Active',
  false: 'Inactive',
};

const store = useStore();
// Computed
const defaultSite = computed(() => {
  return store.getters['session/defaultSite'];
});

const isStrategyDirty = computed(() => {
  return Object.values(isDirty.value).some((value) => value);
});

const isActiveObject = computed(() => {
  if (StrategyStatus.value.isActive) {
    return {
      color: 'greenGraph',
      icon: 'mdi-check-circle',
    };
  }
  return {
    color: 'warning',
    icon: 'mdi-alert-octagon',
  };
});

const isActiveMessage = computed(() => {
  return `Strategy is currently ${
    isActiveToggleModes[
      StrategyStatus.value
        .isActive as unknown as keyof typeof isActiveToggleModes
    ]
  }`;
});

const userCanUpdate = computed(() => {
  // return store.getters['session/userCanUpdate'];
  return true;
});

// Watchers
watch(
  () => StrategyStatus.value.isActive,
  (newVal) => {
    if (newVal !== props.StrategyInfo.isActive) {
      isDirty.value.isActive = true;
    } else isDirty.value.isActive = false;
  }
);

// Methods
const handleSet = async () => {
  if (!userCanUpdate.value) return;

  const strategyPayload = {
    name: props.StrategyInfo.name,
    siteId: props.StrategyInfo.siteId,
    isActive: StrategyStatus.value.isActive,
  };
  try {
    loadingSetButton.value = true;
    await API.toggleActivateStrategy(strategyPayload);
    store.dispatch('StrategiesStore/StrategiesStore/getStrategiesBySiteId', {
      selectedSite: defaultSite.value,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingSetButton.value = false;
  }
};
</script>
<style lang="scss" scoped>
.v-alert ::v-deep(.v-alert__border) {
  opacity: 1 !important;
}

.strategy-dirty-icon {
  cursor: pointer;
}
</style>
