<template>
  <v-container fluid>
    <div>
      <v-chip class="mb-7" label>
        <span class="text-primary font-weight-bold">
          {{ notificationsCounterMessage }}
        </span>
      </v-chip>
      <v-btn class="float-right" label @click="handleShowAllNotifications">
        <v-icon size="small" start color="primary"> mdi-message-badge </v-icon>
        <span class="text-primary font-weight-bold">
          Go to all notifications
        </span>
      </v-btn>
    </div>
    <v-row justify="center">
      <v-expansion-panels variant="popout">
        <v-expansion-panel
          v-for="(notification, i) in filteredNotifications"
          :key="i"
          :class="notification.isRead ? 'read' : 'unread'"
          class="mb-1"
        >
          <v-expansion-panel-title>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="4" sm="2" md="1">
                <v-avatar size="36px">
                  <v-icon :color="handleNotificationType(notification).color">
                    {{ handleNotificationType(notification).icon }}
                  </v-icon>
                </v-avatar>
              </v-col>

              <v-col
                v-if="notification.notificationValue"
                class="text-grey text-truncate hidden-sm-and-down"
              >
                <span class="font-weight-bold cut-text">
                  {{ notification.notificationValue }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-title>

          <v-expansion-panel-text>
            <v-divider />
            <v-card-text>
              {{ notification.createdDateTimeOffset }}:
              {{ notification.notificationValue }}
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <ActionButtonsVue
                :buttonsList="actionButtons"
                :item="notification"
                :outlined="notification.isRead"
              />
            </v-card-actions>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';

import { mapActions, mapGetters } from 'vuex';
import { sortArrayByFieldDescending } from '@/Core.Patterns/Strategy';

export default {
  name: 'NotificationsListOverlay',

  components: {
    ActionButtonsVue,
  },

  computed: {
    ...mapGetters({
      notificationsList:
        'ViewSupportStore/NotificationsStore/getNotificationsListBySite',
      defaultSite: 'session/defaultSite',
      sitesList: 'ViewSupportStore/PortfolioStore/getPortfolioSitesList',
    }),
    filterUnreadNotifications() {
      return this.notificationsList.filter(
        (notification) => !notification.isRead
      );
    },
    filterReadNotifications() {
      return this.notificationsList.filter(
        (notification) => notification.isRead
      );
    },

    notificationsCounterMessage() {
      if (this.filterUnreadNotifications.length === 0) {
        return 'No New Notifications';
      }
      return this.filterUnreadNotifications.length > 1
        ? `${this.filterUnreadNotifications.length} Unread Notifications`
        : `${this.filterUnreadNotifications.length} Unread Notification`;
    },
  },

  data: (instance) => ({
    filteredNotifications: [],
    activeTab: 'unread',
    actionButtons: [
      {
        icon: (item) =>
          item.holdRead ? 'email-outline' : 'email-open-outline',
        toolTip: (item) => (item.isRead ? 'Mark as Unread' : 'Mark as Read'),
        action: (item) => {
          instance.handleNotificationToggleRead(item);
        },
      },
    ],
    tabCounter: {},
  }),

  created() {
    this.handleFilteredNotifications();
    this.handleTabCounter();
  },

  methods: {
    ...mapActions({
      updateNotification:
        'ViewSupportStore/NotificationsStore/updateNotification',
      setDefaultSite: 'session/setDefaultSite',
    }),

    handleFilteredNotifications() {
      this.filteredNotifications = sortArrayByFieldDescending(
        this.filterUnreadNotifications,
        'createdDateTimeOffset'
      );
    },

    handleTabCounter() {
      this.tabCounter = {
        unread: this.filterUnreadNotifications.length,
        read: this.filterReadNotifications.length,
      };
    },

    handleTabClick(tab) {
      this.handleTabs(tab);
    },

    handleTabChange(tab) {
      this.handleTabs(tab);
    },

    handleTabs(tab) {
      this.activeTab = tab;
      switch (tab) {
        case 'unread':
          this.handleFilteredNotifications();
          break;
        case 'read':
          this.filteredNotifications = this.filterReadNotifications;
          break;
      }
    },

    handleNotificationType(notification) {
      const types = [
        {
          type: 'Email',
          color: 'greenGraph',
          icon: 'mdi-email-check-outline',
        },
        {
          type: 'SMS',
          color: 'purple',
          icon: 'mdi-message-alert-outline',
        },
        {
          type: 'PowerControlSettingNotification',
          color: 'intechBlue',
          icon: 'mdi-power-settings',
        },
        {
          type: 'ThermostatSetting',
          color: 'warning',
          icon: 'mdi-thermometer',
        },
      ];
      const type = types.find(
        (type) => type.type === notification.notificationType
      );
      return type;
    },

    async handleNotificationToggleRead(notification) {
      try {
        // TODO: this logic should not be here;
        //       instead we should update the notification from server
        notification.isRead = !notification.isRead;
        await this.updateNotification(notification);
        notification.holdRead = !notification.holdRead;
        this.handleTabCounter();
        this.handleTabs(this.activeTab);
      } catch (error) {
        console.error(error);
      }
    },

    handleShowAllNotifications() {
      const selectedSite = this.sitesList.find(
        (site) => site.siteId === Number(this.$route.params.siteId)
      );

      this.setDefaultSite(selectedSite);
      this.$router.push({
        name: 'AllNotifications',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$borderRadius: 5px !important;

@function borderLeft($color) {
  @return 7px solid $color !important;
}

.read {
  border-left: borderLeft($cadetBlue);
  border-radius: $borderRadius;
}

.unread {
  border-left: borderLeft($primary);
  border-radius: $borderRadius;
}

.v-btn {
  text-transform: none;
}
</style>
