<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-carousel
    :continuous="true"
    :cycle="cycle"
    :show-arrows="slides.length > 1"
    hide-delimiters
    height="auto"
  >
    <v-carousel-item v-for="(slide, i) in slides" :key="i">
      <v-card
        class="mx-auto"
        :width="!smallBreakpoint ? 1024 : 350"
        :min-width="!smallBreakpoint ? 500 : 350"
        color="primary"
        min-height="600"
        :max-height="!smallBreakpoint ? 800 : 500"
      >
        <div class="container" v-if="slide.messageCenterMessageTypeId === 3">
          <v-card-title
            :class="{ 'text-subtitle-1': smallBreakpoint }"
            class="text-h4 justify-center font-weight-bold my-2"
            v-html="slide.messageCenterMessageLabel"
          />
          <v-img
            :src="findMessageIdInSlidesAndMessagesList(slide)"
            height="auto"
            min-height="450"
            width="100%"
            class="image-gradient"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey-lighten-5" />
              </v-row>
            </template>
          </v-img>
          <div class="centered-text-image">
            <v-card-title
              :class="{ 'text-subtitle-1': smallBreakpoint }"
              class="text-h5 justify-center font-weight-bold text-primary"
              v-html="slide.messagingCenterMessageValue"
            />
          </div>
        </div>

        <div v-if="slide.messageCenterMessageTypeId === 2">
          <v-card-title
            :class="{ 'text-subtitle-1': smallBreakpoint }"
            class="text-h4 justify-center font-weight-bold"
            v-html="slide.messageCenterMessageLabel"
          />
          <div
            :style="smallBreakpoint ? 'min-height: 350px' : 'min-height: 400px'"
          >
            <v-divider />

            <div class="centered-text">
              <v-card-text
                class="px-15 mt-5 text-justify justify-center text-body-1 message-overflow"
                v-html="slide.messagingCenterMessageValue"
              />
            </div>
          </div>
        </div>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            class="ma-2 text-white"
            @click="markMessageAsRead(slide)"
            :loading="isLoading"
          >
            Mark as Read
            <v-icon end> mdi-email-open </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>
<script setup lang="ts">
import API from '@/Core.Service.Domain/MessageCenter/Users/API';

import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useAbility } from '@/plugins/useAbility.ts';

import { ISlide } from '@/Core.Service.Domain/MessageCenter/Users/Components/types/Slide.ts';

const ability = useAbility();
onMounted(() => {
  if (ability.can('administer', 'control')) {
    getAllMessagesFromMessageCenter();
  } else {
    setMessagesByUser();
  }
});

defineProps<{
  slides: ISlide[];
}>();

const emits = defineEmits(['close:dialog']);

const smallBreakpoint = ref(false);
const cycle = ref(false);
const isLoading = ref(false);

const store = useStore();
const getAllMessagesFromMessageCenter = async () => {
  try {
    isLoading.value = true;
    await store.dispatch(
      'MessageCenterManagementStore/getAllMessagesFromMessageCenter'
    );
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};
const setUnreadMessagesCount = async () => {
  try {
    isLoading.value = true;
    await store.dispatch('MessageCenterUsersStore/setUnreadMessagesCount');
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};
const setMessagesByUser = async () => {
  try {
    isLoading.value = true;
    await store.dispatch('MessageCenterUsersStore/setMessagesByUser');
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const getMessagesList = computed(
  () => store.getters['MessageCenterManagementStore/getMessagesList']
);
const getMessagesByUser = computed(
  () => store.getters['MessageCenterUsersStore/getMessagesByUser']
);

const markMessageAsRead = async (message: ISlide) => {
  try {
    isLoading.value = true;
    const messageId = message.userMessageCenterMessageId;
    await API.markMessageAsRead(messageId);
    await setUnreadMessagesCount();
    emits('close:dialog');
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const messagesList = ref([]);
const findMessageIdInSlidesAndMessagesList = (slide: ISlide) => {
  if (ability.can('administer', 'control')) {
    messagesList.value = getMessagesList.value;
  } else {
    messagesList.value = getMessagesByUser.value;
  }
  const message = messagesList.value.find(
    (message: ISlide) =>
      message.messageCenterMessageLabel === slide.messageCenterMessageLabel
  );

  const img = new Image();

  if (!message) {
    return (img.src = '');
  }

  //TODO do the implementation for the image source when the upload feature is ready
  // img.src = `./images/${
  //   ability('administer', 'control')
  //     ? message.messageCenterMessageId
  //     : message.userMessageCenterMessageId
  // }.jpg`;
  // TODO remove this line when the upload feature is ready
  img.src = `./images/38.jpg`;

  return img.src;
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  text-align: center;
  min-height: 500px;
}

.v-image {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0.5))
  ) !important;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5)
  ) !important;
}

.centered-text-image {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  z-index: 2;
}

.v-card-title {
  line-height: 2.5;
}

.centered-text {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}

.message-overflow {
  height: 350px;
  line-height: 1.5;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.message-overflow::-webkit-scrollbar {
  display: none;
}

.image-gradient {
  position: relative;
  overflow: hidden;
}

.image-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1) 40%,
    rgba(255, 255, 255, 0.9) 80%
  );
  z-index: 1;
}
</style>
