<template>
  <v-sheet flat border class="pa-4 col-sm-12">
    <p class="text-subtitle-1 pt-3 text-primary mb-3">
      <span class="font-weight-bold">Device Status (V1)</span>
      - Input values at execution time
    </p>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="localInputData.CurrentDateTime"
          :rules="[isDate]"
          variant="outlined"
          label="Current Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="localInputData.ReadingDateTime"
          :rules="[isDate]"
          variant="outlined"
          label="Reading Date/Time"
          type="datetime-local"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="localInputData.DelayInMinutes"
          :rules="[isNumberGreaterThanZero]"
          variant="outlined"
          label="Delay in Minutes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="localInputData.DeviceType"
          :items="deviceList"
          :item-title="(item) => item.Name"
          :item-value="(item) => item.Id"
          label="Device Type"
          variant="outlined"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="localInputData.DeviceName"
          variant="outlined"
          label="Device Friendly Name"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import {
  isNumber,
  isNumberGreaterThanZero,
  hasValue,
  isDate,
} from '@/Core.Patterns/Factory/validationFactory.ts';

export default {
  name: 'DevicsStatusV1Input',
  data() {
    return {
      devices: [],
      localInputData: { ...this.InputData },
    };
  },

  computed: {
    deviceList() {
      // see if we have a list of datasources of this very object in datasources
      const inputs = this.DataSources.find(
        (ds) => ds.Key === 'DeviceTypes'
      )?.Value;
      if (inputs) return inputs;
      // if not, we'll create one based on InputData.ControlId/Name/FriendlyName values
      return [
        {
          Id: this.InputData.DeviceType,
          Name: `${this.InputData.DeviceTypeName}`,
        },
      ];
    },
  },

  props: {
    InputData: {
      type: Object,
      required: true,
    },
    DataSources: {
      type: Array,
      required: true,
    },
  },

  watch: {
    InputData: {
      handler(newVal) {
        this.localInputData = { ...newVal };
      },
      deep: true,
    },
    localInputData: {
      handler(newVal) {
        this.$emit('update:model', newVal);
      },
      deep: true,
    },
  },

  methods: {
    isNumber,
    isNumberGreaterThanZero,
    hasValue,
    isDate,
  },
};
</script>
