<template>
  <PopUpMessageOverlay
    v-if="getNonExpiredUnreadAndNoTextMessages.length"
    :messagesByUserList="getNonExpiredUnreadAndNoTextMessages"
  />
</template>
<script setup lang="ts">
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import PopUpMessageOverlay from '@/Core.Service.Domain/MessageCenter/Users/Components/PopUpMessageOverlay.vue';

// Computed properties
const store = useStore();
const messagesByUserList = computed(
  () => store.getters['MessageCenterUsersStore/getMessagesByUser']
);
const isAuthenticated = computed(
  () => store.getters['session/isAuthenticated']
);
const getNonExpiredUnreadAndNoTextMessages = computed(() =>
  messagesByUserList.value.filter(
    (item: {
      isExpired: boolean;
      isRead: boolean;
      messageCenterMessageTypeId: number;
    }) =>
      !item.isExpired && !item.isRead && item.messageCenterMessageTypeId !== 1
  )
);

// Lifecycle hooks
onMounted(async () => {
  if (isAuthenticated.value) {
    setInterval(() => {
      setUnreadMessagesCount();
    }, 60000);
    await setUnreadMessagesCount();
    await getMessagesByUser();
  }
});
const route = useRoute();
watch(route, (to) => {
  if (to.name !== 'MessageCenterMyMessages') {
    getMessagesByUser();
  }
});

// Store actions
const setUnreadMessagesCount = () =>
  store.dispatch('MessageCenterUsersStore/setUnreadMessagesCount');

const getMessagesByUser = () =>
  store.dispatch('MessageCenterUsersStore/setMessagesByUser');
</script>
