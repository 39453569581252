<template>
  <v-container fluid>
    <v-list lines="two">
      <div v-for="(item, i) in itemsList" :key="i">
        <v-list-item>
          <template v-slot:prepend>
            <v-icon color="primary">
              {{ item.icon }}
            </v-icon>
          </template>

          <div>
            <v-list-item-title>
              <span class="text-primary font-weight-bold">
                {{ item.label }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle v-for="(project, j) in item.project" :key="j">
              <span class="float-left text-subtitle-2">
                {{ item.output(project, j).col1 }}
              </span>
              <span
                class="float-right text-primary text-subtitle-2 font-weight-bold"
              >
                {{ item.output(project, j).col2 }}
              </span>
            </v-list-item-subtitle>
          </div>
        </v-list-item>
        <v-divider v-if="i < itemsList.length - 1" inset />
      </div>
    </v-list>
  </v-container>
</template>

<script>
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';

export default {
  name: 'ProjectCardItemsList',

  props: {
    projectInfo: {
      type: Object,
      required: true,
    },
  },

  data(instance) {
    return {
      itemsList: [
        {
          icon: 'mdi-map-marker',
          label: 'Site',
          project: [instance.projectInfo.siteName],
          output: (item) => ({ col1: item, col2: '' }),
        },
        {
          icon: 'mdi-calendar-multiselect',
          label: 'Date Range',
          project: [
            instance.projectInfo.projectStartDateTime,
            instance.projectInfo.projectEndDateTime,
          ],
          output: (item, i) => {
            const labels = ['Start Date', 'End Date'];
            return { col1: labels[i], col2: dateTimeToUSFormatFactory(item) };
          },
        },
        {
          icon: 'mdi-notebook-edit-outline',
          label: 'Description',
          project: [instance.projectInfo.description],
          output: (item) => ({ col1: item, col2: '' }),
        },
        {
          icon: 'mdi-collage',
          label: 'Associated Model',
          project: [instance.projectInfo.modelName],
          output: (item) => ({ col1: item, col2: '' }),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel:first-child {
  border-top: none;
}

.v-list-item {
  :deep(.v-list-item__spacer) {
    width: 17px !important;
  }
}
</style>
