import '@mdi/font/css/materialdesignicons.css';

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

import { VTimePicker } from 'vuetify/lib/labs/components.mjs';

import { VNumberInput } from 'vuetify/labs/VNumberInput';

import DayJsAdapter from '@date-io/dayjs';

const e360Theme = {
  dark: false,
  colors: {
    primary: '#2E3C54',
    accentOrange: '#FF6E52',
    warning: '#FF6E52',
    charcoal: '#45484D',
    eerieBlack: '#18191A',
    intechBlue: '#21AAD4',
    heatColor: '#B05FA1',
    background: '#F8FCFF',
    aliceBlue: '#F8FCFF',
    greenGraph: '#62BE37',
    sonicSilver: '#737880',
    purpleGraph: '#CC00ED',
    disabled: '#E0E0E0',
    solarYellow: '#ffd600',
    electricGreen: '#779E90',

    daikin: '#00CDFF',
  },
};

export default createVuetify({
  date: {
    adapter: DayJsAdapter,
  },
  components: {
    VTimePicker,
    VNumberInput,
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'e360Theme',
    themes: {
      e360Theme,
    },
  },
});
