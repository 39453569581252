<template>
  <section>
    <h1 v-if="!loading">
      Manage Project Measures for: <b>{{ projectName }}</b>
    </h1>
    <v-row>
      <v-col>
        <v-btn
          location="left"
          color="primary"
          title="Download new yearly spreadsheet"
          @click="openNewSheetModal()"
        >
          <v-icon>mdi-cloud-download-outline</v-icon>
        </v-btn>

        <v-btn
          location="left"
          class="ml-5"
          title="Upload yearly spreadsheet"
          color="primary"
          @click="openUploadSheetModal()"
        >
          <v-icon>mdi-cloud-upload-outline</v-icon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-text-field
        class="mr-5"
        variant="solo"
        v-model="search"
        placeholder="Search"
      >
      </v-text-field>
    </v-row>
    <br />
    <v-dialog width="800" v-model="showNewSheetModal">
      <v-card>
        <v-card-title class="text-h5"
          >Download blank spreadsheet for year</v-card-title
        >
        <v-autocomplete
          :items="years"
          v-model="selectedYear"
          label="Select Year"
          persistent-hint
          hint="Select Year"
          return-object
          variant="solo"
          style="padding: 15px"
        ></v-autocomplete>
        <v-autocomplete
          :items="analyticProjectMeasures"
          v-model="selectedAnalyticProjectMeasure"
          item-title="measureName"
          item-value="analyticProjectMeasureId"
          label="Select Measure"
          persistent-hint
          hint="Select Measure"
          return-object
          variant="solo"
          style="padding: 15px"
        ></v-autocomplete>
        <v-card-actions>
          <v-btn @click="handleDownloadNewSheet()" color="primary"
            >Download</v-btn
          >
          <v-btn @click="closeNewSheetModal()" class="ml-5">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="800" v-model="showUploadSheetModal">
      <v-card>
        <v-card-title class="text-h5">Upload spreadsheet</v-card-title>
        <v-autocomplete
          :items="analyticProjectMeasures"
          v-model="selectedUploadAnalyticProjectMeasure"
          item-title="measureName"
          item-value="analyticProjectMeasureId"
          label="Select Measure"
          persistent-hint
          hint="Select Measure"
          return-object
          variant="solo"
          style="padding: 15px"
        ></v-autocomplete>
        <v-file-input
          small-chips
          label="Select Cost Savings Goal Data"
          v-model="costSavingsGoalFile"
          class="pr-4"
        ></v-file-input>
        <v-card-actions>
          <v-btn @click="handleUploadSheet()" color="primary">Import</v-btn>
          <v-btn @click="closeUploadSheetModal()" class="ml-5">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
    ></v-progress-linear>

    <confirm-dialog
      ref="confirmDelete"
      title="Delete this cost savings goal?"
    ></confirm-dialog>

    <v-row v-if="analyticProjectMeasures.length === 0">
      <v-card
        class="card-center"
        flat
        rounded="0"
        style="background-color: #ecf2f6 !important; font-weight: 800"
      >
        No analytic project measures configured
      </v-card>
    </v-row>
    <v-data-table
      v-else
      :headers="headers"
      :items="analyticProjectMeasures"
      :hide-default-footer="analyticProjectMeasures.length <= 10"
      :search="search"
      class="elevation-1"
    >
      <!--Headers slot-->
      <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="column.class"
            @click="() => toggleSort(column)"
          >
            {{ column.title }}
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)" size="sm" />
            </template>
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.measureName }}</td>
          <td v-if="!!Object.keys(item.yearAndVersion).length">
            <v-select
              @update:modelValue="updateItemVersions(item.selectedYear, item)"
              v-model="item.selectedYear"
              :items="Object.keys(item.yearAndVersion)"
              density="compact"
            ></v-select>
          </td>
          <td v-if="!!Object.keys(item.yearAndVersion).length">
            <v-select
              v-model="item.selectedVersion"
              :items="item.availableVersions"
              density="compact"
            ></v-select>
          </td>
          <td v-if="!!Object.keys(item.yearAndVersion).length">
            <v-icon
              @click="handleDownloadSheet(item)"
              title="Download cost savings goal"
              >mdi-cloud-download-outline</v-icon
            >
            <v-icon
              class="ml-5"
              @click="handleViewChart(item)"
              title="View Cost Savings Report"
              >mdi-chart-bar</v-icon
            >
          </td>
        </tr>
      </template>
    </v-data-table>

    <span class="px-4 text-caption text-primary">
      {{ $appOldVersion }}
    </span>
  </section>
</template>

<script>
import api from '../_api';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
export default {
  components: {
    'confirm-dialog': ConfirmDialog,
  },

  data() {
    return {
      loading: true,

      showNewSheetModal: false,
      showUploadSheetModal: false,
      years: [],
      analyticProjectMeasures: [],
      search: '',

      selectedYear: null,
      selectedAnalyticProjectMeasure: null,
      selectedUploadAnalyticProjectMeasure: null,
      costSavingsGoalFile: null,

      projectId: null,
      projectName: '',
      headers: [
        {
          title: 'Measure Name',
          value: 'measureName',
        },
        {
          title: 'Year',
          value: 'year',
        },
        {
          title: 'Version',
          value: 'versionId',
        },
        {
          title: 'Actions',
          value: 'id',
          sortable: false,
        },
      ],
      defaultOptions: {
        // sortBy: ["version"],
        // sortDesc: true,
      },
    };
  },

  async created() {
    this.projectId = this.$route.params.id;
    this.years = this.getYears();
    try {
      const result = await api.getAnalyticProjectMeasures(this.projectId);
      if (result.length === 0) {
        this.loading = false;
        return;
      }
      this.analyticProjectMeasures = result;
      this.analyticProjectMeasures.forEach((e) => {
        e.selectedYear = Math.max
          .apply(null, Object.keys(e.yearAndVersion))
          .toString();
        e.selectedVersion = Math.max.apply(
          null,
          e.yearAndVersion[e.selectedYear]
        );
        e.availableVersions = e.yearAndVersion[e.selectedYear];
      });
      this.projectName = `${this.analyticProjectMeasures[0].siteName} - ${this.analyticProjectMeasures[0].projectName}`;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    getYears() {
      var startYear = new Date().getFullYear() + 1;
      var years = [];
      for (var i = 0; i < 6; i++) {
        years.push(startYear);
        startYear -= 1;
      }
      return years;
    },

    //New Yearly Sheet
    openNewSheetModal() {
      this.showNewSheetModal = true;
    },

    closeNewSheetModal() {
      this.showNewSheetModal = false;
    },

    async handleDownloadNewSheet() {
      try {
        this.loading = true;
        let zipStream = await api.downloadNewYearlySpreadsheet(
          this.projectId,
          this.selectedAnalyticProjectMeasure.analyticProjectMeasureId,
          this.selectedYear
        );
        let fileURL = window.URL.createObjectURL(
          new Blob([zipStream], { type: 'application/vnd.ms-excel' })
        );
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        var fileName = `Yearly Cost Saving Goals for Year-${this.selectedYear} ${this.selectedAnalyticProjectMeasure.measureName}`;
        fileLink.setAttribute('download', fileName + '.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    //Upload Yearly Sheet
    openUploadSheetModal() {
      this.showUploadSheetModal = true;
    },

    closeUploadSheetModal() {
      this.showUploadSheetModal = false;
    },

    async handleUploadSheet() {
      try {
        this.loading = true;
        var result = await api.uploadYearlySpreadsheet(
          this.projectId,
          this.selectedUploadAnalyticProjectMeasure.analyticProjectMeasureId,
          this.costSavingsGoalFile
        );
        console.log(result);
        this.$router.go(0);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    //Download Yearly Sheet
    async handleDownloadSheet(item) {
      try {
        this.loading = true;
        let zipStream = await api.downloadYearlySpreadsheet(
          this.projectId,
          item.analyticProjectMeasureId,
          item.selectedYear,
          item.selectedVersion
        );
        let fileURL = window.URL.createObjectURL(
          new Blob([zipStream], { type: 'application/vnd.ms-excel' })
        );
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        var fileName = `Yearly Cost Saving Goals for Year-${item.selectedYear} ${item.measureName} v${item.selectedVersion}`;
        fileLink.setAttribute('download', fileName + '.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    handleViewChart(item) {
      this.$router.push({
        name: 'AnalyticsMandVProjectMeasureCostSavingsChart',
        params: {
          projectId: this.projectId,
          projectMeasureId: item.analyticProjectMeasureId,
          year: item.selectedYear,
          version: item.selectedVersion,
        },
      });
    },

    updateItemVersions(yr, item) {
      let apm = this.analyticProjectMeasures;
      this.analyticProjectMeasures = null;
      apm.forEach((e) => {
        if (e.analyticProjectMeasureId == item.analyticProjectMeasureId) {
          e.selectedYear = yr;
          e.selectedVersion = Math.max.apply(
            null,
            e.yearAndVersion[e.selectedYear]
          );
          e.availableVersions = e.yearAndVersion[e.selectedYear];
        }
      });
      this.analyticProjectMeasures = apm;
    },
  },
};
</script>
