<template>
  <Field :name="$attrs.label" :rules="rules">
    <template v-slot="{ errors }">
      <v-select
        :hint="$attrs.hint"
        :chips="$attrs.chips"
        :persistent-hint="$attrs.persistentHint"
        :multiple="$attrs.multiple"
        :items="items"
        :item-props="itemProps"
        v-model="innerValue"
        :error-messages="errors"
        :label="$attrs.label"
        @update:modelValue="handleSelectChange"
        ref="validateSelectField"
        :loading="$attrs.loading"
        :disabled="$attrs.disabled"
      />
    </template>
  </Field>
</template>

<script>
import { Field, defineRule } from 'vee-validate';

defineRule('required', (value) => {
  if (!value) {
    return 'This field is required';
  }
  return true;
});

export default {
  components: {
    Field,
  },
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: [String, Function],
      default: 'id',
    },
    itemTitle: {
      type: String,
      default: 'name',
    },
  },
  data: () => ({
    innerValue: '',
    hint: '',
  }),
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },

  methods: {
    handleSelectChange() {
      this.$emit('change-value', this.innerValue);
    },

    itemProps(item) {
      return {
        title: item[this.itemTitle],
        value: item[this.itemValue],
      };
    },
  },
};
</script>
