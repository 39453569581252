<!-- eslint-disable vue/valid-v-for -->
<template>
  <v-stepper light flat :value="currentStep">
    <v-stepper-header>
      <template v-for="(step, index) in steps">
        <v-stepper-item
          :step="index + 1"
          :complete="currentStep > index + 1"
          :color="currentStep > index + 1 ? 'greenGraph' : 'primary'"
        >
          {{ step }}
        </v-stepper-item>
        <v-divider
          v-if="index !== steps.length - 1"
          :key="`divider-${index}`"
        />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: 'StepperLoader',

  props: {
    stepperMessage: {
      type: String,
      default: 'Replaying the strategy',
    },
  },

  data() {
    return {
      currentStep: 1,
      steps: ['Calculating the results', 'Loading the results'],
    };
  },

  mounted() {
    this.steps.unshift(this.stepperMessage);
    this.startStepper();
  },

  methods: {
    startStepper() {
      const interval = setInterval(() => {
        if (this.currentStep === this.steps + 1) {
          clearInterval(interval);
          return;
        }

        this.currentStep += 1;
      }, 3200);
    },
  },
};
</script>
