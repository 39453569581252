<template>
  <div>
    <!--Settings change Warning-->
    <v-alert
      border="start"
      :border-color="`rgb(${CO2Description().rgb.join(',')})`"
      color="white"
      elevation="2"
      :icon="CO2Description().icon"
      class="text-primary"
      width="400"
    >
      <p>
        <strong>
          {{ CO2Description().title }}
        </strong>
      </p>
      <span class="text-justify" v-html="CO2Description().description" />
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'CO2Alert',

  props: {
    CO2Description: {
      type: Function,
      required: true,
    },
  },
};
</script>
