<template>
  <div>
    <BannerAlertVue
      v-if="!strategiesList.length && !isLoading"
      :message="bannerMessage"
    />
    <StrategiesListVue v-else :StrategiesList="strategiesList" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import StrategiesListVue from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategiesList.vue';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

const store = useStore();
const route = useRoute();

const strategiesList = computed(() => {
  return store.getters[
    'StrategiesStore/StrategiesStore/selectedSiteStrategies'
  ];
});

const isLoading = computed(() => {
  return store.getters[
    'StrategiesStore/StrategiesStore/selectedSiteStrategiesIsLoading'
  ];
});

const bannerMessage = ref(`No ${route.meta.label} configured for this site`);
</script>
