<template>
  <div class="d-flex float-right align-content-start flex-row ma-3">
    <v-tooltip location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn
          class="mr-1"
          color="aliceBlue"
          height="35"
          width="auto"
          v-bind="props"
        >
          <v-icon color="primary" class="ml-n2">
            {{ getWeatherIcon() }}
          </v-icon>
          <span class="text-primary font-weight-bold py-2 spacing">
            {{ Math.round(Number(weatherData.temperature)) }} &deg;F
          </span>
        </v-btn>
      </template>
      <span> Weather: {{ weatherData.weatherSummary }} </span>
    </v-tooltip>
    <v-tooltip
      location="bottom"
      content-class="control-tooltip"
      v-if="weatherData.airQualityIndex"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="aliceBlue"
          :style="`border-right: 5px solid ${`rgb(${getWeatherDescription().rgb.join(
            ','
          )})`} !important`"
          height="35"
          width="auto"
          v-bind="props"
        >
          <v-icon color="primary" class="ml-n2">mdi-air-filter</v-icon>
          <span class="text-primary font-weight-bold py-2 ml-1 mr-n2 spacing">
            {{ weatherData.airQualityIndex }}
          </span>
        </v-btn>
      </template>
      <WeatherAlert :WeatherDescription="getWeatherDescription" />
    </v-tooltip>
  </div>
</template>

<script>
import WeatherAlert from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/WeatherAlert.vue';

export default {
  name: 'WeatherWidget',

  components: {
    WeatherAlert,
  },

  props: {
    weatherData: {
      type: Object,
      required: true,
    },
    weatherIcons: {
      type: Array,
      default: () => [
        {
          icon: 'weather-rainy',
          weatherIcon: 'rain',
        },
        {
          icon: 'weather-sunny',
          weatherIcon: 'sunny',
        },
        {
          icon: 'weather-cloudy',
          weatherIcon: 'cloudy',
        },
        {
          icon: 'weather-partly-cloudy',
          weatherIcon: 'partly-cloudy-day',
        },
        {
          icon: 'weather-night-partly-cloudy',
          weatherIcon: 'partly-cloudy-night',
        },
        {
          icon: 'weather-snowy',
          weatherIcon: 'snow',
        },
        {
          icon: 'weather-sunny',
          weatherIcon: 'clear-day',
        },
        {
          icon: 'weather-night',
          weatherIcon: 'clear-night',
        },
        {
          icon: 'weather-fog',
          weatherIcon: 'fog',
        },
        {
          icon: 'weather-windy',
          weatherIcon: 'wind',
        },
      ],
    },
  },

  methods: {
    getWeatherIcon() {
      const { weatherIcon } = this.weatherData;
      const icon = this.weatherIcons.find(
        (icon) => icon.weatherIcon === weatherIcon
      );
      return icon ? `mdi-${icon.icon}` : 'mdi-information-off';
    },

    getWeatherDescription() {
      const { airQualityIndex } = this.weatherData;
      if (!airQualityIndex)
        return { description: 'Not available', rgb: [0, 0, 0] };
      if (airQualityIndex <= 50)
        return {
          icon: 'mdi-run-fast',
          title: 'Good',
          description: 'Enjoy activities!',
          rgb: [0, 228, 0],
        };
      if (airQualityIndex <= 100)
        return {
          icon: 'mdi-exit-run',
          title: 'Moderate',
          description: `<strong>People unusually sensitive to air pollution:</strong><br />
            Plan strenuous outdoor activities when air quality is better.`,
          rgb: [255, 214, 0],
        };
      if (airQualityIndex <= 150)
        return {
          icon: 'mdi-face-mask',
          title: 'Unhealthy for Sensitive Groups',
          description: 'Avoid strenuous outdoor activities.',
          rgb: [255, 126, 0],
        };
      if (airQualityIndex <= 200)
        return {
          icon: 'mdi-allergy',
          title: 'Unhealthy',
          textColor: 'white',
          description: `<strong>Sensitive groups:</strong> Avoid strenuous outdoor activities.<br />
            <strong>Everyone:</strong> Cut back or reschedule strenuous outdoor activities.`,
          rgb: [255, 0, 0],
        };
      if (airQualityIndex <= 300)
        return {
          icon: 'mdi-emoticon-sick',
          title: 'Very Unhealthy',
          textColor: 'white',
          description: `<strong>Sensitive groups:</strong> Avoid all outdoor physical activities.<br />
            <strong>Everyone:</strong> Significantly cut back on outdoor physical activities.`,
          rgb: [143, 63, 151],
        };
      return {
        icon: 'mdi-skull',
        title: 'Hazardous!',
        textColor: 'white',
        description: 'Avoid all outdoor physical activities.',
        rgb: [126, 0, 35],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-overlay ::v-deep(.control-tooltip) {
  background: none !important;
}

.v-overlay ::v-deep(.v-alert__border) {
  opacity: 1 !important;
}

.spacing {
  letter-spacing: 0px;
}
</style>
