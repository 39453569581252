<template>
  <div id="map" class="map-view" />
  <!-- <AccordionVue :siteDetails="siteDetails" /> -->
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: 'MapVue',

  props: {
    siteDetails: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.geolocate();
  },

  methods: {
    geolocate() {
      const loader = new Loader({
        apiKey: 'AIzaSyCda-Sl20-2xJ-k7nCtmRiOTXWRgfS6P60',
        version: 'weekly',
      });

      loader.importLibrary('maps').then(async ({ Map }) => {
        const map = new Map(document.getElementById('map'), {
          center: {
            lat: this.siteDetails.latitude,
            lng: this.siteDetails.longitude,
          },
          zoom: 20,
        });

        new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            this.siteDetails.latitude,
            this.siteDetails.longitude
          ),
          icon: '/images/E360-bubbles.svg',
          map: map,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.map-view {
  height: 600px;
  width: auto;
  border-radius: 4px !important;
}
</style>
