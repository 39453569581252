<!-- eslint-disable vue/valid-v-for -->
<template>
  <v-sheet flat border class="pa-4 col-sm-12">
    <p class="text-subtitle-1 pt-3 text-primary">
      <span class="font-weight-bold">Energy</span>
      - Input values at execution time
    </p>
    <v-row>
      <v-col sm="4">
        <v-text-field
          v-model="InputData.CurrentDateTime"
          :rules="[isDate]"
          variant="outlined"
          label="Current Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="InputData.ReadingDateTime"
          :rules="[isDate]"
          variant="outlined"
          label="Reading Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="InputData.DelayInMinutes"
          :rules="[isNumberGreaterThanZero]"
          variant="outlined"
          label="Delay in Minutes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3">
        <v-text-field
          v-model="InputData.ElectricityValueKwh"
          :rules="[isNumber]"
          variant="outlined"
          label="Electricity kWh"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="InputData.ElectricityValueKw"
          :rules="[isNumber]"
          variant="outlined"
          label="Electricity kW"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="InputData.SolarValueKwh"
          :rules="[isNumber]"
          variant="outlined"
          label="Solar kWh"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="InputData.SolarValueKw"
          :rules="[isNumber]"
          variant="outlined"
          label="Solar kW"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3">
        <v-text-field
          v-model="InputData.OutSiteTemperature"
          :rules="[isNumber]"
          variant="outlined"
          label="Outside Air Temperature"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import {
  isNumber,
  isNumberGreaterThanZero,
  hasValue,
  isDate,
} from '@/Core.Patterns/Factory/validationFactory.ts';

export default {
  name: 'FiveMinutesTotalEnergyWithWeatherInput',

  props: {
    InputData: {
      type: Object,
      required: true,
    },
  },

  watch: {
    InputData: {
      handler() {
        this.$emit('update:model', this.InputData);
      },
      deep: true,
    },
  },

  methods: {
    isNumber,
    isNumberGreaterThanZero,
    hasValue,
    isDate,
  },
};
</script>
