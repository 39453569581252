import { markRaw } from 'vue';

import VAV11Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV11Drawing.vue';
import VAV12Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV12Drawing.vue';
import VAV13Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV13Drawing.vue';
import VAV14Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV14Drawing.vue';
import VAV15Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV15Drawing.vue';
import VAV16Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV16Drawing.vue';
import VAV17Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV17Drawing.vue';
import VAV18Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV18Drawing.vue';
import VAV19Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV19Drawing.vue';
import VAV110Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV110Drawing.vue';
import VAV111Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV111Drawing.vue';
import VAV112Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV112Drawing.vue';
import VAV113Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV113Drawing.vue';
import VAV114Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV114Drawing.vue';
import VAV115Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV115Drawing.vue';
import VAV116Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV116Drawing.vue';
import VAV117Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV117Drawing.vue';
import VAV118Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV118Drawing.vue';
import VAV119Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV119Drawing.vue';
import VAV120Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV120Drawing.vue';
import VAV121Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV121Drawing.vue';
import VAV122Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV122Drawing.vue';
import VAV123Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite121/VAV123Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      console.error(error);
    }
  }
};

const Suite121ControlsList = [
  {
    id: 5,
    name: 'VAV 1-21',
    controlId: 2155,
    component: markRaw(VAV121Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-21',
      },
    },
    style: {
      position: 'absolute',
      top: '66.5%',
      right: '30.5%',
      width: '29.6%',
      height: '29.6%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '78%',
      left: '50%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 0,
    name: 'VAV 1-22',
    controlId: 2156,
    component: markRaw(VAV122Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-22',
      },
    },
    style: {
      position: 'absolute',
      top: '63.4%',
      right: '15.2%',
      width: '33.3%',
      height: '33.3%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '78%',
      right: '23%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 1-7',
    controlId: 2149,
    component: markRaw(VAV17Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-7',
      },
    },
    style: {
      position: 'absolute',
      bottom: '25.9%',
      right: '38.8%',
      width: '21.4%',
      height: '21.6%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '63%',
      left: '48%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 1-9',
    controlId: 2158,
    component: markRaw(VAV19Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-9',
      },
    },
    style: {
      position: 'absolute',
      top: '34%',
      right: '40.7%',
      width: '24%',
      height: '24%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '44%',
      left: '42%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 1-10',
    controlId: 2159,
    component: markRaw(VAV110Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-10',
      },
    },
    style: {
      position: 'absolute',
      top: '51.5%',
      right: '28.6%',
      width: '11.7%',
      height: '11.7%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '55%',
      left: '62.5%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 4,
    name: 'VAV 1-20',
    controlId: 2151,
    component: markRaw(VAV120Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-20',
      },
    },
    style: {
      position: 'absolute',
      top: '22.3%',
      right: '32%',
      width: '15.2%',
      height: '15.2%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '32%',
      left: '61%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 6,
    name: 'VAV 1-12',
    controlId: 2144,
    component: markRaw(VAV112Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-12',
      },
    },
    style: {
      position: 'absolute',
      top: '22.5%',
      right: '46.2%',
      width: '11.3%',
      height: '11.3%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '27%',
      left: '45%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 7,
    name: 'VAV 1-13',
    controlId: 2162,
    component: markRaw(VAV113Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-13',
      },
    },
    style: {
      position: 'absolute',
      top: '21.6%',
      right: '57.25%',
      width: '19.3%',
      height: '19.3%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '27%',
      left: '34%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 8,
    name: 'VAV 1-19',
    controlId: 2150,
    component: markRaw(VAV119Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-19',
      },
    },
    style: {
      position: 'absolute',
      top: '2.2%',
      right: '17.9%',
      width: '35.5%',
      height: '35.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '10%',
      right: '25%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 9,
    name: 'VAV 1-18',
    controlId: 2097,
    component: markRaw(VAV118Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-18',
      },
    },
    style: {
      position: 'absolute',
      top: '6.2%',
      right: '36.2%',
      width: '26.1%',
      height: '26.1%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '10%',
      right: '50%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 10,
    name: 'VAV 1-17',
    controlId: 2171,
    component: markRaw(VAV117Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-17',
      },
    },
    style: {
      position: 'absolute',
      top: '1.2%',
      right: '57%',
      width: '21.2%',
      height: '21.4%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '10%',
      right: '65%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 11,
    name: 'VAV 1-16',
    controlId: 2170,
    component: markRaw(VAV116Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-16',
      },
    },
    style: {
      position: 'absolute',
      top: '6%',
      left: '12.3%',
      width: '11.7%',
      height: '11.7%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '10%',
      left: '15%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 12,
    name: 'VAV 1-23',
    controlId: 2157,
    component: markRaw(VAV123Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-23',
      },
    },
    style: {
      position: 'absolute',
      top: '24.5%',
      left: '26.7%',
      width: '6.3%',
      height: '6.3%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '25%',
      left: '25%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 13,
    name: 'VAV 1-15',
    controlId: 2169,
    component: markRaw(VAV115Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-15',
      },
    },
    style: {
      position: 'absolute',
      top: '17.5%',
      left: '10.8%',
      width: '12.6%',
      height: '12.6%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '22%',
      left: '14.5%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 14,
    name: 'VAV 1-14',
    controlId: 2146,
    component: markRaw(VAV114Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-14',
      },
    },
    style: {
      position: 'absolute',
      top: '17.4%',
      left: '3.95%',
      width: '46.5%',
      height: '46.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '18.5%',
      left: '22%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 15,
    name: 'VAV 1-8',
    controlId: 2142,
    component: markRaw(VAV18Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-8',
      },
    },
    style: {
      position: 'absolute',
      top: '42.5%',
      left: '4.5%',
      width: '25.5%',
      height: '25%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '52%',
      left: '14.5%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 16,
    name: 'VAV 1-1',
    controlId: 2137,
    component: markRaw(VAV11Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-1',
      },
    },
    style: {
      position: 'absolute',
      bottom: '8.5%',
      left: '10.5%',
      width: '17.4%',
      height: '17.4%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      bottom: '15%',
      left: '16.5%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 17,
    name: 'VAV 1-5',
    controlId: 2140,
    component: markRaw(VAV15Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-5',
      },
    },
    style: {
      position: 'absolute',
      bottom: '8.5%',
      left: '15%',
      width: '33.5%',
      height: '33.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      bottom: '25%',
      left: '29.2%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 18,
    name: 'VAV 1-2',
    controlId: 2096,
    component: markRaw(VAV12Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-2',
      },
    },
    style: {
      position: 'absolute',
      bottom: '25.7%',
      left: '13.8%',
      width: '10.7%',
      height: '10.7%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      bottom: '27.5%',
      left: '16.5%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 19,
    name: 'VAV 1-3',
    controlId: 2138,
    component: markRaw(VAV13Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-3',
      },
    },
    style: {
      position: 'absolute',
      bottom: '41.8%',
      left: '19%',
      width: '17.9%',
      height: '17.9%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      bottom: '45%',
      left: '25%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 20,
    name: 'VAV 1-4',
    controlId: 2139,
    component: markRaw(VAV14Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-4',
      },
    },
    style: {
      position: 'absolute',
      top: '38.7%',
      right: '52.2%',
      width: '19.5%',
      height: '19.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '50%',
      left: '33.2%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 21,
    name: 'VAV 1-6',
    controlId: 2141,
    component: markRaw(VAV16Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-6',
      },
    },
    style: {
      position: 'absolute',
      top: '33.7%',
      right: '56%',
      width: '11.9%',
      height: '11.9%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '38%',
      left: '33.2%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 22,
    name: 'VAV 1-11',
    controlId: 2143,
    component: markRaw(VAV111Hotspot),
    techInfo: {
      location: '1',
      icon: '',
      device: {
        label: 'VAV 1-11',
      },
    },
    style: {
      position: 'absolute',
      top: '29.6%',
      right: '31.1%',
      width: '25.8%',
      height: '25.8%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '38%',
      left: '52.8%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default Suite121ControlsList;
