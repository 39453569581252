<template>
  <v-card class="rounded-2">
    <div>
      <v-card-title>
        <v-text-field
          v-model="searchTerm"
          append-icon="mdi-magnify"
          label="Search Messages"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="messagesList"
        :items-per-page="10"
        :search="searchTerm"
        :header-props="headerProps"
        class="elevation-1"
        no-data-text="No messages found"
      >
        <!--Headers slot-->
        <template
          v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }"
        >
          <tr>
            <th
              v-for="column in columns"
              :key="column.key"
              :class="column.class"
              @click="() => toggleSort(column)"
            >
              {{ column.title }}
              <template v-if="isSorted(column)">
                <v-icon :icon="getSortIcon(column)" size="sm" />
              </template>
            </th>
          </tr>
        </template>
        <!--Items slot-->
        <template v-slot:item="{ item }">
          <tr>
            <!--Type slot-->
            <td>
              <v-tooltip location="left">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-icon v-bind="activatorProps" class="ml-2" color="primary">
                    {{ getMessageTypeIcon(item) }}
                  </v-icon>
                </template>
                <span>
                  {{ getMessageTypeLabel(item) }} -
                  {{ item.messageCenterMessageId }}
                </span>
              </v-tooltip>
            </td>
            <!--Users slot-->
            <td>
              <v-tooltip location="right">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-icon v-bind="activatorProps" class="ml-2" color="primary">
                    {{
                      item.userAssociationCount > 0
                        ? `mdi-account-group`
                        : `mdi-account-off`
                    }}
                  </v-icon>
                </template>
                <span>{{
                  item.userAssociationCount > 0
                    ? `Users assigned - ${item.userAssociationCount}`
                    : 'No users assigned'
                }}</span>
              </v-tooltip>
            </td>
            <!--Title slot-->
            <td>
              <v-tooltip location="left">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    :disabled="isCurrentRouteEdit"
                    variant="text"
                    v-bind="activatorProps"
                    @click="editMessage(item)"
                  >
                    <div class="crop">
                      <span class="text-primary text-subtitle-2 text-left">
                        {{ item.messageCenterMessageLabel || '-' }}
                      </span>
                    </div>
                  </v-btn>
                </template>
                <span> Edit this message </span>
              </v-tooltip>
            </td>
            <td>
              <span class="text-primary text-subtitle-2 text-left">
                {{
                  dateTimeToUSFormatFactory(item.lastModifiedDateTime, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                }}
              </span>
            </td>
            <td>
              <ActionButtonsVue :buttonsList="actionButtons" :item="item" />
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons.vue';

import API from '@/Core.Service.Domain/MessageCenter/Management/API';

export default {
  name: 'MessageCenterSentMessagesList',

  computed: {
    ...mapGetters({
      messagesList: 'MessageCenterManagementStore/getMessagesList',
      getMessageToEdit: 'MessageCenterManagementStore/getMessageToEdit',
      messagesTypesList: 'MessageCenterManagementStore/getMessagesTypesList',
    }),

    isCurrentRouteEdit() {
      return this.$route.name === 'MessageCenterEdit';
    },
  },

  components: {
    ActionButtonsVue,
  },

  data(instance) {
    return {
      searchTerm: '',
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          title: 'Type',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          filterable: true,
          key: 'messageCenterMessageTypeId',
        },
        {
          title: 'Users',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          filterable: true,
          key: 'userAssociationCount',
        },
        {
          title: 'Title',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          filterable: true,
          key: 'messageCenterMessageLabel',
        },
        {
          title: 'Created At',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          filterable: true,
          key: 'lastModifiedDateTime',
        },
        {
          title: 'Delete',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          filterable: false,
          key: '',
        },
      ],
      bannerMessage: 'No messages found',

      actionButtons: [
        {
          icon: () => 'trash-can-outline',
          toolTip: () => 'Delete message',
          action: async (item) => {
            instance.itemIsLoading = item.messageCenterMessageId;
            await instance.deleteMessage(item);
            instance.itemIsLoading = null;
          },
          loading: (item) =>
            item.messageCenterMessageId === instance.itemIsLoading,
          disabled: (item) =>
            item.userAssociationCount > 0 || instance.isCurrentRouteEdit,
        },
      ],
      itemIsLoading: null,
    };
  },

  methods: {
    ...mapActions({
      editMessageFromMessageCenter:
        'MessageCenterManagementStore/editMessageFromMessageCenter',
      getAllMessagesFromMessageCenter:
        'MessageCenterManagementStore/getAllMessagesFromMessageCenter',
    }),

    dateTimeToUSFormatFactory,

    async editMessage(message) {
      const id = message.messageCenterMessageId;
      try {
        await this.editMessageFromMessageCenter(id);
        this.$router.push({
          name: 'MessageCenterEdit',
          params: { id },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async deleteMessage(message) {
      const id = message.messageCenterMessageId;
      try {
        await API.deleteMessage(id);
        await this.getAllMessagesFromMessageCenter();
      } catch (error) {
        console.error(error);
      }
    },

    getMessageTypeIcon(message) {
      const iconsList = {
        1: 'mdi-text-box-outline',
        2: 'mdi-window-restore',
        3: 'mdi-image-area',
      };
      return iconsList[message.messageCenterMessageTypeId];
    },

    getMessageTypeLabel(message) {
      const label = this.messagesTypesList.find(
        (type) =>
          type.messageCenterMessageTypeId === message.messageCenterMessageTypeId
      );

      return label ? label.messageCenterMessageTypeName : '-';
    },
  },
};
</script>
