<template>
  <div class="mt-6 ml-4">
    <v-row>
      <v-col v-for="strategy in strategiesList" :key="strategy.controlId">
        <StrategyCardVue :StrategyInfo="strategy" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StrategyCardVue from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategyCard';
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

export default {
  name: 'StrategiesList',

  components: {
    StrategyCardVue,
  },

  props: {
    StrategiesList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      searchTerm: 'StrategiesStore/StrategiesStore/getStrategySearchTerm',
    }),

    strategiesList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems)
        : sortAlphabetically(this.StrategiesList);
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['description', 'name', 'executionFrequency'],
        this.StrategiesList
      );
    },
  },

  data() {
    return {
      filteredItems: [],
      cardsPerRow: 3,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-col {
  flex-grow: 0;
}
</style>
