<template>
  <v-container fluid>
    <component :is="currentComponent" />
    <router-view class="mt-2 ml-n3" />
  </v-container>
</template>

<script>
import ModelBar from '@/Core.Service.Domain/Analytics/Models/Components/ModelBar';
import MeasureBar from '@/Core.Service.Domain/Analytics/Measures/Components/MeasureBar';
import ProjectBar from '@/Core.Service.Domain/Analytics/Projects/Components/ProjectBar';
import NonRoutineEventBar from '@/Core.Service.Domain/Analytics/NonRoutineEvents/Components/NonRoutineEventBar';

export default {
  name: 'AnalyticsView',

  components: {
    AnalyticsModel: ModelBar,
    AnalyticsMeasure: MeasureBar,
    AnalyticsProject: ProjectBar,
    AnalyticsNonRoutineEvent: NonRoutineEventBar,
  },

  data(instance) {
    return {
      currentComponent: instance.$route.meta.analyticsTypeName,
    };
  },

  watch: {
    $route() {
      this.currentComponent = this.$route.meta.analyticsTypeName;
    },
  },
};
</script>
