export const getAnalyticModels = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticModel/sites/' + siteId);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const validateAnalyticModelNameForCreate = async (siteId, name) => {
  let result = null;
  let model = {
    siteId: siteId,
    name: name,
  };
  try {
    result = await window.axios.post(
      '/v1/AnalyticModel/create/validatename/',
      model
    );
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const validateAnalyticModelNameForUpdate = async (siteId, id, name) => {
  let result = null;
  let model = {
    id: id,
    name: name,
    siteId: siteId,
  };
  try {
    result = await window.axios.post(
      '/v1/AnalyticModel/update/validatename/',
      model
    );
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const validateAnalyticModelDataForRange = async (
  modelId,
  startTime,
  endTime
) => {
  try {
    const result = await window.axios.get(
      `/v1/analyticmodel/energy/weathernormalized/validate/?modelid=${modelId}&starttime=${startTime}&endtime=${endTime}`
    );
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const createAnalyticModel = async (siteId, model) => {
  let result = null;
  try {
    result = await window.axios.post(
      '/v1/AnalyticModel/sites/' + siteId,
      model
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateAnalyticModel = async (siteId, model) => {
  let result = null;
  try {
    result = await window.axios.put('/v1/AnalyticModel/sites/' + siteId, model);
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPortfolioAnalyticModels = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticModel/Portfolio');
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticProjects = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticProject/sites/' + siteId);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getPortfolioAnalyticProjects = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticProject/portfolio/');
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticProject = async (projectId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticProject/' + projectId);
    let project = result.data.data;
    return project;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticModel = async (modelId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticModel/' + modelId);
    let model = result.data.data;
    return model;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

//Analytics Service start
// TODO: keep, will rewrite
export const downLoadAnalyticModelZip = async (siteId, analyticModel) => {
  let result = null;
  const requestOptions = {
    headers: {
      'X-API-Type': 'analytics',
    },
    responseType: 'blob',
    data: analyticModel,
    method: 'post',
    url:
      '/v1/analytics/energy/sites/' +
      siteId +
      '/weathernormalized/predictions/download',
  };

  try {
    result = await window.axios.request(requestOptions);
    let zip = result.data;
    return zip;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
//Analytics Service end

export const getSites = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/AnalyticModel/Site/sites');
    let sites = result.data.data;
    return sites;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getEndUses = async (siteId) => {
  try {
    let result = await window.axios.get('/v1/AnalyticModel/EndUse/' + siteId);
    let endUses = result.data.data;
    return endUses;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalysisTypes = async () => {
  try {
    let result = await window.axios.get('/v1/AnalyticModel/AnalysisType/');
    let types = result.data.data;
    return types;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticFactors = async () => {
  try {
    let result = await window.axios.get('/v1/AnalyticModel/AnalyticFactor/');
    let factors = result.data.data;
    return factors;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getNREs = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get(
      '/v1/AnalyticModel/NonRoutineEvent/' + siteId
    );
    let nres = result.data.data;
    return nres;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export default {
  getAnalyticProject,
  getAnalyticProjects,
  getAnalyticModel,
  getAnalyticModels,
  createAnalyticModel,
  updateAnalyticModel,
  validateAnalyticModelNameForCreate,
  validateAnalyticModelNameForUpdate,
  validateAnalyticModelDataForRange,
  getPortfolioAnalyticModels,
  getPortfolioAnalyticProjects,
  downLoadAnalyticModelZip,

  getSites,
  getEndUses,
  getAnalysisTypes,
  getAnalyticFactors,
  getNREs,
};
