<template>
  <v-container fluid>
    <SimpleTitleBar>Message Center - Management</SimpleTitleBar>
    <v-card class="pa-2 rounded-2">
      <v-row>
        <v-col cols="4">
          <router-view />
        </v-col>
        <v-col cols="8">
          <MessageCenterSentMessagesList />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script setup lang="ts">
import MessageCenterSentMessagesList from '@/Core.Service.Domain/MessageCenter/Management/Components/MessageCenterSentMessagesList.vue';

import SimpleTitleBar from '@/Core.UI.Domain/Components/SimpleTitleBar.vue';

import { onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const getUsersListForMessageCenter = () => {
  store.dispatch('MessageCenterManagementStore/getUsersListForMessageCenter');
};

onMounted(() => {
  getUsersListForMessageCenter();
});
</script>
