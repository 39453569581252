<template>
  <div class="mini-map pa-2">
    <slot />
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
.mini-map {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 100;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(40, 60, 84, 0.2);
  pointer-events: none;
}
</style>
