import ProjectsPortfolio from '@/Core.Service.Domain/Analytics/Projects/Views';

export const ProjectsRoute = {
  path: 'projects',
  name: 'AnalyticsProjects',
  component: ProjectsPortfolio,
  meta: {
    placeholder: 'Search for a Project',
    analyticsTypeName: 'AnalyticsProject',
    label: 'Projects',
    buttonLabel: 'New Project',
    toolTip: 'Project',
  },
};
