<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-dialog v-model="dialog" width="50vw" persistent>
    <v-card class="pa-4">
      <v-card-title class="text-primary">{{
        termsOfService.name
      }}</v-card-title>
      <v-card-text
        v-html="termsOfService.template"
        class="text-primary text-body-2 text-justify"
      />
      <v-card-actions>
        <v-btn color="primary" block @click="handleCloseDialog"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import API from '@/Core.Service.Domain/TermsOfService/API';

const props = defineProps(['show']);
const emits = defineEmits(['closeDialog']);
const dialog = ref(false);
const handleCloseDialog = () => {
  dialog.value = false;
  emits('closeDialog', false);
};
watch(
  () => props.show,
  (newVal) => {
    dialog.value = newVal;
  }
);

const store = useStore();
const isAuthenticated = ref(store.getters['session/isAuthenticated']);
onMounted(() => {
  if (isAuthenticated.value) {
    loadTermsOfService();
  }
});
watch(
  () => store.getters['session/isAuthenticated'],
  (newVal) => {
    if (newVal === true) {
      loadTermsOfService();
    }
  }
);

const termsOfService = ref({ name: '', template: '' });
const loadTermsOfService = async () => {
  try {
    termsOfService.value = await API.getTermsOfService();
  } catch (error) {
    console.log(error);
  }
};
</script>
