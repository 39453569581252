import Edit from '@/modules/analytics/m_and_v/projects/_components/Edit';
import Create from '@/modules/analytics/m_and_v/projects/_components/Create';
import Details from '@/modules/analytics/m_and_v/measures/_components/Details';
import DetailsEvent from '@/modules/analytics/m_and_v/projects/_components/DetailsEvent';

import ProjectMeasureIndex from '@/modules/analytics/m_and_v/projects/_components/ProjectMeasureIndex';
import CostSavingsGoalChart from '@/modules/analytics/m_and_v/projects/_components/CostSavingsGoalChart';
import ProjectImpactReport from '@/modules/analytics/m_and_v/projects/_components/ProjectImpactReport';

const AnalyticsMandVProjectDetailsRoute = {
  path: '/analytics/mandv/projects/:id',
  name: 'AnalyticsMandVProjectDetails',
  component: Edit,
};

const AnalyticsMandVProjectNewRoute = {
  path: '/site/:siteId/analytics/projects/create',
  name: 'AnalyticsMandVProjectNew',
  component: Create,
};

const AnalyticsMandVProjectIndexRoute = {
  path: '/analytics/mandv/projects',
  name: 'AnalyticsMandVProjectIndex',
  redirect: () => {
    return { name: 'AnalyticsProjects' };
  },
  meta: { permission: 'analytics:read' },
};

const AnalyticsMandVProjectMeasureIndexRoute = {
  path: '/analytics/mandv/projects/:id/projectmeasure',
  name: 'AnalyticsMandVProjectMeasureIndex',
  component: ProjectMeasureIndex,
  meta: { permission: 'analytics:read' },
};

const AnalyticsMandVProjectMeasureCostSavingsChartRoute = {
  path: '/analytics/mandv/projects/:projectId/projectmeasure/:projectMeasureId/year/:year/version/:version',
  name: 'AnalyticsMandVProjectMeasureCostSavingsChart',
  component: CostSavingsGoalChart,
  meta: { permission: 'analytics:read' },
};

const AnalyticsMandVProjectImpactReportRoute = {
  path: '/analytics/mandv/project/:id/impact',
  name: 'AnalyticsProjectImpactReport',
  component: ProjectImpactReport,
};

const AnalyticsMandVMeasuresIndexRoute = {
  path: '/analytics/mandv/measures',
  name: 'AnalyticsMandVMeasuresIndex',
  redirect: () => {
    return { name: 'AnalyticsMeasures' };
  },
  meta: { permission: 'measures:read' },
};

const AnalyticsMandVMeasuresDetailsRoute = {
  path: '/analytics/mandv/measures/:measuresId',
  name: 'AnalyticsMandVMeasuresDetails',
  component: Details,
};

const AnalyticsMandVMeasuresNewRoute = {
  path: '/analytics/mandv/measures/new',
  name: 'AnalyticsMandVMeasuresNew',
  component: Details,
};

const AnalyticsMandVProjectEventsDetailsRoute = {
  path: '/analytics/mandv/projects/events/:id',
  name: 'AnalyticsMandVProjectEventsDetail',
  component: DetailsEvent,
};

export {
  AnalyticsMandVProjectDetailsRoute,
  AnalyticsMandVProjectNewRoute,
  AnalyticsMandVProjectIndexRoute,
  AnalyticsMandVProjectMeasureIndexRoute,
  AnalyticsMandVProjectMeasureCostSavingsChartRoute,
  AnalyticsMandVProjectImpactReportRoute,
  AnalyticsMandVMeasuresIndexRoute,
  AnalyticsMandVMeasuresDetailsRoute,
  AnalyticsMandVMeasuresNewRoute,
  AnalyticsMandVProjectEventsDetailsRoute,
};
