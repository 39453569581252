<template>
  <v-card class="ma-2" flat variant="outlined" min-height="300">
    <v-toolbar>
      <v-toolbar-title>
        <span
          class="text-primary"
          v-html="selectedMessage.messageCenterMessageLabel"
        />
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-card-actions>
        <ActionButtonsVue
          :buttonsList="actionButtons as IActionButton<unknown>[]"
          :item="selectedMessage"
          :outlined="selectedMessage.isRead"
        />
      </v-card-actions>
    </v-toolbar>

    <v-card-text>
      <div class="mb-3">
        {{
          dateTimeToUSFormatFactory(
            selectedMessage.messageCenterMessageCreateDateTimeOffset,
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }
          )
        }}
      </div>
      <div
        class="text-primary"
        v-html="selectedMessage.messagingCenterMessageValue"
      />
    </v-card-text>
  </v-card>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons.vue';

import API from '@/Core.Service.Domain/MessageCenter/Users/API';

import { IActionButton } from '@/Core.UI.Domain/Components/types/actionButtons';
import { IUserMessage } from '@/Core.Service.Domain/MessageCenter/Users/types';

defineProps<{
  selectedMessage: IUserMessage;
}>();

const emits = defineEmits(['toggle']);

const actionButtons: IActionButton<IUserMessage>[] = [
  {
    icon: (item) => (item?.isRead ? 'email-outline' : 'email-open-outline'),
    toolTip: (item) => (item?.isRead ? 'Mark as Unread' : 'Mark as Read'),
    action: async (item) => {
      itemIsLoading.value = item?.userMessageCenterMessageId ?? null;
      if (item) {
        await toggleMessageRead(item);
      }
      itemIsLoading.value = null;
    },
    loading: (item) => item?.userMessageCenterMessageId === itemIsLoading.value,
  },
];
const itemIsLoading = ref<number | null>(null);

const toggleMessageRead = async (message: IUserMessage) => {
  const messageId = message.userMessageCenterMessageId;
  try {
    if (message.isRead) {
      await API.markMessageAsUnread(messageId);
    } else {
      await API.markMessageAsRead(messageId);
    }
    message.isRead = !message.isRead;
    emits('toggle');
  } catch (error) {
    console.error(error);
  }
};
</script>
