<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="sensorsList"
      :items-per-page="10"
      :loading="!sensorsList.length"
      :header-props="headerProps"
      :footer-props="footerProps"
      class="elevation-1 mt-n3"
    >
      <!--Headers slot-->
      <template v-slot:headers="props">
        <tr>
          <th
            v-for="(header, i) in props.headers"
            :key="i"
            :class="header.class"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <!--Sensor Name slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.sensorName }}
            </span>
          </td>
          <!--Sensor Unity of Measure slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.sensorUnitOfMeasure }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

export default {
  name: 'PieraSensorsList',

  props: {
    SensorsList: {
      type: Array,
      required: true,
    },
  },

  computed: {
    sensorsList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems, 'sensorName')
        : sortAlphabetically(this.SensorsList, 'sensorName');
    },
  },

  data() {
    return {
      filteredItems: [],
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          text: 'Sensor Name',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'sensorName',
          sortable: true,
        },
        {
          text: 'Sensor Unity of Measure',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'sensorUnitOfMeasure',
          sortable: true,
        },
      ],
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },
    };
  },
};
</script>
