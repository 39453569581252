<template>
  <v-row>
    <v-col :cols="showPeriodSelector ? 3 : 6" class="mr-n4">
      <v-text-field
        v-model="hour"
        :label="customLabels.hour"
        type="number"
        variant="outlined"
        @update:modelValue="handleTimeChange"
        :hint="format === '12' ? '12 hour format' : '24 hour format'"
        :persistent-hint="showPersistentHint"
        :rules="[
          (v: number) => !!v || 'Hour is required',
          (v: number) =>
            (v >= 0 && v <= (format === '12' ? 12 : 23)) ||
            (format === '12'
              ? 'Hour must be between 0 and 12'
              : 'Hour must be between 0 and 23'),
        ]"
      >
        <template v-slot:prepend v-if="prependIconData.showPrependIcon">
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon color="primary" v-bind="props">
                {{ prependIconData.prependIcon }}
              </v-icon>
            </template>
            <span>{{ prependIconData.prependIconText }}</span>
          </v-tooltip>
        </template>
        <template v-slot:append-inner>
          <v-icon color="primary">
            {{ format === '12' ? 'mdi-hours-12' : 'mdi-hours-24' }}
          </v-icon>
        </template>
      </v-text-field>
    </v-col>
    <v-col :cols="showPeriodSelector ? 3 : 6">
      <v-text-field
        v-model="minutes"
        :label="customLabels.minutes"
        variant="outlined"
        @update:modelValue="handleTimeChange"
        hint="Minutes"
        :persistent-hint="showPersistentHint"
        type="number"
        :rules="[
          (v: number) => !!v || 'Minutes are required',
          (v: number) => (v >= 0 && v <= 59) || 'Minutes must be between 0 and 59',
        ]"
      >
        <template v-slot:prepend>
          <h1 class="text-primary mt-n2">:</h1>
        </template>
        <template v-slot:append-inner>
          <v-icon color="primary">mdi-progress-clock</v-icon>
        </template>
      </v-text-field>
    </v-col>
    <v-col v-if="showPeriodSelector" cols="2" class="mr-n5">
      <v-select
        v-model="internalFormat"
        :items="items"
        label="Hour Format"
        variant="outlined"
        @update:modelValue="handleTimeChange"
      />
    </v-col>
    <v-col cols="2" v-if="showPeriodSelector && format === '12'">
      <v-select
        v-model="period"
        :items="periods"
        variant="outlined"
        @update:modelValue="handleTimeChange"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const hour = ref('');
const minutes = ref('');
const period = ref('AM');
const items = ref(['24', '12']);
const periods = ref(['AM', 'PM']);
const internalFormat = ref('24');

const props = defineProps({
  showPeriodSelector: {
    type: Boolean,
    default: false,
  },
  prependIconData: {
    type: Object,
    default: () => ({
      prependIcon: 'mdi-clock-start',
      prependIconText: 'Start time',
      showPrependIcon: false,
    }),
  },
  format: {
    type: String,
    default: '24',
  },
  presetHour: {
    type: String,
    default: '',
  },
  presetMinutes: {
    type: String,
    default: '',
  },
  showPersistentHint: {
    type: Boolean,
    default: false,
  },
  customLabels: {
    type: Object,
    default: () => ({
      hour: 'Hour',
      minutes: 'Minutes',
      period: 'Period',
    }),
  },
});

watch(
  () => props.format,
  () => {
    internalFormat.value = props.format;
  }
);

watch(
  () => props.presetHour,
  () => {
    hour.value = props.presetHour;
  }
);

watch(
  () => props.presetMinutes,
  () => {
    minutes.value = props.presetMinutes;
  }
);

const emits = defineEmits(['time-change']);

const handleTimeChange = () => {
  emits('time-change', {
    hour: hour.value,
    minutes: minutes.value,
    format: props.showPeriodSelector ? props.format : '',
    period: props.format === '12' ? period.value : '',
  });
};
</script>
