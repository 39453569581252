<template>
  <div>
    <v-row v-if="isLoading">
      <ProgressLinearLoaderVue :isLoading="isLoading" />
    </v-row>
    <div v-else>
      <v-row>
        <v-col v-if="!selectedSiteControls.length || !thermostatsList.length">
          <BannerAlertVue :message="bannerMessage" />
        </v-col>
        <v-col v-else>
          <ThermostatsListVue
            @delete-thermostat="handleDataChange"
            @set-thermostat-config="handleDataChange"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import API from '@/Core.Service.Domain/Controls/Thermostats/API';

import ThermostatsListVue from '@/Core.Service.Domain/Controls/Thermostats/Components/ThermostatsList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

export default {
  name: 'ThermostatsPortfolio',

  components: { ThermostatsListVue, ProgressLinearLoaderVue, BannerAlertVue },

  computed: {
    ...mapGetters({
      selectedSiteControls: 'ControlsStore/selectedSiteControls',
      controlCurrentRoute: 'ControlsStore/controlCurrentRoute',
      getControlSettingsHistoryMessagesTimerOn:
        'ControlsStore/getControlSettingsHistoryMessagesTimerOn',
      defaultSite: 'session/defaultSite',
    }),
    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },
  },

  data() {
    return {
      interval: null,
      isLoading: true,
      thermostatsList: [],
      timeInterval: 120000,
    };
  },

  watch: {
    controlCurrentRoute() {
      this.handleDataChange(true);
    },
    defaultSite() {
      this.handleDataChange(true);
    },
    getControlSettingsHistoryMessagesTimerOn(on) {
      if (on) {
        clearInterval(this.interval);
      } else {
        this.interval = setInterval(() => {
          this.handleDataChange();
        }, this.timeInterval);
      }
    },
  },

  created() {
    this.interval = setInterval(() => {
      this.handleDataChange();
    }, this.timeInterval);
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },

  methods: {
    ...mapActions({
      setThermostatsList: 'ControlsStore/ThermostatsStore/setThermostatsList',
    }),

    async getCachedThermostats() {
      try {
        const thermostats = JSON.parse(
          localStorage.getItem('local-storage-thermostats') || '[]'
        );
        const currentTime = Math.floor(Date.now() / 1000);
        const filteredThermostats = thermostats.filter((thermostat) => {
          const timeDiff = currentTime - thermostat.updatedAt;
          return timeDiff < 300;
        });

        const { id } = this.defaultSite;
        const thermostatsLatestUsage = await API.getThermostatLatestUsage(id);

        filteredThermostats.forEach((filteredThermostat) => {
          const responseThermostat = thermostatsLatestUsage.find(
            (t) => t.controlId === filteredThermostat.thermostatId
          );
          if (responseThermostat) {
            Object.assign(responseThermostat, filteredThermostat);
          }
        });

        localStorage.setItem(
          'local-storage-thermostats',
          JSON.stringify(filteredThermostats)
        );
        return thermostatsLatestUsage;
      } catch (error) {
        console.error('Error getting thermostats:', error);
        return null;
      }
    },

    async handleDataChange(isLoading = false) {
      this.isLoading = isLoading;
      try {
        this.thermostatsList = await this.getCachedThermostats();
        this.mapThermostatsList();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    mapThermostatsList() {
      this.thermostatsList.forEach((thermostat) => {
        this.selectedSiteControls.forEach((control) => {
          if (thermostat.controlId === control.id) {
            thermostat.controlInfo = Object.assign({}, control);
          }
        });
      });

      const filteredItems = this.thermostatsList.filter(
        (item) => item.controlInfo
      );

      this.setThermostatsList(filteredItems);
    },
  },
};
</script>
