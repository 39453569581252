<template>
  <svg
    width="201"
    height="116"
    viewBox="0 0 201 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M195.6 89.8301V103.48" stroke="black" stroke-width="0.5" />
    <path d="M192.26 108.56V106.59" stroke="black" stroke-width="0.5" />
    <path d="M200.09 108.56H192.26" stroke="black" stroke-width="0.5" />
    <path d="M192.26 106.59H156.36" stroke="black" stroke-width="0.5" />
    <path d="M141.71 106.59H114.56" stroke="black" stroke-width="0.5" />
    <path d="M105.58 106.59H102.59" stroke="black" stroke-width="0.5" />
    <path d="M96.6102 106.59H46.4902" stroke="black" stroke-width="0.5" />
    <path d="M21.9601 106.59H6.8501" stroke="black" stroke-width="0.5" />
    <path d="M195.75 103.48V107.81" stroke="black" stroke-width="0.5" />
    <path d="M195.6 103.48H195.75" stroke="black" stroke-width="0.5" />
    <path d="M195.75 105.35H157.61" stroke="black" stroke-width="0.5" />
    <path d="M140.46 105.35H114.56" stroke="black" stroke-width="0.5" />
    <path d="M105.58 105.35H45.23" stroke="black" stroke-width="0.5" />
    <path d="M20.7102 105.35H8.68018" stroke="black" stroke-width="0.5" />
    <path d="M5.36011 104.1H0.870117" stroke="black" stroke-width="0.5" />
    <path d="M0.870117 107.84V106.59" stroke="black" stroke-width="0.5" />
    <path d="M6.8501 107.84V106.59" stroke="black" stroke-width="0.5" />
    <path d="M0.870117 107.84H6.8501" stroke="black" stroke-width="0.5" />
    <path d="M0.870117 105.35V104.1" stroke="black" stroke-width="0.5" />
    <path d="M195.75 103.48H197.6" stroke="black" stroke-width="0.5" />
    <path d="M171.99 41.98H181.18" stroke="black" stroke-width="0.5" />
    <path d="M189.72 41.98H197.84" stroke="black" stroke-width="0.5" />
    <path d="M172.13 43.23H181.18" stroke="black" stroke-width="0.5" />
    <path d="M190.16 43.23H195.6" stroke="black" stroke-width="0.5" />
    <path d="M195.75 107.81V108.56" stroke="black" stroke-width="0.5" />
    <path d="M96.6099 107.84V106.59" stroke="black" stroke-width="0.5" />
    <path d="M102.59 107.84V106.59" stroke="black" stroke-width="0.5" />
    <path d="M96.6099 107.84H102.59" stroke="black" stroke-width="0.5" />
    <path d="M114.56 106.59L114.38 108.34" stroke="black" stroke-width="0.5" />
    <path d="M114.38 108.34L113.87 110.03" stroke="black" stroke-width="0.5" />
    <path d="M113.87 110.03L113.04 111.58" stroke="black" stroke-width="0.5" />
    <path d="M113.04 111.58L111.93 112.94" stroke="black" stroke-width="0.5" />
    <path d="M111.93 112.94L110.57 114.06" stroke="black" stroke-width="0.5" />
    <path d="M110.57 114.06L109.01 114.89" stroke="black" stroke-width="0.5" />
    <path d="M109.01 114.89L107.33 115.4" stroke="black" stroke-width="0.5" />
    <path d="M107.33 115.4L105.58 115.57" stroke="black" stroke-width="0.5" />
    <path d="M105.58 106.59H106.02" stroke="black" stroke-width="0.5" />
    <path d="M106.02 106.59V115.56" stroke="black" stroke-width="0.5" />
    <path d="M105.58 115.57V106.59" stroke="black" stroke-width="0.5" />
    <path d="M114.56 106.59V105.35" stroke="black" stroke-width="0.5" />
    <path d="M105.58 106.59V105.35" stroke="black" stroke-width="0.5" />
    <path d="M195.75 107.81V103.48" stroke="black" stroke-width="0.5" />
    <path d="M157.61 13.25V25.22" stroke="black" stroke-width="0.5" />
    <path d="M157.61 26.46V39.5" stroke="black" stroke-width="0.5" />
    <path d="M157.61 55.9199V82.3499" stroke="black" stroke-width="0.5" />
    <path d="M157.61 83.6001V85.1401" stroke="black" stroke-width="0.5" />
    <path d="M157.61 93.6699V105.35" stroke="black" stroke-width="0.5" />
    <path d="M156.36 4.83984V38.3398" stroke="black" stroke-width="0.5" />
    <path d="M156.36 39.5898V40.5499" stroke="black" stroke-width="0.5" />
    <path d="M156.36 54.6699V84.6999" stroke="black" stroke-width="0.5" />
    <path d="M156.36 93.6699V96.5199" stroke="black" stroke-width="0.5" />
    <path d="M156.36 97.77V106.59" stroke="black" stroke-width="0.5" />
    <path d="M157.61 39.5L158.74 39.69" stroke="black" stroke-width="0.5" />
    <path
      d="M167.15 41.1499L171.99 41.9799"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M156.36 40.5498L158.1 40.8498" stroke="black" stroke-width="0.5" />
    <path
      d="M166.94 42.3799L170.64 43.0099"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.13 57.9399H195.6" stroke="black" stroke-width="0.5" />
    <path d="M170.64 59.1899H195.6" stroke="black" stroke-width="0.5" />
    <path d="M172.13 43.23V57.94" stroke="black" stroke-width="0.5" />
    <path d="M170.64 43.0098V59.1898" stroke="black" stroke-width="0.5" />
    <path d="M170.64 54.6699H156.36" stroke="black" stroke-width="0.5" />
    <path d="M170.64 55.9199H157.61" stroke="black" stroke-width="0.5" />
    <path d="M160.6 12V26.46" stroke="black" stroke-width="0.5" />
    <path d="M160.6 26.46H157.61" stroke="black" stroke-width="0.5" />
    <path d="M160.6 25.2202H156.36" stroke="black" stroke-width="0.5" />
    <path d="M197.35 89.8301H193.62" stroke="black" stroke-width="0.5" />
    <path d="M185.08 89.8301H182.9" stroke="black" stroke-width="0.5" />
    <path d="M197.6 91.0698H194.06" stroke="black" stroke-width="0.5" />
    <path d="M185.08 91.0698H182.9" stroke="black" stroke-width="0.5" />
    <path d="M182.9 105.35V91.0698" stroke="black" stroke-width="0.5" />
    <path d="M181.66 105.35V83.6001" stroke="black" stroke-width="0.5" />
    <path d="M182.9 89.8301V82.3501" stroke="black" stroke-width="0.5" />
    <path d="M182.9 82.3501H157.61" stroke="black" stroke-width="0.5" />
    <path d="M181.66 83.6001H157.61" stroke="black" stroke-width="0.5" />
    <path
      d="M185.08 89.8301L185.25 88.0801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M185.25 88.0801L185.76 86.3901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M185.76 86.3898L186.59 84.8398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M186.59 84.84L187.71 83.48" stroke="black" stroke-width="0.5" />
    <path
      d="M187.71 83.4799L189.07 82.3599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M189.07 82.3598L190.62 81.5298"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M190.62 81.53L192.3 81.02" stroke="black" stroke-width="0.5" />
    <path d="M192.3 81.0201L194.06 80.8501" stroke="black" stroke-width="0.5" />
    <path d="M194.06 89.8301H193.62" stroke="black" stroke-width="0.5" />
    <path d="M193.62 89.8299V80.8599" stroke="black" stroke-width="0.5" />
    <path d="M194.06 80.8501V89.8301" stroke="black" stroke-width="0.5" />
    <path d="M194.06 89.8301V91.0701" stroke="black" stroke-width="0.5" />
    <path d="M194.55 89.8301V91.0701" stroke="black" stroke-width="0.5" />
    <path d="M185.08 89.8301V91.0701" stroke="black" stroke-width="0.5" />
    <path d="M184.58 89.8301V91.0701" stroke="black" stroke-width="0.5" />
    <path d="M157.61 93.67L159.36 93.5" stroke="black" stroke-width="0.5" />
    <path
      d="M159.36 93.5002L161.04 92.9902"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M161.04 92.9902L162.6 92.1602" stroke="black" stroke-width="0.5" />
    <path d="M162.6 92.1598L163.96 91.0498" stroke="black" stroke-width="0.5" />
    <path
      d="M163.96 91.0499L165.07 89.6899"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M165.07 89.6899L165.9 88.1299" stroke="black" stroke-width="0.5" />
    <path d="M165.9 88.1302L166.41 86.4502" stroke="black" stroke-width="0.5" />
    <path
      d="M166.41 86.4502L166.58 84.7002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M157.61 84.7002V85.1402" stroke="black" stroke-width="0.5" />
    <path d="M157.61 85.1401H166.57" stroke="black" stroke-width="0.5" />
    <path d="M166.58 84.7002H157.61" stroke="black" stroke-width="0.5" />
    <path d="M157.61 84.7002H156.36" stroke="black" stroke-width="0.5" />
    <path d="M157.61 84.2002H156.36" stroke="black" stroke-width="0.5" />
    <path d="M157.61 93.6699H156.36" stroke="black" stroke-width="0.5" />
    <path d="M157.61 94.1699H156.36" stroke="black" stroke-width="0.5" />
    <path d="M177.7 93.13V92.96" stroke="black" stroke-width="0.5" />
    <path d="M177.7 92.9598L177.69 92.7798" stroke="black" stroke-width="0.5" />
    <path
      d="M177.69 92.7799L177.68 92.6099"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.68 92.6099L177.67 92.4399"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M177.67 92.44L177.65 92.27" stroke="black" stroke-width="0.5" />
    <path
      d="M177.65 92.2699L177.63 92.0898"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M177.63 92.0899L177.6 91.9199" stroke="black" stroke-width="0.5" />
    <path d="M177.6 91.92L177.57 91.75" stroke="black" stroke-width="0.5" />
    <path
      d="M177.57 91.7501L177.54 91.5801"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M177.54 91.5798V91.5698" stroke="black" stroke-width="0.5" />
    <path d="M177.33 90.82L177.31 90.75" stroke="black" stroke-width="0.5" />
    <path
      d="M177.31 90.7498L177.26 90.5898"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M177.26 90.5902L177.2 90.4302" stroke="black" stroke-width="0.5" />
    <path d="M177.2 90.43L177.13 90.27" stroke="black" stroke-width="0.5" />
    <path
      d="M177.13 90.2699L177.06 90.1099"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.06 90.1102L176.99 89.9502"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.99 89.95L176.92 89.79" stroke="black" stroke-width="0.5" />
    <path
      d="M176.92 89.7901L176.84 89.6401"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.84 89.6402L176.76 89.4902"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.76 89.4901L176.7 89.3901" stroke="black" stroke-width="0.5" />
    <path
      d="M176.27 88.7301L176.19 88.6201"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.19 88.62L176.08 88.48" stroke="black" stroke-width="0.5" />
    <path
      d="M176.08 88.4801L175.98 88.3501"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.98 88.3502L175.86 88.2202"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.86 88.2198L175.75 88.0898"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M175.75 88.09L175.63 87.96" stroke="black" stroke-width="0.5" />
    <path
      d="M175.63 87.9599L175.51 87.8398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.51 87.8402L175.39 87.7202"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.39 87.7201L175.26 87.6001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.26 87.5998L175.23 87.5698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M174.62 87.0801L174.59 87.0601"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.59 87.06L174.45 86.96" stroke="black" stroke-width="0.5" />
    <path
      d="M174.45 86.9599L174.31 86.8599"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.31 86.86L174.16 86.77" stroke="black" stroke-width="0.5" />
    <path
      d="M174.16 86.7702L174.01 86.6802"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M174.01 86.6798L173.86 86.5898"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.86 86.5898L173.71 86.5098"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.71 86.5102L173.56 86.4302"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.56 86.4299L173.4 86.3599" stroke="black" stroke-width="0.5" />
    <path d="M173.4 86.3598L173.26 86.2998" stroke="black" stroke-width="0.5" />
    <path d="M172.53 86.01L172.43 85.98" stroke="black" stroke-width="0.5" />
    <path
      d="M172.43 85.9802L172.27 85.9302"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.27 85.9301L172.1 85.8901" stroke="black" stroke-width="0.5" />
    <path d="M172.1 85.8901L171.93 85.8501" stroke="black" stroke-width="0.5" />
    <path
      d="M171.93 85.8501L171.77 85.8101"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.77 85.8098L171.6 85.7798" stroke="black" stroke-width="0.5" />
    <path d="M171.6 85.78L171.43 85.75" stroke="black" stroke-width="0.5" />
    <path
      d="M171.43 85.7502L171.26 85.7202"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M171.26 85.7202L171.08 85.7002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.08 85.6999L171 85.6899" stroke="black" stroke-width="0.5" />
    <path d="M170.22 85.6499H170.05" stroke="black" stroke-width="0.5" />
    <path
      d="M170.05 85.6499L169.88 85.6599"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M169.88 85.6602L169.7 85.6702" stroke="black" stroke-width="0.5" />
    <path d="M169.7 85.6699L169.53 85.6799" stroke="black" stroke-width="0.5" />
    <path
      d="M169.53 85.6802L169.36 85.7002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M169.36 85.7002L169.19 85.7202"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M169.19 85.7202L169.02 85.7502"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M169.02 85.75L168.85 85.78" stroke="black" stroke-width="0.5" />
    <path
      d="M168.85 85.7798L168.68 85.8098"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M168.68 85.8101H168.67" stroke="black" stroke-width="0.5" />
    <path
      d="M167.91 86.0098L167.84 86.0398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M167.84 86.04L167.68 86.09" stroke="black" stroke-width="0.5" />
    <path
      d="M167.68 86.0898L167.52 86.1498"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M167.52 86.1499L167.36 86.2199"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M167.36 86.2202L167.2 86.2902" stroke="black" stroke-width="0.5" />
    <path d="M167.2 86.29L167.04 86.36" stroke="black" stroke-width="0.5" />
    <path
      d="M167.04 86.3599L166.89 86.4299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M166.89 86.4302L166.73 86.5102"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M166.73 86.5098L166.58 86.5898"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M166.58 86.5898L166.48 86.6498"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.83 87.0801L165.71 87.1601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.71 87.1602L165.58 87.2601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.58 87.2598L165.44 87.3698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.44 87.3701L165.31 87.4901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.31 87.4902L165.18 87.6002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.18 87.6001L165.06 87.7201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.06 87.7202L164.93 87.8402"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M164.93 87.8398L164.81 87.9599"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M164.81 87.96L164.69 88.09" stroke="black" stroke-width="0.5" />
    <path
      d="M164.69 88.0898L164.66 88.1198"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M164.17 88.73L164.15 88.76" stroke="black" stroke-width="0.5" />
    <path
      d="M164.15 88.7598L164.05 88.8998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M164.05 88.8999L163.96 89.0399"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M163.96 89.04L163.86 89.19" stroke="black" stroke-width="0.5" />
    <path
      d="M163.86 89.1899L163.77 89.3399"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.77 89.3398L163.69 89.4899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.69 89.4902L163.61 89.6402"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.61 89.6401L163.53 89.7901"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M163.53 89.79L163.45 89.95" stroke="black" stroke-width="0.5" />
    <path
      d="M163.45 89.9502L163.39 90.0902"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.11 90.8198L163.08 90.9198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.08 90.9199L163.03 91.0799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.03 91.0801L162.98 91.2501"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M162.98 91.25L162.94 91.42" stroke="black" stroke-width="0.5" />
    <path d="M162.94 91.4199L162.9 91.5799" stroke="black" stroke-width="0.5" />
    <path d="M162.9 91.5801L162.87 91.7501" stroke="black" stroke-width="0.5" />
    <path d="M162.87 91.75L162.84 91.92" stroke="black" stroke-width="0.5" />
    <path
      d="M162.84 91.9199L162.81 92.0899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M162.81 92.0898L162.79 92.2699"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M162.79 92.27L162.78 92.35" stroke="black" stroke-width="0.5" />
    <path d="M162.74 93.1299V93.2999" stroke="black" stroke-width="0.5" />
    <path
      d="M162.74 93.2998L162.75 93.4698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M162.75 93.4702L162.76 93.6502"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M162.76 93.6499L162.77 93.8199"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M162.77 93.8198L162.79 93.9898"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M162.79 93.9902L162.81 94.1602"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M162.81 94.1602L162.84 94.3302"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M162.84 94.3301L162.87 94.5001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M162.87 94.5L162.9 94.67" stroke="black" stroke-width="0.5" />
    <path d="M162.9 94.6699L162.91 94.6799" stroke="black" stroke-width="0.5" />
    <path
      d="M163.11 95.4399L163.13 95.4999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M163.13 95.5L163.19 95.67" stroke="black" stroke-width="0.5" />
    <path
      d="M163.19 95.6699L163.25 95.8299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.25 95.8301L163.31 95.9901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.31 95.9902L163.38 96.1502"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.38 96.1499L163.45 96.3099"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M163.45 96.3101L163.53 96.4601"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M163.53 96.46L163.61 96.6199" stroke="black" stroke-width="0.5" />
    <path
      d="M163.61 96.6201L163.69 96.7701"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M163.69 96.77L163.74 96.87" stroke="black" stroke-width="0.5" />
    <path d="M164.17 97.52L164.25 97.64" stroke="black" stroke-width="0.5" />
    <path
      d="M164.25 97.6401L164.36 97.7701"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M164.36 97.77L164.47 97.91" stroke="black" stroke-width="0.5" />
    <path
      d="M164.47 97.9102L164.58 98.0401"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M164.58 98.04L164.69 98.17" stroke="black" stroke-width="0.5" />
    <path
      d="M164.69 98.1699L164.81 98.2899"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M164.81 98.29L164.93 98.42" stroke="black" stroke-width="0.5" />
    <path
      d="M164.93 98.4199L165.06 98.5399"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M165.06 98.54L165.18 98.66" stroke="black" stroke-width="0.5" />
    <path
      d="M165.18 98.6602L165.22 98.6902"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.83 99.1802L165.85 99.2002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.85 99.2002L165.99 99.3002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.99 99.2998L166.14 99.3898"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M166.14 99.3901L166.28 99.4901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M166.28 99.4902L166.43 99.5802"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M166.43 99.5801L166.58 99.6601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M166.58 99.6602L166.73 99.7402"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M166.73 99.7402L166.89 99.8202"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M166.89 99.8198L167.04 99.8998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M167.04 99.8999L167.18 99.9599"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M167.91 100.24L168.01 100.27" stroke="black" stroke-width="0.5" />
    <path d="M168.01 100.27L168.17 100.32" stroke="black" stroke-width="0.5" />
    <path d="M168.17 100.32L168.34 100.37" stroke="black" stroke-width="0.5" />
    <path d="M168.34 100.37L168.51 100.41" stroke="black" stroke-width="0.5" />
    <path d="M168.51 100.41L168.68 100.45" stroke="black" stroke-width="0.5" />
    <path d="M168.68 100.45L168.85 100.48" stroke="black" stroke-width="0.5" />
    <path d="M168.85 100.48L169.02 100.51" stroke="black" stroke-width="0.5" />
    <path d="M169.02 100.51L169.19 100.54" stroke="black" stroke-width="0.5" />
    <path d="M169.19 100.54L169.36 100.56" stroke="black" stroke-width="0.5" />
    <path d="M169.36 100.56L169.44 100.57" stroke="black" stroke-width="0.5" />
    <path d="M170.22 100.61H170.39" stroke="black" stroke-width="0.5" />
    <path d="M170.39 100.61L170.57 100.6" stroke="black" stroke-width="0.5" />
    <path d="M170.57 100.6L170.74 100.59" stroke="black" stroke-width="0.5" />
    <path d="M170.74 100.59L170.91 100.58" stroke="black" stroke-width="0.5" />
    <path d="M170.91 100.58L171.08 100.56" stroke="black" stroke-width="0.5" />
    <path d="M171.08 100.56L171.26 100.54" stroke="black" stroke-width="0.5" />
    <path d="M171.26 100.54L171.43 100.51" stroke="black" stroke-width="0.5" />
    <path d="M171.43 100.51L171.6 100.48" stroke="black" stroke-width="0.5" />
    <path d="M171.6 100.48L171.77 100.45" stroke="black" stroke-width="0.5" />
    <path d="M171.77 100.45L171.78 100.44" stroke="black" stroke-width="0.5" />
    <path d="M172.53 100.24L172.6 100.22" stroke="black" stroke-width="0.5" />
    <path d="M172.6 100.22L172.76 100.16" stroke="black" stroke-width="0.5" />
    <path d="M172.76 100.16L172.92 100.1" stroke="black" stroke-width="0.5" />
    <path d="M172.92 100.1L173.08 100.04" stroke="black" stroke-width="0.5" />
    <path d="M173.08 100.04L173.24 99.9702" stroke="black" stroke-width="0.5" />
    <path d="M173.24 99.9699L173.4 99.8999" stroke="black" stroke-width="0.5" />
    <path d="M173.4 99.8998L173.56 99.8198" stroke="black" stroke-width="0.5" />
    <path
      d="M173.56 99.8202L173.71 99.7402"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.71 99.7402L173.86 99.6602"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.86 99.6599L173.96 99.6099"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M174.62 99.1801L174.73 99.1001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M174.73 99.1002L174.87 98.9902"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.87 98.9899L175 98.8799" stroke="black" stroke-width="0.5" />
    <path d="M175 98.88L175.13 98.77" stroke="black" stroke-width="0.5" />
    <path
      d="M175.13 98.7702L175.26 98.6602"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M175.26 98.66L175.39 98.54" stroke="black" stroke-width="0.5" />
    <path
      d="M175.39 98.5399L175.51 98.4199"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M175.51 98.42L175.63 98.29" stroke="black" stroke-width="0.5" />
    <path
      d="M175.63 98.2899L175.75 98.1699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.75 98.1699L175.78 98.1299"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.27 97.52L176.29 97.5" stroke="black" stroke-width="0.5" />
    <path
      d="M176.29 97.5001L176.39 97.3501"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.39 97.35L176.49 97.21" stroke="black" stroke-width="0.5" />
    <path
      d="M176.49 97.2098L176.58 97.0698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.58 97.0699L176.67 96.9199"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.67 96.92L176.76 96.77" stroke="black" stroke-width="0.5" />
    <path
      d="M176.76 96.7701L176.84 96.6201"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.84 96.6199L176.92 96.46" stroke="black" stroke-width="0.5" />
    <path
      d="M176.92 96.4601L176.99 96.3101"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.99 96.3099L177.05 96.1699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.33 95.4398L177.37 95.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.37 95.3399L177.42 95.1699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.42 95.1698L177.46 95.0098"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M177.46 95.0098L177.5 94.8398" stroke="black" stroke-width="0.5" />
    <path d="M177.5 94.8399L177.54 94.6699" stroke="black" stroke-width="0.5" />
    <path d="M177.54 94.67L177.57 94.5" stroke="black" stroke-width="0.5" />
    <path d="M177.57 94.5001L177.6 94.3301" stroke="black" stroke-width="0.5" />
    <path d="M177.6 94.3302L177.63 94.1602" stroke="black" stroke-width="0.5" />
    <path
      d="M177.63 94.1602L177.65 93.9902"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.65 93.9902L177.66 93.9102"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M167.01 91.1901V90.1201" stroke="black" stroke-width="0.5" />
    <path d="M167.01 89.3398V87.7798" stroke="black" stroke-width="0.5" />
    <path d="M167.01 86.9999V85.4399" stroke="black" stroke-width="0.5" />
    <path d="M167.01 84.6601V83.6001" stroke="black" stroke-width="0.5" />
    <path d="M167.01 83.6001H168.82" stroke="black" stroke-width="0.5" />
    <path d="M169.6 83.6001H171.15" stroke="black" stroke-width="0.5" />
    <path d="M171.93 83.6001H173.49" stroke="black" stroke-width="0.5" />
    <path d="M174.27 83.6001H175.83" stroke="black" stroke-width="0.5" />
    <path d="M176.61 83.6001H178.41" stroke="black" stroke-width="0.5" />
    <path d="M178.41 83.6001V84.6601" stroke="black" stroke-width="0.5" />
    <path d="M178.41 85.4399V86.9999" stroke="black" stroke-width="0.5" />
    <path d="M178.41 87.7798V89.3398" stroke="black" stroke-width="0.5" />
    <path d="M178.41 90.1201V91.1901" stroke="black" stroke-width="0.5" />
    <path d="M167.01 91.1899H168.82" stroke="black" stroke-width="0.5" />
    <path d="M169.6 91.1899H171.15" stroke="black" stroke-width="0.5" />
    <path d="M171.93 91.1899H173.49" stroke="black" stroke-width="0.5" />
    <path d="M174.27 91.1899H175.83" stroke="black" stroke-width="0.5" />
    <path d="M176.61 91.1899H178.41" stroke="black" stroke-width="0.5" />
    <path d="M167.01 89.8101H168.82" stroke="black" stroke-width="0.5" />
    <path d="M169.6 89.8101H171.15" stroke="black" stroke-width="0.5" />
    <path d="M171.93 89.8101H173.49" stroke="black" stroke-width="0.5" />
    <path d="M174.27 89.8101H175.83" stroke="black" stroke-width="0.5" />
    <path d="M176.61 89.8101H178.41" stroke="black" stroke-width="0.5" />
    <path d="M167.01 91.1899V92.0299" stroke="black" stroke-width="0.5" />
    <path d="M167.01 92.8101V94.3701" stroke="black" stroke-width="0.5" />
    <path d="M167.01 95.1499V96.7099" stroke="black" stroke-width="0.5" />
    <path d="M167.01 97.4902V99.0502" stroke="black" stroke-width="0.5" />
    <path d="M167.01 99.8198V101.38" stroke="black" stroke-width="0.5" />
    <path d="M167.01 102.16V103.72" stroke="black" stroke-width="0.5" />
    <path d="M167.01 104.5V105.35" stroke="black" stroke-width="0.5" />
    <path d="M181.18 41.98L181.35 40.23" stroke="black" stroke-width="0.5" />
    <path
      d="M181.35 40.2298L181.86 38.5498"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M181.86 38.55L182.69 37" stroke="black" stroke-width="0.5" />
    <path
      d="M182.69 37.0001L183.81 35.6401"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M183.81 35.64L185.17 34.52" stroke="black" stroke-width="0.5" />
    <path
      d="M185.17 34.5199L186.72 33.6899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M186.72 33.6902L188.41 33.1802"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M188.41 33.1798L190.16 33.0098"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M190.16 41.98H189.72" stroke="black" stroke-width="0.5" />
    <path d="M189.72 41.98V33.02" stroke="black" stroke-width="0.5" />
    <path d="M190.16 33.0098V41.9798" stroke="black" stroke-width="0.5" />
    <path d="M190.16 41.98V43.23" stroke="black" stroke-width="0.5" />
    <path d="M190.66 41.98V43.23" stroke="black" stroke-width="0.5" />
    <path d="M181.18 41.98V43.23" stroke="black" stroke-width="0.5" />
    <path d="M180.68 41.98V43.23" stroke="black" stroke-width="0.5" />
    <path
      d="M167.15 41.1501L167.28 39.3901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M167.28 39.3899L167.06 37.6499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M167.06 37.65L166.51 35.98" stroke="black" stroke-width="0.5" />
    <path
      d="M166.51 35.9802L165.64 34.4502"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M165.64 34.4499L164.49 33.1099"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M164.49 33.1098L163.1 32.0298" stroke="black" stroke-width="0.5" />
    <path d="M163.1 32.0302L161.53 31.2402" stroke="black" stroke-width="0.5" />
    <path
      d="M161.53 31.2398L159.83 30.7798"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M158.31 39.6201L158.74 39.6901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M158.74 39.6899L160.26 30.8599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M159.83 30.7798L158.31 39.6198"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M158.1 40.8501L158.31 39.6201" stroke="black" stroke-width="0.5" />
    <path d="M157.6 40.76L157.82 39.54" stroke="black" stroke-width="0.5" />
    <path d="M167.43 42.46L167.64 41.23" stroke="black" stroke-width="0.5" />
    <path
      d="M166.94 42.3799L167.15 41.1499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M128.55 2.83984V38.3398" stroke="black" stroke-width="0.5" />
    <path d="M127.3 2.83984V38.3398" stroke="black" stroke-width="0.5" />
    <path d="M156.36 38.3398H148.85" stroke="black" stroke-width="0.5" />
    <path d="M129.81 38.3398H128.55" stroke="black" stroke-width="0.5" />
    <path d="M156.36 39.5898H149.9" stroke="black" stroke-width="0.5" />
    <path d="M148.63 39.5898H138.7" stroke="black" stroke-width="0.5" />
    <path d="M129.59 39.5898H126.25" stroke="black" stroke-width="0.5" />
    <path d="M138.65 39.8701L148.3 41.5301" stroke="black" stroke-width="0.5" />
    <path
      d="M138.44 41.1001L149.31 42.9801"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M149.52 41.75L155.96 42.86" stroke="black" stroke-width="0.5" />
    <path
      d="M138.65 39.8701L138.78 38.1201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M138.78 38.1201L138.56 36.3701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M138.56 36.3702L138.01 34.7002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M138.01 34.6999L137.14 33.1699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.14 33.1698L135.99 31.8398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.99 31.8398L134.6 30.7598" stroke="black" stroke-width="0.5" />
    <path d="M134.6 30.7602L133.03 29.9702" stroke="black" stroke-width="0.5" />
    <path d="M133.03 29.97L131.34 29.5" stroke="black" stroke-width="0.5" />
    <path
      d="M129.81 38.3398L130.24 38.4198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M130.24 38.4201L131.76 29.5801"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M131.34 29.5L129.81 38.34" stroke="black" stroke-width="0.5" />
    <path d="M138.93 41.1799L139.15 39.96" stroke="black" stroke-width="0.5" />
    <path
      d="M138.44 41.1001L138.65 39.8701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M155.96 42.8598L156.36 40.5498"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M149.31 42.9798L149.9 39.5898" stroke="black" stroke-width="0.5" />
    <path d="M148.3 41.5298L148.85 38.3398" stroke="black" stroke-width="0.5" />
    <path
      d="M129.59 39.5898L129.81 38.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M129.09 39.5898L129.3 38.3398" stroke="black" stroke-width="0.5" />
    <path d="M98.27 4.83984V38.3398" stroke="black" stroke-width="0.5" />
    <path d="M99.52 4.83984V38.3398" stroke="black" stroke-width="0.5" />
    <path d="M99.4902 39.5898V40.5499" stroke="black" stroke-width="0.5" />
    <path d="M99.52 38.3398H107" stroke="black" stroke-width="0.5" />
    <path d="M126.04 38.3398H127.3" stroke="black" stroke-width="0.5" />
    <path d="M97.1899 39.5898H105.95" stroke="black" stroke-width="0.5" />
    <path
      d="M117.19 39.8701L107.55 41.5301"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M117.41 41.1001L106.54 42.9801"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M106.32 41.75L99.8799 42.86" stroke="black" stroke-width="0.5" />
    <path
      d="M117.19 39.8701L117.07 38.1201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M117.07 38.1201L117.28 36.3701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M117.28 36.3702L117.84 34.7002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M117.84 34.6999L118.71 33.1699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M118.71 33.1698L119.86 31.8398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M119.86 31.8398L121.24 30.7598"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M121.24 30.7602L122.82 29.9702"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M122.82 29.97L124.51 29.5" stroke="black" stroke-width="0.5" />
    <path
      d="M126.04 38.3398L125.61 38.4198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M125.61 38.4201L124.08 29.5801"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M124.51 29.5L126.04 38.34" stroke="black" stroke-width="0.5" />
    <path d="M116.92 41.1799L116.7 39.96" stroke="black" stroke-width="0.5" />
    <path
      d="M117.41 41.1001L117.19 39.8701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M99.8802 42.8598L99.4902 40.5498"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M106.54 42.9798L105.95 39.5898"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M107.55 41.5298L107 38.3398" stroke="black" stroke-width="0.5" />
    <path
      d="M126.25 39.5898L126.04 38.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M126.76 39.5898L126.55 38.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M96.98 38.3398H98.27" stroke="black" stroke-width="0.5" />
    <path d="M70.4302 39.5898H76.8902" stroke="black" stroke-width="0.5" />
    <path
      d="M88.1302 39.8701L78.4902 41.5301"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M88.3497 41.1001L77.4697 42.9801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M88.1298 39.8701L88.0098 38.1201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M88.0098 38.1201L88.2197 36.3701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M88.2197 36.3702L88.7798 34.7002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M88.7803 34.6999L89.6403 33.1699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M89.6401 33.1698L90.7901 31.8398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M90.79 31.8398L92.1801 30.7598"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M92.1802 30.7602L93.7602 29.9702"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M93.7598 29.97L95.4498 29.5" stroke="black" stroke-width="0.5" />
    <path
      d="M96.9798 38.3398L96.5498 38.4198"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M96.55 38.4201L95.02 29.5801" stroke="black" stroke-width="0.5" />
    <path d="M95.4502 29.5L96.9802 38.34" stroke="black" stroke-width="0.5" />
    <path
      d="M87.8501 41.1799L87.6401 39.96"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M88.3499 41.1001L88.1299 39.8701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M77.4701 42.9798L76.8901 39.5898"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M78.4899 41.5298L77.9399 38.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M97.19 39.5898L96.98 38.3398" stroke="black" stroke-width="0.5" />
    <path d="M97.7 39.5898L97.48 38.3398" stroke="black" stroke-width="0.5" />
    <path d="M69.6001 4.83984V38.3398" stroke="black" stroke-width="0.5" />
    <path d="M70.8501 4.83984V38.3398" stroke="black" stroke-width="0.5" />
    <path d="M71.0098 39.5898V40.5499" stroke="black" stroke-width="0.5" />
    <path d="M70.8501 38.3398H78.5302" stroke="black" stroke-width="0.5" />
    <path d="M68.5698 39.5898H77.4798" stroke="black" stroke-width="0.5" />
    <path d="M77.8502 41.75L71.4102 42.86" stroke="black" stroke-width="0.5" />
    <path
      d="M71.4097 42.8598L71.0098 40.5498"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M78.06 42.9798L77.48 39.5898" stroke="black" stroke-width="0.5" />
    <path
      d="M79.0803 41.5298L78.5303 38.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M68.5 38.3398H69.6" stroke="black" stroke-width="0.5" />
    <path d="M41.4302 4.83984V39.8398" stroke="black" stroke-width="0.5" />
    <path d="M42.6699 41.1898V4.83984" stroke="black" stroke-width="0.5" />
    <path d="M42.6699 42.4399V45.9299" stroke="black" stroke-width="0.5" />
    <path
      d="M42.6699 41.1901L59.58 39.3301"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M68.0698 38.3898L68.4998 38.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M42.6699 42.4398L59.7199 40.5698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M59.5801 39.3298L59.5601 37.5698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M59.5601 37.5699L59.8801 35.8398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M59.8799 35.8402L60.5399 34.2002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M60.54 34.2L61.5001 32.73" stroke="black" stroke-width="0.5" />
    <path d="M61.5 32.7302L62.73 31.4702" stroke="black" stroke-width="0.5" />
    <path d="M62.73 31.47L64.18 30.48" stroke="black" stroke-width="0.5" />
    <path d="M64.1802 30.48L65.8002 29.79" stroke="black" stroke-width="0.5" />
    <path
      d="M65.7998 29.7899L67.5198 29.4199"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M68.4998 38.3398L68.0698 38.3898"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M68.0698 38.39L67.0898 29.48" stroke="black" stroke-width="0.5" />
    <path d="M67.52 29.4199L68.5 38.3399" stroke="black" stroke-width="0.5" />
    <path d="M68.5 38.3398L68.64 39.5798" stroke="black" stroke-width="0.5" />
    <path d="M69 38.3398L69.14 39.5898" stroke="black" stroke-width="0.5" />
    <path
      d="M59.0801 39.3799L59.22 40.6199"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M59.5801 39.3301L59.72 40.5701"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.71 106.59V97.77" stroke="black" stroke-width="0.5" />
    <path d="M141.71 96.5199V56.8799" stroke="black" stroke-width="0.5" />
    <path d="M140.46 105.35V89.9502" stroke="black" stroke-width="0.5" />
    <path d="M141.71 56.8799H133.12" stroke="black" stroke-width="0.5" />
    <path d="M124.15 56.8799H84.1099" stroke="black" stroke-width="0.5" />
    <path d="M141.71 58.1299H132.69" stroke="black" stroke-width="0.5" />
    <path d="M124.15 58.1299H84.1099" stroke="black" stroke-width="0.5" />
    <path d="M85.3599 58.6201H84.1099" stroke="black" stroke-width="0.5" />
    <path d="M140.46 89.9502H134.48" stroke="black" stroke-width="0.5" />
    <path d="M141.71 88.7002H135.73" stroke="black" stroke-width="0.5" />
    <path d="M135.73 88.6999V58.1299" stroke="black" stroke-width="0.5" />
    <path d="M134.48 105.35V58.1299" stroke="black" stroke-width="0.5" />
    <path d="M103.37 56.8799V79.6599" stroke="black" stroke-width="0.5" />
    <path d="M102.88 56.8799V58.4199" stroke="black" stroke-width="0.5" />
    <path d="M102.88 59.1099V60.4799" stroke="black" stroke-width="0.5" />
    <path d="M102.88 61.1602V62.5401" stroke="black" stroke-width="0.5" />
    <path d="M102.88 63.2202V64.5902" stroke="black" stroke-width="0.5" />
    <path d="M102.88 65.2798V66.6498" stroke="black" stroke-width="0.5" />
    <path d="M102.88 67.3398V68.7099" stroke="black" stroke-width="0.5" />
    <path d="M102.88 69.3901V70.7601" stroke="black" stroke-width="0.5" />
    <path d="M102.88 71.4502V72.8202" stroke="black" stroke-width="0.5" />
    <path d="M102.88 73.5098V74.8798" stroke="black" stroke-width="0.5" />
    <path d="M102.88 75.5601V76.9301" stroke="black" stroke-width="0.5" />
    <path d="M102.88 77.6201V79.1601" stroke="black" stroke-width="0.5" />
    <path d="M103.37 80.9102V102.85" stroke="black" stroke-width="0.5" />
    <path d="M102.88 81.4102V82.2601" stroke="black" stroke-width="0.5" />
    <path d="M102.88 82.9399V84.3099" stroke="black" stroke-width="0.5" />
    <path d="M102.88 85V86.37" stroke="black" stroke-width="0.5" />
    <path d="M102.88 87.0601V88.4301" stroke="black" stroke-width="0.5" />
    <path d="M102.88 89.1099V89.9599" stroke="black" stroke-width="0.5" />
    <path d="M103.37 104.1V105.35" stroke="black" stroke-width="0.5" />
    <path d="M102.88 56.8799V58.1299" stroke="black" stroke-width="0.5" />
    <path d="M104.62 56.8799V105.35" stroke="black" stroke-width="0.5" />
    <path d="M84.1099 56.8799V58.6199" stroke="black" stroke-width="0.5" />
    <path d="M85.3599 56.8799V58.1299" stroke="black" stroke-width="0.5" />
    <path
      d="M124.15 58.1299L124.32 59.8799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M124.32 59.8799L124.83 61.5599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M124.83 61.5601L125.66 63.1101"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M125.66 63.1099L126.78 64.4699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M126.78 64.4702L128.14 65.5902"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M128.14 65.5898L129.69 66.4198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M129.69 66.4199L131.37 66.9299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M131.37 66.9302L133.12 67.1002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M133.12 58.1299H132.69" stroke="black" stroke-width="0.5" />
    <path d="M132.69 58.1299V67.0899" stroke="black" stroke-width="0.5" />
    <path d="M133.12 67.0999V58.1299" stroke="black" stroke-width="0.5" />
    <path d="M133.12 58.1299V56.8799" stroke="black" stroke-width="0.5" />
    <path d="M124.15 58.1299V56.8799" stroke="black" stroke-width="0.5" />
    <path d="M123.65 58.1299V56.8799" stroke="black" stroke-width="0.5" />
    <path d="M102.88 57.5H85.3599" stroke="black" stroke-width="0.5" />
    <path d="M103.37 79.6602H79.2803" stroke="black" stroke-width="0.5" />
    <path d="M102.88 79.1602H102" stroke="black" stroke-width="0.5" />
    <path d="M101.31 79.1602H99.9399" stroke="black" stroke-width="0.5" />
    <path d="M99.2601 79.1602H97.8901" stroke="black" stroke-width="0.5" />
    <path d="M97.2001 79.1602H95.8301" stroke="black" stroke-width="0.5" />
    <path d="M95.14 79.1602H93.77" stroke="black" stroke-width="0.5" />
    <path d="M93.09 79.1602H91.71" stroke="black" stroke-width="0.5" />
    <path d="M91.0302 79.1602H89.6602" stroke="black" stroke-width="0.5" />
    <path d="M88.9701 79.1602H87.6001" stroke="black" stroke-width="0.5" />
    <path d="M86.91 79.1602H85.54" stroke="black" stroke-width="0.5" />
    <path d="M84.86 79.1602H83.98" stroke="black" stroke-width="0.5" />
    <path d="M70.3099 79.6602H49.4199" stroke="black" stroke-width="0.5" />
    <path d="M103.37 80.9102H79.2803" stroke="black" stroke-width="0.5" />
    <path d="M102.88 81.4102H101.96" stroke="black" stroke-width="0.5" />
    <path d="M101.27 81.4102H99.8999" stroke="black" stroke-width="0.5" />
    <path d="M99.2098 81.4102H97.8398" stroke="black" stroke-width="0.5" />
    <path d="M97.1602 81.4102H95.7803" stroke="black" stroke-width="0.5" />
    <path d="M95.1 81.4102H93.73" stroke="black" stroke-width="0.5" />
    <path d="M93.0399 81.4102H91.6699" stroke="black" stroke-width="0.5" />
    <path d="M90.9899 81.4102H89.6099" stroke="black" stroke-width="0.5" />
    <path d="M88.9301 81.4102H87.5601" stroke="black" stroke-width="0.5" />
    <path d="M86.87 81.4102H85.5" stroke="black" stroke-width="0.5" />
    <path d="M84.8099 81.4102H83.4399" stroke="black" stroke-width="0.5" />
    <path d="M82.7601 81.4102H81.3901" stroke="black" stroke-width="0.5" />
    <path d="M80.7003 81.4102H79.7803" stroke="black" stroke-width="0.5" />
    <path d="M70.7399 80.9102H69.1299" stroke="black" stroke-width="0.5" />
    <path
      d="M52.2802 65.48C50.6261 65.568 49.0481 66.2034 47.7945 67.2862C46.541 68.3691 45.683 69.8379 45.3554 71.4617C45.0279 73.0855 45.2496 74.7721 45.9854 76.2562C46.7212 77.7403 47.9295 78.9377 49.4202 79.66"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M52.2803 65.48H69.1503" stroke="black" stroke-width="0.5" />
    <path d="M69.1499 65.48V59.29" stroke="black" stroke-width="0.5" />
    <path d="M69.1501 59.29H53.8901" stroke="black" stroke-width="0.5" />
    <path d="M53.8901 59.29V60.53" stroke="black" stroke-width="0.5" />
    <path d="M53.89 60.5298H50.52" stroke="black" stroke-width="0.5" />
    <path d="M53.8902 59.29H50.4102" stroke="black" stroke-width="0.5" />
    <path d="M46.09 60.8799L43.75 56.8599" stroke="black" stroke-width="0.5" />
    <path d="M39.46 49.48L38.73 48.23" stroke="black" stroke-width="0.5" />
    <path
      d="M37.6499 48.8501L38.1599 49.7301"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M42.6699 57.4902L45.89 63.0302"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M50.5202 60.5298C48.7646 60.8525 47.1078 61.5782 45.6802 62.6498"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M50.4098 59.29C48.8854 59.5543 47.4218 60.093 46.0898 60.8801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M41.4297 39.8398L33.7598 44.2898"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M42.67 45.9302L38.73 48.2302" stroke="black" stroke-width="0.5" />
    <path
      d="M37.6498 48.8501L36.7197 49.3901"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M33.7598 44.29L36.7197 49.39" stroke="black" stroke-width="0.5" />
    <path
      d="M44.2798 60.25C42.5136 61.4536 41.0213 63.0164 39.9003 64.8363C38.7794 66.6561 38.0551 68.692 37.7748 70.8109C37.4945 72.9298 37.6644 75.084 38.2736 77.1327C38.8827 79.1814 39.9173 81.0785 41.3098 82.7"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M45.8899 63.0298C44.045 64.3412 42.6094 66.1484 41.7493 68.2421C40.8892 70.3357 40.6398 72.6302 41.0299 74.8598"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M37.8701 75.4099L41.0302 74.8599"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M43.75 56.8601L45.18 55.8301" stroke="black" stroke-width="0.5" />
    <path
      d="M45.1802 55.8298L46.3802 54.5498"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M46.3799 54.5498L47.2999 53.0498"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M47.2998 53.0499L47.9198 51.3999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M47.9199 51.4001L48.2 49.6602" stroke="black" stroke-width="0.5" />
    <path
      d="M48.2001 49.6602L48.1401 47.9102"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M48.14 47.9099L47.73 46.1899" stroke="black" stroke-width="0.5" />
    <path d="M47.73 46.1898L47 44.5898" stroke="black" stroke-width="0.5" />
    <path
      d="M39.2402 49.1001L39.4603 49.4801"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M39.46 49.48L47.21 44.98" stroke="black" stroke-width="0.5" />
    <path
      d="M47.0002 44.5898L39.2402 49.0999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M39.2402 49.1001L38.1602 49.7301"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M38.9902 48.6699L37.9102 49.2999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M43.9999 57.2998L42.9199 57.9198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M43.7499 56.8599L42.6699 57.4899"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M38.1699 85.8101V98.3601" stroke="black" stroke-width="0.5" />
    <path d="M39.4199 86.3301V97.8401" stroke="black" stroke-width="0.5" />
    <path
      d="M38.1699 85.8101L45.4899 78.5601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M39.4199 86.3298L45.4899 80.3198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M52.8102 85.8101L45.4902 78.5601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M53.0502 87.7998L45.4902 80.3198"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M69.1299 80.9102V87.8002" stroke="black" stroke-width="0.5" />
    <path d="M67.8799 79.6602V85.8102" stroke="black" stroke-width="0.5" />
    <path d="M67.8799 86.8101V87.8001" stroke="black" stroke-width="0.5" />
    <path d="M67.3799 86.8101V87.8001" stroke="black" stroke-width="0.5" />
    <path d="M52.8101 85.8101H67.8801" stroke="black" stroke-width="0.5" />
    <path d="M53.0498 87.7998H67.3798" stroke="black" stroke-width="0.5" />
    <path d="M67.8799 87.7998H69.1299" stroke="black" stroke-width="0.5" />
    <path d="M67.3799 87.3101H67.8799" stroke="black" stroke-width="0.5" />
    <path d="M67.3799 86.8101H67.8799" stroke="black" stroke-width="0.5" />
    <path
      d="M79.2799 80.9102L79.1099 82.6602"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M79.1101 82.6602L78.6001 84.3401"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M78.6 84.3398L77.77 85.8898" stroke="black" stroke-width="0.5" />
    <path
      d="M77.7699 85.8901L76.6499 87.2501"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M76.6501 87.25L75.29 88.37" stroke="black" stroke-width="0.5" />
    <path
      d="M75.2902 88.3701L73.7402 89.2001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M73.7401 89.2002L72.0601 89.7102"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M72.0601 89.71L70.3101 89.88" stroke="black" stroke-width="0.5" />
    <path d="M70.3101 80.9102H70.7401" stroke="black" stroke-width="0.5" />
    <path d="M70.7402 80.9102V89.8701" stroke="black" stroke-width="0.5" />
    <path d="M70.3101 89.8802V80.9102" stroke="black" stroke-width="0.5" />
    <path d="M70.3101 80.9102V79.6602" stroke="black" stroke-width="0.5" />
    <path d="M69.8101 80.9102V79.6602" stroke="black" stroke-width="0.5" />
    <path d="M79.2803 80.9102V79.6602" stroke="black" stroke-width="0.5" />
    <path d="M79.7803 81.4102V79.6602" stroke="black" stroke-width="0.5" />
    <path d="M101.09 104.1H103.37" stroke="black" stroke-width="0.5" />
    <path d="M101.09 104.1H90.9199" stroke="black" stroke-width="0.5" />
    <path d="M103.37 102.85H90.9199" stroke="black" stroke-width="0.5" />
    <path d="M90.9199 104.1V105.35" stroke="black" stroke-width="0.5" />
    <path d="M90.9199 102.85H89.6699" stroke="black" stroke-width="0.5" />
    <path d="M89.6699 102.85V103.85" stroke="black" stroke-width="0.5" />
    <path d="M89.6699 103.85H67.3799" stroke="black" stroke-width="0.5" />
    <path d="M66.8802 103.85H45.4902" stroke="black" stroke-width="0.5" />
    <path
      d="M38.1699 98.3599L46.4899 106.59"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M39.4199 97.8398L45.4899 103.85"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M67.3799 95.7799L65.6299 95.6099"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M65.6302 95.6101L63.9502 95.1001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M63.9501 95.1L62.3901 94.27" stroke="black" stroke-width="0.5" />
    <path
      d="M62.3903 94.2699L61.0303 93.1499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M61.03 93.15L59.9199 91.79" stroke="black" stroke-width="0.5" />
    <path
      d="M59.9198 91.7902L59.0898 90.2402"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M59.0901 90.2401L58.5801 88.5601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M58.5802 88.5601L58.4102 86.8101"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M67.3799 86.8101V87.2401" stroke="black" stroke-width="0.5" />
    <path d="M67.3799 87.2402H58.4199" stroke="black" stroke-width="0.5" />
    <path d="M58.4102 86.8101H67.3802" stroke="black" stroke-width="0.5" />
    <path
      d="M67.3799 95.7798L69.1299 95.9498"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M69.1299 95.9502L70.8199 96.4702"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M70.8198 96.4702L72.3698 97.2902"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M72.3701 97.29L73.7301 98.41" stroke="black" stroke-width="0.5" />
    <path d="M73.73 98.4102L74.84 99.7702" stroke="black" stroke-width="0.5" />
    <path d="M74.8398 99.77L75.6698 101.32" stroke="black" stroke-width="0.5" />
    <path
      d="M75.6699 101.32L76.1799 103.01"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M76.1802 103.01L76.3602 104.76"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M67.3799 104.76V104.32" stroke="black" stroke-width="0.5" />
    <path d="M67.3799 104.32H76.3499" stroke="black" stroke-width="0.5" />
    <path d="M76.3599 104.76H67.3799" stroke="black" stroke-width="0.5" />
    <path d="M67.3799 105.35V103.85" stroke="black" stroke-width="0.5" />
    <path d="M66.8799 105.35V103.85" stroke="black" stroke-width="0.5" />
    <path d="M85.3599 58.1299V68.2599" stroke="black" stroke-width="0.5" />
    <path d="M85.3599 68.2598H84.8599" stroke="black" stroke-width="0.5" />
    <path d="M84.8599 72.2601V58.6201" stroke="black" stroke-width="0.5" />
    <path d="M84.3599 72.2599V60.3799" stroke="black" stroke-width="0.5" />
    <path d="M84.3599 60.3799H84.8599" stroke="black" stroke-width="0.5" />
    <path d="M84.3599 72.2598H84.8599" stroke="black" stroke-width="0.5" />
    <path d="M4.3999 7.83008V104.1" stroke="black" stroke-width="0.5" />
    <path d="M3.3999 7.83008V41.9901" stroke="black" stroke-width="0.5" />
    <path d="M3.3999 43.23V67.95" stroke="black" stroke-width="0.5" />
    <path d="M3.3999 69.2002V104.1" stroke="black" stroke-width="0.5" />
    <path d="M11.2598 7.82985V4.83984" stroke="black" stroke-width="0.5" />
    <path d="M11.2598 4.83984H13.9998" stroke="black" stroke-width="0.5" />
    <path d="M14 4.83984V2.83984" stroke="black" stroke-width="0.5" />
    <path d="M41.4302 4.83984H61.8702" stroke="black" stroke-width="0.5" />
    <path d="M89.29 2.83984V4.83984" stroke="black" stroke-width="0.5" />
    <path d="M137.16 4.83984H157.61" stroke="black" stroke-width="0.5" />
    <path d="M185.03 4.83984H195.6" stroke="black" stroke-width="0.5" />
    <path d="M197.6 41.98H195.6" stroke="black" stroke-width="0.5" />
    <path d="M197.6 89.8301H195.6" stroke="black" stroke-width="0.5" />
    <path d="M195.6 89.8301V103.48" stroke="black" stroke-width="0.5" />
    <path d="M200.09 108.56H195.75" stroke="black" stroke-width="0.5" />
    <path d="M3 8H6H9" stroke="black" stroke-width="0.5" />
    <path d="M3 6H10" stroke="black" stroke-width="0.5" />
    <path d="M8.68018 7.83008H11.2602" stroke="black" stroke-width="0.5" />
    <path d="M41.4302 2.83984V4.83984" stroke="black" stroke-width="0.5" />
    <path d="M9.75977 6.07985V2.83984" stroke="black" stroke-width="0.5" />
    <path d="M89.29 4.83984H109.74" stroke="black" stroke-width="0.5" />
    <path d="M61.8701 4.83984V2.83984" stroke="black" stroke-width="0.5" />
    <path d="M109.74 4.83984V2.83984" stroke="black" stroke-width="0.5" />
    <path d="M137.16 2.83984V4.83984" stroke="black" stroke-width="0.5" />
    <path d="M185.03 2.83984V4.83984" stroke="black" stroke-width="0.5" />
    <path d="M89.29 0.350098H109.74" stroke="black" stroke-width="0.5" />
    <path d="M185.03 0.350098H200.09" stroke="black" stroke-width="0.5" />
    <path d="M41.4302 0.350098H61.8702" stroke="black" stroke-width="0.5" />
    <path d="M195.6 4.83984V14.5298" stroke="black" stroke-width="0.5" />
    <path d="M200.09 0.350098V14.5601" stroke="black" stroke-width="0.5" />
    <path d="M200.09 89.8301V108.56" stroke="black" stroke-width="0.5" />
    <path d="M195.6 14.5601H197.6" stroke="black" stroke-width="0.5" />
    <path d="M195.6 62.3999H197.6" stroke="black" stroke-width="0.5" />
    <path d="M195.6 41.96V62.3999" stroke="black" stroke-width="0.5" />
    <path d="M195.75 103.48V107.81" stroke="black" stroke-width="0.5" />
    <path d="M195.6 103.48H195.75" stroke="black" stroke-width="0.5" />
    <path d="M195.75 103.48H197.6" stroke="black" stroke-width="0.5" />
    <path d="M197.6 89.8301H200.09" stroke="black" stroke-width="0.5" />
    <path d="M197.6 62.3999H200.09" stroke="black" stroke-width="0.5" />
    <path d="M197.6 41.98H200.09" stroke="black" stroke-width="0.5" />
    <path d="M185.03 2.84009V0.350098" stroke="black" stroke-width="0.5" />
    <path d="M157.61 2.84009V0.350098" stroke="black" stroke-width="0.5" />
    <path d="M137.16 2.84009V0.350098" stroke="black" stroke-width="0.5" />
    <path d="M109.74 2.84009V0.350098" stroke="black" stroke-width="0.5" />
    <path d="M89.29 2.84009V0.350098" stroke="black" stroke-width="0.5" />
    <path d="M61.8701 2.84009V0.350098" stroke="black" stroke-width="0.5" />
    <path d="M41.4302 2.84009V0.350098" stroke="black" stroke-width="0.5" />
    <path d="M14 2.84009V0.350098" stroke="black" stroke-width="0.5" />
    <path d="M13.9998 2.83984H9.75977" stroke="black" stroke-width="0.5" />
    <path d="M89.29 2.83984H109.74" stroke="black" stroke-width="0.5" />
    <path d="M137.16 2.83984H157.61" stroke="black" stroke-width="0.5" />
    <path d="M185.03 2.83984H197.6" stroke="black" stroke-width="0.5" />
    <path d="M197.6 14.5598V2.83984" stroke="black" stroke-width="0.5" />
    <path d="M197.6 41.96V62.3999" stroke="black" stroke-width="0.5" />
    <path d="M197.6 89.8301V103.48" stroke="black" stroke-width="0.5" />
    <path d="M195.75 107.81V108.56" stroke="black" stroke-width="0.5" />
    <path d="M14 4.83984H41.43" stroke="black" stroke-width="0.5" />
    <path d="M200.09 41.96V62.3999" stroke="black" stroke-width="0.5" />
    <path d="M137.16 0.350098H157.61" stroke="black" stroke-width="0.5" />
    <path d="M197.6 14.5601H200.09" stroke="black" stroke-width="0.5" />
    <path d="M61.8702 2.83984H41.4302" stroke="black" stroke-width="0.5" />
    <path d="M195.75 107.81V103.48" stroke="black" stroke-width="0.5" />
    <path d="M197.6 89.8299V62.3999" stroke="black" stroke-width="0.5" />
    <path d="M198.85 89.8299V62.3999" stroke="black" stroke-width="0.5" />
    <path d="M195.6 89.8299V62.3999" stroke="black" stroke-width="0.5" />
    <path d="M197.6 62.8999H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 66.25H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 66.75H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 85.2202H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 85.7202H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 89.3301H198.85" stroke="black" stroke-width="0.5" />
    <path d="M200.09 89.8299V62.3999" stroke="black" stroke-width="0.5" />
    <path d="M200.09 89.8301H195.6" stroke="black" stroke-width="0.5" />
    <path d="M200.09 62.3999H195.6" stroke="black" stroke-width="0.5" />
    <path d="M196.6 62.3999V64.4299" stroke="black" stroke-width="0.5" />
    <path d="M196.6 65.3599V67.2299" stroke="black" stroke-width="0.5" />
    <path d="M196.6 68.1699V70.0399" stroke="black" stroke-width="0.5" />
    <path d="M196.6 70.9702V72.8402" stroke="black" stroke-width="0.5" />
    <path d="M196.6 73.7798V75.6498" stroke="black" stroke-width="0.5" />
    <path d="M196.6 76.5801V78.4501" stroke="black" stroke-width="0.5" />
    <path d="M196.6 79.3901V81.2601" stroke="black" stroke-width="0.5" />
    <path d="M196.6 82.1899V84.0599" stroke="black" stroke-width="0.5" />
    <path d="M196.6 85V86.87" stroke="black" stroke-width="0.5" />
    <path d="M196.6 87.7998V89.8298" stroke="black" stroke-width="0.5" />
    <path d="M195.6 62.3999H197.6" stroke="black" stroke-width="0.5" />
    <path d="M197.6 41.9801V14.5601" stroke="black" stroke-width="0.5" />
    <path d="M198.85 41.9801V14.5601" stroke="black" stroke-width="0.5" />
    <path d="M195.6 41.9801V14.5601" stroke="black" stroke-width="0.5" />
    <path d="M197.6 15.0601H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 18.4102H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 18.9102H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 37.3701H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 37.8701H198.85" stroke="black" stroke-width="0.5" />
    <path d="M197.6 41.48H198.85" stroke="black" stroke-width="0.5" />
    <path d="M200.09 41.9801V14.5601" stroke="black" stroke-width="0.5" />
    <path d="M200.09 41.98H195.6" stroke="black" stroke-width="0.5" />
    <path d="M200.09 14.5601H195.6" stroke="black" stroke-width="0.5" />
    <path d="M196.6 14.5601V16.5801" stroke="black" stroke-width="0.5" />
    <path d="M196.6 17.52V19.39" stroke="black" stroke-width="0.5" />
    <path d="M196.6 20.3198V22.1898" stroke="black" stroke-width="0.5" />
    <path d="M196.6 23.1299V24.9999" stroke="black" stroke-width="0.5" />
    <path d="M196.6 25.9302V27.8002" stroke="black" stroke-width="0.5" />
    <path d="M196.6 28.7402V30.6102" stroke="black" stroke-width="0.5" />
    <path d="M196.6 31.54V33.41" stroke="black" stroke-width="0.5" />
    <path d="M196.6 34.3501V36.2201" stroke="black" stroke-width="0.5" />
    <path d="M196.6 37.1499V39.0199" stroke="black" stroke-width="0.5" />
    <path d="M196.6 39.96V41.9799" stroke="black" stroke-width="0.5" />
    <path d="M185.03 2.83984H157.61" stroke="black" stroke-width="0.5" />
    <path d="M185.03 1.58984H157.61" stroke="black" stroke-width="0.5" />
    <path d="M185.03 4.83984H157.61" stroke="black" stroke-width="0.5" />
    <path d="M158.11 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M161.46 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M161.96 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M180.42 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M180.92 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M184.54 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M185.03 0.350098H157.61" stroke="black" stroke-width="0.5" />
    <path d="M185.03 0.350098V4.84009" stroke="black" stroke-width="0.5" />
    <path d="M157.61 0.350098V4.84009" stroke="black" stroke-width="0.5" />
    <path d="M157.61 3.83984H159.63" stroke="black" stroke-width="0.5" />
    <path d="M160.57 3.83984H162.44" stroke="black" stroke-width="0.5" />
    <path d="M163.37 3.83984H165.24" stroke="black" stroke-width="0.5" />
    <path d="M166.18 3.83984H168.05" stroke="black" stroke-width="0.5" />
    <path d="M168.98 3.83984H170.85" stroke="black" stroke-width="0.5" />
    <path d="M171.79 3.83984H173.66" stroke="black" stroke-width="0.5" />
    <path d="M174.59 3.83984H176.46" stroke="black" stroke-width="0.5" />
    <path d="M177.4 3.83984H179.27" stroke="black" stroke-width="0.5" />
    <path d="M180.2 3.83984H182.07" stroke="black" stroke-width="0.5" />
    <path d="M183.01 3.83984H185.03" stroke="black" stroke-width="0.5" />
    <path d="M137.16 2.83984H109.74" stroke="black" stroke-width="0.5" />
    <path d="M137.16 1.58984H109.74" stroke="black" stroke-width="0.5" />
    <path d="M137.16 4.83984H109.74" stroke="black" stroke-width="0.5" />
    <path d="M110.24 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M113.59 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M114.09 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M132.55 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M133.05 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M136.67 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M137.16 0.350098H109.74" stroke="black" stroke-width="0.5" />
    <path d="M137.16 0.350098V4.84009" stroke="black" stroke-width="0.5" />
    <path d="M109.74 0.350098V4.84009" stroke="black" stroke-width="0.5" />
    <path d="M109.74 3.83984H111.04" stroke="black" stroke-width="0.5" />
    <path d="M111.98 3.83984H113.85" stroke="black" stroke-width="0.5" />
    <path d="M114.78 3.83984H116.65" stroke="black" stroke-width="0.5" />
    <path d="M117.59 3.83984H119.45" stroke="black" stroke-width="0.5" />
    <path d="M120.39 3.83984H122.26" stroke="black" stroke-width="0.5" />
    <path d="M123.19 3.83984H125.06" stroke="black" stroke-width="0.5" />
    <path d="M126 3.83984H127.3" stroke="black" stroke-width="0.5" />
    <path d="M128.55 3.83984H129.58" stroke="black" stroke-width="0.5" />
    <path d="M130.52 3.83984H132.39" stroke="black" stroke-width="0.5" />
    <path d="M133.32 3.83984H135.19" stroke="black" stroke-width="0.5" />
    <path d="M136.13 3.83984H137.16" stroke="black" stroke-width="0.5" />
    <path d="M89.2901 2.83984H61.8701" stroke="black" stroke-width="0.5" />
    <path d="M89.2901 1.58984H61.8701" stroke="black" stroke-width="0.5" />
    <path d="M89.2901 4.83984H61.8701" stroke="black" stroke-width="0.5" />
    <path d="M62.3701 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M65.7197 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M66.2197 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M84.6802 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M85.1802 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M88.7998 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M89.2901 0.350098H61.8701" stroke="black" stroke-width="0.5" />
    <path d="M89.29 0.350098V4.84009" stroke="black" stroke-width="0.5" />
    <path d="M61.8701 0.350098V4.84009" stroke="black" stroke-width="0.5" />
    <path d="M61.8701 3.83984H63.9501" stroke="black" stroke-width="0.5" />
    <path d="M64.8799 3.83984H66.7499" stroke="black" stroke-width="0.5" />
    <path d="M67.6899 3.83984H69.7599" stroke="black" stroke-width="0.5" />
    <path d="M71.0098 3.83984H72.6697" stroke="black" stroke-width="0.5" />
    <path d="M73.6099 3.83984H75.4799" stroke="black" stroke-width="0.5" />
    <path d="M76.4102 3.83984H78.2802" stroke="black" stroke-width="0.5" />
    <path d="M79.2197 3.83984H81.0898" stroke="black" stroke-width="0.5" />
    <path d="M82.02 3.83984H83.89" stroke="black" stroke-width="0.5" />
    <path d="M84.8301 3.83984H86.7001" stroke="black" stroke-width="0.5" />
    <path d="M87.6299 3.83984H89.2899" stroke="black" stroke-width="0.5" />
    <path d="M41.43 2.83984H14" stroke="black" stroke-width="0.5" />
    <path d="M41.43 1.58984H14" stroke="black" stroke-width="0.5" />
    <path d="M41.43 4.83984H14" stroke="black" stroke-width="0.5" />
    <path d="M14.5 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M17.8501 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M18.3501 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M36.8198 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M37.3101 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M40.9302 2.83984V1.58984" stroke="black" stroke-width="0.5" />
    <path d="M41.43 0.350098H14" stroke="black" stroke-width="0.5" />
    <path d="M41.4302 0.350098V4.84009" stroke="black" stroke-width="0.5" />
    <path d="M14 0.350098V4.84009" stroke="black" stroke-width="0.5" />
    <path d="M14 3.83984H16.03" stroke="black" stroke-width="0.5" />
    <path d="M16.96 3.83984H18.83" stroke="black" stroke-width="0.5" />
    <path d="M19.77 3.83984H21.64" stroke="black" stroke-width="0.5" />
    <path d="M22.5698 3.83984H24.4398" stroke="black" stroke-width="0.5" />
    <path d="M25.3799 3.83984H27.2499" stroke="black" stroke-width="0.5" />
    <path d="M28.1802 3.83984H30.0502" stroke="black" stroke-width="0.5" />
    <path d="M30.98 3.83984H32.85" stroke="black" stroke-width="0.5" />
    <path d="M33.79 3.83984H35.66" stroke="black" stroke-width="0.5" />
    <path d="M36.5898 3.83984H38.4598" stroke="black" stroke-width="0.5" />
    <path d="M39.3999 3.83984H41.4299" stroke="black" stroke-width="0.5" />
    <path d="M3 6V7.82985" stroke="black" stroke-width="0.5" />
    <path d="M141.71 96.52H146.08" stroke="black" stroke-width="0.5" />
    <path d="M155.06 96.52H156.36" stroke="black" stroke-width="0.5" />
    <path d="M141.71 97.77H146.08" stroke="black" stroke-width="0.5" />
    <path d="M154.62 97.77H156.36" stroke="black" stroke-width="0.5" />
    <path d="M146.08 97.77L146.25 99.52" stroke="black" stroke-width="0.5" />
    <path d="M146.25 99.52L146.77 101.2" stroke="black" stroke-width="0.5" />
    <path d="M146.77 101.2L147.59 102.76" stroke="black" stroke-width="0.5" />
    <path d="M147.59 102.76L148.71 104.12" stroke="black" stroke-width="0.5" />
    <path d="M148.71 104.12L150.07 105.23" stroke="black" stroke-width="0.5" />
    <path d="M150.07 105.23L151.62 106.06" stroke="black" stroke-width="0.5" />
    <path d="M151.62 106.06L153.31 106.57" stroke="black" stroke-width="0.5" />
    <path d="M153.31 106.57L155.06 106.74" stroke="black" stroke-width="0.5" />
    <path d="M155.06 97.77H154.62" stroke="black" stroke-width="0.5" />
    <path d="M154.62 97.77V106.73" stroke="black" stroke-width="0.5" />
    <path d="M155.06 106.74V97.77" stroke="black" stroke-width="0.5" />
    <path d="M155.06 97.77V96.52" stroke="black" stroke-width="0.5" />
    <path d="M155.56 97.77V96.52" stroke="black" stroke-width="0.5" />
    <path d="M146.08 97.77V96.52" stroke="black" stroke-width="0.5" />
    <path d="M145.58 97.77V96.52" stroke="black" stroke-width="0.5" />
    <path d="M5.35986 105.35V104.1" stroke="black" stroke-width="0.5" />
    <path d="M1.75 105.71H5.02002" stroke="black" stroke-width="0.5" />
    <path d="M5.02002 107.24V104.17" stroke="black" stroke-width="0.5" />
    <path d="M1.75 107.24V104.17" stroke="black" stroke-width="0.5" />
    <path d="M97.9502 105.71H101.23" stroke="black" stroke-width="0.5" />
    <path d="M101.23 107.24V104.17" stroke="black" stroke-width="0.5" />
    <path d="M97.9502 107.24V104.17" stroke="black" stroke-width="0.5" />
    <path d="M192.69 105.71H195.96" stroke="black" stroke-width="0.5" />
    <path d="M195.96 107.24V104.17" stroke="black" stroke-width="0.5" />
    <path d="M192.69 107.24V104.17" stroke="black" stroke-width="0.5" />
    <path
      d="M4.3999 30.2701L9.56989 25.1001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M13.8098 29.3301L9.56982 25.1001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M14.6899 28.4501L4.3999 18.1602"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M13.8101 29.3302L14.6901 28.4502"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M41.43 30.2701L36.25 25.1001" stroke="black" stroke-width="0.5" />
    <path d="M32.02 29.3301L36.25 25.1001" stroke="black" stroke-width="0.5" />
    <path
      d="M31.1401 28.4501L41.4301 18.1602"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M32.0201 29.3302L31.1401 28.4502"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M38.1702 98.3599H32.2402" stroke="black" stroke-width="0.5" />
    <path d="M23.2599 98.3599H21.96" stroke="black" stroke-width="0.5" />
    <path d="M38.1702 97.1099H32.2402" stroke="black" stroke-width="0.5" />
    <path d="M23.2599 97.1099H20.71" stroke="black" stroke-width="0.5" />
    <path d="M20.71 105.35V97.1099" stroke="black" stroke-width="0.5" />
    <path d="M21.96 106.59V98.3599" stroke="black" stroke-width="0.5" />
    <path d="M22.7598 98.3599V97.1099" stroke="black" stroke-width="0.5" />
    <path d="M23.2598 98.3599V97.1099" stroke="black" stroke-width="0.5" />
    <path d="M32.2402 98.3599V97.1099" stroke="black" stroke-width="0.5" />
    <path d="M32.7402 98.3599V97.1099" stroke="black" stroke-width="0.5" />
    <path
      d="M32.2398 98.3599L32.0698 100.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M32.0698 100.11L31.5498 101.79"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M31.55 101.79L30.73 103.34" stroke="black" stroke-width="0.5" />
    <path d="M30.7299 103.34L29.6099 104.7" stroke="black" stroke-width="0.5" />
    <path d="M29.61 104.7L28.25 105.82" stroke="black" stroke-width="0.5" />
    <path
      d="M28.2502 105.82L26.7002 106.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M26.6998 106.65L25.0098 107.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M25.0098 107.16L23.2598 107.33"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M23.2598 98.3599H23.6998" stroke="black" stroke-width="0.5" />
    <path d="M23.7002 98.3599V107.32" stroke="black" stroke-width="0.5" />
    <path d="M23.2598 107.33V98.3599" stroke="black" stroke-width="0.5" />
    <path d="M32.7402 97.73H37.7202" stroke="black" stroke-width="0.5" />
    <path d="M37.7197 98.3599V97.1099" stroke="black" stroke-width="0.5" />
    <path d="M83.98 79.6602V79.1602" stroke="black" stroke-width="0.5" />
    <path d="M103.37 89.96H102.88" stroke="black" stroke-width="0.5" />
    <path d="M181.66 103.33V104.37" stroke="black" stroke-width="0.5" />
    <path d="M181.66 104.37H181.49" stroke="black" stroke-width="0.5" />
    <path d="M181.49 104.37V103.33" stroke="black" stroke-width="0.5" />
    <path d="M181.49 103.33H181.66" stroke="black" stroke-width="0.5" />
    <path d="M181.66 95.3899V94.3599" stroke="black" stroke-width="0.5" />
    <path d="M181.66 94.3599H181.49" stroke="black" stroke-width="0.5" />
    <path d="M181.49 94.3599V95.3899" stroke="black" stroke-width="0.5" />
    <path d="M181.49 95.3901H181.66" stroke="black" stroke-width="0.5" />
    <path d="M168.71 105.35H167.67" stroke="black" stroke-width="0.5" />
    <path d="M167.67 105.35V105.19" stroke="black" stroke-width="0.5" />
    <path d="M167.67 105.19H168.71" stroke="black" stroke-width="0.5" />
    <path d="M168.71 105.19V105.35" stroke="black" stroke-width="0.5" />
    <path d="M178.14 105.35H179.18" stroke="black" stroke-width="0.5" />
    <path d="M179.18 105.35V105.19" stroke="black" stroke-width="0.5" />
    <path d="M179.18 105.19H178.14" stroke="black" stroke-width="0.5" />
    <path d="M178.14 105.19V105.35" stroke="black" stroke-width="0.5" />
    <path d="M181.1 99.5298V103.62" stroke="black" stroke-width="0.5" />
    <path d="M181.1 103.62L181.11 103.66" stroke="black" stroke-width="0.5" />
    <path d="M181.11 103.66L181.14 103.68" stroke="black" stroke-width="0.5" />
    <path d="M181.14 103.68L181.17 103.69" stroke="black" stroke-width="0.5" />
    <path d="M181.17 103.69H181.49" stroke="black" stroke-width="0.5" />
    <path d="M181.49 103.69V104.01" stroke="black" stroke-width="0.5" />
    <path d="M181.49 104.01H181.1" stroke="black" stroke-width="0.5" />
    <path d="M181.1 104.01L180.94 103.97" stroke="black" stroke-width="0.5" />
    <path d="M180.94 103.97L180.82 103.85" stroke="black" stroke-width="0.5" />
    <path d="M180.82 103.85L180.78 103.69" stroke="black" stroke-width="0.5" />
    <path d="M180.78 103.69V99.5298" stroke="black" stroke-width="0.5" />
    <path d="M180.78 99.53V95.04" stroke="black" stroke-width="0.5" />
    <path
      d="M180.78 95.0399L180.82 94.8799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M180.82 94.8798L180.94 94.7598"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M180.94 94.7599L181.1 94.71" stroke="black" stroke-width="0.5" />
    <path d="M181.1 94.71H181.49" stroke="black" stroke-width="0.5" />
    <path d="M181.49 94.71V95.0399" stroke="black" stroke-width="0.5" />
    <path d="M181.49 95.04H181.17" stroke="black" stroke-width="0.5" />
    <path d="M181.17 95.04L181.14 95.05" stroke="black" stroke-width="0.5" />
    <path
      d="M181.14 95.0498L181.11 95.0698"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M181.11 95.0698L181.1 95.0998" stroke="black" stroke-width="0.5" />
    <path d="M181.1 95.1001V99.5301" stroke="black" stroke-width="0.5" />
    <path d="M168.42 104.8H168.39" stroke="black" stroke-width="0.5" />
    <path d="M168.39 104.8L168.36 104.83" stroke="black" stroke-width="0.5" />
    <path d="M168.36 104.83L168.35 104.86" stroke="black" stroke-width="0.5" />
    <path d="M168.35 104.86V105.19" stroke="black" stroke-width="0.5" />
    <path d="M168.35 105.19H168.03" stroke="black" stroke-width="0.5" />
    <path d="M168.03 105.19V104.8" stroke="black" stroke-width="0.5" />
    <path d="M168.03 104.8L168.07 104.63" stroke="black" stroke-width="0.5" />
    <path d="M168.07 104.63L168.19 104.51" stroke="black" stroke-width="0.5" />
    <path d="M168.19 104.51L168.35 104.47" stroke="black" stroke-width="0.5" />
    <path d="M168.35 104.47H178.5" stroke="black" stroke-width="0.5" />
    <path d="M178.5 104.47L178.66 104.51" stroke="black" stroke-width="0.5" />
    <path d="M178.66 104.51L178.78 104.63" stroke="black" stroke-width="0.5" />
    <path d="M178.78 104.63L178.83 104.8" stroke="black" stroke-width="0.5" />
    <path d="M178.83 104.8V105.19" stroke="black" stroke-width="0.5" />
    <path d="M178.83 105.19H178.5" stroke="black" stroke-width="0.5" />
    <path d="M178.5 105.19V104.86" stroke="black" stroke-width="0.5" />
    <path d="M178.5 104.86L178.49 104.83" stroke="black" stroke-width="0.5" />
    <path d="M178.49 104.83L178.47 104.8" stroke="black" stroke-width="0.5" />
    <path d="M178.47 104.8H178.44" stroke="black" stroke-width="0.5" />
    <path d="M178.44 104.8H168.42" stroke="black" stroke-width="0.5" />
    <path d="M179.04 99.34L178.52 99.27" stroke="black" stroke-width="0.5" />
    <path
      d="M178.52 99.2702L178.13 99.2202"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M178.13 99.2202L177.8 99.1602" stroke="black" stroke-width="0.5" />
    <path d="M177.8 99.1601L177.51 99.1001" stroke="black" stroke-width="0.5" />
    <path
      d="M177.51 99.0998L177.29 99.0498"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.29 99.0498L177.11 99.0098"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M177.11 99.01L177 98.98" stroke="black" stroke-width="0.5" />
    <path d="M177 98.9802L176.9 98.9502" stroke="black" stroke-width="0.5" />
    <path d="M176.9 98.9499L176.8 98.9399" stroke="black" stroke-width="0.5" />
    <path d="M176.8 98.9402L176.67 98.9302" stroke="black" stroke-width="0.5" />
    <path
      d="M176.67 98.9299L176.49 98.9199"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.49 98.9199H176.26" stroke="black" stroke-width="0.5" />
    <path
      d="M176.26 98.9199L176.01 98.9399"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.01 98.9399L175.73 98.9699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.73 98.9702L175.44 99.0302"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.44 99.0298L175.15 99.1198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.15 99.1201L174.87 99.2401"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.87 99.2402L174.6 99.3902" stroke="black" stroke-width="0.5" />
    <path d="M174.6 99.3901L174.37 99.5601" stroke="black" stroke-width="0.5" />
    <path
      d="M174.37 99.5601L174.16 99.7501"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.16 99.75L174 99.96" stroke="black" stroke-width="0.5" />
    <path d="M174 99.96L173.88 100.17" stroke="black" stroke-width="0.5" />
    <path d="M173.88 100.17L173.8 100.38" stroke="black" stroke-width="0.5" />
    <path d="M173.8 100.38L173.78 100.58" stroke="black" stroke-width="0.5" />
    <path d="M173.78 100.58L173.81 100.79" stroke="black" stroke-width="0.5" />
    <path d="M173.81 100.79L173.88 100.99" stroke="black" stroke-width="0.5" />
    <path d="M173.88 100.99L174 101.2" stroke="black" stroke-width="0.5" />
    <path d="M174 101.2L174.16 101.41" stroke="black" stroke-width="0.5" />
    <path d="M174.16 101.41L174.37 101.6" stroke="black" stroke-width="0.5" />
    <path d="M174.37 101.6L174.6 101.77" stroke="black" stroke-width="0.5" />
    <path d="M174.6 101.77L174.87 101.92" stroke="black" stroke-width="0.5" />
    <path d="M174.87 101.92L175.15 102.04" stroke="black" stroke-width="0.5" />
    <path d="M175.15 102.04L175.44 102.13" stroke="black" stroke-width="0.5" />
    <path d="M175.44 102.13L175.73 102.19" stroke="black" stroke-width="0.5" />
    <path d="M175.73 102.19L176.01 102.23" stroke="black" stroke-width="0.5" />
    <path d="M176.01 102.23L176.27 102.24" stroke="black" stroke-width="0.5" />
    <path d="M176.27 102.24H176.49" stroke="black" stroke-width="0.5" />
    <path d="M176.49 102.24L176.68 102.23" stroke="black" stroke-width="0.5" />
    <path d="M176.68 102.23L176.8 102.22" stroke="black" stroke-width="0.5" />
    <path d="M176.8 102.22L176.9 102.21" stroke="black" stroke-width="0.5" />
    <path d="M176.9 102.21L177 102.19" stroke="black" stroke-width="0.5" />
    <path d="M177 102.19L177.12 102.16" stroke="black" stroke-width="0.5" />
    <path d="M177.12 102.16L177.29 102.11" stroke="black" stroke-width="0.5" />
    <path d="M177.29 102.11L177.52 102.06" stroke="black" stroke-width="0.5" />
    <path d="M177.52 102.06L177.8 102" stroke="black" stroke-width="0.5" />
    <path d="M177.8 102L178.13 101.94" stroke="black" stroke-width="0.5" />
    <path d="M178.13 101.94L178.52 101.89" stroke="black" stroke-width="0.5" />
    <path d="M178.52 101.89L178.93 101.85" stroke="black" stroke-width="0.5" />
    <path d="M178.93 101.85L179.04 101.84" stroke="black" stroke-width="0.5" />
    <path d="M179.18 102.28L179.19 102.32" stroke="black" stroke-width="0.5" />
    <path d="M179.19 102.32L179.2 102.35" stroke="black" stroke-width="0.5" />
    <path d="M179.2 102.35L179.22 102.39" stroke="black" stroke-width="0.5" />
    <path d="M179.22 102.39L179.23 102.41" stroke="black" stroke-width="0.5" />
    <path d="M179.23 102.41L179.25 102.44" stroke="black" stroke-width="0.5" />
    <path d="M179.25 102.44L179.27 102.47" stroke="black" stroke-width="0.5" />
    <path d="M179.27 102.47L179.29 102.51" stroke="black" stroke-width="0.5" />
    <path d="M179.29 102.51L179.32 102.54" stroke="black" stroke-width="0.5" />
    <path d="M179.32 102.54L179.36 102.58" stroke="black" stroke-width="0.5" />
    <path d="M179.36 102.58L179.4 102.62" stroke="black" stroke-width="0.5" />
    <path d="M179.4 102.62L179.45 102.67" stroke="black" stroke-width="0.5" />
    <path d="M179.45 102.67L179.51 102.72" stroke="black" stroke-width="0.5" />
    <path d="M179.51 102.72L179.56 102.76" stroke="black" stroke-width="0.5" />
    <path d="M179.56 102.76L179.63 102.8" stroke="black" stroke-width="0.5" />
    <path d="M179.63 102.8L179.69 102.84" stroke="black" stroke-width="0.5" />
    <path d="M179.69 102.84L179.76 102.87" stroke="black" stroke-width="0.5" />
    <path d="M179.76 102.87L179.83 102.9" stroke="black" stroke-width="0.5" />
    <path d="M179.83 102.9L179.9 102.92" stroke="black" stroke-width="0.5" />
    <path d="M179.9 102.92L179.97 102.93" stroke="black" stroke-width="0.5" />
    <path d="M179.97 102.93L180.04 102.94" stroke="black" stroke-width="0.5" />
    <path d="M180.04 102.94H180.11" stroke="black" stroke-width="0.5" />
    <path d="M180.11 102.94H180.18" stroke="black" stroke-width="0.5" />
    <path d="M180.18 102.94H180.25" stroke="black" stroke-width="0.5" />
    <path d="M180.25 102.94H180.32" stroke="black" stroke-width="0.5" />
    <path d="M180.32 102.94H180.38" stroke="black" stroke-width="0.5" />
    <path d="M180.38 102.94H180.44" stroke="black" stroke-width="0.5" />
    <path d="M180.44 102.94H180.5" stroke="black" stroke-width="0.5" />
    <path d="M180.5 102.94L180.56 102.93" stroke="black" stroke-width="0.5" />
    <path d="M180.56 102.93H180.61" stroke="black" stroke-width="0.5" />
    <path d="M180.61 102.93H180.66" stroke="black" stroke-width="0.5" />
    <path d="M180.66 102.93L180.71 102.92" stroke="black" stroke-width="0.5" />
    <path d="M180.71 102.92H180.75" stroke="black" stroke-width="0.5" />
    <path d="M180.75 102.92L180.79 102.91" stroke="black" stroke-width="0.5" />
    <path d="M180.79 102.91L180.83 102.9" stroke="black" stroke-width="0.5" />
    <path d="M180.83 102.9L180.86 102.89" stroke="black" stroke-width="0.5" />
    <path d="M180.86 102.89L180.89 102.87" stroke="black" stroke-width="0.5" />
    <path d="M180.89 102.87L180.92 102.85" stroke="black" stroke-width="0.5" />
    <path d="M180.92 102.85L180.94 102.82" stroke="black" stroke-width="0.5" />
    <path d="M180.94 102.82L180.97 102.79" stroke="black" stroke-width="0.5" />
    <path d="M180.97 102.79L180.99 102.75" stroke="black" stroke-width="0.5" />
    <path d="M180.99 102.75L181 102.7" stroke="black" stroke-width="0.5" />
    <path d="M181 102.7L181.02 102.64" stroke="black" stroke-width="0.5" />
    <path d="M181.02 102.64L181.03 102.58" stroke="black" stroke-width="0.5" />
    <path d="M181.03 102.58L181.04 102.5" stroke="black" stroke-width="0.5" />
    <path d="M181.04 102.5L181.05 102.4" stroke="black" stroke-width="0.5" />
    <path d="M181.05 102.4L181.06 102.3" stroke="black" stroke-width="0.5" />
    <path d="M181.06 102.3L181.07 102.18" stroke="black" stroke-width="0.5" />
    <path d="M181.07 102.18L181.08 102.05" stroke="black" stroke-width="0.5" />
    <path d="M181.08 102.05V101.9" stroke="black" stroke-width="0.5" />
    <path d="M181.08 101.9L181.09 101.73" stroke="black" stroke-width="0.5" />
    <path d="M181.09 101.73V101.56" stroke="black" stroke-width="0.5" />
    <path d="M181.09 101.56L181.1 101.38" stroke="black" stroke-width="0.5" />
    <path d="M181.1 101.38V101.18" stroke="black" stroke-width="0.5" />
    <path d="M181.1 101.18V100.99" stroke="black" stroke-width="0.5" />
    <path d="M181.1 100.99V100.78" stroke="black" stroke-width="0.5" />
    <path d="M181.1 100.78V100.58" stroke="black" stroke-width="0.5" />
    <path d="M181.1 100.58V100.38" stroke="black" stroke-width="0.5" />
    <path d="M181.1 100.38V100.17" stroke="black" stroke-width="0.5" />
    <path d="M181.1 100.17V99.98" stroke="black" stroke-width="0.5" />
    <path d="M181.1 99.9798V99.7798" stroke="black" stroke-width="0.5" />
    <path d="M181.1 99.7801L181.09 99.6001" stroke="black" stroke-width="0.5" />
    <path d="M181.09 99.5999V99.4199" stroke="black" stroke-width="0.5" />
    <path
      d="M181.09 99.4198L181.08 99.2598"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M181.08 99.2599V99.1099" stroke="black" stroke-width="0.5" />
    <path d="M181.08 99.11L181.07 98.98" stroke="black" stroke-width="0.5" />
    <path
      d="M181.07 98.9799L181.06 98.8599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M181.06 98.8598L181.05 98.7598"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M181.05 98.7599L181.04 98.6699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M181.04 98.6698L181.03 98.5898"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M181.03 98.59L181.01 98.52" stroke="black" stroke-width="0.5" />
    <path d="M181.01 98.52L181 98.46" stroke="black" stroke-width="0.5" />
    <path d="M181 98.4602L180.98 98.4102" stroke="black" stroke-width="0.5" />
    <path
      d="M180.98 98.4101L180.96 98.3701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M180.96 98.3698L180.94 98.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M180.94 98.3401L180.91 98.3101"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M180.91 98.31L180.89 98.29" stroke="black" stroke-width="0.5" />
    <path
      d="M180.89 98.2898L180.86 98.2798"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M180.86 98.28L180.82 98.27" stroke="black" stroke-width="0.5" />
    <path
      d="M180.82 98.2698L180.79 98.2598"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M180.79 98.26L180.75 98.25" stroke="black" stroke-width="0.5" />
    <path d="M180.75 98.2502L180.7 98.2402" stroke="black" stroke-width="0.5" />
    <path d="M180.7 98.2402H180.66" stroke="black" stroke-width="0.5" />
    <path d="M180.66 98.24L180.61 98.23" stroke="black" stroke-width="0.5" />
    <path d="M180.61 98.23H180.55" stroke="black" stroke-width="0.5" />
    <path d="M180.55 98.23H180.5" stroke="black" stroke-width="0.5" />
    <path d="M180.5 98.2302L180.44 98.2202" stroke="black" stroke-width="0.5" />
    <path d="M180.44 98.2202H180.38" stroke="black" stroke-width="0.5" />
    <path d="M180.38 98.2202H180.31" stroke="black" stroke-width="0.5" />
    <path d="M180.31 98.2202H180.25" stroke="black" stroke-width="0.5" />
    <path d="M180.25 98.2202H180.18" stroke="black" stroke-width="0.5" />
    <path d="M180.18 98.2202H180.11" stroke="black" stroke-width="0.5" />
    <path
      d="M180.11 98.2202L180.04 98.2302"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M180.04 98.23L179.97 98.24" stroke="black" stroke-width="0.5" />
    <path d="M179.97 98.2402L179.9 98.2502" stroke="black" stroke-width="0.5" />
    <path d="M179.9 98.25L179.83 98.27" stroke="black" stroke-width="0.5" />
    <path d="M179.83 98.27L179.76 98.3" stroke="black" stroke-width="0.5" />
    <path d="M179.76 98.2998L179.7 98.3298" stroke="black" stroke-width="0.5" />
    <path d="M179.7 98.3301L179.63 98.3701" stroke="black" stroke-width="0.5" />
    <path
      d="M179.63 98.3701L179.57 98.4101"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M179.57 98.4102L179.51 98.4602"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M179.51 98.46L179.45 98.5099" stroke="black" stroke-width="0.5" />
    <path d="M179.45 98.5098L179.4 98.5698" stroke="black" stroke-width="0.5" />
    <path d="M179.4 98.5698L179.35 98.6298" stroke="black" stroke-width="0.5" />
    <path
      d="M179.35 98.6299L179.31 98.6899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M179.31 98.6899L179.26 98.7499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M179.26 98.75L179.23 98.82" stroke="black" stroke-width="0.5" />
    <path d="M179.23 98.8198L179.2 98.8798" stroke="black" stroke-width="0.5" />
    <path d="M179.2 98.8799L179.17 98.9499" stroke="black" stroke-width="0.5" />
    <path
      d="M179.17 98.9502L179.14 99.0202"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M179.14 99.02L179.12 99.09" stroke="black" stroke-width="0.5" />
    <path
      d="M179.12 99.0898L179.09 99.1599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M179.09 99.1602L179.07 99.2301"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M179.07 99.23L179.05 99.3" stroke="black" stroke-width="0.5" />
    <path
      d="M179.05 99.2998L179.03 99.3698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M179.03 99.3701L179.02 99.4501"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M179.02 99.4502L179 99.5202" stroke="black" stroke-width="0.5" />
    <path d="M179 99.52L178.98 99.6" stroke="black" stroke-width="0.5" />
    <path
      d="M178.98 99.6001L178.97 99.6801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.97 99.6802L178.95 99.7602"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.95 99.7598L178.94 99.8398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.94 99.8398L178.93 99.9298"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M178.93 99.9302L178.92 100.02" stroke="black" stroke-width="0.5" />
    <path d="M178.92 100.02L178.91 100.11" stroke="black" stroke-width="0.5" />
    <path d="M178.91 100.11V100.2" stroke="black" stroke-width="0.5" />
    <path d="M178.91 100.2L178.9 100.3" stroke="black" stroke-width="0.5" />
    <path d="M178.9 100.3V100.39" stroke="black" stroke-width="0.5" />
    <path d="M178.9 100.39L178.89 100.49" stroke="black" stroke-width="0.5" />
    <path d="M178.89 100.49V100.59" stroke="black" stroke-width="0.5" />
    <path d="M178.89 100.59V100.68" stroke="black" stroke-width="0.5" />
    <path d="M178.89 100.68V100.78" stroke="black" stroke-width="0.5" />
    <path d="M178.89 100.78L178.9 100.87" stroke="black" stroke-width="0.5" />
    <path d="M178.9 100.87V100.97" stroke="black" stroke-width="0.5" />
    <path d="M178.9 100.97L178.91 101.06" stroke="black" stroke-width="0.5" />
    <path d="M178.91 101.06V101.15" stroke="black" stroke-width="0.5" />
    <path d="M178.91 101.15L178.92 101.23" stroke="black" stroke-width="0.5" />
    <path d="M178.92 101.23L178.93 101.31" stroke="black" stroke-width="0.5" />
    <path d="M178.93 101.31L178.94 101.39" stroke="black" stroke-width="0.5" />
    <path d="M178.94 101.39L178.95 101.46" stroke="black" stroke-width="0.5" />
    <path d="M178.95 101.46L178.96 101.53" stroke="black" stroke-width="0.5" />
    <path d="M178.96 101.53L178.98 101.6" stroke="black" stroke-width="0.5" />
    <path d="M178.98 101.6L178.99 101.66" stroke="black" stroke-width="0.5" />
    <path d="M178.99 101.66L179.01 101.72" stroke="black" stroke-width="0.5" />
    <path d="M179.01 101.72L179.02 101.78" stroke="black" stroke-width="0.5" />
    <path d="M179.02 101.78L179.04 101.84" stroke="black" stroke-width="0.5" />
    <path d="M179.04 101.84L179.06 101.9" stroke="black" stroke-width="0.5" />
    <path d="M179.06 101.9L179.07 101.97" stroke="black" stroke-width="0.5" />
    <path d="M179.07 101.97L179.09 102.03" stroke="black" stroke-width="0.5" />
    <path d="M179.09 102.03L179.11 102.08" stroke="black" stroke-width="0.5" />
    <path d="M179.11 102.08L179.13 102.14" stroke="black" stroke-width="0.5" />
    <path d="M179.13 102.14L179.15 102.19" stroke="black" stroke-width="0.5" />
    <path d="M179.15 102.19L179.16 102.24" stroke="black" stroke-width="0.5" />
    <path d="M179.16 102.24L179.18 102.28" stroke="black" stroke-width="0.5" />
    <path d="M180.88 85.9199H181.25" stroke="black" stroke-width="0.5" />
    <path d="M180.88 87.0199V85.9199" stroke="black" stroke-width="0.5" />
    <path d="M181.25 87.02H180.88" stroke="black" stroke-width="0.5" />
    <path d="M181.25 85.9199V87.0199" stroke="black" stroke-width="0.5" />
    <path
      d="M178.21 84.6001L177.89 84.6701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.89 84.6699L177.65 84.8699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.65 84.8701L177.57 85.1401"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.57 87.7998L177.65 88.0698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.65 88.0698L177.89 88.2598"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.89 88.2598L178.21 88.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M179.8 88.3402L180.25 88.1802" stroke="black" stroke-width="0.5" />
    <path
      d="M180.25 88.1798L180.44 87.7998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M180.44 85.1398L180.25 84.7598"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M180.25 84.7601L179.8 84.6001" stroke="black" stroke-width="0.5" />
    <path d="M178.21 84.6001H179.8" stroke="black" stroke-width="0.5" />
    <path d="M177.57 87.8001V85.1401" stroke="black" stroke-width="0.5" />
    <path d="M179.8 88.3398H178.21" stroke="black" stroke-width="0.5" />
    <path d="M180.44 85.1401V87.8001" stroke="black" stroke-width="0.5" />
    <path d="M177.19 84.2402H181.66" stroke="black" stroke-width="0.5" />
    <path d="M177.19 88.7002V84.2402" stroke="black" stroke-width="0.5" />
    <path d="M181.66 88.7002H177.19" stroke="black" stroke-width="0.5" />
    <path d="M181.66 84.2402V88.7002" stroke="black" stroke-width="0.5" />
    <path
      d="M173.89 52.8102L174.41 52.7402"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.41 52.7399L174.8 52.6899" stroke="black" stroke-width="0.5" />
    <path d="M174.8 52.6899L175.14 52.6299" stroke="black" stroke-width="0.5" />
    <path
      d="M175.14 52.6298L175.42 52.5698"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M175.42 52.57L175.65 52.52" stroke="black" stroke-width="0.5" />
    <path d="M175.65 52.52L175.82 52.48" stroke="black" stroke-width="0.5" />
    <path
      d="M175.82 52.4802L175.94 52.4502"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.94 52.4499L176.03 52.4199"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.03 52.4202L176.13 52.4102"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.13 52.4099L176.26 52.3999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.26 52.4001L176.45 52.3901"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.45 52.3901H176.67" stroke="black" stroke-width="0.5" />
    <path
      d="M176.67 52.3901L176.92 52.4101"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.92 52.4102L177.2 52.4402" stroke="black" stroke-width="0.5" />
    <path d="M177.2 52.4399L177.49 52.4999" stroke="black" stroke-width="0.5" />
    <path d="M177.49 52.5L177.78 52.59" stroke="black" stroke-width="0.5" />
    <path
      d="M177.78 52.5898L178.07 52.7099"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M178.07 52.71L178.33 52.86" stroke="black" stroke-width="0.5" />
    <path
      d="M178.33 52.8599L178.57 53.0299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.57 53.0298L178.77 53.2198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.77 53.2202L178.93 53.4302"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.93 53.4302L179.05 53.6402"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M179.05 53.6401L179.13 53.8501"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M179.13 53.8501L179.15 54.0501"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M179.15 54.0498L179.13 54.2598"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M179.13 54.2598L179.05 54.4598"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M179.05 54.46L178.93 54.67" stroke="black" stroke-width="0.5" />
    <path
      d="M178.93 54.6699L178.77 54.8799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.77 54.8799L178.57 55.0699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.57 55.0698L178.33 55.2398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.33 55.2402L178.06 55.3902"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M178.06 55.3901L177.78 55.5101"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.78 55.5098L177.49 55.5998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M177.49 55.6001L177.2 55.6601" stroke="black" stroke-width="0.5" />
    <path d="M177.2 55.6602L176.92 55.7001" stroke="black" stroke-width="0.5" />
    <path
      d="M176.92 55.7002L176.66 55.7102"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.66 55.71H176.44" stroke="black" stroke-width="0.5" />
    <path
      d="M176.44 55.7102L176.26 55.7002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.26 55.6999L176.13 55.6899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.13 55.6902L176.04 55.6802"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.04 55.6801L175.94 55.6602"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.94 55.6599L175.81 55.6299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.81 55.6301L175.64 55.5801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.64 55.5798L175.42 55.5298"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.42 55.5302L175.14 55.4702"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M175.14 55.4702L174.8 55.4102" stroke="black" stroke-width="0.5" />
    <path d="M174.8 55.4099L174.42 55.3599" stroke="black" stroke-width="0.5" />
    <path d="M174.42 55.3601L174 55.3101" stroke="black" stroke-width="0.5" />
    <path d="M174 55.3101H173.89" stroke="black" stroke-width="0.5" />
    <path d="M173.75 55.75L173.74 55.79" stroke="black" stroke-width="0.5" />
    <path d="M173.74 55.79L173.73 55.8201" stroke="black" stroke-width="0.5" />
    <path
      d="M173.73 55.8198L173.71 55.8498"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.71 55.8501L173.7 55.8801" stroke="black" stroke-width="0.5" />
    <path d="M173.7 55.8799L173.68 55.9099" stroke="black" stroke-width="0.5" />
    <path
      d="M173.68 55.9102L173.66 55.9402"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.66 55.9399L173.64 55.9799"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.64 55.98L173.61 56.01" stroke="black" stroke-width="0.5" />
    <path
      d="M173.61 56.0098L173.57 56.0498"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.57 56.0498L173.53 56.0898"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.53 56.0898L173.48 56.1398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.48 56.1401L173.43 56.1901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.43 56.1899L173.37 56.2299"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.37 56.23L173.31 56.27" stroke="black" stroke-width="0.5" />
    <path d="M173.31 56.27L173.24 56.31" stroke="black" stroke-width="0.5" />
    <path
      d="M173.24 56.3101L173.17 56.3401"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.17 56.3398L173.11 56.3698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.11 56.3701L173.03 56.3801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.03 56.3799L172.96 56.3999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.96 56.3999L172.89 56.4099"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.89 56.4102H172.82" stroke="black" stroke-width="0.5" />
    <path d="M172.82 56.4102H172.75" stroke="black" stroke-width="0.5" />
    <path d="M172.75 56.4102H172.68" stroke="black" stroke-width="0.5" />
    <path d="M172.68 56.4102H172.61" stroke="black" stroke-width="0.5" />
    <path d="M172.61 56.4102H172.55" stroke="black" stroke-width="0.5" />
    <path d="M172.55 56.4102H172.49" stroke="black" stroke-width="0.5" />
    <path d="M172.49 56.4102H172.43" stroke="black" stroke-width="0.5" />
    <path
      d="M172.43 56.4099L172.37 56.3999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.37 56.3999H172.32" stroke="black" stroke-width="0.5" />
    <path d="M172.32 56.3999H172.27" stroke="black" stroke-width="0.5" />
    <path
      d="M172.27 56.4001L172.23 56.3901"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.23 56.3901H172.18" stroke="black" stroke-width="0.5" />
    <path
      d="M172.18 56.3899L172.14 56.3799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.14 56.3801L172.11 56.3701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.11 56.3699L172.07 56.3599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.07 56.3598L172.04 56.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.04 56.3398L172.01 56.3198"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.01 56.3201L171.99 56.29" stroke="black" stroke-width="0.5" />
    <path
      d="M171.99 56.2898L171.97 56.2598"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M171.97 56.2602L171.95 56.2202"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M171.95 56.2199L171.93 56.1699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M171.93 56.1699L171.91 56.1099"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.91 56.11L171.9 56.04" stroke="black" stroke-width="0.5" />
    <path d="M171.9 56.0399L171.89 55.96" stroke="black" stroke-width="0.5" />
    <path
      d="M171.89 55.9601L171.88 55.8701"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.88 55.87L171.87 55.77" stroke="black" stroke-width="0.5" />
    <path
      d="M171.87 55.7699L171.86 55.6499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.86 55.65V55.52" stroke="black" stroke-width="0.5" />
    <path
      d="M171.86 55.5201L171.85 55.3701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M171.85 55.3702L171.84 55.2002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.84 55.1998V55.0298" stroke="black" stroke-width="0.5" />
    <path d="M171.84 55.0301V54.8501" stroke="black" stroke-width="0.5" />
    <path
      d="M171.84 54.8499L171.83 54.6499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.83 54.6499V54.46" stroke="black" stroke-width="0.5" />
    <path d="M171.83 54.46V54.25" stroke="black" stroke-width="0.5" />
    <path d="M171.83 54.2498V54.0498" stroke="black" stroke-width="0.5" />
    <path d="M171.83 54.0501V53.8501" stroke="black" stroke-width="0.5" />
    <path d="M171.83 53.8501V53.6401" stroke="black" stroke-width="0.5" />
    <path d="M171.83 53.6402V53.4502" stroke="black" stroke-width="0.5" />
    <path d="M171.83 53.45L171.84 53.25" stroke="black" stroke-width="0.5" />
    <path d="M171.84 53.2498V53.0698" stroke="black" stroke-width="0.5" />
    <path d="M171.84 53.0701V52.8901" stroke="black" stroke-width="0.5" />
    <path d="M171.84 52.89L171.85 52.73" stroke="black" stroke-width="0.5" />
    <path
      d="M171.85 52.7301L171.86 52.5801"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.86 52.5802V52.4502" stroke="black" stroke-width="0.5" />
    <path
      d="M171.86 52.4501L171.87 52.3301"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.87 52.33L171.88 52.23" stroke="black" stroke-width="0.5" />
    <path
      d="M171.88 52.2299L171.89 52.1299"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M171.89 52.1301L171.9 52.0601" stroke="black" stroke-width="0.5" />
    <path d="M171.9 52.0602L171.92 51.9902" stroke="black" stroke-width="0.5" />
    <path
      d="M171.92 51.9902L171.93 51.9302"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M171.93 51.9299L171.95 51.8799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M171.95 51.8799L171.97 51.8398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M171.97 51.8401L171.99 51.8101"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M171.99 51.8098L172.02 51.7798"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.02 51.7798L172.05 51.7598"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.05 51.76L172.08 51.75" stroke="black" stroke-width="0.5" />
    <path
      d="M172.08 51.7502L172.11 51.7402"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.11 51.74L172.15 51.73" stroke="black" stroke-width="0.5" />
    <path
      d="M172.15 51.7302L172.19 51.7202"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.19 51.72L172.23 51.71" stroke="black" stroke-width="0.5" />
    <path d="M172.23 51.71H172.28" stroke="black" stroke-width="0.5" />
    <path
      d="M172.28 51.7102L172.33 51.7002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.33 51.7002H172.38" stroke="black" stroke-width="0.5" />
    <path d="M172.38 51.7002H172.44" stroke="black" stroke-width="0.5" />
    <path
      d="M172.44 51.6999L172.49 51.6899"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.49 51.6899H172.55" stroke="black" stroke-width="0.5" />
    <path d="M172.55 51.6899H172.62" stroke="black" stroke-width="0.5" />
    <path d="M172.62 51.6899H172.68" stroke="black" stroke-width="0.5" />
    <path d="M172.68 51.6899H172.75" stroke="black" stroke-width="0.5" />
    <path d="M172.75 51.6899H172.82" stroke="black" stroke-width="0.5" />
    <path
      d="M172.82 51.6899L172.89 51.6999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.89 51.7002L172.96 51.7102"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M172.96 51.71L173.03 51.72" stroke="black" stroke-width="0.5" />
    <path d="M173.03 51.7202L173.1 51.7402" stroke="black" stroke-width="0.5" />
    <path d="M173.1 51.7402L173.17 51.7702" stroke="black" stroke-width="0.5" />
    <path d="M173.17 51.77L173.24 51.8" stroke="black" stroke-width="0.5" />
    <path d="M173.24 51.7998L173.3 51.8398" stroke="black" stroke-width="0.5" />
    <path d="M173.3 51.8398L173.36 51.8799" stroke="black" stroke-width="0.5" />
    <path
      d="M173.36 51.8799L173.42 51.9299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.42 51.9302L173.48 51.9802"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.48 51.98L173.53 52.04" stroke="black" stroke-width="0.5" />
    <path d="M173.53 52.04L173.58 52.1001" stroke="black" stroke-width="0.5" />
    <path
      d="M173.58 52.1001L173.63 52.1601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.63 52.1602L173.67 52.2202"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.67 52.2202L173.7 52.2902" stroke="black" stroke-width="0.5" />
    <path d="M173.7 52.29L173.74 52.3501" stroke="black" stroke-width="0.5" />
    <path
      d="M173.74 52.3501L173.77 52.4201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.77 52.4199L173.79 52.4899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.79 52.4902L173.82 52.5602"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.82 52.5601L173.84 52.6301"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.84 52.6299L173.86 52.6999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.86 52.7002L173.88 52.7702"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.88 52.77L173.9 52.84" stroke="black" stroke-width="0.5" />
    <path d="M173.9 52.8398L173.92 52.9198" stroke="black" stroke-width="0.5" />
    <path
      d="M173.92 52.9199L173.93 52.9899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.93 52.9902L173.95 53.0702"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.95 53.0698L173.96 53.1398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.96 53.1401L173.98 53.2301"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.98 53.23L173.99 53.31" stroke="black" stroke-width="0.5" />
    <path d="M173.99 53.3101L174 53.4001" stroke="black" stroke-width="0.5" />
    <path d="M174 53.3999L174.01 53.4899" stroke="black" stroke-width="0.5" />
    <path
      d="M174.01 53.4902L174.02 53.5802"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M174.02 53.5801L174.03 53.6701"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.03 53.6699V53.7699" stroke="black" stroke-width="0.5" />
    <path d="M174.03 53.77L174.04 53.86" stroke="black" stroke-width="0.5" />
    <path d="M174.04 53.8599V53.9599" stroke="black" stroke-width="0.5" />
    <path d="M174.04 53.96V54.06" stroke="black" stroke-width="0.5" />
    <path d="M174.04 54.0601V54.1501" stroke="black" stroke-width="0.5" />
    <path d="M174.04 54.1499V54.2499" stroke="black" stroke-width="0.5" />
    <path d="M174.04 54.25V54.34" stroke="black" stroke-width="0.5" />
    <path
      d="M174.04 54.3398L174.03 54.4398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.03 54.4399V54.5299" stroke="black" stroke-width="0.5" />
    <path
      d="M174.03 54.5298L174.02 54.6198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M174.02 54.6201L174.01 54.7001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.01 54.7002L174 54.7802" stroke="black" stroke-width="0.5" />
    <path d="M174 54.7798L173.99 54.8598" stroke="black" stroke-width="0.5" />
    <path
      d="M173.99 54.8599L173.98 54.9299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.98 54.9302L173.97 55.0002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.97 55L173.96 55.07" stroke="black" stroke-width="0.5" />
    <path
      d="M173.96 55.0698L173.94 55.1298"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.94 55.1299L173.93 55.1899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.93 55.1899L173.91 55.2499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.91 55.25L173.89 55.31" stroke="black" stroke-width="0.5" />
    <path
      d="M173.89 55.3101L173.88 55.3701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.88 55.3701L173.86 55.4401"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.86 55.4399L173.84 55.4899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.84 55.4902L173.82 55.5502"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.82 55.5498L173.8 55.6098" stroke="black" stroke-width="0.5" />
    <path d="M173.8 55.6099L173.78 55.6599" stroke="black" stroke-width="0.5" />
    <path
      d="M173.78 55.6602L173.77 55.7102"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.77 55.71L173.75 55.75" stroke="black" stroke-width="0.5" />
    <path d="M172.13 43.23H178.22" stroke="black" stroke-width="0.5" />
    <path d="M178.22 43.23V49.45" stroke="black" stroke-width="0.5" />
    <path d="M178.22 49.4502H172.13" stroke="black" stroke-width="0.5" />
    <path d="M172.13 49.45V43.23" stroke="black" stroke-width="0.5" />
    <path
      d="M177.63 46.3398L177.44 47.2798"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.44 47.2798L176.91 48.0698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.91 48.0698L176.11 48.5998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.11 48.6001L175.18 48.7901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.18 48.7901L174.24 48.6001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M174.24 48.5998L173.44 48.0698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.44 48.0698L172.91 47.2798"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.91 47.2798L172.73 46.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.73 46.3399L172.91 45.3999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M172.91 45.3999L173.44 44.6099"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M173.44 44.6101L174.24 44.0801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M174.24 44.0801L175.18 43.8901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M175.18 43.8901L176.11 44.0801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.11 44.0801L176.91 44.6101"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.91 44.6099L177.44 45.3999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.44 45.3999L177.63 46.3399"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M177.15 46.3398L176.96 47.1998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.96 47.2002L176.41 47.8802"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.41 47.8799L175.62 48.2699"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M175.62 48.27H174.74" stroke="black" stroke-width="0.5" />
    <path
      d="M174.74 48.2699L173.94 47.8799"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M173.94 47.8802L173.4 47.2002" stroke="black" stroke-width="0.5" />
    <path d="M173.4 47.1998L173.2 46.3398" stroke="black" stroke-width="0.5" />
    <path d="M173.2 46.34L173.4 45.48" stroke="black" stroke-width="0.5" />
    <path d="M173.4 45.4798L173.94 44.7998" stroke="black" stroke-width="0.5" />
    <path
      d="M173.94 44.8002L174.74 44.4102"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.74 44.4102H175.62" stroke="black" stroke-width="0.5" />
    <path
      d="M175.62 44.4102L176.41 44.8002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.41 44.7998L176.96 45.4798"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.96 45.48L177.15 46.34" stroke="black" stroke-width="0.5" />
    <path
      d="M175.73 46.3398L175.57 46.7298"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M175.57 46.73L175.18 46.9" stroke="black" stroke-width="0.5" />
    <path d="M175.18 46.9L174.78 46.73" stroke="black" stroke-width="0.5" />
    <path
      d="M174.78 46.7298L174.62 46.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M174.62 46.3402L174.78 45.9502"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M174.78 45.95L175.18 45.79" stroke="black" stroke-width="0.5" />
    <path d="M175.18 45.79L175.57 45.95" stroke="black" stroke-width="0.5" />
    <path
      d="M175.57 45.9502L175.73 46.3402"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M186.35 57.9402V52.9702" stroke="black" stroke-width="0.5" />
    <path d="M186.85 57.9402V53.1802" stroke="black" stroke-width="0.5" />
    <path
      d="M186.35 52.9701L190.81 48.6201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M186.85 53.1801L191.02 49.1201"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M190.81 48.6201H195.6" stroke="black" stroke-width="0.5" />
    <path d="M191.02 49.1201H195.6" stroke="black" stroke-width="0.5" />
    <path
      d="M192.58 54.4302L192.42 54.8202"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.42 54.8198L192.02 54.9898"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.02 54.9898L191.63 54.8198"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M191.63 54.8202L191.47 54.4302"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M191.47 54.43L191.63 54.04" stroke="black" stroke-width="0.5" />
    <path
      d="M191.63 54.0399L192.02 53.8799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.02 53.8799L192.42 54.0399"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M192.42 54.04L192.58 54.43" stroke="black" stroke-width="0.5" />
    <path
      d="M186.85 57.9402L191.57 54.7402"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M191.47 54.4302L186.85 53.1802"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.02 53.8801L191.02 49.1201"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M192.45 54.0701L195.6 49.1201" stroke="black" stroke-width="0.5" />
    <path d="M192.43 54.7998L195.6 57.9398" stroke="black" stroke-width="0.5" />
    <path d="M195.6 57.9399L192.51 55.9399" stroke="black" stroke-width="0.5" />
    <path
      d="M192.51 55.9402L193.56 54.9902"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M193.56 54.9902L195.6 57.9402" stroke="black" stroke-width="0.5" />
    <path d="M3.3999 65.9302V66.9702" stroke="black" stroke-width="0.5" />
    <path d="M3.40027 66.9702H3.24023" stroke="black" stroke-width="0.5" />
    <path d="M3.24023 66.9702V65.9302" stroke="black" stroke-width="0.5" />
    <path d="M3.24023 65.9302H3.40027" stroke="black" stroke-width="0.5" />
    <path d="M3.3999 58V56.96" stroke="black" stroke-width="0.5" />
    <path d="M3.40027 56.96H3.24023" stroke="black" stroke-width="0.5" />
    <path d="M3.24023 56.96V58" stroke="black" stroke-width="0.5" />
    <path d="M3.24023 58H3.40027" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 62.1299V66.2299" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 66.23L2.86011 66.26" stroke="black" stroke-width="0.5" />
    <path
      d="M2.85986 66.2598L2.88989 66.2798"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.89014 66.2798L2.9201 66.2898"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.91992 66.29H3.23993" stroke="black" stroke-width="0.5" />
    <path d="M3.24023 66.29V66.62" stroke="black" stroke-width="0.5" />
    <path d="M3.24011 66.6201H2.8501" stroke="black" stroke-width="0.5" />
    <path
      d="M2.84991 66.6198L2.68994 66.5698"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.68982 66.5702L2.56982 66.4502"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.57025 66.45L2.53027 66.29" stroke="black" stroke-width="0.5" />
    <path d="M2.53027 66.2899V62.1299" stroke="black" stroke-width="0.5" />
    <path d="M2.53027 62.1301V57.6401" stroke="black" stroke-width="0.5" />
    <path d="M2.53027 57.64L2.57025 57.48" stroke="black" stroke-width="0.5" />
    <path
      d="M2.56982 57.4799L2.68982 57.3599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.68994 57.3601L2.84991 57.3101"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.8501 57.3101H3.24011" stroke="black" stroke-width="0.5" />
    <path d="M3.24023 57.3101V57.6401" stroke="black" stroke-width="0.5" />
    <path d="M3.23993 57.6401H2.91992" stroke="black" stroke-width="0.5" />
    <path
      d="M2.9201 57.6401L2.89014 57.6501"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.88989 57.6499L2.85986 57.6699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.86011 57.6699L2.8501 57.6999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.8501 57.7002V62.1302" stroke="black" stroke-width="0.5" />
    <path
      d="M2.31006 65.5398L2.36005 65.5298"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.35986 65.5298H2.40985" stroke="black" stroke-width="0.5" />
    <path d="M2.41016 65.53L2.4502 65.52" stroke="black" stroke-width="0.5" />
    <path d="M2.4502 65.52H2.50018" stroke="black" stroke-width="0.5" />
    <path d="M2.5 65.5198L2.53998 65.5098" stroke="black" stroke-width="0.5" />
    <path d="M2.54004 65.51L2.57007 65.5" stroke="black" stroke-width="0.5" />
    <path
      d="M2.56982 65.5002L2.6098 65.4902"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.60986 65.4902L2.63989 65.4702"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.64014 65.4702L2.6701 65.4502"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.66992 65.4502L2.68994 65.4302"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.68994 65.4301L2.71991 65.3901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.71973 65.3901L2.73975 65.3501"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.74023 65.3498L2.75024 65.2998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.75 65.3L2.77002 65.25" stroke="black" stroke-width="0.5" />
    <path
      d="M2.77002 65.2502L2.78003 65.1802"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.78027 65.1801L2.79022 65.1001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.79004 65.0998L2.80005 65.0098"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.7998 65.0099L2.80981 64.8999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.81006 64.8998L2.82007 64.7798"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.81982 64.7799L2.82983 64.6499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.83008 64.65V64.5" stroke="black" stroke-width="0.5" />
    <path
      d="M2.83008 64.4998L2.84009 64.3398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.83984 64.3401V64.1602" stroke="black" stroke-width="0.5" />
    <path d="M2.83984 64.16L2.84979 63.98" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 63.98V63.79" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 63.7898V63.5898" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 63.5901V63.3901" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 63.3902V63.1802" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 63.18V62.98" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 62.9798V62.7798" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 62.7801V62.5801" stroke="black" stroke-width="0.5" />
    <path d="M2.8501 62.5801V62.3901" stroke="black" stroke-width="0.5" />
    <path
      d="M2.84979 62.3902L2.83984 62.2002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.83984 62.1998V62.0298" stroke="black" stroke-width="0.5" />
    <path
      d="M2.84009 62.0299L2.83008 61.8599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.82983 61.8602L2.81982 61.7202"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.81982 61.7201V61.5801" stroke="black" stroke-width="0.5" />
    <path d="M2.82007 61.58L2.81006 61.46" stroke="black" stroke-width="0.5" />
    <path
      d="M2.80981 61.4599L2.7998 61.3599"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.80005 61.36L2.79004 61.27" stroke="black" stroke-width="0.5" />
    <path
      d="M2.79022 61.2699L2.78027 61.1899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.77979 61.1901L2.75977 61.1201"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.76001 61.1201L2.75 61.0601" stroke="black" stroke-width="0.5" />
    <path d="M2.75 61.0598L2.72998 61.0098" stroke="black" stroke-width="0.5" />
    <path
      d="M2.72992 61.0102L2.70996 60.9702"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.70996 60.9699L2.68994 60.9399"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.69019 60.9399L2.66016 60.9199"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.65985 60.9199L2.62988 60.8999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.63013 60.8999L2.6001 60.8799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.59979 60.8801L2.56982 60.8701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.57025 60.8699L2.53027 60.8599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M2.53027 60.8601L2.49023 60.8501"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.49017 60.8501H2.4502" stroke="black" stroke-width="0.5" />
    <path
      d="M2.44989 60.8499L2.3999 60.8398"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.40015 60.8398H2.3501" stroke="black" stroke-width="0.5" />
    <path
      d="M2.34979 60.8401L2.2998 60.8301"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M2.62988 48.52H2.99988" stroke="black" stroke-width="0.5" />
    <path d="M2.62988 49.62V48.52" stroke="black" stroke-width="0.5" />
    <path d="M2.99988 49.6201H2.62988" stroke="black" stroke-width="0.5" />
    <path d="M3 48.52V49.62" stroke="black" stroke-width="0.5" />
    <path d="M3.3999 46.8398V51.2999" stroke="black" stroke-width="0.5" />
  </svg>
</template>
