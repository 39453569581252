<template>
  <v-container fluid>
    <router-view class="mt-2 ml-n4" />
  </v-container>
</template>

<script>
export default {
  name: 'StrategiesView',
};
</script>
