<template>
  <v-card class="measure-bar">
    <v-app-bar elevation="1" class="px-11">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        My Reports
      </v-app-bar-title>
      <v-text-field
        class="mt-1"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        bg-color="white"
        variant="outlined"
        density="compact"
        :placeholder="placeholder"
        :clearable="true"
        @click:clear="handleSearchInput"
        @update:modelValue="handleSearchInput"
      />
      <v-spacer />
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'ReportsListBar',

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Report';
    },
  },

  methods: {
    ...mapMutations({
      setReportSearchTerm: 'ViewSupportStore/ReportsStore/REPORT_SEARCH_TERM',
    }),

    handleSearchInput(value) {
      if (typeof value === 'object') {
        this.setReportSearchTerm('');
      } else {
        this.setReportSearchTerm(value);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.measure-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
