<template>
  <svg
    viewBox="0 0 130 114"
    xmlns="http://www.w3.org/2000/svg"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
      id="interactive-group"
    >
      <path
        d="M90 113.5H128V82.5H119V75H129C127.981 69.3075 126.121 67.24 120 66C125.642 64.664 127.731 62.9123 129 57.5H119V50.5H128V24H121.5V1L105 4L95 7L91.5 8.5V16H1V49.5H19C20.1969 43.3748 21.9392 41.2595 27.5 40.5V49.5H31.5V40.5C38.237 40.9704 39.217 43.7259 40 49.5H58V51.5H68.5C67.5357 57.5081 66.0785 60.2628 58 61.5V71H82.5V111H90V113.5Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-group {
  transition: fill-opacity 0.2s;
}
#interactive-group:hover {
  fill-opacity: 0.5;
}
</style>
