<template>
  <v-sheet flat border class="pa-4 col-sm-12">
    <p class="text-subtitle-1 pt-3 text-primary mb-3">
      <span class="font-weight-bold">Energy - End Use Power</span>
      - Input values at execution time
    </p>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="localInputData.CurrentDateTime"
          :rules="[isDate]"
          variant="outlined"
          density="compact"
          label="Current Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="localInputData.ReadingDateTime"
          :rules="[isDate]"
          variant="outlined"
          density="compact"
          label="Reading Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="localInputData.DelayInMinutes"
          :rules="[isNumberGreaterThanZero]"
          variant="outlined"
          density="compact"
          label="Delay in Minutes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-text-field
          v-model="localInputData.ElectricityValueKw"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Site Electricity kW"
        />
      </v-col>
      <v-col sm="6">
        <v-text-field
          v-model="localInputData.SolarValueKw"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Site Solar kW"
        />
      </v-col>
    </v-row>
    <v-row v-for="endUse in endUses" :key="endUse.Id">
      <v-col cols="6">
        <v-text-field
          v-model="endUse.Value"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          :label="`Power kW Value for End Use: ${endUse.Name}`"
          @update:model-value="handleEndUseValueChange(endUse)"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import {
  isNumber,
  isNumberGreaterThanZero,
  hasValue,
  isDate,
} from '@/Core.Patterns/Factory/validationFactory.ts';

export default {
  name: 'EnergyEndUsePowerV3Input',
  data() {
    return {
      devices: [],
      localInputData: {
        ...this.InputData,
        LatestValues: { ...this.InputData.LatestValues },
      },
    };
  },

  computed: {
    endUses() {
      // console.debug(`DataSources`, this.DataSources);
      const safeGet = (id) => {
        if (this.InputData?.LatestValues == undefined) return undefined;
        return this.InputData.LatestValues[id];
      };
      const endUses = this.DataSources.find(
        (ds) => ds.Key === 'EndUses' // Change this when ready to use the real data: EndUses
      )?.Value.map((v) => ({ Id: v.Id, Name: v.Name, Value: safeGet(v.Id) }));
      return endUses;
    },
  },

  props: {
    InputData: {
      type: Object,
      required: true,
      LatestValues: {
        type: Object,
      },
    },
    DataSources: {
      type: Array,
      required: true,
    },
  },

  methods: {
    isNumber,
    isNumberGreaterThanZero,
    hasValue,
    isDate,

    handleEndUseValueChange(endUse) {
      // console.log(`Value changed for ${endUse.Name}:`, endUse);
      const endUseValue = parseFloat(endUse.Value);
      if (!isNaN(endUseValue)) {
        if (this.InputData?.LatestValues == undefined)
          this.localInputData.LatestValues = {};
        this.localInputData.LatestValues[endUse.Id] = endUseValue;
        // console.debug(`setting InputData.Latest value for ${endUse.Id} to ${endUseValue}`, this.InputData);
      }
      this.$emit('update:model', this.localInputData);
    },
  },
};
</script>
