import actions from '@/Core.Service.Domain/ViewSupport/Portfolio/Store/actions.js';
import getters from '@/Core.Service.Domain/ViewSupport/Portfolio/Store/getters.js';
import mutations from '@/Core.Service.Domain/ViewSupport/Portfolio/Store/mutations.js';

const state = {
  siteSearchTerm: '',
  portfolioSites: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
