<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="searchTerm"
          prepend-icon="mdi-magnify"
          label="Search History"
          clearable
        />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="SettingsHistoryList"
      :items-per-page="10"
      :search="searchTerm"
      :loading="!SettingsHistoryList.length"
      :header-props="headerProps"
      :footer-props="footerProps"
      class="elevation-5"
    >
      <!--Headers slot-->
      <template v-slot:headers="props">
        <tr>
          <th
            v-for="(header, i) in props.headers"
            :key="i"
            class="px-0"
            :class="header.class"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <!--Success slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              <v-icon :color="handleIconColor(item)">
                {{ handleSuccess(item) }}
              </v-icon>
            </span>
          </td>
          <!--Message slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ handleMessage(item) }}
            </span>
          </td>
          <!--User Id slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.lastModifiedUserId }}
            </span>
          </td>
          <!--Last Modified Date Time slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{
                dateTimeToUSFormatFactory(item.lastModifiedDateTime, dateFormat)
              }}
            </span>
          </td>
          <!--Response Date Time slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ handleResponseDateTime(item) }}
            </span>
          </td>
          <!--Output slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.output }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';

export default {
  name: 'PowerControlHistoryList',

  props: {
    SettingsHistoryList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dateFormat: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      },
      searchTerm: '',
      headerProps: {
        'sort-icon': '',
      },
      headers: [
        {
          text: 'Success',
          align: 'start',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'success',
          sortable: true,
        },
        {
          text: 'Message',
          align: 'start',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'message',
          sortable: true,
        },
        {
          text: 'User Id',
          align: 'start',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'lastModifiedUserId',
          sortable: true,
        },
        {
          text: 'Last Modified',
          align: 'start',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'lastModifiedDateTime',
          sortable: true,
        },
        {
          text: 'Response Date',
          align: 'start',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'responseDateTime',
          sortable: true,
        },
        {
          text: 'Output',
          align: 'start',
          class: 'text-primary font-weight-bold text-subtitle-1',
          value: 'output',
          sortable: true,
        },
      ],
      footerProps: {
        'items-per-page-options': [5, 10, 20, 30, 40, 50],
      },
      filteredItems: [],
    };
  },

  methods: {
    dateTimeToUSFormatFactory,

    handleSuccess(item) {
      if (item.success === null) return 'mdi-set-none';
      return item.success ? 'mdi-check' : 'mdi-alert-outline';
    },

    handleIconColor(item) {
      if (item.success === null) return 'disabled';
      return item.success ? 'greenGraph' : 'heatColor';
    },

    handleResponseDateTime(item) {
      if (item.responseDateTime === null) return 'N/A';
      return this.dateTimeToUSFormatFactory(
        item.responseDateTime,
        this.dateFormat
      );
    },

    handleMessage(item) {
      if (item.message === null) return 'N/A';
      return item.message;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-data-table) {
  color: $primary;
  thead th {
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid $primary !important;
    text-transform: uppercase;
  }
}

.v-data-table {
  tr:nth-child(odd) {
    background-color: #f5f5f5;
  }

  td:nth-child(odd) {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }
}
</style>
