<template>
  <v-container fluid class="mb-n1 px-1">
    <v-window v-model="onboarding">
      <v-window-item v-for="(slide, key) in generateMappingToSlider" :key="key">
        <template v-if="Array.isArray(slide)">
          <v-row class="pa-3 d-flex justify-space-between flex-row">
            <v-card
              v-for="(item, index) in slide"
              :key="index"
              variant="outlined"
              :height="cardHeight"
              :class="rounded"
              width="49%"
            >
              <v-row
                align="center"
                justify="center"
                class="mt-5 d-flex justify-center flex-column"
              >
                <v-icon :color="item.iconColor" class="mb-1">
                  {{ item.icon }}
                </v-icon>
                <span class="text-primary text-caption font-weight-bold">
                  {{ item.title }}
                </span>
                <div class="d-flex justify-center flex-row">
                  <span class="text-primary text-h3 font-weight-bold">
                    {{ item.value }}
                  </span>
                  <span
                    class="text-primary text-subtitle-2 font-weight-bold mt-1"
                  >
                    {{ item.unit }}
                  </span>
                </div>
              </v-row>
            </v-card>
          </v-row>
        </template>
        <template v-else>
          <v-card variant="outlined" :height="cardHeight" :class="rounded">
            <v-row
              align="center"
              justify="center"
              class="mt-5 d-flex justify-center flex-column"
            >
              <v-icon :color="slide.iconColor" class="mb-1">
                {{ slide.icon }}
              </v-icon>
              <span class="text-primary text-caption font-weight-bold">
                {{ slide.title }}
              </span>
              <div class="d-flex justify-center flex-row">
                <span class="text-primary text-h3 font-weight-bold">
                  {{ slide.value }}
                </span>
                <span
                  class="text-primary text-subtitle-2 font-weight-bold mt-1"
                >
                  {{ slide.unit }}
                </span>
              </div>
            </v-row>
          </v-card>
        </template>
      </v-window-item>
    </v-window>
    <!--Slider controls-->
    <v-card-actions class="justify-space-between px-1">
      <v-btn
        @click="prev"
        variant="flat"
        base-color="primary"
        icon="true"
        size="x-small"
        :disabled="onboarding === 0"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-item-group v-model="onboarding" class="text-center" mandatory>
        <v-item
          v-for="n in generateMappingToSlider.length"
          :key="`btn-${n}`"
          v-slot="{ active, toggle }"
        >
          <v-btn
            :model-value="active"
            icon
            @click="toggle"
            height="10"
            width="10"
            class="mx-1"
            selected-class="primary"
            :disabled="onboarding === n - 1"
          >
            <v-icon size="x-small">mdi-record</v-icon>
          </v-btn>
        </v-item>
      </v-item-group>
      <v-btn
        @click="next"
        variant="flat"
        base-color="primary"
        icon="true"
        size="x-small"
        :disabled="onboarding === generateMappingToSlider.length - 1"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { formatNumberToUS } from '@/Core.Patterns/Factory';

export default {
  name: 'SliderPanel',

  props: {
    siteInfo: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    onboarding: 0,
    cardHeight: 136,
    cardWidth: 338,
    rounded: 'rounded',
  }),

  computed: {
    getWeatherDataModel() {
      return this.siteInfo.siteWeatherAndEnergyDataResourceModel;
    },

    generateMappingToSlider() {
      const mapping = [];
      if (this.getWeatherDataModel.peakDemand) {
        const obj = {
          icon: 'mdi-lightning-bolt-outline',
          iconColor: 'greenGraph',
          title: 'Daily Peak Demand',
          unit: 'kW',
          value: formatNumberToUS(
            Math.round(Number(this.getWeatherDataModel.peakDemand))
          ),
        };
        mapping.push(obj);
      }
      if (this.getWeatherDataModel.peakSolar) {
        const obj = {
          icon: 'mdi-sun-wireless-outline',
          iconColor: 'solarYellow',
          title: 'Daily Peak Solar',
          unit: 'kW',
          value: formatNumberToUS(
            Math.round(Number(this.getWeatherDataModel.peakSolar))
          ),
        };
        mapping.push(obj);
      }

      if (this.siteInfo.indoorAQI) {
        const obj = {
          icon: 'mdi-air-purifier',
          iconColor: 'intechBlue',
          title: 'Indoor AQI',
          unit: '',
          value: formatNumberToUS(Math.round(Number(this.siteInfo.indoorAQI))),
        };
        mapping.push(obj);
      }

      if (mapping.length === 0) {
        mapping.push({
          icon: this.getWeatherIcon.icon,
          iconColor: this.getWeatherIcon.color,
          title: this.getWeatherDataModel.weatherSummary,
          unit: '°F',
          value: formatNumberToUS(
            Math.round(Number(this.getWeatherDataModel.temperature))
          ),
        });
      }

      const temp = [[]];
      for (let i = 0; i < mapping.length - 1; i++) {
        if (
          mapping[i].title === 'Daily Peak Demand' &&
          mapping[i + 1].title === 'Daily Peak Solar'
        ) {
          temp[0].push(mapping[i], mapping[i + 1]);
          mapping.splice(i, 2, temp[0]);
        }
      }

      return mapping;
    },

    weatherIcons() {
      const info = [
        {
          icon: 'weather-rainy',
          color: 'intechBlue',
          weatherIcon: 'rain',
        },
        {
          icon: 'weather-sunny',
          color: 'solarYellow',
          weatherIcon: 'sunny',
        },
        {
          icon: 'weather-cloudy',
          color: 'intechBlue',
          weatherIcon: 'cloudy',
        },
        {
          icon: 'weather-partly-cloudy',
          color: 'intechBlue',
          weatherIcon: 'partly-cloudy-day',
        },
        {
          icon: 'weather-night-partly-cloudy',
          color: 'intechBlue',
          weatherIcon: 'partly-cloudy-night',
        },
        {
          icon: 'weather-snowy',
          color: 'intechBlue',
          weatherIcon: 'snow',
        },
        {
          icon: 'weather-sunny',
          color: 'solarYellow',
          weatherIcon: 'clear-day',
        },
        {
          icon: 'weather-night',
          color: 'intechBlue',
          weatherIcon: 'clear-night',
        },
        {
          icon: 'weather-fog',
          color: 'intechBlue',
          weatherIcon: 'fog',
        },
        {
          icon: 'weather-windy',
          color: 'intechBlue',
          weatherIcon: 'wind',
        },
      ];
      return info;
    },

    getWeatherIcon() {
      const { weatherIcon } = this.getWeatherDataModel;
      const icon = this.weatherIcons.find(
        (icon) => icon.weatherIcon === weatherIcon
      );
      return {
        icon: icon ? `mdi-${icon.icon}` : 'mdi-information-off',
        color: icon ? icon.color : 'intechBlue',
      };
    },
  },

  methods: {
    next() {
      this.onboarding =
        this.onboarding + 1 === this.generateMappingToSlider.length
          ? 0
          : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0
          ? this.generateMappingToSlider.length - 1
          : this.onboarding - 1;
    },
    formatNumberToUS,
  },
};
</script>
