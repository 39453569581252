<template>
  <div>
    <h4 class="text-primary mb-2">Create New Message</h4>
    <v-row class="mb-n9">
      <v-col>
        <v-text-field
          label="Message Title"
          color="primary"
          variant="outlined"
          v-model="messageTitle"
        />
      </v-col>
    </v-row>
    <v-row class="mb-n9">
      <v-col>
        <v-textarea
          color="primary"
          variant="outlined"
          name="message"
          label="Message to the Users"
          v-model="messageBody"
        />
      </v-col>
    </v-row>
    <v-row class="mb-n9">
      <v-col cols="6">
        <v-select
          color="primary"
          class="mb-7"
          v-model="selectedType"
          :items="messagesTypesList"
          item-title="messageCenterMessageTypeName"
          item-value="messageCenterMessageTypeId"
          label="Message Type"
          variant="outlined"
          chips
          clearable
          hint="Select the message type"
          persistent-hint
        >
          <template v-slot:chip="{ item }">
            <v-chip closable color="primary">
              <span class="text-primary">{{ item.title }}</span>
            </v-chip>
          </template>
        </v-select>
        <v-autocomplete
          color="primary"
          v-model="selectedUsers"
          :items="usersList"
          item-title="fullName"
          item-value="userId"
          label="Users"
          multiple
          variant="outlined"
          chips
          clearable
          hint="Select users who will receive the message"
          persistent-hint
          return-object
          @click:clear="() => (filteredItems = [])"
        >
          <template v-slot:chip="{ item, index }">
            <v-chip
              v-if="index <= 2"
              closable
              @click:close="removeUser(item)"
              color="primary"
            >
              <span class="text-primary">{{ item.title }}</span>
            </v-chip>
            <span
              v-if="index === selectedUsers.length - 1 && index > 2"
              class="text-grey text-caption"
            >
              (+{{ selectedUsers.length - 1 }} others)
            </span>
          </template>
          <template v-slot:append>
            <v-btn
              :disabled="selectedUsers.length > 0 || !usersList.length"
              size="small"
              color="primary"
              class="rounded-2"
              variant="flat"
              :loading="!usersList.length"
              @click="selectAllUsers"
            >
              <div
                class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
              >
                {{
                  filteredItems.length
                    ? `Select ${filteredItems.length} Users`
                    : 'Select All Users'
                }}
              </div>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-date-picker
          v-model="selectedDate"
          :show-current="currentDate"
          color="primary"
          width="100%"
          show-adjacent-months
        >
          <template v-slot:title>
            <span class="text-subtitle-1"
              >Select Date to Expire the Message</span
            >
          </template>
          <template v-slot:header="{ header }">
            <div class="px-6 pb-3 pt-2">
              <span class="text-h6">{{
                `${header} ${getCurrentYearAsString()}`
              }}</span>
            </div>
          </template>
        </v-date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-btn
          :loading="isLoading"
          :disabled="isLoading || !isFormDirty || !isFormValid"
          color="primary"
          class="rounded-2 mr-2"
          variant="flat"
          @click="createMessage"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
          >
            Send Message
          </div>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          :loading="isLoading"
          :disabled="isLoading || !isFormDirty"
          class="rounded-2"
          color="warning"
          variant="flat"
          @click="clearForm"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
          >
            Reset Form
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import API from '@/Core.Service.Domain/MessageCenter/Management/API';

// Store
const store = useStore();
const usersList = computed(
  () => store.getters['MessageCenterManagementStore/getUsersList']
);
const messagesTypesList = computed(
  () => store.getters['MessageCenterManagementStore/getMessagesTypesList']
);

// Reactive variables<Refs>
const currentDate = ref(new Date());
const selectedDate = ref(new Date());
const isLoading = ref(false);
const selectedUsers = ref<{ userId: number; fullName: string }[]>([]);
const filteredItems = ref([]);
const messageBody = ref('');
const messageTitle = ref('');
const selectedType = ref(null);

// Computed
const isFormDirty = computed(() => {
  return (
    selectedUsers.value.length > 0 ||
    messageBody.value.length > 0 ||
    messageTitle.value.length > 0 ||
    selectedType.value !== null
  );
});

const isFormValid = computed(() => {
  return (
    messageBody.value.length > 0 &&
    messageTitle.value.length > 0 &&
    selectedType.value !== null &&
    selectedDate.value !== null &&
    selectedDate.value !== undefined
  );
});

// Store actions
const getAllMessagesFromMessageCenter = () => {
  store.dispatch(
    'MessageCenterManagementStore/getAllMessagesFromMessageCenter'
  );
};
const getMessagesTypesList = () => {
  store.dispatch('MessageCenterManagementStore/getMessagesTypesList');
};
const setUnreadMessagesCount = () => {
  store.dispatch('MessageCenterUsersStore/setUnreadMessagesCount');
};

// Lifecycle Hooks
onMounted(async () => {
  if (isFormDirty.value) {
    clearForm();
  }
  getAllMessagesFromMessageCenter();
  getMessagesTypesList();
});

// Methods
const removeUser = (item: { value: number }) => {
  const index = selectedUsers.value.findIndex(
    (user) => user.userId === item.value
  );
  if (index > -1) {
    selectedUsers.value.splice(index, 1);
  }
  if (filteredItems.value.length) {
    filteredItems.value = filteredItems.value.filter(
      (user: { userId: number }) => user.userId !== item.value
    );
  }
};

const selectAllUsers = () => {
  if (filteredItems.value.length) {
    selectedUsers.value = filteredItems.value.map((userId: number) => ({
      userId,
      fullName: '',
    }));
  } else {
    selectedUsers.value = usersList.value.map(
      (item: { userId: number }) => item.userId
    );
  }
};

const createMessage = async () => {
  isLoading.value = true;
  try {
    const response = await API.messageCenterCreateMessage({
      MessageCenterMessageLabel: messageTitle.value,
      MessageCenterMessageValue: messageBody.value,
      MessageCenterMessageTypeId:
        selectedType.value !== null ? selectedType.value : '',
      MessageCenterMessageExpirationDateTimeOffset: convertDateToUSFormat(
        selectedDate.value
      ),
    });
    if (selectedUsers.value.length) {
      await API.associateUserToMessage({
        MessageCenterMessageId: response,
        UserIds: getUserIdsFromSelectedUsers(),
      });
    }

    getAllMessagesFromMessageCenter();
    setUnreadMessagesCount();
    clearForm();
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const clearForm = () => {
  selectedUsers.value = [];
  filteredItems.value = [];
  messageBody.value = '';
  messageTitle.value = '';
  selectedType.value = null;
  selectedDate.value = new Date();
};

const convertDateToUSFormat = (date: Date) => {
  const newDate = new Date(date);
  return `${newDate.getFullYear()}-${
    newDate.getMonth() + 1
  }-${newDate.getDate()}`;
};

const getUserIdsFromSelectedUsers = () => {
  return selectedUsers.value.map((user) => {
    return user.userId;
  });
};

const getCurrentYearAsString = (): string => {
  return new Date().getFullYear().toString();
};
</script>
<style scoped lang="scss">
.v-date-picker :deep(.v-date-picker__title) {
  text-transform: none;
}

.v-picker :deep(.v-picker-title) {
  padding-top: 14px;
  padding-bottom: 0;
}
</style>
