<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="text-h5">Validating model parameters</v-card-title>
      <v-card-text>
        <!-- Validate StepperIndex -->
        <v-stepper v-model="vSteps" vertical>
          <!--Validate Name-->
          <v-stepper-item
            :complete="vSteps > nameIndex && isValidModelName"
            :step="nameIndex.toString()"
            color="green"
          >
            Validating Model Name
            <small
              class="mt-2"
              v-if="vSteps > 1 && isValidModelName === true"
              >{{ 'Model name is valid' }}</small
            >
            <v-row
              style="width: 500px"
              v-if="vSteps > nameIndex && isValidModelName === false"
            >
              <v-col class="mt-2" cols="2" md="9" sm="9">
                <small
                  v-if="vSteps > nameIndex + 1 && isValidModelName === false"
                  >{{
                    'Model name already exists.  Enter another model name'
                  }}</small
                >
              </v-col>
              <v-col cols="auto" md="3" sm="3">
                <v-btn
                  class="mt-n7"
                  color="primary"
                  title="Duplicate model name."
                  @click="fixModelErrors(nameIndex)"
                  >Fix</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-item>

          <!-- <v-stepper-content :step="nameIndex.toString()"> -->
          <v-progress-linear indeterminate color="green"></v-progress-linear>
          <!-- </v-stepper-content> -->

          <!--Validate Energy Data-->
          <v-stepper-item
            :complete="vSteps > energyIndex && isValidEnergyData"
            :step="energyIndex.toString()"
            color="green"
          >
            Validating Energy Data
            <small
              class="mt-2"
              v-if="vSteps > energyIndex && isValidEnergyData === true"
              >{{ 'Energy Data is valid' }}</small
            >
            <v-row
              style="width: 500px"
              v-if="vSteps > energyIndex && isValidEnergyData === false"
            >
              <v-col class="mt-2" cols="2" md="9" sm="9">
                <small
                  v-if="vSteps > energyIndex + 1 && isValidEnergyData === false"
                  >{{ dataValidationResult.energyWarningMessage }}</small
                >
              </v-col>
              <v-col cols="auto" md="3" sm="3">
                <v-btn
                  class="mt-n7"
                  color="primary"
                  title="Select a different date range for model energy data."
                  @click="fixModelErrors(energyIndex)"
                  >Fix</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-item>
          <!-- <v-stepper-content step="energyIndex"> -->
          <v-progress-linear indeterminate color="green"></v-progress-linear>
          <!-- </v-stepper-content> -->

          <!--Validate Weather For Date Range-->
          <v-stepper-item
            :complete="vSteps > weatherIndex && isValidWeatherData"
            :step="weatherIndex.toString()"
            color="green"
          >
            Validating Weather Data
            <small
              v-if="vSteps > weatherIndex && isValidWeatherData === true"
              >{{ 'Weather Data for Date range is valid' }}</small
            >
            <v-row
              style="width: 500px"
              v-if="vSteps > weatherIndex && !isValidWeatherData"
            >
              <v-col class="mt-2" cols="2" md="9" sm="9">
                <small
                  v-if="vSteps > weatherIndex + 1 && !isValidWeatherData"
                  >{{ dataValidationResult.weatherWarningMessage }}</small
                >
              </v-col>
              <v-col cols="auto" md="3" sm="3">
                <v-btn
                  class="mt-n7"
                  color="primary"
                  title="Select a different date range for model weather data."
                  @click="fixModelErrors(weatherIndex)"
                  >Fix</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-item>
          <!-- <v-stepper-content step="weatherIndex"> -->
          <v-progress-linear indeterminate color="green"></v-progress-linear>
          <!-- </v-stepper-content> -->

          <!--Validate Factor-->
          <v-stepper-item
            :complete="vSteps > factorIndex && isValidFactors"
            :step="factorIndex.toString()"
            color="green"
          >
            Validating Factor
            <small
              class="mt-2"
              v-if="vSteps > factorIndex && isValidFactors === true"
              >{{ 'Factor is valid' }}</small
            >
            <v-row
              style="width: 500px"
              v-if="vSteps > factorIndex && isValidFactors === false"
            >
              <v-col class="mt-2" cols="2" md="9" sm="9">
                <small
                  v-if="vSteps > factorIndex + 1 && isValidFactors === false"
                  >{{ 'Factor must be selected.' }}</small
                >
              </v-col>
              <v-col cols="auto" md="3" sm="3">
                <v-btn
                  class="mt-n7"
                  color="primary"
                  title="Factor must be selected."
                  @click="fixModelErrors(factorIndex)"
                >
                  Fix
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-item>
          <!-- <v-stepper-content step="factorIndex"> -->
          <v-progress-linear indeterminate color="green"></v-progress-linear>
          <!-- </v-stepper-content> -->
        </v-stepper>
        <v-row>
          <v-col>
            <table>
              <thead>
                <tr>
                  <th class="pr-2">Requested Date Range:</th>
                  <th class="pr-2">Weather Normalized Records Returned:</th>
                  <th class="pr-2">First Energy Record:</th>
                  <th>Last Energy Record:</th>
                  <th class="pr-2">Energy Records Returned:</th>
                  <th class="pr-2">Missing Energy Records in Range:</th>
                  <th class="pr-2">First Weather Record:</th>
                  <th>Last Weather Record:</th>
                  <th class="pr-2">Weather Records Returned:</th>
                  <th class="pr-2">Missing Weather Records in Range:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ dateRangeBacking.startDateTime }} to
                    {{ dateRangeBacking.endDateTime }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ validationResultBacking.actualRecordCount }}
                  </td>
                </tr>
                <tr>
                  <td>{{ validationResultBacking.energyStartDate }}</td>
                </tr>
                <tr>
                  <td>{{ validationResultBacking.energyEndDate }}</td>
                </tr>
                <tr>
                  <td>
                    {{ validationResultBacking.energyRecordCount }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ validationResultBacking.missingEnergyDataRecordCount }}
                  </td>
                </tr>
                <tr>
                  <td>{{ validationResultBacking.weatherStartDate }}</td>
                </tr>
                <tr>
                  <td>{{ validationResultBacking.weatherEndDate }}</td>
                </tr>
                <tr>
                  <td>
                    {{ validationResultBacking.weatherRecordCount }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ validationResultBacking.missingWeatherDataRecordCount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green-darken-1" variant="text" @click="dismissDialog">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mixin as VueTimers } from 'vue-timers';

export default {
  components: {},

  timers: {
    metaValidator: { time: 500, autostart: false, repeat: true },
  },

  mixins: [VueTimers],

  props: {
    invalid: { type: Boolean, default: () => true },
    modelName: { type: String, default: () => null },
    factors: { type: Array, default: () => [] },
    dateRange: {},
    nameValidationResult: { type: Boolean, default: () => true },
    dataValidationResult: {},
  },
  watch: {
    nameValidationResult: function (newVal) {
      this.isValidModelName = newVal;
    },
    dataValidationResult: function (newVal) {
      this.validationResultBacking = newVal;
    },
    dateRange: function (newVal) {
      this.dateRangeBacking = newVal;
      console.log('dateRange set!');
    },
  },
  data() {
    return {
      dialog: false,
      vSteps: 0,
      validationResultBacking: {},
      dateRangeBacking: {},
      isValidModel: false,
      isValidModelName: false,
      isValidWeatherData: false,
      isValidEnergyData: false,
      isValidFactors: false,
      nameIndex: 1,
      energyIndex: 2,
      weatherIndex: 3,
      factorIndex: 4,
    };
  },

  async created() {},

  methods: {
    validateModel() {
      this.isValidModel = false;
      this.dialog = true;
      this.$timer.start('metaValidator');
    },

    metaValidator() {
      this.vSteps++;

      if (this.vSteps == this.nameIndex) {
        // name is not null
        //this.isValidModelName = this.modelName != undefined;
      }

      if (this.vSteps == this.energyIndex) {
        // site is selected
        this.isValidEnergyData =
          this.dataValidationResult.energyWarningMessage.length === 0;
      }

      if (this.vSteps == this.weatherIndex) {
        this.isValidWeatherData =
          this.dataValidationResult.weatherWarningMessage.length === 0;
      }

      if (this.vSteps == this.factorIndex) {
        // analysis type is selected
        this.isValidFactors = this.factors.length > 0;
      }

      this.isValidModel =
        this.isValidModelName && this.isValidEnergyData && this.isValidFactors;
    },

    fixModelErrors(item) {
      this.cancelDialog();

      let newDateRange = this.dateRangeBacking;
      if (item === this.energyIndex) {
        newDateRange.startDateTime =
          this.validationResultBacking.energyStartDate;
        newDateRange.endDateTime = this.validationResultBacking.energyEndDate;
      }
      if (item === this.weatherIndex) {
        newDateRange.startDateTime =
          this.validationResultBacking.weatherStartDate;
        newDateRange.endDateTime = this.validationResultBacking.weatherEndDate;
      }
      let validationItem = { item: item, newDateRange: newDateRange };
      this.$emit('fixmodel', validationItem);
    },

    cancelDialog() {
      this.dialog = false;
      this.$timer.stop('metaValidator');
      this.vSteps = 0;
    },

    dismissDialog() {
      this.cancelDialog();
      this.$emit('dismissdialog');
    },
  },
};
</script>
