<template>
  <v-dialog v-model="dialogValue" persistent max-width="500">
    <ScheduleAction
      :controlId="powerControlInfo.controlId"
      :controlType="'power-controls'"
      :siteId="powerControlInfo.controlInfo.site.id"
    />
  </v-dialog>
</template>

<script>
// ScheduleAction is not the final component. It should be refactored to the new domain's structure.
import ScheduleAction from '@/modules/controls/_components/ScheduleAction.vue';

import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

export default {
  name: 'ConfirmDialogSchedulePowerControl',

  created() {
    eventBus.$on('ok', () => {
      this.handleOk();
    });

    eventBus.$on('cancel', () => {
      this.handleCancel();
    });
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    powerControlInfo: {
      type: Object,
      required: true,
    },
  },

  components: {
    ScheduleAction,
  },

  data() {
    return {
      loadingDelete: false,
    };
  },

  methods: {
    handleOk() {
      this.dialogValue = false;
    },

    handleCancel() {
      this.dialogValue = false;
    },
  },

  computed: {
    dialogValue: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
};
</script>
