<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg11829"
    viewBox="34.19 140.7 373.44 319.53"
  >
    <g id="g1">
      <path
        d="m 368.94,261.35 h 34.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2"
      />
      <path
        d="m 367.69,261.35 h -43.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3"
      />
      <path
        d="m 365.36,263.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path35"
      />
      <path
        d="m 359.38,263.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path36"
      />
      <path
        d="m 359.38,263.84 h 5.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path37"
      />
      <path
        d="m 365.36,262.59 h 38.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path38"
      />
      <path
        d="m 360.53,283.22 v 21.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path57"
      />
      <path
        d="m 359.29,283.22 v 21.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path58"
      />
      <path
        d="m 404.06,281.98 v 12.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path59"
      />
      <path
        d="m 402.82,283.22 v 9.68 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path60"
      />
      <path
        d="m 370.27,368.06 h 24.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path61"
      />
      <path
        d="M 398.17,454.38 V 428.95 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path74"
      />
      <path
        d="M 398.17,428.2 V 414.1 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path75"
      />
      <path
        d="M 398.17,414.1 V 368.08 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path76"
      />
      <path
        d="m 334.78,281.98 h 24.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path77"
      />
      <path
        d="m 359.15,281.98 v -1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path78"
      />
      <path
        d="m 359.15,280.98 h 5.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path79"
      />
      <path
        d="m 365.14,280.98 v 1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path80"
      />
      <path
        d="m 365.14,281.98 h 1.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path81"
      />
      <path
        d="m 324.19,283.22 h 1.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path82"
      />
      <path
        d="m 325.8,283.22 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path83"
      />
      <path
        d="m 334.78,283.22 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path84"
      />
      <path
        d="m 334.78,283.22 h 24.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path85"
      />
      <path
        d="m 360.53,283.22 h 4.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path86"
      />
      <path
        d="m 381,283.22 v 11.81 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path87"
      />
      <path
        d="m 379.75,283.22 v 11.81 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path88"
      />
      <path
        d="m 365.85,281.98 v 1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path89"
      />
      <path
        d="m 379.75,283.22 h -5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path90"
      />
      <path
        d="m 365.85,283.22 h -0.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path91"
      />
      <path
        d="m 404.06,281.98 h -13.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path92"
      />
      <path
        d="m 381.89,281.98 h -7.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path93"
      />
      <path
        d="m 365.85,281.98 h -0.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path94"
      />
      <path
        d="m 381,283.22 h 0.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path95"
      />
      <path
        d="m 390.86,283.22 h 11.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path96"
      />
      <path
        d="m 390.86,283.22 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path97"
      />
      <path
        d="m 390.86,283.22 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path98"
      />
      <path
        d="m 396.37,366.81 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path99"
      />
      <path
        d="M 396.37,356.59 V 340.38 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path100"
      />
      <path
        d="m 402.36,366.81 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path101"
      />
      <path
        d="M 403.6,368.08 V 356.59 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path102"
      />
      <path
        d="M 395.13,366.81 V 341.62 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path103"
      />
      <path
        d="m 374.5,368.06 h 22.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path104"
      />
      <path
        d="m 374.5,366.81 h 20.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path105"
      />
      <path
        d="m 371.66,340.38 h 9.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path106"
      />
      <path
        d="m 381.95,341.62 h 3.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path107"
      />
      <path
        d="m 393.67,340.38 h 2.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path108"
      />
      <path
        d="m 371.51,341.62 h 13.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path109"
      />
      <path
        d="m 393.67,341.62 h 1.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path110"
      />
      <path
        d="M 371.51,341.62 V 354.1 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path111"
      />
      <path
        d="m 377.99,354.1 v 1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path112"
      />
      <path
        d="m 371.51,355.34 v 11.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path113"
      />
      <path
        d="m 373.26,355.34 v 11.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path114"
      />
      <path
        d="m 374.5,355.34 v 11.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path115"
      />
      <path
        d="m 371.51,354.1 h 6.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path116"
      />
      <path
        d="m 374.5,355.34 h 3.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path117"
      />
      <path
        d="m 373.26,355.34 h -1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path118"
      />
      <path
        d="m 371.51,368.06 h 1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path119"
      />
      <path
        d="m 382.19,368.06 h 4.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path120"
      />
      <path
        d="m 386.47,368.06 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path121"
      />
      <path
        d="m 386.47,366.81 h -4.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path122"
      />
      <path
        d="m 382.19,366.81 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path123"
      />
      <path
        d="m 384.98,340.38 h -3.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path124"
      />
      <path
        d="m 381.95,340.38 v -3.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path125"
      />
      <path
        d="m 381.95,328.35 v -2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path126"
      />
      <path
        d="m 380.7,325.36 v 2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path127"
      />
      <path
        d="m 380.7,337.33 v 3.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path128"
      />
      <path
        d="m 378.83,341.62 h 4.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path129"
      />
      <path
        d="m 379.46,341.62 h 3.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path130"
      />
      <path
        d="m 382.61,341.62 v 0.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path131"
      />
      <path
        d="m 382.61,342.45 h -3.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path132"
      />
      <path
        d="m 379.46,342.45 v -0.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path133"
      />
      <path
        d="m 393.67,341.62 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path134"
      />
      <path
        d="m 384.98,341.62 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path135"
      />
      <path
        d="m 373.26,366.81 h -1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path136"
      />
      <path
        d="m 383.16,341.62 h -4.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path137"
      />
      <path
        d="m 378.81,341.62 v -0.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path138"
      />
      <path
        d="m 378.81,340.79 h 4.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path139"
      />
      <path
        d="m 383.16,340.79 v 0.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path140"
      />
      <path
        d="m 334.78,281.98 -0.17,-1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path175"
      />
      <path
        d="m 334.61,280.23 -0.52,-1.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path176"
      />
      <path
        d="m 334.09,278.54 -0.82,-1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path177"
      />
      <path
        d="m 333.27,276.99 -1.12,-1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path178"
      />
      <path
        d="m 332.15,275.63 -1.36,-1.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path179"
      />
      <path
        d="m 330.79,274.51 -1.55,-0.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path180"
      />
      <path
        d="m 329.24,273.69 -1.69,-0.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path181"
      />
      <path
        d="M 327.55,273.17 325.8,273"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path182"
      />
      <path
        d="m 325.8,281.98 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path183"
      />
      <path
        d="m 326.24,281.98 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path184"
      />
      <path
        d="m 325.8,273 v 8.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path185"
      />
      <path
        d="m 390.86,281.98 -0.17,-1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path186"
      />
      <path
        d="m 390.69,280.23 -0.51,-1.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path187"
      />
      <path
        d="m 390.18,278.54 -0.83,-1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path188"
      />
      <path
        d="m 389.35,276.99 -1.12,-1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path189"
      />
      <path
        d="m 388.23,275.63 -1.36,-1.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path190"
      />
      <path
        d="m 386.87,274.51 -1.55,-0.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path191"
      />
      <path
        d="m 385.32,273.69 -1.68,-0.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path192"
      />
      <path
        d="M 383.64,273.17 381.89,273"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path193"
      />
      <path
        d="m 381.89,281.98 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path194"
      />
      <path
        d="m 382.32,281.98 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path195"
      />
      <path
        d="m 381.89,273 v 8.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path196"
      />
      <path
        d="m 384.98,341.62 0.17,1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path197"
      />
      <path
        d="m 385.15,343.37 0.51,1.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path198"
      />
      <path
        d="m 385.66,345.06 0.83,1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path199"
      />
      <path
        d="m 386.49,346.61 1.11,1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path200"
      />
      <path
        d="m 387.6,347.97 1.36,1.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path201"
      />
      <path
        d="m 388.96,349.08 1.56,0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path202"
      />
      <path
        d="m 390.52,349.91 1.68,0.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path203"
      />
      <path
        d="m 392.2,350.42 1.75,0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path204"
      />
      <path
        d="m 393.95,341.62 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path205"
      />
      <path
        d="m 393.51,341.62 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path206"
      />
      <path
        d="m 393.95,350.6 v -8.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path207"
      />
      <path
        d="m 393.35,357.49 v -0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path246"
      />
      <path
        d="m 393.35,357.31 -0.01,-0.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path247"
      />
      <path
        d="m 393.34,357.14 -0.01,-0.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path248"
      />
      <path
        d="m 393.33,356.97 -0.01,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path249"
      />
      <path
        d="m 393.32,356.8 -0.02,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path250"
      />
      <path
        d="m 393.3,356.63 -0.02,-0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path251"
      />
      <path
        d="m 393.28,356.45 -0.03,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path252"
      />
      <path
        d="m 393.25,356.28 -0.03,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path253"
      />
      <path
        d="m 393.22,356.11 -0.03,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path254"
      />
      <path
        d="m 393.19,355.94 v -0.01 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path255"
      />
      <path
        d="m 392.98,355.18 -0.02,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path256"
      />
      <path
        d="m 392.96,355.11 -0.06,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path257"
      />
      <path
        d="m 392.9,354.95 -0.06,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path258"
      />
      <path
        d="m 392.84,354.79 -0.06,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path259"
      />
      <path
        d="m 392.78,354.63 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path260"
      />
      <path
        d="m 392.71,354.47 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path261"
      />
      <path
        d="m 392.64,354.31 -0.08,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path262"
      />
      <path
        d="M 392.56,354.15 392.49,354"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path263"
      />
      <path
        d="m 392.49,354 -0.09,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path264"
      />
      <path
        d="m 392.4,353.85 -0.05,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path265"
      />
      <path
        d="m 391.92,353.09 -0.08,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path266"
      />
      <path
        d="m 391.84,352.98 -0.11,-0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path267"
      />
      <path
        d="m 391.73,352.84 -0.11,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path268"
      />
      <path
        d="m 391.62,352.71 -0.11,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path269"
      />
      <path
        d="m 391.51,352.58 -0.11,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path270"
      />
      <path
        d="m 391.4,352.45 -0.12,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path271"
      />
      <path
        d="m 391.28,352.32 -0.12,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path272"
      />
      <path
        d="m 391.16,352.2 -0.13,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path273"
      />
      <path
        d="m 391.03,352.08 -0.12,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path274"
      />
      <path
        d="m 390.91,351.96 -0.04,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path275"
      />
      <path
        d="m 390.27,351.44 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path276"
      />
      <path
        d="m 390.24,351.42 -0.14,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path277"
      />
      <path
        d="m 390.1,351.32 -0.15,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path278"
      />
      <path
        d="m 389.95,351.22 -0.14,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path279"
      />
      <path
        d="m 389.81,351.13 -0.15,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path280"
      />
      <path
        d="m 389.66,351.04 -0.15,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path281"
      />
      <path
        d="m 389.51,350.95 -0.15,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path282"
      />
      <path
        d="m 389.36,350.87 -0.16,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path283"
      />
      <path
        d="m 389.2,350.79 -0.15,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path284"
      />
      <path
        d="m 389.05,350.72 -0.14,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path285"
      />
      <path
        d="m 388.18,350.37 -0.1,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path286"
      />
      <path
        d="m 388.08,350.34 -0.16,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path287"
      />
      <path
        d="m 387.92,350.29 -0.17,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path288"
      />
      <path
        d="m 387.75,350.25 -0.17,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path289"
      />
      <path
        d="m 387.58,350.21 -0.17,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path290"
      />
      <path
        d="m 387.41,350.17 -0.17,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path291"
      />
      <path
        d="m 387.24,350.14 -0.17,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path292"
      />
      <path
        d="m 387.07,350.11 -0.17,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path293"
      />
      <path
        d="m 386.9,350.08 -0.17,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path294"
      />
      <path
        d="m 386.73,350.06 -0.08,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path295"
      />
      <path
        d="M 385.87,350.01 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path296"
      />
      <path
        d="M 385.87,350.01 H 385.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path297"
      />
      <path
        d="m 385.7,350.01 -0.18,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path298"
      />
      <path
        d="m 385.52,350.02 -0.17,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path299"
      />
      <path
        d="m 385.35,350.03 -0.17,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path300"
      />
      <path
        d="m 385.18,350.04 -0.17,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path301"
      />
      <path
        d="m 385.01,350.06 -0.17,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path302"
      />
      <path
        d="m 384.84,350.08 -0.17,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path303"
      />
      <path
        d="m 384.67,350.11 -0.17,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path304"
      />
      <path
        d="m 384.5,350.14 -0.17,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path305"
      />
      <path
        d="m 384.33,350.17 h -0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path306"
      />
      <path
        d="m 383.56,350.37 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path307"
      />
      <path
        d="m 383.49,350.4 -0.16,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path308"
      />
      <path
        d="m 383.33,350.45 -0.16,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path309"
      />
      <path
        d="m 383.17,350.51 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path310"
      />
      <path
        d="m 383.01,350.58 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path311"
      />
      <path
        d="m 382.85,350.65 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path312"
      />
      <path
        d="m 382.69,350.72 -0.15,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path313"
      />
      <path
        d="m 382.54,350.79 -0.16,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path314"
      />
      <path
        d="m 382.38,350.87 -0.15,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path315"
      />
      <path
        d="m 382.23,350.95 -0.1,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path316"
      />
      <path
        d="m 381.47,351.44 -0.11,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path317"
      />
      <path
        d="m 381.36,351.52 -0.13,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path318"
      />
      <path
        d="m 381.23,351.62 -0.14,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path319"
      />
      <path
        d="m 381.09,351.73 -0.13,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path320"
      />
      <path
        d="m 380.96,351.85 -0.13,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path321"
      />
      <path
        d="m 380.83,351.96 -0.13,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path322"
      />
      <path
        d="m 380.7,352.08 -0.12,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path323"
      />
      <path
        d="m 380.58,352.2 -0.12,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path324"
      />
      <path
        d="m 380.46,352.32 -0.12,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path325"
      />
      <path
        d="m 380.34,352.45 -0.03,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path326"
      />
      <path
        d="m 379.82,353.09 -0.02,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path327"
      />
      <path
        d="m 379.8,353.12 -0.1,0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path328"
      />
      <path
        d="m 379.7,353.26 -0.1,0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path329"
      />
      <path
        d="m 379.6,353.4 -0.09,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path330"
      />
      <path
        d="m 379.51,353.55 -0.09,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path331"
      />
      <path
        d="m 379.42,353.7 -0.08,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path332"
      />
      <path
        d="M 379.34,353.85 379.25,354"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path333"
      />
      <path
        d="m 379.25,354 -0.08,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path334"
      />
      <path
        d="m 379.17,354.15 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path335"
      />
      <path
        d="m 379.1,354.31 -0.06,0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path336"
      />
      <path
        d="m 378.76,355.18 -0.04,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path337"
      />
      <path
        d="m 378.72,355.28 -0.04,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path338"
      />
      <path
        d="m 378.68,355.44 -0.05,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path339"
      />
      <path
        d="m 378.63,355.61 -0.04,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path340"
      />
      <path
        d="m 378.59,355.78 -0.04,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path341"
      />
      <path
        d="m 378.55,355.94 -0.03,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path342"
      />
      <path
        d="m 378.52,356.11 -0.03,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path343"
      />
      <path
        d="m 378.49,356.28 -0.03,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path344"
      />
      <path
        d="m 378.46,356.45 -0.02,0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path345"
      />
      <path
        d="m 378.44,356.63 -0.01,0.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path346"
      />
      <path
        d="M 378.39,357.49 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path347"
      />
      <path
        d="m 378.39,357.49 v 0.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path348"
      />
      <path
        d="m 378.39,357.66 0.01,0.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path349"
      />
      <path
        d="m 378.4,357.83 0.01,0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path350"
      />
      <path
        d="m 378.41,358.01 0.01,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path351"
      />
      <path
        d="m 378.42,358.18 0.02,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path352"
      />
      <path
        d="m 378.44,358.35 0.02,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path353"
      />
      <path
        d="m 378.46,358.52 0.03,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path354"
      />
      <path
        d="m 378.49,358.69 0.03,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path355"
      />
      <path
        d="m 378.52,358.86 0.03,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path356"
      />
      <path
        d="m 378.55,359.03 v 0.01 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path357"
      />
      <path
        d="m 378.76,359.8 0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path358"
      />
      <path
        d="m 378.78,359.86 0.05,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path359"
      />
      <path
        d="m 378.83,360.03 0.06,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path360"
      />
      <path
        d="m 378.89,360.19 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path361"
      />
      <path
        d="m 378.96,360.35 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path362"
      />
      <path
        d="m 379.03,360.51 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path363"
      />
      <path
        d="m 379.1,360.67 0.07,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path364"
      />
      <path
        d="m 379.17,360.82 0.08,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path365"
      />
      <path
        d="m 379.25,360.98 0.09,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path366"
      />
      <path
        d="m 379.34,361.13 0.05,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path367"
      />
      <path
        d="M 379.82,361.88 379.9,362"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path368"
      />
      <path
        d="m 379.9,362 0.11,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path369"
      />
      <path
        d="m 380.01,362.13 0.11,0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path370"
      />
      <path
        d="m 380.12,362.27 0.11,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path371"
      />
      <path
        d="m 380.23,362.4 0.11,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path372"
      />
      <path
        d="m 380.34,362.53 0.12,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path373"
      />
      <path
        d="m 380.46,362.65 0.12,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path374"
      />
      <path
        d="m 380.58,362.78 0.12,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path375"
      />
      <path
        d="m 380.7,362.9 0.13,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path376"
      />
      <path
        d="m 380.83,363.02 0.03,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path377"
      />
      <path
        d="m 381.47,363.54 0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path378"
      />
      <path
        d="m 381.5,363.56 0.14,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path379"
      />
      <path
        d="m 381.64,363.66 0.15,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path380"
      />
      <path
        d="m 381.79,363.75 0.14,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path381"
      />
      <path
        d="m 381.93,363.85 0.15,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path382"
      />
      <path
        d="m 382.08,363.94 0.15,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path383"
      />
      <path
        d="m 382.23,364.02 0.15,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path384"
      />
      <path
        d="m 382.38,364.1 0.16,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path385"
      />
      <path
        d="m 382.54,364.18 0.15,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path386"
      />
      <path
        d="m 382.69,364.26 0.14,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path387"
      />
      <path
        d="m 383.56,364.6 0.1,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path388"
      />
      <path
        d="m 383.66,364.63 0.16,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path389"
      />
      <path
        d="m 383.82,364.68 0.17,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path390"
      />
      <path
        d="m 383.99,364.73 0.17,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path391"
      />
      <path
        d="m 384.16,364.77 0.17,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path392"
      />
      <path
        d="m 384.33,364.81 0.17,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path393"
      />
      <path
        d="m 384.5,364.84 0.17,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path394"
      />
      <path
        d="m 384.67,364.87 0.17,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path395"
      />
      <path
        d="m 384.84,364.9 0.17,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path396"
      />
      <path
        d="m 385.01,364.92 0.08,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path397"
      />
      <path
        d="M 385.87,364.97 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path398"
      />
      <path
        d="m 385.87,364.97 h 0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path399"
      />
      <path
        d="m 386.04,364.97 0.17,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path400"
      />
      <path
        d="m 386.21,364.96 0.18,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path401"
      />
      <path
        d="m 386.39,364.95 0.17,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path402"
      />
      <path
        d="m 386.56,364.94 0.17,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path403"
      />
      <path
        d="m 386.73,364.92 0.17,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path404"
      />
      <path
        d="m 386.9,364.9 0.17,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path405"
      />
      <path
        d="m 387.07,364.87 0.17,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path406"
      />
      <path
        d="m 387.24,364.84 0.17,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path407"
      />
      <path
        d="m 387.41,364.81 0.01,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path408"
      />
      <path
        d="m 388.18,364.6 0.07,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path409"
      />
      <path
        d="m 388.25,364.58 0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path410"
      />
      <path
        d="m 388.41,364.52 0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path411"
      />
      <path
        d="m 388.57,364.46 0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path412"
      />
      <path
        d="m 388.73,364.4 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path413"
      />
      <path
        d="m 388.89,364.33 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path414"
      />
      <path
        d="m 389.05,364.26 0.15,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path415"
      />
      <path
        d="m 389.2,364.18 0.16,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path416"
      />
      <path
        d="m 389.36,364.1 0.15,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path417"
      />
      <path
        d="m 389.51,364.02 0.1,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path418"
      />
      <path
        d="m 390.27,363.54 0.11,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path419"
      />
      <path
        d="m 390.38,363.46 0.13,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path420"
      />
      <path
        d="m 390.51,363.35 0.14,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path421"
      />
      <path
        d="m 390.65,363.24 0.13,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path422"
      />
      <path
        d="m 390.78,363.13 0.13,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path423"
      />
      <path
        d="m 390.91,363.02 0.12,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path424"
      />
      <path
        d="m 391.03,362.9 0.13,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path425"
      />
      <path
        d="m 391.16,362.78 0.12,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path426"
      />
      <path
        d="m 391.28,362.65 0.12,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path427"
      />
      <path
        d="m 391.4,362.53 0.03,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path428"
      />
      <path
        d="m 391.92,361.88 0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path429"
      />
      <path
        d="m 391.94,361.86 0.1,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path430"
      />
      <path
        d="m 392.04,361.71 0.1,-0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path431"
      />
      <path
        d="m 392.14,361.57 0.09,-0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path432"
      />
      <path
        d="m 392.23,361.43 0.09,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path433"
      />
      <path
        d="m 392.32,361.28 0.08,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path434"
      />
      <path
        d="m 392.4,361.13 0.09,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path435"
      />
      <path
        d="m 392.49,360.98 0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path436"
      />
      <path
        d="m 392.56,360.82 0.08,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path437"
      />
      <path
        d="m 392.64,360.67 0.06,-0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path438"
      />
      <path
        d="m 392.98,359.8 0.03,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path439"
      />
      <path
        d="m 393.01,359.7 0.05,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path440"
      />
      <path
        d="m 393.06,359.53 0.05,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path441"
      />
      <path
        d="m 393.11,359.37 0.04,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path442"
      />
      <path
        d="m 393.15,359.2 0.04,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path443"
      />
      <path
        d="m 393.19,359.03 0.03,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path444"
      />
      <path
        d="m 393.22,358.86 0.03,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path445"
      />
      <path
        d="m 393.25,358.69 0.03,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path446"
      />
      <path
        d="m 393.28,358.52 0.02,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path447"
      />
      <path
        d="m 393.3,358.35 0.01,-0.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path448"
      />
      <path
        d="m 395.13,354.6 h -1.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path449"
      />
      <path
        d="m 393.79,354.6 v 9.29 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path450"
      />
      <path
        d="m 393.79,363.89 h 1.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path451"
      />
      <path
        d="M 395.13,363.89 V 354.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path452"
      />
      <path
        d="M 377.5,354.1 V 341.62 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path453"
      />
      <path
        d="m 377.99,355.34 h -2.56"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path454"
      />
      <path
        d="m 375.43,355.34 v 0.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path455"
      />
      <path
        d="m 375.43,356.3 h 2.56"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path456"
      />
      <path
        d="m 377.99,356.3 v -0.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path457"
      />
      <path
        d="m 374.78,281.98 -0.17,-1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path645"
      />
      <path
        d="m 374.61,280.23 -0.51,-1.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path646"
      />
      <path
        d="m 374.1,278.54 -0.83,-1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path647"
      />
      <path
        d="m 373.27,276.99 -1.12,-1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path648"
      />
      <path
        d="m 372.15,275.63 -1.36,-1.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path649"
      />
      <path
        d="m 370.79,274.51 -1.55,-0.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path650"
      />
      <path
        d="m 369.24,273.69 -1.68,-0.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path651"
      />
      <path
        d="M 367.56,273.17 365.81,273"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path652"
      />
      <path
        d="m 365.81,281.98 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path653"
      />
      <path
        d="m 366.24,281.98 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path654"
      />
      <path
        d="m 365.81,273 v 8.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path655"
      />
      <path
        d="m 370.41,295.03 h 9.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path656"
      />
      <path
        d="m 381,295.03 h 14.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path657"
      />
      <path
        d="m 396.52,295.03 v 30.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path658"
      />
      <path
        d="m 395.27,292.9 v 2.13 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path659"
      />
      <path
        d="m 395.27,296.27 v 27.85 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path660"
      />
      <path
        d="m 374.65,295.03 h 5.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path661"
      />
      <path
        d="m 381,295.03 h 14.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path662"
      />
      <path
        d="m 374.65,296.27 h 20.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path663"
      />
      <path
        d="m 371.66,325.36 h 9.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path664"
      />
      <path
        d="m 382.09,324.12 h 3.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path665"
      />
      <path
        d="m 393.81,325.36 h 2.71"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path666"
      />
      <path
        d="m 371.66,324.12 h 13.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path667"
      />
      <path
        d="m 393.81,324.12 h 1.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path668"
      />
      <path
        d="M 371.66,324.12 V 309.55 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path669"
      />
      <path
        d="m 378.14,309.55 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path670"
      />
      <path
        d="M 371.66,308.31 V 296.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path671"
      />
      <path
        d="M 373.4,308.31 V 296.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path672"
      />
      <path
        d="M 374.65,308.31 V 296.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path673"
      />
      <path
        d="m 371.66,309.55 h 6.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path674"
      />
      <path
        d="m 374.65,308.31 h 3.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path675"
      />
      <path
        d="m 373.4,308.31 h -1.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path676"
      />
      <path
        d="m 370.27,295.03 h 3.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path677"
      />
      <path
        d="m 382.33,295.03 h 4.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path678"
      />
      <path
        d="m 386.62,295.03 v 1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path679"
      />
      <path
        d="m 386.62,296.27 h -4.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path680"
      />
      <path
        d="m 382.33,296.27 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path681"
      />
      <path
        d="m 385.12,325.36 h -3.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path682"
      />
      <path
        d="m 378.98,324.12 h 4.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path683"
      />
      <path
        d="m 379.6,324.12 h 3.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path684"
      />
      <path
        d="m 382.75,324.12 v -0.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path685"
      />
      <path
        d="M 382.75,323.29 H 379.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path686"
      />
      <path
        d="m 379.6,323.29 v 0.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path687"
      />
      <path
        d="m 393.81,324.12 v 1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path688"
      />
      <path
        d="m 385.12,324.12 v 1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path689"
      />
      <path
        d="m 373.4,296.27 h -1.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path690"
      />
      <path
        d="m 383.3,324.12 h -4.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path691"
      />
      <path
        d="m 378.95,324.12 v 0.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path692"
      />
      <path
        d="m 378.95,324.95 h 4.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path693"
      />
      <path
        d="m 383.3,324.95 v -0.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path694"
      />
      <path
        d="m 385.12,324.12 0.17,-1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path695"
      />
      <path
        d="m 385.29,322.37 0.51,-1.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path696"
      />
      <path
        d="m 385.8,320.68 0.83,-1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path697"
      />
      <path
        d="m 386.63,319.13 1.12,-1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path698"
      />
      <path
        d="m 387.75,317.77 1.36,-1.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path699"
      />
      <path
        d="m 389.11,316.65 1.55,-0.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path700"
      />
      <path
        d="m 390.66,315.83 1.68,-0.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path701"
      />
      <path
        d="m 392.34,315.31 1.76,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path702"
      />
      <path
        d="m 394.1,324.12 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path703"
      />
      <path
        d="m 393.66,324.12 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path704"
      />
      <path
        d="m 394.1,315.14 v 8.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path705"
      />
      <path
        d="m 377.64,309.55 v 14.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path706"
      />
      <path
        d="M 370.27,368.06 V 305.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path707"
      />
      <path
        d="m 370.27,304.67 v -9.64 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path708"
      />
      <path
        d="m 381.95,328.35 1.75,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path709"
      />
      <path
        d="m 383.7,328.52 1.68,0.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path710"
      />
      <path
        d="m 385.38,329.04 1.55,0.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path711"
      />
      <path
        d="m 386.93,329.86 1.36,1.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path712"
      />
      <path
        d="m 388.29,330.98 1.12,1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path713"
      />
      <path
        d="m 389.41,332.34 0.83,1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path714"
      />
      <path
        d="m 390.24,333.89 0.51,1.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path715"
      />
      <path
        d="m 390.75,335.58 0.17,1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path716"
      />
      <path
        d="m 381.95,337.33 v -0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path717"
      />
      <path
        d="m 381.95,336.89 h 8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path718"
      />
      <path
        d="m 390.92,337.33 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path719"
      />
      <path
        d="M 381.95,328.35 H 380.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path720"
      />
      <path
        d="M 381.95,337.33 H 380.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path721"
      />
      <path
        d="m 324.19,305.91 h 45.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2116"
      />
      <path
        d="m 324.19,307.16 44.89,-0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2117"
      />
      <path
        d="m 324.19,343.81 h 22.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2118"
      />
      <path
        d="m 324.19,345.06 h 21.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2119"
      />
      <path
        d="m 346.64,343.81 -0.01,24.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2120"
      />
      <path
        d="m 345.39,345.06 v 23.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2121"
      />
      <path
        d="m 369.33,305.91 v 62.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2122"
      />
      <path
        d="M 396.93,369.78 H 366.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2123"
      />
      <path
        d="m 365.73,369.78 h -8.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2124"
      />
      <path
        d="m 357.99,369.78 h -1.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2125"
      />
      <path
        d="M 347.77,369.78 H 334.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2126"
      />
      <path
        d="M 369.33,368.53 H 356.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2127"
      />
      <path
        d="m 347.77,368.53 h -1.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2128"
      />
      <path
        d="M 345.39,368.53 H 334.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2129"
      />
      <path
        d="m 325.17,369.78 h -2.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2130"
      />
      <path
        d="m 325.61,368.06 h -1.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2131"
      />
      <path
        d="m 334.15,368.06 -0.18,-1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2132"
      />
      <path
        d="m 333.97,366.31 -0.51,-1.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2133"
      />
      <path
        d="m 333.46,364.62 -0.83,-1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2134"
      />
      <path
        d="m 332.63,363.07 -1.11,-1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2135"
      />
      <path
        d="m 331.52,361.71 -1.36,-1.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2136"
      />
      <path
        d="m 330.16,360.6 -1.55,-0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2137"
      />
      <path
        d="m 328.61,359.77 -1.69,-0.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2138"
      />
      <path
        d="m 326.92,359.26 -1.75,-0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2139"
      />
      <path
        d="m 325.17,368.06 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2140"
      />
      <path
        d="m 325.61,368.06 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2141"
      />
      <path
        d="m 325.17,359.08 v 8.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2142"
      />
      <path
        d="m 325.17,368.06 v 1.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2143"
      />
      <path
        d="m 324.67,368.06 v 1.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2144"
      />
      <path
        d="m 334.15,368.06 v 1.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2145"
      />
      <path
        d="m 334.64,368.06 v 1.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2146"
      />
      <path
        d="M 322.94,348.24 H 275.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2147"
      />
      <path
        d="M 274.55,348.24 H 229.88"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2148"
      />
      <path
        d="M 322.94,349.49 H 229.88"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2149"
      />
      <path
        d="m 322.94,218.49 v 24.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2150"
      />
      <path
        d="m 322.94,254.61 v 8.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2151"
      />
      <path
        d="m 322.94,272.51 v 61.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2152"
      />
      <path
        d="m 322.94,342.78 v 5.46 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2153"
      />
      <path
        d="m 322.94,349.49 v 20.29 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2154"
      />
      <path
        d="m 324.19,219.74 v 23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2155"
      />
      <path
        d="m 324.19,254.61 v 6.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2156"
      />
      <path
        d="m 324.19,262.59 v 0.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2157"
      />
      <path
        d="m 324.19,272.5 v 32.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2158"
      />
      <path
        d="m 324.19,305.91 v 27.95 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2159"
      />
      <path
        d="m 324.19,342.78 v 1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2160"
      />
      <path
        d="m 324.19,345.06 v 23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2161"
      />
      <path
        d="m 324.19,219.74 v 23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2162"
      />
      <path
        d="m 324.19,254.61 v 6.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2163"
      />
      <path
        d="m 324.19,262.59 v 0.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2164"
      />
      <path
        d="m 324.19,272.5 v 32.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2165"
      />
      <path
        d="m 324.19,283.22 v 21.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2166"
      />
      <path
        d="M 369.33,305.91 H 324.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2167"
      />
      <path
        d="m 370.27,304.67 h -9.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2168"
      />
      <path
        d="m 359.29,304.67 h -35.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2169"
      />
      <path
        d="M 396.93,382.92 V 368.06 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2170"
      />
      <path
        d="M 396.93,412.81 V 388.12 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2171"
      />
      <path
        d="M 396.93,386.87 V 368.06 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2172"
      />
      <path
        d="m 396.93,412.81 v 4.95 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2173"
      />
      <path
        d="m 396.93,419 v 32.56 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2174"
      />
      <path
        d="m 396.37,366.81 h 5.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2175"
      />
      <path
        d="m 396.37,357.84 h 5.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2176"
      />
      <path
        d="m 396.37,356.59 h 7.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2177"
      />
      <path
        d="m 398.17,368.08 h 5.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2178"
      />
      <path
        d="m 396.52,295.03 v -0.88 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2248"
      />
      <path
        d="m 396.52,294.15 h 7.54"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2249"
      />
      <path
        d="m 395.27,292.9 h 7.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2250"
      />
      <path
        d="m 304.24,161.83 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2278"
      />
      <path
        d="m 304.24,163.83 h 11.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2279"
      />
      <path
        d="m 70.35,264.59 h 3.92"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2290"
      />
      <path
        d="m 111.51,158.84 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2291"
      />
      <path
        d="m 111.51,160.84 h 21.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2292"
      />
      <path
        d="m 160.63,160.84 h 20.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2293"
      />
      <path
        d="M 181.08,158.84 H 208.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2294"
      />
      <path
        d="m 208.5,158.84 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2295"
      />
      <path
        d="m 208.5,160.84 h 20.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2296"
      />
      <path
        d="m 256.37,160.84 h 2.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2297"
      />
      <path
        d="m 260.45,158.84 v 2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2298"
      />
      <path
        d="m 258.96,163.83 h 7.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2299"
      />
      <path
        d="M 74.27,263.81 H 74.26 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2300"
      />
      <path
        d="m 74.26,263.81 v -7.39 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2301"
      />
      <path
        d="m 72.83,256.42 v -1.28 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2302"
      />
      <path
        d="m 72.83,245.73 v -3 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2303"
      />
      <path
        d="M 72.83,242.73 V 215.85 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2304"
      />
      <path
        d="M 72.83,215.85 V 197.53 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2305"
      />
      <path
        d="m 72.33,242.23 v -3.42 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2306"
      />
      <path
        d="M 72.33,238.31 V 216.35 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2307"
      />
      <path
        d="M 71.59,242.73 V 215.85 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2308"
      />
      <path
        d="M 71.59,215.85 V 197.53 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2309"
      />
      <path
        d="M 74.83,237.91 V 199.29 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2310"
      />
      <path
        d="m 74.83,198.21 v -0.68 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2311"
      />
      <path
        d="m 74.83,170.1 v -9.26 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2312"
      />
      <path
        d="M 402.97,261.35 H 324.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2313"
      />
      <path
        d="m 316.21,163.83 h 8.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2314"
      />
      <path
        d="m 324.68,163.83 v -2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2315"
      />
      <path
        d="m 324.68,161.83 h 27.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2316"
      />
      <path
        d="m 352.11,161.83 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2317"
      />
      <path
        d="m 352.11,163.83 h 14.71"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2318"
      />
      <path
        d="m 366.82,163.83 v -7.87 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2319"
      />
      <path
        d="m 365.57,161.83 v -7.38 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2320"
      />
      <path
        d="m 366.82,155.96 3.48,-1.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2321"
      />
      <path
        d="m 370.3,154.75 -0.52,-1.76"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2322"
      />
      <path
        d="m 369.78,152.99 9.97,-2.94"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2323"
      />
      <path
        d="m 379.75,150.05 0.53,1.76"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2324"
      />
      <path
        d="m 380.28,151.81 13.53,-2.79"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2325"
      />
      <path
        d="m 393.81,149.02 4.36,-0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2326"
      />
      <path
        d="m 398.17,148.41 v -2.68 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2327"
      />
      <path
        d="m 266.59,163.83 h 0.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2355"
      />
      <path
        d="m 267.22,163.83 h 9.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2356"
      />
      <path
        d="m 276.81,159.34 h 88.76"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2358"
      />
      <path
        d="m 365.36,263.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2378"
      />
      <path
        d="m 359.38,263.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2379"
      />
      <path
        d="m 359.38,263.84 h 5.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2380"
      />
      <path
        d="m 396.93,148.58 v 23.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2381"
      />
      <path
        d="m 72.03,254.71 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2382"
      />
      <path
        d="m 365.57,154.45 4.21,-1.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2383"
      />
      <path
        d="M 70.35,156.35 H 271.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2384"
      />
      <path
        d="m 111.51,158.09 v -1.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2385"
      />
      <path
        d="M 70.35,156.35 V 170.1 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2386"
      />
      <path
        d="M 74.26,256.42 H 72.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2387"
      />
      <path
        d="m 398.17,148.41 h 0.92"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2418"
      />
      <path
        d="m 399.09,148.41 v -1.68 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2419"
      />
      <path
        d="m 399.09,146.27 v -0.54 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2420"
      />
      <path
        d="m 399.09,145.73 h -0.92"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2421"
      />
      <path
        d="m 398.17,145.73 v 2.68 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2422"
      />
      <path
        d="m 399.09,146.73 v -0.46 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2428"
      />
      <path
        d="m 398.17,145.73 v 2.65 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2454"
      />
      <path
        d="m 398.17,148.41 -4.36,0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2455"
      />
      <path
        d="m 398.17,145.35 -8.85,1.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2456"
      />
      <path
        d="m 384.37,148.92 -4.62,1.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2457"
      />
      <path
        d="m 384.07,147.71 -4.58,1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2458"
      />
      <path
        d="m 383.97,147.22 -4.61,1.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2459"
      />
      <path
        d="m 380.28,151.81 -0.53,-1.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2460"
      />
      <path
        d="m 379.49,148.81 -10.15,3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2461"
      />
      <path
        d="m 379.36,148.33 -10.17,3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2462"
      />
      <path
        d="m 369.78,152.98 0.52,1.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2463"
      />
      <path
        d="m 369.34,151.81 -11,3.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2464"
      />
      <path
        d="m 369.19,151.33 -11.35,3.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2465"
      />
      <path
        d="m 359.59,156.54 v 2.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2466"
      />
      <path
        d="m 358.34,155.64 v 3.7 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2467"
      />
      <path
        d="m 357.84,155.29 v 4.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2468"
      />
      <path
        d="m 396.93,148.58 v 23.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2469"
      />
      <path
        d="m 398.17,145.93 v -4.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2470"
      />
      <path
        d="m 398.17,140.95 -5.79,0.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2471"
      />
      <path
        d="m 389.32,146.66 -5.25,1.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2472"
      />
      <path
        d="m 389.32,146.15 -5.35,1.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2473"
      />
      <path
        d="m 398.17,146.61 -8.56,1.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2474"
      />
      <path
        d="m 389.61,147.87 -5.24,1.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2475"
      />
      <path
        d="m 384.84,148.28 h -1.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2476"
      />
      <path
        d="m 382.95,148.28 v 1.66 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2477"
      />
      <path
        d="m 382.95,149.94 h 1.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2478"
      />
      <path
        d="m 384.84,149.94 v -1.66 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2479"
      />
      <path
        d="m 379.75,150.04 -0.5,-1.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2480"
      />
      <path
        d="m 379.27,150.18 -0.35,-1.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2481"
      />
      <path
        d="m 369.28,151.3 0.5,1.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2482"
      />
      <path
        d="m 369.9,151.64 0.36,1.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2483"
      />
      <path
        d="M 389.32,146.66 V 143.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2484"
      />
      <path
        d="m 392.38,143.34 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2485"
      />
      <path
        d="m 392.38,143.34 -3.06,0.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2486"
      />
      <path
        d="m 380.28,151.81 -9.98,2.94"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2487"
      />
      <path
        d="m 398.17,148.41 v -2.68 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2488"
      />
      <path
        d="m 365.57,154.45 -5.98,2.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2489"
      />
      <path
        d="m 352.11,161.83 v -2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2490"
      />
      <path
        d="m 324.68,161.83 v -2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2491"
      />
      <path
        d="m 304.24,161.83 v -2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2492"
      />
      <path
        d="M 365.32,159.34 H 271.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2493"
      />
      <path
        d="M 365.32,161.83 H 260.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2494"
      />
      <path
        d="m 271.33,159.34 v -2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2495"
      />
      <path
        d="m 256.37,158.84 h 4.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2496"
      />
      <path
        d="m 208.5,158.84 v -2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2497"
      />
      <path
        d="m 160.63,158.84 v -2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2498"
      />
      <path
        d="m 208.5,158.84 h 20.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2499"
      />
      <path
        d="m 160.63,158.84 h 20.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2500"
      />
      <path
        d="m 111.51,158.84 h 21.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2501"
      />
      <path
        d="M 84.09,158.84 H 72.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2502"
      />
      <path
        d="M 84.09,160.84 H 74.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2503"
      />
      <path
        d="m 352.11,161.83 h 13.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2504"
      />
      <path
        d="M 72.83,170.1 V 158.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2505"
      />
      <path
        d="m 398.17,454.38 v -2.82 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2520"
      />
      <path
        d="m 398.17,451.56 h -6.64"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2522"
      />
      <path
        d="m 380.5,448.09 a 100.786,100.786 0 0 0 11.03,3.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2523"
      />
      <path
        d="m 380.5,448.09 -0.14,0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2524"
      />
      <path
        d="M 380.36,448.4 370.39,444"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2525"
      />
      <path
        d="m 370.39,444 0.13,-0.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2526"
      />
      <path
        d="M 365.73,431.6 H 352.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2527"
      />
      <path
        d="m 352.11,431.6 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2528"
      />
      <path
        d="M 352.11,433.6 H 328.54"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2529"
      />
      <path
        d="m 325.18,433.6 h -0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2530"
      />
      <path
        d="m 304.24,431.6 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2531"
      />
      <path
        d="m 276.81,433.6 v -2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2533"
      />
      <path
        d="M 276.81,431.6 H 258.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2534"
      />
      <path
        d="m 228.95,436.59 v -2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2540"
      />
      <path
        d="m 208.5,434.59 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2541"
      />
      <path
        d="M 208.5,436.59 H 181.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2542"
      />
      <path
        d="m 324.68,433.6 v -2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2575"
      />
      <path
        d="m 407.07,454.38 h -8.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2577"
      />
      <path
        d="m 407.07,453.63 h -8.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2579"
      />
      <path
        d="m 370.52,443.7 -3.54,-1.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2580"
      />
      <path
        d="M 366.98,441.8 V 431.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2582"
      />
      <path
        d="M 304.24,433.6 H 280.67"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2583"
      />
      <path
        d="m 277.31,433.6 h -0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2584"
      />
      <path
        d="m 112.76,434.59 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2596"
      />
      <path
        d="m 258.96,431.6 v 2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2597"
      />
      <path
        d="M 228.95,434.59 H 208.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2598"
      />
      <path
        d="M 324.68,431.6 H 304.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2599"
      />
      <path
        d="m 258.96,434.59 h -2.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2600"
      />
      <path
        d="m 160.63,434.59 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2601"
      />
      <path
        d="m 160.63,436.59 h -4.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2602"
      />
      <path
        d="m 137.56,436.59 h -4.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2603"
      />
      <path
        d="M 256.37,436.59 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2604"
      />
      <path
        d="M 365.48,433.6 H 352.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2605"
      />
      <path
        d="M 324.68,433.6 H 304.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2606"
      />
      <path
        d="m 256.37,434.59 v 2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2607"
      />
      <path
        d="M 85.34,434.59 H 74.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2608"
      />
      <path
        d="M 276.81,433.6 H 260.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2609"
      />
      <path
        d="m 181.08,436.59 v -2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2610"
      />
      <path
        d="m 133.21,436.59 v -2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2611"
      />
      <path
        d="M 181.08,434.59 H 160.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2612"
      />
      <path
        d="M 133.21,434.59 H 112.76"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2614"
      />
      <path
        d="M 72.84,436.59 V 410.85 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2615"
      />
      <path
        d="M 74.77,342.75 H 71.94"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2616"
      />
      <path
        d="m 365.48,433.6 v 8.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2640"
      />
      <path
        d="m 358.5,440.46 10.91,5.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2641"
      />
      <path
        d="m 358,440.76 11.18,5.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2642"
      />
      <path
        d="m 359.74,438.86 10.46,5.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2643"
      />
      <path
        d="m 369.9,445.15 9.95,4.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2644"
      />
      <path
        d="m 369.41,446.29 9.94,4.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2645"
      />
      <path
        d="m 369.21,446.75 9.94,4.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2646"
      />
      <path
        d="m 379.99,449.23 a 102.033,102.033 0 0 0 11.38,3.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2647"
      />
      <path
        d="m 386.14,459.04 h 12.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2648"
      />
      <path
        d="m 386.64,458.54 h 11.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2649"
      />
      <path
        d="m 388.14,457.54 h 8.54"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2650"
      />
      <path
        d="m 391.37,452.8 h 5.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2651"
      />
      <path
        d="m 396.68,452.8 v 4.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2652"
      />
      <path
        d="m 359.74,436.09 v 2.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2653"
      />
      <path
        d="m 358.5,436.09 v 4.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2654"
      />
      <path
        d="m 358,436.09 v 4.67 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2655"
      />
      <path
        d="m 370.39,444 -0.98,2.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2656"
      />
      <path
        d="m 379.35,450.69 1.01,-2.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2657"
      />
      <path
        d="m 386.64,453.14 v 5.4 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2658"
      />
      <path
        d="m 386.14,453.14 v 5.9 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2659"
      />
      <path
        d="m 388.14,451.94 v 5.6 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2660"
      />
      <path
        d="m 398.17,454.38 v 4.66 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2661"
      />
      <path
        d="m 397.67,451.56 v 6.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2662"
      />
      <path
        d="m 379.35,450.69 a 102.033,102.033 0 0 0 7.29,2.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2663"
      />
      <path
        d="m 379.17,451.15 a 102.531,102.531 0 0 0 7.33,2.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2664"
      />
      <path
        d="m 384.74,449.93 h -1.66"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2665"
      />
      <path
        d="m 383.08,449.93 v 1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2666"
      />
      <path
        d="m 383.08,451.46 h 1.66"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2667"
      />
      <path
        d="m 384.74,451.46 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2668"
      />
      <path
        d="m 398.17,459.04 v 0.94 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2669"
      />
      <path
        d="m 398.17,459.98 h -3.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2670"
      />
      <path
        d="m 395.09,459.98 v -0.94 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2671"
      />
      <path
        d="m 260.36,433.6 v 2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2672"
      />
      <path
        d="M 74.77,347.91 H 72.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2673"
      />
      <path
        d="m 47.16,337.27 h 8.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2674"
      />
      <path
        d="m 47.16,307.11 h 9.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2675"
      />
      <path
        d="m 47.16,309.85 h 8.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2676"
      />
      <path
        d="m 47.16,312.59 h 9.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2677"
      />
      <path
        d="m 47.16,315.33 h 9.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2678"
      />
      <path
        d="m 47.16,318.08 h 9.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2679"
      />
      <path
        d="m 47.16,320.82 h 9.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2680"
      />
      <path
        d="m 47.16,323.56 h 9.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2681"
      />
      <path
        d="m 47.16,326.3 h 9.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2682"
      />
      <path
        d="m 47.16,329.05 h 8.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2683"
      />
      <path
        d="m 47.16,331.79 h 8.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2684"
      />
      <path
        d="m 47.16,334.53 h 8.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2685"
      />
      <path
        d="m 56.32,339.21 v -33.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2686"
      />
      <path
        d="m 56.32,339.21 a 0.801,0.801 0 0 0 0.8,0.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2687"
      />
      <path
        d="m 55.32,339.21 a 1.798,1.798 0 0 0 1.8,1.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2688"
      />
      <path
        d="m 55.32,339.21 v -33.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2689"
      />
      <path
        d="M 365.48,436.09 H 271.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2690"
      />
      <path
        d="m 271.33,436.09 v 2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2691"
      />
      <path
        d="M 271.33,439.08 H 256.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2692"
      />
      <path
        d="M 256.37,439.08 H 70.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2693"
      />
      <path
        d="M 256.37,436.59 H 72.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2694"
      />
      <path
        d="M 70.28,439.08 V 281.98 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2695"
      />
      <path
        d="M 365.48,433.6 H 352.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2696"
      />
      <path
        d="M 324.68,433.6 H 304.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2697"
      />
      <path
        d="m 277.31,433.6 h -8.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2698"
      />
      <path
        d="M 256.37,436.59 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2699"
      />
      <path
        d="M 256.37,436.59 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2700"
      />
      <path
        d="m 46.16,340.17 v 11.31 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2707"
      />
      <path
        d="M 70.35,352.23 H 45.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2708"
      />
      <path
        d="m 60.31,288.11 v 1.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2709"
      />
      <path
        d="M 45.41,288.11 H 70.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2710"
      />
      <path
        d="M 70.28,289.33 H 45.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2711"
      />
      <path
        d="m 62.98,354.23 v -2.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2712"
      />
      <path
        d="m 53,354.23 v -2.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2713"
      />
      <path
        d="m 53,354.23 v -2.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2714"
      />
      <path
        d="m 53,351.48 h 9.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2715"
      />
      <path
        d="m 62.98,351.48 v 2.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2716"
      />
      <path
        d="M 62.98,354.23 H 53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2717"
      />
      <path
        d="m 53,352.48 h 9.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2718"
      />
      <path
        d="m 53,352.23 h 9.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2719"
      />
      <path
        d="m 53,353.23 h 9.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2720"
      />
      <path
        d="m 56.71,352.48 v 0.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2721"
      />
      <path
        d="m 56.21,352.48 v 0.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2722"
      />
      <path
        d="m 62.48,352.48 v 0.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2723"
      />
      <path
        d="m 53.5,352.48 v 0.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2724"
      />
      <path
        d="m 53,352.48 v 0.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2725"
      />
      <path
        d="M 45.16,347.65 V 332.69 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2726"
      />
      <path
        d="M 45.41,347.65 V 332.69 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2727"
      />
      <path
        d="M 44.41,347.65 V 332.69 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2728"
      />
      <path
        d="M 45.16,337.85 H 44.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2729"
      />
      <path
        d="M 45.16,338.35 H 44.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2730"
      />
      <path
        d="M 45.16,333.19 H 44.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2731"
      />
      <path
        d="M 45.16,347.15 H 44.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2732"
      />
      <path
        d="m 43.42,347.65 h 2.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2733"
      />
      <path
        d="M 46.16,347.65 V 332.69 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2734"
      />
      <path
        d="M 46.16,332.69 H 43.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2735"
      />
      <path
        d="m 43.42,332.69 v 14.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2736"
      />
      <path
        d="M 46.16,340.17 H 45.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2737"
      />
      <path
        d="M 71.94,342.75 H 69.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2738"
      />
      <path
        d="M 69.53,337.27 H 60.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2739"
      />
      <path
        d="M 60.37,339.21 V 326.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2740"
      />
      <path
        d="M 59.38,339.21 V 326.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2741"
      />
      <path
        d="M 58.57,340.02 H 57.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2742"
      />
      <path
        d="m 58.57,340.02 a 0.801,0.801 0 0 0 0.81,-0.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2743"
      />
      <path
        d="m 58.57,341.01 a 1.798,1.798 0 0 0 1.8,-1.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2744"
      />
      <path
        d="M 58.57,341.01 H 57.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2745"
      />
      <path
        d="M 69.53,337.27 H 60.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2746"
      />
      <path
        d="M 69.53,323.55 H 66.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2747"
      />
      <path
        d="M 69.53,323.56 H 66.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2748"
      />
      <path
        d="M 65.3,323.56 H 63.67"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2749"
      />
      <path
        d="M 69.53,326.3 H 60.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2750"
      />
      <path
        d="M 69.53,329.05 H 60.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2751"
      />
      <path
        d="M 69.53,331.79 H 60.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2752"
      />
      <path
        d="M 69.53,334.53 H 60.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2753"
      />
      <path
        d="m 51.24,307.11 v 37.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2754"
      />
      <path
        d="M 51.24,344.73 H 64.65"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2755"
      />
      <path
        d="m 57.81,327.54 5.86,-3.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2756"
      />
      <path
        d="m 66.44,322.59 3.84,-2.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2757"
      />
      <path
        d="m 63.67,324.18 v -1.93 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2758"
      />
      <path
        d="m 63.67,322.25 2.77,2.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2759"
      />
      <path
        d="m 66.44,324.48 v -1.89 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2760"
      />
      <path
        d="m 64.65,325.45 v 19.28 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2761"
      />
      <path
        d="m 64.65,325.45 1.51,2.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2762"
      />
      <path
        d="m 64.65,325.45 -1.51,2.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2763"
      />
      <path
        d="M 50.96,307.21 V 307 l -0.02,0.11 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2764"
      />
      <path
        d="m 50.96,307.21 0.05,0.09 -0.05,-0.3 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2765"
      />
      <path
        d="m 51.01,307.3 v -0.39 L 50.96,307 Z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2766"
      />
      <path
        d="m 51.01,307.3 0.08,0.07 -0.08,-0.46 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2767"
      />
      <path
        d="m 51.09,307.37 v -0.52 l -0.08,0.06 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2768"
      />
      <path
        d="m 51.09,307.37 0.1,0.03 -0.1,-0.55 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2769"
      />
      <path
        d="m 51.19,307.4 v -0.59 l -0.1,0.04 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2770"
      />
      <path
        d="m 51.19,307.4 h 0.11 l -0.11,-0.59 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2771"
      />
      <path
        d="m 51.3,307.4 v -0.59 h -0.11 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2772"
      />
      <path
        d="m 51.3,307.4 0.09,-0.03 -0.09,-0.56 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2773"
      />
      <path
        d="m 51.39,307.37 v -0.52 l -0.09,-0.04 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2774"
      />
      <path
        d="m 51.39,307.37 0.08,-0.07 -0.08,-0.45 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2775"
      />
      <path
        d="m 51.47,307.3 v -0.39 l -0.08,-0.06 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2776"
      />
      <path
        d="m 51.47,307.3 0.06,-0.09 -0.06,-0.3 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2777"
      />
      <path
        d="M 51.53,307.21 V 307 l -0.06,-0.09 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2778"
      />
      <path
        d="m 51.53,307.21 0.01,-0.1 -0.01,-0.11 z"
        fill="#000000"
        stroke="#000000"
        stroke-width="0.2"
        id="path2779"
      />
      <path
        d="m 51.54,307.11 -0.08,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2780"
      />
      <path
        d="m 51.46,307.32 -0.22,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2781"
      />
      <path
        d="m 51.24,307.41 -0.21,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2782"
      />
      <path
        d="m 51.03,307.32 -0.09,-0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2783"
      />
      <path
        d="m 50.94,307.11 0.09,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2784"
      />
      <path
        d="m 51.03,306.89 0.21,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2785"
      />
      <path
        d="m 51.24,306.81 0.22,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2786"
      />
      <path
        d="m 51.46,306.89 0.08,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2787"
      />
      <path
        d="M 46.78,342.26 V 302.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2788"
      />
      <path
        d="M 47.16,342.3 47.15,302.72 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2789"
      />
      <path
        d="m 46.16,342.78 a 0.562,0.562 0 0 0 0.62,-0.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2790"
      />
      <path
        d="m 46.16,343.15 a 0.936,0.936 0 0 0 1,-0.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2791"
      />
      <path
        d="m 46.78,302.8 a 1.546,1.546 0 0 0 -1.37,-1.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2792"
      />
      <path
        d="m 47.15,302.72 a 1.92,1.92 0 0 0 -1.7,-1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2793"
      />
      <path
        d="M 69.91,343.89 V 320.61 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2794"
      />
      <path
        d="m 69.91,343.89 a 0.562,0.562 0 0 0 0.37,0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2795"
      />
      <path
        d="M 46.16,351.48 H 69.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2796"
      />
      <path
        d="m 69.53,337.27 v 14.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2797"
      />
      <path
        d="m 69.53,343.92 0.01,-23.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2798"
      />
      <path
        d="m 69.53,343.92 a 0.936,0.936 0 0 0 0.75,0.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2799"
      />
      <path
        d="m 60.31,289.33 0.17,1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2800"
      />
      <path
        d="m 60.48,291.08 0.51,1.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2801"
      />
      <path
        d="m 60.99,292.76 0.83,1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2802"
      />
      <path
        d="m 61.82,294.31 1.12,1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2803"
      />
      <path
        d="m 62.94,295.67 1.36,1.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2804"
      />
      <path
        d="m 64.3,296.79 1.55,0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2805"
      />
      <path
        d="m 65.85,297.62 1.69,0.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2806"
      />
      <path
        d="m 67.54,298.13 1.75,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2807"
      />
      <path
        d="M 69.29,289.33 H 68.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2808"
      />
      <path
        d="m 68.85,289.33 v 8.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2809"
      />
      <path
        d="m 69.29,298.3 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2810"
      />
      <path
        d="m 45.41,288.41 h 0.81 v 0.61 h -0.81 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2949"
      />
      <path
        d="m 47,288.41 h 1.56 v 0.61 H 47 Z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2950"
      />
      <path
        d="m 49.34,288.41 h 1.56 v 0.61 h -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2951"
      />
      <path
        d="m 51.68,288.41 h 1.55 v 0.61 h -1.55 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2952"
      />
      <path
        d="m 54.01,288.41 h 1.56 v 0.61 h -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2953"
      />
      <path
        d="m 56.35,288.41 h 1.56 v 0.61 h -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2954"
      />
      <path
        d="m 58.69,288.41 h 1.56 v 0.61 h -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2955"
      />
      <path
        d="m 61.03,288.41 h 1.55 v 0.61 h -1.55 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2956"
      />
      <path
        d="m 63.36,288.41 h 1.56 v 0.61 h -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2957"
      />
      <path
        d="m 65.7,288.41 h 1.56 v 0.61 H 65.7 Z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2958"
      />
      <path
        d="m 68.04,288.41 h 1.56 v 0.61 h -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2959"
      />
      <path
        d="m 70.38,288.41 h 0.81 v 0.61 h -0.81 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2960"
      />
      <path
        d="m 71.49,288.72 v 0.91 h -0.61 v -0.91 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2961"
      />
      <path
        d="m 71.49,290.41 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2962"
      />
      <path
        d="m 71.49,292.75 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2963"
      />
      <path
        d="m 71.49,295.09 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2964"
      />
      <path
        d="m 71.49,297.42 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2965"
      />
      <path
        d="m 71.49,299.76 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2966"
      />
      <path
        d="m 71.49,302.1 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2967"
      />
      <path
        d="m 71.49,304.44 v 1.55 h -0.61 v -1.55 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2968"
      />
      <path
        d="m 71.49,306.77 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2969"
      />
      <path
        d="m 71.49,309.11 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2970"
      />
      <path
        d="m 71.49,311.45 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2971"
      />
      <path
        d="m 71.49,313.79 v 1.55 h -0.61 v -1.55 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2972"
      />
      <path
        d="m 71.49,316.12 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2973"
      />
      <path
        d="m 71.49,318.46 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2974"
      />
      <path
        d="m 71.49,320.8 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2975"
      />
      <path
        d="m 71.49,323.14 v 1.55 h -0.61 v -1.55 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2976"
      />
      <path
        d="m 71.49,325.47 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2977"
      />
      <path
        d="m 71.49,327.81 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2978"
      />
      <path
        d="m 71.49,330.15 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2979"
      />
      <path
        d="m 71.49,332.49 v 1.55 h -0.61 v -1.55 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2980"
      />
      <path
        d="m 71.49,334.82 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2981"
      />
      <path
        d="m 71.49,337.16 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2982"
      />
      <path
        d="m 71.49,339.5 v 1.56 h -0.61 v -1.56 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2983"
      />
      <path
        d="m 71.49,341.83 v 0.92 h -0.61 v -0.92 z"
        fill-rule="nonzero"
        fill="#000000"
        id="path2984"
      />
      <path
        d="m 125.23,434.59 h 1.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2993"
      />
      <path
        d="m 263.73,431.6 h -1.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2994"
      />
      <path
        d="m 204.01,158.84 v 5.81 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2996"
      />
      <path
        d="m 202.77,158.84 v 4.56 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2997"
      />
      <path
        d="m 204.01,164.65 h -4.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2998"
      />
      <path
        d="m 202.77,163.4 h -4.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path2999"
      />
      <path
        d="M 395.35,455.71 H 393.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3004"
      />
      <path
        d="m 393.8,455.71 v 1.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3005"
      />
      <path
        d="m 393.8,457.54 h 1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3006"
      />
      <path
        d="m 395.35,457.54 v -1.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3007"
      />
      <path
        d="m 386.64,459.04 8.45,0.94"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3008"
      />
      <path
        d="m 43.42,299.43 -1.75,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3009"
      />
      <path
        d="m 41.67,299.26 -1.69,-0.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3010"
      />
      <path
        d="m 39.98,298.75 -1.55,-0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3011"
      />
      <path
        d="M 38.43,297.92 37.07,296.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3012"
      />
      <path
        d="m 37.07,296.8 -1.11,-1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3013"
      />
      <path
        d="m 35.96,295.44 -0.83,-1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3014"
      />
      <path
        d="m 35.13,293.89 -0.51,-1.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3015"
      />
      <path
        d="m 34.62,292.21 -0.18,-1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3016"
      />
      <path
        d="m 43.42,290.46 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3017"
      />
      <path
        d="M 43.42,290.89 H 34.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3018"
      />
      <path
        d="m 34.44,290.46 h 8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3019"
      />
      <path
        d="m 43.42,299.43 h 1.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3020"
      />
      <path
        d="m 43.42,290.46 h 1.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3021"
      />
      <path
        d="m 43.42,290.02 h 1.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3022"
      />
      <path
        d="m 70.35,254.71 h 1.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3023"
      />
      <path
        d="m 70.35,255.14 h 2.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3024"
      />
      <path
        d="m 70.35,245.73 h 2.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3025"
      />
      <path
        d="m 70.35,242.73 h 2.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3026"
      />
      <path
        d="m 71.59,242.23 h 1.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3027"
      />
      <path
        d="M 72.83,216.35 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3087"
      />
      <path
        d="M 72.83,215.85 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3088"
      />
      <path
        d="M 72.83,219.38 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3089"
      />
      <path
        d="M 72.83,219.87 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3090"
      />
      <path
        d="m 71.59,238.81 h 1.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3091"
      />
      <path
        d="m 71.59,238.31 h 1.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3092"
      />
      <path
        d="M 359.38,262.59 H 324.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3093"
      />
      <path
        d="m 396.93,171.83 h 6.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3094"
      />
      <path
        d="m 402.97,171.83 v 26.11 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3095"
      />
      <path
        d="M 402.97,197.94 H 394"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3096"
      />
      <path
        d="m 394,222.62 v 7.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3097"
      />
      <path
        d="m 394,230.35 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3098"
      />
      <path
        d="m 395.24,229.1 h 8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3099"
      />
      <path
        d="m 402.97,230.35 v 31 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3100"
      />
      <path
        d="m 404.22,229.1 v 33.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3101"
      />
      <path
        d="M 398.17,170.58 V 140.95 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3102"
      />
      <path
        d="m 398.17,170.58 h 9.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3103"
      />
      <path
        d="m 398.17,169.33 h 9.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3105"
      />
      <path
        d="m 404.22,170.58 v 28.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3107"
      />
      <path
        d="m 404.22,199.19 h -8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3209"
      />
      <path
        d="m 395.24,222.19 v 6.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3210"
      />
      <path
        d="m 394,199.19 h 1.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3211"
      />
      <path
        d="m 395.24,204.67 v -5.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3212"
      />
      <path
        d="m 393.99,204.67 v -5.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3213"
      />
      <path
        d="m 395.24,204.67 h -1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3214"
      />
      <path
        d="m 395.24,204.17 h -1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3215"
      />
      <path
        d="m 395.24,199.68 h -1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3216"
      />
      <path
        d="m 395.24,199.19 h -1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3217"
      />
      <path
        d="m 394.62,199.68 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3218"
      />
      <path
        d="m 395.24,213.65 1.75,-0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3219"
      />
      <path
        d="m 396.99,213.47 1.68,-0.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3220"
      />
      <path
        d="m 398.67,212.96 1.55,-0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3221"
      />
      <path
        d="m 400.22,212.13 1.36,-1.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3222"
      />
      <path
        d="m 401.58,211.02 1.12,-1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3223"
      />
      <path
        d="m 402.7,209.66 0.83,-1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3224"
      />
      <path
        d="m 403.53,208.11 0.51,-1.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3225"
      />
      <path
        d="m 404.04,206.42 0.17,-1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3226"
      />
      <path
        d="m 395.24,204.67 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3227"
      />
      <path
        d="m 395.24,205.11 h 8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3228"
      />
      <path
        d="m 404.21,204.67 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3229"
      />
      <path
        d="m 395.24,213.65 1.75,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3230"
      />
      <path
        d="m 396.99,213.82 1.68,0.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3231"
      />
      <path
        d="m 398.67,214.33 1.55,0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3232"
      />
      <path
        d="m 400.22,215.16 1.36,1.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3233"
      />
      <path
        d="m 401.58,216.28 1.12,1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3234"
      />
      <path
        d="m 402.7,217.64 0.83,1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3235"
      />
      <path
        d="m 403.53,219.19 0.51,1.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3236"
      />
      <path
        d="m 404.04,220.87 0.17,1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3237"
      />
      <path
        d="m 395.24,222.62 v -0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3238"
      />
      <path
        d="m 395.24,222.19 h 8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3239"
      />
      <path
        d="m 404.21,222.62 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3240"
      />
      <path
        d="M 395.24,222.62 H 394"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3241"
      />
      <path
        d="m 395.24,228.61 v -5.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3242"
      />
      <path
        d="m 393.99,228.61 v -5.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3243"
      />
      <path
        d="m 395.24,228.61 h -1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3244"
      />
      <path
        d="m 395.24,228.11 h -1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3245"
      />
      <path
        d="m 395.24,223.62 h -1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3246"
      />
      <path
        d="m 395.24,223.12 h -1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3247"
      />
      <path
        d="m 394.62,223.62 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3248"
      />
      <path
        d="M 360.73,261.71 H 364"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3249"
      />
      <path
        d="m 364,263.24 v -3.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3250"
      />
      <path
        d="m 360.73,263.24 v -3.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3251"
      />
      <path
        d="M 72.83,197.53 V 170.1 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3252"
      />
      <path
        d="M 71.59,197.53 V 170.1 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3253"
      />
      <path
        d="M 74.83,197.53 V 170.1 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3254"
      />
      <path
        d="M 72.83,170.6 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3255"
      />
      <path
        d="M 72.83,173.95 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3256"
      />
      <path
        d="M 72.83,174.45 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3257"
      />
      <path
        d="M 72.83,192.92 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3258"
      />
      <path
        d="M 72.83,193.42 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3259"
      />
      <path
        d="M 72.83,197.03 H 71.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3260"
      />
      <path
        d="M 70.35,197.53 V 170.1 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3261"
      />
      <path
        d="m 70.35,197.53 h 4.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3262"
      />
      <path
        d="m 70.35,170.1 h 4.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3263"
      />
      <path
        d="m 84.09,158.84 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3264"
      />
      <path
        d="m 84.09,157.59 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3265"
      />
      <path
        d="m 84.09,160.84 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3266"
      />
      <path
        d="m 111.02,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3267"
      />
      <path
        d="m 107.66,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3268"
      />
      <path
        d="m 107.17,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3269"
      />
      <path
        d="m 88.7,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3270"
      />
      <path
        d="m 88.2,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3271"
      />
      <path
        d="m 84.59,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3272"
      />
      <path
        d="m 84.09,156.35 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3273"
      />
      <path
        d="m 84.09,156.35 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3274"
      />
      <path
        d="m 111.51,156.35 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3275"
      />
      <path
        d="m 133.21,158.84 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3276"
      />
      <path
        d="m 133.21,157.59 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3277"
      />
      <path
        d="m 133.21,160.84 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3278"
      />
      <path
        d="m 160.13,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3279"
      />
      <path
        d="m 156.78,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3280"
      />
      <path
        d="m 156.28,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3281"
      />
      <path
        d="m 137.82,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3282"
      />
      <path
        d="m 137.32,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3283"
      />
      <path
        d="m 133.7,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3284"
      />
      <path
        d="m 133.21,156.35 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3285"
      />
      <path
        d="m 133.21,156.35 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3286"
      />
      <path
        d="m 160.63,156.35 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3287"
      />
      <path
        d="M 181.08,158.84 H 208.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3288"
      />
      <path
        d="M 181.08,157.59 H 208.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3289"
      />
      <path
        d="M 181.08,160.84 H 208.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3290"
      />
      <path
        d="m 208,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3291"
      />
      <path
        d="m 204.65,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3292"
      />
      <path
        d="m 204.15,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3293"
      />
      <path
        d="m 185.69,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3294"
      />
      <path
        d="m 185.19,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3295"
      />
      <path
        d="m 181.57,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3296"
      />
      <path
        d="M 181.08,156.35 H 208.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3297"
      />
      <path
        d="m 181.08,156.35 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3298"
      />
      <path
        d="m 208.5,156.35 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3299"
      />
      <path
        d="m 228.95,158.84 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3300"
      />
      <path
        d="m 228.95,157.59 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3301"
      />
      <path
        d="m 228.95,160.84 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3302"
      />
      <path
        d="m 255.87,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3303"
      />
      <path
        d="m 252.52,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3304"
      />
      <path
        d="m 252.02,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3305"
      />
      <path
        d="m 233.55,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3306"
      />
      <path
        d="m 233.06,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3307"
      />
      <path
        d="m 229.44,158.84 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3308"
      />
      <path
        d="m 228.95,156.35 h 27.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3309"
      />
      <path
        d="m 228.95,156.35 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3310"
      />
      <path
        d="m 256.37,156.35 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3311"
      />
      <path
        d="m 276.81,161.83 h 27.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3312"
      />
      <path
        d="m 276.81,160.58 h 27.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3313"
      />
      <path
        d="m 276.81,163.83 h 27.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3314"
      />
      <path
        d="m 303.74,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3315"
      />
      <path
        d="m 300.39,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3316"
      />
      <path
        d="m 299.89,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3317"
      />
      <path
        d="m 281.42,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3318"
      />
      <path
        d="m 280.93,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3319"
      />
      <path
        d="m 277.31,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3320"
      />
      <path
        d="m 276.81,159.34 h 27.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3321"
      />
      <path
        d="m 276.81,159.34 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3322"
      />
      <path
        d="m 304.24,159.34 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3323"
      />
      <path
        d="m 258.96,160.84 v 2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3324"
      />
      <path
        d="m 276.81,162.83 h 2.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3325"
      />
      <path
        d="m 279.78,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3326"
      />
      <path
        d="m 282.58,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3327"
      />
      <path
        d="m 285.39,162.83 h 1.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3328"
      />
      <path
        d="m 288.19,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3329"
      />
      <path
        d="m 290.99,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3330"
      />
      <path
        d="m 293.8,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3331"
      />
      <path
        d="m 296.6,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3332"
      />
      <path
        d="m 299.41,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3333"
      />
      <path
        d="m 302.21,162.83 h 2.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3334"
      />
      <path
        d="m 324.68,161.83 h 27.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3335"
      />
      <path
        d="m 324.68,160.58 h 27.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3336"
      />
      <path
        d="m 324.68,163.83 h 27.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3337"
      />
      <path
        d="m 351.61,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3338"
      />
      <path
        d="m 348.26,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3339"
      />
      <path
        d="m 347.76,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3340"
      />
      <path
        d="m 329.29,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3341"
      />
      <path
        d="m 328.8,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3342"
      />
      <path
        d="m 325.18,161.83 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3343"
      />
      <path
        d="m 324.68,159.34 h 27.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3344"
      />
      <path
        d="m 324.68,159.34 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3345"
      />
      <path
        d="m 352.11,159.34 v 4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3346"
      />
      <path
        d="m 324.68,162.83 h 1.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3347"
      />
      <path
        d="m 327.03,162.83 h 1.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3348"
      />
      <path
        d="m 329.7,162.83 h 2.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3349"
      />
      <path
        d="m 332.96,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3350"
      />
      <path
        d="m 335.76,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3351"
      />
      <path
        d="m 338.57,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3352"
      />
      <path
        d="m 341.37,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3353"
      />
      <path
        d="m 344.18,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3354"
      />
      <path
        d="m 346.98,162.83 h 1.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3355"
      />
      <path
        d="m 349.79,162.83 h 2.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3356"
      />
      <path
        d="M 72.77,410.85 V 383.43 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3452"
      />
      <path
        d="M 71.52,410.85 V 383.43 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3453"
      />
      <path
        d="M 74.77,410.85 V 383.43 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3454"
      />
      <path
        d="M 72.77,383.92 H 71.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3455"
      />
      <path
        d="M 72.77,387.28 H 71.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3456"
      />
      <path
        d="M 72.77,387.78 H 71.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3457"
      />
      <path
        d="M 72.77,406.24 H 71.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3458"
      />
      <path
        d="M 72.77,406.74 H 71.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3459"
      />
      <path
        d="M 72.77,410.35 H 71.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3460"
      />
      <path
        d="M 70.28,410.85 V 383.43 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3461"
      />
      <path
        d="m 70.28,410.85 h 4.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3462"
      />
      <path
        d="m 70.28,383.43 h 4.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3463"
      />
      <path
        d="M 74.77,383.43 V 336.11 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3464"
      />
      <path
        d="m 74.77,410.85 v 23.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3465"
      />
      <path
        d="M 160.63,436.59 H 133.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3466"
      />
      <path
        d="M 160.63,437.84 H 133.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3467"
      />
      <path
        d="M 160.63,434.59 H 133.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3468"
      />
      <path
        d="m 133.7,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3469"
      />
      <path
        d="m 137.06,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3470"
      />
      <path
        d="m 137.56,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3471"
      />
      <path
        d="m 156.02,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3472"
      />
      <path
        d="m 156.52,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3473"
      />
      <path
        d="m 160.13,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3474"
      />
      <path
        d="M 160.63,439.08 H 133.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3475"
      />
      <path
        d="m 160.63,439.08 v -4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3476"
      />
      <path
        d="m 133.21,439.08 v -4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3477"
      />
      <path
        d="M 208.5,436.59 H 181.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3478"
      />
      <path
        d="M 208.5,437.84 H 181.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3479"
      />
      <path
        d="M 208.5,434.59 H 181.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3480"
      />
      <path
        d="m 181.57,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3481"
      />
      <path
        d="m 184.93,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3482"
      />
      <path
        d="m 185.43,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3483"
      />
      <path
        d="m 203.89,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3484"
      />
      <path
        d="m 204.39,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3485"
      />
      <path
        d="m 208,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3486"
      />
      <path
        d="M 208.5,439.08 H 181.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3487"
      />
      <path
        d="m 208.5,439.08 v -4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3488"
      />
      <path
        d="m 181.08,439.08 v -4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3489"
      />
      <path
        d="M 256.37,436.59 H 228.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3490"
      />
      <path
        d="M 256.37,437.84 H 228.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3491"
      />
      <path
        d="M 256.37,434.59 H 228.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3492"
      />
      <path
        d="m 229.44,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3493"
      />
      <path
        d="m 232.8,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3494"
      />
      <path
        d="m 233.29,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3495"
      />
      <path
        d="m 251.76,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3496"
      />
      <path
        d="m 252.26,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3497"
      />
      <path
        d="m 255.87,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3498"
      />
      <path
        d="M 256.37,439.08 H 228.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3499"
      />
      <path
        d="m 256.37,439.08 v -4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3500"
      />
      <path
        d="m 228.95,439.08 v -4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3501"
      />
      <path
        d="M 304.24,433.6 H 276.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3502"
      />
      <path
        d="M 304.24,434.85 H 276.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3503"
      />
      <path
        d="M 304.24,431.6 H 276.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3504"
      />
      <path
        d="m 277.31,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3505"
      />
      <path
        d="m 280.67,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3506"
      />
      <path
        d="m 281.16,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3507"
      />
      <path
        d="m 299.63,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3508"
      />
      <path
        d="m 300.13,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3509"
      />
      <path
        d="m 303.74,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3510"
      />
      <path
        d="M 304.24,436.09 H 276.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3511"
      />
      <path
        d="M 304.24,436.09 V 431.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3512"
      />
      <path
        d="M 276.81,436.09 V 431.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3513"
      />
      <path
        d="M 352.11,433.6 H 324.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3514"
      />
      <path
        d="M 352.11,434.85 H 324.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3515"
      />
      <path
        d="M 352.11,431.6 H 324.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3516"
      />
      <path
        d="m 325.18,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3517"
      />
      <path
        d="m 328.54,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3518"
      />
      <path
        d="m 329.03,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3519"
      />
      <path
        d="m 347.5,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3520"
      />
      <path
        d="m 348,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3521"
      />
      <path
        d="m 351.61,433.6 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3522"
      />
      <path
        d="M 352.11,436.09 H 324.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3523"
      />
      <path
        d="M 352.11,436.09 V 431.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3524"
      />
      <path
        d="M 324.68,436.09 V 431.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3525"
      />
      <path
        d="m 55.32,306.11 v -4.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3768"
      />
      <path
        d="M 69.53,340.02 H 60.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3769"
      />
      <path
        d="M 69.53,340.02 H 64.65"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3770"
      />
      <path
        d="m 46.16,340.02 h 9.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3771"
      />
      <path
        d="m 254.27,434.59 h -2.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3772"
      />
      <path
        d="m 371.66,325.36 v 15.02 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3773"
      />
      <path
        d="m 391.68,149.46 v 1.34 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3816"
      />
      <path
        d="m 390.43,149.72 v 2.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3817"
      />
      <path
        d="m 391.68,150.8 h 5.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3818"
      />
      <path
        d="m 390.43,152.05 h 6.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3819"
      />
      <path
        d="m 264.6,345.27 h 3.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3846"
      />
      <path
        d="m 267.87,346.81 v -3.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3847"
      />
      <path
        d="m 264.6,346.81 v -3.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3848"
      />
      <path
        d="m 360.53,345.27 h 3.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3849"
      />
      <path
        d="m 363.8,346.81 v -3.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3850"
      />
      <path
        d="m 360.53,346.81 v -3.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3851"
      />
      <path
        d="m 263.72,343.29 h 4.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3855"
      />
      <path
        d="m 268.52,343.29 v 4.95 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3856"
      />
      <path
        d="m 268.52,348.24 h -4.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3857"
      />
      <path
        d="m 263.72,348.24 v -4.95 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3858"
      />
      <path
        d="m 327.91,345.9 v -0.41 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3859"
      />
      <path
        d="m 326.72,345.9 h 1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3860"
      />
      <path
        d="m 326.72,345.49 v 0.41 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3861"
      />
      <path
        d="m 327.91,345.49 h -1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3862"
      />
      <path
        d="m 329.34,348.8 -0.07,0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3863"
      />
      <path
        d="m 329.27,349.15 -0.22,0.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3864"
      />
      <path
        d="m 329.05,349.41 -0.29,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3865"
      />
      <path
        d="m 325.87,349.5 -0.29,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3866"
      />
      <path
        d="m 325.58,349.41 -0.22,-0.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3867"
      />
      <path
        d="m 325.36,349.15 -0.07,-0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3868"
      />
      <path
        d="m 325.29,347.07 0.07,-0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3869"
      />
      <path
        d="m 325.36,346.72 0.22,-0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3870"
      />
      <path
        d="m 325.58,346.47 0.29,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3871"
      />
      <path
        d="m 328.76,346.37 0.29,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3872"
      />
      <path
        d="m 329.05,346.47 0.22,0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3873"
      />
      <path
        d="m 329.27,346.72 0.07,0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3874"
      />
      <path
        d="m 329.34,348.8 v -1.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3875"
      />
      <path
        d="m 325.87,349.5 h 2.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3876"
      />
      <path
        d="m 325.29,347.07 v 1.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3877"
      />
      <path
        d="m 328.76,346.37 h -2.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3878"
      />
      <path
        d="m 329.74,349.91 v -4.85 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3879"
      />
      <path
        d="m 324.89,349.91 h 4.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3880"
      />
      <path
        d="m 324.89,345.06 v 4.85 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3881"
      />
      <path
        d="m 329.74,345.06 h -4.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3882"
      />
      <path
        d="m 345.39,347.07 v -1.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3883"
      />
      <path
        d="m 345.39,346.03 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3884"
      />
      <path
        d="m 345.23,346.03 v 1.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3885"
      />
      <path
        d="m 345.23,347.07 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3886"
      />
      <path
        d="m 345.39,355.01 v 1.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3887"
      />
      <path
        d="m 345.39,356.05 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3888"
      />
      <path
        d="m 345.23,356.05 v -1.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3889"
      />
      <path
        d="m 345.23,355.01 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3890"
      />
      <path
        d="m 332.45,345.05 h -1.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3891"
      />
      <path
        d="m 331.41,345.05 v 0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3892"
      />
      <path
        d="m 331.41,345.21 h 1.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3893"
      />
      <path
        d="m 332.45,345.21 v -0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3894"
      />
      <path
        d="m 341.88,345.05 1.04,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3895"
      />
      <path
        d="m 342.92,345.06 v 0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3896"
      />
      <path
        d="m 342.92,345.22 h -1.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3897"
      />
      <path
        d="m 341.88,345.22 v -0.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3898"
      />
      <path
        d="m 344.84,350.88 v -4.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3899"
      />
      <path
        d="M 344.84,346.78 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3900"
      />
      <path
        d="m 344.84,346.78 0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3901"
      />
      <path
        d="m 344.85,346.75 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3902"
      />
      <path
        d="m 344.87,346.72 0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3903"
      />
      <path
        d="m 344.91,346.71 h 0.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3904"
      />
      <path
        d="m 345.23,346.71 v -0.32 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3905"
      />
      <path
        d="m 345.23,346.39 h -0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3906"
      />
      <path
        d="M 344.84,346.39 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3907"
      />
      <path
        d="m 344.84,346.39 -0.16,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3908"
      />
      <path
        d="m 344.68,346.43 -0.12,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3909"
      />
      <path
        d="m 344.56,346.55 -0.04,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3910"
      />
      <path
        d="m 344.52,346.71 v 4.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3911"
      />
      <path
        d="m 344.52,350.88 -0.01,4.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3912"
      />
      <path
        d="M 344.51,355.36 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3913"
      />
      <path
        d="m 344.51,355.36 0.05,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3914"
      />
      <path
        d="m 344.56,355.53 0.12,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3915"
      />
      <path
        d="m 344.68,355.65 0.16,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3916"
      />
      <path
        d="m 344.84,355.69 h 0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3917"
      />
      <path
        d="m 345.23,355.69 v -0.32 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3918"
      />
      <path
        d="m 345.23,355.37 -0.33,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3919"
      />
      <path
        d="M 344.9,355.36 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3920"
      />
      <path
        d="m 344.9,355.36 h -0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3921"
      />
      <path
        d="m 344.87,355.36 -0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3922"
      />
      <path
        d="m 344.85,355.33 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3923"
      />
      <path
        d="m 344.84,355.3 v -4.42 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3924"
      />
      <path
        d="m 332.16,345.6 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3925"
      />
      <path
        d="m 332.12,345.59 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3926"
      />
      <path
        d="m 332.1,345.57 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3927"
      />
      <path
        d="m 332.09,345.54 v -0.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3928"
      />
      <path
        d="m 332.09,345.21 h -0.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3929"
      />
      <path
        d="m 331.77,345.21 v 0.39 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3930"
      />
      <path
        d="M 331.77,345.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3931"
      />
      <path
        d="m 331.77,345.6 0.04,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3932"
      />
      <path
        d="m 331.81,345.76 0.12,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3933"
      />
      <path
        d="m 331.93,345.88 0.16,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3934"
      />
      <path
        d="m 332.09,345.93 h 10.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3935"
      />
      <path
        d="M 342.24,345.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3936"
      />
      <path
        d="m 342.24,345.93 0.16,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3937"
      />
      <path
        d="m 342.4,345.89 0.12,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3938"
      />
      <path
        d="m 342.52,345.77 0.04,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3939"
      />
      <path
        d="m 342.56,345.61 v -0.39 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3940"
      />
      <path
        d="m 342.56,345.22 h -0.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3941"
      />
      <path
        d="m 342.24,345.22 v 0.32 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3942"
      />
      <path
        d="M 342.24,345.54 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3943"
      />
      <path
        d="m 342.24,345.54 -0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3944"
      />
      <path
        d="m 342.23,345.57 -0.02,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3945"
      />
      <path
        d="m 342.21,345.6 -0.04,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3946"
      />
      <path
        d="M 342.17,345.61 332.16,345.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3947"
      />
      <path
        d="m 341.06,347.67 0.06,0.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3948"
      />
      <path
        d="m 341.12,348.19 0.05,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3949"
      />
      <path
        d="m 341.17,348.58 0.06,0.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3950"
      />
      <path
        d="m 341.23,348.91 0.06,0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3951"
      />
      <path
        d="m 341.29,349.2 0.05,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3952"
      />
      <path
        d="m 341.34,349.42 0.04,0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3953"
      />
      <path
        d="m 341.38,349.6 0.04,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3954"
      />
      <path
        d="m 341.42,349.71 0.02,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3955"
      />
      <path
        d="m 341.44,349.81 0.01,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3956"
      />
      <path
        d="m 341.45,349.91 0.01,0.13 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3957"
      />
      <path
        d="m 341.46,350.04 0.01,0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3958"
      />
      <path
        d="m 341.47,350.22 v 0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3959"
      />
      <path
        d="m 341.47,350.45 -0.01,0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3960"
      />
      <path
        d="m 341.46,350.7 -0.04,0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3961"
      />
      <path
        d="m 341.42,350.98 -0.06,0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3962"
      />
      <path
        d="m 341.36,351.27 -0.09,0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3963"
      />
      <path
        d="m 341.27,351.56 -0.12,0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3964"
      />
      <path
        d="M 341.15,351.84 341,352.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3965"
      />
      <path
        d="m 341,352.11 -0.17,0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3966"
      />
      <path
        d="m 340.83,352.34 -0.19,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3967"
      />
      <path
        d="m 340.64,352.55 -0.21,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3968"
      />
      <path
        d="m 340.43,352.71 -0.21,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3969"
      />
      <path
        d="m 340.22,352.83 -0.21,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3970"
      />
      <path
        d="m 340.01,352.9 -0.2,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3971"
      />
      <path
        d="M 339.81,352.93 339.6,352.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3972"
      />
      <path
        d="m 339.6,352.9 -0.2,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3973"
      />
      <path
        d="m 339.4,352.83 -0.21,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3974"
      />
      <path
        d="m 339.19,352.71 -0.21,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3975"
      />
      <path
        d="m 338.98,352.54 -0.19,-0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3976"
      />
      <path
        d="m 338.79,352.34 -0.17,-0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3977"
      />
      <path
        d="m 338.62,352.11 -0.15,-0.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3978"
      />
      <path
        d="m 338.47,351.84 -0.12,-0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3979"
      />
      <path
        d="m 338.35,351.56 -0.09,-0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3980"
      />
      <path
        d="m 338.26,351.27 -0.06,-0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3981"
      />
      <path
        d="m 338.2,350.98 -0.04,-0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3982"
      />
      <path
        d="m 338.16,350.7 -0.01,-0.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3983"
      />
      <path
        d="m 338.15,350.44 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3984"
      />
      <path
        d="m 338.15,350.21 0.01,-0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3985"
      />
      <path
        d="m 338.16,350.03 0.01,-0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3986"
      />
      <path
        d="m 338.17,349.91 0.01,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3987"
      />
      <path
        d="m 338.18,349.81 0.02,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3988"
      />
      <path
        d="m 338.2,349.71 0.04,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3989"
      />
      <path
        d="m 338.24,349.59 0.04,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3990"
      />
      <path
        d="m 338.28,349.42 0.05,-0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3991"
      />
      <path
        d="m 338.33,349.19 0.06,-0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3992"
      />
      <path
        d="m 338.39,348.91 0.06,-0.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3993"
      />
      <path
        d="m 338.45,348.58 0.05,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3994"
      />
      <path
        d="m 338.5,348.19 0.05,-0.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3995"
      />
      <path
        d="m 338.55,347.78 v -0.11 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3996"
      />
      <path
        d="m 338.11,347.53 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3997"
      />
      <path
        d="m 338.07,347.52 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3998"
      />
      <path
        d="m 338.04,347.5 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path3999"
      />
      <path
        d="m 338.01,347.49 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4000"
      />
      <path
        d="m 337.98,347.48 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4001"
      />
      <path
        d="m 337.95,347.46 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4002"
      />
      <path
        d="m 337.92,347.44 -0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4003"
      />
      <path
        d="m 337.89,347.41 -0.04,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4004"
      />
      <path
        d="m 337.85,347.38 -0.04,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4005"
      />
      <path
        d="m 337.81,347.35 -0.04,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4006"
      />
      <path
        d="m 337.77,347.3 -0.05,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4007"
      />
      <path
        d="m 337.72,347.26 -0.04,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4008"
      />
      <path
        d="m 337.68,347.2 -0.05,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4009"
      />
      <path
        d="m 337.63,347.14 -0.04,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4010"
      />
      <path
        d="m 337.59,347.08 -0.04,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4011"
      />
      <path
        d="m 337.55,347.02 -0.03,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4012"
      />
      <path
        d="m 337.52,346.95 -0.02,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4013"
      />
      <path
        d="m 337.5,346.88 -0.02,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4014"
      />
      <path
        d="m 337.48,346.81 -0.02,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4015"
      />
      <path
        d="m 337.46,346.74 v -0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4016"
      />
      <path
        d="m 337.46,346.67 -0.01,-0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4017"
      />
      <path
        d="m 337.45,346.6 v -0.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4018"
      />
      <path
        d="m 337.45,346.52 v -0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4019"
      />
      <path
        d="m 337.45,346.46 v -0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4020"
      />
      <path
        d="m 337.45,346.39 v -0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4021"
      />
      <path
        d="m 337.45,346.32 v -0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4022"
      />
      <path
        d="m 337.45,346.26 v -0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4023"
      />
      <path
        d="m 337.45,346.21 0.01,-0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4024"
      />
      <path
        d="M 337.46,346.15 V 346.1 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4025"
      />
      <path
        d="m 337.46,346.1 0.01,-0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4026"
      />
      <path
        d="M 337.47,346.05 V 346 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4027"
      />
      <path
        d="m 337.47,346 0.01,-0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4028"
      />
      <path
        d="m 337.48,345.96 v -0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4029"
      />
      <path
        d="m 337.48,345.92 0.01,-0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4030"
      />
      <path
        d="m 337.49,345.88 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4031"
      />
      <path
        d="m 337.51,345.85 0.01,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4032"
      />
      <path
        d="m 337.52,345.82 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4033"
      />
      <path
        d="m 337.54,345.79 0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4034"
      />
      <path
        d="m 337.57,345.76 0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4035"
      />
      <path
        d="m 337.6,345.74 0.04,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4036"
      />
      <path
        d="m 337.64,345.72 0.05,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4037"
      />
      <path
        d="m 337.69,345.7 0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4038"
      />
      <path
        d="m 337.75,345.69 0.07,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4039"
      />
      <path
        d="m 337.82,345.68 0.08,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4040"
      />
      <path
        d="m 337.9,345.66 0.09,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4041"
      />
      <path
        d="m 337.99,345.65 h 0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4042"
      />
      <path
        d="m 338.09,345.65 0.12,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4043"
      />
      <path
        d="m 338.21,345.64 0.14,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4044"
      />
      <path
        d="m 338.35,345.63 h 0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4045"
      />
      <path
        d="m 338.5,345.63 0.16,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4046"
      />
      <path
        d="m 338.66,345.62 h 0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4047"
      />
      <path
        d="m 338.83,345.62 0.19,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4048"
      />
      <path
        d="m 339.02,345.61 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4049"
      />
      <path
        d="m 339.21,345.61 h 0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4050"
      />
      <path
        d="m 339.41,345.61 h 0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4051"
      />
      <path
        d="m 339.61,345.61 0.2,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4052"
      />
      <path
        d="m 339.81,345.6 0.21,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4053"
      />
      <path
        d="m 340.02,345.61 h 0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4054"
      />
      <path
        d="m 340.22,345.61 h 0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4055"
      />
      <path
        d="m 340.42,345.61 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4056"
      />
      <path
        d="m 340.61,345.61 0.18,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4057"
      />
      <path
        d="m 340.79,345.62 h 0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4058"
      />
      <path
        d="m 340.97,345.62 0.16,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4059"
      />
      <path
        d="m 341.13,345.63 h 0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4060"
      />
      <path
        d="m 341.28,345.63 0.13,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4061"
      />
      <path
        d="m 341.41,345.64 0.12,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4062"
      />
      <path
        d="m 341.53,345.65 0.11,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4063"
      />
      <path
        d="m 341.64,345.66 0.09,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4064"
      />
      <path
        d="m 341.73,345.67 0.08,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4065"
      />
      <path
        d="m 341.81,345.68 0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4066"
      />
      <path
        d="m 341.88,345.7 0.05,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4067"
      />
      <path
        d="m 341.93,345.71 0.05,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4068"
      />
      <path
        d="m 341.98,345.73 0.04,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4069"
      />
      <path
        d="m 342.02,345.75 0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4070"
      />
      <path
        d="m 342.05,345.77 0.03,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4071"
      />
      <path
        d="m 342.08,345.8 0.02,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4072"
      />
      <path
        d="m 342.1,345.82 0.01,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4073"
      />
      <path
        d="m 342.11,345.85 0.02,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4074"
      />
      <path
        d="m 342.13,345.89 0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4075"
      />
      <path
        d="m 342.14,345.92 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4076"
      />
      <path
        d="m 342.14,345.96 0.01,0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4077"
      />
      <path
        d="m 342.15,346.01 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4078"
      />
      <path
        d="m 342.15,346.05 0.01,0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4079"
      />
      <path
        d="m 342.16,346.1 v 0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4080"
      />
      <path
        d="m 342.16,346.16 0.01,0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4081"
      />
      <path
        d="m 342.17,346.21 v 0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4082"
      />
      <path
        d="m 342.17,346.27 v 0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4083"
      />
      <path
        d="m 342.17,346.33 v 0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4084"
      />
      <path
        d="m 342.17,346.4 v 0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4085"
      />
      <path
        d="m 342.17,346.46 v 0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4086"
      />
      <path
        d="m 342.17,346.53 v 0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4087"
      />
      <path
        d="m 342.17,346.6 -0.01,0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4088"
      />
      <path
        d="m 342.16,346.67 -0.01,0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4089"
      />
      <path
        d="m 342.15,346.74 -0.01,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4090"
      />
      <path
        d="m 342.14,346.81 -0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4091"
      />
      <path
        d="m 342.12,346.88 -0.03,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4092"
      />
      <path
        d="m 342.09,346.95 -0.03,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4093"
      />
      <path
        d="m 342.06,347.01 -0.04,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4094"
      />
      <path
        d="m 342.02,347.08 -0.04,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4095"
      />
      <path
        d="m 341.98,347.14 -0.05,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4096"
      />
      <path
        d="m 341.93,347.2 -0.05,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4097"
      />
      <path
        d="m 341.88,347.26 -0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4098"
      />
      <path
        d="m 341.82,347.31 -0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4099"
      />
      <path
        d="m 341.76,347.36 -0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4100"
      />
      <path
        d="m 341.7,347.4 -0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4101"
      />
      <path
        d="m 341.64,347.45 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4102"
      />
      <path
        d="m 341.57,347.48 -0.06,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4103"
      />
      <path
        d="m 341.51,347.51 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4104"
      />
      <path
        d="m 341.44,347.54 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4105"
      />
      <path
        d="m 341.37,347.57 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4106"
      />
      <path
        d="m 341.3,347.59 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4107"
      />
      <path
        d="m 341.23,347.62 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4108"
      />
      <path
        d="m 341.16,347.64 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4109"
      />
      <path
        d="m 341.09,347.66 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4110"
      />
      <path
        d="m 341.02,347.68 -0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4111"
      />
      <path
        d="m 340.95,347.69 -0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4112"
      />
      <path
        d="m 340.87,347.71 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4113"
      />
      <path
        d="m 340.8,347.73 -0.08,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4114"
      />
      <path
        d="m 340.72,347.74 -0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4115"
      />
      <path
        d="m 340.64,347.76 -0.09,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4116"
      />
      <path
        d="m 340.55,347.77 -0.08,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4117"
      />
      <path
        d="m 340.47,347.78 -0.09,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4118"
      />
      <path
        d="m 340.38,347.79 -0.1,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4119"
      />
      <path
        d="m 340.28,347.8 h -0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4120"
      />
      <path
        d="m 340.19,347.8 -0.09,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4121"
      />
      <path
        d="M 340.1,347.81 H 340"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4122"
      />
      <path
        d="m 340,347.81 -0.1,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4123"
      />
      <path
        d="m 339.9,347.82 h -0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4124"
      />
      <path
        d="m 339.81,347.82 h -0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4125"
      />
      <path
        d="m 339.71,347.82 h -0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4126"
      />
      <path
        d="m 339.61,347.82 -0.09,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4127"
      />
      <path
        d="m 339.52,347.81 h -0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4128"
      />
      <path
        d="m 339.42,347.81 -0.09,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4129"
      />
      <path
        d="m 339.33,347.8 h -0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4130"
      />
      <path
        d="m 339.24,347.8 -0.08,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4131"
      />
      <path
        d="m 339.16,347.79 -0.08,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4132"
      />
      <path
        d="M 339.08,347.78 339,347.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4133"
      />
      <path
        d="m 339,347.77 -0.07,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4134"
      />
      <path
        d="m 338.93,347.76 -0.07,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4135"
      />
      <path
        d="m 338.86,347.75 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4136"
      />
      <path
        d="m 338.8,347.73 -0.07,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4137"
      />
      <path
        d="m 338.73,347.72 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4138"
      />
      <path
        d="m 338.67,347.7 -0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4139"
      />
      <path
        d="m 338.61,347.69 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4140"
      />
      <path
        d="m 338.55,347.67 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4141"
      />
      <path
        d="m 338.49,347.65 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4142"
      />
      <path
        d="m 338.43,347.63 -0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4143"
      />
      <path
        d="m 338.37,347.62 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4144"
      />
      <path
        d="m 338.31,347.6 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4145"
      />
      <path
        d="m 338.25,347.58 -0.05,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4146"
      />
      <path
        d="m 338.2,347.56 -0.05,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4147"
      />
      <path
        d="m 338.15,347.54 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4148"
      />
      <path
        d="m 346.64,343.97 22.69,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4149"
      />
      <path
        d="m 369.33,343.98 v 3.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4150"
      />
      <path
        d="M 369.33,347.73 H 346.64"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4151"
      />
      <path
        d="m 346.64,347.73 v -3.76 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4152"
      />
      <path
        d="m 377.13,299.52 0.52,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4153"
      />
      <path
        d="m 377.65,299.45 0.39,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4154"
      />
      <path
        d="m 378.04,299.4 0.33,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4155"
      />
      <path
        d="m 378.37,299.34 0.28,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4156"
      />
      <path
        d="m 378.65,299.29 0.23,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4157"
      />
      <path
        d="m 378.88,299.23 0.18,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4158"
      />
      <path
        d="m 379.06,299.19 0.11,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4159"
      />
      <path
        d="m 379.17,299.16 0.1,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4160"
      />
      <path
        d="m 379.27,299.13 0.1,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4161"
      />
      <path
        d="m 379.37,299.12 0.13,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4162"
      />
      <path
        d="m 379.5,299.11 0.18,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4163"
      />
      <path
        d="m 379.68,299.1 h 0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4164"
      />
      <path
        d="m 379.9,299.1 0.26,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4165"
      />
      <path
        d="m 380.16,299.12 0.28,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4166"
      />
      <path
        d="m 380.44,299.15 0.29,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4167"
      />
      <path
        d="m 380.73,299.22 0.29,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4168"
      />
      <path
        d="m 381.02,299.31 0.28,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4169"
      />
      <path
        d="m 381.3,299.42 0.27,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4170"
      />
      <path
        d="m 381.57,299.57 0.23,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4171"
      />
      <path
        d="m 381.8,299.74 0.2,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4172"
      />
      <path
        d="m 382,299.93 0.17,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4173"
      />
      <path
        d="m 382.17,300.14 0.12,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4174"
      />
      <path
        d="m 382.29,300.35 0.07,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4175"
      />
      <path
        d="m 382.36,300.56 0.03,0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4176"
      />
      <path
        d="m 382.39,300.76 -0.03,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4177"
      />
      <path
        d="m 382.36,300.97 -0.07,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4178"
      />
      <path
        d="m 382.29,301.18 -0.12,0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4179"
      />
      <path
        d="M 382.17,301.38 382,301.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4180"
      />
      <path
        d="m 382,301.59 -0.2,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4181"
      />
      <path
        d="m 381.8,301.78 -0.23,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4182"
      />
      <path
        d="m 381.57,301.95 -0.27,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4183"
      />
      <path
        d="m 381.3,302.1 -0.28,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4184"
      />
      <path
        d="m 381.02,302.22 -0.29,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4185"
      />
      <path
        d="m 380.73,302.31 -0.29,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4186"
      />
      <path
        d="m 380.44,302.37 -0.28,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4187"
      />
      <path
        d="m 380.16,302.41 -0.26,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4188"
      />
      <path
        d="m 379.9,302.42 h -0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4189"
      />
      <path
        d="m 379.67,302.42 -0.18,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4190"
      />
      <path
        d="m 379.49,302.41 h -0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4191"
      />
      <path
        d="m 379.37,302.41 -0.1,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4192"
      />
      <path
        d="m 379.27,302.39 -0.1,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4193"
      />
      <path
        d="m 379.17,302.37 -0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4194"
      />
      <path
        d="m 379.05,302.34 -0.17,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4195"
      />
      <path
        d="m 378.88,302.29 -0.23,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4196"
      />
      <path
        d="m 378.65,302.24 -0.28,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4197"
      />
      <path
        d="m 378.37,302.18 -0.33,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4198"
      />
      <path
        d="m 378.04,302.12 -0.39,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4199"
      />
      <path
        d="m 377.65,302.07 -0.41,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4200"
      />
      <path
        d="m 377.24,302.03 -0.11,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4201"
      />
      <path
        d="m 376.99,302.46 -0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4202"
      />
      <path
        d="m 376.98,302.5 -0.02,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4203"
      />
      <path
        d="m 376.96,302.54 -0.01,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4204"
      />
      <path
        d="m 376.95,302.57 -0.01,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4205"
      />
      <path
        d="m 376.94,302.6 -0.02,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4206"
      />
      <path
        d="m 376.92,302.62 -0.02,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4207"
      />
      <path
        d="m 376.9,302.65 -0.03,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4208"
      />
      <path
        d="m 376.87,302.69 -0.03,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4209"
      />
      <path
        d="m 376.84,302.72 -0.03,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4210"
      />
      <path
        d="m 376.81,302.76 -0.05,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4211"
      />
      <path
        d="m 376.76,302.81 -0.04,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4212"
      />
      <path
        d="m 376.72,302.85 -0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4213"
      />
      <path
        d="m 376.66,302.9 -0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4214"
      />
      <path
        d="m 376.6,302.94 -0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4215"
      />
      <path
        d="m 376.54,302.98 -0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4216"
      />
      <path
        d="m 376.48,303.02 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4217"
      />
      <path
        d="m 376.41,303.05 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4218"
      />
      <path
        d="m 376.34,303.08 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4219"
      />
      <path
        d="m 376.27,303.1 -0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4220"
      />
      <path
        d="m 376.2,303.11 -0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4221"
      />
      <path
        d="m 376.13,303.12 h -0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4222"
      />
      <path
        d="m 376.06,303.12 -0.08,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4223"
      />
      <path
        d="m 375.98,303.13 h -0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4224"
      />
      <path
        d="m 375.92,303.13 -0.07,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4225"
      />
      <path
        d="m 375.85,303.12 h -0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4226"
      />
      <path
        d="m 375.79,303.12 h -0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4227"
      />
      <path
        d="m 375.72,303.12 h -0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4228"
      />
      <path
        d="m 375.67,303.12 h -0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4229"
      />
      <path
        d="m 375.61,303.12 -0.05,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4230"
      />
      <path
        d="m 375.56,303.11 h -0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4231"
      />
      <path
        d="m 375.51,303.11 -0.05,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4232"
      />
      <path
        d="m 375.46,303.1 h -0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4233"
      />
      <path
        d="m 375.42,303.1 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4234"
      />
      <path
        d="m 375.38,303.09 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4235"
      />
      <path
        d="m 375.34,303.08 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4236"
      />
      <path
        d="m 375.31,303.07 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4237"
      />
      <path
        d="m 375.28,303.05 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4238"
      />
      <path
        d="m 375.25,303.03 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4239"
      />
      <path
        d="m 375.22,303.01 -0.02,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4240"
      />
      <path
        d="m 375.2,302.97 -0.02,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4241"
      />
      <path
        d="m 375.18,302.93 -0.02,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4242"
      />
      <path
        d="m 375.16,302.88 -0.01,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4243"
      />
      <path
        d="m 375.15,302.82 -0.01,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4244"
      />
      <path
        d="m 375.14,302.76 -0.02,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4245"
      />
      <path
        d="m 375.12,302.68 -0.01,-0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4246"
      />
      <path
        d="m 375.11,302.58 v -0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4247"
      />
      <path
        d="m 375.11,302.48 -0.01,-0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4248"
      />
      <path
        d="m 375.1,302.36 -0.01,-0.13 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4249"
      />
      <path
        d="m 375.09,302.23 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4250"
      />
      <path
        d="m 375.09,302.08 -0.01,-0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4251"
      />
      <path
        d="m 375.08,301.92 v -0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4252"
      />
      <path
        d="m 375.08,301.74 -0.01,-0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4253"
      />
      <path
        d="m 375.07,301.56 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4254"
      />
      <path
        d="m 375.07,301.37 v -0.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4255"
      />
      <path
        d="m 375.07,301.17 -0.01,-0.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4256"
      />
      <path
        d="m 375.06,300.97 v -0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4257"
      />
      <path
        d="m 375.06,300.76 v -0.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4258"
      />
      <path
        d="m 375.06,300.56 0.01,-0.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4259"
      />
      <path
        d="m 375.07,300.36 v -0.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4260"
      />
      <path
        d="m 375.07,300.16 v -0.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4261"
      />
      <path
        d="m 375.07,299.96 0.01,-0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4262"
      />
      <path
        d="m 375.08,299.78 v -0.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4263"
      />
      <path
        d="m 375.08,299.61 0.01,-0.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4264"
      />
      <path
        d="m 375.09,299.44 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4265"
      />
      <path
        d="m 375.09,299.29 0.01,-0.13 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4266"
      />
      <path
        d="m 375.1,299.16 0.01,-0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4267"
      />
      <path
        d="m 375.11,299.04 0.01,-0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4268"
      />
      <path
        d="m 375.12,298.94 0.01,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4269"
      />
      <path
        d="m 375.13,298.85 0.01,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4270"
      />
      <path
        d="m 375.14,298.77 0.01,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4271"
      />
      <path
        d="m 375.15,298.7 0.02,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4272"
      />
      <path
        d="m 375.17,298.64 0.02,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4273"
      />
      <path
        d="m 375.19,298.59 0.02,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4274"
      />
      <path
        d="m 375.21,298.55 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4275"
      />
      <path
        d="m 375.23,298.52 0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4276"
      />
      <path
        d="m 375.26,298.5 0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4277"
      />
      <path
        d="m 375.28,298.48 0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4278"
      />
      <path
        d="m 375.31,298.46 0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4279"
      />
      <path
        d="m 375.35,298.45 0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4280"
      />
      <path
        d="m 375.38,298.44 0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4281"
      />
      <path
        d="m 375.42,298.43 h 0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4282"
      />
      <path
        d="m 375.47,298.43 0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4283"
      />
      <path
        d="m 375.51,298.42 h 0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4284"
      />
      <path
        d="m 375.56,298.42 0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4285"
      />
      <path
        d="m 375.62,298.41 h 0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4286"
      />
      <path
        d="m 375.67,298.41 h 0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4287"
      />
      <path
        d="m 375.73,298.41 0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4288"
      />
      <path
        d="m 375.79,298.4 h 0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4289"
      />
      <path
        d="m 375.85,298.4 h 0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4290"
      />
      <path
        d="m 375.92,298.4 h 0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4291"
      />
      <path
        d="m 375.99,298.4 0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4292"
      />
      <path
        d="m 376.06,298.41 h 0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4293"
      />
      <path
        d="m 376.13,298.41 0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4294"
      />
      <path
        d="m 376.2,298.42 0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4295"
      />
      <path
        d="m 376.27,298.44 0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4296"
      />
      <path
        d="m 376.34,298.45 0.06,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4297"
      />
      <path
        d="m 376.4,298.48 0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4298"
      />
      <path
        d="m 376.47,298.51 0.07,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4299"
      />
      <path
        d="m 376.54,298.55 0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4300"
      />
      <path
        d="m 376.6,298.59 0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4301"
      />
      <path
        d="m 376.66,298.64 0.06,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4302"
      />
      <path
        d="m 376.72,298.7 0.05,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4303"
      />
      <path
        d="m 376.77,298.75 0.05,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4304"
      />
      <path
        d="m 376.82,298.81 0.04,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4305"
      />
      <path
        d="m 376.86,298.87 0.04,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4306"
      />
      <path
        d="m 376.9,298.93 0.04,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4307"
      />
      <path
        d="m 376.94,299 0.03,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4308"
      />
      <path
        d="m 376.97,299.07 0.03,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4309"
      />
      <path
        d="m 377,299.13 0.03,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4310"
      />
      <path
        d="m 377.03,299.2 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4311"
      />
      <path
        d="m 377.05,299.27 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4312"
      />
      <path
        d="m 377.07,299.34 0.03,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4313"
      />
      <path
        d="m 377.1,299.41 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4314"
      />
      <path
        d="m 377.12,299.48 0.01,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4315"
      />
      <path
        d="m 377.13,299.55 0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4316"
      />
      <path
        d="m 377.15,299.63 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4317"
      />
      <path
        d="m 377.17,299.7 0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4318"
      />
      <path
        d="m 377.19,299.78 0.01,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4319"
      />
      <path
        d="m 377.2,299.86 0.01,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4320"
      />
      <path
        d="m 377.21,299.94 0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4321"
      />
      <path
        d="m 377.23,300.02 0.01,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4322"
      />
      <path
        d="m 377.24,300.11 0.01,0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4323"
      />
      <path
        d="m 377.25,300.2 0.01,0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4324"
      />
      <path
        d="m 377.26,300.29 v 0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4325"
      />
      <path
        d="m 377.26,300.38 0.01,0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4326"
      />
      <path
        d="m 377.27,300.48 v 0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4327"
      />
      <path
        d="m 377.27,300.57 0.01,0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4328"
      />
      <path
        d="m 377.28,300.67 v 0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4329"
      />
      <path
        d="m 377.28,300.77 v 0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4330"
      />
      <path
        d="m 377.28,300.86 v 0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4331"
      />
      <path
        d="m 377.28,300.96 -0.01,0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4332"
      />
      <path
        d="m 377.27,301.06 v 0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4333"
      />
      <path
        d="m 377.27,301.15 -0.01,0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4334"
      />
      <path
        d="m 377.26,301.24 -0.01,0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4335"
      />
      <path
        d="m 377.25,301.33 v 0.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4336"
      />
      <path
        d="m 377.25,301.41 -0.01,0.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4337"
      />
      <path
        d="m 377.24,301.49 -0.01,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4338"
      />
      <path
        d="m 377.23,301.57 -0.01,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4339"
      />
      <path
        d="m 377.22,301.64 -0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4340"
      />
      <path
        d="m 377.2,301.71 -0.01,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4341"
      />
      <path
        d="m 377.19,301.78 -0.01,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4342"
      />
      <path
        d="m 377.18,301.84 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4343"
      />
      <path
        d="m 377.16,301.9 -0.01,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4344"
      />
      <path
        d="m 377.15,301.96 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4345"
      />
      <path
        d="m 377.13,302.02 -0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4346"
      />
      <path
        d="m 377.11,302.09 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4347"
      />
      <path
        d="m 377.09,302.15 -0.01,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4348"
      />
      <path
        d="m 377.08,302.21 -0.02,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4349"
      />
      <path
        d="m 377.06,302.26 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4350"
      />
      <path
        d="m 377.04,302.32 -0.02,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4351"
      />
      <path
        d="M 377.02,302.37 377,302.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4352"
      />
      <path
        d="m 377,302.42 -0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4353"
      />
      <path
        d="M 374.39,319.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4354"
      />
      <path
        d="m 372.45,318.69 h 0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4355"
      />
      <path
        d="m 372.7,318.69 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4356"
      />
      <path
        d="m 372.7,319.94 h -0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4357"
      />
      <path
        d="m 372.45,319.94 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4358"
      />
      <path
        d="m 374.4,321.31 0.27,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4359"
      />
      <path
        d="m 374.67,321.27 0.26,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4360"
      />
      <path
        d="m 374.93,321.16 0.24,-0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4361"
      />
      <path
        d="m 375.17,320.97 0.2,-0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4362"
      />
      <path
        d="m 375.37,320.72 0.17,-0.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4363"
      />
      <path
        d="m 375.54,320.42 0.13,-0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4364"
      />
      <path
        d="m 375.67,320.08 0.08,-0.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4365"
      />
      <path
        d="m 375.75,319.7 0.03,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4366"
      />
      <path
        d="m 375.78,319.31 -0.03,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4367"
      />
      <path
        d="m 375.75,318.92 -0.08,-0.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4368"
      />
      <path
        d="m 375.67,318.55 -0.13,-0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4369"
      />
      <path
        d="m 375.54,318.21 -0.17,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4370"
      />
      <path
        d="m 375.37,317.9 -0.2,-0.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4371"
      />
      <path
        d="m 375.17,317.66 -0.24,-0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4372"
      />
      <path
        d="m 374.93,317.47 -0.26,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4373"
      />
      <path
        d="m 374.67,317.36 -0.27,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4374"
      />
      <path
        d="m 374.4,317.32 -0.26,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4375"
      />
      <path
        d="m 374.14,317.36 -0.26,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4376"
      />
      <path
        d="m 373.88,317.47 -0.24,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4377"
      />
      <path
        d="m 373.64,317.66 -0.2,0.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4378"
      />
      <path
        d="m 373.44,317.9 -0.18,0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4379"
      />
      <path
        d="m 373.26,318.21 -0.12,0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4380"
      />
      <path
        d="m 373.14,318.55 -0.08,0.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4381"
      />
      <path
        d="m 373.06,318.92 -0.03,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4382"
      />
      <path
        d="m 373.03,319.31 0.03,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4383"
      />
      <path
        d="m 373.06,319.7 0.08,0.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4384"
      />
      <path
        d="m 373.14,320.08 0.12,0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4385"
      />
      <path
        d="m 373.26,320.42 0.18,0.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4386"
      />
      <path
        d="m 373.44,320.72 0.2,0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4387"
      />
      <path
        d="m 373.64,320.97 0.24,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4388"
      />
      <path
        d="m 373.88,321.16 0.26,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4389"
      />
      <path
        d="m 374.14,321.27 0.26,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4390"
      />
      <path
        d="m 374.4,317.07 -0.31,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4391"
      />
      <path
        d="m 374.09,317.11 -0.31,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4392"
      />
      <path
        d="m 373.78,317.24 -0.28,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4393"
      />
      <path
        d="m 373.5,317.45 -0.24,0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4394"
      />
      <path
        d="m 373.26,317.73 -0.2,0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4395"
      />
      <path
        d="m 373.06,318.07 -0.15,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4396"
      />
      <path
        d="m 372.91,318.46 -0.09,0.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4397"
      />
      <path
        d="m 372.82,318.88 -0.04,0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4398"
      />
      <path
        d="m 372.78,319.31 0.04,0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4399"
      />
      <path
        d="m 372.82,319.75 0.09,0.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4400"
      />
      <path
        d="m 372.91,320.17 0.15,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4401"
      />
      <path
        d="m 373.06,320.56 0.2,0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4402"
      />
      <path
        d="m 373.26,320.9 0.24,0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4403"
      />
      <path
        d="m 373.5,321.18 0.28,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4404"
      />
      <path
        d="m 373.78,321.39 0.31,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4405"
      />
      <path
        d="m 374.09,321.51 0.31,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4406"
      />
      <path
        d="m 374.4,321.56 0.32,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4407"
      />
      <path
        d="m 374.72,321.51 0.3,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4408"
      />
      <path
        d="m 375.02,321.39 0.28,-0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4409"
      />
      <path
        d="m 375.3,321.18 0.25,-0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4410"
      />
      <path
        d="m 375.55,320.9 0.2,-0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4411"
      />
      <path
        d="m 375.75,320.56 0.15,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4412"
      />
      <path
        d="m 375.9,320.17 0.09,-0.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4413"
      />
      <path
        d="m 375.99,319.75 0.04,-0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4414"
      />
      <path
        d="m 376.03,319.31 -0.04,-0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4415"
      />
      <path
        d="m 375.99,318.88 -0.09,-0.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4416"
      />
      <path
        d="m 375.9,318.46 -0.15,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4417"
      />
      <path
        d="m 375.75,318.07 -0.2,-0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4418"
      />
      <path
        d="m 375.55,317.73 -0.25,-0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4419"
      />
      <path
        d="m 375.3,317.45 -0.28,-0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4420"
      />
      <path
        d="m 375.02,317.24 -0.3,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4421"
      />
      <path
        d="m 374.72,317.11 -0.32,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4422"
      />
      <path
        d="m 372.4,319.31 h 1.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4423"
      />
      <path
        d="M 374.39,346.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4424"
      />
      <path
        d="m 372.45,345.97 h 0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4425"
      />
      <path
        d="m 372.7,345.97 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4426"
      />
      <path
        d="m 372.7,347.22 h -0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4427"
      />
      <path
        d="m 372.45,347.22 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4428"
      />
      <path
        d="m 374.4,348.59 0.27,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4429"
      />
      <path
        d="m 374.67,348.55 0.26,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4430"
      />
      <path
        d="m 374.93,348.44 0.24,-0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4431"
      />
      <path
        d="m 375.17,348.26 0.2,-0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4432"
      />
      <path
        d="m 375.37,348.01 0.17,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4433"
      />
      <path
        d="m 375.54,347.7 0.13,-0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4434"
      />
      <path
        d="m 375.67,347.36 0.08,-0.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4435"
      />
      <path
        d="m 375.75,346.99 0.03,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4436"
      />
      <path
        d="m 375.78,346.6 -0.03,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4437"
      />
      <path
        d="m 375.75,346.21 -0.08,-0.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4438"
      />
      <path
        d="m 375.67,345.83 -0.13,-0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4439"
      />
      <path
        d="m 375.54,345.49 -0.17,-0.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4440"
      />
      <path
        d="m 375.37,345.19 -0.2,-0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4441"
      />
      <path
        d="m 375.17,344.94 -0.24,-0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4442"
      />
      <path
        d="m 374.93,344.75 -0.26,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4443"
      />
      <path
        d="M 374.67,344.64 374.4,344.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4444"
      />
      <path
        d="m 374.4,344.6 -0.26,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4445"
      />
      <path
        d="m 374.14,344.64 -0.26,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4446"
      />
      <path
        d="m 373.88,344.75 -0.24,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4447"
      />
      <path
        d="m 373.64,344.94 -0.2,0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4448"
      />
      <path
        d="m 373.44,345.19 -0.18,0.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4449"
      />
      <path
        d="m 373.26,345.49 -0.12,0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4450"
      />
      <path
        d="m 373.14,345.83 -0.08,0.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4451"
      />
      <path
        d="m 373.06,346.21 -0.03,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4452"
      />
      <path
        d="m 373.03,346.6 0.03,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4453"
      />
      <path
        d="m 373.06,346.99 0.08,0.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4454"
      />
      <path
        d="m 373.14,347.36 0.12,0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4455"
      />
      <path
        d="m 373.26,347.7 0.18,0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4456"
      />
      <path
        d="m 373.44,348.01 0.2,0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4457"
      />
      <path
        d="m 373.64,348.26 0.24,0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4458"
      />
      <path
        d="m 373.88,348.44 0.26,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4459"
      />
      <path
        d="m 374.14,348.55 0.26,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4460"
      />
      <path
        d="m 374.4,344.35 -0.31,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4461"
      />
      <path
        d="m 374.09,344.4 -0.31,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4462"
      />
      <path
        d="m 373.78,344.52 -0.28,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4463"
      />
      <path
        d="m 373.5,344.73 -0.24,0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4464"
      />
      <path
        d="m 373.26,345.01 -0.2,0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4465"
      />
      <path
        d="m 373.06,345.35 -0.15,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4466"
      />
      <path
        d="m 372.91,345.74 -0.09,0.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4467"
      />
      <path
        d="m 372.82,346.16 -0.04,0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4468"
      />
      <path
        d="m 372.78,346.6 0.04,0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4469"
      />
      <path
        d="m 372.82,347.03 0.09,0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4470"
      />
      <path
        d="m 372.91,347.46 0.15,0.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4471"
      />
      <path
        d="m 373.06,347.84 0.2,0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4472"
      />
      <path
        d="m 373.26,348.18 0.24,0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4473"
      />
      <path
        d="m 373.5,348.46 0.28,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4474"
      />
      <path
        d="m 373.78,348.67 0.31,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4475"
      />
      <path
        d="m 374.09,348.8 0.31,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4476"
      />
      <path
        d="m 374.4,348.84 0.32,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4477"
      />
      <path
        d="m 374.72,348.8 0.3,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4478"
      />
      <path
        d="m 375.02,348.67 0.28,-0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4479"
      />
      <path
        d="m 375.3,348.46 0.25,-0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4480"
      />
      <path
        d="m 375.55,348.18 0.2,-0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4481"
      />
      <path
        d="m 375.75,347.84 0.15,-0.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4482"
      />
      <path
        d="m 375.9,347.46 0.09,-0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4483"
      />
      <path
        d="m 375.99,347.03 0.04,-0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4484"
      />
      <path
        d="m 376.03,346.6 -0.04,-0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4485"
      />
      <path
        d="m 375.99,346.16 -0.09,-0.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4486"
      />
      <path
        d="m 375.9,345.74 -0.15,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4487"
      />
      <path
        d="m 375.75,345.35 -0.2,-0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4488"
      />
      <path
        d="m 375.55,345.01 -0.25,-0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4489"
      />
      <path
        d="m 375.3,344.73 -0.28,-0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4490"
      />
      <path
        d="m 375.02,344.52 -0.3,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4491"
      />
      <path
        d="m 374.72,344.4 -0.32,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4492"
      />
      <path
        d="m 372.4,346.6 h 1.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4493"
      />
      <path
        d="m 377.16,296.27 h 1.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4494"
      />
      <path
        d="m 378.17,296.27 v 0.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4495"
      />
      <path
        d="m 378.17,296.64 h -1.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4496"
      />
      <path
        d="m 377.16,296.64 v -0.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4497"
      />
      <path
        d="m 387.52,296.27 h 1.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4498"
      />
      <path
        d="m 388.54,296.27 v 0.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4499"
      />
      <path
        d="m 388.54,296.64 h -1.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4500"
      />
      <path
        d="m 387.52,296.64 v -0.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4501"
      />
      <path
        d="m 378.4,296.87 h 8.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4502"
      />
      <path
        d="m 378.17,297.18 h 9.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4503"
      />
      <path
        d="m 377.16,296.64 a 1.334,1.334 0 0 0 1.01,0.54"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4504"
      />
      <path
        d="m 387.52,297.18 a 1.334,1.334 0 0 0 1.02,-0.54"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4505"
      />
      <path
        d="m 378.17,296.64 a 1.334,1.334 0 0 0 0.23,0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4506"
      />
      <path
        d="m 387.3,296.87 a 1.334,1.334 0 0 0 0.22,-0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4507"
      />
      <path
        d="m 374.65,307.2 v -1.02 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4508"
      />
      <path
        d="m 374.65,306.18 h 0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4509"
      />
      <path
        d="m 375.01,306.18 v 1.02 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4510"
      />
      <path
        d="m 375.01,307.2 h -0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4511"
      />
      <path
        d="m 374.65,298.39 v -1.02 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4512"
      />
      <path
        d="m 374.65,297.37 h 0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4513"
      />
      <path
        d="m 375.01,297.37 v 1.02 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4514"
      />
      <path
        d="m 375.01,298.39 h -0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4515"
      />
      <path
        d="m 375.25,305.96 v -7.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4516"
      />
      <path
        d="m 375.56,306.18 v -7.79 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4517"
      />
      <path
        d="m 375.01,307.2 a 1.334,1.334 0 0 0 0.55,-1.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4518"
      />
      <path
        d="m 375.56,298.39 a 1.334,1.334 0 0 0 -0.55,-1.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4519"
      />
      <path
        d="m 375.01,306.18 a 1.334,1.334 0 0 0 0.24,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4520"
      />
      <path
        d="m 375.25,298.61 a 1.334,1.334 0 0 0 -0.24,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4521"
      />
      <path
        d="m 376.77,360.94 0.53,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4522"
      />
      <path
        d="m 377.3,360.88 0.39,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4523"
      />
      <path
        d="m 377.69,360.83 0.33,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4524"
      />
      <path
        d="m 378.02,360.77 0.28,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4525"
      />
      <path
        d="m 378.3,360.71 0.23,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4526"
      />
      <path
        d="m 378.53,360.66 0.17,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4527"
      />
      <path
        d="m 378.7,360.62 0.12,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4528"
      />
      <path
        d="m 378.82,360.58 0.1,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4529"
      />
      <path
        d="m 378.92,360.56 0.1,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4530"
      />
      <path
        d="m 379.02,360.55 0.12,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4531"
      />
      <path
        d="m 379.14,360.54 0.19,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4532"
      />
      <path
        d="m 379.33,360.53 h 0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4533"
      />
      <path
        d="m 379.55,360.53 0.26,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4534"
      />
      <path
        d="m 379.81,360.54 0.28,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4535"
      />
      <path
        d="m 380.09,360.58 0.29,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4536"
      />
      <path
        d="m 380.38,360.64 0.28,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4537"
      />
      <path
        d="m 380.66,360.73 0.29,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4538"
      />
      <path
        d="m 380.95,360.85 0.26,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4539"
      />
      <path
        d="m 381.21,361 0.24,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4540"
      />
      <path
        d="m 381.45,361.17 0.2,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4541"
      />
      <path
        d="m 381.65,361.36 0.17,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4542"
      />
      <path
        d="m 381.82,361.57 0.12,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4543"
      />
      <path
        d="m 381.94,361.78 0.07,0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4544"
      />
      <path
        d="m 382.01,361.98 0.03,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4545"
      />
      <path
        d="m 382.04,362.19 -0.03,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4546"
      />
      <path
        d="m 382.01,362.4 -0.07,0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4547"
      />
      <path
        d="m 381.94,362.6 -0.12,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4548"
      />
      <path
        d="m 381.82,362.81 -0.17,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4549"
      />
      <path
        d="m 381.65,363.02 -0.2,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4550"
      />
      <path
        d="m 381.45,363.21 -0.24,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4551"
      />
      <path
        d="m 381.21,363.38 -0.26,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4552"
      />
      <path
        d="m 380.95,363.53 -0.28,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4553"
      />
      <path
        d="m 380.67,363.65 -0.29,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4554"
      />
      <path
        d="m 380.38,363.74 -0.3,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4555"
      />
      <path
        d="m 380.08,363.8 -0.27,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4556"
      />
      <path
        d="m 379.81,363.84 -0.26,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4557"
      />
      <path
        d="m 379.55,363.85 h -0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4558"
      />
      <path
        d="m 379.32,363.85 -0.18,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4559"
      />
      <path
        d="m 379.14,363.84 -0.12,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4560"
      />
      <path
        d="m 379.02,363.83 -0.1,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4561"
      />
      <path
        d="m 378.92,363.82 -0.1,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4562"
      />
      <path
        d="m 378.82,363.8 -0.12,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4563"
      />
      <path
        d="m 378.7,363.76 -0.17,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4564"
      />
      <path
        d="m 378.53,363.72 -0.23,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4565"
      />
      <path
        d="m 378.3,363.67 -0.28,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4566"
      />
      <path
        d="m 378.02,363.61 -0.33,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4567"
      />
      <path
        d="M 377.69,363.55 377.3,363.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4568"
      />
      <path
        d="m 377.3,363.5 -0.41,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4569"
      />
      <path
        d="m 376.89,363.45 h -0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4570"
      />
      <path
        d="m 376.64,363.89 -0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4571"
      />
      <path
        d="m 376.63,363.93 -0.02,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4572"
      />
      <path
        d="m 376.61,363.96 -0.01,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4573"
      />
      <path
        d="m 376.6,363.99 -0.01,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4574"
      />
      <path
        d="m 376.59,364.02 -0.02,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4575"
      />
      <path
        d="m 376.57,364.05 -0.02,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4576"
      />
      <path
        d="m 376.55,364.08 -0.03,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4577"
      />
      <path
        d="m 376.52,364.11 -0.03,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4578"
      />
      <path
        d="m 376.49,364.15 -0.03,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4579"
      />
      <path
        d="m 376.46,364.19 -0.05,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4580"
      />
      <path
        d="m 376.41,364.23 -0.05,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4581"
      />
      <path
        d="m 376.36,364.28 -0.05,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4582"
      />
      <path
        d="m 376.31,364.32 -0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4583"
      />
      <path
        d="m 376.25,364.37 -0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4584"
      />
      <path
        d="m 376.19,364.41 -0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4585"
      />
      <path
        d="m 376.13,364.45 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4586"
      />
      <path
        d="m 376.06,364.48 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4587"
      />
      <path
        d="m 375.99,364.5 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4588"
      />
      <path
        d="m 375.92,364.52 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4589"
      />
      <path
        d="m 375.85,364.54 -0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4590"
      />
      <path
        d="M 375.78,364.55 H 375.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4591"
      />
      <path
        d="m 375.7,364.55 h -0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4592"
      />
      <path
        d="m 375.63,364.55 h -0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4593"
      />
      <path
        d="M 375.56,364.55 H 375.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4594"
      />
      <path
        d="m 375.5,364.55 h -0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4595"
      />
      <path
        d="m 375.43,364.55 h -0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4596"
      />
      <path
        d="m 375.37,364.55 h -0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4597"
      />
      <path
        d="m 375.31,364.55 -0.05,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4598"
      />
      <path
        d="m 375.26,364.54 h -0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4599"
      />
      <path
        d="m 375.21,364.54 h -0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4600"
      />
      <path
        d="m 375.16,364.54 -0.05,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4601"
      />
      <path
        d="m 375.11,364.53 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4602"
      />
      <path
        d="m 375.07,364.52 h -0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4603"
      />
      <path
        d="m 375.03,364.52 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4604"
      />
      <path
        d="m 374.99,364.51 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4605"
      />
      <path
        d="m 374.96,364.5 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4606"
      />
      <path
        d="m 374.93,364.48 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4607"
      />
      <path
        d="m 374.9,364.46 -0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4608"
      />
      <path
        d="m 374.87,364.43 -0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4609"
      />
      <path
        d="m 374.85,364.4 -0.02,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4610"
      />
      <path
        d="m 374.83,364.36 -0.02,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4611"
      />
      <path
        d="m 374.81,364.31 -0.01,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4612"
      />
      <path
        d="m 374.8,364.25 -0.02,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4613"
      />
      <path
        d="m 374.78,364.18 -0.01,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4614"
      />
      <path
        d="m 374.77,364.1 -0.01,-0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4615"
      />
      <path
        d="m 374.76,364.01 -0.01,-0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4616"
      />
      <path
        d="m 374.75,363.91 v -0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4617"
      />
      <path
        d="m 374.75,363.79 -0.01,-0.14 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4618"
      />
      <path
        d="m 374.74,363.65 -0.01,-0.14 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4619"
      />
      <path
        d="m 374.73,363.51 v -0.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4620"
      />
      <path
        d="m 374.73,363.34 -0.01,-0.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4621"
      />
      <path
        d="m 374.72,363.17 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4622"
      />
      <path
        d="m 374.72,362.98 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4623"
      />
      <path
        d="M 374.72,362.79 V 362.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4624"
      />
      <path
        d="m 374.72,362.6 -0.01,-0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4625"
      />
      <path
        d="m 374.71,362.39 v -0.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4626"
      />
      <path
        d="m 374.71,362.19 v -0.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4627"
      />
      <path
        d="m 374.71,361.99 0.01,-0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4628"
      />
      <path
        d="m 374.72,361.78 v -0.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4629"
      />
      <path
        d="m 374.72,361.58 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4630"
      />
      <path
        d="m 374.72,361.39 v -0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4631"
      />
      <path
        d="m 374.72,361.21 0.01,-0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4632"
      />
      <path
        d="m 374.73,361.03 0.01,-0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4633"
      />
      <path
        d="m 374.74,360.87 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4634"
      />
      <path
        d="m 374.74,360.72 0.01,-0.13 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4635"
      />
      <path
        d="m 374.75,360.59 0.01,-0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4636"
      />
      <path
        d="m 374.76,360.47 0.01,-0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4637"
      />
      <path
        d="m 374.77,360.37 0.01,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4638"
      />
      <path
        d="m 374.78,360.27 0.01,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4639"
      />
      <path
        d="m 374.79,360.19 0.01,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4640"
      />
      <path
        d="m 374.8,360.13 0.02,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4641"
      />
      <path
        d="m 374.82,360.07 0.02,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4642"
      />
      <path
        d="m 374.84,360.02 0.02,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4643"
      />
      <path
        d="m 374.86,359.98 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4644"
      />
      <path
        d="m 374.88,359.95 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4645"
      />
      <path
        d="m 374.9,359.92 0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4646"
      />
      <path
        d="m 374.93,359.9 0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4647"
      />
      <path
        d="M 374.96,359.89 375,359.88"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4648"
      />
      <path
        d="m 375,359.88 0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4649"
      />
      <path
        d="m 375.03,359.87 0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4650"
      />
      <path
        d="m 375.07,359.86 0.05,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4651"
      />
      <path
        d="m 375.12,359.85 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4652"
      />
      <path
        d="m 375.16,359.85 0.05,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4653"
      />
      <path
        d="m 375.21,359.84 h 0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4654"
      />
      <path
        d="m 375.26,359.84 h 0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4655"
      />
      <path
        d="m 375.32,359.84 0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4656"
      />
      <path
        d="m 375.38,359.83 h 0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4657"
      />
      <path
        d="m 375.44,359.83 h 0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4658"
      />
      <path
        d="m 375.5,359.83 h 0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4659"
      />
      <path
        d="m 375.57,359.83 h 0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4660"
      />
      <path
        d="m 375.64,359.83 h 0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4661"
      />
      <path
        d="m 375.71,359.83 0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4662"
      />
      <path
        d="m 375.78,359.84 0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4663"
      />
      <path
        d="m 375.85,359.85 0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4664"
      />
      <path
        d="m 375.92,359.86 0.06,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4665"
      />
      <path
        d="m 375.98,359.88 0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4666"
      />
      <path
        d="m 376.05,359.91 0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4667"
      />
      <path
        d="m 376.12,359.94 0.07,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4668"
      />
      <path
        d="m 376.19,359.98 0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4669"
      />
      <path
        d="m 376.25,360.02 0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4670"
      />
      <path
        d="m 376.31,360.07 0.05,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4671"
      />
      <path
        d="m 376.36,360.12 0.06,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4672"
      />
      <path
        d="m 376.42,360.18 0.05,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4673"
      />
      <path
        d="m 376.47,360.24 0.04,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4674"
      />
      <path
        d="m 376.51,360.3 0.04,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4675"
      />
      <path
        d="m 376.55,360.36 0.04,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4676"
      />
      <path
        d="m 376.59,360.43 0.03,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4677"
      />
      <path
        d="m 376.62,360.49 0.03,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4678"
      />
      <path
        d="m 376.65,360.56 0.03,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4679"
      />
      <path
        d="m 376.68,360.63 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4680"
      />
      <path
        d="m 376.7,360.7 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4681"
      />
      <path
        d="m 376.72,360.77 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4682"
      />
      <path
        d="m 376.74,360.84 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4683"
      />
      <path
        d="m 376.76,360.91 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4684"
      />
      <path
        d="m 376.78,360.98 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4685"
      />
      <path
        d="m 376.8,361.05 0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4686"
      />
      <path
        d="m 376.82,361.13 0.01,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4687"
      />
      <path
        d="m 376.83,361.21 0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4688"
      />
      <path
        d="m 376.85,361.28 0.01,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4689"
      />
      <path
        d="m 376.86,361.36 0.02,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4690"
      />
      <path
        d="m 376.88,361.45 0.01,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4691"
      />
      <path
        d="m 376.89,361.54 0.01,0.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4692"
      />
      <path
        d="m 376.9,361.62 v 0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4693"
      />
      <path
        d="m 376.9,361.72 0.01,0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4694"
      />
      <path
        d="m 376.91,361.81 0.01,0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4695"
      />
      <path
        d="M 376.92,361.9 V 362 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4696"
      />
      <path
        d="m 376.92,362 v 0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4697"
      />
      <path
        d="m 376.92,362.1 0.01,0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4698"
      />
      <path
        d="m 376.93,362.19 v 0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4699"
      />
      <path
        d="m 376.93,362.29 -0.01,0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4700"
      />
      <path
        d="m 376.92,362.39 v 0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4701"
      />
      <path
        d="m 376.92,362.48 v 0.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4702"
      />
      <path
        d="m 376.92,362.58 -0.01,0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4703"
      />
      <path
        d="m 376.91,362.67 -0.01,0.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4704"
      />
      <path
        d="m 376.9,362.76 v 0.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4705"
      />
      <path
        d="m 376.9,362.84 -0.01,0.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4706"
      />
      <path
        d="M 376.89,362.92 376.88,363"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4707"
      />
      <path
        d="m 376.88,363 -0.01,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4708"
      />
      <path
        d="m 376.87,363.07 -0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4709"
      />
      <path
        d="m 376.85,363.14 -0.01,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4710"
      />
      <path
        d="m 376.84,363.2 -0.01,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4711"
      />
      <path
        d="m 376.83,363.27 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4712"
      />
      <path
        d="m 376.81,363.33 -0.01,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4713"
      />
      <path
        d="m 376.8,363.39 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4714"
      />
      <path
        d="m 376.78,363.45 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4715"
      />
      <path
        d="m 376.76,363.51 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4716"
      />
      <path
        d="m 376.74,363.57 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4717"
      />
      <path
        d="m 376.72,363.63 -0.01,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4718"
      />
      <path
        d="m 376.71,363.69 -0.02,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4719"
      />
      <path
        d="m 376.69,363.75 -0.02,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4720"
      />
      <path
        d="m 376.67,363.8 -0.02,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4721"
      />
      <path
        d="m 376.65,363.85 -0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4722"
      />
      <path
        d="m 377.16,366.81 h 1.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4723"
      />
      <path
        d="m 378.17,366.81 v -0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4724"
      />
      <path
        d="m 378.17,366.45 h -1.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4725"
      />
      <path
        d="m 377.16,366.45 v 0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4726"
      />
      <path
        d="m 387.52,366.81 h 1.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4727"
      />
      <path
        d="m 388.54,366.81 v -0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4728"
      />
      <path
        d="m 388.54,366.45 h -1.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4729"
      />
      <path
        d="m 387.52,366.45 v 0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4730"
      />
      <path
        d="m 378.4,366.21 h 8.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4731"
      />
      <path
        d="m 378.17,365.91 h 9.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4732"
      />
      <path
        d="m 377.16,366.45 0.44,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4733"
      />
      <path
        d="m 377.6,366.06 0.57,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4734"
      />
      <path
        d="m 387.52,365.91 0.57,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4735"
      />
      <path
        d="m 388.09,366.06 0.45,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4736"
      />
      <path
        d="m 378.17,366.45 0.23,-0.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4737"
      />
      <path
        d="m 387.3,366.21 0.22,0.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4738"
      />
      <path
        d="m 374.5,366.23 v -1.02 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4739"
      />
      <path
        d="m 374.5,365.21 h 0.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4740"
      />
      <path
        d="m 374.87,365.21 v 1.02 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4741"
      />
      <path
        d="M 374.87,366.23 H 374.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4742"
      />
      <path
        d="M 374.5,357.42 V 356.4 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4743"
      />
      <path
        d="m 374.5,356.4 h 0.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4744"
      />
      <path
        d="m 374.87,356.4 v 1.02 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4745"
      />
      <path
        d="M 374.87,357.42 H 374.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4746"
      />
      <path
        d="m 375.1,364.99 v -7.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4747"
      />
      <path
        d="m 375.41,365.21 v -7.79 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4748"
      />
      <path
        d="m 374.87,366.23 a 1.334,1.334 0 0 0 0.54,-1.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4749"
      />
      <path
        d="m 375.41,357.42 a 1.334,1.334 0 0 0 -0.54,-1.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4750"
      />
      <path
        d="m 374.87,365.21 a 1.334,1.334 0 0 0 0.23,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4751"
      />
      <path
        d="m 375.1,357.64 a 1.334,1.334 0 0 0 -0.23,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4752"
      />
      <path
        d="m 407.07,428.95 h -8.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4852"
      />
      <path
        d="m 407.07,428.2 h -8.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path4854"
      />
      <path
        d="m 70.28,342.75 h 4.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6634"
      />
      <path
        d="m 72.77,383.43 0.07,-35.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6635"
      />
      <path
        d="M 112.76,436.59 H 85.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6636"
      />
      <path
        d="M 112.76,437.84 H 85.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6637"
      />
      <path
        d="M 112.76,434.59 H 85.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6638"
      />
      <path
        d="m 85.83,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6639"
      />
      <path
        d="m 89.19,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6640"
      />
      <path
        d="m 89.69,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6641"
      />
      <path
        d="m 108.15,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6642"
      />
      <path
        d="m 108.65,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6643"
      />
      <path
        d="m 112.26,436.59 v 1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6644"
      />
      <path
        d="M 112.76,439.08 H 85.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6645"
      />
      <path
        d="m 112.76,439.08 v -4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6646"
      />
      <path
        d="m 85.34,439.08 v -4.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6647"
      />
      <path
        d="m 256.37,436.59 h 3.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6648"
      />
      <path
        d="m 227.58,196.95 h -0.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6839"
      />
      <path
        d="M 226.63,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6840"
      />
      <path
        d="M 226.63,196.8 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6841"
      />
      <path
        d="m 226.63,161.27 h -0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6842"
      />
      <path
        d="M 225.73,161.27 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6843"
      />
      <path
        d="m 225.73,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6844"
      />
      <path
        d="m 225.73,196.95 h -0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6845"
      />
      <path
        d="m 225.73,161.27 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6846"
      />
      <path
        d="M 225.57,161.27 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6847"
      />
      <path
        d="m 225.57,196.8 h -0.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6848"
      />
      <path
        d="m 227.58,196.8 h -0.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6849"
      />
      <path
        d="M 226.78,196.8 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6850"
      />
      <path
        d="m 226.78,161.27 h -0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6851"
      />
      <path
        d="M 215.3,196.95 H 198.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6852"
      />
      <path
        d="M 198.53,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6853"
      />
      <path
        d="M 198.53,196.8 V 162.32 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6854"
      />
      <path
        d="m 198.53,162.32 h 4.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6855"
      />
      <path
        d="m 203.28,162.32 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6856"
      />
      <path
        d="m 204.18,162.32 v -0.9 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6857"
      />
      <path
        d="m 204.18,161.42 v -1.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6858"
      />
      <path
        d="m 204.18,159.62 h -0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6859"
      />
      <path
        d="m 203.28,159.62 v 1.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6860"
      />
      <path
        d="m 203.28,161.42 h -4.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6861"
      />
      <path
        d="m 198.53,161.42 h -0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6862"
      />
      <path
        d="m 197.63,161.42 v 0.9 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6863"
      />
      <path
        d="M 197.63,162.32 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6864"
      />
      <path
        d="m 197.63,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6865"
      />
      <path
        d="m 197.63,196.95 h -0.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6866"
      />
      <path
        d="m 203.28,159.62 h -0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6867"
      />
      <path
        d="m 203.13,159.62 v 1.65 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6868"
      />
      <path
        d="m 203.13,161.27 h -5.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6869"
      />
      <path
        d="m 197.63,161.27 h -0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6870"
      />
      <path
        d="M 197.48,161.27 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6871"
      />
      <path
        d="m 197.48,196.8 h -0.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6872"
      />
      <path
        d="M 215.3,196.8 H 198.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6873"
      />
      <path
        d="M 198.69,196.8 V 162.48 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6874"
      />
      <path
        d="m 198.69,162.48 h 5.65"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6875"
      />
      <path
        d="m 204.34,162.48 v -0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6876"
      />
      <path
        d="m 204.34,162.32 v -2.7 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6877"
      />
      <path
        d="m 204.34,159.62 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6878"
      />
      <path
        d="M 187.15,196.95 H 170.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6879"
      />
      <path
        d="M 170.42,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6880"
      />
      <path
        d="M 170.42,196.8 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6881"
      />
      <path
        d="m 170.42,161.27 h -0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6882"
      />
      <path
        d="M 169.52,161.27 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6883"
      />
      <path
        d="m 169.52,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6884"
      />
      <path
        d="m 169.52,196.95 h -0.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6885"
      />
      <path
        d="M 158.83,196.95 H 142.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6886"
      />
      <path
        d="M 142.21,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6887"
      />
      <path
        d="M 142.21,196.8 V 162.32 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6888"
      />
      <path
        d="m 142.21,162.32 v -0.9 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6889"
      />
      <path
        d="m 142.21,161.42 h -0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6890"
      />
      <path
        d="m 141.31,161.42 h -3.92"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6891"
      />
      <path
        d="m 137.39,161.42 v -1.81 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6892"
      />
      <path
        d="m 137.39,159.61 h -0.91"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6893"
      />
      <path
        d="m 136.48,159.61 v 1.81 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6894"
      />
      <path
        d="m 136.48,161.42 v 0.9 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6895"
      />
      <path
        d="m 136.48,162.32 h 0.91"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6896"
      />
      <path
        d="m 137.39,162.32 h 3.92"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6897"
      />
      <path
        d="M 141.31,162.32 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6898"
      />
      <path
        d="m 141.31,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6899"
      />
      <path
        d="M 141.31,196.95 H 140.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6900"
      />
      <path
        d="m 130.54,196.95 h -16.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6901"
      />
      <path
        d="M 113.94,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6902"
      />
      <path
        d="M 113.94,196.8 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6903"
      />
      <path
        d="m 113.94,161.27 h -0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6904"
      />
      <path
        d="M 113.04,161.27 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6905"
      />
      <path
        d="m 113.04,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6906"
      />
      <path
        d="m 113.04,196.95 h -0.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6907"
      />
      <path
        d="m 225.25,197.86 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6908"
      />
      <path
        d="m 225.73,197.86 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6909"
      />
      <path
        d="m 226.63,197.86 h 0.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6910"
      />
      <path
        d="m 197.11,197.86 h 0.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6911"
      />
      <path
        d="m 197.63,197.86 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6912"
      />
      <path
        d="M 198.53,197.86 H 215.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6913"
      />
      <path
        d="m 168.78,197.86 h 0.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6914"
      />
      <path
        d="m 169.52,197.86 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6915"
      />
      <path
        d="m 170.42,197.86 h 16.73"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6916"
      />
      <path
        d="m 140.5,197.86 h 0.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6917"
      />
      <path
        d="m 141.31,197.86 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6918"
      />
      <path
        d="m 142.21,197.86 h 16.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6919"
      />
      <path
        d="m 112.09,197.86 h 0.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6920"
      />
      <path
        d="m 113.04,197.86 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6921"
      />
      <path
        d="m 113.94,197.86 h 16.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6922"
      />
      <path
        d="M 187.15,196.8 H 170.58"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6923"
      />
      <path
        d="M 170.58,196.8 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6924"
      />
      <path
        d="m 170.58,161.27 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6925"
      />
      <path
        d="m 169.52,161.27 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6926"
      />
      <path
        d="M 169.36,161.27 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6927"
      />
      <path
        d="m 169.36,196.8 h -0.58"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6928"
      />
      <path
        d="M 158.83,196.8 H 142.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6929"
      />
      <path
        d="M 142.37,196.8 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6930"
      />
      <path
        d="m 142.37,161.27 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6931"
      />
      <path
        d="m 142.21,161.27 h -4.67"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6932"
      />
      <path
        d="m 137.54,161.27 v -1.66 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6933"
      />
      <path
        d="m 137.54,159.61 h -0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6934"
      />
      <path
        d="m 136.48,159.61 h -0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6935"
      />
      <path
        d="m 136.33,159.61 v 2.71 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6936"
      />
      <path
        d="m 136.33,162.32 v 0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6937"
      />
      <path
        d="m 136.33,162.48 h 4.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6938"
      />
      <path
        d="M 141.15,162.48 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6939"
      />
      <path
        d="M 141.15,196.8 H 140.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6940"
      />
      <path
        d="M 130.54,196.8 H 114.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6941"
      />
      <path
        d="M 114.1,196.8 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6942"
      />
      <path
        d="m 114.1,161.27 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6943"
      />
      <path
        d="m 113.04,161.27 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6944"
      />
      <path
        d="M 112.88,161.27 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6945"
      />
      <path
        d="m 112.88,196.8 h -0.79"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6946"
      />
      <path
        d="M 102.13,196.8 H 75.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6947"
      />
      <path
        d="m 75.19,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6948"
      />
      <path
        d="m 75.19,196.95 v 1.26 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6949"
      />
      <path
        d="M 75.19,198.21 H 74.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6950"
      />
      <path
        d="m 112.09,198.01 h 18.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6951"
      />
      <path
        d="m 140.5,198.01 h 18.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6952"
      />
      <path
        d="m 168.78,198.01 h 18.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6953"
      />
      <path
        d="M 197.11,198.01 H 215.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6954"
      />
      <path
        d="m 225.25,198.01 h 2.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6955"
      />
      <path
        d="m 227.58,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6956"
      />
      <path
        d="M 237.54,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6957"
      />
      <path
        d="m 215.3,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6958"
      />
      <path
        d="M 225.25,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6959"
      />
      <path
        d="m 187.15,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6960"
      />
      <path
        d="M 197.11,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6961"
      />
      <path
        d="m 158.83,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6962"
      />
      <path
        d="M 168.78,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6963"
      />
      <path
        d="m 130.54,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6964"
      />
      <path
        d="M 140.5,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6965"
      />
      <path
        d="m 102.13,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6966"
      />
      <path
        d="M 112.09,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6967"
      />
      <path
        d="M 102.63,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6968"
      />
      <path
        d="m 102.63,196.8 h -0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6969"
      />
      <path
        d="m 102.13,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6970"
      />
      <path
        d="m 102.13,198.01 h 0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6971"
      />
      <path
        d="M 112.09,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6972"
      />
      <path
        d="m 112.09,196.8 h -0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6973"
      />
      <path
        d="m 111.59,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6974"
      />
      <path
        d="m 111.59,198.01 h 0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6975"
      />
      <path
        d="m 111.59,187.83 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6976"
      />
      <path
        d="m 111.15,187.83 v 1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6977"
      />
      <path
        d="m 111.15,188.86 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6978"
      />
      <path
        d="m 111.59,188.86 v -1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6979"
      />
      <path
        d="m 111.59,190.63 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6980"
      />
      <path
        d="m 111.15,190.63 v 6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6981"
      />
      <path
        d="m 111.15,196.8 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6982"
      />
      <path
        d="m 111.59,196.8 v -6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6983"
      />
      <path
        d="m 111.37,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6984"
      />
      <path
        d="m 111.59,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6985"
      />
      <path
        d="m 111.15,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6986"
      />
      <path
        d="m 111.15,187.84 a 8.962,8.962 0 0 0 -8.52,8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6987"
      />
      <path
        d="M 102.13,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6988"
      />
      <path
        d="m 102.13,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6989"
      />
      <path
        d="m 102.13,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6990"
      />
      <path
        d="m 102.13,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6991"
      />
      <path
        d="m 102.13,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6992"
      />
      <path
        d="m 102.13,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6993"
      />
      <path
        d="M 112.09,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6994"
      />
      <path
        d="m 112.09,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6995"
      />
      <path
        d="m 112.09,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6996"
      />
      <path
        d="m 112.09,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6997"
      />
      <path
        d="m 112.09,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6998"
      />
      <path
        d="m 112.09,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path6999"
      />
      <path
        d="M 131.04,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7000"
      />
      <path
        d="m 131.04,196.8 h -0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7001"
      />
      <path
        d="m 130.54,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7002"
      />
      <path
        d="m 130.54,198.01 h 0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7003"
      />
      <path
        d="M 140.5,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7004"
      />
      <path
        d="M 140.5,196.8 H 140"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7005"
      />
      <path
        d="m 140,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7006"
      />
      <path
        d="m 140,198.01 h 0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7007"
      />
      <path
        d="m 140,187.83 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7008"
      />
      <path
        d="m 139.56,187.83 v 1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7009"
      />
      <path
        d="M 139.56,188.86 H 140"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7010"
      />
      <path
        d="m 140,188.86 v -1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7011"
      />
      <path
        d="m 140,190.63 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7012"
      />
      <path
        d="m 139.56,190.63 v 6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7013"
      />
      <path
        d="M 139.56,196.8 H 140"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7014"
      />
      <path
        d="m 140,196.8 v -6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7015"
      />
      <path
        d="m 139.78,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7016"
      />
      <path
        d="m 140,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7017"
      />
      <path
        d="m 139.56,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7018"
      />
      <path
        d="m 139.56,187.84 a 8.963,8.963 0 0 0 -8.52,8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7019"
      />
      <path
        d="M 130.54,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7020"
      />
      <path
        d="m 130.54,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7021"
      />
      <path
        d="m 130.54,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7022"
      />
      <path
        d="m 130.54,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7023"
      />
      <path
        d="m 130.54,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7024"
      />
      <path
        d="m 130.54,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7025"
      />
      <path
        d="M 140.5,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7026"
      />
      <path
        d="m 140.5,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7027"
      />
      <path
        d="m 140.5,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7028"
      />
      <path
        d="m 140.5,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7029"
      />
      <path
        d="m 140.5,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7030"
      />
      <path
        d="m 140.5,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7031"
      />
      <path
        d="M 159.32,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7032"
      />
      <path
        d="m 159.32,196.8 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7033"
      />
      <path
        d="m 158.83,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7034"
      />
      <path
        d="m 158.83,198.01 h 0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7035"
      />
      <path
        d="M 168.78,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7036"
      />
      <path
        d="m 168.78,196.8 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7037"
      />
      <path
        d="m 168.29,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7038"
      />
      <path
        d="m 168.29,198.01 h 0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7039"
      />
      <path
        d="m 168.29,187.83 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7040"
      />
      <path
        d="m 167.85,187.83 v 1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7041"
      />
      <path
        d="m 167.85,188.86 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7042"
      />
      <path
        d="m 168.29,188.86 v -1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7043"
      />
      <path
        d="m 168.29,190.63 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7044"
      />
      <path
        d="m 167.85,190.63 v 6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7045"
      />
      <path
        d="m 167.85,196.8 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7046"
      />
      <path
        d="m 168.29,196.8 v -6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7047"
      />
      <path
        d="m 168.07,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7048"
      />
      <path
        d="m 168.29,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7049"
      />
      <path
        d="m 167.85,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7050"
      />
      <path
        d="m 167.85,187.84 a 8.963,8.963 0 0 0 -8.53,8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7051"
      />
      <path
        d="M 158.83,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7052"
      />
      <path
        d="m 158.83,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7053"
      />
      <path
        d="m 158.83,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7054"
      />
      <path
        d="m 158.83,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7055"
      />
      <path
        d="m 158.83,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7056"
      />
      <path
        d="m 158.83,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7057"
      />
      <path
        d="M 168.78,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7058"
      />
      <path
        d="m 168.78,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7059"
      />
      <path
        d="m 168.78,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7060"
      />
      <path
        d="m 168.78,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7061"
      />
      <path
        d="m 168.78,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7062"
      />
      <path
        d="m 168.78,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7063"
      />
      <path
        d="M 187.65,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7064"
      />
      <path
        d="m 187.65,196.8 h -0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7065"
      />
      <path
        d="m 187.15,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7066"
      />
      <path
        d="m 187.15,198.01 h 0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7067"
      />
      <path
        d="M 197.11,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7068"
      />
      <path
        d="m 197.11,196.8 h -0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7069"
      />
      <path
        d="m 196.61,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7070"
      />
      <path
        d="m 196.61,198.01 h 0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7071"
      />
      <path
        d="m 196.61,187.83 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7072"
      />
      <path
        d="m 196.17,187.83 v 1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7073"
      />
      <path
        d="m 196.17,188.86 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7074"
      />
      <path
        d="m 196.61,188.86 v -1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7075"
      />
      <path
        d="m 196.61,190.63 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7076"
      />
      <path
        d="m 196.17,190.63 v 6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7077"
      />
      <path
        d="m 196.17,196.8 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7078"
      />
      <path
        d="m 196.61,196.8 v -6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7079"
      />
      <path
        d="m 196.39,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7080"
      />
      <path
        d="m 196.61,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7081"
      />
      <path
        d="m 196.17,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7082"
      />
      <path
        d="m 196.17,187.84 a 8.962,8.962 0 0 0 -8.52,8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7083"
      />
      <path
        d="M 187.15,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7084"
      />
      <path
        d="m 187.15,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7085"
      />
      <path
        d="m 187.15,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7086"
      />
      <path
        d="m 187.15,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7087"
      />
      <path
        d="m 187.15,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7088"
      />
      <path
        d="m 187.15,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7089"
      />
      <path
        d="M 197.11,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7090"
      />
      <path
        d="m 197.11,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7091"
      />
      <path
        d="m 197.11,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7092"
      />
      <path
        d="m 197.11,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7093"
      />
      <path
        d="m 197.11,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7094"
      />
      <path
        d="m 197.11,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7095"
      />
      <path
        d="M 215.79,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7096"
      />
      <path
        d="M 215.79,196.8 H 215.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7097"
      />
      <path
        d="m 215.3,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7098"
      />
      <path
        d="m 215.3,198.01 h 0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7099"
      />
      <path
        d="M 225.25,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7100"
      />
      <path
        d="m 225.25,196.8 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7101"
      />
      <path
        d="m 224.76,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7102"
      />
      <path
        d="m 224.76,198.01 h 0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7103"
      />
      <path
        d="m 224.76,187.83 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7104"
      />
      <path
        d="m 224.32,187.83 v 1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7105"
      />
      <path
        d="m 224.32,188.86 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7106"
      />
      <path
        d="m 224.76,188.86 v -1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7107"
      />
      <path
        d="m 224.76,190.63 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7108"
      />
      <path
        d="m 224.32,190.63 v 6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7109"
      />
      <path
        d="m 224.32,196.8 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7110"
      />
      <path
        d="m 224.76,196.8 v -6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7111"
      />
      <path
        d="m 224.54,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7112"
      />
      <path
        d="m 224.76,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7113"
      />
      <path
        d="m 224.32,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7114"
      />
      <path
        d="m 224.32,187.84 a 8.962,8.962 0 0 0 -8.53,8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7115"
      />
      <path
        d="M 215.3,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7116"
      />
      <path
        d="m 215.3,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7117"
      />
      <path
        d="m 215.3,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7118"
      />
      <path
        d="m 215.3,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7119"
      />
      <path
        d="m 215.3,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7120"
      />
      <path
        d="m 215.3,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7121"
      />
      <path
        d="M 225.25,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7122"
      />
      <path
        d="m 225.25,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7123"
      />
      <path
        d="m 225.25,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7124"
      />
      <path
        d="m 225.25,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7125"
      />
      <path
        d="m 225.25,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7126"
      />
      <path
        d="m 225.25,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7127"
      />
      <path
        d="M 237.05,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7128"
      />
      <path
        d="m 237.05,196.8 h 0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7129"
      />
      <path
        d="m 237.54,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7130"
      />
      <path
        d="m 237.54,198.01 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7131"
      />
      <path
        d="M 227.58,198.01 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7132"
      />
      <path
        d="m 227.58,196.8 h 0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7133"
      />
      <path
        d="m 228.08,196.8 v 1.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7134"
      />
      <path
        d="m 228.08,198.01 h -0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7135"
      />
      <path
        d="m 228.08,187.83 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7136"
      />
      <path
        d="m 228.52,187.83 v 1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7137"
      />
      <path
        d="m 228.52,188.86 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7138"
      />
      <path
        d="m 228.08,188.86 v -1.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7139"
      />
      <path
        d="m 228.08,190.63 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7140"
      />
      <path
        d="m 228.52,190.63 v 6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7141"
      />
      <path
        d="m 228.52,196.8 h -0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7142"
      />
      <path
        d="m 228.08,196.8 v -6.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7143"
      />
      <path
        d="m 228.3,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7144"
      />
      <path
        d="m 228.08,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7145"
      />
      <path
        d="m 228.52,188.86 v 1.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7146"
      />
      <path
        d="m 237.05,196.8 a 8.963,8.963 0 0 0 -8.53,-8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7147"
      />
      <path
        d="M 237.54,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7148"
      />
      <path
        d="m 237.54,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7149"
      />
      <path
        d="m 237.54,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7150"
      />
      <path
        d="m 237.54,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7151"
      />
      <path
        d="m 237.54,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7152"
      />
      <path
        d="m 237.54,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7153"
      />
      <path
        d="M 227.58,196.95 V 196.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7154"
      />
      <path
        d="m 227.58,196.8 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7155"
      />
      <path
        d="m 227.58,197.86 v -0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7156"
      />
      <path
        d="m 227.58,196.95 v 0.91 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7157"
      />
      <path
        d="m 227.58,198.01 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7158"
      />
      <path
        d="m 227.58,197.86 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7159"
      />
      <path
        d="m 143.66,227.89 v -8.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7160"
      />
      <path
        d="m 143.66,219.85 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7161"
      />
      <path
        d="M 143.82,219.85 H 161.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7162"
      />
      <path
        d="M 161.4,219.85 V 231.4 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7163"
      />
      <path
        d="m 161.4,231.4 v 0.9 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7164"
      />
      <path
        d="m 161.4,232.3 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7165"
      />
      <path
        d="m 162.3,232.3 h 5.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7166"
      />
      <path
        d="m 168.13,232.3 v 6.88 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7167"
      />
      <path
        d="m 168.13,239.18 h -0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7168"
      />
      <path
        d="M 167.98,239.18 H 143.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7169"
      />
      <path
        d="m 143.82,239.18 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7170"
      />
      <path
        d="m 143.66,239.18 v -1.34 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7171"
      />
      <path
        d="m 142.57,227.89 v -3.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7172"
      />
      <path
        d="m 142.57,223.56 v -4.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7173"
      />
      <path
        d="m 142.57,218.76 h 8.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7174"
      />
      <path
        d="m 152.64,218.76 h 9.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7175"
      />
      <path
        d="m 162.49,219.85 v 11.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7176"
      />
      <path
        d="m 162.49,231.21 h 5.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7177"
      />
      <path
        d="m 169.22,231.21 v 9.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7178"
      />
      <path
        d="M 168.22,240.27 H 142.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7179"
      />
      <path
        d="m 142.57,239.03 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7180"
      />
      <path
        d="M 143.82,227.89 H 142.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7181"
      />
      <path
        d="m 142.6,237.84 h 1.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7182"
      />
      <path
        d="m 142.6,228.38 h 1.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7183"
      />
      <path
        d="m 143.82,228.38 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7184"
      />
      <path
        d="M 143.82,227.89 H 142.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7185"
      />
      <path
        d="m 142.6,227.89 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7186"
      />
      <path
        d="m 142.6,237.84 h 1.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7187"
      />
      <path
        d="m 143.82,237.84 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7188"
      />
      <path
        d="M 143.82,237.35 H 142.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7189"
      />
      <path
        d="m 142.6,237.35 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7190"
      />
      <path
        d="m 152.78,237.35 v -0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7191"
      />
      <path
        d="m 152.78,236.91 h -8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7192"
      />
      <path
        d="m 143.82,236.91 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7193"
      />
      <path
        d="m 143.82,237.35 h 8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7194"
      />
      <path
        d="m 152.77,236.91 a 8.962,8.962 0 0 0 -8.95,-8.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7195"
      />
      <path
        d="m 143.66,227.89 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7196"
      />
      <path
        d="m 143.82,227.89 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7197"
      />
      <path
        d="m 142.76,227.89 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7198"
      />
      <path
        d="m 143.66,227.89 h -0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7199"
      />
      <path
        d="m 142.6,227.89 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7200"
      />
      <path
        d="M 142.76,227.89 H 142.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7201"
      />
      <path
        d="m 143.66,237.84 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7202"
      />
      <path
        d="m 143.82,237.84 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7203"
      />
      <path
        d="m 142.76,237.84 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7204"
      />
      <path
        d="m 143.66,237.84 h -0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7205"
      />
      <path
        d="m 142.6,237.84 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7206"
      />
      <path
        d="M 142.76,237.84 H 142.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7207"
      />
      <path
        d="m 85.01,262.72 v 0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7208"
      />
      <path
        d="m 85.01,262.88 v 0.29 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7209"
      />
      <path
        d="m 85.01,263.17 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7210"
      />
      <path
        d="m 85.91,263.17 h -0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7211"
      />
      <path
        d="m 85.01,263.17 h 0.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7212"
      />
      <path
        d="m 72.77,239.29 v 0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7213"
      />
      <path
        d="m 72.77,239.44 h 7.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7214"
      />
      <path
        d="m 80.06,239.44 h 0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7215"
      />
      <path
        d="m 80.21,239.44 -0.06,-1.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7216"
      />
      <path
        d="m 87.99,238.03 v 23.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7217"
      />
      <path
        d="m 89.24,238.03 v 23.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7218"
      />
      <path
        d="m 74.27,261.66 v 0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7219"
      />
      <path
        d="m 74.27,262.72 v 0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7220"
      />
      <path
        d="m 74.27,262.88 h 10.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7221"
      />
      <path
        d="m 168.17,348.02 h 4.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7222"
      />
      <path
        d="m 172.44,348.02 v -4.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7223"
      />
      <path
        d="m 172.44,343.79 h -4.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7224"
      />
      <path
        d="m 168.17,343.79 v 4.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7225"
      />
      <path
        d="m 168.8,344.41 h 3.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7226"
      />
      <path
        d="m 171.81,344.41 v 2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7227"
      />
      <path
        d="M 171.81,347.4 H 168.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7228"
      />
      <path
        d="m 168.8,347.4 v -2.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7229"
      />
      <path
        d="m 168.02,348.18 h 4.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7230"
      />
      <path
        d="m 172.59,348.18 v -4.55 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7231"
      />
      <path
        d="m 172.59,343.63 h -4.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7232"
      />
      <path
        d="m 168.02,343.63 v 4.55 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7233"
      />
      <path
        d="m 85.91,263.17 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7234"
      />
      <path
        d="M 86.07,263.17 H 85.91"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7235"
      />
      <path
        d="m 85.91,263.17 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7236"
      />
      <path
        d="m 86.07,263.17 v -0.29 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7237"
      />
      <path
        d="m 84.85,262.88 v 0.29 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7238"
      />
      <path
        d="m 84.85,263.17 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7239"
      />
      <path
        d="M 85.01,263.17 H 84.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7240"
      />
      <path
        d="m 84.85,263.17 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7241"
      />
      <path
        d="M 86.07,272.63 H 84.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7242"
      />
      <path
        d="m 84.85,272.63 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7243"
      />
      <path
        d="m 84.85,273.12 h 1.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7244"
      />
      <path
        d="m 86.07,273.12 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7245"
      />
      <path
        d="M 86.07,263.17 H 84.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7246"
      />
      <path
        d="m 84.85,263.17 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7247"
      />
      <path
        d="m 84.85,263.66 h 1.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7248"
      />
      <path
        d="m 86.07,263.66 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7249"
      />
      <path
        d="m 75.89,263.66 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7250"
      />
      <path
        d="m 75.89,264.1 h 8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7251"
      />
      <path
        d="m 84.85,264.1 v -0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7252"
      />
      <path
        d="M 84.85,263.66 H 75.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7253"
      />
      <path
        d="m 75.9,264.1 a 8.963,8.963 0 0 0 8.95,8.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7254"
      />
      <path
        d="M 228.21,165.99 V 166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7255"
      />
      <path
        d="m 228.21,166 v 0.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7256"
      />
      <path
        d="m 228.21,166.96 h -0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7257"
      />
      <path
        d="M 228.16,166.96 V 166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7258"
      />
      <path
        d="M 228.16,166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7259"
      />
      <path
        d="m 228.16,166 -0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7260"
      />
      <path
        d="m 228.13,165.97 -0.01,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7261"
      />
      <path
        d="M 228.12,165.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7262"
      />
      <path
        d="m 228.12,165.93 0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7263"
      />
      <path
        d="m 228.13,165.9 0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7264"
      />
      <path
        d="m 228.15,165.88 0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7265"
      />
      <path
        d="M 228.18,165.87 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7266"
      />
      <path
        d="m 228.18,165.87 0.05,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7267"
      />
      <path
        d="m 228.23,165.89 0.02,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7268"
      />
      <path
        d="M 228.25,165.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7269"
      />
      <path
        d="m 228.25,165.93 -0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7270"
      />
      <path
        d="m 228.24,165.97 -0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7271"
      />
      <path
        d="m 228.12,164.07 0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7272"
      />
      <path
        d="m 228.13,164.1 0.02,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7273"
      />
      <path
        d="m 228.15,164.12 0.03,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7274"
      />
      <path
        d="M 228.18,164.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7275"
      />
      <path
        d="m 228.18,164.13 0.05,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7276"
      />
      <path
        d="m 228.23,164.11 0.02,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7277"
      />
      <path
        d="M 228.25,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7278"
      />
      <path
        d="m 228.25,164.07 -0.01,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7279"
      />
      <path
        d="m 228.24,164.03 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7280"
      />
      <path
        d="M 228.21,164.01 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7281"
      />
      <path
        d="m 228.21,164.01 v -0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7282"
      />
      <path
        d="m 228.21,163.04 h -0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7283"
      />
      <path
        d="m 228.16,163.04 v 0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7284"
      />
      <path
        d="M 228.16,164.01 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7285"
      />
      <path
        d="m 228.16,164.01 -0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7286"
      />
      <path
        d="m 228.13,164.03 -0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7287"
      />
      <path
        d="M 233.01,175.21 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7288"
      />
      <path
        d="m 233.01,161.27 h -6.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7289"
      />
      <path
        d="m 226.78,161.27 v 13.94 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7290"
      />
      <path
        d="m 226.78,175.21 h 6.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7291"
      />
      <path
        d="m 228.53,163.57 0.06,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7292"
      />
      <path
        d="m 228.59,163.35 0.16,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7293"
      />
      <path
        d="m 228.75,163.19 0.21,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7294"
      />
      <path
        d="m 228.96,163.13 h 2.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7295"
      />
      <path
        d="M 231.83,163.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7296"
      />
      <path
        d="m 231.83,163.13 0.21,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7297"
      />
      <path
        d="m 232.04,163.19 0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7298"
      />
      <path
        d="m 232.2,163.35 0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7299"
      />
      <path
        d="m 232.26,163.57 v 2.86 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7300"
      />
      <path
        d="M 232.26,166.43 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7301"
      />
      <path
        d="m 232.26,166.43 -0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7302"
      />
      <path
        d="m 232.2,166.65 -0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7303"
      />
      <path
        d="m 232.04,166.81 -0.21,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7304"
      />
      <path
        d="m 231.83,166.87 h -2.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7305"
      />
      <path
        d="M 228.96,166.87 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7306"
      />
      <path
        d="m 228.96,166.87 -0.21,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7307"
      />
      <path
        d="m 228.75,166.81 -0.16,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7308"
      />
      <path
        d="m 228.59,166.65 -0.06,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7309"
      />
      <path
        d="m 228.53,166.43 v -2.86 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7310"
      />
      <path
        d="m 232.2,166.37 -0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7311"
      />
      <path
        d="m 232.14,166.59 -0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7312"
      />
      <path
        d="m 231.98,166.75 -0.22,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7313"
      />
      <path
        d="m 231.76,166.81 h -2.73"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7314"
      />
      <path
        d="M 229.03,166.81 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7315"
      />
      <path
        d="m 229.03,166.81 -0.22,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7316"
      />
      <path
        d="m 228.81,166.75 -0.16,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7317"
      />
      <path
        d="m 228.65,166.59 -0.06,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7318"
      />
      <path
        d="m 228.59,166.37 v -2.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7319"
      />
      <path
        d="M 228.59,163.63 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7320"
      />
      <path
        d="m 228.59,163.63 0.06,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7321"
      />
      <path
        d="m 228.65,163.41 0.16,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7322"
      />
      <path
        d="m 228.81,163.25 0.22,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7323"
      />
      <path
        d="m 229.03,163.2 h 2.73"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7324"
      />
      <path
        d="M 231.76,163.2 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7325"
      />
      <path
        d="m 231.76,163.2 0.22,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7326"
      />
      <path
        d="m 231.98,163.25 0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7327"
      />
      <path
        d="m 232.14,163.41 0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7328"
      />
      <path
        d="m 232.2,163.63 v 2.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7329"
      />
      <path
        d="m 229.6,165.09 h -1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7330"
      />
      <path
        d="m 228.17,165.09 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7331"
      />
      <path
        d="m 228.14,165.08 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7332"
      />
      <path
        d="m 228.12,165.06 -0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7333"
      />
      <path
        d="M 228.1,165.03 V 165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7334"
      />
      <path
        d="m 228.1,165 v -0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7335"
      />
      <path
        d="m 228.1,164.97 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7336"
      />
      <path
        d="m 228.12,164.94 0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7337"
      />
      <path
        d="m 228.14,164.92 0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7338"
      />
      <path
        d="m 228.17,164.91 h 1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7339"
      />
      <path
        d="M 229.6,164.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7340"
      />
      <path
        d="m 229.6,164.91 0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7341"
      />
      <path
        d="M 229.66,164.9 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7342"
      />
      <path
        d="m 229.66,164.9 0.05,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7343"
      />
      <path
        d="m 229.71,164.91 0.04,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7344"
      />
      <path
        d="m 229.75,164.95 0.01,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7345"
      />
      <path
        d="M 229.76,165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7346"
      />
      <path
        d="m 229.76,165 -0.01,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7347"
      />
      <path
        d="m 229.75,165.05 -0.04,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7348"
      />
      <path
        d="m 229.71,165.09 -0.05,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7349"
      />
      <path
        d="M 229.66,165.11 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7350"
      />
      <path
        d="m 229.66,165.11 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7351"
      />
      <path
        d="m 230.64,165 -0.07,0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7352"
      />
      <path
        d="m 230.57,165.18 -0.18,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7353"
      />
      <path
        d="m 230.39,165.25 -0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7354"
      />
      <path
        d="M 230.22,165.18 230.14,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7355"
      />
      <path
        d="m 230.14,165 0.08,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7356"
      />
      <path
        d="m 230.22,164.83 0.17,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7357"
      />
      <path
        d="m 230.39,164.75 0.18,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7358"
      />
      <path
        d="m 230.57,164.83 0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7359"
      />
      <path
        d="M 224.23,165.93 V 165.9 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7360"
      />
      <path
        d="m 224.23,165.9 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7361"
      />
      <path
        d="m 224.2,165.88 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7362"
      />
      <path
        d="M 224.17,165.87 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7363"
      />
      <path
        d="m 224.17,165.87 -0.03,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7364"
      />
      <path
        d="m 224.14,165.88 -0.02,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7365"
      />
      <path
        d="m 224.12,165.9 -0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7366"
      />
      <path
        d="M 224.11,165.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7367"
      />
      <path
        d="m 224.11,165.93 0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7368"
      />
      <path
        d="m 224.12,165.97 0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7369"
      />
      <path
        d="M 224.15,165.99 V 166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7370"
      />
      <path
        d="m 224.15,166 v 0.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7371"
      />
      <path
        d="m 224.15,166.96 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7372"
      />
      <path
        d="M 224.19,166.96 V 166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7373"
      />
      <path
        d="M 224.19,166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7374"
      />
      <path
        d="m 224.19,166 0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7375"
      />
      <path
        d="m 224.22,165.97 0.01,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7376"
      />
      <path
        d="M 224.15,164.01 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7377"
      />
      <path
        d="m 224.15,164.01 v -0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7378"
      />
      <path
        d="m 224.15,163.04 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7379"
      />
      <path
        d="m 224.19,163.04 v 0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7380"
      />
      <path
        d="M 224.19,164.01 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7381"
      />
      <path
        d="m 224.19,164.01 0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7382"
      />
      <path
        d="m 224.22,164.03 0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7383"
      />
      <path
        d="M 224.23,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7384"
      />
      <path
        d="m 224.23,164.07 -0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7385"
      />
      <path
        d="m 224.22,164.11 -0.05,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7386"
      />
      <path
        d="M 224.17,164.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7387"
      />
      <path
        d="m 224.17,164.13 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7388"
      />
      <path
        d="m 224.14,164.12 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7389"
      />
      <path
        d="m 224.12,164.1 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7390"
      />
      <path
        d="M 224.11,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7391"
      />
      <path
        d="m 224.11,164.07 0.01,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7392"
      />
      <path
        d="m 224.12,164.03 0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7393"
      />
      <path
        d="M 219.35,175.21 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7394"
      />
      <path
        d="m 219.35,161.27 h 6.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7395"
      />
      <path
        d="m 225.57,161.27 v 13.94 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7396"
      />
      <path
        d="m 225.57,175.21 h -6.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7397"
      />
      <path
        d="m 223.83,163.57 -0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7398"
      />
      <path
        d="m 223.7,163.26 -0.31,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7399"
      />
      <path
        d="m 223.39,163.13 h -2.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7400"
      />
      <path
        d="M 220.53,163.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7401"
      />
      <path
        d="m 220.53,163.13 -0.22,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7402"
      />
      <path
        d="m 220.31,163.19 -0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7403"
      />
      <path
        d="m 220.15,163.35 -0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7404"
      />
      <path
        d="m 220.09,163.57 v 2.86 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7405"
      />
      <path
        d="M 220.09,166.43 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7406"
      />
      <path
        d="m 220.09,166.43 0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7407"
      />
      <path
        d="m 220.15,166.65 0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7408"
      />
      <path
        d="m 220.31,166.81 0.22,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7409"
      />
      <path
        d="m 220.53,166.87 h 2.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7410"
      />
      <path
        d="M 223.39,166.87 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7411"
      />
      <path
        d="m 223.39,166.87 0.31,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7412"
      />
      <path
        d="m 223.7,166.74 0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7413"
      />
      <path
        d="m 223.83,166.43 v -2.86 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7414"
      />
      <path
        d="m 220.16,166.37 0.05,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7415"
      />
      <path
        d="m 220.21,166.59 0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7416"
      />
      <path
        d="m 220.37,166.75 0.22,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7417"
      />
      <path
        d="m 220.59,166.81 h 2.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7418"
      />
      <path
        d="M 223.33,166.81 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7419"
      />
      <path
        d="m 223.33,166.81 0.31,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7420"
      />
      <path
        d="m 223.64,166.68 0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7421"
      />
      <path
        d="m 223.77,166.37 v -2.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7422"
      />
      <path
        d="M 223.77,163.63 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7423"
      />
      <path
        d="m 223.77,163.63 -0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7424"
      />
      <path
        d="m 223.64,163.32 -0.31,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7425"
      />
      <path
        d="m 223.33,163.2 h -2.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7426"
      />
      <path
        d="M 220.59,163.2 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7427"
      />
      <path
        d="m 220.59,163.2 -0.22,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7428"
      />
      <path
        d="m 220.37,163.25 -0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7429"
      />
      <path
        d="m 220.21,163.41 -0.05,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7430"
      />
      <path
        d="m 220.16,163.63 v 2.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7431"
      />
      <path
        d="m 222.7,164.9 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7432"
      />
      <path
        d="M 222.63,164.93 222.59,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7433"
      />
      <path
        d="M 222.59,165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7434"
      />
      <path
        d="m 222.59,165 0.04,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7435"
      />
      <path
        d="m 222.63,165.08 0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7436"
      />
      <path
        d="M 222.7,165.11 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7437"
      />
      <path
        d="m 222.7,165.11 0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7438"
      />
      <path
        d="m 222.76,165.09 h 1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7439"
      />
      <path
        d="m 224.19,165.09 0.02,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7440"
      />
      <path
        d="m 224.21,165.08 0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7441"
      />
      <path
        d="m 224.24,165.06 0.01,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7442"
      />
      <path
        d="M 224.25,165.03 224.26,165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7443"
      />
      <path
        d="m 224.26,165 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7444"
      />
      <path
        d="m 224.25,164.97 -0.01,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7445"
      />
      <path
        d="m 224.24,164.94 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7446"
      />
      <path
        d="m 224.21,164.92 -0.02,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7447"
      />
      <path
        d="m 224.19,164.91 h -1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7448"
      />
      <path
        d="M 222.76,164.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7449"
      />
      <path
        d="M 222.76,164.91 222.7,164.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7450"
      />
      <path
        d="m 222.21,165 -0.07,0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7451"
      />
      <path
        d="m 222.14,165.18 -0.18,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7452"
      />
      <path
        d="m 221.96,165.25 -0.18,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7453"
      />
      <path
        d="M 221.78,165.18 221.71,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7454"
      />
      <path
        d="m 221.71,165 0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7455"
      />
      <path
        d="m 221.78,164.83 0.18,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7456"
      />
      <path
        d="m 221.96,164.75 0.18,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7457"
      />
      <path
        d="m 222.14,164.83 0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7458"
      />
      <path
        d="m 196.09,165.93 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7459"
      />
      <path
        d="m 196.08,165.9 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7460"
      />
      <path
        d="m 196.06,165.88 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7461"
      />
      <path
        d="M 196.03,165.87 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7462"
      />
      <path
        d="m 196.03,165.87 -0.04,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7463"
      />
      <path
        d="m 195.99,165.88 -0.02,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7464"
      />
      <path
        d="m 195.97,165.9 -0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7465"
      />
      <path
        d="M 195.96,165.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7466"
      />
      <path
        d="m 195.96,165.93 0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7467"
      />
      <path
        d="m 195.97,165.97 0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7468"
      />
      <path
        d="m 196,165.99 0.01,0.01 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7469"
      />
      <path
        d="m 196.01,166 v 0.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7470"
      />
      <path
        d="m 196.01,166.96 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7471"
      />
      <path
        d="M 196.05,166.96 V 166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7472"
      />
      <path
        d="M 196.05,166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7473"
      />
      <path
        d="m 196.05,166 0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7474"
      />
      <path
        d="m 196.08,165.97 0.01,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7475"
      />
      <path
        d="m 196,164.01 h 0.01 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7476"
      />
      <path
        d="m 196.01,164.01 v -0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7477"
      />
      <path
        d="m 196.01,163.04 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7478"
      />
      <path
        d="m 196.05,163.04 v 0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7479"
      />
      <path
        d="M 196.05,164.01 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7480"
      />
      <path
        d="m 196.05,164.01 0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7481"
      />
      <path
        d="m 196.08,164.03 0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7482"
      />
      <path
        d="M 196.09,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7483"
      />
      <path
        d="m 196.09,164.07 -0.02,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7484"
      />
      <path
        d="m 196.07,164.11 -0.04,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7485"
      />
      <path
        d="M 196.03,164.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7486"
      />
      <path
        d="m 196.03,164.13 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7487"
      />
      <path
        d="m 195.99,164.12 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7488"
      />
      <path
        d="m 195.97,164.1 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7489"
      />
      <path
        d="M 195.96,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7490"
      />
      <path
        d="m 195.96,164.07 0.01,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7491"
      />
      <path
        d="M 195.97,164.03 196,164.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7492"
      />
      <path
        d="M 191.2,175.21 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7493"
      />
      <path
        d="m 191.2,161.27 h 6.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7494"
      />
      <path
        d="m 197.43,161.27 v 13.94 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7495"
      />
      <path
        d="M 197.43,175.21 H 191.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7496"
      />
      <path
        d="m 195.68,163.57 -0.12,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7497"
      />
      <path
        d="m 195.56,163.26 -0.31,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7498"
      />
      <path
        d="m 195.25,163.13 h -2.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7499"
      />
      <path
        d="M 192.38,163.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7500"
      />
      <path
        d="m 192.38,163.13 -0.21,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7501"
      />
      <path
        d="m 192.17,163.19 -0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7502"
      />
      <path
        d="m 192.01,163.35 -0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7503"
      />
      <path
        d="m 191.95,163.57 v 2.86 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7504"
      />
      <path
        d="M 191.95,166.43 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7505"
      />
      <path
        d="m 191.95,166.43 0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7506"
      />
      <path
        d="m 192.01,166.65 0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7507"
      />
      <path
        d="m 192.17,166.81 0.21,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7508"
      />
      <path
        d="m 192.38,166.87 h 2.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7509"
      />
      <path
        d="M 195.25,166.87 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7510"
      />
      <path
        d="m 195.25,166.87 0.31,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7511"
      />
      <path
        d="m 195.56,166.74 0.12,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7512"
      />
      <path
        d="m 195.68,166.43 v -2.86 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7513"
      />
      <path
        d="m 192.01,166.37 0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7514"
      />
      <path
        d="m 192.07,166.59 0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7515"
      />
      <path
        d="m 192.23,166.75 0.22,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7516"
      />
      <path
        d="m 192.45,166.81 h 2.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7517"
      />
      <path
        d="M 195.19,166.81 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7518"
      />
      <path
        d="m 195.19,166.81 0.3,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7519"
      />
      <path
        d="m 195.49,166.68 0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7520"
      />
      <path
        d="m 195.62,166.37 v -2.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7521"
      />
      <path
        d="M 195.62,163.63 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7522"
      />
      <path
        d="m 195.62,163.63 -0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7523"
      />
      <path
        d="m 195.49,163.32 -0.3,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7524"
      />
      <path
        d="m 195.19,163.2 h -2.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7525"
      />
      <path
        d="M 192.45,163.2 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7526"
      />
      <path
        d="m 192.45,163.2 -0.22,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7527"
      />
      <path
        d="m 192.23,163.25 -0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7528"
      />
      <path
        d="m 192.07,163.41 -0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7529"
      />
      <path
        d="m 192.01,163.63 v 2.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7530"
      />
      <path
        d="m 194.55,164.9 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7531"
      />
      <path
        d="M 194.48,164.93 194.45,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7532"
      />
      <path
        d="M 194.45,165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7533"
      />
      <path
        d="m 194.45,165 0.03,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7534"
      />
      <path
        d="m 194.48,165.08 0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7535"
      />
      <path
        d="M 194.55,165.11 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7536"
      />
      <path
        d="m 194.55,165.11 0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7537"
      />
      <path
        d="m 194.61,165.09 h 1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7538"
      />
      <path
        d="m 196.04,165.09 0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7539"
      />
      <path
        d="m 196.07,165.08 0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7540"
      />
      <path
        d="m 196.09,165.06 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7541"
      />
      <path
        d="M 196.11,165.03 V 165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7542"
      />
      <path
        d="m 196.11,165 v -0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7543"
      />
      <path
        d="m 196.11,164.97 -0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7544"
      />
      <path
        d="m 196.09,164.94 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7545"
      />
      <path
        d="m 196.07,164.92 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7546"
      />
      <path
        d="m 196.04,164.91 h -1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7547"
      />
      <path
        d="M 194.61,164.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7548"
      />
      <path
        d="m 194.61,164.91 -0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7549"
      />
      <path
        d="m 194.07,165 -0.08,0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7550"
      />
      <path
        d="m 193.99,165.18 -0.17,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7551"
      />
      <path
        d="m 193.82,165.25 -0.18,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7552"
      />
      <path
        d="M 193.64,165.18 193.57,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7553"
      />
      <path
        d="m 193.57,165 0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7554"
      />
      <path
        d="m 193.64,164.83 0.18,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7555"
      />
      <path
        d="m 193.82,164.75 0.17,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7556"
      />
      <path
        d="m 193.99,164.83 0.08,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7557"
      />
      <path
        d="m 168.03,165.93 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7558"
      />
      <path
        d="m 168.02,165.9 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7559"
      />
      <path
        d="m 167.99,165.88 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7560"
      />
      <path
        d="M 167.96,165.87 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7561"
      />
      <path
        d="m 167.96,165.87 -0.03,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7562"
      />
      <path
        d="m 167.93,165.88 -0.02,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7563"
      />
      <path
        d="m 167.91,165.9 -0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7564"
      />
      <path
        d="M 167.9,165.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7565"
      />
      <path
        d="m 167.9,165.93 0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7566"
      />
      <path
        d="m 167.91,165.97 0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7567"
      />
      <path
        d="M 167.94,165.99 V 166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7568"
      />
      <path
        d="m 167.94,166 v 0.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7569"
      />
      <path
        d="m 167.94,166.96 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7570"
      />
      <path
        d="M 167.98,166.96 V 166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7571"
      />
      <path
        d="M 167.98,166 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7572"
      />
      <path
        d="m 167.98,166 0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7573"
      />
      <path
        d="m 168.01,165.97 0.02,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7574"
      />
      <path
        d="M 167.94,164.01 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7575"
      />
      <path
        d="m 167.94,164.01 v -0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7576"
      />
      <path
        d="m 167.94,163.04 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7577"
      />
      <path
        d="m 167.98,163.04 v 0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7578"
      />
      <path
        d="M 167.98,164.01 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7579"
      />
      <path
        d="m 167.98,164.01 0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7580"
      />
      <path
        d="m 168.01,164.03 0.02,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7581"
      />
      <path
        d="M 168.03,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7582"
      />
      <path
        d="m 168.03,164.07 -0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7583"
      />
      <path
        d="m 168.02,164.1 -0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7584"
      />
      <path
        d="m 167.99,164.12 -0.03,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7585"
      />
      <path
        d="M 167.96,164.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7586"
      />
      <path
        d="m 167.96,164.13 -0.04,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7587"
      />
      <path
        d="m 167.92,164.11 -0.02,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7588"
      />
      <path
        d="M 167.9,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7589"
      />
      <path
        d="m 167.9,164.07 0.01,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7590"
      />
      <path
        d="m 167.91,164.03 0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7591"
      />
      <path
        d="M 163.14,175.21 V 161.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7592"
      />
      <path
        d="m 163.14,161.27 h 6.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7593"
      />
      <path
        d="m 169.36,161.27 v 13.94 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7594"
      />
      <path
        d="m 169.36,175.21 h -6.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7595"
      />
      <path
        d="m 167.62,163.57 -0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7596"
      />
      <path
        d="m 167.49,163.26 -0.31,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7597"
      />
      <path
        d="m 167.18,163.13 h -2.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7598"
      />
      <path
        d="M 164.32,163.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7599"
      />
      <path
        d="m 164.32,163.13 -0.22,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7600"
      />
      <path
        d="m 164.1,163.19 -0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7601"
      />
      <path
        d="m 163.94,163.35 -0.05,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7602"
      />
      <path
        d="m 163.89,163.57 v 2.86 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7603"
      />
      <path
        d="M 163.89,166.43 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7604"
      />
      <path
        d="m 163.89,166.43 0.05,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7605"
      />
      <path
        d="m 163.94,166.65 0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7606"
      />
      <path
        d="m 164.1,166.81 0.22,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7607"
      />
      <path
        d="m 164.32,166.87 h 2.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7608"
      />
      <path
        d="M 167.18,166.87 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7609"
      />
      <path
        d="m 167.18,166.87 0.31,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7610"
      />
      <path
        d="m 167.49,166.74 0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7611"
      />
      <path
        d="m 167.62,166.43 v -2.86 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7612"
      />
      <path
        d="m 163.95,166.37 0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7613"
      />
      <path
        d="m 164.01,166.59 0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7614"
      />
      <path
        d="m 164.17,166.75 0.21,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7615"
      />
      <path
        d="m 164.38,166.81 h 2.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7616"
      />
      <path
        d="M 167.12,166.81 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7617"
      />
      <path
        d="m 167.12,166.81 0.22,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7618"
      />
      <path
        d="m 167.34,166.75 0.16,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7619"
      />
      <path
        d="m 167.5,166.59 0.06,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7620"
      />
      <path
        d="m 167.56,166.37 v -2.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7621"
      />
      <path
        d="M 167.56,163.63 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7622"
      />
      <path
        d="m 167.56,163.63 -0.06,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7623"
      />
      <path
        d="m 167.5,163.41 -0.16,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7624"
      />
      <path
        d="m 167.34,163.25 -0.22,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7625"
      />
      <path
        d="m 167.12,163.2 h -2.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7626"
      />
      <path
        d="M 164.38,163.2 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7627"
      />
      <path
        d="m 164.38,163.2 -0.21,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7628"
      />
      <path
        d="m 164.17,163.25 -0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7629"
      />
      <path
        d="m 164.01,163.41 -0.06,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7630"
      />
      <path
        d="m 163.95,163.63 v 2.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7631"
      />
      <path
        d="m 166.49,164.9 -0.05,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7632"
      />
      <path
        d="m 166.44,164.91 -0.04,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7633"
      />
      <path
        d="M 166.4,164.95 166.39,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7634"
      />
      <path
        d="M 166.39,165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7635"
      />
      <path
        d="m 166.39,165 0.01,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7636"
      />
      <path
        d="m 166.4,165.05 0.04,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7637"
      />
      <path
        d="m 166.44,165.09 0.05,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7638"
      />
      <path
        d="M 166.49,165.11 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7639"
      />
      <path
        d="m 166.49,165.11 0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7640"
      />
      <path
        d="m 166.55,165.09 h 1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7641"
      />
      <path
        d="m 167.98,165.09 0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7642"
      />
      <path
        d="m 168.01,165.08 0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7643"
      />
      <path
        d="m 168.03,165.06 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7644"
      />
      <path
        d="M 168.05,165.03 V 165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7645"
      />
      <path
        d="m 168.05,165 v -0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7646"
      />
      <path
        d="m 168.05,164.97 -0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7647"
      />
      <path
        d="m 168.03,164.94 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7648"
      />
      <path
        d="m 168.01,164.92 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7649"
      />
      <path
        d="m 167.98,164.91 h -1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7650"
      />
      <path
        d="M 166.55,164.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7651"
      />
      <path
        d="m 166.55,164.91 -0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7652"
      />
      <path
        d="m 166,165 -0.07,0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7653"
      />
      <path
        d="m 165.93,165.18 -0.18,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7654"
      />
      <path
        d="m 165.75,165.25 -0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7655"
      />
      <path
        d="M 165.58,165.18 165.5,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7656"
      />
      <path
        d="m 165.5,165 0.08,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7657"
      />
      <path
        d="m 165.58,164.83 0.17,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7658"
      />
      <path
        d="m 165.75,164.75 0.18,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7659"
      />
      <path
        d="M 165.93,164.83 166,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7660"
      />
      <path
        d="M 139.82,167.15 139.8,167.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7661"
      />
      <path
        d="m 139.8,167.1 -0.05,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7662"
      />
      <path
        d="M 139.75,167.08 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7663"
      />
      <path
        d="m 139.75,167.08 -0.03,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7664"
      />
      <path
        d="m 139.72,167.09 -0.02,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7665"
      />
      <path
        d="m 139.7,167.12 -0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7666"
      />
      <path
        d="M 139.69,167.15 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7667"
      />
      <path
        d="m 139.69,167.15 0.01,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7668"
      />
      <path
        d="m 139.7,167.18 0.03,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7669"
      />
      <path
        d="M 139.73,167.21 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7670"
      />
      <path
        d="m 139.73,167.21 v 0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7671"
      />
      <path
        d="m 139.73,168.18 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7672"
      />
      <path
        d="m 139.77,168.18 v -0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7673"
      />
      <path
        d="M 139.77,167.21 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7674"
      />
      <path
        d="m 139.77,167.21 0.04,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7675"
      />
      <path
        d="m 139.81,167.19 0.01,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7676"
      />
      <path
        d="M 139.73,165.22 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7677"
      />
      <path
        d="m 139.73,165.22 v -0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7678"
      />
      <path
        d="m 139.73,164.25 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7679"
      />
      <path
        d="m 139.77,164.25 v 0.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7680"
      />
      <path
        d="M 139.77,165.22 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7681"
      />
      <path
        d="m 139.77,165.22 0.04,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7682"
      />
      <path
        d="m 139.81,165.24 0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7683"
      />
      <path
        d="M 139.82,165.28 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7684"
      />
      <path
        d="m 139.82,165.28 -0.02,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7685"
      />
      <path
        d="m 139.8,165.33 -0.05,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7686"
      />
      <path
        d="M 139.75,165.35 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7687"
      />
      <path
        d="m 139.75,165.35 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7688"
      />
      <path
        d="m 139.72,165.34 -0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7689"
      />
      <path
        d="m 139.7,165.31 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7690"
      />
      <path
        d="M 139.69,165.28 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7691"
      />
      <path
        d="m 139.69,165.28 0.01,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7692"
      />
      <path
        d="m 139.7,165.25 0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7693"
      />
      <path
        d="M 134.93,176.42 V 162.48 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7694"
      />
      <path
        d="m 134.93,162.48 h 6.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7695"
      />
      <path
        d="m 141.15,162.48 v 13.94 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7696"
      />
      <path
        d="m 141.15,176.42 h -6.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7697"
      />
      <path
        d="m 139.41,164.78 -0.06,-0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7698"
      />
      <path
        d="m 139.35,164.57 -0.16,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7699"
      />
      <path
        d="m 139.19,164.41 -0.22,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7700"
      />
      <path
        d="m 138.97,164.35 h -2.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7701"
      />
      <path
        d="M 136.11,164.35 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7702"
      />
      <path
        d="m 136.11,164.35 -0.22,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7703"
      />
      <path
        d="m 135.89,164.41 -0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7704"
      />
      <path
        d="m 135.73,164.57 -0.05,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7705"
      />
      <path
        d="m 135.68,164.78 v 2.87 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7706"
      />
      <path
        d="M 135.68,167.65 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7707"
      />
      <path
        d="m 135.68,167.65 0.05,0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7708"
      />
      <path
        d="m 135.73,167.86 0.16,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7709"
      />
      <path
        d="m 135.89,168.02 0.22,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7710"
      />
      <path
        d="m 136.11,168.08 h 2.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7711"
      />
      <path
        d="M 138.97,168.08 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7712"
      />
      <path
        d="m 138.97,168.08 0.22,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7713"
      />
      <path
        d="m 139.19,168.02 0.16,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7714"
      />
      <path
        d="m 139.35,167.86 0.06,-0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7715"
      />
      <path
        d="m 139.41,167.65 v -2.87 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7716"
      />
      <path
        d="m 135.74,167.58 0.13,0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7717"
      />
      <path
        d="m 135.87,167.89 0.3,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7718"
      />
      <path
        d="m 136.17,168.02 h 2.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7719"
      />
      <path
        d="M 138.91,168.02 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7720"
      />
      <path
        d="m 138.91,168.02 0.31,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7721"
      />
      <path
        d="m 139.22,167.89 0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7722"
      />
      <path
        d="m 139.35,167.58 v -2.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7723"
      />
      <path
        d="M 139.35,164.85 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7724"
      />
      <path
        d="m 139.35,164.85 -0.13,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7725"
      />
      <path
        d="m 139.22,164.54 -0.31,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7726"
      />
      <path
        d="m 138.91,164.41 h -2.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7727"
      />
      <path
        d="M 136.17,164.41 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7728"
      />
      <path
        d="m 136.17,164.41 -0.3,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7729"
      />
      <path
        d="m 135.87,164.54 -0.13,0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7730"
      />
      <path
        d="m 135.74,164.85 v 2.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7731"
      />
      <path
        d="m 138.28,166.11 -0.05,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7732"
      />
      <path
        d="m 138.23,166.12 -0.04,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7733"
      />
      <path
        d="m 138.19,166.16 -0.01,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7734"
      />
      <path
        d="M 138.18,166.21 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7735"
      />
      <path
        d="m 138.18,166.21 0.01,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7736"
      />
      <path
        d="m 138.19,166.27 0.04,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7737"
      />
      <path
        d="m 138.23,166.31 0.05,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7738"
      />
      <path
        d="M 138.28,166.32 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7739"
      />
      <path
        d="m 138.28,166.32 0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7740"
      />
      <path
        d="m 138.34,166.3 h 1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7741"
      />
      <path
        d="m 139.77,166.3 0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7742"
      />
      <path
        d="m 139.8,166.29 0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7743"
      />
      <path
        d="m 139.82,166.27 0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7744"
      />
      <path
        d="m 139.84,166.25 v -0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7745"
      />
      <path
        d="m 139.84,166.21 v -0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7746"
      />
      <path
        d="m 139.84,166.18 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7747"
      />
      <path
        d="m 139.82,166.16 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7748"
      />
      <path
        d="m 139.8,166.14 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7749"
      />
      <path
        d="m 139.77,166.13 h -1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7750"
      />
      <path
        d="M 138.34,166.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7751"
      />
      <path
        d="m 138.34,166.13 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7752"
      />
      <path
        d="m 137.79,166.21 -0.07,0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7753"
      />
      <path
        d="m 137.72,166.39 -0.18,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7754"
      />
      <path
        d="m 137.54,166.46 -0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7755"
      />
      <path
        d="m 137.37,166.39 -0.08,-0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7756"
      />
      <path
        d="m 137.29,166.21 0.08,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7757"
      />
      <path
        d="m 137.37,166.04 0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7758"
      />
      <path
        d="m 137.54,165.97 0.18,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7759"
      />
      <path
        d="m 137.72,166.04 0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7760"
      />
      <path
        d="m 68.79,298.72 v -8.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7761"
      />
      <path
        d="m 68.79,289.76 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7762"
      />
      <path
        d="m 69.23,289.76 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7763"
      />
      <path
        d="M 69.23,298.73 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7764"
      />
      <path
        d="m 69.23,298.73 -1.75,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7765"
      />
      <path
        d="M 67.48,298.56 65.8,298.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7766"
      />
      <path
        d="m 65.8,298.05 -1.55,-0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7767"
      />
      <path
        d="M 64.25,297.22 62.89,296.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7768"
      />
      <path
        d="m 62.89,296.1 -1.12,-1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7769"
      />
      <path
        d="m 61.77,294.74 -0.83,-1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7770"
      />
      <path
        d="m 60.94,293.19 -0.51,-1.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7771"
      />
      <path
        d="m 60.43,291.51 -0.17,-1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7772"
      />
      <path
        d="m 171.5,346.09 v 1.25 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7773"
      />
      <path
        d="M 171.5,347.34 Z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7774"
      />
      <path
        d="m 171.5,347.34 0.02,0.04"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7775"
      />
      <path
        d="m 171.52,347.38 0.05,0.02"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7776"
      />
      <path
        d="m 171.57,347.4 h 0.24"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7777"
      />
      <path
        d="m 171.81,347.4 v -2.99 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7778"
      />
      <path
        d="m 171.81,344.41 h -0.24"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7779"
      />
      <path
        d="M 171.57,344.41 Z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7780"
      />
      <path
        d="m 171.57,344.41 -0.04,0.01"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7781"
      />
      <path
        d="m 171.53,344.42 -0.02,0.02"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7782"
      />
      <path
        d="m 171.51,344.44 -0.01,0.03 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7783"
      />
      <path
        d="m 171.5,344.47 v 1.25 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7784"
      />
      <path
        d="M 171.5,345.72 Z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7785"
      />
      <path
        d="m 171.5,345.72 -0.01,0.04"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7786"
      />
      <path
        d="m 171.49,345.76 -0.05,0.02"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7787"
      />
      <path
        d="m 171.44,345.78 h -2.27"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7788"
      />
      <path
        d="m 171.44,346.03 h -2.27"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7789"
      />
      <path
        d="M 169.17,346.03 Z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7790"
      />
      <path
        d="m 169.17,346.03 -0.04,0.02"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7791"
      />
      <path
        d="m 169.13,346.05 -0.02,0.04"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7792"
      />
      <path
        d="m 169.11,346.09 v 1.25 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7793"
      />
      <path
        d="M 169.11,347.34 Z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7794"
      />
      <path
        d="m 169.11,347.34 -0.01,0.03 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7795"
      />
      <path
        d="m 169.1,347.37 -0.02,0.02"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7796"
      />
      <path
        d="m 169.08,347.39 -0.04,0.01"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7797"
      />
      <path
        d="M 169.04,347.4 H 168.8"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7798"
      />
      <path
        d="m 168.8,347.4 v -2.99 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7799"
      />
      <path
        d="m 168.8,344.41 h 0.24"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7800"
      />
      <path
        d="M 169.04,344.41 Z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7801"
      />
      <path
        d="m 169.04,344.41 0.04,0.01"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7802"
      />
      <path
        d="m 169.08,344.42 0.02,0.02"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7803"
      />
      <path
        d="m 169.1,344.44 0.01,0.03 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7804"
      />
      <path
        d="m 169.11,344.47 v 1.25 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7805"
      />
      <path
        d="M 71.53,343.23 V 282.54 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7806"
      />
      <path
        d="M 71.53,282.54 H 70.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7807"
      />
      <path
        d="m 70.28,282.54 v 5.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7808"
      />
      <path
        d="M 70.28,288.52 H 69.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7809"
      />
      <path
        d="m 69.23,288.52 v 1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7810"
      />
      <path
        d="m 69.23,289.76 h 1.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7811"
      />
      <path
        d="m 70.28,289.76 v 53.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7812"
      />
      <path
        d="M 51.67,260.82 H 43.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7813"
      />
      <path
        d="m 43.4,260.82 h 9.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7814"
      />
      <path
        d="m 52.86,260.82 h 9.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7815"
      />
      <path
        d="m 62.81,260.82 h 7.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7816"
      />
      <path
        d="m 70.28,260.82 v 1.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7817"
      />
      <path
        d="M 70.28,262.81 H 62.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7818"
      />
      <path
        d="m 62.81,262.81 v -1.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7819"
      />
      <path
        d="m 45.04,347.03 v -8.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7820"
      />
      <path
        d="m 45.04,337.69 v -4.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7821"
      />
      <path
        d="m 43.4,260.82 v 6.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7822"
      />
      <path
        d="m 43.4,267.54 h 1.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7823"
      />
      <path
        d="m 45.39,267.54 v -4.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7824"
      />
      <path
        d="m 45.39,262.81 h 7.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7825"
      />
      <path
        d="m 52.86,262.81 v -1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7826"
      />
      <path
        d="m 52.86,261.72 0.06,1.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7827"
      />
      <path
        d="m 52.92,262.81 h 0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7828"
      />
      <path
        d="m 53.54,262.81 h 8.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7829"
      />
      <path
        d="m 62.13,262.81 h 0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7830"
      />
      <path
        d="m 62.75,262.81 v -1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7831"
      />
      <path
        d="m 62.75,261.72 h 0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7832"
      />
      <path
        d="m 62.81,261.72 v 1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7833"
      />
      <path
        d="m 52.86,260.82 v 1.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7834"
      />
      <path
        d="m 52.86,262.81 h 0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7835"
      />
      <path
        d="m 52.92,262.81 v -1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7836"
      />
      <path
        d="m 52.92,261.72 h 0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7837"
      />
      <path
        d="m 53.54,261.72 h 8.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7838"
      />
      <path
        d="m 62.13,261.72 v 1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7839"
      />
      <path
        d="m 53.54,262.47 h 2.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7840"
      />
      <path
        d="m 56.47,262.47 h 5.66"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7841"
      />
      <path
        d="m 62.13,261.72 h 0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7842"
      />
      <path
        d="m 62.75,261.72 v 1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7843"
      />
      <path
        d="m 62.75,262.81 h 0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7844"
      />
      <path
        d="m 62.81,262.81 -0.06,-1.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7845"
      />
      <path
        d="m 45.39,260.82 v 1.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7846"
      />
      <path
        d="m 45.39,262.81 h 6.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7847"
      />
      <path
        d="m 35.34,268.66 h 8.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7848"
      />
      <path
        d="m 44.29,268.66 v -0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7849"
      />
      <path
        d="M 44.29,268.23 H 35.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7850"
      />
      <path
        d="M 35.33,268.23 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7851"
      />
      <path
        d="m 35.33,268.23 0.17,1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7852"
      />
      <path
        d="m 35.5,269.98 0.51,1.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7853"
      />
      <path
        d="m 36.01,271.66 0.83,1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7854"
      />
      <path
        d="m 36.84,273.21 1.11,1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7855"
      />
      <path
        d="m 37.95,274.57 1.36,1.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7856"
      />
      <path
        d="m 39.31,275.68 1.55,0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7857"
      />
      <path
        d="m 40.86,276.51 1.68,0.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7858"
      />
      <path
        d="m 42.54,277.02 1.75,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7859"
      />
      <path
        d="m 44.29,277.19 v 0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7860"
      />
      <path
        d="m 44.29,277.81 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7861"
      />
      <path
        d="m 45.39,277.81 v -0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7862"
      />
      <path
        d="m 45.39,277.19 v 4.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7863"
      />
      <path
        d="m 45.39,281.8 h -1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7864"
      />
      <path
        d="m 44.29,281.8 v 0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7865"
      />
      <path
        d="m 44.29,282.42 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7866"
      />
      <path
        d="m 45.39,282.42 h -1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7867"
      />
      <path
        d="m 44.29,282.42 v 0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7868"
      />
      <path
        d="m 44.29,282.48 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7869"
      />
      <path
        d="m 45.39,282.48 v -0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7870"
      />
      <path
        d="m 45.39,277.19 h -1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7871"
      />
      <path
        d="m 44.29,277.19 v 4.67 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7872"
      />
      <path
        d="m 45.04,277.81 v 3.99 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7873"
      />
      <path
        d="m 45.39,267.54 v 0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7874"
      />
      <path
        d="m 45.39,267.61 h -1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7875"
      />
      <path
        d="m 44.29,267.61 v -0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7876"
      />
      <path
        d="m 44.29,267.54 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7877"
      />
      <path
        d="m 45.39,267.54 -1.1,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7878"
      />
      <path
        d="m 44.29,267.61 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7879"
      />
      <path
        d="m 45.39,267.61 v 0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7880"
      />
      <path
        d="m 45.39,268.23 h -1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7881"
      />
      <path
        d="m 44.29,268.23 v -0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7882"
      />
      <path
        d="m 45.39,281.8 v 0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7883"
      />
      <path
        d="m 45.39,282.42 -1.1,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7884"
      />
      <path
        d="M 45.39,300.72 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7885"
      />
      <path
        d="m 61.33,255.03 h 8.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7886"
      />
      <path
        d="m 70.28,255.03 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7887"
      />
      <path
        d="M 70.28,255.47 H 61.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7888"
      />
      <path
        d="M 61.32,255.47 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7889"
      />
      <path
        d="m 61.32,255.47 0.17,-1.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7890"
      />
      <path
        d="M 61.49,253.72 62,252.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7891"
      />
      <path
        d="m 62,252.04 0.83,-1.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7892"
      />
      <path
        d="m 62.83,250.49 1.12,-1.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7893"
      />
      <path
        d="m 63.95,249.13 1.35,-1.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7894"
      />
      <path
        d="m 65.3,248.02 1.55,-0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7895"
      />
      <path
        d="m 66.85,247.19 1.68,-0.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7896"
      />
      <path
        d="m 68.53,246.68 1.75,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7897"
      />
      <path
        d="m 70.28,246.51 h 2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7898"
      />
      <path
        d="m 72.28,246.51 v -0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7899"
      />
      <path
        d="m 72.28,246.01 h -2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7900"
      />
      <path
        d="m 70.28,246.01 v -0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7901"
      />
      <path
        d="m 72.42,239.6 v 3.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7902"
      />
      <path
        d="M 71.68,243.08 V 239.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7903"
      />
      <path
        d="m 71.68,239.6 h 1.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7904"
      />
      <path
        d="m 70.28,256.03 h 2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7905"
      />
      <path
        d="m 72.28,256.03 -2,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7906"
      />
      <path
        d="m 70.28,255.97 h 2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7907"
      />
      <path
        d="m 72.28,255.97 v -0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7908"
      />
      <path
        d="m 72.28,255.47 h -2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7909"
      />
      <path
        d="m 70.28,255.47 v 0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7910"
      />
      <path
        d="m 70.28,255.97 v 0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7911"
      />
      <path
        d="M 69.23,298.73 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7912"
      />
      <path
        d="m 169.17,345.78 h -0.01"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7913"
      />
      <path
        d="m 169.16,345.78 h -0.01"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7914"
      />
      <path
        d="m 169.15,345.78 -0.02,-0.01"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7915"
      />
      <path
        d="m 169.13,345.77 v -0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7916"
      />
      <path
        d="m 169.13,345.76 -0.01,-0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7917"
      />
      <path
        d="m 169.12,345.75 -0.01,-0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7918"
      />
      <path
        d="m 169.11,345.74 v -0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7919"
      />
      <path
        d="m 169.11,345.73 v -0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7920"
      />
      <path
        d="m 171.44,346.03 h 0.01"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7921"
      />
      <path
        d="m 171.45,346.03 0.02,0.01"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7922"
      />
      <path
        d="m 171.47,346.04 h 0.01"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7923"
      />
      <path
        d="m 171.48,346.04 0.01,0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7924"
      />
      <path
        d="m 171.49,346.05 v 0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7925"
      />
      <path
        d="m 171.49,346.06 0.01,0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7926"
      />
      <path
        d="m 171.5,346.07 v 0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7927"
      />
      <path
        d="m 171.5,346.08 v 0.01 z"
        fill="none"
        stroke="#808080"
        stroke-width="0.5"
        id="path7928"
      />
      <path
        d="m 60.26,289.76 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7929"
      />
      <path
        d="M 60.26,288.52 H 47.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7930"
      />
      <path
        d="M 47.22,288.52 H 45.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7931"
      />
      <path
        d="m 45.39,288.52 v 1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7932"
      />
      <path
        d="M 45.39,289.76 H 60.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7933"
      />
      <path
        d="m 45.39,332.84 v -0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7934"
      />
      <path
        d="m 53.54,261.72 v 1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7935"
      />
      <path
        d="M 56.47,262.81 H 55.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7936"
      />
      <path
        d="m 55.84,262.81 v -1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7937"
      />
      <path
        d="m 55.84,261.72 h 0.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7938"
      />
      <path
        d="m 56.47,261.72 v 1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7939"
      />
      <path
        d="m 52.86,261.72 h 0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7940"
      />
      <path
        d="m 52.92,261.72 v 1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7941"
      />
      <path
        d="M 46.97,288.52 H 45.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7942"
      />
      <path
        d="m 45.39,288.52 v -6.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7943"
      />
      <path
        d="m 45.39,282.48 h 1.58"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7944"
      />
      <path
        d="m 46.97,282.48 v 6.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7945"
      />
      <path
        d="M 52.86,264.46 H 46.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7946"
      />
      <path
        d="m 46.97,264.46 v 3.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7947"
      />
      <path
        d="M 46.97,267.54 H 45.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7948"
      />
      <path
        d="m 45.39,267.54 v -4.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7949"
      />
      <path
        d="m 45.39,262.81 h 7.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7950"
      />
      <path
        d="m 52.86,262.81 v 1.65 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7951"
      />
      <path
        d="m 52.86,264.46 h 9.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7952"
      />
      <path
        d="m 62.81,264.46 v -1.65 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7953"
      />
      <path
        d="m 62.81,262.81 h 7.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7954"
      />
      <path
        d="m 70.28,262.81 v 1.65 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7955"
      />
      <path
        d="M 70.28,264.46 H 62.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7956"
      />
      <path
        d="m 43.4,290.57 v 0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7957"
      />
      <path
        d="m 43.4,290.64 h 1.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7958"
      />
      <path
        d="m 71.68,239.1 h 1.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7959"
      />
      <path
        d="m 72.28,246.01 h -2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7960"
      />
      <path
        d="m 70.28,246.01 v 0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7961"
      />
      <path
        d="m 72.28,256.03 v -0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7962"
      />
      <path
        d="m 72.28,255.97 h -2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7963"
      />
      <path
        d="m 74.27,257.25 v 7.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7964"
      />
      <path
        d="M 74.27,264.87 H 70.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7965"
      />
      <path
        d="m 70.28,264.87 v -8.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7966"
      />
      <path
        d="m 70.28,256.03 h 2.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7967"
      />
      <path
        d="m 72.77,256.03 v 1.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7968"
      />
      <path
        d="m 72.77,257.25 h 1.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7969"
      />
      <path
        d="m 46.63,260.45 h 1.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7970"
      />
      <path
        d="m 48.13,260.45 v 0.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7971"
      />
      <path
        d="m 48.13,261.2 h -1.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7972"
      />
      <path
        d="m 46.63,261.2 v -0.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7973"
      />
      <path
        d="m 227.98,175.21 h -1.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7974"
      />
      <path
        d="m 226.78,175.21 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7975"
      />
      <path
        d="m 226.78,172.82 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7976"
      />
      <path
        d="m 226.78,170.43 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7977"
      />
      <path
        d="m 226.78,168.04 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7978"
      />
      <path
        d="m 226.78,165.65 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7979"
      />
      <path
        d="m 226.78,163.26 v -0.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7980"
      />
      <path
        d="m 226.78,162.46 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7981"
      />
      <path
        d="m 226.78,161.27 h 1.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7982"
      />
      <path
        d="m 229.08,175.21 h 1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7983"
      />
      <path
        d="m 230.27,175.21 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7984"
      />
      <path
        d="m 230.27,172.82 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7985"
      />
      <path
        d="m 230.27,170.43 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7986"
      />
      <path
        d="m 230.27,168.04 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7987"
      />
      <path
        d="m 230.27,165.65 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7988"
      />
      <path
        d="m 230.27,163.26 v -0.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7989"
      />
      <path
        d="m 230.27,162.46 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7990"
      />
      <path
        d="m 230.27,161.27 h -1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7991"
      />
      <path
        d="m 224.38,175.21 h 1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7992"
      />
      <path
        d="m 225.57,175.21 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7993"
      />
      <path
        d="m 225.57,172.82 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7994"
      />
      <path
        d="m 225.57,170.43 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7995"
      />
      <path
        d="m 225.57,168.04 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7996"
      />
      <path
        d="m 225.57,165.65 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7997"
      />
      <path
        d="m 225.57,163.26 v -0.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7998"
      />
      <path
        d="m 225.57,162.46 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path7999"
      />
      <path
        d="m 225.57,161.27 h -1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8000"
      />
      <path
        d="m 223.28,175.21 h -1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8001"
      />
      <path
        d="m 222.09,175.21 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8002"
      />
      <path
        d="m 222.09,172.82 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8003"
      />
      <path
        d="m 222.09,170.43 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8004"
      />
      <path
        d="m 222.09,168.04 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8005"
      />
      <path
        d="m 222.09,165.65 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8006"
      />
      <path
        d="m 222.09,163.26 v -0.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8007"
      />
      <path
        d="m 222.09,162.46 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8008"
      />
      <path
        d="m 222.09,161.27 h 1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8009"
      />
      <path
        d="m 196.23,175.21 h 1.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8010"
      />
      <path
        d="m 197.43,175.21 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8011"
      />
      <path
        d="m 197.43,172.82 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8012"
      />
      <path
        d="m 197.43,170.43 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8013"
      />
      <path
        d="m 197.43,168.04 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8014"
      />
      <path
        d="m 197.43,165.65 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8015"
      />
      <path
        d="m 197.43,163.26 v -0.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8016"
      />
      <path
        d="m 197.43,162.46 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8017"
      />
      <path
        d="m 197.43,161.27 h -1.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8018"
      />
      <path
        d="m 195.14,175.21 h -1.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8019"
      />
      <path
        d="m 193.94,175.21 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8020"
      />
      <path
        d="m 193.94,172.82 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8021"
      />
      <path
        d="m 193.94,170.43 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8022"
      />
      <path
        d="m 193.94,168.04 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8023"
      />
      <path
        d="m 193.94,165.65 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8024"
      />
      <path
        d="m 193.94,163.26 v -0.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8025"
      />
      <path
        d="m 193.94,162.46 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8026"
      />
      <path
        d="m 193.94,161.27 h 1.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8027"
      />
      <path
        d="m 168.17,175.21 h 1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8028"
      />
      <path
        d="m 169.36,175.21 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8029"
      />
      <path
        d="m 169.36,172.82 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8030"
      />
      <path
        d="m 169.36,170.43 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8031"
      />
      <path
        d="m 169.36,168.04 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8032"
      />
      <path
        d="m 169.36,165.65 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8033"
      />
      <path
        d="m 169.36,163.26 v -0.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8034"
      />
      <path
        d="m 169.36,162.46 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8035"
      />
      <path
        d="m 169.36,161.27 h -1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8036"
      />
      <path
        d="m 167.07,175.21 h -1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8037"
      />
      <path
        d="m 165.88,175.21 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8038"
      />
      <path
        d="m 165.88,172.82 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8039"
      />
      <path
        d="m 165.88,170.43 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8040"
      />
      <path
        d="m 165.88,168.04 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8041"
      />
      <path
        d="m 165.88,165.65 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8042"
      />
      <path
        d="m 165.88,163.26 v -0.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8043"
      />
      <path
        d="m 165.88,162.46 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8044"
      />
      <path
        d="m 165.88,161.27 h 1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8045"
      />
      <path
        d="m 139.96,176.42 h 1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8046"
      />
      <path
        d="m 141.15,176.42 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8047"
      />
      <path
        d="m 141.15,174.03 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8048"
      />
      <path
        d="m 141.15,171.64 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8049"
      />
      <path
        d="m 141.15,169.25 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8050"
      />
      <path
        d="m 141.15,166.86 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8051"
      />
      <path
        d="m 141.15,164.47 v -0.79 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8052"
      />
      <path
        d="m 141.15,163.68 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8053"
      />
      <path
        d="m 141.15,162.48 h -1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8054"
      />
      <path
        d="m 138.86,176.42 h -1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8055"
      />
      <path
        d="m 137.67,176.42 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8056"
      />
      <path
        d="m 137.67,174.03 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8057"
      />
      <path
        d="m 137.67,171.64 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8058"
      />
      <path
        d="m 137.67,169.25 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8059"
      />
      <path
        d="m 137.67,166.86 v -1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8060"
      />
      <path
        d="m 137.67,164.47 v -0.79 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8061"
      />
      <path
        d="m 137.67,163.68 v -1.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8062"
      />
      <path
        d="m 137.67,162.48 h 1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8063"
      />
      <path
        d="m 228.34,165.93 -0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8064"
      />
      <path
        d="m 228.29,165.82 -0.11,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8065"
      />
      <path
        d="M 228.18,165.78 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8066"
      />
      <path
        d="m 228.18,165.78 -0.1,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8067"
      />
      <path
        d="m 228.08,165.82 -0.05,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8068"
      />
      <path
        d="M 228.03,165.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8069"
      />
      <path
        d="m 228.03,165.93 0.05,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8070"
      />
      <path
        d="m 228.08,166.04 0.1,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8071"
      />
      <path
        d="M 228.18,166.09 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8072"
      />
      <path
        d="m 228.18,166.09 0.11,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8073"
      />
      <path
        d="m 228.29,166.04 0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8074"
      />
      <path
        d="m 228.03,164.07 0.05,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8075"
      />
      <path
        d="m 228.08,164.18 0.1,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8076"
      />
      <path
        d="M 228.18,164.22 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8077"
      />
      <path
        d="m 228.18,164.22 0.11,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8078"
      />
      <path
        d="m 228.29,164.18 0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8079"
      />
      <path
        d="M 228.34,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8080"
      />
      <path
        d="m 228.34,164.07 -0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8081"
      />
      <path
        d="m 228.29,163.96 -0.11,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8082"
      />
      <path
        d="M 228.18,163.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8083"
      />
      <path
        d="m 228.18,163.91 -0.1,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8084"
      />
      <path
        d="m 228.08,163.96 -0.05,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8085"
      />
      <path
        d="m 228.4,164.91 -0.08,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8086"
      />
      <path
        d="m 228.32,164.81 -0.14,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8087"
      />
      <path
        d="M 228.18,164.77 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8088"
      />
      <path
        d="m 228.18,164.77 -0.16,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8089"
      />
      <path
        d="M 228.02,164.83 227.95,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8090"
      />
      <path
        d="M 227.95,165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8091"
      />
      <path
        d="m 227.95,165 0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8092"
      />
      <path
        d="m 228.02,165.17 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8093"
      />
      <path
        d="M 228.18,165.24 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8094"
      />
      <path
        d="m 228.18,165.24 0.14,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8095"
      />
      <path
        d="m 228.32,165.2 0.08,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8096"
      />
      <path
        d="m 224.33,165.93 -0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8097"
      />
      <path
        d="m 224.28,165.82 -0.11,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8098"
      />
      <path
        d="M 224.17,165.78 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8099"
      />
      <path
        d="m 224.17,165.78 -0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8100"
      />
      <path
        d="m 224.09,165.8 -0.05,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8101"
      />
      <path
        d="m 224.04,165.86 -0.03,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8102"
      />
      <path
        d="M 224.01,165.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8103"
      />
      <path
        d="m 224.01,165.93 0.03,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8104"
      />
      <path
        d="m 224.04,166.01 0.05,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8105"
      />
      <path
        d="m 224.09,166.07 0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8106"
      />
      <path
        d="M 224.17,166.09 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8107"
      />
      <path
        d="m 224.17,166.09 0.11,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8108"
      />
      <path
        d="m 224.28,166.04 0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8109"
      />
      <path
        d="m 224.01,164.07 0.03,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8110"
      />
      <path
        d="m 224.04,164.15 0.05,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8111"
      />
      <path
        d="m 224.09,164.2 0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8112"
      />
      <path
        d="M 224.17,164.22 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8113"
      />
      <path
        d="m 224.17,164.22 0.11,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8114"
      />
      <path
        d="m 224.28,164.18 0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8115"
      />
      <path
        d="M 224.33,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8116"
      />
      <path
        d="m 224.33,164.07 -0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8117"
      />
      <path
        d="m 224.28,163.96 -0.11,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8118"
      />
      <path
        d="M 224.17,163.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8119"
      />
      <path
        d="m 224.17,163.91 -0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8120"
      />
      <path
        d="m 224.09,163.93 -0.05,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8121"
      />
      <path
        d="m 224.04,163.99 -0.03,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8122"
      />
      <path
        d="m 223.95,165.09 0.09,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8123"
      />
      <path
        d="m 224.04,165.2 0.13,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8124"
      />
      <path
        d="M 224.17,165.24 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8125"
      />
      <path
        d="m 224.17,165.24 0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8126"
      />
      <path
        d="M 224.34,165.17 224.41,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8127"
      />
      <path
        d="M 224.41,165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8128"
      />
      <path
        d="m 224.41,165 -0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8129"
      />
      <path
        d="m 224.34,164.83 -0.17,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8130"
      />
      <path
        d="M 224.17,164.77 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8131"
      />
      <path
        d="m 224.17,164.77 -0.13,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8132"
      />
      <path
        d="m 224.04,164.81 -0.09,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8133"
      />
      <path
        d="m 196.18,165.93 -0.04,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8134"
      />
      <path
        d="m 196.14,165.82 -0.11,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8135"
      />
      <path
        d="M 196.03,165.78 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8136"
      />
      <path
        d="m 196.03,165.78 -0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8137"
      />
      <path
        d="m 195.95,165.8 -0.06,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8138"
      />
      <path
        d="m 195.89,165.86 -0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8139"
      />
      <path
        d="M 195.87,165.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8140"
      />
      <path
        d="m 195.87,165.93 0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8141"
      />
      <path
        d="m 195.89,166.01 0.06,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8142"
      />
      <path
        d="m 195.95,166.07 0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8143"
      />
      <path
        d="M 196.03,166.09 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8144"
      />
      <path
        d="m 196.03,166.09 0.11,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8145"
      />
      <path
        d="m 196.14,166.04 0.04,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8146"
      />
      <path
        d="m 195.87,164.07 0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8147"
      />
      <path
        d="m 195.89,164.15 0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8148"
      />
      <path
        d="m 195.95,164.2 0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8149"
      />
      <path
        d="M 196.03,164.22 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8150"
      />
      <path
        d="m 196.03,164.22 0.11,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8151"
      />
      <path
        d="m 196.14,164.18 0.04,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8152"
      />
      <path
        d="M 196.18,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8153"
      />
      <path
        d="m 196.18,164.07 -0.04,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8154"
      />
      <path
        d="m 196.14,163.96 -0.11,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8155"
      />
      <path
        d="M 196.03,163.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8156"
      />
      <path
        d="m 196.03,163.91 -0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8157"
      />
      <path
        d="m 195.95,163.93 -0.06,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8158"
      />
      <path
        d="m 195.89,163.99 -0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8159"
      />
      <path
        d="m 195.81,165.09 0.08,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8160"
      />
      <path
        d="m 195.89,165.2 0.14,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8161"
      />
      <path
        d="M 196.03,165.24 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8162"
      />
      <path
        d="m 196.03,165.24 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8163"
      />
      <path
        d="M 196.19,165.17 196.26,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8164"
      />
      <path
        d="M 196.26,165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8165"
      />
      <path
        d="m 196.26,165 -0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8166"
      />
      <path
        d="m 196.19,164.83 -0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8167"
      />
      <path
        d="M 196.03,164.77 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8168"
      />
      <path
        d="m 196.03,164.77 -0.14,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8169"
      />
      <path
        d="m 195.89,164.81 -0.08,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8170"
      />
      <path
        d="m 168.12,165.93 -0.02,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8171"
      />
      <path
        d="m 168.1,165.86 -0.06,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8172"
      />
      <path
        d="m 168.04,165.8 -0.08,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8173"
      />
      <path
        d="M 167.96,165.78 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8174"
      />
      <path
        d="m 167.96,165.78 -0.11,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8175"
      />
      <path
        d="m 167.85,165.82 -0.04,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8176"
      />
      <path
        d="M 167.81,165.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8177"
      />
      <path
        d="m 167.81,165.93 0.04,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8178"
      />
      <path
        d="m 167.85,166.04 0.11,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8179"
      />
      <path
        d="M 167.96,166.09 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8180"
      />
      <path
        d="m 167.96,166.09 0.08,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8181"
      />
      <path
        d="m 168.04,166.07 0.06,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8182"
      />
      <path
        d="m 168.1,166.01 0.02,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8183"
      />
      <path
        d="m 167.81,164.07 0.04,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8184"
      />
      <path
        d="m 167.85,164.18 0.11,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8185"
      />
      <path
        d="M 167.96,164.22 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8186"
      />
      <path
        d="m 167.96,164.22 0.08,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8187"
      />
      <path
        d="m 168.04,164.2 0.06,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8188"
      />
      <path
        d="m 168.1,164.15 0.02,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8189"
      />
      <path
        d="M 168.12,164.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8190"
      />
      <path
        d="m 168.12,164.07 -0.02,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8191"
      />
      <path
        d="m 168.1,163.99 -0.06,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8192"
      />
      <path
        d="m 168.04,163.93 -0.08,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8193"
      />
      <path
        d="M 167.96,163.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8194"
      />
      <path
        d="m 167.96,163.91 -0.11,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8195"
      />
      <path
        d="m 167.85,163.96 -0.04,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8196"
      />
      <path
        d="m 167.74,165.09 0.09,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8197"
      />
      <path
        d="m 167.83,165.2 0.13,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8198"
      />
      <path
        d="M 167.96,165.24 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8199"
      />
      <path
        d="m 167.96,165.24 0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8200"
      />
      <path
        d="M 168.13,165.17 168.2,165"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8201"
      />
      <path
        d="M 168.2,165 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8202"
      />
      <path
        d="m 168.2,165 -0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8203"
      />
      <path
        d="m 168.13,164.83 -0.17,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8204"
      />
      <path
        d="M 167.96,164.77 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8205"
      />
      <path
        d="m 167.96,164.77 -0.13,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8206"
      />
      <path
        d="m 167.83,164.81 -0.09,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8207"
      />
      <path
        d="m 139.91,167.15 -0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8208"
      />
      <path
        d="m 139.86,167.04 -0.11,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8209"
      />
      <path
        d="M 139.75,166.99 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8210"
      />
      <path
        d="m 139.75,166.99 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8211"
      />
      <path
        d="m 139.68,167.01 -0.06,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8212"
      />
      <path
        d="m 139.62,167.07 -0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8213"
      />
      <path
        d="M 139.6,167.15 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8214"
      />
      <path
        d="m 139.6,167.15 0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8215"
      />
      <path
        d="m 139.62,167.23 0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8216"
      />
      <path
        d="m 139.68,167.28 0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8217"
      />
      <path
        d="M 139.75,167.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8218"
      />
      <path
        d="m 139.75,167.3 0.11,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8219"
      />
      <path
        d="m 139.86,167.26 0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8220"
      />
      <path
        d="m 139.6,165.28 0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8221"
      />
      <path
        d="m 139.62,165.36 0.06,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8222"
      />
      <path
        d="m 139.68,165.42 0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8223"
      />
      <path
        d="M 139.75,165.44 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8224"
      />
      <path
        d="m 139.75,165.44 0.11,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8225"
      />
      <path
        d="m 139.86,165.39 0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8226"
      />
      <path
        d="M 139.91,165.28 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8227"
      />
      <path
        d="m 139.91,165.28 -0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8228"
      />
      <path
        d="m 139.86,165.17 -0.11,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8229"
      />
      <path
        d="M 139.75,165.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8230"
      />
      <path
        d="m 139.75,165.13 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8231"
      />
      <path
        d="m 139.68,165.15 -0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8232"
      />
      <path
        d="m 139.62,165.2 -0.02,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8233"
      />
      <path
        d="m 139.53,166.3 0.09,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8234"
      />
      <path
        d="m 139.62,166.41 0.13,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8235"
      />
      <path
        d="M 139.75,166.45 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8236"
      />
      <path
        d="m 139.75,166.45 0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8237"
      />
      <path
        d="m 139.92,166.38 0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8238"
      />
      <path
        d="M 139.99,166.21 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8239"
      />
      <path
        d="m 139.99,166.21 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8240"
      />
      <path
        d="m 139.92,166.05 -0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8241"
      />
      <path
        d="M 139.75,165.98 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8242"
      />
      <path
        d="m 139.75,165.98 -0.13,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8243"
      />
      <path
        d="m 139.62,166.02 -0.09,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8244"
      />
      <path
        d="m 43.42,299.43 v 54.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8245"
      />
      <path
        d="M 70.28,354.23 H 43.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8246"
      />
      <path
        d="m 45.39,299.43 v 52.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path8247"
      />
      <path
        d="M 70.28,156.35 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9687"
      />
      <path
        d="M 70.28,439.08 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9688"
      />
      <path
        d="M 102.13,197.93 H 76.73"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9690"
      />
      <path
        d="m 76.73,197.93 v 1.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9691"
      />
      <path
        d="m 76.73,199.29 h -1.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9692"
      />
      <path
        d="m 237.54,198.01 h 19.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9693"
      />
      <path
        d="m 275.01,198.01 h 19.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9694"
      />
      <path
        d="m 303.24,198.01 h 3.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9695"
      />
      <path
        d="m 315.35,198.01 h 16.71"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9696"
      />
      <path
        d="m 333.31,198.01 h 14.92"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9697"
      />
      <path
        d="m 237.54,196.76 h 18.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9698"
      />
      <path
        d="m 276.26,196.76 h 18.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9699"
      />
      <path
        d="m 303.24,196.76 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9700"
      />
      <path
        d="m 305.58,196.76 h 0.83"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9701"
      />
      <path
        d="m 315.33,196.76 h 31.65"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9702"
      />
      <path
        d="M 348.23,198.01 V 163.83 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9703"
      />
      <path
        d="M 346.98,196.76 V 163.83 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9704"
      />
      <path
        d="m 257.39,198.01 v -9.54 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9705"
      />
      <path
        d="M 257.39,187.23 V 160.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9706"
      />
      <path
        d="M 256.15,196.76 V 160.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9707"
      />
      <path
        d="M 276.26,196.76 V 160.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9708"
      />
      <path
        d="m 275.01,198.01 v -9.54 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9709"
      />
      <path
        d="M 275.01,187.23 V 160.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9710"
      />
      <path
        d="M 305.58,196.76 V 160.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9711"
      />
      <path
        d="M 304.34,196.76 V 160.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9712"
      />
      <path
        d="m 275.01,187.23 h -8.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9713"
      />
      <path
        d="m 258.05,187.23 h -0.66"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9714"
      />
      <path
        d="m 275.01,188.47 h -8.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9715"
      />
      <path
        d="m 258.4,188.47 h -1.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9716"
      />
      <path
        d="m 107.55,230.27 v 10.3 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9717"
      />
      <path
        d="M 107.55,248.94 V 250 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9718"
      />
      <path
        d="m 107.55,258.37 v 3.95 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9719"
      />
      <path
        d="m 107.55,276.5 v 21.77 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9720"
      />
      <path
        d="M 107.55,307.21 V 337.5 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9721"
      />
      <path
        d="m 107.55,346.44 v 12.38 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9722"
      />
      <path
        d="m 106.3,230.27 v 6.51 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9723"
      />
      <path
        d="m 106.3,238.03 v 2.54 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9724"
      />
      <path
        d="M 106.3,248.94 V 250 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9725"
      />
      <path
        d="m 106.3,258.37 v 2.7 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9726"
      />
      <path
        d="m 106.3,277.74 v 18.01 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9727"
      />
      <path
        d="m 106.3,296.99 v 1.28 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9728"
      />
      <path
        d="m 106.3,307.19 v 27.68 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9729"
      />
      <path
        d="m 106.3,336.11 v 1.39 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9730"
      />
      <path
        d="m 106.3,346.42 v 11.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9731"
      />
      <path
        d="M 107.55,358.82 H 98.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9732"
      />
      <path
        d="M 97.38,358.82 H 74.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9733"
      />
      <path
        d="M 106.3,357.57 H 74.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9734"
      />
      <path
        d="M 106.3,336.11 H 71.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9735"
      />
      <path
        d="M 106.3,334.87 H 71.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9736"
      />
      <path
        d="M 106.3,296.99 H 71.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9737"
      />
      <path
        d="M 106.3,295.75 H 71.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9738"
      />
      <path
        d="m 106.3,277.74 h -1.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9739"
      />
      <path
        d="M 96.24,277.74 H 71.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9740"
      />
      <path
        d="m 107.55,276.5 h -2.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9741"
      />
      <path
        d="M 96.23,276.5 H 86.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9742"
      />
      <path
        d="M 84.82,276.5 H 70.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9743"
      />
      <path
        d="M 107.55,262.32 H 86.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9744"
      />
      <path
        d="M 84.82,262.32 H 74.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9745"
      />
      <path
        d="M 106.3,261.07 H 89.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9746"
      />
      <path
        d="M 87.99,261.07 H 74.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9747"
      />
      <path
        d="M 106.3,238.03 H 89.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9748"
      />
      <path
        d="M 87.99,238.03 H 80.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9749"
      />
      <path
        d="M 106.3,236.78 H 74.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9750"
      />
      <path
        d="m 123.63,380.5 h -2.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9751"
      />
      <path
        d="M 112.44,380.5 H 74.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9752"
      />
      <path
        d="m 127.19,379.25 h -3.56"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9753"
      />
      <path
        d="m 122.38,379.25 h -1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9754"
      />
      <path
        d="M 112.45,379.25 H 98.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9755"
      />
      <path
        d="M 97.38,379.25 H 74.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9756"
      />
      <path
        d="M 98.62,379.25 V 368.86 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9757"
      />
      <path
        d="m 98.62,359.94 v -1.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9758"
      />
      <path
        d="M 97.38,379.25 V 368.88 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9759"
      />
      <path
        d="m 97.38,359.94 v -1.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9760"
      />
      <path
        d="m 122.38,212.06 v 13.13 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9761"
      />
      <path
        d="m 122.38,249.93 v 21.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9762"
      />
      <path
        d="m 122.38,320.58 v 7.79 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9763"
      />
      <path
        d="m 122.38,343.29 v 35.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9764"
      />
      <path
        d="m 123.63,213.3 v 10.26 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9765"
      />
      <path
        d="m 123.63,240.27 v 0.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9766"
      />
      <path
        d="m 123.63,249.91 v 20.51 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9767"
      />
      <path
        d="m 123.63,320.58 v 6.54 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9768"
      />
      <path
        d="m 123.63,344.64 v 34.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9769"
      />
      <path
        d="m 123.63,380.5 v 54.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9770"
      />
      <path
        d="m 122.38,212.06 h 7.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9771"
      />
      <path
        d="m 144.63,212.06 h 25.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9772"
      />
      <path
        d="m 179.42,212.06 h 19.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9773"
      />
      <path
        d="m 207.89,212.06 h 19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9774"
      />
      <path
        d="m 235.83,212.06 h 19.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9775"
      />
      <path
        d="m 264.29,212.06 h 19.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9776"
      />
      <path
        d="m 292.43,212.06 h 15.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9777"
      />
      <path
        d="m 123.63,213.3 h 6.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9778"
      />
      <path
        d="m 144.63,213.3 h 6.76"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9779"
      />
      <path
        d="m 152.64,213.3 h 17.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9780"
      />
      <path
        d="m 179.41,213.3 h 17.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9781"
      />
      <path
        d="m 197.73,213.3 h 1.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9782"
      />
      <path
        d="M 207.87,213.3 H 224.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9783"
      />
      <path
        d="m 225.95,213.3 h 0.94"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9784"
      />
      <path
        d="m 235.81,213.3 h 17.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9785"
      />
      <path
        d="m 254.06,213.3 h 1.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9786"
      />
      <path
        d="M 264.28,213.3 H 281"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9787"
      />
      <path
        d="m 282.24,213.3 h 1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9788"
      />
      <path
        d="m 292.41,213.3 h 14.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9789"
      />
      <path
        d="m 307.86,212.06 v 38.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9790"
      />
      <path
        d="m 306.61,213.3 v 35.87 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9791"
      />
      <path
        d="M 307.86,250.41 H 268.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9792"
      />
      <path
        d="M 267.02,250.41 H 231.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9793"
      />
      <path
        d="M 229.94,250.41 H 209.93"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9794"
      />
      <path
        d="M 208.69,250.41 H 169.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9795"
      />
      <path
        d="M 306.61,249.17 H 282.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9796"
      />
      <path
        d="M 281,249.17 H 254.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9797"
      />
      <path
        d="M 252.82,249.17 H 225.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9798"
      />
      <path
        d="M 224.7,249.17 H 197.73"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9799"
      />
      <path
        d="M 196.49,249.17 H 169.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9800"
      />
      <path
        d="m 169.46,250.41 v 36.72 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9801"
      />
      <path
        d="m 169.46,288.38 v 17.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9802"
      />
      <path
        d="m 168.22,240.27 v 30.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9803"
      />
      <path
        d="m 168.22,271.66 v 26.78 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9804"
      />
      <path
        d="m 168.22,299.69 v 6.13 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9805"
      />
      <path
        d="M 282.24,249.17 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9806"
      />
      <path
        d="M 281,249.17 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9807"
      />
      <path
        d="M 254.06,249.17 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9808"
      />
      <path
        d="M 252.82,249.17 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9809"
      />
      <path
        d="M 225.95,249.17 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9810"
      />
      <path
        d="M 224.7,249.17 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9811"
      />
      <path
        d="M 197.73,249.17 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9812"
      />
      <path
        d="M 196.49,249.17 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9813"
      />
      <path
        d="M 142.57,240.27 H 123.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9814"
      />
      <path
        d="m 142.57,239.03 -20.19,-0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9815"
      />
      <path
        d="m 142.57,224.81 -20.19,0.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9816"
      />
      <path
        d="M 142.57,223.56 H 123.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9817"
      />
      <path
        d="M 152.64,218.76 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9818"
      />
      <path
        d="M 151.39,218.76 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9819"
      />
      <path
        d="m 169.46,249.17 -0.24,-8.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9820"
      />
      <path
        d="M 168.22,270.42 H 123.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9821"
      />
      <path
        d="M 168.22,271.66 H 132.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9822"
      />
      <path
        d="m 131.27,271.66 h -8.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9823"
      />
      <path
        d="m 132.51,271.66 v 16.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9824"
      />
      <path
        d="m 132.51,297.11 v 1.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9825"
      />
      <path
        d="m 131.27,271.66 v 16.51 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9826"
      />
      <path
        d="m 131.27,297.11 v 1.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9827"
      />
      <path
        d="M 168.22,298.44 H 132.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9828"
      />
      <path
        d="m 131.27,298.44 h -8.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9829"
      />
      <path
        d="M 168.22,299.69 H 123.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9830"
      />
      <path
        d="m 86.07,262.32 v 1.34 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9831"
      />
      <path
        d="m 86.07,272.63 v 3.87 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9832"
      />
      <path
        d="m 84.82,262.32 v 1.34 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9833"
      />
      <path
        d="m 84.81,272.63 0.01,3.87 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9834"
      />
      <path
        d="m 127.19,379.25 v 55.34 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9835"
      />
      <path
        d="m 73.53,336.11 v 6.64 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9836"
      />
      <path
        d="m 71.53,282.54 v -4.8 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9837"
      />
      <path
        d="M 70.28,282.54 V 276.5 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9838"
      />
      <path
        d="m 122.38,343.29 h 6.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9839"
      />
      <path
        d="m 128.44,343.29 v 1.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9840"
      />
      <path
        d="m 128.44,344.64 h -4.81"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9841"
      />
      <path
        d="m 122.38,328.37 h 87.55"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9842"
      />
      <path
        d="m 123.63,327.12 h 85.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9843"
      />
      <path
        d="M 209.93,328.37 V 320.4 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9844"
      />
      <path
        d="M 209.93,306.07 V 285.95 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9845"
      />
      <path
        d="M 209.93,277.01 V 266.12 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9846"
      />
      <path
        d="M 209.93,264.63 V 250.41 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9847"
      />
      <path
        d="M 208.69,327.12 V 320.4 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9848"
      />
      <path
        d="M 208.69,306.07 V 288.38 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9849"
      />
      <path
        d="m 208.69,287.13 v -1.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9850"
      />
      <path
        d="M 208.69,277.02 V 250.41 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9851"
      />
      <path
        d="m 229.94,328.37 h 38.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9852"
      />
      <path
        d="m 231.18,327.12 h 37.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9853"
      />
      <path
        d="M 268.27,328.37 V 250.41 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9854"
      />
      <path
        d="M 267.02,328.37 V 250.41 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9855"
      />
      <path
        d="M 208.69,288.38 H 169.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9856"
      />
      <path
        d="M 208.69,287.13 H 169.46"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9857"
      />
      <path
        d="m 231.18,288.38 h 35.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9858"
      />
      <path
        d="m 231.18,287.13 h 35.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9859"
      />
      <path
        d="M 231.18,327.12 V 298.63 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9860"
      />
      <path
        d="m 231.18,289.7 v -1.32 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9861"
      />
      <path
        d="m 231.18,287.13 v -0.98 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9862"
      />
      <path
        d="M 231.18,277.22 V 250.41 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9863"
      />
      <path
        d="M 229.94,328.37 V 298.64 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9864"
      />
      <path
        d="m 229.94,289.7 v -3.55 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9865"
      />
      <path
        d="M 229.94,277.21 V 266.12 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9866"
      />
      <path
        d="M 229.94,264.63 V 250.41 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9867"
      />
      <path
        d="m 366.98,431.6 v -2.75 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9868"
      />
      <path
        d="M 366.98,419.93 V 419 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9869"
      />
      <path
        d="m 366.98,417.76 v -1.52 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9870"
      />
      <path
        d="M 366.98,407.31 V 388.12 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9871"
      />
      <path
        d="m 366.98,386.87 v -1.52 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9872"
      />
      <path
        d="m 366.98,376.43 v -6.65 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9873"
      />
      <path
        d="m 365.73,431.6 v -2.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9874"
      />
      <path
        d="m 365.73,419.93 v -3.69 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9875"
      />
      <path
        d="M 365.73,407.3 V 385.35 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9876"
      />
      <path
        d="m 365.73,376.41 v -6.63 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9877"
      />
      <path
        d="m 366.98,419 h 29.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9878"
      />
      <path
        d="m 366.98,417.76 h 29.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9879"
      />
      <path
        d="m 366.98,388.12 h 29.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9880"
      />
      <path
        d="m 366.98,386.87 h 29.95"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9881"
      />
      <path
        d="M 209.87,434.59 V 363.48 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9882"
      />
      <path
        d="M 208.63,434.59 V 363.48 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9883"
      />
      <path
        d="m 209.87,363.48 h 0.79"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9884"
      />
      <path
        d="m 207.84,362.24 h 2.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9885"
      />
      <path
        d="m 207.84,363.48 h 0.79"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9886"
      />
      <path
        d="m 210.66,363.48 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9887"
      />
      <path
        d="m 207.84,363.48 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9888"
      />
      <path
        d="m 229.88,349.49 v -1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9889"
      />
      <path
        d="M 268.27,318.87 H 304.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9890"
      />
      <path
        d="m 304.9,318.87 v 9.59 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9891"
      />
      <path
        d="m 304.9,328.46 h -1.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9892"
      />
      <path
        d="m 303.47,328.46 v -7.65 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9893"
      />
      <path
        d="m 303.47,320.81 h -35.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9894"
      />
      <path
        d="m 365.26,261.35 v -2.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9895"
      />
      <path
        d="M 365.26,258.91 H 357.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9896"
      />
      <path
        d="M 357.7,258.91 V 218.49 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9897"
      />
      <path
        d="M 356.46,258.91 V 219.74 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9898"
      />
      <path
        d="M 357.7,218.49 H 333.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9899"
      />
      <path
        d="m 332.06,218.49 h -9.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9900"
      />
      <path
        d="M 356.46,219.74 H 324.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9901"
      />
      <path
        d="m 333.31,218.49 v -9.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9902"
      />
      <path
        d="m 333.31,198.9 v -0.89 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9903"
      />
      <path
        d="m 332.06,218.49 v -9.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9904"
      />
      <path
        d="m 332.06,198.9 v -0.89 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9905"
      />
      <path
        d="m 122.38,306.18 h 1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9906"
      />
      <path
        d="m 122.38,320.58 h 1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9907"
      />
      <path
        d="m 122.38,298.44 v 7.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9908"
      />
      <path
        d="m 123.63,299.69 v 6.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9909"
      />
      <path
        d="m 169.46,305.82 h -1.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9910"
      />
      <path
        d="M 169.22,231.21 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9911"
      />
      <path
        d="M 167.98,231.21 V 219.85 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9912"
      />
      <path
        d="M 167.98,218.76 V 213.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9913"
      />
      <path
        d="m 162.49,218.76 h 5.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9914"
      />
      <path
        d="m 162.49,219.85 h 5.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9915"
      />
      <path
        d="m 144.63,213.3 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9916"
      />
      <path
        d="m 129.65,213.3 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9917"
      />
      <path
        d="m 257.44,188.47 v -1.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9918"
      />
      <path
        d="m 257.44,186.99 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9919"
      />
      <path
        d="m 258.05,186.99 v 0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9920"
      />
      <path
        d="m 258.05,187.44 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9921"
      />
      <path
        d="m 258.24,187.44 v 0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9922"
      />
      <path
        d="m 258.24,188.01 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9923"
      />
      <path
        d="m 258.05,188.01 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9924"
      />
      <path
        d="m 258.05,188.44 v 0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9925"
      />
      <path
        d="m 258.05,188.47 h -0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9926"
      />
      <path
        d="m 258.05,188.47 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9927"
      />
      <path
        d="m 258.05,197.44 h 0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9928"
      />
      <path
        d="m 258.4,197.44 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9929"
      />
      <path
        d="m 258.4,188.47 h -0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9930"
      />
      <path
        d="m 267.48,188.47 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9931"
      />
      <path
        d="m 267.48,186.94 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9932"
      />
      <path
        d="m 266.99,186.94 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9933"
      />
      <path
        d="m 266.99,187.38 h -0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9934"
      />
      <path
        d="m 266.76,187.38 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9935"
      />
      <path
        d="m 266.76,187.99 h 0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9936"
      />
      <path
        d="m 266.97,187.99 v 0.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9937"
      />
      <path
        d="m 266.97,188.46 0.51,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9938"
      />
      <path
        d="m 258.4,197.44 a 9.032,9.032 0 0 0 8.57,-8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9939"
      />
      <path
        d="m 303.85,196.65 v 1.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9940"
      />
      <path
        d="m 303.85,198.13 h -0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9941"
      />
      <path
        d="m 303.24,198.13 v -0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9942"
      />
      <path
        d="m 303.24,197.68 h -0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9943"
      />
      <path
        d="m 303.04,197.68 v -0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9944"
      />
      <path
        d="m 303.04,197.11 h 0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9945"
      />
      <path
        d="m 303.24,197.11 v -0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9946"
      />
      <path
        d="m 303.24,196.68 v -0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9947"
      />
      <path
        d="m 303.24,196.65 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9948"
      />
      <path
        d="m 303.24,196.65 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9949"
      />
      <path
        d="m 303.24,187.68 h -0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9950"
      />
      <path
        d="m 302.88,187.68 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9951"
      />
      <path
        d="m 302.88,196.65 h 0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9952"
      />
      <path
        d="m 293.81,196.65 v 1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9953"
      />
      <path
        d="m 293.81,198.18 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9954"
      />
      <path
        d="m 294.29,198.18 v -0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9955"
      />
      <path
        d="m 294.29,197.74 h 0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9956"
      />
      <path
        d="m 294.52,197.74 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9957"
      />
      <path
        d="m 294.52,197.13 h -0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9958"
      />
      <path
        d="m 294.31,197.13 v -0.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9959"
      />
      <path
        d="m 294.31,196.66 -0.5,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9960"
      />
      <path
        d="m 302.88,187.68 a 9.032,9.032 0 0 0 -8.57,8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9961"
      />
      <path
        d="m 305.8,196.65 v 1.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9962"
      />
      <path
        d="m 305.8,198.14 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9963"
      />
      <path
        d="m 306.41,198.14 v -0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9964"
      />
      <path
        d="m 306.41,197.69 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9965"
      />
      <path
        d="m 306.6,197.69 v -0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9966"
      />
      <path
        d="m 306.6,197.12 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9967"
      />
      <path
        d="m 306.41,197.12 v -0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9968"
      />
      <path
        d="m 306.41,196.69 v -0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9969"
      />
      <path
        d="M 306.41,196.65 H 305.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9970"
      />
      <path
        d="m 306.41,196.65 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9971"
      />
      <path
        d="m 306.41,187.68 h 0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9972"
      />
      <path
        d="m 306.76,187.68 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9973"
      />
      <path
        d="m 306.76,196.65 h -0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9974"
      />
      <path
        d="m 315.84,196.65 v 1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9975"
      />
      <path
        d="m 315.84,198.18 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9976"
      />
      <path
        d="m 315.35,198.18 v -0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9977"
      />
      <path
        d="m 315.35,197.75 h -0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9978"
      />
      <path
        d="m 315.12,197.75 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9979"
      />
      <path
        d="m 315.12,197.14 h 0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9980"
      />
      <path
        d="m 315.33,197.14 v -0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9981"
      />
      <path
        d="m 315.33,196.66 0.51,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9982"
      />
      <path
        d="m 315.33,196.66 a 9.032,9.032 0 0 0 -8.57,-8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9983"
      />
      <path
        d="m 333.52,198.28 h -1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9984"
      />
      <path
        d="m 332.03,198.28 v 0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9985"
      />
      <path
        d="m 332.03,198.9 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9986"
      />
      <path
        d="m 332.48,198.9 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9987"
      />
      <path
        d="m 332.48,199.09 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9988"
      />
      <path
        d="M 333.05,199.09 V 198.9 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9989"
      />
      <path
        d="m 333.05,198.9 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9990"
      />
      <path
        d="m 333.48,198.9 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9991"
      />
      <path
        d="m 333.52,198.9 v -0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9992"
      />
      <path
        d="m 333.52,198.9 h 10.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9993"
      />
      <path
        d="m 343.92,198.9 v 0.41 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9994"
      />
      <path
        d="M 343.92,199.31 H 333.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9995"
      />
      <path
        d="m 333.51,199.25 0.01,-0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9996"
      />
      <path
        d="m 333.52,209.75 h -1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9997"
      />
      <path
        d="m 331.99,209.75 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9998"
      />
      <path
        d="m 331.99,209.26 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path9999"
      />
      <path
        d="m 332.43,209.26 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10000"
      />
      <path
        d="m 332.43,209.03 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10001"
      />
      <path
        d="m 333.04,209.03 v 0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10002"
      />
      <path
        d="m 333.04,209.24 h 0.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10003"
      />
      <path
        d="m 333.51,209.24 0.01,0.51 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10004"
      />
      <path
        d="m 333.5,209.24 a 10.473,10.473 0 0 0 10.42,-9.93"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10005"
      />
      <path
        d="m 322.94,242.74 h 1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10006"
      />
      <path
        d="m 322.94,254.61 h 1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10007"
      />
      <path
        d="m 282.88,213.49 v -1.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10008"
      />
      <path
        d="m 282.88,212.01 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10009"
      />
      <path
        d="m 283.49,212.01 v 0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10010"
      />
      <path
        d="m 283.49,212.46 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10011"
      />
      <path
        d="m 283.68,212.46 v 0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10012"
      />
      <path
        d="m 283.68,213.03 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10013"
      />
      <path
        d="m 283.49,213.03 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10014"
      />
      <path
        d="m 283.49,213.46 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10015"
      />
      <path
        d="m 283.49,213.5 -0.61,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10016"
      />
      <path
        d="m 283.49,213.5 v 8.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10017"
      />
      <path
        d="m 283.49,222.46 h 0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10018"
      />
      <path
        d="m 283.84,222.46 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10019"
      />
      <path
        d="m 283.84,213.49 -0.35,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10020"
      />
      <path
        d="m 292.92,213.49 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10021"
      />
      <path
        d="m 292.92,211.96 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10022"
      />
      <path
        d="m 292.43,211.96 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10023"
      />
      <path
        d="M 292.43,212.4 H 292.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10024"
      />
      <path
        d="m 292.2,212.4 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10025"
      />
      <path
        d="m 292.2,213.01 h 0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10026"
      />
      <path
        d="m 292.41,213.01 v 0.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10027"
      />
      <path
        d="m 292.41,213.48 0.51,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10028"
      />
      <path
        d="m 283.84,222.46 a 9.032,9.032 0 0 0 8.57,-8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10029"
      />
      <path
        d="m 254.74,213.49 v -1.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10030"
      />
      <path
        d="m 254.74,212.01 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10031"
      />
      <path
        d="m 255.35,212.01 v 0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10032"
      />
      <path
        d="m 255.35,212.46 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10033"
      />
      <path
        d="m 255.54,212.46 v 0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10034"
      />
      <path
        d="m 255.54,213.03 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10035"
      />
      <path
        d="m 255.35,213.03 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10036"
      />
      <path
        d="m 255.35,213.46 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10037"
      />
      <path
        d="m 255.35,213.5 -0.61,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10038"
      />
      <path
        d="m 255.35,213.5 v 8.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10039"
      />
      <path
        d="m 255.35,222.46 h 0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10040"
      />
      <path
        d="m 255.71,222.46 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10041"
      />
      <path
        d="m 255.71,213.49 -0.36,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10042"
      />
      <path
        d="m 264.78,213.49 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10043"
      />
      <path
        d="m 264.78,211.96 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10044"
      />
      <path
        d="m 264.29,211.96 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10045"
      />
      <path
        d="m 264.29,212.4 h -0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10046"
      />
      <path
        d="m 264.06,212.4 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10047"
      />
      <path
        d="m 264.06,213.01 h 0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10048"
      />
      <path
        d="m 264.28,213.01 v 0.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10049"
      />
      <path
        d="m 264.28,213.48 0.5,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10050"
      />
      <path
        d="m 255.71,222.46 a 9.032,9.032 0 0 0 8.57,-8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10051"
      />
      <path
        d="m 226.27,213.49 v -1.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10052"
      />
      <path
        d="m 226.27,212.01 h 0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10053"
      />
      <path
        d="m 226.89,212.01 v 0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10054"
      />
      <path
        d="m 226.89,212.46 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10055"
      />
      <path
        d="m 227.08,212.46 v 0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10056"
      />
      <path
        d="m 227.08,213.03 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10057"
      />
      <path
        d="m 226.89,213.03 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10058"
      />
      <path
        d="m 226.89,213.46 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10059"
      />
      <path
        d="m 226.89,213.5 -0.62,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10060"
      />
      <path
        d="m 226.89,213.5 v 8.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10061"
      />
      <path
        d="m 226.89,222.46 h 0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10062"
      />
      <path
        d="m 227.24,222.46 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10063"
      />
      <path
        d="m 227.24,213.49 -0.35,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10064"
      />
      <path
        d="m 236.31,213.49 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10065"
      />
      <path
        d="m 236.31,211.96 h -0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10066"
      />
      <path
        d="m 235.83,211.96 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10067"
      />
      <path
        d="M 235.83,212.4 H 235.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10068"
      />
      <path
        d="m 235.6,212.4 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10069"
      />
      <path
        d="m 235.6,213.01 h 0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10070"
      />
      <path
        d="m 235.81,213.01 v 0.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10071"
      />
      <path
        d="m 235.81,213.48 0.5,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10072"
      />
      <path
        d="m 227.24,222.46 a 9.032,9.032 0 0 0 8.57,-8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10073"
      />
      <path
        d="m 198.34,213.49 v -1.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10074"
      />
      <path
        d="m 198.34,212.01 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10075"
      />
      <path
        d="m 198.95,212.01 v 0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10076"
      />
      <path
        d="m 198.95,212.46 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10077"
      />
      <path
        d="m 199.14,212.46 v 0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10078"
      />
      <path
        d="m 199.14,213.03 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10079"
      />
      <path
        d="m 198.95,213.03 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10080"
      />
      <path
        d="m 198.95,213.46 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10081"
      />
      <path
        d="m 198.95,213.5 -0.61,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10082"
      />
      <path
        d="m 198.95,213.5 v 8.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10083"
      />
      <path
        d="m 198.95,222.46 h 0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10084"
      />
      <path
        d="m 199.3,222.46 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10085"
      />
      <path
        d="m 199.3,213.49 -0.35,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10086"
      />
      <path
        d="m 208.38,213.49 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10087"
      />
      <path
        d="m 208.38,211.96 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10088"
      />
      <path
        d="m 207.89,211.96 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10089"
      />
      <path
        d="m 207.89,212.4 h -0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10090"
      />
      <path
        d="m 207.66,212.4 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10091"
      />
      <path
        d="m 207.66,213.01 h 0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10092"
      />
      <path
        d="m 207.87,213.01 v 0.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10093"
      />
      <path
        d="m 207.87,213.48 0.51,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10094"
      />
      <path
        d="m 199.3,222.46 a 9.032,9.032 0 0 0 8.57,-8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10095"
      />
      <path
        d="m 169.87,213.49 v -1.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10096"
      />
      <path
        d="m 169.87,212.01 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10097"
      />
      <path
        d="m 170.48,212.01 v 0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10098"
      />
      <path
        d="m 170.48,212.46 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10099"
      />
      <path
        d="m 170.67,212.46 v 0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10100"
      />
      <path
        d="m 170.67,213.03 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10101"
      />
      <path
        d="m 170.48,213.03 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10102"
      />
      <path
        d="m 170.48,213.46 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10103"
      />
      <path
        d="m 170.48,213.5 -0.61,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10104"
      />
      <path
        d="m 170.48,213.5 v 8.96 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10105"
      />
      <path
        d="m 170.48,222.46 h 0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10106"
      />
      <path
        d="m 170.84,222.46 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10107"
      />
      <path
        d="m 170.84,213.49 -0.36,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10108"
      />
      <path
        d="m 179.91,213.49 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10109"
      />
      <path
        d="m 179.91,211.96 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10110"
      />
      <path
        d="m 179.42,211.96 v 0.44 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10111"
      />
      <path
        d="m 179.42,212.4 h -0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10112"
      />
      <path
        d="m 179.19,212.4 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10113"
      />
      <path
        d="m 179.19,213.01 h 0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10114"
      />
      <path
        d="m 179.41,213.01 v 0.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10115"
      />
      <path
        d="m 179.41,213.48 0.5,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10116"
      />
      <path
        d="m 170.84,222.46 a 9.032,9.032 0 0 0 8.57,-8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10117"
      />
      <path
        d="m 374.75,281.98 v 1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10118"
      />
      <path
        d="m 381.89,283.22 v -1.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10119"
      />
      <path
        d="m 351.85,348.58 v -0.41 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10120"
      />
      <path
        d="m 350.66,348.58 h 1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10121"
      />
      <path
        d="m 350.66,348.17 v 0.41 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10122"
      />
      <path
        d="m 351.85,348.17 h -1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10123"
      />
      <path
        d="m 353.28,351.48 -0.08,0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10124"
      />
      <path
        d="m 353.2,351.83 -0.21,0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10125"
      />
      <path
        d="m 352.99,352.08 -0.29,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10126"
      />
      <path
        d="m 349.81,352.18 -0.3,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10127"
      />
      <path
        d="m 349.51,352.08 -0.21,-0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10128"
      />
      <path
        d="m 349.3,351.83 -0.08,-0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10129"
      />
      <path
        d="m 349.22,349.75 0.08,-0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10130"
      />
      <path
        d="m 349.3,349.4 0.21,-0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10131"
      />
      <path
        d="m 349.51,349.15 0.3,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10132"
      />
      <path
        d="m 352.7,349.05 0.29,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10133"
      />
      <path
        d="m 352.99,349.15 0.21,0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10134"
      />
      <path
        d="m 353.2,349.4 0.08,0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10135"
      />
      <path
        d="m 353.28,351.48 v -1.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10136"
      />
      <path
        d="m 349.81,352.18 h 2.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10137"
      />
      <path
        d="m 349.22,349.75 v 1.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10138"
      />
      <path
        d="m 352.7,349.05 h -2.89"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10139"
      />
      <path
        d="m 353.68,352.58 v -4.85 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10140"
      />
      <path
        d="m 348.83,352.58 h 4.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10141"
      />
      <path
        d="m 348.83,347.73 v 4.85 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10142"
      />
      <path
        d="m 353.68,347.73 h -4.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10143"
      />
      <path
        d="m 369.33,349.75 v -1.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10144"
      />
      <path
        d="m 369.33,348.71 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10145"
      />
      <path
        d="m 369.17,348.71 v 1.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10146"
      />
      <path
        d="m 369.17,349.75 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10147"
      />
      <path
        d="m 369.33,357.69 v 1.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10148"
      />
      <path
        d="m 369.33,358.73 h -0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10149"
      />
      <path
        d="m 369.17,358.73 v -1.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10150"
      />
      <path
        d="m 369.17,357.69 h 0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10151"
      />
      <path
        d="m 356.39,347.73 h -1.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10152"
      />
      <path
        d="m 355.35,347.73 v 0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10153"
      />
      <path
        d="m 355.35,347.89 h 1.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10154"
      />
      <path
        d="m 356.39,347.89 v -0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10155"
      />
      <path
        d="m 365.82,347.73 h 1.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10156"
      />
      <path
        d="m 366.86,347.73 v 0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10157"
      />
      <path
        d="m 366.86,347.89 h -1.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10158"
      />
      <path
        d="m 365.82,347.89 v -0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10159"
      />
      <path
        d="m 368.78,353.56 v -4.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10160"
      />
      <path
        d="M 368.78,349.46 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10161"
      />
      <path
        d="m 368.78,349.46 0.01,-0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10162"
      />
      <path
        d="m 368.79,349.42 0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10163"
      />
      <path
        d="m 368.81,349.4 0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10164"
      />
      <path
        d="m 368.84,349.39 h 0.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10165"
      />
      <path
        d="m 369.17,349.39 v -0.32 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10166"
      />
      <path
        d="m 369.17,349.07 h -0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10167"
      />
      <path
        d="M 368.78,349.07 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10168"
      />
      <path
        d="m 368.78,349.07 -0.16,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10169"
      />
      <path
        d="m 368.62,349.11 -0.12,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10170"
      />
      <path
        d="m 368.5,349.23 -0.04,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10171"
      />
      <path
        d="m 368.46,349.39 -0.01,4.17 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10172"
      />
      <path
        d="m 368.45,353.56 v 4.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10173"
      />
      <path
        d="M 368.45,358.04 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10174"
      />
      <path
        d="m 368.45,358.04 0.05,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10175"
      />
      <path
        d="m 368.5,358.21 0.11,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10176"
      />
      <path
        d="m 368.61,358.32 0.17,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10177"
      />
      <path
        d="m 368.78,358.37 h 0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10178"
      />
      <path
        d="m 369.17,358.37 v -0.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10179"
      />
      <path
        d="m 369.17,358.04 h -0.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10180"
      />
      <path
        d="M 368.84,358.04 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10181"
      />
      <path
        d="m 368.84,358.04 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10182"
      />
      <path
        d="m 368.81,358.03 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10183"
      />
      <path
        d="m 368.79,358.01 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10184"
      />
      <path
        d="m 368.78,357.98 v -4.42 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10185"
      />
      <path
        d="m 356.1,348.28 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10186"
      />
      <path
        d="m 356.06,348.27 -0.02,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10187"
      />
      <path
        d="m 356.04,348.25 -0.01,-0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10188"
      />
      <path
        d="m 356.03,348.22 v -0.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10189"
      />
      <path
        d="m 356.03,347.89 h -0.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10190"
      />
      <path
        d="m 355.71,347.89 v 0.39 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10191"
      />
      <path
        d="M 355.71,348.28 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10192"
      />
      <path
        d="m 355.71,348.28 0.04,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10193"
      />
      <path
        d="m 355.75,348.44 0.12,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10194"
      />
      <path
        d="m 355.87,348.56 0.16,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10195"
      />
      <path
        d="m 356.03,348.61 h 10.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10196"
      />
      <path
        d="M 366.18,348.61 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10197"
      />
      <path
        d="m 366.18,348.61 0.16,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10198"
      />
      <path
        d="m 366.34,348.57 0.12,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10199"
      />
      <path
        d="m 366.46,348.45 0.04,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10200"
      />
      <path
        d="m 366.5,348.28 v -0.39 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10201"
      />
      <path
        d="m 366.5,347.89 h -0.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10202"
      />
      <path
        d="m 366.18,347.89 v 0.33 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10203"
      />
      <path
        d="M 366.18,348.22 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10204"
      />
      <path
        d="m 366.18,348.22 -0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10205"
      />
      <path
        d="m 366.17,348.25 -0.03,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10206"
      />
      <path
        d="m 366.14,348.28 h -0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10207"
      />
      <path
        d="M 366.11,348.28 H 356.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10208"
      />
      <path
        d="m 364.99,350.34 0.07,0.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10209"
      />
      <path
        d="m 365.06,350.87 0.05,0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10210"
      />
      <path
        d="m 365.11,351.26 0.06,0.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10211"
      />
      <path
        d="m 365.17,351.59 0.05,0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10212"
      />
      <path
        d="m 365.22,351.87 0.06,0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10213"
      />
      <path
        d="m 365.28,352.1 0.04,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10214"
      />
      <path
        d="m 365.32,352.27 0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10215"
      />
      <path
        d="m 365.35,352.39 0.03,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10216"
      />
      <path
        d="m 365.38,352.49 0.01,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10217"
      />
      <path
        d="m 365.39,352.59 0.01,0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10218"
      />
      <path
        d="m 365.4,352.71 0.01,0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10219"
      />
      <path
        d="m 365.41,352.9 v 0.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10220"
      />
      <path
        d="m 365.41,353.12 -0.02,0.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10221"
      />
      <path
        d="m 365.39,353.38 -0.03,0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10222"
      />
      <path
        d="m 365.36,353.66 -0.07,0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10223"
      />
      <path
        d="m 365.29,353.95 -0.09,0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10224"
      />
      <path
        d="m 365.2,354.24 -0.12,0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10225"
      />
      <path
        d="m 365.08,354.52 -0.14,0.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10226"
      />
      <path
        d="m 364.94,354.78 -0.17,0.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10227"
      />
      <path
        d="m 364.77,355.02 -0.2,0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10228"
      />
      <path
        d="m 364.57,355.22 -0.2,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10229"
      />
      <path
        d="m 364.37,355.39 -0.21,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10230"
      />
      <path
        d="m 364.16,355.51 -0.21,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10231"
      />
      <path
        d="m 363.95,355.58 -0.2,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10232"
      />
      <path
        d="m 363.75,355.61 -0.21,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10233"
      />
      <path
        d="m 363.54,355.58 -0.21,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10234"
      />
      <path
        d="m 363.33,355.51 -0.21,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10235"
      />
      <path
        d="m 363.12,355.39 -0.2,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10236"
      />
      <path
        d="m 362.92,355.22 -0.19,-0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10237"
      />
      <path
        d="m 362.73,355.02 -0.18,-0.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10238"
      />
      <path
        d="m 362.55,354.78 -0.14,-0.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10239"
      />
      <path
        d="m 362.41,354.52 -0.12,-0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10240"
      />
      <path
        d="m 362.29,354.24 -0.09,-0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10241"
      />
      <path
        d="m 362.2,353.95 -0.06,-0.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10242"
      />
      <path
        d="m 362.14,353.65 -0.04,-0.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10243"
      />
      <path
        d="m 362.1,353.38 -0.01,-0.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10244"
      />
      <path
        d="m 362.09,353.12 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10245"
      />
      <path
        d="m 362.09,352.89 0.01,-0.18 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10246"
      />
      <path
        d="m 362.1,352.71 0.01,-0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10247"
      />
      <path
        d="m 362.11,352.59 0.01,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10248"
      />
      <path
        d="m 362.12,352.49 0.02,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10249"
      />
      <path
        d="m 362.14,352.39 0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10250"
      />
      <path
        d="m 362.17,352.27 0.05,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10251"
      />
      <path
        d="m 362.22,352.1 0.05,-0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10252"
      />
      <path
        d="m 362.27,351.87 0.06,-0.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10253"
      />
      <path
        d="m 362.33,351.59 0.06,-0.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10254"
      />
      <path
        d="m 362.39,351.26 0.05,-0.39"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10255"
      />
      <path
        d="m 362.44,350.87 0.04,-0.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10256"
      />
      <path
        d="m 362.48,350.46 0.01,-0.11 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10257"
      />
      <path
        d="m 362.05,350.21 -0.04,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10258"
      />
      <path
        d="m 362.01,350.19 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10259"
      />
      <path
        d="m 361.98,350.18 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10260"
      />
      <path
        d="m 361.95,350.17 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10261"
      />
      <path
        d="m 361.92,350.15 -0.03,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10262"
      />
      <path
        d="m 361.89,350.14 -0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10263"
      />
      <path
        d="m 361.86,350.12 -0.04,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10264"
      />
      <path
        d="m 361.82,350.09 -0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10265"
      />
      <path
        d="m 361.79,350.06 -0.04,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10266"
      />
      <path
        d="m 361.75,350.03 -0.04,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10267"
      />
      <path
        d="m 361.71,349.98 -0.05,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10268"
      />
      <path
        d="m 361.66,349.93 -0.05,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10269"
      />
      <path
        d="m 361.61,349.88 -0.04,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10270"
      />
      <path
        d="m 361.57,349.82 -0.04,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10271"
      />
      <path
        d="m 361.53,349.76 -0.04,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10272"
      />
      <path
        d="m 361.49,349.7 -0.03,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10273"
      />
      <path
        d="m 361.46,349.63 -0.03,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10274"
      />
      <path
        d="m 361.43,349.56 -0.01,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10275"
      />
      <path
        d="m 361.42,349.49 -0.02,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10276"
      />
      <path
        d="m 361.4,349.42 -0.01,-0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10277"
      />
      <path
        d="m 361.39,349.35 v -0.08 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10278"
      />
      <path
        d="M 361.39,349.27 V 349.2 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10279"
      />
      <path
        d="m 361.39,349.2 v -0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10280"
      />
      <path
        d="m 361.39,349.13 v -0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10281"
      />
      <path
        d="M 361.39,349.07 V 349 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10282"
      />
      <path
        d="m 361.39,349 v -0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10283"
      />
      <path
        d="m 361.39,348.94 v -0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10284"
      />
      <path
        d="m 361.39,348.88 0.01,-0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10285"
      />
      <path
        d="m 361.4,348.83 v -0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10286"
      />
      <path
        d="m 361.4,348.78 v -0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10287"
      />
      <path
        d="m 361.4,348.73 0.01,-0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10288"
      />
      <path
        d="m 361.41,348.68 0.01,-0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10289"
      />
      <path
        d="M 361.42,348.64 V 348.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10290"
      />
      <path
        d="m 361.42,348.6 0.01,-0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10291"
      />
      <path
        d="m 361.43,348.56 0.01,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10292"
      />
      <path
        d="m 361.44,348.53 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10293"
      />
      <path
        d="m 361.46,348.5 0.02,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10294"
      />
      <path
        d="m 361.48,348.47 0.03,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10295"
      />
      <path
        d="m 361.51,348.44 0.03,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10296"
      />
      <path
        d="m 361.54,348.42 0.04,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10297"
      />
      <path
        d="m 361.58,348.4 0.05,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10298"
      />
      <path
        d="m 361.63,348.38 0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10299"
      />
      <path
        d="m 361.69,348.37 0.07,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10300"
      />
      <path
        d="m 361.76,348.35 0.08,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10301"
      />
      <path
        d="m 361.84,348.34 0.09,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10302"
      />
      <path
        d="m 361.93,348.33 0.1,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10303"
      />
      <path
        d="m 362.03,348.32 h 0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10304"
      />
      <path
        d="m 362.15,348.32 0.13,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10305"
      />
      <path
        d="m 362.28,348.31 0.15,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10306"
      />
      <path
        d="m 362.43,348.3 h 0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10307"
      />
      <path
        d="m 362.6,348.3 0.17,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10308"
      />
      <path
        d="m 362.77,348.29 h 0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10309"
      />
      <path
        d="m 362.95,348.29 h 0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10310"
      />
      <path
        d="m 363.15,348.29 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10311"
      />
      <path
        d="m 363.34,348.29 0.21,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10312"
      />
      <path
        d="m 363.55,348.28 h 0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10313"
      />
      <path
        d="m 363.75,348.28 h 0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10314"
      />
      <path
        d="m 363.95,348.28 0.21,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10315"
      />
      <path
        d="m 364.16,348.29 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10316"
      />
      <path
        d="m 364.35,348.29 h 0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10317"
      />
      <path
        d="m 364.55,348.29 0.18,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10318"
      />
      <path
        d="m 364.73,348.3 h 0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10319"
      />
      <path
        d="m 364.91,348.3 0.16,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10320"
      />
      <path
        d="m 365.07,348.31 h 0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10321"
      />
      <path
        d="m 365.22,348.31 0.13,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10322"
      />
      <path
        d="m 365.35,348.32 0.12,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10323"
      />
      <path
        d="m 365.47,348.33 0.1,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10324"
      />
      <path
        d="m 365.57,348.34 0.1,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10325"
      />
      <path
        d="m 365.67,348.35 0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10326"
      />
      <path
        d="m 365.74,348.36 0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10327"
      />
      <path
        d="m 365.81,348.37 0.06,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10328"
      />
      <path
        d="m 365.87,348.39 0.05,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10329"
      />
      <path
        d="m 365.92,348.41 0.04,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10330"
      />
      <path
        d="m 365.96,348.43 0.03,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10331"
      />
      <path
        d="m 365.99,348.45 0.03,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10332"
      />
      <path
        d="m 366.02,348.48 0.02,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10333"
      />
      <path
        d="m 366.04,348.5 0.01,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10334"
      />
      <path
        d="m 366.05,348.53 0.01,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10335"
      />
      <path
        d="m 366.06,348.57 0.01,0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10336"
      />
      <path
        d="m 366.07,348.6 0.01,0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10337"
      />
      <path
        d="m 366.08,348.64 0.01,0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10338"
      />
      <path
        d="m 366.09,348.69 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10339"
      />
      <path
        d="m 366.09,348.73 0.01,0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10340"
      />
      <path
        d="m 366.1,348.78 v 0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10341"
      />
      <path
        d="m 366.1,348.84 v 0.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10342"
      />
      <path
        d="m 366.1,348.89 0.01,0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10343"
      />
      <path
        d="m 366.11,348.95 v 0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10344"
      />
      <path
        d="m 366.11,349.01 v 0.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10345"
      />
      <path
        d="m 366.11,349.07 v 0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10346"
      />
      <path
        d="m 366.11,349.14 v 0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10347"
      />
      <path
        d="m 366.11,349.21 v 0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10348"
      />
      <path
        d="m 366.11,349.28 -0.01,0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10349"
      />
      <path
        d="m 366.1,349.35 -0.01,0.07 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10350"
      />
      <path
        d="m 366.09,349.42 -0.01,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10351"
      />
      <path
        d="m 366.08,349.49 -0.02,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10352"
      />
      <path
        d="m 366.06,349.56 -0.03,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10353"
      />
      <path
        d="M 366.03,349.62 366,349.69"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10354"
      />
      <path
        d="m 366,349.69 -0.04,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10355"
      />
      <path
        d="m 365.96,349.76 -0.04,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10356"
      />
      <path
        d="m 365.92,349.82 -0.05,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10357"
      />
      <path
        d="m 365.87,349.88 -0.05,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10358"
      />
      <path
        d="m 365.82,349.94 -0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10359"
      />
      <path
        d="m 365.76,349.99 -0.06,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10360"
      />
      <path
        d="m 365.7,350.04 -0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10361"
      />
      <path
        d="m 365.64,350.08 -0.06,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10362"
      />
      <path
        d="m 365.58,350.12 -0.07,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10363"
      />
      <path
        d="m 365.51,350.16 -0.06,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10364"
      />
      <path
        d="m 365.45,350.19 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10365"
      />
      <path
        d="m 365.38,350.22 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10366"
      />
      <path
        d="m 365.31,350.25 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10367"
      />
      <path
        d="m 365.24,350.27 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10368"
      />
      <path
        d="m 365.17,350.29 -0.07,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10369"
      />
      <path
        d="m 365.1,350.32 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10370"
      />
      <path
        d="m 365.03,350.34 -0.07,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10371"
      />
      <path
        d="m 364.96,350.35 -0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10372"
      />
      <path
        d="m 364.88,350.37 -0.07,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10373"
      />
      <path
        d="m 364.81,350.39 -0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10374"
      />
      <path
        d="m 364.73,350.41 -0.08,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10375"
      />
      <path
        d="m 364.65,350.42 -0.08,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10376"
      />
      <path
        d="m 364.57,350.43 -0.08,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10377"
      />
      <path
        d="m 364.49,350.45 -0.09,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10378"
      />
      <path
        d="m 364.4,350.46 -0.09,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10379"
      />
      <path
        d="m 364.31,350.47 -0.09,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10380"
      />
      <path
        d="m 364.22,350.48 h -0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10381"
      />
      <path
        d="m 364.13,350.48 -0.1,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10382"
      />
      <path
        d="m 364.03,350.49 h -0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10383"
      />
      <path
        d="m 363.94,350.49 h -0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10384"
      />
      <path
        d="m 363.84,350.49 -0.1,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10385"
      />
      <path
        d="m 363.74,350.5 h -0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10386"
      />
      <path
        d="m 363.65,350.5 -0.1,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10387"
      />
      <path
        d="m 363.55,350.49 h -0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10388"
      />
      <path
        d="m 363.46,350.49 h -0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10389"
      />
      <path
        d="m 363.36,350.49 -0.09,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10390"
      />
      <path
        d="m 363.27,350.48 -0.09,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10391"
      />
      <path
        d="M 363.18,350.47 H 363.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10392"
      />
      <path
        d="m 363.1,350.47 -0.08,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10393"
      />
      <path
        d="m 363.02,350.46 -0.08,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10394"
      />
      <path
        d="m 362.94,350.45 -0.07,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10395"
      />
      <path
        d="m 362.87,350.44 -0.07,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10396"
      />
      <path
        d="m 362.8,350.42 -0.07,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10397"
      />
      <path
        d="m 362.73,350.41 -0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10398"
      />
      <path
        d="m 362.67,350.4 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10399"
      />
      <path
        d="m 362.61,350.38 -0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10400"
      />
      <path
        d="m 362.55,350.37 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10401"
      />
      <path
        d="m 362.49,350.35 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10402"
      />
      <path
        d="m 362.43,350.33 -0.07,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10403"
      />
      <path
        d="m 362.36,350.31 -0.05,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10404"
      />
      <path
        d="m 362.31,350.29 -0.06,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10405"
      />
      <path
        d="m 362.25,350.27 -0.06,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10406"
      />
      <path
        d="m 362.19,350.26 -0.05,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10407"
      />
      <path
        d="m 362.14,350.24 -0.05,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10408"
      />
      <path
        d="m 362.09,350.22 -0.04,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10409"
      />
      <path
        d="m 347.15,368.43 v 1.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10410"
      />
      <path
        d="m 347.15,369.92 h 0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10411"
      />
      <path
        d="m 347.77,369.92 v -0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10412"
      />
      <path
        d="m 347.77,369.47 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10413"
      />
      <path
        d="M 347.96,369.47 V 368.9 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10414"
      />
      <path
        d="m 347.96,368.9 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10415"
      />
      <path
        d="m 347.77,368.9 v -0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10416"
      />
      <path
        d="m 347.77,368.47 v -0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10417"
      />
      <path
        d="m 347.77,368.43 h -0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10418"
      />
      <path
        d="m 347.77,368.43 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10419"
      />
      <path
        d="m 347.77,359.46 h 0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10420"
      />
      <path
        d="m 348.12,359.46 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10421"
      />
      <path
        d="m 348.12,368.43 h -0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10422"
      />
      <path
        d="m 357.19,368.44 v 1.52 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10423"
      />
      <path
        d="m 357.19,369.96 h -0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10424"
      />
      <path
        d="m 356.71,369.96 v -0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10425"
      />
      <path
        d="m 356.71,369.53 h -0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10426"
      />
      <path
        d="m 356.48,369.53 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10427"
      />
      <path
        d="m 356.48,368.92 h 0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10428"
      />
      <path
        d="m 356.69,368.92 v -0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10429"
      />
      <path
        d="m 356.69,368.44 h 0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10430"
      />
      <path
        d="m 356.69,368.44 a 9.032,9.032 0 0 0 -8.57,-8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10431"
      />
      <path
        d="m 367.15,385.96 h -1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10432"
      />
      <path
        d="m 365.66,385.96 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10433"
      />
      <path
        d="m 365.66,385.35 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10434"
      />
      <path
        d="m 366.11,385.35 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10435"
      />
      <path
        d="m 366.11,385.16 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10436"
      />
      <path
        d="m 366.68,385.16 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10437"
      />
      <path
        d="m 366.68,385.35 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10438"
      />
      <path
        d="m 367.11,385.35 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10439"
      />
      <path
        d="m 367.15,385.35 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10440"
      />
      <path
        d="m 367.15,385.35 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10441"
      />
      <path
        d="M 376.12,385.35 V 385 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10442"
      />
      <path
        d="m 376.12,385 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10443"
      />
      <path
        d="m 367.15,385 v 0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10444"
      />
      <path
        d="m 367.15,375.92 h -1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10445"
      />
      <path
        d="m 365.62,375.92 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10446"
      />
      <path
        d="m 365.62,376.41 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10447"
      />
      <path
        d="m 366.05,376.41 v 0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10448"
      />
      <path
        d="m 366.05,376.64 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10449"
      />
      <path
        d="m 366.66,376.64 v -0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10450"
      />
      <path
        d="m 366.66,376.43 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10451"
      />
      <path
        d="m 367.14,376.43 0.01,-0.51 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10452"
      />
      <path
        d="m 376.12,385 a 9.032,9.032 0 0 0 -8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10453"
      />
      <path
        d="m 367.15,416.85 h -1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10454"
      />
      <path
        d="m 365.66,416.85 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10455"
      />
      <path
        d="m 365.66,416.24 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10456"
      />
      <path
        d="m 366.11,416.24 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10457"
      />
      <path
        d="m 366.11,416.05 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10458"
      />
      <path
        d="m 366.68,416.05 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10459"
      />
      <path
        d="m 366.68,416.24 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10460"
      />
      <path
        d="m 367.11,416.24 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10461"
      />
      <path
        d="m 367.15,416.24 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10462"
      />
      <path
        d="m 367.15,416.24 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10463"
      />
      <path
        d="m 376.12,416.24 v -0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10464"
      />
      <path
        d="m 376.12,415.88 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10465"
      />
      <path
        d="m 367.15,415.88 v 0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10466"
      />
      <path
        d="m 367.15,406.81 h -1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10467"
      />
      <path
        d="m 365.62,406.81 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10468"
      />
      <path
        d="m 365.62,407.3 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10469"
      />
      <path
        d="m 366.05,407.3 v 0.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10470"
      />
      <path
        d="m 366.05,407.52 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10471"
      />
      <path
        d="m 366.66,407.52 v -0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10472"
      />
      <path
        d="m 366.66,407.31 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10473"
      />
      <path
        d="m 367.14,407.31 0.01,-0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10474"
      />
      <path
        d="m 376.12,415.88 a 9.032,9.032 0 0 0 -8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10475"
      />
      <path
        d="m 367.14,419.31 h -1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10476"
      />
      <path
        d="m 365.65,419.31 v 0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10477"
      />
      <path
        d="m 365.65,419.93 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10478"
      />
      <path
        d="m 366.1,419.93 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10479"
      />
      <path
        d="m 366.1,420.12 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10480"
      />
      <path
        d="m 366.67,420.12 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10481"
      />
      <path
        d="m 366.67,419.93 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10482"
      />
      <path
        d="m 367.1,419.93 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10483"
      />
      <path
        d="m 367.14,419.93 v -0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10484"
      />
      <path
        d="m 367.14,419.93 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10485"
      />
      <path
        d="m 376.11,419.93 v 0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10486"
      />
      <path
        d="m 376.11,420.28 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10487"
      />
      <path
        d="m 367.14,420.28 v -0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10488"
      />
      <path
        d="m 367.14,429.35 h -1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10489"
      />
      <path
        d="m 365.61,429.35 v -0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10490"
      />
      <path
        d="m 365.61,428.87 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10491"
      />
      <path
        d="m 366.04,428.87 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10492"
      />
      <path
        d="m 366.04,428.64 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10493"
      />
      <path
        d="m 366.65,428.64 v 0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10494"
      />
      <path
        d="m 366.65,428.85 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10495"
      />
      <path
        d="m 367.13,428.85 0.01,0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10496"
      />
      <path
        d="m 367.13,428.85 a 9.032,9.032 0 0 0 8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10497"
      />
      <path
        d="m 209.93,320.4 h -1.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10498"
      />
      <path
        d="m 209.93,306.07 h -1.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10499"
      />
      <path
        d="m 231.38,289.09 h -1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10500"
      />
      <path
        d="m 229.89,289.09 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10501"
      />
      <path
        d="m 229.89,289.7 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10502"
      />
      <path
        d="m 230.34,289.7 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10503"
      />
      <path
        d="m 230.34,289.89 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10504"
      />
      <path
        d="M 230.91,289.89 V 289.7 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10505"
      />
      <path
        d="m 230.91,289.7 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10506"
      />
      <path
        d="m 231.34,289.7 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10507"
      />
      <path
        d="m 231.38,289.7 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10508"
      />
      <path
        d="m 231.38,289.7 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10509"
      />
      <path
        d="m 240.35,289.7 v 0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10510"
      />
      <path
        d="m 240.35,290.06 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10511"
      />
      <path
        d="M 231.38,290.06 V 289.7 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10512"
      />
      <path
        d="m 231.37,299.13 h -1.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10513"
      />
      <path
        d="m 229.85,299.13 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10514"
      />
      <path
        d="m 229.85,298.64 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10515"
      />
      <path
        d="m 230.28,298.64 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10516"
      />
      <path
        d="m 230.28,298.41 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10517"
      />
      <path
        d="m 230.89,298.41 v 0.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10518"
      />
      <path
        d="m 230.89,298.63 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10519"
      />
      <path
        d="m 231.37,298.63 v 0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10520"
      />
      <path
        d="m 231.37,298.63 a 9.032,9.032 0 0 0 8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10521"
      />
      <path
        d="m 208.58,286.56 h 1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10522"
      />
      <path
        d="m 210.07,286.56 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10523"
      />
      <path
        d="m 210.07,285.95 h -0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10524"
      />
      <path
        d="m 209.62,285.95 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10525"
      />
      <path
        d="m 209.62,285.76 h -0.58"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10526"
      />
      <path
        d="m 209.04,285.76 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10527"
      />
      <path
        d="m 209.04,285.95 h -0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10528"
      />
      <path
        d="m 208.61,285.95 h -0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10529"
      />
      <path
        d="m 208.58,285.95 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10530"
      />
      <path
        d="m 208.58,285.95 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10531"
      />
      <path
        d="m 199.61,285.95 v -0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10532"
      />
      <path
        d="m 199.61,285.59 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10533"
      />
      <path
        d="m 208.58,285.59 v 0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10534"
      />
      <path
        d="m 208.58,276.52 h 1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10535"
      />
      <path
        d="m 210.11,276.52 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10536"
      />
      <path
        d="m 210.11,277.01 h -0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10537"
      />
      <path
        d="m 209.68,277.01 v 0.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10538"
      />
      <path
        d="m 209.68,277.23 h -0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10539"
      />
      <path
        d="m 209.07,277.23 v -0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10540"
      />
      <path
        d="m 209.07,277.02 h -0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10541"
      />
      <path
        d="m 208.59,277.02 -0.01,-0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10542"
      />
      <path
        d="m 208.59,277.02 a 9.032,9.032 0 0 0 -8.98,8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10543"
      />
      <path
        d="m 231.38,286.76 h -1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10544"
      />
      <path
        d="m 229.89,286.76 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10545"
      />
      <path
        d="m 229.89,286.15 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10546"
      />
      <path
        d="m 230.34,286.15 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10547"
      />
      <path
        d="m 230.34,285.96 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10548"
      />
      <path
        d="m 230.91,285.96 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10549"
      />
      <path
        d="m 230.91,286.15 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10550"
      />
      <path
        d="m 231.34,286.15 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10551"
      />
      <path
        d="m 231.38,286.15 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10552"
      />
      <path
        d="m 231.38,286.15 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10553"
      />
      <path
        d="m 240.35,286.15 v -0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10554"
      />
      <path
        d="m 240.35,285.79 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10555"
      />
      <path
        d="m 231.38,285.79 v 0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10556"
      />
      <path
        d="m 231.37,276.72 h -1.52"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10557"
      />
      <path
        d="m 229.85,276.72 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10558"
      />
      <path
        d="m 229.85,277.21 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10559"
      />
      <path
        d="m 230.28,277.21 v 0.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10560"
      />
      <path
        d="m 230.28,277.43 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10561"
      />
      <path
        d="m 230.89,277.43 v -0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10562"
      />
      <path
        d="m 230.89,277.22 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10563"
      />
      <path
        d="m 231.37,277.22 v -0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10564"
      />
      <path
        d="m 240.35,285.79 a 9.032,9.032 0 0 0 -8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10565"
      />
      <path
        d="m 229.5,266.12 v -1.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10566"
      />
      <path
        d="m 229.5,264.63 h -0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10567"
      />
      <path
        d="m 228.88,264.63 v 0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10568"
      />
      <path
        d="m 228.88,265.08 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10569"
      />
      <path
        d="m 228.69,265.08 v 0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10570"
      />
      <path
        d="m 228.69,265.65 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10571"
      />
      <path
        d="m 228.88,265.65 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10572"
      />
      <path
        d="m 228.88,266.08 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10573"
      />
      <path
        d="m 228.88,266.12 h 0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10574"
      />
      <path
        d="m 228.88,266.12 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10575"
      />
      <path
        d="m 228.88,275.09 h -0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10576"
      />
      <path
        d="m 228.53,275.09 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10577"
      />
      <path
        d="m 228.53,266.12 h 0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10578"
      />
      <path
        d="m 219.46,266.12 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10579"
      />
      <path
        d="m 219.46,264.59 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10580"
      />
      <path
        d="m 219.94,264.59 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10581"
      />
      <path
        d="m 219.94,265.02 h 0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10582"
      />
      <path
        d="m 220.17,265.02 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10583"
      />
      <path
        d="m 220.17,265.63 h -0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10584"
      />
      <path
        d="m 219.96,265.63 v 0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10585"
      />
      <path
        d="m 219.96,266.11 -0.5,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10586"
      />
      <path
        d="m 219.96,266.11 a 9.032,9.032 0 0 0 8.57,8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10587"
      />
      <path
        d="m 210.22,266.12 v -1.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10588"
      />
      <path
        d="m 210.22,264.63 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10589"
      />
      <path
        d="m 210.83,264.63 v 0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10590"
      />
      <path
        d="m 210.83,265.08 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10591"
      />
      <path
        d="m 211.02,265.08 v 0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10592"
      />
      <path
        d="m 211.02,265.65 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10593"
      />
      <path
        d="m 210.83,265.65 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10594"
      />
      <path
        d="m 210.83,266.08 v 0.04 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10595"
      />
      <path
        d="m 210.83,266.12 h -0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10596"
      />
      <path
        d="m 210.83,266.12 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10597"
      />
      <path
        d="m 210.83,275.09 h 0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10598"
      />
      <path
        d="m 211.18,275.09 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10599"
      />
      <path
        d="m 211.18,266.12 h -0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10600"
      />
      <path
        d="m 220.26,266.12 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10601"
      />
      <path
        d="m 220.26,264.59 h -0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10602"
      />
      <path
        d="m 219.77,264.59 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10603"
      />
      <path
        d="m 219.77,265.02 h -0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10604"
      />
      <path
        d="m 219.54,265.02 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10605"
      />
      <path
        d="m 219.54,265.63 h 0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10606"
      />
      <path
        d="m 219.75,265.63 v 0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10607"
      />
      <path
        d="m 219.75,266.11 0.51,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10608"
      />
      <path
        d="m 211.18,275.09 a 9.032,9.032 0 0 0 8.57,-8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10609"
      />
      <path
        d="m 229.5,266.12 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10610"
      />
      <path
        d="m 229.5,264.63 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10611"
      />
      <path
        d="m 210.22,266.12 h -0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10612"
      />
      <path
        d="m 210.22,264.63 h -0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10613"
      />
      <path
        d="m 324.3,262.96 h -1.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10614"
      />
      <path
        d="m 322.82,262.96 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10615"
      />
      <path
        d="m 322.82,263.57 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10616"
      />
      <path
        d="m 323.27,263.57 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10617"
      />
      <path
        d="m 323.27,263.76 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10618"
      />
      <path
        d="m 323.84,263.76 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10619"
      />
      <path
        d="m 323.84,263.57 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10620"
      />
      <path
        d="m 324.27,263.57 h 0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10621"
      />
      <path
        d="m 324.3,263.57 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10622"
      />
      <path
        d="m 324.3,263.57 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10623"
      />
      <path
        d="m 333.27,263.57 v 0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10624"
      />
      <path
        d="M 333.27,263.92 H 324.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10625"
      />
      <path
        d="m 324.3,263.92 v -0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10626"
      />
      <path
        d="m 324.3,273 h -1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10627"
      />
      <path
        d="m 322.77,273 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10628"
      />
      <path
        d="m 322.77,272.51 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10629"
      />
      <path
        d="m 323.21,272.51 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10630"
      />
      <path
        d="m 323.21,272.28 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10631"
      />
      <path
        d="m 323.82,272.28 v 0.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10632"
      />
      <path
        d="m 323.82,272.5 h 0.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10633"
      />
      <path
        d="m 324.29,272.5 0.01,0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10634"
      />
      <path
        d="m 324.29,272.5 a 9.032,9.032 0 0 0 8.98,-8.58"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10635"
      />
      <path
        d="m 324.33,343.4 h -1.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10636"
      />
      <path
        d="m 322.85,343.4 v -0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10637"
      />
      <path
        d="m 322.85,342.78 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10638"
      />
      <path
        d="m 323.3,342.78 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10639"
      />
      <path
        d="m 323.3,342.59 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10640"
      />
      <path
        d="m 323.87,342.59 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10641"
      />
      <path
        d="m 323.87,342.78 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10642"
      />
      <path
        d="m 324.3,342.78 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10643"
      />
      <path
        d="m 324.34,342.78 -0.01,0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10644"
      />
      <path
        d="m 324.34,342.78 h 8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10645"
      />
      <path
        d="m 333.3,342.78 v -0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10646"
      />
      <path
        d="m 333.3,342.43 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10647"
      />
      <path
        d="m 324.33,342.43 0.01,0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10648"
      />
      <path
        d="M 324.33,333.36 H 322.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10649"
      />
      <path
        d="m 322.8,333.36 v 0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10650"
      />
      <path
        d="m 322.8,333.84 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10651"
      />
      <path
        d="m 323.24,333.84 v 0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10652"
      />
      <path
        d="m 323.24,334.07 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10653"
      />
      <path
        d="m 323.85,334.07 v -0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10654"
      />
      <path
        d="m 323.85,333.86 h 0.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10655"
      />
      <path
        d="m 324.32,333.86 0.01,-0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10656"
      />
      <path
        d="m 333.3,342.43 a 9.032,9.032 0 0 0 -8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10657"
      />
      <path
        d="m 121.99,379.13 v 1.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10658"
      />
      <path
        d="m 121.99,380.61 h -0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10659"
      />
      <path
        d="m 121.38,380.61 v -0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10660"
      />
      <path
        d="m 121.38,380.16 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10661"
      />
      <path
        d="m 121.19,380.16 v -0.57 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10662"
      />
      <path
        d="m 121.19,379.59 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10663"
      />
      <path
        d="m 121.38,379.59 v -0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10664"
      />
      <path
        d="m 121.38,379.16 v -0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10665"
      />
      <path
        d="m 121.38,379.13 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10666"
      />
      <path
        d="m 121.38,379.13 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10667"
      />
      <path
        d="m 121.38,370.16 h -0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10668"
      />
      <path
        d="m 121.03,370.16 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10669"
      />
      <path
        d="m 121.03,379.13 h 0.35"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10670"
      />
      <path
        d="m 111.95,379.13 v 1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10671"
      />
      <path
        d="m 111.95,380.66 h 0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10672"
      />
      <path
        d="m 112.44,380.66 v -0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10673"
      />
      <path
        d="m 112.44,380.23 h 0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10674"
      />
      <path
        d="m 112.67,380.23 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10675"
      />
      <path
        d="m 112.67,379.62 h -0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10676"
      />
      <path
        d="m 112.45,379.62 v -0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10677"
      />
      <path
        d="m 112.45,379.14 -0.5,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10678"
      />
      <path
        d="m 121.03,370.16 a 9.032,9.032 0 0 0 -8.58,8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10679"
      />
      <path
        d="M 98.78,359.33 H 97.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10680"
      />
      <path
        d="m 97.29,359.33 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10681"
      />
      <path
        d="m 97.29,359.94 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10682"
      />
      <path
        d="m 97.74,359.94 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10683"
      />
      <path
        d="m 97.74,360.13 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10684"
      />
      <path
        d="m 98.31,360.13 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10685"
      />
      <path
        d="m 98.31,359.94 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10686"
      />
      <path
        d="m 98.74,359.94 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10687"
      />
      <path
        d="m 98.78,359.94 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10688"
      />
      <path
        d="m 98.78,359.94 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10689"
      />
      <path
        d="m 107.75,359.94 v 0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10690"
      />
      <path
        d="M 107.75,360.29 H 98.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10691"
      />
      <path
        d="m 98.77,360.29 0.01,-0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10692"
      />
      <path
        d="M 98.77,369.37 H 97.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10693"
      />
      <path
        d="m 97.24,369.37 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10694"
      />
      <path
        d="m 97.24,368.88 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10695"
      />
      <path
        d="m 97.68,368.88 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10696"
      />
      <path
        d="m 97.68,368.65 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10697"
      />
      <path
        d="m 98.29,368.65 v 0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10698"
      />
      <path
        d="m 98.29,368.86 h 0.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10699"
      />
      <path
        d="m 98.76,368.86 0.01,0.51 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10700"
      />
      <path
        d="m 98.76,368.86 a 9.032,9.032 0 0 0 8.99,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10701"
      />
      <path
        d="m 106.1,336.89 h 1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10702"
      />
      <path
        d="m 107.59,336.89 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10703"
      />
      <path
        d="m 107.59,337.5 h -0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10704"
      />
      <path
        d="m 107.14,337.5 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10705"
      />
      <path
        d="m 107.14,337.69 h -0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10706"
      />
      <path
        d="M 106.57,337.69 V 337.5 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10707"
      />
      <path
        d="m 106.57,337.5 h -0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10708"
      />
      <path
        d="M 106.14,337.5 H 106.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10709"
      />
      <path
        d="m 106.1,337.5 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10710"
      />
      <path
        d="M 106.1,337.5 H 97.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10711"
      />
      <path
        d="m 97.13,337.5 v 0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10712"
      />
      <path
        d="m 97.13,337.85 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10713"
      />
      <path
        d="M 106.1,337.85 V 337.5 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10714"
      />
      <path
        d="m 106.1,346.93 h 1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10715"
      />
      <path
        d="m 107.63,346.93 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10716"
      />
      <path
        d="M 107.63,346.44 H 107.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10717"
      />
      <path
        d="m 107.2,346.44 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10718"
      />
      <path
        d="m 107.2,346.21 h -0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10719"
      />
      <path
        d="m 106.59,346.21 v 0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10720"
      />
      <path
        d="m 106.59,346.42 h -0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10721"
      />
      <path
        d="m 106.11,346.42 -0.01,0.51 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10722"
      />
      <path
        d="m 97.13,337.85 a 9.032,9.032 0 0 0 8.98,8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10723"
      />
      <path
        d="m 106.1,297.65 h 1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10724"
      />
      <path
        d="m 107.59,297.65 v 0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10725"
      />
      <path
        d="m 107.59,298.27 h -0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10726"
      />
      <path
        d="m 107.14,298.27 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10727"
      />
      <path
        d="m 107.14,298.46 h -0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10728"
      />
      <path
        d="m 106.57,298.46 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10729"
      />
      <path
        d="m 106.57,298.27 h -0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10730"
      />
      <path
        d="M 106.14,298.27 H 106.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10731"
      />
      <path
        d="m 106.1,298.27 v -0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10732"
      />
      <path
        d="M 106.1,298.27 H 97.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10733"
      />
      <path
        d="m 97.13,298.27 v 0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10734"
      />
      <path
        d="m 97.13,298.62 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10735"
      />
      <path
        d="m 106.1,298.62 v -0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10736"
      />
      <path
        d="m 106.1,307.69 h 1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10737"
      />
      <path
        d="m 107.63,307.69 v -0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10738"
      />
      <path
        d="M 107.63,307.21 H 107.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10739"
      />
      <path
        d="m 107.2,307.21 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10740"
      />
      <path
        d="m 107.2,306.98 h -0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10741"
      />
      <path
        d="m 106.59,306.98 v 0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10742"
      />
      <path
        d="m 106.59,307.19 h -0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10743"
      />
      <path
        d="m 106.11,307.19 -0.01,0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10744"
      />
      <path
        d="m 97.13,298.62 a 9.032,9.032 0 0 0 8.98,8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10745"
      />
      <path
        d="M 132.68,297.72 H 131.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10746"
      />
      <path
        d="m 131.2,297.72 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10747"
      />
      <path
        d="m 131.2,297.11 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10748"
      />
      <path
        d="m 131.65,297.11 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10749"
      />
      <path
        d="m 131.65,296.92 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10750"
      />
      <path
        d="m 132.22,296.92 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10751"
      />
      <path
        d="m 132.22,297.11 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10752"
      />
      <path
        d="m 132.65,297.11 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10753"
      />
      <path
        d="m 132.69,297.11 -0.01,0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10754"
      />
      <path
        d="m 132.69,297.11 h 8.96"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10755"
      />
      <path
        d="m 141.65,297.11 v -0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10756"
      />
      <path
        d="m 141.65,296.76 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10757"
      />
      <path
        d="m 132.68,296.76 0.01,0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10758"
      />
      <path
        d="m 132.68,287.68 h -1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10759"
      />
      <path
        d="m 131.15,287.68 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10760"
      />
      <path
        d="m 131.15,288.17 h 0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10761"
      />
      <path
        d="m 131.59,288.17 v 0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10762"
      />
      <path
        d="m 131.59,288.4 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10763"
      />
      <path
        d="m 132.2,288.4 v -0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10764"
      />
      <path
        d="m 132.2,288.19 h 0.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10765"
      />
      <path
        d="m 132.67,288.19 0.01,-0.51 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10766"
      />
      <path
        d="m 141.65,296.76 a 9.032,9.032 0 0 0 -8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10767"
      />
      <path
        d="m 105.78,277.88 v -1.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10768"
      />
      <path
        d="m 105.78,276.39 h -0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10769"
      />
      <path
        d="m 105.17,276.39 v 0.45 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10770"
      />
      <path
        d="m 105.17,276.84 h -0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10771"
      />
      <path
        d="m 104.98,276.84 v 0.58 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10772"
      />
      <path
        d="m 104.98,277.42 h 0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10773"
      />
      <path
        d="m 105.17,277.42 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10774"
      />
      <path
        d="m 105.17,277.85 v 0.03 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10775"
      />
      <path
        d="m 105.17,277.88 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10776"
      />
      <path
        d="m 105.17,277.88 v 8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10777"
      />
      <path
        d="m 105.17,286.85 h -0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10778"
      />
      <path
        d="m 104.81,286.85 v -8.97 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10779"
      />
      <path
        d="m 104.81,277.88 h 0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10780"
      />
      <path
        d="m 95.74,277.88 v -1.53 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10781"
      />
      <path
        d="m 95.74,276.35 h 0.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10782"
      />
      <path
        d="m 96.23,276.35 v 0.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10783"
      />
      <path
        d="m 96.23,276.78 h 0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10784"
      />
      <path
        d="m 96.46,276.78 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10785"
      />
      <path
        d="M 96.46,277.39 H 96.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10786"
      />
      <path
        d="m 96.24,277.39 v 0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10787"
      />
      <path
        d="m 96.24,277.87 -0.5,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10788"
      />
      <path
        d="m 96.24,277.87 a 9.032,9.032 0 0 0 8.57,8.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10789"
      />
      <path
        d="m 123.88,240.37 h -1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10790"
      />
      <path
        d="m 122.39,240.37 v 0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10791"
      />
      <path
        d="m 122.39,240.99 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10792"
      />
      <path
        d="m 122.84,240.99 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10793"
      />
      <path
        d="m 122.84,241.18 h 0.58"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10794"
      />
      <path
        d="m 123.42,241.18 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10795"
      />
      <path
        d="m 123.42,240.99 h 0.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10796"
      />
      <path
        d="m 123.84,240.99 h 0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10797"
      />
      <path
        d="m 123.88,240.99 v -0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10798"
      />
      <path
        d="m 123.88,240.99 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10799"
      />
      <path
        d="m 132.85,240.99 v 0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10800"
      />
      <path
        d="m 132.85,241.34 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10801"
      />
      <path
        d="m 123.88,241.34 v -0.35 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10802"
      />
      <path
        d="m 123.88,250.41 h -1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10803"
      />
      <path
        d="m 122.35,250.41 v -0.48 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10804"
      />
      <path
        d="m 122.35,249.93 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10805"
      />
      <path
        d="M 122.78,249.93 V 249.7 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10806"
      />
      <path
        d="m 122.78,249.7 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10807"
      />
      <path
        d="m 123.39,249.7 v 0.21 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10808"
      />
      <path
        d="m 123.39,249.91 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10809"
      />
      <path
        d="m 123.87,249.91 0.01,0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10810"
      />
      <path
        d="m 123.87,249.91 a 9.032,9.032 0 0 0 8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10811"
      />
      <path
        d="m 122.38,238.74 0.18,1.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10812"
      />
      <path
        d="m 107.77,239.96 h -1.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10813"
      />
      <path
        d="m 106.29,239.96 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10814"
      />
      <path
        d="m 106.29,240.57 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10815"
      />
      <path
        d="m 106.74,240.57 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10816"
      />
      <path
        d="m 106.74,240.76 h 0.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10817"
      />
      <path
        d="m 107.31,240.76 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10818"
      />
      <path
        d="m 107.31,240.57 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10819"
      />
      <path
        d="m 107.74,240.57 h 0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10820"
      />
      <path
        d="m 107.77,240.57 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10821"
      />
      <path
        d="m 107.77,240.57 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10822"
      />
      <path
        d="m 116.74,240.57 v 0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10823"
      />
      <path
        d="m 116.74,240.93 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10824"
      />
      <path
        d="m 107.77,240.93 v -0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10825"
      />
      <path
        d="m 107.77,250 h -1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10826"
      />
      <path
        d="m 106.24,250 v -0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10827"
      />
      <path
        d="m 106.24,249.51 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10828"
      />
      <path
        d="m 106.67,249.51 v -0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10829"
      />
      <path
        d="m 106.67,249.28 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10830"
      />
      <path
        d="m 107.28,249.28 v 0.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10831"
      />
      <path
        d="m 107.28,249.5 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10832"
      />
      <path
        d="m 107.76,249.5 0.01,0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10833"
      />
      <path
        d="m 107.76,249.5 a 9.032,9.032 0 0 0 8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10834"
      />
      <path
        d="m 107.77,258.98 h -1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10835"
      />
      <path
        d="m 106.28,258.98 v -0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10836"
      />
      <path
        d="m 106.28,258.37 h 0.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10837"
      />
      <path
        d="m 106.73,258.37 v -0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10838"
      />
      <path
        d="m 106.73,258.18 h 0.58"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10839"
      />
      <path
        d="m 107.31,258.18 v 0.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10840"
      />
      <path
        d="m 107.31,258.37 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10841"
      />
      <path
        d="m 107.74,258.37 h 0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10842"
      />
      <path
        d="m 107.77,258.37 v 0.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10843"
      />
      <path
        d="m 107.77,258.37 h 8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10844"
      />
      <path
        d="m 116.74,258.37 v -0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10845"
      />
      <path
        d="m 116.74,258.01 h -8.97"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10846"
      />
      <path
        d="m 107.77,258.01 v 0.36 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10847"
      />
      <path
        d="m 107.77,248.94 h -1.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10848"
      />
      <path
        d="m 106.24,248.94 v 0.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10849"
      />
      <path
        d="m 106.24,249.43 h 0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10850"
      />
      <path
        d="m 106.67,249.43 v 0.23 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10851"
      />
      <path
        d="m 106.67,249.66 h 0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10852"
      />
      <path
        d="m 107.28,249.66 v -0.22 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10853"
      />
      <path
        d="m 107.28,249.44 h 0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10854"
      />
      <path
        d="m 107.76,249.44 0.01,-0.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10855"
      />
      <path
        d="m 116.74,258.01 a 9.032,9.032 0 0 0 -8.98,-8.57"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10856"
      />
      <path
        d="m 70.35,197.53 -0.07,48.45"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10857"
      />
      <path
        d="m 92.27,235.63 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10858"
      />
      <path
        d="m 96.01,235.63 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10859"
      />
      <path
        d="M 96.01,231.89 H 92.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10860"
      />
      <path
        d="m 92.27,231.89 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10861"
      />
      <path
        d="m 92.62,234.4 v -1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10862"
      />
      <path
        d="M 92.62,232.56 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10863"
      />
      <path
        d="m 92.62,232.56 0.03,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10864"
      />
      <path
        d="m 92.65,232.43 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10865"
      />
      <path
        d="m 92.74,232.34 0.13,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10866"
      />
      <path
        d="m 92.87,232.31 h 2.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10867"
      />
      <path
        d="M 95.36,232.31 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10868"
      />
      <path
        d="m 95.36,232.31 0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10869"
      />
      <path
        d="m 95.48,232.34 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10870"
      />
      <path
        d="m 95.57,232.43 0.04,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10871"
      />
      <path
        d="m 95.61,232.56 v 1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10872"
      />
      <path
        d="M 95.61,234.4 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10873"
      />
      <path
        d="m 95.61,234.4 -0.04,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10874"
      />
      <path
        d="m 95.57,234.52 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10875"
      />
      <path
        d="m 95.48,234.61 -0.12,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10876"
      />
      <path
        d="M 95.36,234.65 H 92.87"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10877"
      />
      <path
        d="M 92.87,234.65 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10878"
      />
      <path
        d="m 92.87,234.65 -0.13,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10879"
      />
      <path
        d="m 92.74,234.61 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10880"
      />
      <path
        d="M 92.65,234.52 92.62,234.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10881"
      />
      <path
        d="m 94.09,235.05 v -1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10882"
      />
      <path
        d="M 93.71,235.01 H 92.65"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10883"
      />
      <path
        d="m 94.53,235.01 h 1.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10884"
      />
      <path
        d="m 94.15,233.29 0.16,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10885"
      />
      <path
        d="m 94.31,233.35 0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10886"
      />
      <path
        d="m 94.38,233.52 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10887"
      />
      <path
        d="m 94.31,233.68 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10888"
      />
      <path
        d="m 94.15,233.75 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10889"
      />
      <path
        d="m 93.99,233.68 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10890"
      />
      <path
        d="m 93.92,233.52 0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10891"
      />
      <path
        d="m 93.99,233.35 0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10892"
      />
      <path
        d="m 171.2,243.33 v 3.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10893"
      />
      <path
        d="m 171.2,247.06 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10894"
      />
      <path
        d="m 174.94,247.06 v -3.73 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10895"
      />
      <path
        d="M 174.94,243.33 H 171.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10896"
      />
      <path
        d="m 172.42,243.67 h 1.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10897"
      />
      <path
        d="M 174.27,243.67 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10898"
      />
      <path
        d="m 174.27,243.67 0.12,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10899"
      />
      <path
        d="m 174.39,243.71 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10900"
      />
      <path
        d="m 174.48,243.8 0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10901"
      />
      <path
        d="m 174.51,243.92 v 2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10902"
      />
      <path
        d="M 174.51,246.41 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10903"
      />
      <path
        d="m 174.51,246.41 -0.03,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10904"
      />
      <path
        d="m 174.48,246.54 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10905"
      />
      <path
        d="m 174.39,246.63 -0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10906"
      />
      <path
        d="m 174.27,246.66 h -1.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10907"
      />
      <path
        d="M 172.42,246.66 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10908"
      />
      <path
        d="m 172.42,246.66 -0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10909"
      />
      <path
        d="m 172.3,246.63 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10910"
      />
      <path
        d="m 172.21,246.54 -0.03,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10911"
      />
      <path
        d="m 172.18,246.41 v -2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10912"
      />
      <path
        d="M 172.18,243.92 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10913"
      />
      <path
        d="m 172.18,243.92 0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10914"
      />
      <path
        d="m 172.21,243.8 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10915"
      />
      <path
        d="m 172.3,243.71 0.12,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10916"
      />
      <path
        d="m 171.77,245.15 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10917"
      />
      <path
        d="m 171.81,244.76 v -1.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10918"
      />
      <path
        d="m 171.81,245.59 v 1.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10919"
      />
      <path
        d="m 173.54,245.21 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10920"
      />
      <path
        d="m 173.47,245.37 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10921"
      />
      <path
        d="m 173.31,245.44 -0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10922"
      />
      <path
        d="m 173.14,245.37 -0.06,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10923"
      />
      <path
        d="m 173.08,245.21 0.06,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10924"
      />
      <path
        d="m 173.14,245.04 0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10925"
      />
      <path
        d="m 173.31,244.97 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10926"
      />
      <path
        d="m 173.47,245.04 0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10927"
      />
      <path
        d="m 199.3,243.51 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10928"
      />
      <path
        d="m 199.3,247.25 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10929"
      />
      <path
        d="m 203.04,247.25 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10930"
      />
      <path
        d="M 203.04,243.51 H 199.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10931"
      />
      <path
        d="m 200.52,243.86 h 1.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10932"
      />
      <path
        d="M 202.37,243.86 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10933"
      />
      <path
        d="m 202.37,243.86 0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10934"
      />
      <path
        d="m 202.49,243.89 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10935"
      />
      <path
        d="m 202.58,243.98 0.03,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10936"
      />
      <path
        d="m 202.61,244.11 v 2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10937"
      />
      <path
        d="M 202.61,246.6 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10938"
      />
      <path
        d="m 202.61,246.6 -0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10939"
      />
      <path
        d="m 202.58,246.72 -0.09,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10940"
      />
      <path
        d="m 202.49,246.82 -0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10941"
      />
      <path
        d="m 202.37,246.85 h -1.85"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10942"
      />
      <path
        d="M 200.52,246.85 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10943"
      />
      <path
        d="m 200.52,246.85 -0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10944"
      />
      <path
        d="m 200.4,246.82 -0.09,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10945"
      />
      <path
        d="m 200.31,246.72 -0.04,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10946"
      />
      <path
        d="m 200.27,246.6 v -2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10947"
      />
      <path
        d="M 200.27,244.11 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10948"
      />
      <path
        d="m 200.27,244.11 0.04,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10949"
      />
      <path
        d="m 200.31,243.98 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10950"
      />
      <path
        d="m 200.4,243.89 0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10951"
      />
      <path
        d="m 199.87,245.34 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10952"
      />
      <path
        d="M 199.91,244.95 V 243.9 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10953"
      />
      <path
        d="m 199.91,245.78 v 1.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10954"
      />
      <path
        d="m 201.64,245.39 -0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10955"
      />
      <path
        d="m 201.57,245.56 -0.16,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10956"
      />
      <path
        d="m 201.41,245.62 -0.17,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10957"
      />
      <path
        d="m 201.24,245.56 -0.06,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10958"
      />
      <path
        d="m 201.18,245.39 0.06,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10959"
      />
      <path
        d="m 201.24,245.23 0.17,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10960"
      />
      <path
        d="m 201.41,245.16 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10961"
      />
      <path
        d="m 201.57,245.23 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10962"
      />
      <path
        d="m 227.31,243.59 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10963"
      />
      <path
        d="m 227.31,247.33 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10964"
      />
      <path
        d="m 231.05,247.33 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10965"
      />
      <path
        d="m 231.05,243.59 h -3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10966"
      />
      <path
        d="m 228.54,243.94 h 1.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10967"
      />
      <path
        d="M 230.38,243.94 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10968"
      />
      <path
        d="m 230.38,243.94 0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10969"
      />
      <path
        d="m 230.5,243.97 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10970"
      />
      <path
        d="m 230.59,244.06 0.04,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10971"
      />
      <path
        d="m 230.63,244.18 v 2.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10972"
      />
      <path
        d="M 230.63,246.68 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10973"
      />
      <path
        d="m 230.63,246.68 -0.04,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10974"
      />
      <path
        d="m 230.59,246.8 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10975"
      />
      <path
        d="m 230.5,246.89 -0.12,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10976"
      />
      <path
        d="m 230.38,246.93 h -1.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10977"
      />
      <path
        d="M 228.54,246.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10978"
      />
      <path
        d="m 228.54,246.93 -0.13,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10979"
      />
      <path
        d="m 228.41,246.89 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10980"
      />
      <path
        d="m 228.32,246.8 -0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10981"
      />
      <path
        d="m 228.29,246.68 v -2.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10982"
      />
      <path
        d="M 228.29,244.18 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10983"
      />
      <path
        d="m 228.29,244.18 0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10984"
      />
      <path
        d="m 228.32,244.06 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10985"
      />
      <path
        d="m 228.41,243.97 0.13,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10986"
      />
      <path
        d="m 227.88,245.41 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10987"
      />
      <path
        d="m 227.92,245.03 v -1.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10988"
      />
      <path
        d="m 227.92,245.85 v 1.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10989"
      />
      <path
        d="m 229.65,245.47 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10990"
      />
      <path
        d="m 229.58,245.63 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10991"
      />
      <path
        d="m 229.42,245.7 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10992"
      />
      <path
        d="m 229.26,245.63 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10993"
      />
      <path
        d="m 229.19,245.47 0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10994"
      />
      <path
        d="m 229.26,245.31 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10995"
      />
      <path
        d="m 229.42,245.24 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10996"
      />
      <path
        d="m 229.58,245.31 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10997"
      />
      <path
        d="m 255.42,243.69 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10998"
      />
      <path
        d="m 255.42,247.43 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path10999"
      />
      <path
        d="m 259.16,247.43 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11000"
      />
      <path
        d="m 259.16,243.69 h -3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11001"
      />
      <path
        d="m 256.65,244.04 h 1.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11002"
      />
      <path
        d="M 258.49,244.04 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11003"
      />
      <path
        d="m 258.49,244.04 0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11004"
      />
      <path
        d="m 258.61,244.07 0.1,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11005"
      />
      <path
        d="m 258.71,244.16 0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11006"
      />
      <path
        d="m 258.74,244.28 v 2.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11007"
      />
      <path
        d="M 258.74,246.78 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11008"
      />
      <path
        d="m 258.74,246.78 -0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11009"
      />
      <path
        d="m 258.71,246.9 -0.1,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11010"
      />
      <path
        d="m 258.61,246.99 -0.12,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11011"
      />
      <path
        d="m 258.49,247.03 h -1.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11012"
      />
      <path
        d="M 256.65,247.03 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11013"
      />
      <path
        d="m 256.65,247.03 -0.13,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11014"
      />
      <path
        d="m 256.52,246.99 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11015"
      />
      <path
        d="m 256.43,246.9 -0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11016"
      />
      <path
        d="m 256.4,246.78 v -2.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11017"
      />
      <path
        d="M 256.4,244.28 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11018"
      />
      <path
        d="m 256.4,244.28 0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11019"
      />
      <path
        d="m 256.43,244.16 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11020"
      />
      <path
        d="m 256.52,244.07 0.13,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11021"
      />
      <path
        d="m 256,245.51 h 1.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11022"
      />
      <path
        d="m 256.03,245.13 v -1.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11023"
      />
      <path
        d="m 256.03,245.95 v 1.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11024"
      />
      <path
        d="m 257.76,245.57 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11025"
      />
      <path
        d="m 257.69,245.73 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11026"
      />
      <path
        d="m 257.53,245.8 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11027"
      />
      <path
        d="m 257.37,245.73 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11028"
      />
      <path
        d="m 257.3,245.57 0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11029"
      />
      <path
        d="m 257.37,245.41 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11030"
      />
      <path
        d="m 257.53,245.34 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11031"
      />
      <path
        d="m 257.69,245.41 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11032"
      />
      <path
        d="m 284.64,248.15 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11033"
      />
      <path
        d="m 288.38,248.15 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11034"
      />
      <path
        d="m 288.38,244.41 h -3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11035"
      />
      <path
        d="m 284.64,244.41 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11036"
      />
      <path
        d="m 284.99,246.93 v -1.85 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11037"
      />
      <path
        d="M 284.99,245.08 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11038"
      />
      <path
        d="m 284.99,245.08 0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11039"
      />
      <path
        d="m 285.02,244.96 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11040"
      />
      <path
        d="m 285.11,244.87 0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11041"
      />
      <path
        d="m 285.23,244.84 h 2.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11042"
      />
      <path
        d="M 287.73,244.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11043"
      />
      <path
        d="m 287.73,244.84 0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11044"
      />
      <path
        d="m 287.85,244.87 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11045"
      />
      <path
        d="m 287.94,244.96 0.04,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11046"
      />
      <path
        d="m 287.98,245.08 v 1.85 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11047"
      />
      <path
        d="M 287.98,246.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11048"
      />
      <path
        d="m 287.98,246.93 -0.04,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11049"
      />
      <path
        d="m 287.94,247.05 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11050"
      />
      <path
        d="m 287.85,247.14 -0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11051"
      />
      <path
        d="m 287.73,247.17 h -2.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11052"
      />
      <path
        d="M 285.23,247.17 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11053"
      />
      <path
        d="m 285.23,247.17 -0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11054"
      />
      <path
        d="m 285.11,247.14 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11055"
      />
      <path
        d="m 285.02,247.05 -0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11056"
      />
      <path
        d="m 286.46,247.58 v -1.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11057"
      />
      <path
        d="m 286.08,247.54 h -1.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11058"
      />
      <path
        d="m 286.9,247.54 h 1.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11059"
      />
      <path
        d="m 286.52,245.81 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11060"
      />
      <path
        d="m 286.68,245.88 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11061"
      />
      <path
        d="m 286.75,246.04 -0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11062"
      />
      <path
        d="m 286.68,246.21 -0.16,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11063"
      />
      <path
        d="m 286.52,246.27 -0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11064"
      />
      <path
        d="m 286.36,246.21 -0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11065"
      />
      <path
        d="m 286.29,246.04 0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11066"
      />
      <path
        d="m 286.36,245.88 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11067"
      />
      <path
        d="m 302.89,169.39 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11068"
      />
      <path
        d="m 302.89,165.65 h -3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11069"
      />
      <path
        d="m 299.15,165.65 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11070"
      />
      <path
        d="m 299.15,169.39 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11071"
      />
      <path
        d="m 301.66,169.04 h -1.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11072"
      />
      <path
        d="M 299.82,169.04 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11073"
      />
      <path
        d="m 299.82,169.04 -0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11074"
      />
      <path
        d="m 299.7,169.01 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11075"
      />
      <path
        d="m 299.61,168.92 -0.04,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11076"
      />
      <path
        d="m 299.57,168.8 v -2.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11077"
      />
      <path
        d="M 299.57,166.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11078"
      />
      <path
        d="m 299.57,166.3 0.04,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11079"
      />
      <path
        d="m 299.61,166.18 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11080"
      />
      <path
        d="m 299.7,166.09 0.12,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11081"
      />
      <path
        d="m 299.82,166.05 h 1.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11082"
      />
      <path
        d="M 301.66,166.05 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11083"
      />
      <path
        d="m 301.66,166.05 0.13,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11084"
      />
      <path
        d="m 301.79,166.09 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11085"
      />
      <path
        d="m 301.88,166.18 0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11086"
      />
      <path
        d="m 301.91,166.3 v 2.5 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11087"
      />
      <path
        d="M 301.91,168.8 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11088"
      />
      <path
        d="m 301.91,168.8 -0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11089"
      />
      <path
        d="m 301.88,168.92 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11090"
      />
      <path
        d="m 301.79,169.01 -0.13,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11091"
      />
      <path
        d="m 302.32,167.57 h -1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11092"
      />
      <path
        d="m 302.28,167.95 v 1.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11093"
      />
      <path
        d="m 302.28,167.13 v -1.06 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11094"
      />
      <path
        d="m 300.55,167.51 0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11095"
      />
      <path
        d="m 300.62,167.35 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11096"
      />
      <path
        d="m 300.78,167.28 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11097"
      />
      <path
        d="m 300.94,167.35 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11098"
      />
      <path
        d="m 301.01,167.51 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11099"
      />
      <path
        d="m 300.94,167.67 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11100"
      />
      <path
        d="m 300.78,167.74 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11101"
      />
      <path
        d="m 300.62,167.67 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11102"
      />
      <path
        d="m 274.71,185.39 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11103"
      />
      <path
        d="m 274.71,181.65 h -3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11104"
      />
      <path
        d="m 270.97,181.65 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11105"
      />
      <path
        d="m 270.97,185.39 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11106"
      />
      <path
        d="m 273.48,185.04 h -1.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11107"
      />
      <path
        d="M 271.64,185.04 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11108"
      />
      <path
        d="m 271.64,185.04 -0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11109"
      />
      <path
        d="m 271.52,185.01 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11110"
      />
      <path
        d="m 271.43,184.92 -0.04,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11111"
      />
      <path
        d="M 271.39,184.79 V 182.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11112"
      />
      <path
        d="M 271.39,182.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11113"
      />
      <path
        d="m 271.39,182.3 0.04,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11114"
      />
      <path
        d="m 271.43,182.18 0.09,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11115"
      />
      <path
        d="m 271.52,182.08 0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11116"
      />
      <path
        d="m 271.64,182.05 h 1.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11117"
      />
      <path
        d="M 273.48,182.05 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11118"
      />
      <path
        d="m 273.48,182.05 0.13,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11119"
      />
      <path
        d="m 273.61,182.08 0.09,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11120"
      />
      <path
        d="m 273.7,182.18 0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11121"
      />
      <path
        d="m 273.73,182.3 v 2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11122"
      />
      <path
        d="M 273.73,184.79 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11123"
      />
      <path
        d="m 273.73,184.79 -0.03,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11124"
      />
      <path
        d="m 273.7,184.92 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11125"
      />
      <path
        d="m 273.61,185.01 -0.13,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11126"
      />
      <path
        d="m 274.14,183.56 h -1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11127"
      />
      <path
        d="M 274.1,183.95 V 185 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11128"
      />
      <path
        d="m 274.1,183.12 v -1.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11129"
      />
      <path
        d="m 272.37,183.51 0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11130"
      />
      <path
        d="m 272.44,183.34 0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11131"
      />
      <path
        d="m 272.6,183.28 0.17,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11132"
      />
      <path
        d="m 272.77,183.34 0.06,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11133"
      />
      <path
        d="m 272.83,183.51 -0.06,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11134"
      />
      <path
        d="m 272.77,183.67 -0.17,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11135"
      />
      <path
        d="m 272.6,183.74 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11136"
      />
      <path
        d="m 272.44,183.67 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11137"
      />
      <path
        d="m 261.81,286.24 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11138"
      />
      <path
        d="M 265.55,286.24 V 282.5 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11139"
      />
      <path
        d="m 265.55,282.5 h -3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11140"
      />
      <path
        d="m 261.81,282.5 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11141"
      />
      <path
        d="m 262.15,285.01 v -1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11142"
      />
      <path
        d="M 262.15,283.17 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11143"
      />
      <path
        d="m 262.15,283.17 0.04,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11144"
      />
      <path
        d="m 262.19,283.04 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11145"
      />
      <path
        d="m 262.28,282.95 0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11146"
      />
      <path
        d="m 262.4,282.92 h 2.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11147"
      />
      <path
        d="M 264.9,282.92 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11148"
      />
      <path
        d="m 264.9,282.92 0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11149"
      />
      <path
        d="m 265.02,282.95 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11150"
      />
      <path
        d="m 265.11,283.04 0.03,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11151"
      />
      <path
        d="m 265.14,283.17 v 1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11152"
      />
      <path
        d="M 265.14,285.01 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11153"
      />
      <path
        d="m 265.14,285.01 -0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11154"
      />
      <path
        d="m 265.11,285.13 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11155"
      />
      <path
        d="m 265.02,285.22 -0.12,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11156"
      />
      <path
        d="m 264.9,285.26 h -2.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11157"
      />
      <path
        d="M 262.4,285.26 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11158"
      />
      <path
        d="m 262.4,285.26 -0.12,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11159"
      />
      <path
        d="m 262.28,285.22 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11160"
      />
      <path
        d="m 262.19,285.13 -0.04,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11161"
      />
      <path
        d="m 263.63,285.66 v -1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11162"
      />
      <path
        d="m 263.25,285.62 h -1.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11163"
      />
      <path
        d="m 264.07,285.62 h 1.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11164"
      />
      <path
        d="m 263.69,283.9 0.16,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11165"
      />
      <path
        d="m 263.85,283.96 0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11166"
      />
      <path
        d="m 263.92,284.13 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11167"
      />
      <path
        d="m 263.85,284.29 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11168"
      />
      <path
        d="m 263.69,284.36 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11169"
      />
      <path
        d="m 263.53,284.29 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11170"
      />
      <path
        d="m 263.46,284.13 0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11171"
      />
      <path
        d="m 263.53,283.96 0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11172"
      />
      <path
        d="m 265.57,288.96 h -3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11173"
      />
      <path
        d="m 261.83,288.96 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11174"
      />
      <path
        d="m 261.83,292.7 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11175"
      />
      <path
        d="m 265.57,292.7 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11176"
      />
      <path
        d="m 265.22,290.19 v 1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11177"
      />
      <path
        d="M 265.22,292.03 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11178"
      />
      <path
        d="m 265.22,292.03 -0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11179"
      />
      <path
        d="m 265.19,292.15 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11180"
      />
      <path
        d="m 265.1,292.24 -0.13,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11181"
      />
      <path
        d="m 264.97,292.28 h -2.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11182"
      />
      <path
        d="M 262.48,292.28 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11183"
      />
      <path
        d="m 262.48,292.28 -0.12,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11184"
      />
      <path
        d="m 262.36,292.24 -0.1,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11185"
      />
      <path
        d="m 262.26,292.15 -0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11186"
      />
      <path
        d="m 262.23,292.03 v -1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11187"
      />
      <path
        d="M 262.23,290.19 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11188"
      />
      <path
        d="m 262.23,290.19 0.03,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11189"
      />
      <path
        d="m 262.26,290.06 0.1,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11190"
      />
      <path
        d="m 262.36,289.97 0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11191"
      />
      <path
        d="m 262.48,289.94 h 2.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11192"
      />
      <path
        d="M 264.97,289.94 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11193"
      />
      <path
        d="m 264.97,289.94 0.13,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11194"
      />
      <path
        d="m 265.1,289.97 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11195"
      />
      <path
        d="m 265.19,290.06 0.03,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11196"
      />
      <path
        d="m 263.75,289.53 v 1.1 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11197"
      />
      <path
        d="m 264.13,289.57 h 1.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11198"
      />
      <path
        d="m 263.3,289.57 h -1.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11199"
      />
      <path
        d="m 263.69,291.3 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11200"
      />
      <path
        d="m 263.53,291.23 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11201"
      />
      <path
        d="m 263.46,291.07 0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11202"
      />
      <path
        d="m 263.53,290.91 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11203"
      />
      <path
        d="m 263.69,290.84 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11204"
      />
      <path
        d="m 263.85,290.91 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11205"
      />
      <path
        d="m 263.92,291.07 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11206"
      />
      <path
        d="m 263.85,291.23 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11207"
      />
      <path
        d="M 94.95,381.77 H 91.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11208"
      />
      <path
        d="m 91.21,381.77 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11209"
      />
      <path
        d="m 91.21,385.51 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11210"
      />
      <path
        d="m 94.95,385.51 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11211"
      />
      <path
        d="m 94.6,383 v 1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11212"
      />
      <path
        d="M 94.6,384.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11213"
      />
      <path
        d="m 94.6,384.84 -0.03,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11214"
      />
      <path
        d="m 94.57,384.97 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11215"
      />
      <path
        d="m 94.48,385.06 -0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11216"
      />
      <path
        d="m 94.36,385.09 h -2.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11217"
      />
      <path
        d="M 91.86,385.09 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11218"
      />
      <path
        d="m 91.86,385.09 -0.12,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11219"
      />
      <path
        d="m 91.74,385.06 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11220"
      />
      <path
        d="m 91.65,384.97 -0.04,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11221"
      />
      <path
        d="M 91.61,384.84 V 383 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11222"
      />
      <path
        d="M 91.61,383 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11223"
      />
      <path
        d="m 91.61,383 0.04,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11224"
      />
      <path
        d="m 91.65,382.88 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11225"
      />
      <path
        d="m 91.74,382.79 0.12,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11226"
      />
      <path
        d="m 91.86,382.75 h 2.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11227"
      />
      <path
        d="M 94.36,382.75 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11228"
      />
      <path
        d="m 94.36,382.75 0.12,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11229"
      />
      <path
        d="m 94.48,382.79 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11230"
      />
      <path
        d="M 94.57,382.88 94.6,383"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11231"
      />
      <path
        d="m 93.13,382.35 v 1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11232"
      />
      <path
        d="m 93.51,382.39 h 1.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11233"
      />
      <path
        d="M 92.69,382.39 H 91.63"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11234"
      />
      <path
        d="m 93.07,384.11 -0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11235"
      />
      <path
        d="m 92.91,384.05 -0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11236"
      />
      <path
        d="m 92.84,383.88 0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11237"
      />
      <path
        d="m 92.91,383.72 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11238"
      />
      <path
        d="m 93.07,383.65 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11239"
      />
      <path
        d="m 93.23,383.72 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11240"
      />
      <path
        d="m 93.3,383.88 -0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11241"
      />
      <path
        d="m 93.23,384.05 -0.16,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11242"
      />
      <path
        d="m 92.02,378.2 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11243"
      />
      <path
        d="m 95.76,378.2 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11244"
      />
      <path
        d="M 95.76,374.46 H 92.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11245"
      />
      <path
        d="m 92.02,374.46 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11246"
      />
      <path
        d="m 92.37,376.97 v -1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11247"
      />
      <path
        d="M 92.37,375.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11248"
      />
      <path
        d="m 92.37,375.13 0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11249"
      />
      <path
        d="m 92.4,375.01 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11250"
      />
      <path
        d="m 92.49,374.92 0.13,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11251"
      />
      <path
        d="m 92.62,374.88 h 2.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11252"
      />
      <path
        d="M 95.11,374.88 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11253"
      />
      <path
        d="m 95.11,374.88 0.12,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11254"
      />
      <path
        d="m 95.23,374.92 0.1,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11255"
      />
      <path
        d="m 95.33,375.01 0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11256"
      />
      <path
        d="m 95.36,375.13 v 1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11257"
      />
      <path
        d="M 95.36,376.97 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11258"
      />
      <path
        d="m 95.36,376.97 -0.03,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11259"
      />
      <path
        d="m 95.33,377.1 -0.1,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11260"
      />
      <path
        d="m 95.23,377.19 -0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11261"
      />
      <path
        d="M 95.11,377.22 H 92.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11262"
      />
      <path
        d="M 92.62,377.22 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11263"
      />
      <path
        d="m 92.62,377.22 -0.13,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11264"
      />
      <path
        d="M 92.49,377.19 92.4,377.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11265"
      />
      <path
        d="m 92.4,377.1 -0.03,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11266"
      />
      <path
        d="m 93.84,377.62 v -1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11267"
      />
      <path
        d="M 93.46,377.59 H 92.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11268"
      />
      <path
        d="m 94.29,377.59 h 1.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11269"
      />
      <path
        d="m 93.9,375.86 0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11270"
      />
      <path
        d="m 94.06,375.93 0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11271"
      />
      <path
        d="m 94.13,376.09 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11272"
      />
      <path
        d="m 94.06,376.25 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11273"
      />
      <path
        d="m 93.9,376.32 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11274"
      />
      <path
        d="m 93.74,376.25 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11275"
      />
      <path
        d="m 93.67,376.09 0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11276"
      />
      <path
        d="m 93.74,375.93 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11277"
      />
      <path
        d="m 162.85,297.53 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11278"
      />
      <path
        d="m 166.59,297.53 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11279"
      />
      <path
        d="m 166.59,293.79 h -3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11280"
      />
      <path
        d="m 162.85,293.79 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11281"
      />
      <path
        d="m 163.2,296.3 v -1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11282"
      />
      <path
        d="M 163.2,294.46 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11283"
      />
      <path
        d="m 163.2,294.46 0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11284"
      />
      <path
        d="m 163.23,294.34 0.09,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11285"
      />
      <path
        d="m 163.32,294.24 0.13,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11286"
      />
      <path
        d="m 163.45,294.21 h 2.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11287"
      />
      <path
        d="M 165.94,294.21 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11288"
      />
      <path
        d="m 165.94,294.21 0.13,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11289"
      />
      <path
        d="m 166.07,294.24 0.09,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11290"
      />
      <path
        d="m 166.16,294.34 0.03,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11291"
      />
      <path
        d="m 166.19,294.46 v 1.84 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11292"
      />
      <path
        d="M 166.19,296.3 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11293"
      />
      <path
        d="m 166.19,296.3 -0.03,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11294"
      />
      <path
        d="m 166.16,296.43 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11295"
      />
      <path
        d="m 166.07,296.52 -0.13,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11296"
      />
      <path
        d="m 165.94,296.55 h -2.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11297"
      />
      <path
        d="M 163.45,296.55 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11298"
      />
      <path
        d="m 163.45,296.55 -0.13,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11299"
      />
      <path
        d="m 163.32,296.52 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11300"
      />
      <path
        d="M 163.23,296.43 163.2,296.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11301"
      />
      <path
        d="m 164.68,296.95 v -1.09 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11302"
      />
      <path
        d="m 164.29,296.92 h -1.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11303"
      />
      <path
        d="m 165.12,296.92 h 1.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11304"
      />
      <path
        d="m 164.73,295.19 0.17,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11305"
      />
      <path
        d="m 164.9,295.26 0.06,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11306"
      />
      <path
        d="m 164.96,295.42 -0.06,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11307"
      />
      <path
        d="m 164.9,295.58 -0.17,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11308"
      />
      <path
        d="m 164.73,295.65 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11309"
      />
      <path
        d="m 164.57,295.58 -0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11310"
      />
      <path
        d="m 164.5,295.42 0.07,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11311"
      />
      <path
        d="m 164.57,295.26 0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11312"
      />
      <path
        d="m 297.75,163.83 v 13.83 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11313"
      />
      <path
        d="m 297.75,177.66 h 6.59"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11314"
      />
      <path
        d="m 300.76,163.83 v 1.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11315"
      />
      <path
        d="m 300.76,165.95 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11316"
      />
      <path
        d="m 300.76,168.01 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11317"
      />
      <path
        d="m 300.76,170.06 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11318"
      />
      <path
        d="m 300.76,172.12 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11319"
      />
      <path
        d="m 300.76,174.18 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11320"
      />
      <path
        d="m 300.76,176.23 v 1.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11321"
      />
      <path
        d="m 254.06,235.03 h 6.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11322"
      />
      <path
        d="m 260.28,235.03 v 14.14 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11323"
      />
      <path
        d="m 257.82,235.03 v 1.58 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11324"
      />
      <path
        d="m 257.82,237.3 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11325"
      />
      <path
        d="m 257.82,239.35 v 1.38 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11326"
      />
      <path
        d="m 257.82,241.41 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11327"
      />
      <path
        d="m 257.82,243.47 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11328"
      />
      <path
        d="m 257.82,245.53 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11329"
      />
      <path
        d="m 257.82,247.58 v 1.59 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11330"
      />
      <path
        d="m 225.95,235.03 h 6.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11331"
      />
      <path
        d="m 232.16,235.03 v 14.14 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11332"
      />
      <path
        d="m 229.7,235.03 v 1.58 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11333"
      />
      <path
        d="m 229.7,237.3 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11334"
      />
      <path
        d="m 229.7,239.35 v 1.38 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11335"
      />
      <path
        d="m 229.7,241.41 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11336"
      />
      <path
        d="m 229.7,243.47 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11337"
      />
      <path
        d="m 229.7,245.53 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11338"
      />
      <path
        d="m 229.7,247.58 v 1.59 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11339"
      />
      <path
        d="m 197.73,235.03 h 6.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11340"
      />
      <path
        d="m 203.94,235.03 v 14.14 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11341"
      />
      <path
        d="m 201.49,235.03 v 1.58 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11342"
      />
      <path
        d="m 201.49,237.3 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11343"
      />
      <path
        d="m 201.49,239.35 v 1.38 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11344"
      />
      <path
        d="m 201.49,241.41 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11345"
      />
      <path
        d="m 201.49,243.47 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11346"
      />
      <path
        d="m 201.49,245.53 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11347"
      />
      <path
        d="m 201.49,247.58 v 1.59 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11348"
      />
      <path
        d="m 169.46,235.03 h 6.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11349"
      />
      <path
        d="m 175.67,235.03 v 14.14 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11350"
      />
      <path
        d="m 173.22,235.03 v 1.58 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11351"
      />
      <path
        d="m 173.22,237.3 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11352"
      />
      <path
        d="m 173.22,239.35 v 1.38 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11353"
      />
      <path
        d="m 173.22,241.41 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11354"
      />
      <path
        d="m 173.22,243.47 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11355"
      />
      <path
        d="m 173.22,245.53 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11356"
      />
      <path
        d="m 173.22,247.58 v 1.59 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11357"
      />
      <path
        d="M 267.02,280.89 H 253.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11358"
      />
      <path
        d="m 253.07,280.89 v 6.24 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11359"
      />
      <path
        d="m 253.07,283.67 h 1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11360"
      />
      <path
        d="m 255.25,283.67 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11361"
      />
      <path
        d="m 257.3,283.67 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11362"
      />
      <path
        d="m 259.36,283.67 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11363"
      />
      <path
        d="m 261.42,283.67 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11364"
      />
      <path
        d="m 263.47,283.67 h 1.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11365"
      />
      <path
        d="m 265.53,283.67 h 1.49"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11366"
      />
      <path
        d="m 253.03,288.38 v 6 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11367"
      />
      <path
        d="m 253.03,294.38 h 13.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11368"
      />
      <path
        d="m 253.03,292.01 h 1.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11369"
      />
      <path
        d="m 255.23,292.01 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11370"
      />
      <path
        d="m 257.28,292.01 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11371"
      />
      <path
        d="m 259.34,292.01 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11372"
      />
      <path
        d="m 261.4,292.01 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11373"
      />
      <path
        d="m 263.45,292.01 h 1.38"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11374"
      />
      <path
        d="m 265.51,292.01 h 1.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11375"
      />
      <path
        d="M 168.22,292.01 H 154.41"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11376"
      />
      <path
        d="m 154.41,292.01 v 6.43 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11377"
      />
      <path
        d="m 154.41,294.91 h 1.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11378"
      />
      <path
        d="m 156.51,294.91 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11379"
      />
      <path
        d="m 158.57,294.91 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11380"
      />
      <path
        d="M 160.63,294.91 H 162"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11381"
      />
      <path
        d="m 162.68,294.91 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11382"
      />
      <path
        d="m 164.74,294.91 h 1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11383"
      />
      <path
        d="m 166.8,294.91 h 1.42"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11384"
      />
      <path
        d="m 79.17,277.74 v 18.01 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11385"
      />
      <path
        d="m 76.28,277.74 v 1.46 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11386"
      />
      <path
        d="m 76.28,279.89 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11387"
      />
      <path
        d="m 76.28,281.95 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11388"
      />
      <path
        d="m 76.28,284 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11389"
      />
      <path
        d="m 76.28,286.06 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11390"
      />
      <path
        d="m 76.28,288.12 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11391"
      />
      <path
        d="m 76.28,290.17 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11392"
      />
      <path
        d="m 76.28,292.23 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11393"
      />
      <path
        d="m 76.28,294.29 v 1.46 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11394"
      />
      <path
        d="m 74.17,290.32 v 3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11395"
      />
      <path
        d="m 74.17,294.06 h 3.74"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11396"
      />
      <path
        d="m 77.91,294.06 v -3.74 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11397"
      />
      <path
        d="M 77.91,290.32 H 74.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11398"
      />
      <path
        d="m 75.4,290.66 h 1.84"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11399"
      />
      <path
        d="M 77.24,290.66 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11400"
      />
      <path
        d="m 77.24,290.66 0.12,0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11401"
      />
      <path
        d="m 77.36,290.7 0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11402"
      />
      <path
        d="m 77.45,290.79 0.04,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11403"
      />
      <path
        d="m 77.49,290.91 v 2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11404"
      />
      <path
        d="M 77.49,293.4 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11405"
      />
      <path
        d="m 77.49,293.4 -0.04,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11406"
      />
      <path
        d="m 77.45,293.53 -0.09,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11407"
      />
      <path
        d="m 77.36,293.62 -0.12,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11408"
      />
      <path
        d="M 77.24,293.65 H 75.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11409"
      />
      <path
        d="M 75.4,293.65 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11410"
      />
      <path
        d="m 75.4,293.65 -0.13,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11411"
      />
      <path
        d="m 75.27,293.62 -0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11412"
      />
      <path
        d="M 75.18,293.53 75.15,293.4"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11413"
      />
      <path
        d="m 75.15,293.4 v -2.49 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11414"
      />
      <path
        d="M 75.15,290.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11415"
      />
      <path
        d="m 75.15,290.91 0.03,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11416"
      />
      <path
        d="m 75.18,290.79 0.09,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11417"
      />
      <path
        d="m 75.27,290.7 0.13,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11418"
      />
      <path
        d="m 74.74,292.14 h 1.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11419"
      />
      <path
        d="M 74.78,291.76 V 290.7 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11420"
      />
      <path
        d="m 74.78,292.58 v 1.05 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11421"
      />
      <path
        d="m 76.51,292.2 -0.07,0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11422"
      />
      <path
        d="m 76.44,292.36 -0.16,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11423"
      />
      <path
        d="m 76.28,292.43 -0.16,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11424"
      />
      <path
        d="M 76.12,292.36 76.05,292.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11425"
      />
      <path
        d="m 76.05,292.2 0.07,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11426"
      />
      <path
        d="m 76.12,292.03 0.16,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11427"
      />
      <path
        d="m 76.28,291.97 0.16,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11428"
      />
      <path
        d="m 76.44,292.03 0.07,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11429"
      />
      <path
        d="m 83.07,380.5 v 6.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11430"
      />
      <path
        d="m 83.07,386.87 h 23.28"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11431"
      />
      <path
        d="M 106.35,386.87 V 380.5 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11432"
      />
      <path
        d="m 106.35,383.69 h -1.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11433"
      />
      <path
        d="m 104.65,383.69 h -1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11434"
      />
      <path
        d="m 102.59,383.69 h -1.37"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11435"
      />
      <path
        d="M 100.54,383.69 H 99.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11436"
      />
      <path
        d="M 98.48,383.69 H 97.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11437"
      />
      <path
        d="M 96.42,383.69 H 95.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11438"
      />
      <path
        d="M 94.37,383.69 H 92.99"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11439"
      />
      <path
        d="M 92.31,383.69 H 90.94"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11440"
      />
      <path
        d="M 90.25,383.69 H 88.88"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11441"
      />
      <path
        d="M 88.19,383.69 H 86.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11442"
      />
      <path
        d="M 86.14,383.69 H 84.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11443"
      />
      <path
        d="M 84.08,383.69 H 83.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11444"
      />
      <path
        d="M 74.83,215.97 H 80.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11445"
      />
      <path
        d="m 80.9,215.97 v 14.61 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11446"
      />
      <path
        d="M 80.9,230.58 H 98.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11447"
      />
      <path
        d="m 98.05,230.58 v 6.2 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11448"
      />
      <path
        d="m 77.87,215.97 v 1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11449"
      />
      <path
        d="m 77.87,217.85 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11450"
      />
      <path
        d="m 77.87,219.9 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11451"
      />
      <path
        d="m 77.87,221.96 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11452"
      />
      <path
        d="m 77.87,224.02 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11453"
      />
      <path
        d="m 77.87,226.07 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11454"
      />
      <path
        d="m 77.87,228.13 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11455"
      />
      <path
        d="m 77.87,230.19 v 1.37 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11456"
      />
      <path
        d="m 77.87,232.24 v 1.19 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11457"
      />
      <path
        d="m 77.87,233.43 1.52,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11458"
      />
      <path
        d="m 80.08,233.46 1.37,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11459"
      />
      <path
        d="m 82.13,233.49 1.37,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11460"
      />
      <path
        d="m 84.19,233.51 1.37,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11461"
      />
      <path
        d="m 86.25,233.54 1.37,0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11462"
      />
      <path
        d="m 88.3,233.56 1.38,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11463"
      />
      <path
        d="m 90.36,233.58 1.37,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11464"
      />
      <path
        d="m 92.42,233.61 1.37,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11465"
      />
      <path
        d="m 94.47,233.63 1.38,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11466"
      />
      <path
        d="m 96.53,233.66 1.52,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11467"
      />
      <path
        d="m 78.32,378.66 h 1.92"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11468"
      />
      <path
        d="m 80.24,378.66 0.31,-0.75"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11469"
      />
      <path
        d="M 80.55,377.91 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11470"
      />
      <path
        d="m 80.55,377.91 0.27,-0.73"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11471"
      />
      <path
        d="M 80.82,377.18 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11472"
      />
      <path
        d="m 80.82,377.18 0.25,-1.26"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11473"
      />
      <path
        d="m 81.07,375.92 -0.04,-1.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11474"
      />
      <path
        d="m 81.03,374.63 -0.34,-1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11475"
      />
      <path
        d="m 80.69,373.38 -0.6,-1.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11476"
      />
      <path
        d="M 80.09,372.24 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11477"
      />
      <path
        d="m 80.09,372.24 -0.36,-0.33"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11478"
      />
      <path
        d="m 79.73,371.91 -0.48,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11479"
      />
      <path
        d="m 79.25,371.81 -0.47,0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11480"
      />
      <path
        d="M 78.78,371.95 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11481"
      />
      <path
        d="m 78.78,371.95 -0.53,0.5"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11482"
      />
      <path
        d="M 78.25,372.45 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11483"
      />
      <path
        d="m 78.25,372.45 -0.44,0.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11484"
      />
      <path
        d="M 77.81,373.25 77.5,374.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11485"
      />
      <path
        d="M 77.5,374.1 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11486"
      />
      <path
        d="m 77.5,374.1 -0.12,0.62"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11487"
      />
      <path
        d="m 77.38,374.72 -0.01,0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11488"
      />
      <path
        d="M 77.37,375.34 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11489"
      />
      <path
        d="m 77.37,375.34 0.17,1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11490"
      />
      <path
        d="m 77.54,376.53 0.78,2.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11491"
      />
      <path
        d="m 77.45,375.78 0.34,0.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11492"
      />
      <path
        d="m 77.79,376.48 0.09,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11493"
      />
      <path
        d="m 77.88,376.67 0.17,0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11494"
      />
      <path
        d="m 78.05,376.8 h 0.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11495"
      />
      <path
        d="m 78.32,376.8 2.16,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11496"
      />
      <path
        d="m 80.48,376.82 0.15,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11497"
      />
      <path
        d="m 80.63,376.75 0.19,-0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11498"
      />
      <path
        d="m 80.82,376.5 0.19,-0.43"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11499"
      />
      <path
        d="m 81.01,376.07 0.05,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11500"
      />
      <path
        d="m 85.22,379.22 -0.01,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11501"
      />
      <path
        d="m 85.21,379.07 -0.07,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11502"
      />
      <path
        d="m 85.14,378.96 -0.09,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11503"
      />
      <path
        d="M 85.05,378.84 H 84.93"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11504"
      />
      <path
        d="m 84.93,378.84 -7.98,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11505"
      />
      <path
        d="m 76.95,378.82 -0.12,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11506"
      />
      <path
        d="m 76.83,378.87 -0.07,0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11507"
      />
      <path
        d="m 76.76,378.94 -0.04,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11508"
      />
      <path
        d="m 76.72,379.09 0.01,0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11509"
      />
      <path
        d="m 85.69,379.25 -0.03,-0.18"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11510"
      />
      <path
        d="m 85.66,379.07 -0.07,-0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11511"
      />
      <path
        d="m 85.59,378.85 -0.16,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11512"
      />
      <path
        d="m 85.43,378.69 -0.15,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11513"
      />
      <path
        d="m 85.28,378.58 -0.2,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11514"
      />
      <path
        d="m 85.08,378.49 -0.15,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11515"
      />
      <path
        d="M 84.93,378.41 H 77.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11516"
      />
      <path
        d="m 77.11,378.41 -0.19,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11517"
      />
      <path
        d="m 76.92,378.47 -0.29,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11518"
      />
      <path
        d="m 76.63,378.58 -0.13,0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11519"
      />
      <path
        d="m 76.5,378.72 -0.08,0.22"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11520"
      />
      <path
        d="m 76.42,378.94 -0.05,0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11521"
      />
      <path
        d="m 76.37,379.14 0.02,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11522"
      />
      <path
        d="m 74.78,377.67 h 0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11523"
      />
      <path
        d="m 74.92,377.67 0.17,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11524"
      />
      <path
        d="m 75.09,377.62 0.06,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11525"
      />
      <path
        d="m 75.15,377.54 0.11,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11526"
      />
      <path
        d="m 75.26,377.53 0.08,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11527"
      />
      <path
        d="m 75.34,377.42 0.12,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11528"
      />
      <path
        d="m 75.46,377.26 0.03,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11529"
      />
      <path
        d="M 75.49,377.09 V 365.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11530"
      />
      <path
        d="M 75.49,365.93 75.47,365.8"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11531"
      />
      <path
        d="M 75.47,365.8 75.4,365.68"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11532"
      />
      <path
        d="m 75.4,365.68 -0.07,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11533"
      />
      <path
        d="m 75.33,365.56 -0.12,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11534"
      />
      <path
        d="m 75.21,365.46 -0.09,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11535"
      />
      <path
        d="m 75.12,365.39 -0.11,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11536"
      />
      <path
        d="m 75.01,365.36 -0.12,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11537"
      />
      <path
        d="M 74.89,365.35 H 74.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11538"
      />
      <path
        d="m 74.77,377.23 0.12,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11539"
      />
      <path
        d="M 74.89,377.22 75,377.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11540"
      />
      <path
        d="m 75,377.21 0.05,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11541"
      />
      <path
        d="m 75.05,377.14 0.08,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11542"
      />
      <path
        d="m 75.13,377.04 0.05,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11543"
      />
      <path
        d="m 75.18,376.94 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11544"
      />
      <path
        d="M 75.18,376.79 V 366.18 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11545"
      />
      <path
        d="m 75.18,366.18 -0.03,-0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11546"
      />
      <path
        d="m 75.15,366.04 -0.07,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11547"
      />
      <path
        d="M 75.08,365.95 75.01,365.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11548"
      />
      <path
        d="M 75.01,365.9 H 74.9"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11549"
      />
      <path
        d="m 74.9,365.9 -0.13,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11550"
      />
      <path
        d="m 74.77,368.52 h 1.56"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11551"
      />
      <path
        d="m 76.33,368.52 v 1.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11552"
      />
      <path
        d="M 76.33,369.99 H 74.77"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11553"
      />
      <path
        d="m 75.76,369.99 v -1.46 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11554"
      />
      <path
        d="m 303.08,248.6 h -1.92"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11555"
      />
      <path
        d="m 301.16,248.6 -0.31,-0.76"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11556"
      />
      <path
        d="M 300.85,247.84 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11557"
      />
      <path
        d="m 300.85,247.84 -0.27,-0.72"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11558"
      />
      <path
        d="M 300.58,247.12 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11559"
      />
      <path
        d="m 300.58,247.12 -0.25,-1.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11560"
      />
      <path
        d="m 300.33,245.85 0.04,-1.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11561"
      />
      <path
        d="m 300.37,244.56 0.34,-1.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11562"
      />
      <path
        d="m 300.71,243.31 0.6,-1.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11563"
      />
      <path
        d="M 301.31,242.17 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11564"
      />
      <path
        d="m 301.31,242.17 0.36,-0.32"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11565"
      />
      <path
        d="m 301.67,241.85 0.48,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11566"
      />
      <path
        d="m 302.15,241.74 0.47,0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11567"
      />
      <path
        d="M 302.62,241.88 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11568"
      />
      <path
        d="m 302.62,241.88 0.53,0.51"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11569"
      />
      <path
        d="M 303.15,242.39 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11570"
      />
      <path
        d="m 303.15,242.39 0.44,0.79"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11571"
      />
      <path
        d="m 303.59,243.18 0.31,0.86"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11572"
      />
      <path
        d="M 303.9,244.04 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11573"
      />
      <path
        d="m 303.9,244.04 0.12,0.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11574"
      />
      <path
        d="m 304.02,244.65 0.01,0.62 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11575"
      />
      <path
        d="M 304.03,245.27 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11576"
      />
      <path
        d="m 304.03,245.27 -0.17,1.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11577"
      />
      <path
        d="m 303.86,246.46 -0.78,2.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11578"
      />
      <path
        d="m 303.95,245.71 -0.34,0.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11579"
      />
      <path
        d="m 303.61,246.41 -0.09,0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11580"
      />
      <path
        d="m 303.52,246.61 -0.17,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11581"
      />
      <path
        d="m 303.35,246.73 h -0.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11582"
      />
      <path
        d="m 303.08,246.73 -2.16,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11583"
      />
      <path
        d="m 300.92,246.75 -0.15,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11584"
      />
      <path
        d="m 300.77,246.68 -0.19,-0.24"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11585"
      />
      <path
        d="M 300.58,246.44 300.39,246"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11586"
      />
      <path
        d="m 300.39,246 -0.05,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11587"
      />
      <path
        d="M 296.18,249.15 296.2,249"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11588"
      />
      <path
        d="m 296.2,249 0.06,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11589"
      />
      <path
        d="m 296.26,248.89 0.09,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11590"
      />
      <path
        d="m 296.35,248.77 h 0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11591"
      />
      <path
        d="m 296.47,248.77 7.98,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11592"
      />
      <path
        d="m 304.45,248.75 0.12,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11593"
      />
      <path
        d="m 304.57,248.8 0.07,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11594"
      />
      <path
        d="m 304.64,248.88 0.04,0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11595"
      />
      <path
        d="m 304.68,249.02 -0.01,0.16 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11596"
      />
      <path
        d="M 295.71,249.18 295.74,249"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11597"
      />
      <path
        d="m 295.74,249 0.07,-0.21"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11598"
      />
      <path
        d="m 295.81,248.79 0.16,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11599"
      />
      <path
        d="m 295.97,248.62 0.15,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11600"
      />
      <path
        d="m 296.12,248.51 0.2,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11601"
      />
      <path
        d="m 296.32,248.42 0.15,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11602"
      />
      <path
        d="m 296.47,248.34 h 7.82"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11603"
      />
      <path
        d="m 304.29,248.34 0.19,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11604"
      />
      <path
        d="m 304.48,248.4 0.29,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11605"
      />
      <path
        d="m 304.77,248.51 0.13,0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11606"
      />
      <path
        d="m 304.9,248.65 0.08,0.23"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11607"
      />
      <path
        d="m 304.98,248.88 0.05,0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11608"
      />
      <path
        d="m 305.03,249.08 -0.02,0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11609"
      />
      <path
        d="m 306.62,247.6 h -0.14"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11610"
      />
      <path
        d="m 306.48,247.6 -0.17,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11611"
      />
      <path
        d="m 306.31,247.55 -0.06,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11612"
      />
      <path
        d="m 306.25,247.47 -0.11,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11613"
      />
      <path
        d="m 306.14,247.46 -0.08,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11614"
      />
      <path
        d="m 306.06,247.35 -0.12,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11615"
      />
      <path
        d="m 305.94,247.19 -0.03,-0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11616"
      />
      <path
        d="M 305.91,247.02 V 235.86 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11617"
      />
      <path
        d="m 305.91,235.86 0.02,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11618"
      />
      <path
        d="M 305.93,235.73 306,235.61"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11619"
      />
      <path
        d="m 306,235.61 0.07,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11620"
      />
      <path
        d="m 306.07,235.49 0.12,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11621"
      />
      <path
        d="m 306.19,235.39 0.09,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11622"
      />
      <path
        d="m 306.28,235.32 0.11,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11623"
      />
      <path
        d="m 306.39,235.3 0.12,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11624"
      />
      <path
        d="m 306.51,235.29 h 0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11625"
      />
      <path
        d="m 306.63,247.16 -0.12,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11626"
      />
      <path
        d="m 306.51,247.15 -0.11,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11627"
      />
      <path
        d="m 306.4,247.14 -0.05,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11628"
      />
      <path
        d="m 306.35,247.07 -0.08,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11629"
      />
      <path
        d="m 306.27,246.98 -0.05,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11630"
      />
      <path
        d="m 306.22,246.87 v -0.15 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11631"
      />
      <path
        d="M 306.22,246.72 V 236.11 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11632"
      />
      <path
        d="m 306.22,236.11 0.03,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11633"
      />
      <path
        d="m 306.25,235.98 0.07,-0.1"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11634"
      />
      <path
        d="m 306.32,235.88 0.07,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11635"
      />
      <path
        d="m 306.39,235.83 h 0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11636"
      />
      <path
        d="m 306.5,235.83 0.13,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11637"
      />
      <path
        d="m 306.63,238.45 h -1.56"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11638"
      />
      <path
        d="m 305.07,238.45 v 1.47 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11639"
      />
      <path
        d="m 305.07,239.92 h 1.56"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11640"
      />
      <path
        d="m 305.64,239.92 v -1.46 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11641"
      />
      <path
        d="m 274.44,167.36 v 1.92 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11642"
      />
      <path
        d="m 274.44,169.28 -0.75,0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11643"
      />
      <path
        d="M 273.69,169.59 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11644"
      />
      <path
        d="m 273.69,169.59 -0.73,0.27"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11645"
      />
      <path
        d="M 272.96,169.86 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11646"
      />
      <path
        d="m 272.96,169.86 -1.27,0.25"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11647"
      />
      <path
        d="m 271.69,170.11 -1.29,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11648"
      />
      <path
        d="m 270.4,170.07 -1.25,-0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11649"
      />
      <path
        d="m 269.15,169.73 -1.14,-0.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11650"
      />
      <path
        d="M 268.01,169.13 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11651"
      />
      <path
        d="m 268.01,169.13 -0.32,-0.36"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11652"
      />
      <path
        d="m 267.69,168.77 -0.11,-0.48"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11653"
      />
      <path
        d="m 267.58,168.29 0.14,-0.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11654"
      />
      <path
        d="M 267.72,167.82 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11655"
      />
      <path
        d="m 267.72,167.82 0.51,-0.53"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11656"
      />
      <path
        d="M 268.23,167.29 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11657"
      />
      <path
        d="m 268.23,167.29 0.8,-0.44"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11658"
      />
      <path
        d="m 269.03,166.85 0.85,-0.31"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11659"
      />
      <path
        d="M 269.88,166.54 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11660"
      />
      <path
        d="m 269.88,166.54 0.61,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11661"
      />
      <path
        d="m 270.49,166.42 0.63,-0.01"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11662"
      />
      <path
        d="M 271.12,166.41 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11663"
      />
      <path
        d="m 271.12,166.41 1.19,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11664"
      />
      <path
        d="m 272.31,166.58 2.13,0.78"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11665"
      />
      <path
        d="m 271.55,166.49 0.71,0.34"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11666"
      />
      <path
        d="m 272.26,166.83 0.19,0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11667"
      />
      <path
        d="m 272.45,166.92 0.12,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11668"
      />
      <path
        d="m 272.57,167.09 v 0.27 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11669"
      />
      <path
        d="m 272.57,167.36 0.03,2.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11670"
      />
      <path
        d="m 272.6,169.52 -0.08,0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11671"
      />
      <path
        d="m 272.52,169.67 -0.24,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11672"
      />
      <path
        d="m 272.28,169.86 -0.44,0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11673"
      />
      <path
        d="m 271.84,170.05 -0.17,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11674"
      />
      <path
        d="m 275,174.26 -0.16,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11675"
      />
      <path
        d="m 274.84,174.24 -0.1,-0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11676"
      />
      <path
        d="m 274.74,174.18 -0.13,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11677"
      />
      <path
        d="m 274.61,174.09 v -0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11678"
      />
      <path
        d="m 274.61,173.97 -0.01,-7.98"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11679"
      />
      <path
        d="m 274.6,165.99 0.04,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11680"
      />
      <path
        d="m 274.64,165.87 0.08,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11681"
      />
      <path
        d="m 274.72,165.8 0.15,-0.04"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11682"
      />
      <path
        d="m 274.87,165.76 h 0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11683"
      />
      <path
        d="m 275.03,174.73 -0.19,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11684"
      />
      <path
        d="m 274.84,174.7 -0.21,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11685"
      />
      <path
        d="m 274.63,174.63 -0.17,-0.16"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11686"
      />
      <path
        d="m 274.46,174.47 -0.1,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11687"
      />
      <path
        d="m 274.36,174.32 -0.1,-0.2"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11688"
      />
      <path
        d="m 274.26,174.12 -0.07,-0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11689"
      />
      <path
        d="m 274.19,173.97 v -7.82 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11690"
      />
      <path
        d="m 274.19,166.15 0.06,-0.19"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11691"
      />
      <path
        d="m 274.25,165.96 0.11,-0.29"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11692"
      />
      <path
        d="m 274.36,165.67 0.13,-0.13"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11693"
      />
      <path
        d="m 274.49,165.54 0.23,-0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11694"
      />
      <path
        d="m 274.72,165.46 0.2,-0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11695"
      />
      <path
        d="m 274.92,165.41 0.11,0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11696"
      />
      <path
        d="m 273.44,163.82 v 0.14 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11697"
      />
      <path
        d="m 273.44,163.96 -0.04,0.17"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11698"
      />
      <path
        d="m 273.4,164.13 -0.08,0.06"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11699"
      />
      <path
        d="m 273.32,164.19 -0.02,0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11700"
      />
      <path
        d="m 273.3,164.3 -0.11,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11701"
      />
      <path
        d="m 273.19,164.38 -0.15,0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11702"
      />
      <path
        d="m 273.04,164.5 -0.17,0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11703"
      />
      <path
        d="M 272.87,164.53 H 261.7"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11704"
      />
      <path
        d="m 261.7,164.53 -0.12,-0.02"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11705"
      />
      <path
        d="m 261.58,164.51 -0.13,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11706"
      />
      <path
        d="m 261.45,164.44 -0.12,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11707"
      />
      <path
        d="m 261.33,164.37 -0.09,-0.12"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11708"
      />
      <path
        d="m 261.24,164.25 -0.07,-0.09"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11709"
      />
      <path
        d="m 261.17,164.16 -0.03,-0.11"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11710"
      />
      <path
        d="m 261.14,164.05 -0.01,-0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11711"
      />
      <path
        d="m 261.13,163.93 v -0.12 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11712"
      />
      <path
        d="M 273.01,163.81 273,163.93 Z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11713"
      />
      <path
        d="m 273,163.93 -0.01,0.11 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11714"
      />
      <path
        d="m 272.99,164.04 -0.07,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11715"
      />
      <path
        d="m 272.92,164.09 -0.1,0.08"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11716"
      />
      <path
        d="m 272.82,164.17 -0.11,0.05"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11717"
      />
      <path
        d="m 272.71,164.22 h -0.15"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11718"
      />
      <path
        d="m 272.56,164.22 h -10.6"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11719"
      />
      <path
        d="m 261.96,164.22 -0.14,-0.03"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11720"
      />
      <path
        d="m 261.82,164.19 -0.1,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11721"
      />
      <path
        d="m 261.72,164.12 -0.05,-0.07"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11722"
      />
      <path
        d="m 261.67,164.05 v -0.11 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11723"
      />
      <path
        d="m 261.67,163.94 -0.01,-0.13 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11724"
      />
      <path
        d="m 264.3,163.81 v 1.56 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11725"
      />
      <path
        d="m 264.3,165.37 h 1.47"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11726"
      />
      <path
        d="m 265.77,165.37 v -1.56 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11727"
      />
      <path
        d="M 265.76,164.8 H 264.3"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11728"
      />
      <path
        d="m 394,197.94 -0.01,1.25 z"
        fill="none"
        stroke="#000000"
        stroke-width="0.5"
        id="path11729"
      />
    </g>
  </svg>
</template>
