<template>
  <div>
    <BannerAlertVue
      v-if="!measuresList.length && !isLoading"
      :message="bannerMessage"
    />
    <MeasuresListVue v-else :MeasuresList="measuresList" />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </div>
</template>

<script>
import MeasuresListVue from '@/Core.Service.Domain/Analytics/Measures/Components/MeasuresList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MeasuresPortfolio',

  components: { MeasuresListVue, ProgressLinearLoaderVue, BannerAlertVue },

  computed: {
    ...mapGetters({
      measuresList:
        'AnalyticsStore/AnalyticsMeasuresStore/getAnalyticsMeasuresList',
    }),
    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getAnalyticsMeasures();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      getAnalyticsMeasures:
        'AnalyticsStore/AnalyticsMeasuresStore/getAnalyticsMeasures',
    }),
  },
};
</script>
