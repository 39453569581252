<template>
  <div>
    <BannerAlertVue
      v-if="!nonRoutineEventsList.length && !isLoading"
      :message="bannerMessage"
    />
    <NonRoutineEventListVue
      v-else
      :NonRoutineEventsList="nonRoutineEventsList"
    />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NonRoutineEventListVue from '@/Core.Service.Domain/Analytics/NonRoutineEvents/Components/NonRoutineEventsList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert';

export default {
  name: 'NonRoutineEventPortfolio',

  components: {
    NonRoutineEventListVue,
    ProgressLinearLoaderVue,
    BannerAlertVue,
  },

  computed: {
    ...mapGetters({
      nonRoutineEventsList:
        'AnalyticsStore/AnalyticsNonRoutineEventsStore/selectedSiteWithAnalyticsNonRoutineEvents',
      isLoading:
        'AnalyticsStore/AnalyticsNonRoutineEventsStore/selectedSiteWithAnalyticsNonRoutineEventsIsLoading',
    }),

    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },
  },
};
</script>
