<template>
  <div>
    <highcharts id="chart" class="hc" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    costSavingsData: { type: Array, default: () => null },
  },

  data() {
    return {
      chartOptions: {
        credits: { enabled: false },
        chart: {
          zoomType: 'x',
          marginTop: 20,
          height: 450,
          type: 'spline',
        },
        title: {
          text: 'Cost Savings Goal',
        },
        subtitle: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          labels: {
            rotation: -45,
            formatter: function () {
              if (!this.value) return '';
              console.log(this.value);
              return moment(this.value * 1000).format('YYYY-MM-DD');
            },
          },
        },
        yAxis: {
          title: {
            text: '$',
          },
        },
        tooltip: {
          formatter: function () {
            return (
              '<b>' +
              moment(this.x * 1000).format('YYYY-MM-DD HH:mm') +
              '</b><br>' +
              '$' +
              this.y
            );
          },
          valuePrefix: '$',
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: null,
      },
    };
  },

  mounted() {
    this.refreshChartData();
  },

  methods: {
    refreshChartData() {
      this.chartOptions.series = this.getData(this.costSavingsData);
    },

    getData(csg) {
      const makeSeriesDataPoint = (item) => {
        return [item[0], item[1]];
      };

      var seriesData = [
        {
          name: 'Savings',
          type: 'spline',
          color: '#81C784',
          marker: {
            radius: 2,
          },
          tooltip: {
            valueSuffix: ' kWh',
            valueDecimals: 0,
          },
          data: csg[0].data.map((p) => makeSeriesDataPoint(p)),
        },
      ];

      return seriesData;
    },
  },
};
</script>

<style>
.hc {
  height: 600px;
}
</style>
