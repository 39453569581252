<template>
  <div class="mt-n8">
    <v-icon color="primary" size="large" class="mt-n1">
      {{ icon }}
    </v-icon>
    <span class="text-h6 text-primary">
      {{ label }}
    </span>
  </div>
</template>
<script>
export default {
  name: "TitleBar",
  computed: {
    label() {
      return this.$route.meta.label;
    },

    icon() {
      return this.$route.meta.icon;
    }
  }
};
</script>