<template>
  <div>
    <v-tooltip location="right" content-class="control-tooltip ml-n2 mt-7">
      <template v-slot:activator="{ props }">
        <v-card
          variant="outlined"
          height="50"
          :style="`border-right: 5px solid ${`rgb(${getCO2Description().rgb.join(
            ','
          )})`} !important`"
          class="mb-1"
          v-bind="props"
        >
          <v-row
            align="center"
            justify="center"
            class="mt-1 d-flex justify-center flex-column"
          >
            <span class="text-primary text-caption font-weight-bold">
              <span>CO2 Levels (ppm)</span>
            </span>
            <span class="mt-n2 text-primary text-h6 font-weight-bold">
              {{ controlData.co2Level }}
            </span>
          </v-row>
        </v-card>
      </template>
      <CO2Alert :CO2Description="getCO2Description" />
    </v-tooltip>
    <v-card variant="outlined" height="50" class="mb-n2">
      <v-row
        align="center"
        justify="center"
        class="mt-1 d-flex justify-center flex-column"
      >
        <span class="text-primary text-caption font-weight-bold">
          <span>CO2 Setting</span>
        </span>
        <span class="mt-n2 text-primary text-h6 font-weight-bold">
          {{ controlData.co2Setting }}
        </span>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import CO2Alert from '@/Core.Service.Domain/Controls/Thermostats/Components/CO2Alert.vue';

export default {
  name: 'CO2Widget',

  components: {
    CO2Alert,
  },

  props: {
    controlData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      co2Text:
        '<br /><br /><p>Carbon dioxide (<strong>CO2</strong>) is an important heat-trapping gas, also known as a greenhouse gas, that comes from the extraction and burning of fossil fuels (such as coal, oil, and natural gas), from wildfires, and natural processes like volcanic eruptions.</p>',
    };
  },

  methods: {
    getCO2Description() {
      const { co2Level } = this.controlData;
      const { co2Setting } = this.controlData;

      if (co2Level <= co2Setting)
        return {
          icon: 'mdi-molecule-co2',
          title: 'Good',
          description: `CO2 Levels are <strong>below</strong> the set threshold. ${this.co2Text}`,
          rgb: [0, 228, 0],
        };
      return {
        icon: 'mdi-molecule-co2',
        title: 'Warning',
        description: `CO2 Levels are <strong>above</strong> the set threshold. ${this.co2Text}`,
        rgb: [255, 0, 0],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-overlay ::v-deep(.control-tooltip) {
  background: none !important;
}

.v-overlay ::v-deep(.v-alert__border) {
  opacity: 1 !important;
}
</style>
