const SITE_SEARCH_TERM = (state, siteSearchTerm) => {
  state.siteSearchTerm = siteSearchTerm;
};

const PORTFOLIO_SITES = (state, portfolioSites) => {
  state.portfolioSites = portfolioSites;
};

export default {
  SITE_SEARCH_TERM,
  PORTFOLIO_SITES,
};
