<template>
  <v-card
    :id="`thermostat_${ThermostatInfo.controlId}`"
    class="rounded-2"
    height="100%"
    :width="cardWidth"
    elevation="24"
  >
    <!--Card Header-->
    <v-toolbar color="primary" height="88">
      <v-container>
        <v-row>
          <v-col class="d-flex">
            <v-tooltip location="right">
              <template v-slot:activator="{ props }">
                <v-toolbar-title
                  class="text-aliceBlue font-weight-bold ml-1 text-h5 mt-2"
                  v-bind="props"
                >
                  <v-icon color="white" class="mr-n1 mt-n2" start size="small">
                    mdi-thermometer
                  </v-icon>
                  {{ ThermostatInfo.controlInfo.friendlyName }}
                </v-toolbar-title>
              </template>
              <span>{{ ThermostatInfo.controlInfo.friendlyName }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <!--Dirty Icon-->
            <v-tooltip location="bottom" content-class="control-tooltip">
              <template v-slot:activator="{ props }">
                <v-icon
                  class="mt-2 ml-2 control-dirty-icon"
                  :color="isDirty.isOutOfRange ? 'warning' : 'accentOrange'"
                  v-bind="props"
                  v-if="isThermostatDirty || isDirty.isOutOfRange"
                >
                  {{
                    isThermostatDirty
                      ? 'mdi-alert-circle'
                      : isDirty.isOutOfRange
                      ? 'mdi-alert-outline'
                      : ''
                  }}
                </v-icon>
              </template>
              <!--Warnings-->
              <ThermostatAlert
                :isDirty="isDirty"
                :isThermostatDirty="isThermostatDirty"
                :getThermostatNewSettings="getThermostatNewSettings"
                :getTemperaturesOutOfRange="getTemperaturesOutOfRange"
              />
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <span class="text-aliceBlue text-caption ml-3 mt-n4">
              {{ ThermostatInfo.controlInfo.siteLocationName }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <!--Card Body-->
    <v-container class="pa-4 rounded-2">
      <v-row>
        <!--Temperature Card-->
        <v-col
          :cols="ThermostatInfo.co2Level ? 6 : 12"
          :class="{ 'mr-n1': ThermostatInfo.co2Level }"
        >
          <v-card variant="outlined" height="104" class="mb-n2">
            <v-row
              align="center"
              justify="center"
              class="mt-3 d-flex justify-center flex-column"
            >
              <span class="text-primary text-caption font-weight-bold">
                <span>Temperature (F&deg;)</span>
              </span>
              <span class="text-primary text-h3 font-weight-bold">
                {{ ThermostatInfo.temperature }}&deg;
              </span>
            </v-row>
          </v-card>
        </v-col>
        <!--CO2 Card-->
        <v-col
          v-if="ThermostatInfo.co2Level"
          class="ml-n1 control-co2-widget"
          @click="handleCO2Action"
        >
          <CO2Widget :controlData="ThermostatInfo" />
        </v-col>
      </v-row>
      <!--Status/Fan Card-->
      <v-row>
        <v-col>
          <v-card variant="outlined" height="302px" class="pa-4 mb-n2">
            <v-row class="mb-1">
              <v-col>
                <span
                  class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-left"
                >
                  Status
                </span>
                <span
                  class="text-primary text-caption text-sm-subtitle-1 font-weight-bold float-right"
                >
                  {{ ThermostatInfo.runStatus || 'NA' }}
                </span>
              </v-col>
            </v-row>
            <v-divider />
            <!--Heat Settings-->
            <v-row class="mt-1">
              <v-col>
                <v-icon color="heatColor"> mdi-fire </v-icon>
                <span
                  class="text-primary text-caption text-sm-subtitle-2 font-weight-bold"
                >
                  Heat setting
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-slider
                  :disabled="!userCanUpdate || isDirty.isOutOfRange"
                  v-model="ThermostatCurrentStatus.heatSetting"
                  class="align-center my-n7"
                  color="heatColor"
                  :max="heatSettings.maxHeat"
                  :min="heatSettings.minHeat"
                  hide-details
                  step="1"
                  show-ticks
                  thumb-label
                >
                  <template v-slot:append>
                    <v-text-field
                      :disabled="!userCanUpdate || isDirty.isOutOfRange"
                      v-model="ThermostatCurrentStatus.heatSetting"
                      hide-details
                      class="rounded-2 font-weight-bold text-primary"
                      single-line
                      variant="outlined"
                      density="compact"
                      style="width: 60px"
                      suffix="º"
                    />
                  </template>
                </v-slider>
              </v-col>
            </v-row>
            <v-row class="mt-n1 mb-1">
              <v-col>
                <span class="text-primary text-caption text-sm-subtitle-2">
                  {{ `Min: ${heatSettings.minHeat}&deg;` }}
                </span>
                <span
                  class="text-primary text-caption text-sm-subtitle-2 float-right mr-16"
                  :class="{ 'mt-1': smallBreakpoint }"
                >
                  {{ `Max: ${heatSettings.maxHeat}&deg;` }}
                </span>
              </v-col>
            </v-row>
            <v-divider />
            <!--Cool Settings-->
            <v-row class="mt-1">
              <v-col>
                <v-icon color="intechBlue"> mdi-snowflake </v-icon>
                <span
                  class="text-primary text-caption text-sm-subtitle-2 font-weight-bold"
                >
                  Cool setting
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-slider
                  :disabled="!userCanUpdate || isDirty.isOutOfRange"
                  v-model="ThermostatCurrentStatus.coolSetting"
                  class="align-center my-n7"
                  color="intechBlue"
                  :max="coolSettings.maxCool"
                  :min="coolSettings.minCool"
                  hide-details
                  step="1"
                  show-ticks
                  thumb-label
                >
                  <template v-slot:append>
                    <v-text-field
                      :disabled="!userCanUpdate || isDirty.isOutOfRange"
                      v-model="ThermostatCurrentStatus.coolSetting"
                      hide-details
                      class="rounded-2 font-weight-bold text-primary"
                      single-line
                      variant="outlined"
                      density="compact"
                      style="width: 60px"
                      suffix="º"
                    />
                  </template>
                </v-slider>
              </v-col>
            </v-row>
            <v-row class="mt-n1 mb-1">
              <v-col>
                <span class="text-primary text-caption text-sm-subtitle-2">
                  {{ `Min: ${coolSettings.minCool}&deg;` }}
                </span>
                <span
                  class="text-primary text-caption text-sm-subtitle-2 float-right mr-16"
                  :class="{ 'mt-1': smallBreakpoint }"
                >
                  {{ `Max: ${coolSettings.maxCool}&deg;` }}
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--System/Fan Card-->
      <v-row>
        <v-col>
          <v-card
            variant="outlined"
            height="88"
            class="d-flex justify-space-between center align-center px-4 mb-n2"
          >
            <v-select
              density="compact"
              :disabled="!userCanUpdate || isDirty.isOutOfRange"
              v-model="ThermostatCurrentStatus.mode"
              :items="systemModes"
              label="System"
              variant="outlined"
              class="text-primary select-dropdown"
              :class="smallBreakpoint ? 'mr-1' : 'mr-4'"
              :hide-details="true"
            />
            <v-select
              density="compact"
              :disabled="!userCanUpdate || isDirty.isOutOfRange"
              v-model="ThermostatCurrentStatus.fanStatus"
              :items="fanModes"
              label="Fan"
              class="text-primary select-dropdown"
              variant="outlined"
              :hide-details="true"
            />
          </v-card>
        </v-col>
      </v-row>
      <!--Front Keypad Card-->
      <v-row>
        <v-col>
          <v-card
            variant="outlined"
            height="88"
            class="d-flex justify-space-between center align-center px-4 pt-3"
          >
            <v-row>
              <v-col>
                <v-icon color="primary" size="small"> mdi-dialpad </v-icon>
                <span
                  class="text-primary text-caption text-sm-subtitle-1 font-weight-bold"
                >
                  {{
                    `Front Keypad - ${
                      ThermostatCurrentStatus.frontKeypad ? 'Unlock' : 'Lock'
                    }`
                  }}
                </span>
                <v-switch
                  hide-details
                  :disabled="!userCanUpdate || isDirty.isOutOfRange"
                  class="mt-n2 ml-1"
                  color="primary"
                  inset
                  v-model="ThermostatCurrentStatus.frontKeypad"
                  :label="
                    ThermostatCurrentStatus.frontKeypad ? 'Locked' : 'Unlocked'
                  "
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" align-self="center">
          <span
            class="text-left text-caption text-primary"
            :class="{ 'text-truncate': !smallBreakpoint }"
          >
            Last updated{{ ThermostatInfo.timeSinceLastUpdate }}
          </span>
        </v-col>
        <v-col cols="6">
          <v-btn
            class="rounded-2"
            color="disabled"
            variant="flat"
            block
            size="large"
            v-if="isDirty.isOutOfRange"
          >
            <v-tooltip location="top" content-class="control-tooltip">
              <template v-slot:activator="{ props }">
                <v-icon
                  class="control-dirty-icon"
                  color="warning"
                  size="large"
                  v-bind="props"
                >
                  mdi-alert-outline
                </v-icon>
              </template>
              <!--Warnings-->
              <ThermostatAlert
                :isDirty="isDirty"
                :isThermostatDirty="isThermostatDirty"
                :getThermostatNewSettings="getThermostatNewSettings"
                :getTemperaturesOutOfRange="getTemperaturesOutOfRange"
              />
            </v-tooltip>
          </v-btn>
          <v-btn
            :disabled="
              !isThermostatDirty ||
              isThermostatSetLoading ||
              !userCanUpdate ||
              isDirty.isOutOfRange
            "
            :loading="isThermostatSetLoading"
            class="rounded-2"
            :color="!isThermostatDirty ? 'grey' : 'accentOrange'"
            variant="flat"
            block
            size="large"
            @click="handleSet"
            v-else
          >
            <div
              class="text-subtitle-2 text-white text-truncate font-weight-bold"
            >
              Set
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!--Card Footer-->
    <v-divider />
    <v-card-actions>
      <ActionButtonsVue
        :buttonsList="actionButtons"
        :disabled="!userCanUpdate"
      />
    </v-card-actions>
    <!--Confirm Dialogs - Delete-->
    <ConfirmDialogDeleteThermostat
      v-if="showConfirmDialogDelete"
      :dialog="showConfirmDialogDelete"
      :thermostatInfo="ThermostatInfo"
      @update:dialog="(value) => (showConfirmDialogDelete = value)"
    />
    <!--Confirm Dialogs - Schedule-->
    <ConfirmDialogScheduleThermostat
      v-if="showConfirmDialogSchedule"
      :dialog="showConfirmDialogSchedule"
      :thermostatInfo="ThermostatInfo"
      @update:dialog="handleShowConfirmDialogSchedule"
    />
    <!--Confirm Dialogs - Admin-->
    <ConfirmDialogAdminThermostat
      v-if="showConfirmDialogAdmin"
      :dialog="showConfirmDialogAdmin"
      :thermostatInfo="ThermostatInfo"
      @update:dialog="(value) => (showConfirmDialogAdmin = value)"
    />
    <!--History Dialog-->
    <ThermostatSettingsHistoryDialog
      v-if="showSettingsHistoryDialog"
      :dialog="showSettingsHistoryDialog"
      @update:dialog="(value) => (showSettingsHistoryDialog = value)"
      :controlDetails="controlDetails"
      @update:settingsHistoryList="() => (isSettingsHistoryLoading = false)"
    />
  </v-card>
</template>

<script>
import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
import { CompositeObject } from '@/Core.Patterns/Composite';
import API from '@/Core.Service.Domain/Controls/Thermostats/API';

import CO2Widget from '@/Core.Service.Domain/Controls/Thermostats/Components/CO2Widget.vue';

import ConfirmDialogAdminThermostat from '@/Core.Service.Domain/Controls/Thermostats/Components/ConfirmDialogAdminThermostat.vue';
import ConfirmDialogScheduleThermostat from '@/Core.Service.Domain/Controls/Thermostats/Components/ConfirmDialogScheduleThermostat.vue';
import ConfirmDialogDeleteThermostat from '@/Core.Service.Domain/Controls/Thermostats/Components/ConfirmDialogDeleteThermostat.vue';
import ThermostatAlert from '@/Core.Service.Domain/Controls/Thermostats/Components/ThermostatAlert.vue';
import ThermostatSettingsHistoryDialog from '@/Core.Service.Domain/Controls/Thermostats/Components/ThermostatSettingsHistoryDialog.vue';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';

import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

export default {
  name: 'ThermostatCard',

  components: {
    ActionButtonsVue,
    ConfirmDialogAdminThermostat,
    ConfirmDialogScheduleThermostat,
    ConfirmDialogDeleteThermostat,
    ThermostatAlert,
    ThermostatSettingsHistoryDialog,
    CO2Widget,
  },

  props: {
    ThermostatInfo: {
      type: Object,
      required: true,
    },
  },

  created() {
    eventBus.$on('isConfigLoading', (value) => {
      this.isConfigLoading = value;
    });
    this.setTemperaturesOutOfRange();
  },

  mounted() {
    this.$nextTick(() => {
      const controlAnchorPosition = localStorage.getItem(
        'controlAnchorPosition'
      );
      if (controlAnchorPosition) {
        window.scrollTo({
          top: document.querySelector(controlAnchorPosition).offsetTop - 150,
          behavior: 'smooth',
        });
      }
    });
  },

  data(instance) {
    return {
      controlDetails: {
        name: instance.ThermostatInfo.controlInfo.friendlyName,
        controlId: instance.ThermostatInfo.controlId,
        siteLocationName: instance.ThermostatInfo.controlInfo.siteLocationName,
      },
      isConfigLoading: false,
      isThermostatSetLoading: false,
      isSettingsHistoryLoading: false,
      showConfirmDialogDelete: false,
      showConfirmDialogSchedule: false,
      showConfirmDialogAdmin: false,
      showSettingsHistoryDialog: false,
      cardWidth: 360,
      isDirty: {
        heatSetting: false,
        coolSetting: false,
        mode: false,
        fanStatus: false,
        frontKeypad: false,
        isOutOfRange: false,
      },

      isDirtyNames: {
        heatSetting: 'Heat',
        coolSetting: 'Cool',
        mode: 'System Status',
        fanStatus: 'Fan Status',
        frontKeypad: 'Front Keypad',
      },

      ThermostatCurrentStatus: {
        heatSetting: instance.ThermostatInfo.heatSetting,
        coolSetting: instance.ThermostatInfo.coolSetting,
        mode: instance.ThermostatInfo.mode,
        fanStatus: instance.ThermostatInfo.fanStatus,
        frontKeypad:
          instance.ThermostatInfo.frontKeypad === 'On' ? true : false,
      },

      heatSettings: {
        maxHeat: instance.ThermostatInfo.maxHeatSetting,
        minHeat: instance.ThermostatInfo.minHeatSetting,
      },
      coolSettings: {
        maxCool: instance.ThermostatInfo.maxCoolSetting,
        minCool: instance.ThermostatInfo.minCoolSetting,
      },
      systemModes: ['Heat', 'Cool', 'Auto', 'Off'],
      fanModes: ['On', 'Auto'],
      frontKeypadModes: ['Locked', 'Unlocked'],

      actionButtons: [
        {
          icon: 'calendar-blank',
          toolTip: 'Schedule Dashboard',
          show: this.$can('update', 'controlschedules'),
          action: () => {
            instance.showConfirmDialogSchedule = true;
          },
          loading: () => instance.isConfigLoading,
        },
        {
          icon: 'cog',
          toolTip: 'Admin Dashboard',
          show: this.$can('administer', 'control'),
          action: () => {
            instance.showConfirmDialogAdmin = true;
          },
        },
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          show: this.$can('update', 'controls'),
          action: () => {
            // this route will be replaced when the new edit page is ready
            instance.$router.push({
              name: 'ControlsEdit',
              params: {
                controlId: instance.ThermostatInfo.controlId,
                type: 'thermostat',
              },
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          show: this.$can('delete', 'controls'),
          action: () => {
            instance.showConfirmDialogDelete = true;
          },
        },
        {
          icon: 'history',
          toolTip: `${instance.$route.meta.toolTip}'s Changes' History`,
          disabled: () =>
            instance.ThermostatInfo.controlInfo.hasSettingsMessages === false,
          loading: () => instance.isSettingsHistoryLoading,
          action: () => {
            if (
              instance.ThermostatInfo.controlInfo.hasSettingsMessages === false
            )
              return;
            instance.isSettingsHistoryLoading = true;
            instance.showSettingsHistoryDialog = true;
          },
        },
      ],
    };
  },

  computed: {
    isThermostatDirty() {
      return (
        Object.values(this.isDirty).some((value) => value) &&
        !this.isDirty.isOutOfRange
      );
    },

    userCanUpdate() {
      return this.$can('update', 'controls');
    },
  },

  watch: {
    'ThermostatCurrentStatus.heatSetting': function (newVal) {
      if (this.isDirty.isOutOfRange) return;
      this.isDirty.heatSetting = newVal !== this.ThermostatInfo.heatSetting;
    },
    'ThermostatCurrentStatus.coolSetting': function (newVal) {
      if (this.isDirty.isOutOfRange) return;
      this.isDirty.coolSetting = newVal !== this.ThermostatInfo.coolSetting;
    },
    'ThermostatCurrentStatus.mode': function (newVal) {
      this.isDirty.mode = newVal !== this.ThermostatInfo.mode;
    },
    'ThermostatCurrentStatus.fanStatus': function (newVal) {
      this.isDirty.fanStatus = newVal !== this.ThermostatInfo.fanStatus;
    },
    'ThermostatCurrentStatus.frontKeypad': function (newVal) {
      const toggle = newVal ? 'On' : 'Off';
      this.isDirty.frontKeypad = toggle !== this.ThermostatInfo.frontKeypad;
    },
  },

  methods: {
    async handleSet() {
      if (!this.userCanUpdate) {
        return;
      }

      this.controlAnchorPosition();
      this.isThermostatSetLoading = true;
      const sanitizedData = this.sanitizeThermostatData();

      try {
        await Promise.all([
          API.setThermostatData(sanitizedData),
          this.cacheThermostats(sanitizedData),
        ]);
        this.$emit('set-thermostat-config');
        this.resetIsDirty();
      } catch (error) {
        console.error(error);
      } finally {
        this.isThermostatSetLoading = false;
      }
    },

    controlAnchorPosition() {
      localStorage.setItem(
        'controlAnchorPosition',
        `#thermostat_${this.ThermostatInfo.controlId}`
      );
    },

    sanitizeThermostatData() {
      const thermostat = CompositeObject(
        this.ThermostatCurrentStatus,
        this.ThermostatInfo,
        [
          'minHeatSetting',
          'maxHeatSetting',
          'minCoolSetting',
          'maxCoolSetting',
          'controlId',
        ]
      );

      thermostat.frontKeypad = thermostat.frontKeypad ? 'On' : 'Off';

      RemapObjectKeysAdapter(thermostat, ['controlId'], ['thermostatId']);

      delete thermostat.controlInfo;

      return thermostat;
    },

    cacheThermostats(thermostat, now = Math.floor(Date.now() / 1000)) {
      try {
        const thermostats = JSON.parse(
          localStorage.getItem('local-storage-thermostats') || '[]'
        );
        thermostat.updatedAt = now;

        const foundIndex = thermostats.findIndex(
          (item) => item.thermostatId === thermostat.thermostatId
        );
        if (foundIndex > -1) {
          thermostats[foundIndex] = thermostat;
        } else {
          thermostats.push(thermostat);
        }

        localStorage.setItem(
          'local-storage-thermostats',
          JSON.stringify(thermostats)
        );
      } catch (error) {
        console.error('Error caching thermostat:', error);
      }
    },

    setTemperaturesOutOfRange() {
      const {
        coolSetting,
        heatSetting,
        minHeatSetting,
        maxHeatSetting,
        minCoolSetting,
        maxCoolSetting,
      } = this.ThermostatInfo;
      if (coolSetting < minCoolSetting || coolSetting > maxCoolSetting) {
        this.isDirty.isOutOfRange = true;
      }

      if (heatSetting < minHeatSetting || heatSetting > maxHeatSetting) {
        this.isDirty.isOutOfRange = true;
      }
    },

    getTemperaturesOutOfRange() {
      const {
        coolSetting,
        heatSetting,
        minHeatSetting,
        maxHeatSetting,
        minCoolSetting,
        maxCoolSetting,
      } = this.ThermostatInfo;

      const outOfRangeSettings = [];

      if (heatSetting < minHeatSetting || heatSetting > maxHeatSetting) {
        outOfRangeSettings.push({
          name: 'Heat',
          expected: `${minHeatSetting}-${maxHeatSetting}`,
          current: heatSetting,
        });
      }

      if (coolSetting < minCoolSetting || coolSetting > maxCoolSetting) {
        outOfRangeSettings.push({
          name: 'Cool',
          expected: `${minCoolSetting}-${maxCoolSetting}`,
          current: coolSetting,
        });
      }
      return outOfRangeSettings;
    },

    getThermostatNewSettings() {
      const dirtySettings = [];

      Object.entries(this.isDirty).forEach(([setting, isDirty]) => {
        if (isDirty) {
          if (setting === 'frontKeypad')
            return dirtySettings.push({
              name: this.isDirtyNames[setting],
              newSetting: this.ThermostatCurrentStatus.frontKeypad
                ? 'Locked'
                : 'Unlocked',
              currentSetting:
                this.ThermostatInfo.frontKeypad === 'On'
                  ? 'Locked'
                  : 'Unlocked',
            });
          const name = this.isDirtyNames[setting];
          const newSetting = this.ThermostatCurrentStatus[setting];
          const currentSetting = this.ThermostatInfo[setting];
          dirtySettings.push({ name, newSetting, currentSetting });
        }
      });

      return dirtySettings;
    },

    handleCO2Action() {
      const controlFriendlyName = `${this.ThermostatInfo.controlInfo.name}-${this.ThermostatInfo.controlInfo.friendlyName}`;

      const { id } = this.ThermostatInfo.controlInfo.site;
      this.$router.push({
        name: 'ControlsReportRoute',
        params: {
          reportName: 'CO2 Report',
          siteId: id,
          controlFriendlyName,
        },
      });
    },

    resetIsDirty() {
      this.isDirty = {
        heatSetting: false,
        coolSetting: false,
        mode: false,
        fanStatus: false,
        frontKeypad: false,
        isOutOfRange: false,
      };
    },

    handleShowConfirmDialogSchedule(value) {
      this.showConfirmDialogSchedule = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field :deep(.v-text-field__slot) {
  input,
  .v-select__selection {
    color: $primary !important;
  }
}

.v-text-field :deep(.v-text-field__suffix) {
  padding-left: 0 !important;
  padding-right: 10px !important;
}

.select-dropdown {
  width: 138px !important;
}

.v-chip__content {
  display: flex !important;
  justify-content: space-between !important;
  min-width: 100% !important;
}

.v-overlay :deep(.control-tooltip) {
  background: none !important;
}

.v-overlay :deep(.v-alert__border) {
  opacity: 1 !important;
}

.control-dirty-icon,
.control-co2-widget {
  cursor: pointer;
}
</style>
