<template>
  <div>
    <v-btn
      v-if="isComponentMounted"
      color="aliceBlue"
      density="default"
      variant="flat"
      :style="getButtonStyleLeft"
      class="toggle-button"
      @click="setLateraMenuOpen(!mini)"
      :icon="mini ? `mdi-chevron-right` : `mdi-chevron-left`"
    />
    <v-navigation-drawer
      v-model="drawer"
      :rail="mini"
      permanent
      color="primary"
      :width="notMiniWidth"
      :rail-width="miniWidth"
      :transitionend="(drawerWidth = mini ? miniWidth : notMiniWidth)"
    >
      <v-expansion-panels
        class="border-opacity-0"
        variant="accordion"
        color="primary"
        v-model="openedPanel"
      >
        <v-expansion-panel
          v-for="item in activeItems"
          :key="item.title"
          @click="item.action ? item.action() : handleClick(item)"
          class="px-1"
          :hide-actions="!hasChildren(item) || mini"
          elevation="0"
          bg-color="primary"
        >
          <template v-slot:title>
            <div>
              <v-divider v-if="item.separator" class="my-5 border-opacity-0" />
              <v-tooltip location="end">
                <template v-slot:activator="{ props }">
                  <v-icon
                    :icon="item.icon"
                    color="white"
                    class="mr-3"
                    v-bind="mini ? props : null"
                  >
                  </v-icon>
                  <span class="text-white" v-if="renderMenuLabels">
                    {{
                      typeof item.title === 'function'
                        ? item.title()
                        : item.title
                    }}
                  </span>
                </template>
                <div class="custom-tooltip">
                  <span>
                    {{
                      typeof item.title === 'function'
                        ? item.title()
                        : item.title
                    }}
                  </span>
                  <div class="tooltip-arrow"></div>
                </div>
              </v-tooltip>
            </div>
          </template>
          <v-expansion-panel-text
            v-for="child in item.children"
            :key="child.title"
            @click="handleClick(child)"
            :class="renderMenuLabels ? 'pl-5' : 'pl-1'"
          >
            <div class="child-container">
              <v-tooltip location="end">
                <template v-slot:activator="{ props }">
                  <v-icon
                    :icon="child.icon"
                    class="mr-2"
                    color="white"
                    size="x-small"
                    v-bind="mini ? props : null"
                  >
                  </v-icon>
                  <span
                    class="text-white text-subtitle-2"
                    v-if="renderMenuLabels"
                  >
                    {{ child.title }}
                  </span>
                </template>
                <div class="custom-tooltip">
                  <span>
                    {{ child.title }}
                  </span>
                  <div class="tooltip-arrow"></div>
                </div>
              </v-tooltip>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Avatar -->
      <template v-slot:prepend>
        <v-row class="user-info-area" @click="onClickParent = !onClickParent">
          <v-col>
            <v-avatar size="44" class="ml-5 mt-5">
              <v-img :src="userPicture" />
            </v-avatar>
          </v-col>
          <v-col v-if="showUserName">
            <v-list-item-title class="ml-n11 mt-8">
              {{ userName }}
            </v-list-item-title>
          </v-col>
        </v-row>
        <v-divider class="my-6" />
      </template>

      <!-- Contact Us -->
      <template v-slot:append>
        <v-list>
          <v-list-item link>
            <template v-if="mini" v-slot:prepend>
              <v-btn
                class="ml-3 mb-7"
                color="aliceBlue"
                size="x-small"
                icon="true"
              >
                <v-icon> mdi-email-outline </v-icon>
              </v-btn>
            </template>

            <v-menu transition="slide-x-transition">
              <template v-slot:activator>
                <v-list-item class="pl-7 mb-5" v-if="renderContactLabel">
                  <v-btn
                    class="rounded-2"
                    color="aliceBlue"
                    max-width="140px"
                    @click="handleContactUs"
                  >
                    <div>
                      <v-icon start color="primary">mdi-email-outline</v-icon>
                      <span class="font-weight-bold text-primary">
                        Contact Us
                      </span>
                    </div>
                  </v-btn>
                </v-list-item>
              </template>
            </v-menu>
          </v-list-item>
        </v-list>
        <v-card
          flat
          color="primary"
          class="d-flex justify-center pb-3"
          v-if="!smallBreakpoint"
        >
          <span class="text-caption text-white">
            {{ appVersion }}
          </span>
        </v-card>
      </template>
      <AdminMenu
        :onClickParent="onClickParent"
        :style="mini ? 'margin-left: 0' : 'margin-left: -5px'"
        :adminData="{ userPicture, userName }"
        @update:onClickParent="onClickParent = $event"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import AdminMenu from '@/Core.UI.Domain/Components/AdminMenu.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LateralMenuVue',

  components: {
    AdminMenu,
  },

  computed: {
    ...mapGetters({
      userName: 'session/jwtName',
      userPicture: 'session/jwtPicture',
      mini: 'UIStore/getLateraMenuOpen',
    }),

    getButtonStyleLeft() {
      return `left: ${this.drawerWidth - 25}px;`;
    },

    appVersion() {
      // eslint-disable-next-line no-undef
      return VITE_APP_VERSION;
    },

    showAdminMenu() {
      return this.$can('administer', 'control');
    },

    miniWidth() {
      return this.smallBreakpoint ? 68 : 85;
    },

    activeItems() {
      return this.items.filter((item) => item.active());
    },
  },

  watch: {
    mini(val) {
      this.renderContactLabel = !val;
      if (val) {
        this.showUserName = false;
        this.renderMenuLabels = false;
        this.closeAllPanels();
      } else {
        this.openPanel(this.openedPanel);
        setTimeout(() => {
          this.showUserName = true;
        }, 120);
        setTimeout(() => {
          this.renderMenuLabels = true;
        }, 200);
      }
    },
    smallBreakpoint(val) {
      this.setLateraMenuOpen(val);
    },
  },

  data(instance) {
    return {
      onClickParent: false,
      drawer: true,
      renderContactLabel: true,
      renderMenuLabels: true,
      showUserName: true,
      isComponentMounted: false,
      drawerWidth: 0,
      notMiniWidth: 229,
      openedPanel: null,
      items: [
        {
          title: 'Portfolio',
          icon: 'mdi-folder',
          to: '',
          active: () => true,
          children: [
            {
              icon: 'mdi-domain',
              title: 'My Portfolio',
              to: {
                name: 'Portfolio',
              },
            },
            {
              icon: 'mdi-message-badge',
              title: 'Notifications',
              to: {
                name: 'AllNotifications',
              },
            },
          ],
        },
        {
          icon: 'mdi-tune-vertical-variant',
          children: [
            {
              icon: 'mdi-thermometer',
              title: 'Thermostats',
              to: { name: 'Thermostats' },
            },
            {
              icon: 'mdi-power-settings',
              title: 'Power Controls',
              to: { name: 'PowerControls' },
            },
          ],
          title: 'Controls',
          to: '',
          active: () => true,
        },
        {
          title: 'Reports',
          icon: 'mdi-file-chart-outline',
          to: '',
          active: () => true,
          children: [
            {
              title: 'My Reports',
              icon: 'mdi-file-chart-outline',
              to: { name: 'Reports' },
              active: () => instance.$can('read', 'reports'),
            },
            {
              title: 'Scheduled Reports',
              icon: 'mdi-invoice-text-clock-outline',
              to: { name: 'ScheduledReports' },
              active: () => instance.$can('read', 'scheduled-reports'),
            },
          ],
        },
        {
          title: 'Strategies',
          to: '',
          active: () => this.$can('read', 'strategies'),
          icon: 'mdi-head-lightbulb-outline',
          children: [
            {
              icon: 'mdi-strategy',
              title: 'Site Strategies',
              to: { name: 'StrategiesCommon' },
              active: () => true,
            },
            {
              icon: 'mdi-lightbulb-multiple-outline',
              title: 'Strategy Templates',
              to: { name: 'StrategyTemplates' },
              active: () => instance.$can('read', 'strategy-templates'),
            },
          ],
        },
        {
          icon: 'mdi-cog-box',
          title: () => (instance.showAdminMenu ? 'Admin Menu' : 'User Menu'),
          to: '',
          separator: true,
          active: () => true,
          action: () => {
            instance.onClickParent = !instance.onClickParent;
          },
        },
      ],
    };
  },

  mounted() {
    this.drawerWidth = this.miniWidth;
    this.isComponentMounted = true;
    if (this.smallBreakpoint) {
      this.setLateraMenuOpen(true);
    }
  },

  methods: {
    ...mapActions({
      setLateraMenuOpen: 'UIStore/setLateraMenuOpen',
    }),

    hasChildren(item) {
      return item.children && item.children.length;
    },

    handleClick(item) {
      if (item.to) {
        this.$router.push(item.to);
      }
    },

    handleContactUs() {
      this.$router.push({ name: 'ContactForm' });
    },

    closeAllPanels() {
      this.openedPanel = null;
    },

    openPanel(index) {
      this.openedPanel = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-button {
  border-width: 10px;
  border-color: $primary;
  position: fixed;
  top: 65px;
  width: 44px;
  height: 44px;
  transition: left 0.1s ease-in;
  z-index: 9999;
}

.v-list-item {
  :deep(.v-list-item__overlay) {
    opacity: 0 !important;
  }
  :deep(.v-list-item__spacer) {
    width: 17px !important;
  }
}

.v-list-group__items .v-list-item {
  padding-left: 30px !important;
}

.child-container {
  cursor: pointer;
  width: 100%;
  height: 25px;
  margin: 0px;
  display: block;
  transform: scale(1);
  transition: transform 0.1s ease-out;
}

.child-container:hover {
  transform: scale(1.1);
  transition: transform 0.1s ease-in;
}

.user-info-area {
  cursor: pointer;
}

:deep(.v-overlay__content) {
  background: none !important;
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  background-color: rgba(46, 60, 84, 0.95);
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 17px;
}

/* Tooltip arrow */
.tooltip-arrow {
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid rgba(46, 60, 84, 0.95);
}
</style>
