import { h, resolveComponent } from 'vue';
import UserIndex from '@/Core.Service.Domain/Users/Views/UserList';

const routesList = [];

const UsersRoute = {
  path: '/administration/users',
  name: 'Users',
  component: {
    render: () => h(resolveComponent('router-view')),
  },
  children: [
    {
      path: '',
      name: 'UserIndex',
      component: UserIndex,
      meta: { permission: 'users:read' },
    },
    {
      path: 'edit/:userId',
      name: 'UserEdit',
      component: () =>
        import('@/modules/administration/users/_components/Edit'),
      meta: { permission: 'users:read' },
      props: true,
    },
    {
      path: 'create',
      name: 'UserCreate',
      component: () =>
        import('@/modules/administration/users/_components/Create'),
      meta: { permission: 'users:create' },
    },
    {
      path: 'roles-cache',
      name: 'UsersRolesCache',
      component: () => import('@/Core.Service.Domain/Users/Views'),
      meta: { permission: 'users:read' },
    },
  ],
};

routesList.push(UsersRoute);

const AdminReportsRoute = {
  path: '/administration/reports',
  name: 'AdminReports',
  component: {
    render: () => h(resolveComponent('router-view')),
  },
  children: [
    {
      path: '',
      name: 'AdminReportIndex',
      component: () =>
        import('@/modules/administration/reports/_components/Index'),
    },
    {
      path: 'create',
      name: 'AdminReportCreate',
      component: () =>
        import('@/modules/administration/reports/_components/Create'),
    },
    {
      path: ':id',
      name: 'AdminReportEdit',
      component: () =>
        import('@/modules/administration/reports/_components/Edit'),
    },
  ],
};

routesList.push(AdminReportsRoute);

const ADFSyncRoute = {
  path: '/administration/adf-sync',
  name: 'ADFSyncIndex',
  component: () =>
    import('@/modules/administration/adf_sync/_components/ADFSyncView.vue'),
  meta: { permission: 'users:create' },
};

routesList.push(ADFSyncRoute);

const UserUnsubscribeRoute = {
  path: '/user-unsubscribe',
  name: 'UserUnsubscribe',
  component: () =>
    import('@/Core.Service.Domain/Users/Views/UserUnsubscribe.vue'),
  meta: { allowAnonymous: true },
};

routesList.push(UserUnsubscribeRoute);

export default routesList;
