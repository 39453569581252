<!-- eslint-disable vue/valid-v-for -->
<template>
  <v-stepper vertical light flat>
    <template v-for="(message, index) in replayMessages">
      <v-stepper-item
        :step="index + 1"
        :rules="isErrorMessage(message) ? [() => false] : [() => true]"
        complete
      >
        {{ message.message }}
      </v-stepper-item>
      <v-divider />
    </template>
  </v-stepper>
</template>
<script>
export default {
  props: {
    replayMessages: Array,
  },

  methods: {
    isErrorMessage(message) {
      return message.messageType === 'Error';
    },
  },
};
</script>
