<template>
  <v-dialog v-model="dialogValue" persistent max-width="290">
    <v-card>
      <v-card-title class="text-primary text-h5">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text class="text-primary">
        {{ dialogConfirm }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-2"
          color="primary"
          variant="flat"
          @click="dialogValue = false"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
          >
            Cancel
          </div>
        </v-btn>
        <v-btn
          class="rounded-2"
          color="warning"
          variant="flat"
          :loading="loadingDelete"
          :disabled="loadingDelete"
          @click="handleDelete"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
          >
            Delete
          </div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import API from '@/Core.Service.Domain/Strategies/Strategies/API';

import { Strategy } from '@/Core.Service.Domain/Strategies/Strategies/types/strategies.ts';

// Props
const props = defineProps<{
  dialog: boolean;
  strategyInfo: {
    siteId: string;
    name: string;
  };
}>();

const loadingDelete = ref(false);

// Computed
const emits = defineEmits(['update:dialog']);
const dialogValue = computed({
  get: () => props.dialog,
  set: (value: boolean) => {
    emits('update:dialog', value);
  },
});

const route = useRoute();
const dialogTitle = computed(() => {
  return `Delete this ${route.meta.toolTip}?`;
});

const dialogConfirm = computed(() => {
  return `Are you sure you want to delete this ${route.meta.toolTip}? The configured ${route.meta.toolTip} actions will no longer run.`;
});

const defaultSite = computed(() => {
  return route.params.siteId;
});

const store = useStore();
const getStrategiesBySiteId = (siteId: string) => {
  store.dispatch('StrategiesStore/StrategiesStore/getStrategiesBySiteId', {
    selectedSite: siteId,
  });
};

// Methods
const handleDelete = async () => {
  const strategy: Strategy = {
    siteId: props.strategyInfo.siteId,
    name: props.strategyInfo.name,
  };
  loadingDelete.value = true;

  try {
    await API.deleteStrategy(strategy);
    getStrategiesBySiteId(defaultSite.value as string);
  } catch (error) {
    console.error(error);
  } finally {
    dialogValue.value = false;
    loadingDelete.value = false;
  }
};
</script>
