// Further options can be passed to options object
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

/**
 * @param {String} dateTime
 * @param {Object} options
 * @returns String
 * @description Convert date time to US format
 * @example dateTimeToUSFormatFactory('2020-01-01T00:00:00.000Z');
 * returns 'January 1, 2020'
 * @example dateTimeToUSFormatFactory('2020-01-01T00:00:00.000Z', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
 * returns 'January 1, 2020, 5:00:00 PM'
 */
export const dateTimeToUSFormatFactory = (
  dateTime: string | number | Date,
  options: object = { year: 'numeric', month: 'long', day: 'numeric' }
) => {
  const date = new Date(dateTime);
  return date.toLocaleDateString('en-US', options);
};

/**
 * @param {String} searchTerm
 * @param {Array} searchFields
 * @param {Array} originalArray
 * @returns Array
 * @description Search an array of objects by term
 */
export const searchByTermFactory = <T>(
  searchTerm: string,
  searchFields: Array<keyof T>,
  originalArray: Array<T>
) => {
  const searchTerms = searchTerm.toLowerCase().split(' ');

  const getValueByPath = (object: T, path: string) => {
    return path
      .split('.')
      .reduce((acc: Record<string, unknown>, part: string) => {
        if (typeof acc === 'string') {
          return acc;
        }
        return acc[part] as Record<string, unknown>;
      }, object as unknown as Record<string, unknown>);
  };

  return originalArray.filter((item) => {
    return searchTerms.every((term) => {
      return searchFields.some((field) => {
        const value = getValueByPath(item, field as string);
        return (
          value && (value as unknown as string).toLowerCase().includes(term)
        );
      });
    });
  });
};

/**
 * @param {Number} value
 * @param {Number} decimals
 * @returns String
 * @description Format number to N decimals
 * @example formatNumberPrecisionToNDecimalsFactory(123.456, 2);
 * returns '123.46'
 *
 **/
export const formatNumberPrecisionToNDecimalsFactory = (
  value: number,
  decimals: number = 2
) => {
  if (value === null) {
    return 'N/A';
  }
  return value.toFixed(decimals);
};

/**
 * @returns String
 * @description Format number to US format
 * @example formatNumberToUS(123456.789);
returns '123,456.79'
 */
export const formatNumberToUS = (num: number) => {
  if (Math.floor(num) === num) {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 0,
    }).format(num);
  }

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

export const transformDateToDatepicker = (inputDate: string) => {
  const dateParts = inputDate.split('T');
  const dateOnly = dateParts[0];

  return dateOnly;
};

/**
 * @param {Error} error
 * @returns Object
 * @description Get function and file name from error stack
 * @example getFunctionAndFileName(new Error('test'));
 * returns { functionName: 'Unknown', fileName: 'Unknown' }
 * @example getFunctionAndFileName(new Error('test\n    at functionName (fileName:1:1)'));
 * returns { functionName: 'functionName', fileName: 'fileName' }
 *
 **/
export interface ErrorInfo {
  functionName: string;
  fileName: string;
}

export const getFunctionAndFileName = (error: Error | string): ErrorInfo => {
  if (typeof error === 'string') {
    return { functionName: 'Unknown', fileName: error };
  }
  if (!error.stack) {
    return { functionName: 'Unknown', fileName: 'Unknown' };
  }
  const stackLines = error.stack.split('\n');
  const relevantLine = stackLines[2] || '';
  const match = relevantLine.match(/at (.+) \((.+):\d+:\d+\)/) || [];
  let functionName = match[1];
  let fileName = match[2];

  if (!functionName || !fileName) {
    const alternateMatch = relevantLine.match(/at (.+):\d+:\d+/) || [];
    if (alternateMatch[1]) {
      fileName = alternateMatch[1];
      functionName = 'Unknown';
    }
  }

  fileName = fileName.split('/').pop() || '';

  return { functionName, fileName };
};

/**
 * @param {String} text
 * @returns String
 * @description Split camel case text
 * @example splitCamelCase('camelCaseText');
 * returns 'Camel Case Text'
 *
 **/
export const splitCamelCase = (text: string) => {
  const spacedText = text.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
  const capitalizedText =
    spacedText.charAt(0).toUpperCase() + spacedText.slice(1);
  return capitalizedText;
};

/**
 * @param {Object} originalObject
 * @param {Array} keysToRemove
 * @returns Object
 * @description Remove keys from object
 * @example removeObjectKeys({ a: 1, b: 2, c: 3 }, ['a', 'b']);
 * returns { c: 3 }
 *
 **/
export const removeObjectKeys = <T extends Record<string, unknown>>(
  originalObject: T,
  keysToRemove: Array<keyof T>
): T => {
  const filteredObject = { ...originalObject };

  keysToRemove.forEach((key) => {
    delete filteredObject[key];
  });

  return filteredObject;
};

/**
 *
 * @param length
 * @returns String of random characters with given length
 * @description Generates a random character to be used as keys on components
 *
 */
export const generateRandomCustomKey = (length = 5) => {
  return Array.from({ length }, () => {
    const randomIndex = Math.floor(Math.random() * 62);
    if (randomIndex < 10) {
      // Generate a digit (0-9)
      return String.fromCharCode(48 + randomIndex);
    } else if (randomIndex < 36) {
      // Generate an uppercase letter (A-Z)
      return String.fromCharCode(65 + randomIndex - 10);
    } else {
      // Generate a lowercase letter (a-z)
      return String.fromCharCode(97 + randomIndex - 36);
    }
  }).join('');
};

export const addCommasToNumber = (number: number) => {
  const numberString = number.toString();

  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
