import api from '@/modules/administration/users/_api';
import { updateAbilitiesFromAuth0Permissions } from '@/plugins/abilities';
import router from '@/router';
import AuthenticationService from '@/services/AuthenticationService';
import moment from 'moment';

const authenticationService = new AuthenticationService();

let getRefreshTokenAttempt = 0;

/**
 * Gets permissions from JWT access token.
 * @param {string} accessToken an Auth0 JWT token
 * @returns an array of strings, likely in format "action:model" or "model:action".
 */
function getPermissions(accessToken) {
  const b64Url = accessToken.split('.')[1];
  const b64 = b64Url.replace('-', '+').replace('_', '/');
  const result = JSON.parse(atob(b64));
  return 'permissions' in result ? result['permissions'] : [];
}

const mutations = {
  authenticated(state, result) {
    const parsedToken = authenticationService.parseAuth0Token(
      result.accessToken
    );
    state.authenticated = true;
    state.accessToken = result.accessToken;
    state.expiresIn = parsedToken.exp;

    if (result.name) {
      state.userPicture = result.picture;
      state.userName = result.name;
      state.userEmail = result.email;
    }

    window.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${state.accessToken}`;

    localStorage.setItem('access_token', state.accessToken);
    localStorage.setItem('expires_in', state.expiresIn);
    localStorage.setItem('user_picture', state.userPicture);
    localStorage.setItem('user_name', state.userName);
    localStorage.setItem('user_email', state.userEmail);
  },

  logout(state) {
    state.authenticated = false;
    state.accessToken = '';
    state.userPermissionsSet = false;

    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('user_picture');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_email');
  },

  setNewTermsOfServices(state, result) {
    state.newTermsOfServices = result;
    state.hasNewTermsOfServices = result !== null && result !== undefined;

    localStorage.setItem(
      'hasNewTermsOfServices',
      JSON.stringify(state.hasNewTermsOfServices)
    );
    localStorage.setItem(
      'newTermsOfServices',
      JSON.stringify(state.newTermsOfServices)
    );
  },

  userPermissionsSet(state) {
    state.userPermissionsSet = true;
  },

  setDefaultSite(state, id) {
    state.defaultSite = id;
    localStorage.setItem('defaultSite', JSON.stringify(state.defaultSite));
  },
};

const actions = {
  setDefaultSite({ commit }, site) {
    commit('setDefaultSite', site);
  },

  login() {
    authenticationService.login();
  },

  logout({ commit }) {
    commit('logout');
    authenticationService.logout();
    router.push('/logout');
  },

  async handleAuthentication({ commit, dispatch }) {
    return authenticationService.handleAuthentication().then((result) => {
      commit('authenticated', result);
      dispatch('initializeSession');

      //check if authenticated user is an orphan
      const auth0Id = result.sub;
      api
        .isAuth0UserOrphan(auth0Id)
        .then((orphanStatus) => {
          //if orphan create e360 user
          if (orphanStatus === true) {
            console.log(`createUser required for ${auth0Id}`);

            api
              .createE360User(auth0Id)
              .then((user) => {
                console.log(user);

                //set default user role
                api
                  .setDefaultUserRole(auth0Id)
                  .then((result) => {
                    console.log(result);
                    //finally check terms of service
                    dispatch('checkTermsOfService');
                  })
                  .catch((err) => {
                    console.error(
                      `error calling setDefaultUserRole(${auth0Id})`,
                      err
                    );
                  });
              })
              .catch((err) => {
                console.error(`error calling createE360User(${auth0Id})`, err);
              });
          } else {
            dispatch('checkTermsOfService');
            return Promise.resolve();
          }
        })
        .catch((err) => {
          console.error(`error calling isAuth0UserOrphan(${auth0Id})`, err);
        });
    });
  },

  checkTermsOfService({ commit }) {
    //check for any new terms of services for
    window.axios
      .get('v1/termsofservices')
      .then((response) => {
        if (response && response.data && response.data.data) {
          console.log(`found new terms of service`, response.data.data);
          commit('setNewTermsOfServices', response.data.data);
        }
      })
      .catch((err) => {
        console.error(`error checkTermsOfService`, err);
      });
  },

  async initializeSession({ dispatch, commit, state }) {
    try {
      if (!state.authenticated) return;

      const { expiresIn } = state;

      const minutes = 5;
      const intervalTime = minutes * 60 * 1000;

      const interval = setInterval(async () => {
        const now = moment();

        const difference = moment.duration(moment.unix(expiresIn).diff(now));
        console.log(
          'Token expires in:',
          Math.round(difference.asMinutes()),
          'minutes'
        );
        console.log(
          'isRealTimeReport',
          router.currentRoute.meta?.isRealTimeReport
        );
        if (Math.round(difference.asMinutes()) <= 5) {
          if (!router.currentRoute.meta?.isRealTimeReport) {
            dispatch('logout');
          } else {
            try {
              const accessToken =
                await authenticationService.getAuth0RefreshToken();
              console.log('Token refreshed');
              commit('authenticated', { accessToken });
              getRefreshTokenAttempt = 0;
            } catch (err) {
              console.error('Error:', err);
              if (getRefreshTokenAttempt > 3) {
                dispatch('logout');
                return;
              }
              getRefreshTokenAttempt += 1;
            } finally {
              clearInterval(interval);
              dispatch('initializeSession');
            }
          }
        }
      }, intervalTime);

      if (state.accessToken !== '') {
        await updateAbilitiesFromAuth0Permissions(
          getPermissions(state.accessToken)
        );
        commit('userPermissionsSet');
      }
    } catch (err) {
      console.error('Error:', err);
    }
  },

  acceptTermsOfServices({ commit }, { termsOfServiceId }) {
    window.axios
      .post(`v1/termsofservices/${termsOfServiceId}`)
      .then((response) => {
        if (response && response.data && response.data.data) {
          commit('setNewTermsOfServices', null);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
};

const getters = {
  isAuthenticated(state) {
    return state.authenticated;
  },
  jwt: (state) => state.accessToken,
  jwtData: (state, getters) => {
    try {
      const base64Url = getters.jwt.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const result = JSON.parse(atob(base64));
      return result;
    } catch (err) {
      console.error('err', err);
      return null;
    }
  },

  acc: (state) => state.accessToken,
  accData: (state, getters) => {
    const b64Url = getters.acc.split('.')[1];
    const b64 = b64Url.replace('-', '+').replace('_', '/');
    const result = JSON.parse(atob(b64));
    return result;
  },

  userPermissions: (state, getters) =>
    getters.accData ? getters.accData.permissions : null,
  jwtSubject: (state, getters) =>
    getters.jwtData ? getters.jwtData.sub : null,
  jwtEmail: (state) => state.userEmail || localStorage.getItem('user_email'),
  jwtName: (state) => state.userName || localStorage.getItem('user_name'),
  jwtPicture: (state) =>
    state.userPicture || localStorage.getItem('user_picture'),

  hasNewTermsOfServices: (state) => state.hasNewTermsOfServices,
  newTermsOfServices: (state) => state.newTermsOfServices,
  defaultSite: (state) => state.defaultSite,
  userPermissionsSet: (state) => state.userPermissionsSet,
};

const state = {
  authenticated: !!localStorage.getItem('access_token'),
  accessToken: localStorage.getItem('access_token'),
  expiresIn: new Date().setTime(localStorage.getItem('expires_in')),
  newTermsOfServices: JSON.parse(localStorage.getItem('newTermsOfServices')),
  hasNewTermsOfServices:
    localStorage.getItem('hasNewTermsOfServices') === 'true',
  defaultSite: (() => {
    try {
      return JSON.parse(localStorage.getItem('defaultSite'));
    } catch (err) {
      console.error('err', err);
      return null;
    }
  })(),
  userPermissionsSet: false,
  userPicture: '',
  userName: '',
  userEmail: '',
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
