<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg11829"
    viewBox="323.88 344.81 73.18 106.86"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
      id="interactive-group"
    >
      <path
        d="m 345.24937,344.94094 -21.24218,0.0976 v 23.09375 l 1.72851,0.008 c -0.13797,-0.13387 -0.29101,-0.28321 -0.29101,-0.28321 l -0.27735,-8.54297 c 0,0 8.34954,-0.27324 9.08789,8.86524 l 13.65235,0.0625 0.27539,-9.06641 c 0,0 7.22644,0.95689 8.6582,9.10742 l 9.94531,0.0449 0.0215,7.90039 c 0.0843,0.0122 0.16633,0.0227 0.2539,0.0371 9.23332,1.51592 9.09571,8.81836 9.09571,8.81836 l -9.09571,0.13867 c 0,0 -0.13536,-0.16159 -0.23046,-0.27344 l 0.0586,22.29492 c 0.0576,0.009 0.11269,0.0172 0.17187,0.0274 8.81988,1.51592 8.82032,8.40625 8.82032,8.40625 l -8.95703,0.27734 c 0,0 -0.0115,-0.0134 -0.0117,-0.0137 l 0.0117,4.70117 c 0.32821,-0.33897 0.55078,-0.55469 0.55078,-0.55469 l 8.26953,-0.13672 c 0,0 0.40549,8.03474 -8.79688,8.94141 l 0.0332,12.91016 3.50781,1.85156 10.03711,4.48242 10.91406,3.41016 5.45703,-0.29102 0.0312,-83.19531 -27.63281,0.29297 -0.36719,-20.58594 h -22.21679 l -1.16993,0.0957 z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-group {
  transition: fill-opacity 0.2s;
}
#interactive-group:hover {
  fill-opacity: 0.5;
}
</style>
