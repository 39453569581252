<template>
  <v-card
    :width="cardWidth"
    height="100%"
    min-height="352"
    class="rounded-2"
    elevation="24"
    @click="handleButtonAction('ReportDetailsRoute', reportInfo.reportName)"
  >
    <!--Card Header-->
    <v-toolbar :color="getColorByType" height="56">
      <v-container>
        <v-row class="d-flex align-center">
          <v-col class="ml-n3" cols="10">
            <v-tooltip location="right">
              <template v-slot:activator="{ props }">
                <v-toolbar-title
                  class="text-aliceBlue font-weight-bold ml-1 text-h6 mt-1"
                  v-bind="props"
                >
                  <v-icon
                    color="aliceBlue"
                    size="small"
                    class="mr-0 mt-n2"
                    start
                  >
                    {{ getIconByType }}
                  </v-icon>
                  {{ reportInfo.reportName }}
                </v-toolbar-title>
              </template>
              <span>{{ reportInfo.reportName }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-card-actions>
              <ActionButtonsVue
                :buttonsList="actionButtons"
                :color="'aliceBlue'"
              />
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <!--Card Body-->
    <v-card
      flat
      height="100%"
      class="rounded-2 d-flex flex-column justify-space-between"
    >
      <v-container>
        <v-row>
          <v-col>
            <v-img
              :src="reportInfo.powerBiImageUrl || fallBackImage"
              class="rounded my-1"
              :height="imageHeight"
            >
            </v-img>
            <span class="text-primary text-caption d-flex justify-left pt-3">
              {{ reportInfo.powerBiDescription }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons.vue';
import { IActionButton } from '@/Core.UI.Domain/Components/types/actionButtons';

const props = defineProps<{
  reportInfo: {
    reportName: string;
    powerBiImageUrl: string;
    powerBiDescription: string;
    reportType: string;
  };
}>();

const fallBackImage = ref(
  'https://energy360sr.blob.core.windows.net/energy-360-report-images/default-chart.jpg'
);
const cardWidth = ref(370);
const imageHeight = ref(200);
const actionButtons = ref<IActionButton[]>([
  {
    icon: 'chevron-right',
    toolTip: 'Go to report',
    action: () => {
      handleButtonAction('ReportDetailsRoute', props.reportInfo.reportName);
    },
  },
]);

const isRealTimeType = computed(() => {
  return /real\s*-?\s*time/gi.test(props.reportInfo.reportType);
});

const isScheduledType = computed(() => {
  return /scheduled/gi.test(props.reportInfo.reportType);
});

const getIconByType = computed(() => {
  if (isRealTimeType.value) {
    return 'mdi-chart-multiline';
  } else if (isScheduledType.value) {
    return 'mdi-invoice-text-clock-outline';
  } else {
    return 'mdi-file-chart-outline';
  }
});

const getColorByType = computed(() => {
  if (isRealTimeType.value) {
    return 'success';
  } else if (isScheduledType.value) {
    return 'warning';
  } else {
    return 'primary';
  }
});

const router = useRouter();
const handleButtonAction = (name: string, reportName = '') => {
  router.push({
    name,
    params: {
      reportName,
    },
  });
};
</script>

<style lang="scss" scoped>
:deep('.v-btn') {
  .v-btn__content {
    opacity: 1 !important;
  }
}

span {
  letter-spacing: 0px;
}
</style>
