<template>
  <v-dialog
    v-model="dialogValue"
    width="50%"
    transition="slide-x-reverse-transition"
    content-class="notifications-dialog"
    persistent
  >
    <v-card class="rounded-2 px-10 py-9 notifications-dialog__card">
      <v-row>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex mb-n5">
              <v-toolbar-title
                class="text-primary font-weight-bold mr-auto text-h5"
              >
                {{ siteInfo.name }}
              </v-toolbar-title>
              <div>
                <v-btn
                  variant="plain"
                  size="small"
                  class="mr-n3"
                  @click="emits('update:dialog', false)"
                >
                  <v-icon color="primary"> mdi-close-circle </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <!--Noticiations List-->
      <v-row>
        <NotificationsListOverlay
          @update:dialog="emits('update:dialog', false)"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import NotificationsListOverlay from '@/Core.Service.Domain/ViewSupport/Notifications/Components/NotificationsListOverlay.vue';

const props = defineProps<{
  siteInfo: {
    siteId: string;
    name: string;
  };
  dialog: boolean;
}>();

const emits = defineEmits(['update:dialog']);

const dialogValue = ref(props.dialog);

onMounted(() => {
  const router = useRouter();
  router.push({
    name: 'ViewSiteNotifications',
    params: { siteId: props.siteInfo.siteId },
  });
});
</script>
