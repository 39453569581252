import { createApp } from 'vue';
import axios from 'axios';
import { globalConfig } from '../configuration/config.js';
import ToastPlugin from './toasts.ts';

import { stringify } from '@/Core.Patterns/Adapter/index.ts';
// Full config:  https://github.com/axios/axios#request-config

import { store } from '../store';

const app = createApp({});
app.use(ToastPlugin);
const appContext = app._context.config.globalProperties;

const access_token = localStorage.getItem('access_token');
axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

const axiosInstance = axios.create();

axiosInstance.defaults.paramsSerializer = (params) => {
  return stringify(params);
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const apiType = config.headers['X-API-Type'];
    delete config.headers['X-API-Type'];

    if (apiType === 'analytics') {
      config.baseURL = globalConfig.get().DYNO_ANALYTICS_API_URL;
    } else if (apiType === 'sensor') {
      config.baseURL = globalConfig.get().DYNO_SENSOR_API_URL;
    } else {
      config.baseURL = globalConfig.get().DYNO_CORE_API_URL;
    }

    return config;
  },
  (error) => {
    const options = {
      title: 'Error',
      message: `Error: ${error.message} - Status code: ${error.response.status}`,
      status: 'error',
    };
    appContext.$toast.show(options);

    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch('session/logout');
    }
    const options = {
      title: 'Error',
      message: `<strong>Error:</strong> ${error.message} <br />
      Please try again. If the problem persists, please contact support and paste the data below. <br /><br /><br />
      <span>
          <strong>You can copy the data below</strong>
      </span> <br /><br />
      <strong>Request URL:</strong> ${error.request.responseURL} <br />
      <strong>Status Code:</strong> ${error.response.status} <br />
      <strong>Error:</strong> ${error.message} <br />
      <strong>User Client:</strong> ${navigator.userAgent} <br />
      <strong>Date:</strong> ${new Date()}`,
      status: 'error',
    };
    appContext.$toast.show(options);

    return Promise.reject(error.response?.data);
  }
);

const myPlugin = {
  install(app) {
    app.axios = axiosInstance;
    window.axios = axiosInstance;
  },
};

app.use(myPlugin);

export default myPlugin;
