<!-- eslint-disable vue/valid-v-for -->
<template>
  <v-sheet flat border class="pa-4 col-sm-12">
    <p class="text-subtitle-1 pt-3 text-primary mb-3">
      <span class="font-weight-bold">Indoor Air Quality</span>
      - Input values at execution time
    </p>
    <v-row>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.CurrentDateTime"
          :rules="[isDate]"
          variant="outlined"
          density="compact"
          label="Current Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.ReadingDateTime"
          :rules="[isDate]"
          variant="outlined"
          density="compact"
          label="Reading Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.DelayInMinutes"
          :rules="[isNumberGreaterThanZero]"
          variant="outlined"
          density="compact"
          label="Delay in Minutes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-select
          v-model="localInputData.DeviceId"
          :items="devices"
          :item-title="(item) => item.Name"
          :item-value="(item) => item.Id"
          :rules="[hasValue]"
          label="Monitor Friendly Name"
          variant="outlined"
          density="compact"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.PM05"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Pm0.5 (ug/m3)"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.PM1"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Pm1.0 (ug/m3)"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.PM25"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Pm2.5 (ug/m3)"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.PM10"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Pm10.0 (ug/m3)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.VOCAQI"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="VOC AQI"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.CO2"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="CO2 (ppm)"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.AQIIndex"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="AQI Index"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.Temperature"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Temperature (°C)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.RelativeHumidity"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Relative Humidity (%)"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.PM05AverageLast30"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Average of Pm0.5 in the last 30 minutes"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.PM10AverageLast30"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Average of Pm1.0 in the last 30 minutes"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.PM25AverageLast30"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Average of Pm2.5 in the last 30 minutes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.PM100AverageLast30"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Average of Pm10.0 in the last 30 minutes"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.VOCAQIAverageLast30"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Average of VOC AQI in the last 30 minutes"
        />
      </v-col>
      <v-col sm="3">
        <v-text-field
          v-model="localInputData.CO2AverageLast30"
          :rules="[isNumber]"
          variant="outlined"
          density="compact"
          label="Average of CO2 in the last 30 minutes"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import {
  isNumber,
  isNumberGreaterThanZero,
  hasValue,
  isDate,
} from '@/Core.Patterns/Factory/validationFactory.ts';

export default {
  name: 'IndoorAirQualityInput',

  data() {
    return {
      devices: [],
      localInputData: { ...this.InputData },
    };
  },

  props: {
    InputData: {
      type: Object,
      required: true,
    },
    DataSources: {
      type: Array,
      required: true,
    },
  },

  watch: {
    DataSources: {
      handler() {
        if (this.DataSources.length) {
          this.devices = this.DataSources.find(
            (d) => d.Key == 'PieraSensors'
          )?.Value;
        }
      },
      immediate: true,
    },

    InputData: {
      handler(newVal) {
        this.localInputData = { ...newVal };
      },
      deep: true,
    },

    localInputData: {
      handler() {
        this.$emit('update:model', this.localInputData);
      },
      deep: true,
    },
  },

  methods: {
    isNumber,
    isNumberGreaterThanZero,
    hasValue,
    isDate,
  },
};
</script>
