const apiVersion = 'v1';

const getThermostatLatestUsage = async (id: number) => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/Thermostats/usage/latest`,
      {
        params: { id },
      }
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteThermostat = async (thermostatId: number) => {
  try {
    await window.axios.delete(
      `/${apiVersion}/Thermostat/delete/${thermostatId}`
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

const setThermostatData = async (thermostatData: number) => {
  try {
    const response = await window.axios.put(
      `/${apiVersion}/Thermostat/set`,
      thermostatData
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetThermostatSettingsMessages = async (thermostatId: number) => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/ViewSupport/ThermostatSettingsMessage/${thermostatId}`
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getThermostatLatestUsage,
  deleteThermostat,
  setThermostatData,
  GetThermostatSettingsMessages,
};
