<template>
  <svg
    width="154"
    height="120"
    viewBox="0 0 154 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M147.68 116.35H45.42" stroke="black" stroke-width="0.5" />
    <path d="M142.4 78.95H119.97" stroke="black" stroke-width="0.5" />
    <path d="M118.72 78.95H113.32" stroke="black" stroke-width="0.5" />
    <path d="M104.35 78.95H82.16" stroke="black" stroke-width="0.5" />
    <path d="M73.18 78.95H69.77" stroke="black" stroke-width="0.5" />
    <path d="M60.7899 78.95H39.6899" stroke="black" stroke-width="0.5" />
    <path d="M96.28 63.49H94.14" stroke="black" stroke-width="0.5" />
    <path d="M85.16 63.49H56.01" stroke="black" stroke-width="0.5" />
    <path d="M95.03 62.24H93.7" stroke="black" stroke-width="0.5" />
    <path d="M85.16 62.24H57.26" stroke="black" stroke-width="0.5" />
    <path d="M142.4 80.2H113.32" stroke="black" stroke-width="0.5" />
    <path d="M104.78 80.2H103.26" stroke="black" stroke-width="0.5" />
    <path d="M102.01 80.2H82.16" stroke="black" stroke-width="0.5" />
    <path d="M73.62 80.2H72.1" stroke="black" stroke-width="0.5" />
    <path d="M70.85 80.2H69.33" stroke="black" stroke-width="0.5" />
    <path d="M60.7899 80.2H40.9299" stroke="black" stroke-width="0.5" />
    <path d="M139.87 117.59H95.03" stroke="black" stroke-width="0.5" />
    <path d="M95.0301 117.59H49.9" stroke="black" stroke-width="0.5" />
    <path d="M144.36 115.1H139.87" stroke="black" stroke-width="0.5" />
    <path d="M139.87 118.84V117.59" stroke="black" stroke-width="0.5" />
    <path d="M145.85 118.84V117.59" stroke="black" stroke-width="0.5" />
    <path d="M139.87 118.84H145.85" stroke="black" stroke-width="0.5" />
    <path d="M49.9 118.84V117.59" stroke="black" stroke-width="0.5" />
    <path d="M139.87 116.35V115.1" stroke="black" stroke-width="0.5" />
    <path d="M43.92 118.84H49.9" stroke="black" stroke-width="0.5" />
    <path d="M43.92 118.84V117.59" stroke="black" stroke-width="0.5" />
    <path d="M43.92 117.59H40.9299" stroke="black" stroke-width="0.5" />
    <path d="M5.53003 116.35H40.93" stroke="black" stroke-width="0.5" />
    <path d="M45.42 116.35H40.9299" stroke="black" stroke-width="0.5" />
    <path d="M4.28003 117.59H40.93" stroke="black" stroke-width="0.5" />
    <path d="M143.4 18.8301V115.1" stroke="black" stroke-width="0.5" />
    <path d="M142.4 18.8301V52.9901" stroke="black" stroke-width="0.5" />
    <path d="M142.4 54.23V78.95" stroke="black" stroke-width="0.5" />
    <path d="M142.4 80.2V115.1" stroke="black" stroke-width="0.5" />
    <path d="M102.01 80.2V116.35" stroke="black" stroke-width="0.5" />
    <path d="M103.26 80.2V116.35" stroke="black" stroke-width="0.5" />
    <path d="M119.97 54.23V55.65" stroke="black" stroke-width="0.5" />
    <path d="M119.97 64.1899V78.9499" stroke="black" stroke-width="0.5" />
    <path d="M118.72 54.23V55.39" stroke="black" stroke-width="0.5" />
    <path d="M118.72 64.1899V78.9499" stroke="black" stroke-width="0.5" />
    <path d="M96.28 18.8301V52.9901" stroke="black" stroke-width="0.5" />
    <path d="M96.28 54.23V63.49" stroke="black" stroke-width="0.5" />
    <path d="M95.03 18.8301V62.2401" stroke="black" stroke-width="0.5" />
    <path d="M150.26 18.8301V15.8401" stroke="black" stroke-width="0.5" />
    <path d="M150.26 15.8401H153" stroke="black" stroke-width="0.5" />
    <path d="M153 15.8401V13.8401" stroke="black" stroke-width="0.5" />
    <path d="M11.2 0.72998V3.37999" stroke="black" stroke-width="0.5" />
    <path d="M11.2 3.40991L20.6099 4.76991" stroke="black" stroke-width="0.5" />
    <path d="M11.2 0.350098L20.0499 1.6601" stroke="black" stroke-width="0.5" />
    <path d="M20.61 4.77002L29.09 6.82002" stroke="black" stroke-width="0.5" />
    <path d="M25 3.91992L29.56 5.01993" stroke="black" stroke-width="0.5" />
    <path d="M25.3 2.70996L29.8801 3.80995" stroke="black" stroke-width="0.5" />
    <path d="M25.4 2.21997L30.01 3.32997" stroke="black" stroke-width="0.5" />
    <path d="M29.09 6.82005L29.62 5.04004" stroke="black" stroke-width="0.5" />
    <path d="M29.62 5.04004L39.59 7.98004" stroke="black" stroke-width="0.5" />
    <path d="M29.88 3.81006L40.03 6.81006" stroke="black" stroke-width="0.5" />
    <path d="M30.01 3.33008L40.19 6.33008" stroke="black" stroke-width="0.5" />
    <path
      d="M39.5901 7.97998L39.0701 9.74998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M39.0701 9.75L42.55 10.96" stroke="black" stroke-width="0.5" />
    <path d="M39.59 7.97998L49.78 11.53" stroke="black" stroke-width="0.5" />
    <path d="M40.03 6.81006L51.03 10.6401" stroke="black" stroke-width="0.5" />
    <path
      d="M40.1899 6.33008L51.5299 10.2901"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M42.55 10.96V18.83" stroke="black" stroke-width="0.5" />
    <path d="M44.05 9.53003V16.83" stroke="black" stroke-width="0.5" />
    <path d="M49.78 11.53V14.34" stroke="black" stroke-width="0.5" />
    <path d="M51.03 10.6399V14.3399" stroke="black" stroke-width="0.5" />
    <path d="M51.53 10.29V14.34" stroke="black" stroke-width="0.5" />
    <path d="M42.55 18.8301H57.2601" stroke="black" stroke-width="0.5" />
    <path d="M132.56 18.8301H147.68" stroke="black" stroke-width="0.5" />
    <path d="M132.56 17.0801H148.76" stroke="black" stroke-width="0.5" />
    <path d="M44.05 14.3401H138.04" stroke="black" stroke-width="0.5" />
    <path d="M147.68 18.8301H150.26" stroke="black" stroke-width="0.5" />
    <path d="M148.76 17.0801V13.8401" stroke="black" stroke-width="0.5" />
    <path d="M138.04 11.3501H153" stroke="black" stroke-width="0.5" />
    <path d="M57.2601 16.8301H44.05" stroke="black" stroke-width="0.5" />
    <path d="M91.79 18.8301H94.91" stroke="black" stroke-width="0.5" />
    <path d="M84.6899 18.8301H105.13" stroke="black" stroke-width="0.5" />
    <path d="M153 13.8401V11.3501" stroke="black" stroke-width="0.5" />
    <path d="M138.04 14.3401V11.3501" stroke="black" stroke-width="0.5" />
    <path d="M153 13.8401H148.76" stroke="black" stroke-width="0.5" />
    <path d="M105.13 17.3401H84.6899" stroke="black" stroke-width="0.5" />
    <path d="M11.2 0.72998H10.28" stroke="black" stroke-width="0.5" />
    <path d="M10.28 0.72998V1.26999" stroke="black" stroke-width="0.5" />
    <path d="M10.28 1.72998V3.40999" stroke="black" stroke-width="0.5" />
    <path d="M10.28 3.40991H11.2" stroke="black" stroke-width="0.5" />
    <path d="M10.28 1.73001V1.27002" stroke="black" stroke-width="0.5" />
    <path d="M20.05 1.65991L25.3 2.70992" stroke="black" stroke-width="0.5" />
    <path d="M20.05 1.1499L25.4001 2.21991" stroke="black" stroke-width="0.5" />
    <path d="M11.2 1.61011L19.7599 2.8701" stroke="black" stroke-width="0.5" />
    <path d="M19.76 2.87012L25 3.92012" stroke="black" stroke-width="0.5" />
    <path d="M24.53 3.28003H26.42" stroke="black" stroke-width="0.5" />
    <path d="M26.42 3.28003V4.94003" stroke="black" stroke-width="0.5" />
    <path d="M26.42 4.93994H24.53" stroke="black" stroke-width="0.5" />
    <path d="M24.53 4.94003V3.28003" stroke="black" stroke-width="0.5" />
    <path d="M29.62 5.0401L30.12 3.36011" stroke="black" stroke-width="0.5" />
    <path d="M40.09 6.30005L39.59 7.98004" stroke="black" stroke-width="0.5" />
    <path d="M39.47 6.63989L39.11 7.83989" stroke="black" stroke-width="0.5" />
    <path d="M29.09 6.82007L39.07 9.75006" stroke="black" stroke-width="0.5" />
    <path d="M11.2 3.40999V0.72998" stroke="black" stroke-width="0.5" />
    <path d="M1.12 25.5801H10.28" stroke="black" stroke-width="0.5" />
    <path d="M1.12 24.3301H10.28" stroke="black" stroke-width="0.5" />
    <path d="M1.72998 24.3301V25.5801" stroke="black" stroke-width="0.5" />
    <path d="M1.73001 24.3301H1.12" stroke="black" stroke-width="0.5" />
    <path d="M1.12 24.3301V25.5801" stroke="black" stroke-width="0.5" />
    <path d="M153 11.3501V15.8401" stroke="black" stroke-width="0.5" />
    <path d="M132.56 16.8301H105.13" stroke="black" stroke-width="0.5" />
    <path d="M132.56 15.5801H105.13" stroke="black" stroke-width="0.5" />
    <path d="M132.56 18.8301H105.13" stroke="black" stroke-width="0.5" />
    <path d="M105.63 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M108.98 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M109.48 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M127.95 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M128.44 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M132.06 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M132.56 14.3401H105.13" stroke="black" stroke-width="0.5" />
    <path d="M132.56 14.3401V18.8301" stroke="black" stroke-width="0.5" />
    <path d="M105.13 14.3401V18.8301" stroke="black" stroke-width="0.5" />
    <path d="M84.69 16.8301H57.26" stroke="black" stroke-width="0.5" />
    <path d="M84.69 15.5801H57.26" stroke="black" stroke-width="0.5" />
    <path d="M84.69 18.8301H57.26" stroke="black" stroke-width="0.5" />
    <path d="M57.76 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M61.11 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M61.61 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M80.08 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M80.5701 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M84.1899 16.8301V15.5801" stroke="black" stroke-width="0.5" />
    <path d="M84.69 14.3401H57.26" stroke="black" stroke-width="0.5" />
    <path d="M84.6899 14.3401V18.8301" stroke="black" stroke-width="0.5" />
    <path d="M57.26 14.3401V18.8301" stroke="black" stroke-width="0.5" />
    <path d="M5.53003 26.8301V42.2701" stroke="black" stroke-width="0.5" />
    <path d="M5.53003 43.51V52.94" stroke="black" stroke-width="0.5" />
    <path d="M5.53003 52.9399H13.26" stroke="black" stroke-width="0.5" />
    <path d="M14.51 52.9399V64.2499" stroke="black" stroke-width="0.5" />
    <path d="M14.51 73.23V84.1" stroke="black" stroke-width="0.5" />
    <path d="M14.51 85.3501H5.53003" stroke="black" stroke-width="0.5" />
    <path d="M13.26 84.1001H4.28003" stroke="black" stroke-width="0.5" />
    <path d="M5.53003 85.3501V116.35" stroke="black" stroke-width="0.5" />
    <path d="M39.6899 78.95V84.1" stroke="black" stroke-width="0.5" />
    <path d="M39.6899 85.3501V116.35" stroke="black" stroke-width="0.5" />
    <path d="M70.85 80.2V116.35" stroke="black" stroke-width="0.5" />
    <path d="M72.1 80.2V116.35" stroke="black" stroke-width="0.5" />
    <path d="M40.9299 80.2V116.35" stroke="black" stroke-width="0.5" />
    <path d="M4.28003 84.1001V117.59" stroke="black" stroke-width="0.5" />
    <path d="M4.28003 25.5801V54.1901" stroke="black" stroke-width="0.5" />
    <path d="M4.28003 54.1899H13.26" stroke="black" stroke-width="0.5" />
    <path d="M13.26 54.1899V64.2499" stroke="black" stroke-width="0.5" />
    <path d="M13.26 72.79V84.1001" stroke="black" stroke-width="0.5" />
    <path d="M13.26 73.23H14.51" stroke="black" stroke-width="0.5" />
    <path d="M13.26 64.25L11.51 64.42" stroke="black" stroke-width="0.5" />
    <path
      d="M11.51 64.4199L9.82996 64.9299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M9.83002 64.9299L8.28003 65.7599"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M8.28003 65.76L6.92004 66.88" stroke="black" stroke-width="0.5" />
    <path
      d="M6.92007 66.8799L5.80005 68.2399"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M5.79996 68.24L4.96997 69.79" stroke="black" stroke-width="0.5" />
    <path d="M4.96997 69.79L4.45996 71.48" stroke="black" stroke-width="0.5" />
    <path d="M4.46002 71.48L4.29004 73.23" stroke="black" stroke-width="0.5" />
    <path d="M13.26 73.23V72.79" stroke="black" stroke-width="0.5" />
    <path d="M13.2601 72.79H4.30005" stroke="black" stroke-width="0.5" />
    <path d="M4.29004 73.23H13.26" stroke="black" stroke-width="0.5" />
    <path d="M13.26 64.25H14.51" stroke="black" stroke-width="0.5" />
    <path d="M11.53 3.58008V26.8301" stroke="black" stroke-width="0.5" />
    <path d="M11.53 3.45996V26.83" stroke="black" stroke-width="0.5" />
    <path d="M10.28 25.5799V3.40991" stroke="black" stroke-width="0.5" />
    <path d="M62.5601 116.35V117.59" stroke="black" stroke-width="0.5" />
    <path d="M17.42 4.30005V5.68004" stroke="black" stroke-width="0.5" />
    <path d="M18.66 4.47998V6.91998" stroke="black" stroke-width="0.5" />
    <path d="M17.42 5.67993H11.53" stroke="black" stroke-width="0.5" />
    <path d="M18.66 6.91992H11.53" stroke="black" stroke-width="0.5" />
    <path d="M144.36 116.35V115.1" stroke="black" stroke-width="0.5" />
    <path d="M44.96 116.71H48.23" stroke="black" stroke-width="0.5" />
    <path d="M48.23 118.24V115.17" stroke="black" stroke-width="0.5" />
    <path d="M44.96 118.24V115.17" stroke="black" stroke-width="0.5" />
    <path d="M140.75 116.71H144.02" stroke="black" stroke-width="0.5" />
    <path d="M144.02 118.24V115.17" stroke="black" stroke-width="0.5" />
    <path d="M140.75 118.24V115.17" stroke="black" stroke-width="0.5" />
    <path d="M5.53003 26.8301H11.53" stroke="black" stroke-width="0.5" />
    <path d="M56.01 42.27H25.1" stroke="black" stroke-width="0.5" />
    <path d="M16.12 42.27H5.53003" stroke="black" stroke-width="0.5" />
    <path d="M56.01 43.51H25.1" stroke="black" stroke-width="0.5" />
    <path d="M16.12 43.51H14.51" stroke="black" stroke-width="0.5" />
    <path d="M13.26 43.51H5.53003" stroke="black" stroke-width="0.5" />
    <path d="M32.46 54.48H29.46" stroke="black" stroke-width="0.5" />
    <path d="M142.4 52.99H106.52" stroke="black" stroke-width="0.5" />
    <path d="M97.98 52.99H96.28" stroke="black" stroke-width="0.5" />
    <path d="M142.4 54.23H119.97" stroke="black" stroke-width="0.5" />
    <path d="M118.72 54.23H106.52" stroke="black" stroke-width="0.5" />
    <path d="M97.55 54.23H96.28" stroke="black" stroke-width="0.5" />
    <path d="M57.26 18.8301V62.2401" stroke="black" stroke-width="0.5" />
    <path d="M56.01 18.8301V42.2701" stroke="black" stroke-width="0.5" />
    <path d="M56.01 43.51V63.49" stroke="black" stroke-width="0.5" />
    <path d="M14.51 52.94V43.51" stroke="black" stroke-width="0.5" />
    <path d="M29.46 64.36V54.48" stroke="black" stroke-width="0.5" />
    <path d="M30.34 63.4899V62.4199" stroke="black" stroke-width="0.5" />
    <path d="M30.34 61.7301V60.3601" stroke="black" stroke-width="0.5" />
    <path d="M30.34 59.67V58.3" stroke="black" stroke-width="0.5" />
    <path d="M30.34 57.62V56.24" stroke="black" stroke-width="0.5" />
    <path d="M30.34 55.56V54.48" stroke="black" stroke-width="0.5" />
    <path d="M32.46 61.37V54.48" stroke="black" stroke-width="0.5" />
    <path d="M31.58 62.24V60.76" stroke="black" stroke-width="0.5" />
    <path d="M31.58 60.08V58.71" stroke="black" stroke-width="0.5" />
    <path d="M31.58 58.0199V56.6499" stroke="black" stroke-width="0.5" />
    <path d="M31.58 55.96V54.48" stroke="black" stroke-width="0.5" />
    <path d="M13.26 52.94V43.51" stroke="black" stroke-width="0.5" />
    <path d="M56.01 63.49H54.83" stroke="black" stroke-width="0.5" />
    <path d="M54.15 63.49H52.77" stroke="black" stroke-width="0.5" />
    <path d="M52.09 63.49H50.72" stroke="black" stroke-width="0.5" />
    <path d="M50.03 63.49H48.66" stroke="black" stroke-width="0.5" />
    <path d="M47.98 63.49H46.6" stroke="black" stroke-width="0.5" />
    <path d="M45.9201 63.49H44.55" stroke="black" stroke-width="0.5" />
    <path d="M43.86 63.49H42.49" stroke="black" stroke-width="0.5" />
    <path d="M41.7999 63.49H40.4299" stroke="black" stroke-width="0.5" />
    <path d="M39.75 63.49H38.38" stroke="black" stroke-width="0.5" />
    <path d="M37.6901 63.49H36.3201" stroke="black" stroke-width="0.5" />
    <path d="M35.63 63.49H34.26" stroke="black" stroke-width="0.5" />
    <path d="M33.58 63.49H32.21" stroke="black" stroke-width="0.5" />
    <path d="M31.52 63.49H30.34" stroke="black" stroke-width="0.5" />
    <path d="M56.01 64.3601H29.46" stroke="black" stroke-width="0.5" />
    <path d="M56.0099 62.24H54.4299" stroke="black" stroke-width="0.5" />
    <path d="M53.74 62.24H52.37" stroke="black" stroke-width="0.5" />
    <path d="M51.6801 62.24H50.3101" stroke="black" stroke-width="0.5" />
    <path d="M49.63 62.24H48.26" stroke="black" stroke-width="0.5" />
    <path d="M47.5699 62.24H46.2" stroke="black" stroke-width="0.5" />
    <path d="M45.51 62.24H44.14" stroke="black" stroke-width="0.5" />
    <path d="M43.46 62.24H42.08" stroke="black" stroke-width="0.5" />
    <path d="M41.4 62.24H40.03" stroke="black" stroke-width="0.5" />
    <path d="M39.34 62.24H37.97" stroke="black" stroke-width="0.5" />
    <path d="M37.29 62.24H35.91" stroke="black" stroke-width="0.5" />
    <path d="M35.23 62.24H33.86" stroke="black" stroke-width="0.5" />
    <path d="M33.17 62.24H31.58" stroke="black" stroke-width="0.5" />
    <path d="M56.01 61.3701H32.46" stroke="black" stroke-width="0.5" />
    <path d="M56.01 64.36V63.49" stroke="black" stroke-width="0.5" />
    <path d="M14.51 85.3501H29.63" stroke="black" stroke-width="0.5" />
    <path d="M38.16 85.3501H39.69" stroke="black" stroke-width="0.5" />
    <path d="M14.51 84.1001H29.63" stroke="black" stroke-width="0.5" />
    <path d="M38.6 84.1001H39.69" stroke="black" stroke-width="0.5" />
    <path d="M14.51 43.51H16.12" stroke="black" stroke-width="0.5" />
    <path d="M16.12 43.51V42.27" stroke="black" stroke-width="0.5" />
    <path d="M25.1 43.51V42.27" stroke="black" stroke-width="0.5" />
    <path d="M25.0999 42.27L24.9299 40.52" stroke="black" stroke-width="0.5" />
    <path d="M24.93 40.5201L24.41 38.8301" stroke="black" stroke-width="0.5" />
    <path d="M24.41 38.83L23.58 37.28" stroke="black" stroke-width="0.5" />
    <path d="M23.58 37.2799L22.47 35.9199" stroke="black" stroke-width="0.5" />
    <path d="M22.47 35.92L21.11 34.8" stroke="black" stroke-width="0.5" />
    <path d="M21.11 34.8L19.5601 33.98" stroke="black" stroke-width="0.5" />
    <path d="M19.56 33.9799L17.87 33.46" stroke="black" stroke-width="0.5" />
    <path d="M17.87 33.4601L16.12 33.29" stroke="black" stroke-width="0.5" />
    <path d="M16.12 42.27H16.56" stroke="black" stroke-width="0.5" />
    <path d="M16.5601 42.2701V33.3" stroke="black" stroke-width="0.5" />
    <path d="M16.12 33.29V42.2701" stroke="black" stroke-width="0.5" />
    <path d="M85.16 62.24L85.34 60.49" stroke="black" stroke-width="0.5" />
    <path d="M85.34 60.4901L85.85 58.8101" stroke="black" stroke-width="0.5" />
    <path d="M85.85 58.81L86.68 57.26" stroke="black" stroke-width="0.5" />
    <path
      d="M86.6799 57.2599L87.7899 55.8999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M87.79 55.9L89.1501 54.78" stroke="black" stroke-width="0.5" />
    <path d="M89.15 54.78L90.7 53.95" stroke="black" stroke-width="0.5" />
    <path d="M90.7 53.9499L92.39 53.4399" stroke="black" stroke-width="0.5" />
    <path d="M92.39 53.44L94.14 53.27" stroke="black" stroke-width="0.5" />
    <path d="M94.14 62.24H93.7" stroke="black" stroke-width="0.5" />
    <path d="M93.7 62.24V53.28" stroke="black" stroke-width="0.5" />
    <path d="M94.14 53.27V62.24" stroke="black" stroke-width="0.5" />
    <path d="M94.14 62.24V63.49" stroke="black" stroke-width="0.5" />
    <path d="M94.64 62.24V63.49" stroke="black" stroke-width="0.5" />
    <path d="M85.16 62.24V63.49" stroke="black" stroke-width="0.5" />
    <path d="M84.67 62.24V63.49" stroke="black" stroke-width="0.5" />
    <path d="M95.03 62.24H94.14" stroke="black" stroke-width="0.5" />
    <path d="M96.28 52.99H97.98" stroke="black" stroke-width="0.5" />
    <path d="M106.52 52.99L106.35 51.24" stroke="black" stroke-width="0.5" />
    <path d="M106.35 51.2401L105.84 49.55" stroke="black" stroke-width="0.5" />
    <path d="M105.84 49.55L105.01 48" stroke="black" stroke-width="0.5" />
    <path
      d="M105.01 47.9999L103.89 46.6399"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M103.89 46.64L102.53 45.53" stroke="black" stroke-width="0.5" />
    <path d="M102.53 45.53L100.98 44.7" stroke="black" stroke-width="0.5" />
    <path d="M100.98 44.6999L99.3 44.1899" stroke="black" stroke-width="0.5" />
    <path d="M99.3 44.19L97.55 44.01" stroke="black" stroke-width="0.5" />
    <path d="M97.55 52.99H97.98" stroke="black" stroke-width="0.5" />
    <path d="M97.98 52.99V44.02" stroke="black" stroke-width="0.5" />
    <path d="M97.55 44.01V52.99" stroke="black" stroke-width="0.5" />
    <path d="M97.55 52.99V54.23" stroke="black" stroke-width="0.5" />
    <path d="M97.05 52.99V54.23" stroke="black" stroke-width="0.5" />
    <path d="M106.52 52.99V54.23" stroke="black" stroke-width="0.5" />
    <path d="M107.02 52.99V54.23" stroke="black" stroke-width="0.5" />
    <path d="M73.62 80.2H72.1" stroke="black" stroke-width="0.5" />
    <path d="M82.16 80.2L81.99 81.95" stroke="black" stroke-width="0.5" />
    <path d="M81.99 81.95L81.48 83.63" stroke="black" stroke-width="0.5" />
    <path d="M81.48 83.6299L80.65 85.1799" stroke="black" stroke-width="0.5" />
    <path d="M80.65 85.1799L79.53 86.5399" stroke="black" stroke-width="0.5" />
    <path d="M79.53 86.54L78.17 87.66" stroke="black" stroke-width="0.5" />
    <path d="M78.17 87.6599L76.62 88.4899" stroke="black" stroke-width="0.5" />
    <path d="M76.6199 88.49L74.9399 89" stroke="black" stroke-width="0.5" />
    <path d="M74.9399 89L73.1799 89.17" stroke="black" stroke-width="0.5" />
    <path d="M73.1799 80.2H73.6199" stroke="black" stroke-width="0.5" />
    <path d="M73.62 80.2V89.16" stroke="black" stroke-width="0.5" />
    <path d="M73.1799 89.17V80.2" stroke="black" stroke-width="0.5" />
    <path d="M73.1799 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M72.6899 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M82.16 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M82.66 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M104.78 80.2H103.26" stroke="black" stroke-width="0.5" />
    <path d="M113.32 80.2L113.15 81.95" stroke="black" stroke-width="0.5" />
    <path d="M113.15 81.95L112.64 83.63" stroke="black" stroke-width="0.5" />
    <path
      d="M112.64 83.6299L111.81 85.1799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M111.81 85.1799L110.69 86.5399"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M110.69 86.54L109.33 87.66" stroke="black" stroke-width="0.5" />
    <path
      d="M109.33 87.6599L107.78 88.4899"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M107.78 88.49L106.1 89" stroke="black" stroke-width="0.5" />
    <path d="M106.1 89L104.35 89.17" stroke="black" stroke-width="0.5" />
    <path d="M104.35 80.2H104.78" stroke="black" stroke-width="0.5" />
    <path d="M104.78 80.2V89.16" stroke="black" stroke-width="0.5" />
    <path d="M104.35 89.17V80.2" stroke="black" stroke-width="0.5" />
    <path d="M104.35 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M103.85 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M113.32 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M113.82 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M60.79 80.2L60.96 81.95" stroke="black" stroke-width="0.5" />
    <path d="M60.96 81.95L61.47 83.63" stroke="black" stroke-width="0.5" />
    <path d="M61.47 83.6299L62.3 85.1799" stroke="black" stroke-width="0.5" />
    <path d="M62.3 85.1799L63.4201 86.5399" stroke="black" stroke-width="0.5" />
    <path d="M63.42 86.54L64.78 87.66" stroke="black" stroke-width="0.5" />
    <path d="M64.78 87.6599L66.33 88.4899" stroke="black" stroke-width="0.5" />
    <path d="M66.33 88.49L68.02 89" stroke="black" stroke-width="0.5" />
    <path d="M68.02 89L69.77 89.17" stroke="black" stroke-width="0.5" />
    <path d="M69.77 80.2H69.33" stroke="black" stroke-width="0.5" />
    <path d="M69.33 80.2V89.16" stroke="black" stroke-width="0.5" />
    <path d="M69.77 89.17V80.2" stroke="black" stroke-width="0.5" />
    <path d="M69.77 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M70.26 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M60.79 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M60.29 80.2V78.95" stroke="black" stroke-width="0.5" />
    <path d="M39.69 85.3501H38.16" stroke="black" stroke-width="0.5" />
    <path d="M29.63 85.3501L29.8 87.1001" stroke="black" stroke-width="0.5" />
    <path d="M29.8 87.1001L30.3101 88.7901" stroke="black" stroke-width="0.5" />
    <path d="M30.3101 88.79L31.1401 90.34" stroke="black" stroke-width="0.5" />
    <path d="M31.14 90.3401L32.25 91.7001" stroke="black" stroke-width="0.5" />
    <path d="M32.25 91.7L33.61 92.81" stroke="black" stroke-width="0.5" />
    <path d="M33.61 92.8101L35.17 93.6401" stroke="black" stroke-width="0.5" />
    <path d="M35.17 93.6399L36.85 94.1499" stroke="black" stroke-width="0.5" />
    <path d="M36.85 94.1499L38.6 94.3299" stroke="black" stroke-width="0.5" />
    <path d="M38.6 85.3501H38.16" stroke="black" stroke-width="0.5" />
    <path d="M38.16 85.3501V94.3201" stroke="black" stroke-width="0.5" />
    <path d="M38.6 94.3301V85.3501" stroke="black" stroke-width="0.5" />
    <path d="M38.6 85.3501V84.1001" stroke="black" stroke-width="0.5" />
    <path d="M39.1 85.3501V84.1001" stroke="black" stroke-width="0.5" />
    <path d="M29.63 85.3501V84.1001" stroke="black" stroke-width="0.5" />
    <path d="M29.13 85.3501V84.1001" stroke="black" stroke-width="0.5" />
    <path d="M119.97 54.23V55.39" stroke="black" stroke-width="0.5" />
    <path d="M119.97 55.3899H118.72" stroke="black" stroke-width="0.5" />
    <path d="M119.97 54.8899H118.72" stroke="black" stroke-width="0.5" />
    <path d="M119.97 64.19L121.72 64.02" stroke="black" stroke-width="0.5" />
    <path d="M121.72 64.02L123.4 63.51" stroke="black" stroke-width="0.5" />
    <path d="M123.4 63.5099L124.95 62.6799" stroke="black" stroke-width="0.5" />
    <path
      d="M124.95 62.6801L126.31 61.5601"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M126.31 61.56L127.43 60.2" stroke="black" stroke-width="0.5" />
    <path
      d="M127.43 60.1999L128.26 58.6499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M128.26 58.6499L128.77 56.96" stroke="black" stroke-width="0.5" />
    <path d="M128.77 56.96L128.94 55.21" stroke="black" stroke-width="0.5" />
    <path d="M119.97 55.21V55.6499" stroke="black" stroke-width="0.5" />
    <path d="M119.97 55.6499H128.93" stroke="black" stroke-width="0.5" />
    <path d="M128.94 55.21H119.97" stroke="black" stroke-width="0.5" />
    <path d="M119.97 64.1899H118.72" stroke="black" stroke-width="0.5" />
    <path d="M119.97 64.6899H118.72" stroke="black" stroke-width="0.5" />
    <path d="M142.4 76.9299V77.9699" stroke="black" stroke-width="0.5" />
    <path d="M142.4 77.97H142.24" stroke="black" stroke-width="0.5" />
    <path d="M142.24 77.9699V76.9299" stroke="black" stroke-width="0.5" />
    <path d="M142.24 76.9299H142.4" stroke="black" stroke-width="0.5" />
    <path d="M142.4 69V67.96" stroke="black" stroke-width="0.5" />
    <path d="M142.4 67.96H142.24" stroke="black" stroke-width="0.5" />
    <path d="M142.24 67.96V69" stroke="black" stroke-width="0.5" />
    <path d="M142.24 69H142.4" stroke="black" stroke-width="0.5" />
    <path d="M129.46 78.95H128.42" stroke="black" stroke-width="0.5" />
    <path d="M128.42 78.95V78.79" stroke="black" stroke-width="0.5" />
    <path d="M128.42 78.79H129.46" stroke="black" stroke-width="0.5" />
    <path d="M129.46 78.79V78.95" stroke="black" stroke-width="0.5" />
    <path d="M138.89 78.95H139.93" stroke="black" stroke-width="0.5" />
    <path d="M139.93 78.95V78.79" stroke="black" stroke-width="0.5" />
    <path d="M139.93 78.79H138.89" stroke="black" stroke-width="0.5" />
    <path d="M138.89 78.79V78.95" stroke="black" stroke-width="0.5" />
    <path d="M141.85 73.1299V77.2299" stroke="black" stroke-width="0.5" />
    <path d="M141.85 77.23L141.86 77.26" stroke="black" stroke-width="0.5" />
    <path d="M141.86 77.26L141.89 77.28" stroke="black" stroke-width="0.5" />
    <path d="M141.89 77.28L141.92 77.29" stroke="black" stroke-width="0.5" />
    <path d="M141.92 77.29H142.24" stroke="black" stroke-width="0.5" />
    <path d="M142.24 77.29V77.62" stroke="black" stroke-width="0.5" />
    <path d="M142.24 77.6201H141.85" stroke="black" stroke-width="0.5" />
    <path
      d="M141.85 77.6201L141.69 77.5701"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.69 77.57L141.57 77.45" stroke="black" stroke-width="0.5" />
    <path d="M141.57 77.45L141.53 77.29" stroke="black" stroke-width="0.5" />
    <path d="M141.53 77.2899V73.1299" stroke="black" stroke-width="0.5" />
    <path d="M141.53 73.1299V68.6399" stroke="black" stroke-width="0.5" />
    <path d="M141.53 68.64L141.57 68.48" stroke="black" stroke-width="0.5" />
    <path
      d="M141.57 68.4801L141.69 68.3601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.69 68.3601L141.85 68.3101"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.85 68.3101H142.24" stroke="black" stroke-width="0.5" />
    <path d="M142.24 68.3101V68.6401" stroke="black" stroke-width="0.5" />
    <path d="M142.24 68.6399H141.92" stroke="black" stroke-width="0.5" />
    <path
      d="M141.92 68.6399L141.89 68.6499"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.89 68.6499L141.86 68.6699"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.86 68.6699L141.85 68.6999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.85 68.7V73.13" stroke="black" stroke-width="0.5" />
    <path
      d="M129.17 78.3999L129.14 78.4099"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M129.14 78.4099L129.11 78.4299"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M129.11 78.4299L129.1 78.4599" stroke="black" stroke-width="0.5" />
    <path d="M129.1 78.46V78.7899" stroke="black" stroke-width="0.5" />
    <path d="M129.1 78.79H128.78" stroke="black" stroke-width="0.5" />
    <path d="M128.78 78.7899V78.3999" stroke="black" stroke-width="0.5" />
    <path d="M128.78 78.4L128.82 78.24" stroke="black" stroke-width="0.5" />
    <path
      d="M128.82 78.2401L128.94 78.1201"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M128.94 78.1201L129.1 78.0701" stroke="black" stroke-width="0.5" />
    <path d="M129.1 78.0701H139.25" stroke="black" stroke-width="0.5" />
    <path
      d="M139.25 78.0701L139.41 78.1201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.41 78.1201L139.53 78.2401"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.53 78.24L139.57 78.4" stroke="black" stroke-width="0.5" />
    <path d="M139.57 78.3999V78.7899" stroke="black" stroke-width="0.5" />
    <path d="M139.57 78.79H139.25" stroke="black" stroke-width="0.5" />
    <path d="M139.25 78.7899V78.46" stroke="black" stroke-width="0.5" />
    <path
      d="M139.25 78.4599L139.24 78.4299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.24 78.4299L139.22 78.4099"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.22 78.4099L139.18 78.3999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.18 78.3999H129.17" stroke="black" stroke-width="0.5" />
    <path
      d="M139.79 72.9401L139.27 72.8701"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.27 72.8701L138.88 72.8201"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M138.88 72.82L138.54 72.76" stroke="black" stroke-width="0.5" />
    <path d="M138.54 72.7599L138.26 72.71" stroke="black" stroke-width="0.5" />
    <path
      d="M138.26 72.7099L138.03 72.6499"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M138.03 72.6501L137.86 72.6101"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.86 72.6101L137.75 72.5801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.75 72.5801L137.65 72.5601"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M137.65 72.56L137.55 72.54" stroke="black" stroke-width="0.5" />
    <path d="M137.55 72.54L137.42 72.53" stroke="black" stroke-width="0.5" />
    <path d="M137.42 72.53L137.23 72.52" stroke="black" stroke-width="0.5" />
    <path d="M137.23 72.52H137.01" stroke="black" stroke-width="0.5" />
    <path d="M137.01 72.52L136.76 72.54" stroke="black" stroke-width="0.5" />
    <path d="M136.76 72.54L136.48 72.5701" stroke="black" stroke-width="0.5" />
    <path
      d="M136.48 72.5701L136.19 72.6401"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M136.19 72.6399L135.9 72.7299" stroke="black" stroke-width="0.5" />
    <path d="M135.9 72.73L135.62 72.85" stroke="black" stroke-width="0.5" />
    <path
      d="M135.62 72.8501L135.35 72.9901"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.35 72.99L135.12 73.16" stroke="black" stroke-width="0.5" />
    <path
      d="M135.12 73.1599L134.91 73.3599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M134.91 73.3601L134.75 73.5601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M134.75 73.5601L134.63 73.7701"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M134.63 73.77L134.55 73.98" stroke="black" stroke-width="0.5" />
    <path d="M134.55 73.98L134.53 74.18" stroke="black" stroke-width="0.5" />
    <path
      d="M134.53 74.1799L134.56 74.3899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M134.56 74.3899L134.63 74.5999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M134.63 74.6001L134.75 74.8101"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M134.75 74.8101L134.91 75.0101"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M134.91 75.01L135.12 75.2" stroke="black" stroke-width="0.5" />
    <path d="M135.12 75.2L135.35 75.38" stroke="black" stroke-width="0.5" />
    <path
      d="M135.35 75.3799L135.62 75.5199"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.62 75.52L135.9 75.64" stroke="black" stroke-width="0.5" />
    <path d="M135.9 75.6399L136.19 75.7299" stroke="black" stroke-width="0.5" />
    <path d="M136.19 75.73L136.48 75.8" stroke="black" stroke-width="0.5" />
    <path d="M136.48 75.8L136.76 75.83" stroke="black" stroke-width="0.5" />
    <path
      d="M136.76 75.8301L137.02 75.8401"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M137.02 75.8401H137.24" stroke="black" stroke-width="0.5" />
    <path d="M137.24 75.8401H137.43" stroke="black" stroke-width="0.5" />
    <path
      d="M137.43 75.8401L137.55 75.8301"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.55 75.8301L137.65 75.8101"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M137.65 75.81L137.75 75.79" stroke="black" stroke-width="0.5" />
    <path d="M137.75 75.79L137.87 75.76" stroke="black" stroke-width="0.5" />
    <path d="M137.87 75.7599L138.04 75.71" stroke="black" stroke-width="0.5" />
    <path
      d="M138.04 75.7099L138.26 75.6599"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M138.26 75.6601L138.54 75.6001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M138.54 75.6001L138.88 75.55" stroke="black" stroke-width="0.5" />
    <path d="M138.88 75.55L139.27 75.49" stroke="black" stroke-width="0.5" />
    <path d="M139.27 75.49L139.68 75.45" stroke="black" stroke-width="0.5" />
    <path d="M139.68 75.45H139.79" stroke="black" stroke-width="0.5" />
    <path
      d="M139.93 75.8799L139.94 75.9199"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.94 75.9199L139.95 75.9599"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.95 75.96L139.97 75.99" stroke="black" stroke-width="0.5" />
    <path d="M139.97 75.99L139.98 76.02" stroke="black" stroke-width="0.5" />
    <path d="M139.98 76.02L140 76.05" stroke="black" stroke-width="0.5" />
    <path d="M140 76.05L140.02 76.08" stroke="black" stroke-width="0.5" />
    <path
      d="M140.02 76.0801L140.04 76.1101"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.04 76.1101L140.07 76.1401"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.07 76.1399L140.11 76.1799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.11 76.1799L140.15 76.2299"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M140.15 76.23L140.2 76.27" stroke="black" stroke-width="0.5" />
    <path d="M140.2 76.27L140.25 76.32" stroke="black" stroke-width="0.5" />
    <path
      d="M140.25 76.3201L140.31 76.3601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.31 76.3601L140.37 76.4001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.37 76.3999L140.44 76.4399"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.44 76.4399L140.51 76.4699"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M140.51 76.47L140.58 76.5" stroke="black" stroke-width="0.5" />
    <path d="M140.58 76.5L140.65 76.52" stroke="black" stroke-width="0.5" />
    <path d="M140.65 76.52L140.72 76.53" stroke="black" stroke-width="0.5" />
    <path d="M140.72 76.53L140.79 76.54" stroke="black" stroke-width="0.5" />
    <path d="M140.79 76.54H140.86" stroke="black" stroke-width="0.5" />
    <path d="M140.86 76.54L140.93 76.55" stroke="black" stroke-width="0.5" />
    <path d="M140.93 76.55H141" stroke="black" stroke-width="0.5" />
    <path d="M141 76.55H141.07" stroke="black" stroke-width="0.5" />
    <path d="M141.07 76.55L141.13 76.54" stroke="black" stroke-width="0.5" />
    <path d="M141.13 76.54H141.19" stroke="black" stroke-width="0.5" />
    <path d="M141.19 76.54H141.25" stroke="black" stroke-width="0.5" />
    <path d="M141.25 76.54H141.31" stroke="black" stroke-width="0.5" />
    <path d="M141.31 76.54L141.36 76.53" stroke="black" stroke-width="0.5" />
    <path d="M141.36 76.53H141.41" stroke="black" stroke-width="0.5" />
    <path d="M141.41 76.53L141.45 76.52" stroke="black" stroke-width="0.5" />
    <path d="M141.45 76.52H141.5" stroke="black" stroke-width="0.5" />
    <path d="M141.5 76.52L141.54 76.51" stroke="black" stroke-width="0.5" />
    <path d="M141.54 76.51L141.57 76.5" stroke="black" stroke-width="0.5" />
    <path d="M141.57 76.5L141.61 76.49" stroke="black" stroke-width="0.5" />
    <path d="M141.61 76.49L141.64 76.47" stroke="black" stroke-width="0.5" />
    <path d="M141.64 76.47L141.67 76.45" stroke="black" stroke-width="0.5" />
    <path
      d="M141.67 76.4499L141.69 76.4299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.69 76.4299L141.72 76.3899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.72 76.3901L141.74 76.3501"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.74 76.3501L141.75 76.3" stroke="black" stroke-width="0.5" />
    <path d="M141.75 76.3L141.77 76.25" stroke="black" stroke-width="0.5" />
    <path
      d="M141.77 76.2499L141.78 76.1799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.78 76.1801L141.79 76.1001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.79 76.1L141.8 76.01" stroke="black" stroke-width="0.5" />
    <path d="M141.8 76.0099L141.81 75.8999" stroke="black" stroke-width="0.5" />
    <path d="M141.81 75.9L141.82 75.78" stroke="black" stroke-width="0.5" />
    <path
      d="M141.82 75.7799L141.83 75.6499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.83 75.65V75.5" stroke="black" stroke-width="0.5" />
    <path
      d="M141.83 75.5001L141.84 75.3401"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.84 75.3399V75.1599" stroke="black" stroke-width="0.5" />
    <path d="M141.84 75.16L141.85 74.98" stroke="black" stroke-width="0.5" />
    <path d="M141.85 74.98V74.79" stroke="black" stroke-width="0.5" />
    <path d="M141.85 74.7901V74.5901" stroke="black" stroke-width="0.5" />
    <path d="M141.85 74.5899V74.3899" stroke="black" stroke-width="0.5" />
    <path d="M141.85 74.3899V74.1799" stroke="black" stroke-width="0.5" />
    <path d="M141.85 74.18V73.98" stroke="black" stroke-width="0.5" />
    <path d="M141.85 73.98V73.78" stroke="black" stroke-width="0.5" />
    <path d="M141.85 73.7801V73.5801" stroke="black" stroke-width="0.5" />
    <path d="M141.85 73.5799V73.3899" stroke="black" stroke-width="0.5" />
    <path d="M141.85 73.39L141.84 73.2" stroke="black" stroke-width="0.5" />
    <path d="M141.84 73.2V73.03" stroke="black" stroke-width="0.5" />
    <path
      d="M141.84 73.0301L141.83 72.8601"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.83 72.86L141.82 72.72" stroke="black" stroke-width="0.5" />
    <path d="M141.82 72.7201V72.5801" stroke="black" stroke-width="0.5" />
    <path d="M141.82 72.58L141.81 72.46" stroke="black" stroke-width="0.5" />
    <path d="M141.81 72.4601L141.8 72.3601" stroke="black" stroke-width="0.5" />
    <path d="M141.8 72.36L141.79 72.27" stroke="black" stroke-width="0.5" />
    <path
      d="M141.79 72.2699L141.78 72.1899"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.78 72.1901L141.76 72.1201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.76 72.1201L141.75 72.0601"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.75 72.06L141.73 72.01" stroke="black" stroke-width="0.5" />
    <path d="M141.73 72.01L141.71 71.97" stroke="black" stroke-width="0.5" />
    <path
      d="M141.71 71.9699L141.69 71.9399"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.69 71.9399L141.66 71.9199"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.66 71.9199L141.63 71.8999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.63 71.8999L141.6 71.8799" stroke="black" stroke-width="0.5" />
    <path d="M141.6 71.8801L141.57 71.8701" stroke="black" stroke-width="0.5" />
    <path
      d="M141.57 71.8701L141.53 71.8601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M141.53 71.8601L141.49 71.8501"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.49 71.8501H141.45" stroke="black" stroke-width="0.5" />
    <path d="M141.45 71.8501L141.4 71.8401" stroke="black" stroke-width="0.5" />
    <path d="M141.4 71.8401H141.35" stroke="black" stroke-width="0.5" />
    <path d="M141.35 71.8401L141.3 71.8301" stroke="black" stroke-width="0.5" />
    <path d="M141.3 71.8301H141.25" stroke="black" stroke-width="0.5" />
    <path d="M141.25 71.8301H141.19" stroke="black" stroke-width="0.5" />
    <path
      d="M141.19 71.8301L141.13 71.8201"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.13 71.8201H141.06" stroke="black" stroke-width="0.5" />
    <path d="M141.06 71.8201H141" stroke="black" stroke-width="0.5" />
    <path d="M141 71.8201H140.93" stroke="black" stroke-width="0.5" />
    <path
      d="M140.93 71.8201L140.86 71.8301"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M140.86 71.8301H140.79" stroke="black" stroke-width="0.5" />
    <path
      d="M140.79 71.8301L140.72 71.8401"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.72 71.8401L140.65 71.8601"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.65 71.8601L140.58 71.8801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.58 71.8799L140.51 71.8999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.51 71.8999L140.45 71.9299"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.45 71.9299L140.38 71.9699"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M140.38 71.97L140.32 72.01" stroke="black" stroke-width="0.5" />
    <path d="M140.32 72.01L140.26 72.06" stroke="black" stroke-width="0.5" />
    <path d="M140.26 72.0601L140.2 72.1201" stroke="black" stroke-width="0.5" />
    <path d="M140.2 72.1201L140.15 72.1701" stroke="black" stroke-width="0.5" />
    <path d="M140.15 72.1699L140.1 72.2299" stroke="black" stroke-width="0.5" />
    <path d="M140.1 72.23L140.05 72.29" stroke="black" stroke-width="0.5" />
    <path d="M140.05 72.29L140.01 72.36" stroke="black" stroke-width="0.5" />
    <path
      d="M140.01 72.3601L139.98 72.4201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.98 72.4199L139.94 72.4899"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.94 72.49L139.92 72.55" stroke="black" stroke-width="0.5" />
    <path d="M139.92 72.55L139.89 72.62" stroke="black" stroke-width="0.5" />
    <path
      d="M139.89 72.6201L139.86 72.6901"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.86 72.6899L139.84 72.7599"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.84 72.76L139.82 72.83" stroke="black" stroke-width="0.5" />
    <path d="M139.82 72.8301L139.8 72.9001" stroke="black" stroke-width="0.5" />
    <path d="M139.8 72.8999L139.78 72.9699" stroke="black" stroke-width="0.5" />
    <path d="M139.78 72.97L139.76 73.05" stroke="black" stroke-width="0.5" />
    <path d="M139.76 73.05L139.75 73.12" stroke="black" stroke-width="0.5" />
    <path
      d="M139.75 73.1201L139.73 73.2001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.73 73.2L139.72 73.28" stroke="black" stroke-width="0.5" />
    <path d="M139.72 73.28L139.7 73.36" stroke="black" stroke-width="0.5" />
    <path d="M139.7 73.3601L139.69 73.4401" stroke="black" stroke-width="0.5" />
    <path
      d="M139.69 73.4399L139.68 73.5299"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.68 73.53L139.67 73.62" stroke="black" stroke-width="0.5" />
    <path
      d="M139.67 73.6201L139.66 73.7101"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.66 73.71L139.65 73.8" stroke="black" stroke-width="0.5" />
    <path d="M139.65 73.8V73.9" stroke="black" stroke-width="0.5" />
    <path
      d="M139.65 73.8999L139.64 73.9899"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.64 73.99V74.09" stroke="black" stroke-width="0.5" />
    <path d="M139.64 74.0901V74.1901" stroke="black" stroke-width="0.5" />
    <path d="M139.64 74.1899V74.2799" stroke="black" stroke-width="0.5" />
    <path d="M139.64 74.28V74.38" stroke="black" stroke-width="0.5" />
    <path
      d="M139.64 74.3799L139.65 74.4799"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.65 74.48V74.57" stroke="black" stroke-width="0.5" />
    <path
      d="M139.65 74.5701L139.66 74.6601"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.66 74.6599V74.7499" stroke="black" stroke-width="0.5" />
    <path d="M139.66 74.75L139.67 74.83" stroke="black" stroke-width="0.5" />
    <path
      d="M139.67 74.8301L139.68 74.9201"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.68 74.9199L139.69 74.9899"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.69 74.99L139.7 75.06" stroke="black" stroke-width="0.5" />
    <path d="M139.7 75.0601L139.71 75.1301" stroke="black" stroke-width="0.5" />
    <path
      d="M139.71 75.1299L139.73 75.1999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.73 75.2L139.74 75.2599" stroke="black" stroke-width="0.5" />
    <path d="M139.74 75.26L139.75 75.32" stroke="black" stroke-width="0.5" />
    <path
      d="M139.75 75.3201L139.77 75.3801"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.77 75.3799L139.79 75.4499"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.79 75.45L139.81 75.5099" stroke="black" stroke-width="0.5" />
    <path d="M139.81 75.51L139.82 75.57" stroke="black" stroke-width="0.5" />
    <path
      d="M139.82 75.5701L139.84 75.6301"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.84 75.6299L139.86 75.6799"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.86 75.6799L139.88 75.7399"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.88 75.74L139.9 75.79" stroke="black" stroke-width="0.5" />
    <path d="M139.9 75.79L139.91 75.84" stroke="black" stroke-width="0.5" />
    <path
      d="M139.91 75.8401L139.93 75.8801"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M141.63 59.52H142" stroke="black" stroke-width="0.5" />
    <path d="M141.63 60.62V59.52" stroke="black" stroke-width="0.5" />
    <path d="M142 60.6201H141.63" stroke="black" stroke-width="0.5" />
    <path d="M142 59.52V60.62" stroke="black" stroke-width="0.5" />
    <path d="M138.96 58.2L138.64 58.28" stroke="black" stroke-width="0.5" />
    <path d="M138.64 58.28L138.4 58.47" stroke="black" stroke-width="0.5" />
    <path d="M138.4 58.47L138.31 58.74" stroke="black" stroke-width="0.5" />
    <path d="M138.31 61.3999L138.4 61.6699" stroke="black" stroke-width="0.5" />
    <path d="M138.4 61.6699L138.64 61.8699" stroke="black" stroke-width="0.5" />
    <path
      d="M138.64 61.8701L138.96 61.9401"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M140.55 61.94L141 61.78" stroke="black" stroke-width="0.5" />
    <path d="M141 61.7799L141.19 61.3999" stroke="black" stroke-width="0.5" />
    <path d="M141.19 58.7401L141 58.3601" stroke="black" stroke-width="0.5" />
    <path d="M141 58.36L140.55 58.2" stroke="black" stroke-width="0.5" />
    <path d="M138.96 58.2H140.55" stroke="black" stroke-width="0.5" />
    <path d="M138.31 61.4V58.74" stroke="black" stroke-width="0.5" />
    <path d="M140.55 61.9399H138.96" stroke="black" stroke-width="0.5" />
    <path d="M141.19 58.74V61.4" stroke="black" stroke-width="0.5" />
    <path d="M137.94 57.8401H142.4" stroke="black" stroke-width="0.5" />
    <path d="M137.94 62.3001V57.8401" stroke="black" stroke-width="0.5" />
    <path d="M142.4 62.3H137.94" stroke="black" stroke-width="0.5" />
    <path d="M142.4 57.8401V62.3001" stroke="black" stroke-width="0.5" />
  </svg>
</template>
