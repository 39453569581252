import { Icontact } from '@/Core.Service.Domain/Contact/types';

const sendIssueEmail = async (formData: Icontact) => {
  try {
    const result = await window.axios.post('/v1/contact/issue', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export default {
  sendIssueEmail,
};
