<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg11829"
    viewBox="670.87 272.75 91.47 166.58"
  >
    <path
      d="M 672.36,283.22 H 760.5"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path73"
    />
    <path
      d="m 698.22,281.98 -0.17,-1.75"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path208"
    />
    <path
      d="m 698.05,280.23 -0.52,-1.69"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path209"
    />
    <path
      d="m 697.53,278.54 -0.82,-1.55"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path210"
    />
    <path
      d="m 696.71,276.99 -1.12,-1.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path211"
    />
    <path
      d="m 695.59,275.63 -1.36,-1.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path212"
    />
    <path
      d="m 694.23,274.51 -1.55,-0.82"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path213"
    />
    <path
      d="m 692.68,273.69 -1.69,-0.52"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path214"
    />
    <path
      d="M 690.99,273.17 689.24,273"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path215"
    />
    <path
      d="m 689.24,281.98 h 0.44"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path216"
    />
    <path
      d="m 689.68,281.98 v -8.97 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path217"
    />
    <path
      d="m 689.24,273 v 8.98 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path218"
    />
    <path
      d="m 689.24,281.98 v 1.24 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path219"
    />
    <path
      d="m 698.22,281.98 v 1.24 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path220"
    />
    <path
      d="m 736.56,282.6 h -0.54"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path238"
    />
    <path
      d="m 736.02,282.6 v -1.07 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path239"
    />
    <path
      d="m 736.02,281.53 h 4.86"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path240"
    />
    <path
      d="m 740.88,281.53 v 1.07 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path241"
    />
    <path
      d="m 740.88,282.6 h -0.54"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path242"
    />
    <path
      d="m 740.34,282.6 v 0.62 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path243"
    />
    <path
      d="m 740.34,283.22 h -3.78"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path244"
    />
    <path
      d="M 736.56,283.22 V 282.6 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path245"
    />
    <path
      d="M 671.12,283.22 V 434.91 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path745"
    />
    <path
      d="M 672.36,283.22 V 434.91 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path746"
    />
    <path
      d="m 760.5,283.22 v 59.53 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2521"
    />
    <path
      d="m 760.5,345.43 h -2.75"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2532"
    />
    <path
      d="M 757.75,350.6 H 757.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2535"
    />
    <path
      d="m 757.6,342.75 v 40.39 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2536"
    />
    <path
      d="m 756.1,345.43 v 89.16 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2537"
    />
    <path
      d="m 758.85,345.43 v 37.71 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2538"
    />
    <path
      d="m 757.6,383.14 h 2"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2539"
    />
    <path
      d="m 757.6,410.56 v 24.03 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2543"
    />
    <path
      d="m 758.84,410.56 v 26.03 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2544"
    />
    <path
      d="M 757.6,434.59 H 747.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2545"
    />
    <path
      d="M 758.84,436.59 H 747.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2546"
    />
    <path
      d="m 747.03,434.59 v 2 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2547"
    />
    <path
      d="m 719.61,436.59 v -2 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2548"
    />
    <path
      d="M 719.61,434.59 H 698.54"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2549"
    />
    <path
      d="M 719.61,436.59 H 698.54"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2550"
    />
    <path
      d="m 757.6,350.59 h 0.15"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2812"
    />
    <path
      d="M 760.5,342.75 V 283.22 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2813"
    />
    <path
      d="M 759,342.75 V 283.22 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2814"
    />
    <path
      d="m 756.1,345.43 h 4.4"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2815"
    />
    <path
      d="m 757.75,350.59 h 1.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2816"
    />
    <path
      d="m 736.56,282.6 h -0.54"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2985"
    />
    <path
      d="m 736.02,282.6 v -1.07 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2986"
    />
    <path
      d="m 736.02,281.53 h 4.86"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2987"
    />
    <path
      d="m 740.88,281.53 v 1.07 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2988"
    />
    <path
      d="m 740.88,282.6 h -0.54"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2989"
    />
    <path
      d="m 740.34,282.6 v 0.62 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2990"
    />
    <path
      d="m 740.34,283.22 h -3.78"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2991"
    />
    <path
      d="M 736.56,283.22 V 282.6 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path2992"
    />
    <path
      d="M 762.09,342.75 H 757.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3028"
    />
    <path
      d="M 698.54,436.59 H 671.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3574"
    />
    <path
      d="M 698.54,437.84 H 671.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3575"
    />
    <path
      d="M 698.54,434.59 H 671.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3576"
    />
    <path
      d="m 671.61,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3577"
    />
    <path
      d="m 674.97,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3578"
    />
    <path
      d="m 675.47,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3579"
    />
    <path
      d="m 693.93,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3580"
    />
    <path
      d="m 694.43,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3581"
    />
    <path
      d="m 698.04,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3582"
    />
    <path
      d="M 698.54,439.08 H 671.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3583"
    />
    <path
      d="m 698.54,439.08 v -4.49 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3584"
    />
    <path
      d="m 671.12,439.08 v -4.49 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3585"
    />
    <path
      d="M 747.03,436.59 H 719.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3586"
    />
    <path
      d="M 747.03,437.84 H 719.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3587"
    />
    <path
      d="M 747.03,434.59 H 719.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3588"
    />
    <path
      d="m 720.11,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3589"
    />
    <path
      d="m 723.46,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3590"
    />
    <path
      d="m 723.96,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3591"
    />
    <path
      d="m 742.42,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3592"
    />
    <path
      d="m 742.92,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3593"
    />
    <path
      d="m 746.54,436.59 v 1.25 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3594"
    />
    <path
      d="M 747.03,439.08 H 719.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3595"
    />
    <path
      d="m 747.03,439.08 v -4.49 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3596"
    />
    <path
      d="m 719.61,439.08 v -4.49 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3597"
    />
    <path
      d="M 760.09,410.56 V 383.14 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3598"
    />
    <path
      d="M 758.84,410.56 V 383.14 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3599"
    />
    <path
      d="M 762.09,410.56 V 383.14 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3600"
    />
    <path
      d="m 760.09,383.64 h -1.25"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3601"
    />
    <path
      d="m 760.09,386.99 h -1.25"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3602"
    />
    <path
      d="m 760.09,387.49 h -1.25"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3603"
    />
    <path
      d="m 760.09,405.96 h -1.25"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3604"
    />
    <path
      d="m 760.09,406.45 h -1.25"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3605"
    />
    <path
      d="m 760.09,410.07 h -1.25"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3606"
    />
    <path
      d="M 757.6,410.56 V 383.14 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3607"
    />
    <path
      d="m 757.6,410.56 h 4.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3608"
    />
    <path
      d="m 757.6,383.14 h 4.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path3609"
    />
    <path
      d="M 671.12,321.41 V 319.9 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path5998"
    />
    <path
      d="m 671.12,319.22 v -1.38 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path5999"
    />
    <path
      d="m 762.09,289.01 h -0.8 v -0.61 h 0.8 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6609"
    />
    <path
      d="m 761.6,288.71 v 0.92 h -0.61 v -0.92 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6610"
    />
    <path
      d="m 761.6,290.41 v 1.55 h -0.61 v -1.55 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6611"
    />
    <path
      d="m 761.6,292.74 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6612"
    />
    <path
      d="m 761.6,295.08 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6613"
    />
    <path
      d="m 761.6,297.42 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6614"
    />
    <path
      d="m 761.6,299.76 v 1.55 h -0.61 v -1.55 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6615"
    />
    <path
      d="m 761.6,302.09 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6616"
    />
    <path
      d="m 761.6,304.43 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6617"
    />
    <path
      d="m 761.6,306.77 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6618"
    />
    <path
      d="m 761.6,309.11 v 1.55 h -0.61 v -1.55 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6619"
    />
    <path
      d="M 761.6,311.44 V 313 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6620"
    />
    <path
      d="m 761.6,313.78 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6621"
    />
    <path
      d="m 761.6,316.12 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6622"
    />
    <path
      d="m 761.6,318.45 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6623"
    />
    <path
      d="m 761.6,320.79 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6624"
    />
    <path
      d="m 761.6,323.13 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6625"
    />
    <path
      d="m 761.6,325.47 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6626"
    />
    <path
      d="m 761.6,327.8 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6627"
    />
    <path
      d="m 761.6,330.14 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6628"
    />
    <path
      d="m 761.6,332.48 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6629"
    />
    <path
      d="m 761.6,334.82 v 1.55 h -0.61 v -1.55 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6630"
    />
    <path
      d="m 761.6,337.15 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6631"
    />
    <path
      d="m 761.6,339.49 v 1.56 h -0.61 v -1.56 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6632"
    />
    <path
      d="m 761.6,341.83 v 0.92 h -0.61 v -0.92 z"
      fill-rule="nonzero"
      fill="#000000"
      id="path6633"
    />
    <path
      d="M 724.96,434.59 V 404.81 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9002"
    />
    <path
      d="M 723.71,434.59 V 404.81 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9003"
    />
    <path
      d="m 724.96,404.81 h 2.87"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9004"
    />
    <path
      d="m 672.36,403.56 h 15.47"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9005"
    />
    <path
      d="m 695.71,403.56 h 17.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9006"
    />
    <path
      d="m 722.62,403.56 h 3.96"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9007"
    />
    <path
      d="m 672.36,405.38 h 15.47"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9008"
    />
    <path
      d="M 696.96,404.81 H 713.7"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9009"
    />
    <path
      d="m 722.62,404.81 h 1.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9010"
    />
    <path
      d="m 724.96,404.81 h 2.87"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9011"
    />
    <path
      d="m 727.83,404.81 v -3 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9012"
    />
    <path
      d="m 727.83,392.88 v -0.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9013"
    />
    <path
      d="m 727.83,390.89 v -1.2 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9014"
    />
    <path
      d="M 727.83,381.12 V 364.21 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9015"
    />
    <path
      d="m 727.83,362.96 v -0.71 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9016"
    />
    <path
      d="M 727.83,353.32 V 336.25 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9017"
    />
    <path
      d="m 727.83,335 v -0.8 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9018"
    />
    <path
      d="M 727.83,325.27 V 308.35 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9019"
    />
    <path
      d="m 726.58,403.56 v -1.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9020"
    />
    <path
      d="m 726.58,392.88 v -2.84 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9021"
    />
    <path
      d="M 726.58,381.1 V 362.25 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9022"
    />
    <path
      d="M 726.58,353.31 V 334.2 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9023"
    />
    <path
      d="M 726.58,325.25 V 307.1 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9024"
    />
    <path
      d="M 727.83,308.35 H 759"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9025"
    />
    <path
      d="M 726.58,307.1 H 759"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9026"
    />
    <path
      d="m 696.96,404.81 v 29.78 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9027"
    />
    <path
      d="m 695.71,403.56 v 31.03 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9028"
    />
    <path
      d="M 727.83,392.14 H 757.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9029"
    />
    <path
      d="M 727.83,390.89 H 757.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9030"
    />
    <path
      d="M 727.83,364.21 H 757.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9031"
    />
    <path
      d="M 727.83,362.96 H 757.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9032"
    />
    <path
      d="M 727.83,336.25 H 757.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9033"
    />
    <path
      d="M 727.83,335 H 757.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9034"
    />
    <path
      d="m 691.48,373.07 v -18 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9035"
    />
    <path
      d="M 691.48,353.52 V 333.96 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9036"
    />
    <path
      d="M 690.24,373.07 V 333.96 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9037"
    />
    <path
      d="m 672.36,332.29 h 6.45"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9038"
    />
    <path
      d="m 686.97,332.29 h 27.86"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9039"
    />
    <path
      d="m 714.83,332.29 v 2.28 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9040"
    />
    <path
      d="m 714.83,343.51 v 11.56 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9041"
    />
    <path
      d="M 714.83,355.07 H 691.48"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9042"
    />
    <path
      d="m 691.48,353.52 h 22.3"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9043"
    />
    <path
      d="M 713.78,353.52 V 343.49 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9044"
    />
    <path
      d="M 713.78,334.57 V 333.9 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9045"
    />
    <path
      d="m 678.81,333.9 h -6.45"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9046"
    />
    <path
      d="m 686.97,333.96 v -1.67 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9047"
    />
    <path
      d="m 678.81,333.9 v -1.61 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9048"
    />
    <path
      d="m 672.36,354.11 h 7.63"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9049"
    />
    <path
      d="m 679.99,354.11 v -1.22 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9050"
    />
    <path
      d="m 679.99,352.89 h -7.63"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9051"
    />
    <path
      d="m 672.36,373.26 h 7.63"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9052"
    />
    <path
      d="m 679.99,373.26 v -1.22 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9053"
    />
    <path
      d="m 679.99,372.04 h -7.63"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9054"
    />
    <path
      d="m 687.83,405.38 v -1.82 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9055"
    />
    <path
      d="m 713.12,323.54 v -7.17 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9056"
    />
    <path
      d="M 711.88,323.54 V 308.75 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9057"
    />
    <path
      d="m 713.12,316.37 h 3.86"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9058"
    />
    <path
      d="m 725.92,316.37 h 0.66"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9059"
    />
    <path
      d="m 711.88,315.12 h 5.11"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9060"
    />
    <path
      d="m 725.92,315.12 h 0.66"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9061"
    />
    <path
      d="m 672.36,316.13 a 116.72,116.72 0 0 0 39.52,0.46"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9062"
    />
    <path
      d="m 672.36,308.3 a 116.72,116.72 0 0 0 7.87,1.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9063"
    />
    <path
      d="m 704.08,309.73 a 116.72,116.72 0 0 0 7.8,-0.98"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9064"
    />
    <path
      d="m 680.33,308.31 -0.36,4.14"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9065"
    />
    <path
      d="m 679.97,312.45 0.55,0.02"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9066"
    />
    <path
      d="M 680.52,312.47 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9067"
    />
    <path
      d="m 680.52,312.47 7.89,0.18"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9068"
    />
    <path
      d="m 688.41,312.65 7.9,-0.08"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9069"
    />
    <path
      d="m 696.31,312.57 7.88,-0.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9070"
    />
    <path
      d="m 704.19,312.21 -0.18,-4.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9071"
    />
    <path
      d="m 704.01,308.17 -0.49,0.02"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9072"
    />
    <path
      d="M 703.52,308.19 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9073"
    />
    <path
      d="m 703.52,308.19 -5.79,0.45"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9074"
    />
    <path
      d="m 697.73,308.64 -5.8,0.18"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9075"
    />
    <path
      d="m 691.93,308.82 -5.8,-0.11"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9076"
    />
    <path
      d="m 686.13,308.71 -5.8,-0.4"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9077"
    />
    <path
      d="m 711.88,323.54 h 1.24"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9078"
    />
    <path
      d="m 726.53,314.93 v 1.49 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9079"
    />
    <path
      d="m 726.53,316.42 h -0.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9080"
    />
    <path
      d="m 725.92,316.42 v -0.45 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9081"
    />
    <path
      d="m 725.92,315.97 h -0.19"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9082"
    />
    <path
      d="M 725.73,315.97 V 315.4 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9083"
    />
    <path
      d="m 725.73,315.4 h 0.19"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9084"
    />
    <path
      d="m 725.92,315.4 v -0.43 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9085"
    />
    <path
      d="m 725.92,314.97 v -0.04 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9086"
    />
    <path
      d="m 725.92,314.93 h 0.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9087"
    />
    <path
      d="m 725.92,314.93 v -8.97 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9088"
    />
    <path
      d="m 725.92,305.96 h -0.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9089"
    />
    <path
      d="m 725.56,305.96 v 8.97 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9090"
    />
    <path
      d="m 725.56,314.93 h 0.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9091"
    />
    <path
      d="m 716.49,314.93 v 1.53 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9092"
    />
    <path
      d="m 716.49,316.46 h 0.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9093"
    />
    <path
      d="m 716.98,316.46 v -0.43 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9094"
    />
    <path
      d="m 716.98,316.03 h 0.22"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9095"
    />
    <path
      d="m 717.2,316.03 v -0.61 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9096"
    />
    <path
      d="m 717.2,315.42 h -0.21"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9097"
    />
    <path
      d="m 716.99,315.42 v -0.48 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9098"
    />
    <path
      d="m 716.99,314.94 -0.5,-0.01"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9099"
    />
    <path
      d="m 725.56,305.96 a 9.032,9.032 0 0 0 -8.57,8.98"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9100"
    />
    <path
      d="m 728.02,334.81 h -1.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9101"
    />
    <path
      d="M 726.53,334.81 V 334.2 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9102"
    />
    <path
      d="m 726.53,334.2 h 0.45"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9103"
    />
    <path
      d="M 726.98,334.2 V 334 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9104"
    />
    <path
      d="m 726.98,334 h 0.58"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9105"
    />
    <path
      d="m 727.56,334 v 0.2 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9106"
    />
    <path
      d="m 727.56,334.2 h 0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9107"
    />
    <path
      d="m 727.99,334.2 h 0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9108"
    />
    <path
      d="m 728.02,334.2 v 0.61 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9109"
    />
    <path
      d="m 728.02,334.2 h 8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9110"
    />
    <path
      d="m 736.99,334.2 v -0.36 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9111"
    />
    <path
      d="m 736.99,333.84 h -8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9112"
    />
    <path
      d="m 728.02,333.84 v 0.36 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9113"
    />
    <path
      d="m 728.02,324.77 h -1.53"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9114"
    />
    <path
      d="m 726.49,324.77 v 0.48 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9115"
    />
    <path
      d="m 726.49,325.25 h 0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9116"
    />
    <path
      d="m 726.92,325.25 v 0.23 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9117"
    />
    <path
      d="m 726.92,325.48 h 0.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9118"
    />
    <path
      d="m 727.53,325.48 v -0.21 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9119"
    />
    <path
      d="m 727.53,325.27 h 0.48"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9120"
    />
    <path
      d="m 728.01,325.27 0.01,-0.5 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9121"
    />
    <path
      d="m 736.99,333.84 a 9.032,9.032 0 0 0 -8.98,-8.57"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9122"
    />
    <path
      d="m 728.02,362.86 h -1.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9123"
    />
    <path
      d="m 726.53,362.86 v -0.61 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9124"
    />
    <path
      d="m 726.53,362.25 h 0.45"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9125"
    />
    <path
      d="m 726.98,362.25 v -0.19 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9126"
    />
    <path
      d="m 726.98,362.06 h 0.58"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9127"
    />
    <path
      d="m 727.56,362.06 v 0.19 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9128"
    />
    <path
      d="m 727.56,362.25 h 0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9129"
    />
    <path
      d="m 727.99,362.25 h 0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9130"
    />
    <path
      d="m 728.02,362.25 v 0.61 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9131"
    />
    <path
      d="m 728.02,362.25 h 8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9132"
    />
    <path
      d="m 736.99,362.25 v -0.36 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9133"
    />
    <path
      d="m 736.99,361.89 h -8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9134"
    />
    <path
      d="m 728.02,361.89 v 0.36 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9135"
    />
    <path
      d="m 728.02,352.82 h -1.53"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9136"
    />
    <path
      d="m 726.49,352.82 v 0.49 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9137"
    />
    <path
      d="m 726.49,353.31 h 0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9138"
    />
    <path
      d="m 726.92,353.31 v 0.23 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9139"
    />
    <path
      d="m 726.92,353.54 h 0.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9140"
    />
    <path
      d="m 727.53,353.54 v -0.22 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9141"
    />
    <path
      d="m 727.53,353.32 h 0.48"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9142"
    />
    <path
      d="m 728.01,353.32 0.01,-0.5 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9143"
    />
    <path
      d="m 736.99,361.89 a 9.032,9.032 0 0 0 -8.98,-8.57"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9144"
    />
    <path
      d="m 727.94,390.66 h -1.48"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9145"
    />
    <path
      d="m 726.46,390.66 v -0.62 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9146"
    />
    <path
      d="m 726.46,390.04 h 0.45"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9147"
    />
    <path
      d="m 726.91,390.04 v -0.19 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9148"
    />
    <path
      d="m 726.91,389.85 h 0.57"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9149"
    />
    <path
      d="m 727.48,389.85 v 0.19 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9150"
    />
    <path
      d="m 727.48,390.04 h 0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9151"
    />
    <path
      d="m 727.91,390.04 h 0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9152"
    />
    <path
      d="m 727.94,390.04 v 0.62 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9153"
    />
    <path
      d="m 727.94,390.04 h 8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9154"
    />
    <path
      d="m 736.91,390.04 v -0.35 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9155"
    />
    <path
      d="m 736.91,389.69 h -8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9156"
    />
    <path
      d="m 727.94,389.69 v 0.35 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9157"
    />
    <path
      d="m 727.94,380.62 h -1.53"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9158"
    />
    <path
      d="m 726.41,380.62 v 0.48 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9159"
    />
    <path
      d="m 726.41,381.1 h 0.44"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9160"
    />
    <path
      d="m 726.85,381.1 v 0.23 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9161"
    />
    <path
      d="m 726.85,381.33 h 0.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9162"
    />
    <path
      d="m 727.46,381.33 v -0.21 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9163"
    />
    <path
      d="m 727.46,381.12 h 0.47"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9164"
    />
    <path
      d="m 727.93,381.12 0.01,-0.5 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9165"
    />
    <path
      d="m 736.91,389.69 a 9.032,9.032 0 0 0 -8.98,-8.57"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9166"
    />
    <path
      d="m 713.59,333.95 h 1.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9167"
    />
    <path
      d="m 715.08,333.95 v 0.62 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9168"
    />
    <path
      d="m 715.08,334.57 h -0.45"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9169"
    />
    <path
      d="m 714.63,334.57 v 0.19 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9170"
    />
    <path
      d="m 714.63,334.76 h -0.58"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9171"
    />
    <path
      d="m 714.05,334.76 v -0.19 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9172"
    />
    <path
      d="m 714.05,334.57 h -0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9173"
    />
    <path
      d="m 713.62,334.57 h -0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9174"
    />
    <path
      d="m 713.59,334.57 v -0.62 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9175"
    />
    <path
      d="m 713.59,334.57 h -8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9176"
    />
    <path
      d="m 704.62,334.57 v 0.35 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9177"
    />
    <path
      d="m 704.62,334.92 h 8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9178"
    />
    <path
      d="m 713.59,334.92 v -0.35 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9179"
    />
    <path
      d="m 713.59,343.99 h 1.53"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9180"
    />
    <path
      d="m 715.12,343.99 v -0.48 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9181"
    />
    <path
      d="m 715.12,343.51 h -0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9182"
    />
    <path
      d="m 714.69,343.51 v -0.23 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9183"
    />
    <path
      d="m 714.69,343.28 h -0.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9184"
    />
    <path
      d="m 714.08,343.28 v 0.21 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9185"
    />
    <path
      d="M 714.08,343.49 H 713.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9186"
    />
    <path
      d="m 713.6,343.49 -0.01,0.5 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9187"
    />
    <path
      d="m 704.62,334.92 a 9.032,9.032 0 0 0 8.98,8.57"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9188"
    />
    <path
      d="m 728.02,392.27 h -1.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9189"
    />
    <path
      d="m 726.53,392.27 v 0.61 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9190"
    />
    <path
      d="m 726.53,392.88 h 0.45"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9191"
    />
    <path
      d="m 726.98,392.88 v 0.19 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9192"
    />
    <path
      d="m 726.98,393.07 h 0.58"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9193"
    />
    <path
      d="m 727.56,393.07 v -0.19 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9194"
    />
    <path
      d="m 727.56,392.88 h 0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9195"
    />
    <path
      d="m 727.99,392.88 h 0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9196"
    />
    <path
      d="m 728.02,392.88 v -0.61 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9197"
    />
    <path
      d="m 728.02,392.88 h 8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9198"
    />
    <path
      d="m 736.99,392.88 v 0.35 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9199"
    />
    <path
      d="m 736.99,393.23 h -8.97"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9200"
    />
    <path
      d="m 728.02,393.23 v -0.35 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9201"
    />
    <path
      d="m 728.02,402.31 h -1.53"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9202"
    />
    <path
      d="m 726.49,402.31 v -0.49 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9203"
    />
    <path
      d="m 726.49,401.82 h 0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9204"
    />
    <path
      d="m 726.92,401.82 v -0.23 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9205"
    />
    <path
      d="m 726.92,401.59 h 0.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9206"
    />
    <path
      d="m 727.53,401.59 v 0.22 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9207"
    />
    <path
      d="m 727.53,401.81 h 0.48"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9208"
    />
    <path
      d="m 728.01,401.81 0.01,0.5 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9209"
    />
    <path
      d="m 728.01,401.81 a 9.032,9.032 0 0 0 8.98,-8.58"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9210"
    />
    <path
      d="m 723.24,404.97 v -1.48 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9211"
    />
    <path
      d="m 723.24,403.49 h -0.62"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9212"
    />
    <path
      d="m 722.62,403.49 v 0.45 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9213"
    />
    <path
      d="m 722.62,403.94 h -0.19"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9214"
    />
    <path
      d="m 722.43,403.94 v 0.57 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9215"
    />
    <path
      d="m 722.43,404.51 h 0.19"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9216"
    />
    <path
      d="m 722.62,404.51 v 0.43 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9217"
    />
    <path
      d="m 722.62,404.94 v 0.03 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9218"
    />
    <path
      d="m 722.62,404.97 h 0.62"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9219"
    />
    <path
      d="m 722.62,404.97 v 8.97 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9220"
    />
    <path
      d="m 722.62,413.94 h -0.35"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9221"
    />
    <path
      d="m 722.27,413.94 v -8.97 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9222"
    />
    <path
      d="m 722.27,404.97 h 0.35"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9223"
    />
    <path
      d="m 713.2,404.97 v -1.53 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9224"
    />
    <path
      d="m 713.2,403.44 h 0.48"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9225"
    />
    <path
      d="m 713.68,403.44 v 0.44 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9226"
    />
    <path
      d="m 713.68,403.88 h 0.23"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9227"
    />
    <path
      d="m 713.91,403.88 v 0.61 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9228"
    />
    <path
      d="M 713.91,404.49 H 713.7"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9229"
    />
    <path
      d="m 713.7,404.49 v 0.47 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9230"
    />
    <path
      d="m 713.7,404.96 -0.5,0.01"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9231"
    />
    <path
      d="m 713.7,404.96 a 9.032,9.032 0 0 0 8.57,8.98"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9232"
    />
    <path
      d="m 680.21,404.75 v -0.31 h 9.64 v 0.31 z"
      fill="#000000"
      stroke="#000000"
      stroke-width="0.2"
      id="path9233"
    />
    <path
      d="m 686.97,333.96 h 3.27"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9235"
    />
    <path
      d="m 690.24,333.96 23.35,-0.01"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9236"
    />
    <path
      d="m 695.03,352.93 h 1.92"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9237"
    />
    <path
      d="m 696.95,352.93 0.32,-0.75"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9238"
    />
    <path
      d="M 697.27,352.18 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9239"
    />
    <path
      d="m 697.27,352.18 0.26,-0.73"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9240"
    />
    <path
      d="M 697.53,351.45 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9241"
    />
    <path
      d="m 697.53,351.45 0.25,-1.27"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9242"
    />
    <path
      d="m 697.78,350.18 -0.04,-1.29"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9243"
    />
    <path
      d="m 697.74,348.89 -0.33,-1.24"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9244"
    />
    <path
      d="m 697.41,347.65 -0.6,-1.15"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9245"
    />
    <path
      d="M 696.81,346.5 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9246"
    />
    <path
      d="m 696.81,346.5 -0.37,-0.32"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9247"
    />
    <path
      d="m 696.44,346.18 -0.48,-0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9248"
    />
    <path
      d="m 695.96,346.08 -0.47,0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9249"
    />
    <path
      d="M 695.49,346.21 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9250"
    />
    <path
      d="m 695.49,346.21 -0.53,0.51"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9251"
    />
    <path
      d="M 694.96,346.72 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9252"
    />
    <path
      d="m 694.96,346.72 -0.44,0.8"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9253"
    />
    <path
      d="m 694.52,347.52 -0.31,0.85"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9254"
    />
    <path
      d="M 694.21,348.37 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9255"
    />
    <path
      d="m 694.21,348.37 -0.12,0.61"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9256"
    />
    <path
      d="m 694.09,348.98 v 0.63 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9257"
    />
    <path
      d="M 694.09,349.61 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9258"
    />
    <path
      d="m 694.09,349.61 0.17,1.19"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9259"
    />
    <path
      d="m 694.26,350.8 0.77,2.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9260"
    />
    <path
      d="m 694.16,350.04 0.34,0.71"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9261"
    />
    <path
      d="m 694.5,350.75 0.1,0.19"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9262"
    />
    <path
      d="m 694.6,350.94 0.17,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9263"
    />
    <path
      d="m 694.77,351.06 h 0.26"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9264"
    />
    <path
      d="m 695.03,351.06 2.16,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9265"
    />
    <path
      d="m 697.19,351.09 0.15,-0.08"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9266"
    />
    <path
      d="m 697.34,351.01 0.19,-0.24"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9267"
    />
    <path
      d="m 697.53,350.77 0.2,-0.43"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9268"
    />
    <path
      d="m 697.73,350.34 0.05,-0.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9269"
    />
    <path
      d="m 701.93,353.49 -0.01,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9270"
    />
    <path
      d="m 701.92,353.33 -0.06,-0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9271"
    />
    <path
      d="m 701.86,353.23 -0.09,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9272"
    />
    <path
      d="m 701.77,353.11 h -0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9273"
    />
    <path
      d="m 701.64,353.11 -7.97,-0.02"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9274"
    />
    <path
      d="m 693.67,353.09 -0.12,0.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9275"
    />
    <path
      d="m 693.55,353.14 -0.08,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9276"
    />
    <path
      d="m 693.47,353.21 -0.04,0.15"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9277"
    />
    <path
      d="m 693.43,353.36 0.01,0.15 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9278"
    />
    <path
      d="m 702.41,353.52 -0.03,-0.19"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9279"
    />
    <path
      d="m 702.38,353.33 -0.08,-0.21"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9280"
    />
    <path
      d="m 702.3,353.12 -0.15,-0.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9281"
    />
    <path
      d="m 702.15,352.95 -0.16,-0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9282"
    />
    <path
      d="m 701.99,352.85 -0.19,-0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9283"
    />
    <path
      d="m 701.8,352.75 -0.16,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9284"
    />
    <path
      d="m 701.64,352.68 h -7.82"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9285"
    />
    <path
      d="m 693.82,352.68 -0.18,0.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9286"
    />
    <path
      d="m 693.64,352.74 -0.29,0.11"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9287"
    />
    <path
      d="m 693.35,352.85 -0.14,0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9288"
    />
    <path
      d="m 693.21,352.98 -0.08,0.23"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9289"
    />
    <path
      d="m 693.13,353.21 -0.04,0.2"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9290"
    />
    <path
      d="m 693.09,353.41 0.01,0.11"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9291"
    />
    <path
      d="m 691.49,351.93 h 0.14"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9292"
    />
    <path
      d="m 691.63,351.93 0.17,-0.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9293"
    />
    <path
      d="m 691.8,351.89 0.06,-0.08"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9294"
    />
    <path
      d="m 691.86,351.81 0.11,-0.02"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9295"
    />
    <path
      d="m 691.97,351.79 0.08,-0.11"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9296"
    />
    <path
      d="m 692.05,351.68 0.12,-0.15"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9297"
    />
    <path
      d="m 692.17,351.53 0.04,-0.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9298"
    />
    <path
      d="M 692.21,351.36 V 340.2 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9299"
    />
    <path
      d="m 692.21,340.2 -0.03,-0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9300"
    />
    <path
      d="m 692.18,340.07 -0.07,-0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9301"
    />
    <path
      d="m 692.11,339.94 -0.07,-0.11"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9302"
    />
    <path
      d="m 692.04,339.83 -0.12,-0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9303"
    />
    <path
      d="m 691.92,339.73 -0.08,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9304"
    />
    <path
      d="m 691.84,339.66 -0.12,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9305"
    />
    <path
      d="m 691.72,339.63 -0.12,-0.01"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9306"
    />
    <path
      d="m 691.6,339.62 h -0.11"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9307"
    />
    <path
      d="m 691.48,351.5 0.12,-0.01"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9308"
    />
    <path
      d="m 691.6,351.49 0.11,-0.01"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9309"
    />
    <path
      d="m 691.71,351.48 0.05,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9310"
    />
    <path
      d="m 691.76,351.41 0.08,-0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9311"
    />
    <path
      d="m 691.84,351.31 0.06,-0.11"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9312"
    />
    <path
      d="m 691.9,351.2 v -0.14 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9313"
    />
    <path
      d="M 691.9,351.06 V 340.45 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9314"
    />
    <path
      d="m 691.9,340.45 -0.03,-0.14"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9315"
    />
    <path
      d="m 691.87,340.31 -0.08,-0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9316"
    />
    <path
      d="m 691.79,340.21 -0.07,-0.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9317"
    />
    <path
      d="m 691.72,340.16 h -0.11"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9318"
    />
    <path
      d="m 691.61,340.16 -0.13,-0.01"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9319"
    />
    <path
      d="m 691.48,342.79 h 1.57"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9320"
    />
    <path
      d="m 693.05,342.79 v 1.47 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9321"
    />
    <path
      d="m 693.05,344.26 h -1.57"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9322"
    />
    <path
      d="m 692.48,344.25 v -1.46 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9323"
    />
    <path
      d="m 707.73,353.02 h 3.73"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9324"
    />
    <path
      d="m 711.46,353.02 v -3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9325"
    />
    <path
      d="m 711.46,349.28 h -3.73"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9326"
    />
    <path
      d="m 707.73,349.28 v 3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9327"
    />
    <path
      d="m 708.07,351.8 v -1.85 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9328"
    />
    <path
      d="M 708.07,349.95 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9329"
    />
    <path
      d="m 708.07,349.95 0.03,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9330"
    />
    <path
      d="m 708.1,349.83 0.1,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9331"
    />
    <path
      d="m 708.2,349.74 0.12,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9332"
    />
    <path
      d="m 708.32,349.71 h 2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9333"
    />
    <path
      d="M 710.81,349.71 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9334"
    />
    <path
      d="m 710.81,349.71 0.13,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9335"
    />
    <path
      d="m 710.94,349.74 0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9336"
    />
    <path
      d="m 711.03,349.83 0.03,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9337"
    />
    <path
      d="m 711.06,349.95 v 1.85 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9338"
    />
    <path
      d="M 711.06,351.8 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9339"
    />
    <path
      d="m 711.06,351.8 -0.03,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9340"
    />
    <path
      d="m 711.03,351.92 -0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9341"
    />
    <path
      d="m 710.94,352.01 -0.13,0.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9342"
    />
    <path
      d="m 710.81,352.05 h -2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9343"
    />
    <path
      d="M 708.32,352.05 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9344"
    />
    <path
      d="m 708.32,352.05 -0.12,-0.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9345"
    />
    <path
      d="m 708.2,352.01 -0.1,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9346"
    />
    <path
      d="m 708.1,351.92 -0.03,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9347"
    />
    <path
      d="m 709.55,352.45 v -1.1 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9348"
    />
    <path
      d="m 709.16,352.41 h -1.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9349"
    />
    <path
      d="m 709.99,352.41 h 1.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9350"
    />
    <path
      d="m 709.6,350.68 0.17,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9351"
    />
    <path
      d="m 709.77,350.75 0.06,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9352"
    />
    <path
      d="m 709.83,350.91 -0.06,0.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9353"
    />
    <path
      d="m 709.77,351.08 -0.17,0.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9354"
    />
    <path
      d="m 709.6,351.14 -0.16,-0.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9355"
    />
    <path
      d="m 709.44,351.08 -0.07,-0.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9356"
    />
    <path
      d="m 709.37,350.91 0.07,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9357"
    />
    <path
      d="m 709.44,350.75 0.16,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9358"
    />
    <path
      d="M 759,329.25 H 747.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9359"
    />
    <path
      d="M 747.37,329.25 V 335 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9360"
    />
    <path
      d="m 747.37,331.7 h 1.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9361"
    />
    <path
      d="m 749.42,331.7 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9362"
    />
    <path
      d="m 751.47,331.7 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9363"
    />
    <path
      d="m 753.53,331.7 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9364"
    />
    <path
      d="m 755.59,331.7 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9365"
    />
    <path
      d="M 757.64,331.7 H 759"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9366"
    />
    <path
      d="M 759,357.26 H 747.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9367"
    />
    <path
      d="m 747.37,357.26 v 5.75 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9368"
    />
    <path
      d="m 747.37,359.72 h 1.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9369"
    />
    <path
      d="m 749.42,359.72 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9370"
    />
    <path
      d="m 751.47,359.72 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9371"
    />
    <path
      d="m 753.53,359.72 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9372"
    />
    <path
      d="m 755.59,359.72 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9373"
    />
    <path
      d="M 757.64,359.72 H 759"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9374"
    />
    <path
      d="M 759,385.11 H 747.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9375"
    />
    <path
      d="m 747.37,385.11 v 5.75 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9376"
    />
    <path
      d="m 747.37,387.57 h 1.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9377"
    />
    <path
      d="m 749.42,387.57 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9378"
    />
    <path
      d="m 751.47,387.57 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9379"
    />
    <path
      d="m 753.53,387.57 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9380"
    />
    <path
      d="m 755.59,387.57 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9381"
    />
    <path
      d="M 757.64,387.57 H 759"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9382"
    />
    <path
      d="M 759,398.22 H 747.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9383"
    />
    <path
      d="m 747.37,398.22 v -5.75 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9384"
    />
    <path
      d="m 747.37,395.77 h 1.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9385"
    />
    <path
      d="m 749.42,395.77 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9386"
    />
    <path
      d="m 751.47,395.77 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9387"
    />
    <path
      d="m 753.53,395.77 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9388"
    />
    <path
      d="m 755.59,395.77 h 1.37"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9389"
    />
    <path
      d="M 757.64,395.77 H 759"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9390"
    />
    <path
      d="M 723.61,424.23 H 718"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9391"
    />
    <path
      d="M 718,424.23 V 434.6 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9392"
    />
    <path
      d="m 720.39,424.26 v 0.7 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9393"
    />
    <path
      d="m 720.39,425.64 v 1.38 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9394"
    />
    <path
      d="m 720.39,427.7 v 1.37 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9395"
    />
    <path
      d="m 720.39,429.76 v 1.37 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9396"
    />
    <path
      d="m 720.39,431.81 v 1.38 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9397"
    />
    <path
      d="m 720.39,433.87 v 0.7 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9398"
    />
    <path
      d="m 687.54,405.38 v 5.93 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9399"
    />
    <path
      d="M 687.54,411.31 H 672.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9400"
    />
    <path
      d="M 687.54,408.34 H 672.36"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9401"
    />
    <path
      d="m 753.29,334.26 h 3.74"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9402"
    />
    <path
      d="m 757.03,334.26 v -3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9403"
    />
    <path
      d="m 757.03,330.52 h -3.74"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9404"
    />
    <path
      d="m 753.29,330.52 v 3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9405"
    />
    <path
      d="m 753.64,333.04 v -1.85 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9406"
    />
    <path
      d="M 753.64,331.19 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9407"
    />
    <path
      d="m 753.64,331.19 0.03,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9408"
    />
    <path
      d="m 753.67,331.07 0.09,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9409"
    />
    <path
      d="m 753.76,330.98 0.13,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9410"
    />
    <path
      d="m 753.89,330.95 h 2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9411"
    />
    <path
      d="M 756.38,330.95 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9412"
    />
    <path
      d="m 756.38,330.95 0.12,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9413"
    />
    <path
      d="m 756.5,330.98 0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9414"
    />
    <path
      d="m 756.59,331.07 0.04,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9415"
    />
    <path
      d="m 756.63,331.19 v 1.85 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9416"
    />
    <path
      d="M 756.63,333.04 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9417"
    />
    <path
      d="m 756.63,333.04 -0.04,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9418"
    />
    <path
      d="m 756.59,333.16 -0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9419"
    />
    <path
      d="m 756.5,333.25 -0.12,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9420"
    />
    <path
      d="m 756.38,333.28 h -2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9421"
    />
    <path
      d="M 753.89,333.28 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9422"
    />
    <path
      d="m 753.89,333.28 -0.13,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9423"
    />
    <path
      d="m 753.76,333.25 -0.09,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9424"
    />
    <path
      d="m 753.67,333.16 -0.03,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9425"
    />
    <path
      d="m 755.11,333.69 v -1.1 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9426"
    />
    <path
      d="m 754.73,333.65 h -1.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9427"
    />
    <path
      d="m 755.55,333.65 h 1.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9428"
    />
    <path
      d="m 755.17,331.92 0.16,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9429"
    />
    <path
      d="m 755.33,331.99 0.07,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9430"
    />
    <path
      d="m 755.4,332.15 -0.07,0.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9431"
    />
    <path
      d="m 755.33,332.32 -0.16,0.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9432"
    />
    <path
      d="m 755.17,332.38 -0.16,-0.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9433"
    />
    <path
      d="m 755.01,332.32 -0.07,-0.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9434"
    />
    <path
      d="m 754.94,332.15 0.07,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9435"
    />
    <path
      d="m 755.01,331.99 0.16,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9436"
    />
    <path
      d="m 753.3,362.24 h 3.73"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9437"
    />
    <path
      d="M 757.03,362.24 V 358.5 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9438"
    />
    <path
      d="M 757.03,358.5 H 753.3"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9439"
    />
    <path
      d="m 753.3,358.5 v 3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9440"
    />
    <path
      d="m 753.64,361.01 v -1.84 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9441"
    />
    <path
      d="M 753.64,359.17 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9442"
    />
    <path
      d="m 753.64,359.17 0.03,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9443"
    />
    <path
      d="m 753.67,359.05 0.09,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9444"
    />
    <path
      d="m 753.76,358.96 0.13,-0.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9445"
    />
    <path
      d="m 753.89,358.92 h 2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9446"
    />
    <path
      d="M 756.38,358.92 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9447"
    />
    <path
      d="m 756.38,358.92 0.13,0.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9448"
    />
    <path
      d="m 756.51,358.96 0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9449"
    />
    <path
      d="m 756.6,359.05 0.03,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9450"
    />
    <path
      d="m 756.63,359.17 v 1.84 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9451"
    />
    <path
      d="M 756.63,361.01 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9452"
    />
    <path
      d="m 756.63,361.01 -0.03,0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9453"
    />
    <path
      d="m 756.6,361.14 -0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9454"
    />
    <path
      d="m 756.51,361.23 -0.13,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9455"
    />
    <path
      d="m 756.38,361.26 h -2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9456"
    />
    <path
      d="M 753.89,361.26 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9457"
    />
    <path
      d="m 753.89,361.26 -0.13,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9458"
    />
    <path
      d="m 753.76,361.23 -0.09,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9459"
    />
    <path
      d="m 753.67,361.14 -0.03,-0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9460"
    />
    <path
      d="m 755.12,361.67 v -1.1 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9461"
    />
    <path
      d="m 754.73,361.63 h -1.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9462"
    />
    <path
      d="m 755.56,361.63 h 1.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9463"
    />
    <path
      d="m 755.17,359.9 0.17,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9464"
    />
    <path
      d="m 755.34,359.97 0.06,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9465"
    />
    <path
      d="m 755.4,360.13 -0.06,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9466"
    />
    <path
      d="m 755.34,360.29 -0.17,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9467"
    />
    <path
      d="m 755.17,360.36 -0.16,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9468"
    />
    <path
      d="m 755.01,360.29 -0.07,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9469"
    />
    <path
      d="m 754.94,360.13 0.07,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9470"
    />
    <path
      d="m 755.01,359.97 0.16,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9471"
    />
    <path
      d="m 753.1,390.09 h 3.74"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9472"
    />
    <path
      d="m 756.84,390.09 v -3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9473"
    />
    <path
      d="M 756.84,386.35 H 753.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9474"
    />
    <path
      d="m 753.1,386.35 v 3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9475"
    />
    <path
      d="m 753.44,388.86 v -1.84 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9476"
    />
    <path
      d="M 753.44,387.02 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9477"
    />
    <path
      d="m 753.44,387.02 0.04,-0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9478"
    />
    <path
      d="m 753.48,386.89 0.09,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9479"
    />
    <path
      d="m 753.57,386.8 0.12,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9480"
    />
    <path
      d="m 753.69,386.77 h 2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9481"
    />
    <path
      d="M 756.18,386.77 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9482"
    />
    <path
      d="m 756.18,386.77 0.13,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9483"
    />
    <path
      d="m 756.31,386.8 0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9484"
    />
    <path
      d="m 756.4,386.89 0.03,0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9485"
    />
    <path
      d="m 756.43,387.02 v 1.84 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9486"
    />
    <path
      d="M 756.43,388.86 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9487"
    />
    <path
      d="m 756.43,388.86 -0.03,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9488"
    />
    <path
      d="m 756.4,388.98 -0.09,0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9489"
    />
    <path
      d="m 756.31,389.08 -0.13,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9490"
    />
    <path
      d="m 756.18,389.11 h -2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9491"
    />
    <path
      d="M 753.69,389.11 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9492"
    />
    <path
      d="m 753.69,389.11 -0.12,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9493"
    />
    <path
      d="m 753.57,389.08 -0.09,-0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9494"
    />
    <path
      d="m 753.48,388.98 -0.04,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9495"
    />
    <path
      d="m 754.92,389.51 v -1.09 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9496"
    />
    <path
      d="m 754.54,389.47 h -1.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9497"
    />
    <path
      d="m 755.36,389.47 h 1.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9498"
    />
    <path
      d="m 754.98,387.75 0.16,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9499"
    />
    <path
      d="m 755.14,387.82 0.07,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9500"
    />
    <path
      d="m 755.21,387.98 -0.07,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9501"
    />
    <path
      d="m 755.14,388.14 -0.16,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9502"
    />
    <path
      d="m 754.98,388.21 -0.17,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9503"
    />
    <path
      d="m 754.81,388.14 -0.06,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9504"
    />
    <path
      d="m 754.75,387.98 0.06,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9505"
    />
    <path
      d="m 754.81,387.82 0.17,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9506"
    />
    <path
      d="m 756.99,392.97 h -3.74"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9507"
    />
    <path
      d="m 753.25,392.97 v 3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9508"
    />
    <path
      d="m 753.25,396.71 h 3.74"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9509"
    />
    <path
      d="m 756.99,396.71 v -3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9510"
    />
    <path
      d="m 756.64,394.19 v 1.84 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9511"
    />
    <path
      d="M 756.64,396.03 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9512"
    />
    <path
      d="m 756.64,396.03 -0.03,0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9513"
    />
    <path
      d="m 756.61,396.16 -0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9514"
    />
    <path
      d="m 756.52,396.25 -0.13,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9515"
    />
    <path
      d="M 756.39,396.28 H 753.9"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9516"
    />
    <path
      d="M 753.9,396.28 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9517"
    />
    <path
      d="m 753.9,396.28 -0.12,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9518"
    />
    <path
      d="m 753.78,396.25 -0.1,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9519"
    />
    <path
      d="m 753.68,396.16 -0.03,-0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9520"
    />
    <path
      d="m 753.65,396.03 v -1.84 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9521"
    />
    <path
      d="M 753.65,394.19 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9522"
    />
    <path
      d="m 753.65,394.19 0.03,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9523"
    />
    <path
      d="m 753.68,394.07 0.1,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9524"
    />
    <path
      d="m 753.78,393.98 0.12,-0.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9525"
    />
    <path
      d="m 753.9,393.94 h 2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9526"
    />
    <path
      d="M 756.39,393.94 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9527"
    />
    <path
      d="m 756.39,393.94 0.13,0.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9528"
    />
    <path
      d="m 756.52,393.98 0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9529"
    />
    <path
      d="m 756.61,394.07 0.03,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9530"
    />
    <path
      d="m 755.16,393.54 v 1.09 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9531"
    />
    <path
      d="m 755.55,393.58 h 1.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9532"
    />
    <path
      d="m 754.72,393.58 h -1.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9533"
    />
    <path
      d="m 755.11,395.31 -0.17,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9534"
    />
    <path
      d="m 754.94,395.24 -0.06,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9535"
    />
    <path
      d="m 754.88,395.08 0.06,-0.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9536"
    />
    <path
      d="m 754.94,394.91 0.17,-0.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9537"
    />
    <path
      d="m 755.11,394.85 0.16,0.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9538"
    />
    <path
      d="m 755.27,394.91 0.07,0.17"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9539"
    />
    <path
      d="m 755.34,395.08 -0.07,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9540"
    />
    <path
      d="m 755.27,395.24 -0.16,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9541"
    />
    <path
      d="m 723.27,433.91 v -3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9542"
    />
    <path
      d="m 723.27,430.17 h -3.74"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9543"
    />
    <path
      d="m 719.53,430.17 v 3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9544"
    />
    <path
      d="m 719.53,433.91 h 3.74"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9545"
    />
    <path
      d="M 722.04,433.56 H 720.2"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9546"
    />
    <path
      d="M 720.2,433.56 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9547"
    />
    <path
      d="m 720.2,433.56 -0.12,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9548"
    />
    <path
      d="m 720.08,433.53 -0.1,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9549"
    />
    <path
      d="m 719.98,433.44 -0.03,-0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9550"
    />
    <path
      d="m 719.95,433.31 v -2.49 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9551"
    />
    <path
      d="M 719.95,430.82 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9552"
    />
    <path
      d="m 719.95,430.82 0.03,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9553"
    />
    <path
      d="m 719.98,430.7 0.1,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9554"
    />
    <path
      d="m 720.08,430.61 0.12,-0.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9555"
    />
    <path
      d="m 720.2,430.57 h 1.84"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9556"
    />
    <path
      d="M 722.04,430.57 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9557"
    />
    <path
      d="m 722.04,430.57 0.13,0.04"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9558"
    />
    <path
      d="m 722.17,430.61 0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9559"
    />
    <path
      d="m 722.26,430.7 0.03,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9560"
    />
    <path
      d="m 722.29,430.82 v 2.49 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9561"
    />
    <path
      d="M 722.29,433.31 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9562"
    />
    <path
      d="m 722.29,433.31 -0.03,0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9563"
    />
    <path
      d="m 722.26,433.44 -0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9564"
    />
    <path
      d="m 722.17,433.53 -0.13,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9565"
    />
    <path
      d="M 722.69,432.09 H 721.6"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9566"
    />
    <path
      d="m 722.66,432.47 v 1.05 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9567"
    />
    <path
      d="m 722.66,431.65 v -1.06 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9568"
    />
    <path
      d="M 720.93,432.03 721,431.87"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9569"
    />
    <path
      d="m 721,431.87 0.16,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9570"
    />
    <path
      d="m 721.16,431.8 0.16,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9571"
    />
    <path
      d="m 721.32,431.87 0.07,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9572"
    />
    <path
      d="m 721.39,432.03 -0.07,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9573"
    />
    <path
      d="m 721.32,432.19 -0.16,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9574"
    />
    <path
      d="M 721.16,432.26 721,432.19"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9575"
    />
    <path
      d="m 721,432.19 -0.07,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9576"
    />
    <path
      d="m 684.31,406.84 h -3.74"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9577"
    />
    <path
      d="m 680.57,406.84 v 3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9578"
    />
    <path
      d="m 680.57,410.58 h 3.74"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9579"
    />
    <path
      d="m 684.31,410.58 v -3.74 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9580"
    />
    <path
      d="m 683.96,408.07 v 1.84 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9581"
    />
    <path
      d="M 683.96,409.91 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9582"
    />
    <path
      d="m 683.96,409.91 -0.03,0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9583"
    />
    <path
      d="m 683.93,410.03 -0.09,0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9584"
    />
    <path
      d="m 683.84,410.13 -0.13,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9585"
    />
    <path
      d="m 683.71,410.16 h -2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9586"
    />
    <path
      d="M 681.22,410.16 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9587"
    />
    <path
      d="m 681.22,410.16 -0.12,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9588"
    />
    <path
      d="m 681.1,410.13 -0.09,-0.1"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9589"
    />
    <path
      d="m 681.01,410.03 -0.04,-0.12"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9590"
    />
    <path
      d="m 680.97,409.91 v -1.84 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9591"
    />
    <path
      d="M 680.97,408.07 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9592"
    />
    <path
      d="m 680.97,408.07 0.04,-0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9593"
    />
    <path
      d="m 681.01,407.94 0.09,-0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9594"
    />
    <path
      d="m 681.1,407.85 0.12,-0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9595"
    />
    <path
      d="m 681.22,407.82 h 2.49"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9596"
    />
    <path
      d="M 683.71,407.82 Z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9597"
    />
    <path
      d="m 683.71,407.82 0.13,0.03"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9598"
    />
    <path
      d="m 683.84,407.85 0.09,0.09"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9599"
    />
    <path
      d="m 683.93,407.94 0.03,0.13"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9600"
    />
    <path
      d="m 682.49,407.42 v 1.09 z"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9601"
    />
    <path
      d="m 682.87,407.46 h 1.05"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9602"
    />
    <path
      d="m 682.05,407.46 h -1.06"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9603"
    />
    <path
      d="m 682.43,409.18 -0.16,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9604"
    />
    <path
      d="m 682.27,409.11 -0.07,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9605"
    />
    <path
      d="m 682.2,408.95 0.07,-0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9606"
    />
    <path
      d="m 682.27,408.79 0.16,-0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9607"
    />
    <path
      d="m 682.43,408.72 0.16,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9608"
    />
    <path
      d="m 682.59,408.79 0.07,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9609"
    />
    <path
      d="m 682.66,408.95 -0.07,0.16"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9610"
    />
    <path
      d="m 682.59,409.11 -0.16,0.07"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9611"
    />
    <path
      d="m 690.24,373.07 h 1.24"
      fill="none"
      stroke="#000000"
      stroke-width="0.5"
      id="path9612"
    />
  </svg>
</template>
