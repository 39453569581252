<template>
  <v-card class="roi-predictions-bar">
    <v-app-bar elevation="1" class="pl-13 pr-16">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        {{
          isRouteVisualAidSummary ? `${defaultSite.name}` : 'ROI Predictions'
        }}
      </v-app-bar-title>
      <div
        v-if="isRouteVisualAidSummary"
        class="d-flex flex-row align-end ml-auto"
      >
        <v-btn
          class="rounded-2 mr-3"
          color="primary"
          variant="flat"
          size="large"
          @click="gotToROIPredictionsList"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold"
          >
            Back to ROI's List
          </div>
        </v-btn>
      </div>
      <v-spacer />
      <v-autocomplete
        v-if="isRouteVisualAidList"
        class="mt-7"
        :items="ROIPredictionsSites"
        :item-title="(item) => item.name"
        v-model="selectedSite"
        bg-color="white"
        color="primary"
        return-object
        variant="outlined"
        density="compact"
        @update:modelValue="
          (selectedSite) => setDefaultSiteFromROIPredictionsDomain(selectedSite)
        "
      >
        <template v-slot:label>
          <span class="text-primary text-caption font-weight-bold"> Site </span>
        </template>
      </v-autocomplete>
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ROIPredictionsBar',

  props: {
    ROIPredictionsSites: {
      type: Array,
      default: () => [],
    },
  },

  async created() {
    await this.setDefaultSiteFromROIPredictionsDomain(this.defaultSite);
    this.siteId = this.defaultSite.id;
    this.gotToROIPredictionsList();
  },

  data() {
    return {
      toolBarHeight: 50,
      toolBarWidth: 1175,
      selectedSite: null,
      siteId: null,
    };
  },

  computed: {
    ...mapGetters({
      isLoading: 'SitesStore/ROIPredictionsStore/roiPredictionsBySiteIsLoading',
      defaultSite: 'session/defaultSite',
    }),

    isRouteVisualAidSummary() {
      return this.$route.meta.visualAid === 'summary';
    },

    isRouteVisualAidList() {
      return this.$route.meta.visualAid === 'list';
    },
  },

  watch: {
    siteId: {
      handler(current, initial) {
        if (initial && current !== initial) this.gotToROIPredictionsList();
      },
    },
  },

  methods: {
    ...mapActions({
      getROIPredictionsBySite:
        'SitesStore/ROIPredictionsStore/getROIPredictionsBySite',
      setDefaultSite: 'session/setDefaultSite',
    }),

    async setDefaultSiteFromROIPredictionsDomain(selectedSite) {
      this.selectedSite = selectedSite || this.ROIPredictionsSites[0];
      const siteId = this.selectedSite.siteId || this.selectedSite.id;

      try {
        this.$emit('isLoading', true);
        await this.getROIPredictionsBySite({
          siteId,
        });
        this.setDefaultSite(this.selectedSite);
        this.siteId = siteId;
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit('isLoading', false);
      }
    },

    gotToROIPredictionsList() {
      this.$router.push({
        name: 'ROIPredictionsList',
        params: {
          siteId: this.selectedSite.siteId || this.selectedSite.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.roi-predictions-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}
</style>
