import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
const apiVersion = 'v1';

import {
  IStrategyPayload,
  StrategySite,
  ISelectedTemplate,
  IStrategyTemplate,
} from '@/Core.Service.Domain/Strategies/Strategies/types/strategies.ts';

const getStrategyTemplates = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/StrategyTemplate`);

    const { data } = result.data;
    data.forEach((site: StrategySite) => {
      RemapObjectKeysAdapter(site, ['siteId'], ['id']);
    });
    return data;
  } catch (err) {
    return err;
  }
};

const deleteStrategyTemplate = async (template: ISelectedTemplate) => {
  const data = {
    id: template.id,
  };
  try {
    const result = await window.axios.delete(
      `/${apiVersion}/strategytemplate/delete`,
      { data }
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getInputTypes = async () => {
  const inputs = await window.axios.get(
    `/v1/strategy-inputs/template-input-types`
  );
  return inputs.data.data;
};

const validateRule = async (payload: IStrategyPayload) => {
  const {
    inputTypeName,
    strategyName,
    strategyDescription,
    frequency,
    editorData,
  } = payload;
  const strategyPayload = {
    inputTypeName: inputTypeName,
    strategyName: strategyName,
    strategyDescription: strategyDescription,
    strategyContent: editorData,
    strategyFrequency: frequency,
  };
  try {
    const result = await window.axios.post(
      `/${apiVersion}/strategytemplate/validate-rule`,
      strategyPayload
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const saveStrategyTemplate = async (payload: IStrategyPayload) => {
  const {
    strategyName,
    strategyDescription,
    inputTypeName,
    frequency,
    editorData,
  } = payload;
  const strategyTemplatePayload = {
    strategyName,
    strategyDescription,
    inputTypeName,
    strategyFrequency: frequency,
    strategyContent: editorData,
  };
  try {
    await window.axios.post(
      `/v1/strategytemplate/create`,
      strategyTemplatePayload
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

const getEditorSettingsForInput = async (inputType: string) => {
  try {
    const settings = await window.axios.get(
      `/${apiVersion}/strategytemplate/${inputType}/settings`
    );

    return settings.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getStrategyTemplateById = async (id: string) => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/strategytemplate/${id}/template`
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getEditorSettingsForStrategyTemplate = async (id: string) => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/strategytemplate/${id}/settings`
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateStrategyTemplate = async (payload: IStrategyTemplate) => {
  const {
    strategyId,
    strategyName,
    strategyDescription,
    inputTypeName,
    strategyFrequency,
    strategyContent,
  } = payload;
  const strategyTemplatePayload = {
    strategyId,
    strategyName,
    strategyDescription,
    inputTypeName,
    strategyFrequency,
    strategyContent,
  };
  try {
    await window.axios.put(
      `/${apiVersion}/strategytemplate/update`,
      strategyTemplatePayload
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getStrategyTemplates,
  deleteStrategyTemplate,
  getInputTypes,
  validateRule,
  saveStrategyTemplate,
  getEditorSettingsForInput,
  getStrategyTemplateById,
  getEditorSettingsForStrategyTemplate,
  updateStrategyTemplate,
};
