<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="nonRoutineEventsList"
      :items-per-page="10"
      :search="searchTerm"
      :header-props="headerProps"
      class="elevation-1"
      no-data-text="No non-routine events found"
    >
      <!--Headers slot-->
      <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="column.class"
            @click="() => toggleSort(column)"
          >
            {{ column.title }}
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)" size="sm" />
            </template>
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <!--Name slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.name }}
            </span>
          </td>
          <!--NRE Type slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.nreTypeName }}
            </span>
          </td>
          <!--Start Date slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ dateTimeToUSFormatFactory(item.startDateTime) }}
            </span>
          </td>
          <!--End Date slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ dateTimeToUSFormatFactory(item.endDateTime) }}
            </span>
          </td>
          <!--Comment slot-->
          <td>
            <span class="text-primary text-subtitle-1 cut-text">
              {{ item.comment }}
            </span>
          </td>
          <!--Actions buttons slot-->
          <td>
            <ActionButtonsVue
              :buttonsList="actionButtons"
              :outlined="true"
              :disabled="!userCanUpdate || !userCanCreate || !userCanDelete"
              :item="item"
              class="ml-n2"
            />
          </td>
          <!--Assigned to Model slot-->
          <td>
            <v-tooltip location="right" v-if="item.analyticsModels.length">
              <template v-slot:activator="{ props }">
                <v-icon
                  color="primary"
                  v-bind="props"
                  style="cursor: pointer"
                  @click="handleAssignedModel(item.analyticsModels[0].id)"
                >
                  mdi-check-all
                </v-icon>
              </template>
              <span> Assigned to: {{ item.analyticsModels[0].name }} </span>
            </v-tooltip>
            <v-btn
              v-else
              :disabled="!userCanUpdate || !userCanCreate || !userCanDelete"
              variant="outlined"
              color="primary"
              @click="handleAssignModel"
            >
              Assign
              <v-icon end> mdi-collage </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteNonRoutineEvent
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :nreInfo="NreInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-container>
</template>

<script>
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';

import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import ConfirmDialogDeleteNonRoutineEvent from '@/Core.Service.Domain/Analytics/NonRoutineEvents/Components/ConfirmDialogDeleteNonRoutineEvent';

export default {
  name: 'NonRoutineEventsList',

  props: {
    NonRoutineEventsList: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ActionButtonsVue,
    ConfirmDialogDeleteNonRoutineEvent,
  },

  computed: {
    ...mapGetters({
      searchTerm:
        'AnalyticsStore/AnalyticsNonRoutineEventsStore/getAnalyticsNREsearchTerm',
    }),

    nonRoutineEventsList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems)
        : sortAlphabetically(this.NonRoutineEventsList);
    },

    userCanCreate() {
      return this.$can('create', 'analytics');
    },
    userCanDelete() {
      return this.$can('delete', 'analytics');
    },
    userCanUpdate() {
      return this.$can('update', 'analytics');
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['nreTypeName', 'name'],
        this.NonRoutineEventsList
      );
    },
  },

  data(instance) {
    return {
      filteredItems: [],
      showConfirmDialog: false,
      NreInfo: {},
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          title: 'Name',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'name',
          sortable: true,
        },
        {
          title: 'Type',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'nreTypeName',
          sortable: true,
        },
        {
          title: 'Start Date',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'startDateTime',
          sortable: true,
        },
        {
          title: 'End Date',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'endDateTime',
          sortable: true,
        },
        {
          title: 'Comments',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'comment',
          sortable: true,
        },
        {
          title: 'Assigned to Model',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'analyticsModels[0]',
          sortable: true,
        },
        {
          title: 'Actions',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          key: 'actions',
          sortable: false,
        },
      ],
      actionButtons: [
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          action: (item) => {
            if (
              !instance.userCanUpdate ||
              !instance.userCanCreate ||
              !instance.userCanDelete
            )
              return;
            instance.$router.push({
              name: 'NREEdit',
              params: {
                nonRoutineEventId: item.id,
              },
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          disabled: (item) => item.analyticsModels.length > 0,
          action: (item) => {
            if (
              !instance.userCanUpdate ||
              !instance.userCanCreate ||
              !instance.userCanDelete ||
              item.analyticsModels.length > 0
            )
              return;
            instance.NreInfo = item;
            instance.showConfirmDialog = true;
          },
        },
      ],
    };
  },

  methods: {
    dateTimeToUSFormatFactory,

    handleAssignModel() {
      if (!this.userCanUpdate || !this.userCanCreate || !this.userCanDelete)
        return;
      this.$router.push({
        name: 'Models',
      });
    },

    handleAssignedModel(id) {
      if (!this.userCanUpdate || !this.userCanCreate || !this.userCanDelete)
        return;
      this.$router.push({
        name: 'ModelEdit',
        params: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-table .v-table__wrapper > table > thead > tr > th {
  border: none !important;
}

.v-table {
  color: $primary !important;
}
</style>
