<template>
  <section style="height: 100%">
    <!-- <loading
      v-model:active="isLoading"
      :loader="loader"
      color="#789e90"
      backgroundColor="#ecf2f6"
      :opacity="loaderOpacity"
    > -->
    <!-- <template v-slot:default>
        <span>Solar Savings</span>
      </template> -->
    <!-- </loading> -->
    <KioskPowerBIReport
      :reportId="reportId"
      :hasSubmit="false"
      ref="powerBIReport"
    ></KioskPowerBIReport>
  </section>
</template>

<script>
import KioskPowerBIReport from './KioskPowerBIReport';
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: 'KioskPowerBIReport',

  data() {
    return {
      reportId: this.$route.params.id,
      isLoading: true,
      loader: 'dots',
      loaderOpacity: 100,
      loadTimer: this.$route.params.loadTimer ?? 9000,
    };
  },
  components: {
    KioskPowerBIReport,
    // loading: Loading,
  },
  computed: {},
  async created() {
    //const authFilter = { $schema: "http://powerbi.com/product/schema#basic"};
    //this.reportFilters = [authFilter];
    setTimeout(() => {
      this.isLoading = false;
    }, this.loadTimer);
  },
};
</script>
