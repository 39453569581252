<template>
  <v-card class="users-bar">
    <v-app-bar elevation="1" class="px-11">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        Users
      </v-app-bar-title>

      <v-text-field
        class="mt-1 mr-5"
        v-model="searchTerm"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        bg-color="white"
        variant="outlined"
        density="compact"
        :placeholder="placeholder"
        :clearable="true"
        @click:clear="handleSearchInput"
        @update:modelValue="handleSearchInput"
      />

      <v-btn
        class="rounded-2 mr-2"
        color="primary"
        variant="flat"
        size="large"
        @click="$emit('add-user')"
      >
        <span class="text-subtitle-2 text-white text-truncate font-weight-bold">
          Add new user
        </span>
      </v-btn>
      <v-spacer />
    </v-app-bar>
  </v-card>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue', 'add-user']);

const searchTerm = ref(props.modelValue);

watch(searchTerm, (newValue) => {
  emit('update:modelValue', newValue);
});

const handleSearchInput = (value) => {
  if (typeof value === 'object') {
    searchTerm.value = '';
  } else {
    searchTerm.value = value;
  }
};

const placeholder = ref('Search for an User');
</script>

<style lang="scss" scoped>
.users-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
