<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 204 117"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
      id="interactive-group"
    >
      <path
        d="M55.8398 22.9991C55.9401 22.5092 55.9397 22.5091 55.9393 22.5091L55.9384 22.5089L55.9361 22.5084L55.9299 22.5072L55.9106 22.5036C55.8948 22.5008 55.8731 22.497 55.8459 22.4929C55.7914 22.4845 55.7146 22.4741 55.6183 22.4647C55.4258 22.446 55.1539 22.4311 54.8243 22.4443C54.1661 22.4706 53.2701 22.6092 52.3182 23.0591C50.4427 23.9453 48.4664 25.9819 47.5857 30.4082H28.5V1H153.5V30.4082H152.57V22.9991V22.5255L152.097 22.4998L152.07 22.9991C152.097 22.4998 152.097 22.4998 152.097 22.4998L152.096 22.4997L152.094 22.4996L152.088 22.4993L152.068 22.4985C152.052 22.498 152.029 22.4974 152 22.497C151.943 22.4962 151.862 22.4963 151.759 22.4995C151.555 22.5061 151.267 22.5253 150.92 22.5757C150.228 22.6761 149.293 22.9018 148.331 23.4057C146.446 24.3922 144.53 26.4074 144.05 30.4082H120.471V22.9991V22.5312L120.004 22.5002L119.971 22.9991C120.004 22.5002 120.004 22.5001 120.003 22.5001L120.002 22.5001L120 22.4999L119.994 22.4996L119.974 22.4985C119.957 22.4977 119.933 22.4967 119.903 22.4959C119.844 22.4942 119.759 22.4931 119.652 22.4948C119.438 22.4983 119.137 22.5134 118.776 22.559C118.056 22.6499 117.087 22.8641 116.103 23.3612C115.117 23.8601 114.117 24.6442 113.349 25.8671C112.64 26.9967 112.145 28.477 112.023 30.4082H87.9805V22.9991V22.5319L87.5144 22.5002L87.4805 22.9991C87.5144 22.5002 87.5141 22.5002 87.5138 22.5002L87.513 22.5001L87.5108 22.5L87.5046 22.4996L87.4846 22.4985C87.4679 22.4977 87.4446 22.4967 87.4151 22.4959C87.3562 22.4942 87.2724 22.4931 87.167 22.4948C86.9563 22.4984 86.6585 22.5134 86.2998 22.559C85.5841 22.65 84.616 22.8641 83.6138 23.3591C81.6499 24.329 79.6267 26.3443 79.0585 30.4082H56.3398V22.9991V22.591L55.9401 22.5092L55.8398 22.9991Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path-1"
      />
      <path
        d="M1 60.5V38.5H28.5V37H30.5V53H36.5V57.5C30 58.5 28 61.5 27.5 66.5H36.5V67.5H3V60.5H1Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path-2"
      />
      <path
        d="M179.648 92.5385C179.648 92.5381 179.648 92.5378 179.148 92.5529L179.648 92.5378L179.633 92.0464L179.142 92.0529L171.139 92.1582L171.105 76.2114C175.54 75.5783 177.713 73.3354 178.748 71.1777C179.272 70.085 179.496 69.0319 179.59 68.2537C179.637 67.8638 179.652 67.5404 179.654 67.312C179.656 67.1977 179.654 67.107 179.652 67.0434C179.651 67.0116 179.65 66.9865 179.649 66.9687L179.648 66.9473L179.647 66.9408L179.647 66.9386L179.647 66.9378C179.647 66.9374 179.647 66.9371 179.148 66.9726L179.647 66.9371L179.615 66.4788L179.155 66.4726L171.083 66.3645L171.048 50.7035C175.038 50.5812 177.262 48.4207 178.466 46.2739C179.08 45.1781 179.431 44.0873 179.628 43.2735C179.727 42.8655 179.788 42.5245 179.824 42.2835C179.842 42.163 179.854 42.0672 179.862 42.0005C179.866 41.9671 179.869 41.9409 179.87 41.9225L179.872 41.9007L179.873 41.8943L179.873 41.8922L179.873 41.8914C179.873 41.8911 179.873 41.8909 179.375 41.8496L179.873 41.8909L179.918 41.3496H179.375H171.028L170.941 1H203V116H171.141L171.16 101.966C175.402 100.879 177.547 98.5994 178.614 96.5282C179.158 95.473 179.417 94.485 179.539 93.7591C179.6 93.3958 179.628 93.0967 179.64 92.8856C179.646 92.78 179.648 92.6963 179.649 92.6374C179.649 92.6079 179.649 92.5846 179.649 92.5679L179.649 92.5478L179.648 92.5415L179.648 92.5394L179.648 92.5385Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path-3"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-group {
  transition: fill-opacity 0.2s;
}
#interactive-group:hover {
  fill-opacity: 0.5;
}
</style>
