import { ITermsOfServiceResponse } from '@/Core.Service.Domain/TermsOfService/types';

const getTermsOfService = async () => {
  try {
    const result: ITermsOfServiceResponse = await window.axios.get(
      '/v1/termsofservices/accepted'
    );
    const { data } = result.data;
    return data;
  } catch (err: unknown) {
    console.error(err);
    return Promise.reject(err);
  }
};

export default {
  getTermsOfService,
};
