<template>
  <v-card class="report-bar">
    <v-app-bar
      :collapse="isFullscreen"
      :elevation="isFullscreen ? 2 : 1"
      :class="isFullscreen ? 'fullscreen-bar' : 'pl-13 pr-16'"
    >
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        {{ reportName }}
      </v-app-bar-title>
      <v-spacer />
      <v-btn
        class="ml-3 mt-1"
        color="primary"
        :variant="isFullscreen ? 'text' : 'flat'"
        :append-icon="isFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'"
        :ripple="false"
        @click="toggleFullscreen()"
      >
        <span class="mr-2" :class="{ 'mr-3': isFullscreen }">
          {{ isFullscreen ? 'Exit' : 'Fullscreen' }}
        </span>
      </v-btn>
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'ReportBar',

  props: {
    reportName: { type: String, required: true },
  },

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Report';
    },
  },

  data() {
    return {
      isFullscreen: false,
    };
  },

  created() {
    document.addEventListener('fullscreenchange', () => {
      this.isFullscreen = !!document.fullscreenElement;
    });
  },

  methods: {
    ...mapMutations({
      setReportSearchTerm: 'ViewSupportStore/ReportsStore/REPORT_SEARCH_TERM',
    }),

    handleSearchInput(value) {
      if (typeof value === 'object') {
        this.setReportSearchTerm('');
      } else {
        this.setReportSearchTerm(value);
      }
    },

    toggleFullscreen() {
      const container = this.$parent.$refs.reportContainer;
      if (!document.fullscreenElement) {
        if (container.requestFullscreen) {
          container.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.report-bar {
  z-index: 1;
}

.fullscreen-bar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.v-toolbar-title {
  max-width: 300px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
