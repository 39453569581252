<template>
  <v-container fluid class="mt-7">
    <v-row>
      <v-col :cols="smallBreakpoint ? 12 : 4" v-for="(group, g) in 6" :key="g">
        <v-card class="mx-auto" max-width="800" elevation="5">
          <v-table :class="`group${g}`" :id="`table-${g}`">
            <thead>
              <tr>
                <th>
                  <p
                    class="text-primary mt-4 font-weight-bold"
                    :class="!smallBreakpoint ? 'text-h6' : 'text-subtitle-1'"
                    v-html="getTitleByGroup(g)"
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in getSummaryByGroup(g)"
                :key="i"
                style="height: 50px"
              >
                <td>
                  <p class="text-primary text-subtitle-1">
                    {{ item.label }}
                  </p>
                </td>
                <td>
                  <p class="text-primary text-right font-weight-bold">
                    {{ item.value }}
                  </p>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ROIPredictionSummaryList',

  props: {
    summary: {
      type: Object,
    },
  },

  computed: {
    isTherms() {
      const regex = /Therms/gi;
      return regex.test(this.summary.unitOfMeasure?.value);
    },
  },

  mounted() {
    this.$nextTick(() => {
      const element = document.getElementById('table-0');
      if (this.isTherms) {
        element.classList.add('group0-therms');
      } else {
        element.classList.add('group0');
      }
    });
  },

  methods: {
    getSummaryByGroup(group) {
      return Object.values(this.summary).filter((item) => item.group === group);
    },

    getTitleByGroup(group) {
      switch (group) {
        case 0:
          return 'Building Information';
        case 1:
          return 'Utility Bills';
        case 2:
          return 'Electric and Gas EUIs';
        case 3:
          return 'Performance and Savings vs Median EUI';
        case 4:
          return `Savings Potential to Match Similar Buildings <br />(building type, similar size, similar region)`;
        case 5:
          return 'Energy Savings Summary';
        default:
          return '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.group0 {
  border-left: 7px solid #002db3;
}
.group0-therms {
  border-left: 7px solid #d81159;
}
.group1 {
  border-left: 7px solid #ef9a9a;
}
.group2 {
  border-left: 7px solid #c5cae9;
}
.group3 {
  border-left: 7px solid #b2dfdb;
}
.group4 {
  border-left: 7px solid #f0f4c3;
}
.group5 {
  border-left: 7px solid #ffe0b2;
}
</style>
