<template>
  <v-overlay
    class="message-overlay"
    :model-value="showOverlay"
    @click="close()"
  >
    <v-card
      class="p-2"
      width="800"
      height="100vh"
      max-height="750"
      color="primary"
    >
      <v-card-title>
        <span class="text-h5">{{ stepperTitle }}</span>
      </v-card-title>
      <v-card-text>
        <div class="container">
          <transition-group name="fade" mode="out-in">
            <template>
              <div v-if="isLoading" class="text-center loader" key="loader">
                <v-progress-circular
                  class="my-10"
                  :size="50"
                  color="primary"
                  indeterminate
                />
                <StepperLoader :stepperMessage="stepperTitle" />
              </div>
              <div key="stepper">
                <StepperIndex :replayMessages="replayMessages" />
              </div>
            </template>
          </transition-group>
        </div>
      </v-card-text>
    </v-card>
    <v-btn
      class="ml-2 close-button"
      color="primary"
      :disabled="isLoading"
      @click="close"
    >
      Close
    </v-btn>
  </v-overlay>
</template>
<script>
import StepperIndex from '@/Core.Service.Domain/Strategies/Strategies/Components/StepperIndex.vue';

import StepperLoader from '@/Core.Service.Domain/Strategies/Strategies/Components/StepperLoader.vue';

export default {
  name: 'StepperOverlay',

  props: {
    replayMessages: {
      type: Array,
    },
    showOverlay: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    stepperTitle: {
      type: String,
      default: 'Replaying the strategy',
    },
  },

  components: {
    StepperIndex,
    StepperLoader,
  },

  methods: {
    close() {
      if (this.isLoading) return;
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.message-overlay {
  z-index: 9999 !important;
}

.container {
  position: absolute;
  height: 80%;
  width: 96%;
  bottom: 70px;
  background-color: white;
  overflow-y: scroll;
  border-radius: 2px;
}

.close-button {
  position: absolute !important;
  bottom: 10px;
  right: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loader {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
