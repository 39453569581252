<template>
  <v-container grid-list-xl style="max-width: 1540px">
    <v-progress-linear :active="loading" :indeterminate="loading" absolute />
    <h1 v-if="!loading">
      Cost Saving Goals: <b>{{ analyticsProjectName }} - {{ measureName }}</b>
    </h1>
    <bigchart :costSavingsData="costSavingsGoals" v-if="showChart" />
  </v-container>
</template>

<script>
import api from '../_api';
import moment from 'moment';
import BigChart from './CostSavingsGoalBigChart.vue';
export default {
  components: {
    bigchart: BigChart,
  },

  data() {
    return {
      loading: true,
      showChart: false,
      projectId: null,
      projectMeasureId: null,
      year: null,
      version: null,
      costSavingsGoals: null,
      analyticsProjectName: null,
      measureName: null,
    };
  },

  async created() {
    this.projectId = this.$route.params.projectId;
    this.projectMeasureId = this.$route.params.projectMeasureId;
    this.year = this.$route.params.year;
    this.version = this.$route.params.version;

    await api
      .getChartData(
        this.projectId,
        this.projectMeasureId,
        this.year,
        this.version
      )
      .then((result) => {
        this.analyticsProjectName = result.data.analyticsProjectName;
        this.measureName = result.data.measureName;
        this.costSavingsGoals = this.mapChartSeriesData(
          result.data.costSavingValues
        );
        this.loading = false;
        this.showChart = true;
      });
  },

  methods: {
    mapChartSeriesData(costSavingValues) {
      const makeSeriesDataPoint = (item, property) => {
        var unixDate = moment(item.date).unix();
        var value = item[property];
        return [unixDate, value];
      };
      var seriesData = [
        {
          name: 'Savings',
          data: costSavingValues.map((item) =>
            makeSeriesDataPoint(item, 'value')
          ),
        },
      ];
      return seriesData;
    },
  },
};
</script>
