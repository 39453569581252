import ContactForm from '@/Core.Service.Domain/Contact/Components/ContactForm.vue';

const routesList = [];

const ContactFormRoute = {
  path: '/contact_us',
  name: 'ContactForm',
  component: ContactForm,
};

routesList.push(ContactFormRoute);

export default routesList;
