<template>
  <section>
    <confirm-dialog
      ref="confirmOpen"
      width="450"
      :title="getTitle"
      :showConfirmButton="showConfirmButton"
    >
      <span class="px-4 text-caption text-primary">
        {{ $appOldVersion }}
      </span>
    </confirm-dialog>
  </section>
</template>

<script>
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog';
import { getSchedulerAccount } from '../_api';

import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

export default {
  components: {
    'confirm-dialog': ConfirmDialog,
  },
  props: {
    controlId: { type: Number, required: true },
    controlType: { type: String, required: true },
    siteId: { type: Number, required: true },
  },
  data() {
    return {
      scheduleMenu: false,
      sharedSchedules: [],
      currentScheduleId: null,
      initialScheduleId: null,
      isLoading: false,
      isSaving: false,
      title: '',
      showConfirmButton: true,
    };
  },
  computed: {
    isDirty() {
      return this.currentScheduleId !== this.initialScheduleId;
    },
    getTitle() {
      return this.title;
    },
  },

  mounted() {
    this.openScheduleDashboard();
  },

  methods: {
    async openScheduleDashboard() {
      try {
        eventBus.$emit('isConfigLoading', true);
        this.title = 'Open Control Configuration Page?';
        const data = await getSchedulerAccount(this.siteId);
        eventBus.$emit('isConfigLoading', false);

        const msgHtml =
          `Will now open Pelican Management Site for the current site, <em>${data.account}</em>, in a new window or tab.` +
          '<br/><br/>' +
          'Please wait for the page to load, then select the <br/>' +
          "<strong style='background: #2988c8; color: white; padding: 4px'><large>Schedule Dashboard</large></strong> " +
          'tile (blue background, fourth from the top) to view or configure your schedules.';

        const confirmed = await this.$refs.confirmOpen.confirm(msgHtml);

        if (!confirmed) {
          return;
        }

        const windowOptions =
          'modal=yes, toolbar=no, location=no, directories=no, status=no, menubar=no,' +
          'height=400,width=600,top=20,left=5000';

        this.openWindowWithPost(
          data.url,
          windowOptions,
          `dash_scheduler_${data.account}`,
          {
            username: data.user,
            password: data.pass,
          }
        );
      } catch (error) {
        console.error(error);
        this.showConfirmButton = false;
        this.title = 'Permission Error';
        const msgHtml =
          'You do not have control permission for scheduling. Please contact your account or administrator Intech Support to gain access to scheduling';

        this.$refs.confirmOpen.confirm(msgHtml);
      } finally {
        eventBus.$emit('isConfigLoading', false);
      }
    },

    openWindowWithPost(url, windowoption, name, params) {
      var form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', url);
      form.setAttribute('target', name);
      for (var i in params) {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = i;
        input.value = params[i];
        form.appendChild(input);
      }
      document.body.appendChild(form);
      window.open('about:blank', name, windowoption);
      form.submit();
      document.body.removeChild(form);
    },
  },
};
</script>
