/**
 * @param {Object} baseObject
 * @param {Object} compositeObject
 * @param {Array} compositeObjectKeys
 * @returns Object
 * @description Composite object with base object and composite object
 * @example CompositeObject({ a: 1, b: 2, c: 3 }, { u: 3, v: 2, x: 1, y: 2, z: 3 }, ['x', 'y', 'z']);
 * returns { a: 1, b: 2, c: 3, x: 1, y: 2, z: 3 }
 * @example CompositeObject({ a: 1, b: 2, c: 3 }, { u: 3, v: 2, x: 1, y: 2, z: 3 });
 * returns { a: 1, b: 2, c: 3, u: 3, v: 2, x: 1, y: 2, z: 3 }
 */
export const CompositeObject = (
  baseObject,
  compositeObject,
  compositeObjectKeys
) => {
  return Object.assign(
    {},
    baseObject,
    compositeObjectKeys
      ? compositeObjectKeys.reduce((acc, key) => {
          acc[key] = compositeObject[key];
          return acc;
        }, {})
      : compositeObject
  );
};
