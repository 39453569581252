<template>
  <Form ref="obs" v-slot="{ invalid }">
    <v-container grid-list-xl>
      <h1>Measure Detail</h1>
      <form @submit.prevent="submit">
        <v-sheet elevation="2" class="pa-4">
          <validation-summary :messages="server_errors" />
          <v-text-field
            :rules="[(v) => !!v || 'Name is required']"
            v-model="measure.measureName"
            label="Name"
          ></v-text-field>
          <v-text-field
            v-model="measure.measureCode"
            label="Code"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-text-field
                v-model="measure.eul"
                type="number"
                step="0.01"
                min="0.00"
                label="EUL"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="measure.nTGValue"
                type="number"
                step="0.01"
                min="0.00"
                label="NTG Value"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                :items="measureTypes"
                v-model="measure.measureType"
                label="Measure Type"
                hint="select a type for this measure"
                persistent-hint
                item-title="name"
                item-value="name"
                :loading="loading"
              ></v-select>
            </v-col>

            <v-col>
              <v-select
                :items="installTypes"
                v-model="measure.installType"
                label="Install Type"
                hint="select an install type for this measure"
                persistent-hint
                item-title="name"
                item-value="name"
                :loading="loading"
              ></v-select>
            </v-col>

            <v-col>
              <v-select
                :items="marketSectors"
                v-model="measure.marketSector"
                label="Market Sector"
                hint="select a market sector for this measure"
                persistent-hint
                item-title="name"
                item-value="name"
                :loading="loading"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                v-if="$can('create', 'analytics')"
                type="submit"
                color="primary"
                :disabled="invalid"
                >Save</v-btn
              >
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </form>
      <span class="px-4 text-caption text-primary">
        {{ $appOldVersion }}
      </span>
    </v-container>
  </Form>
</template>

<script>
import ValidationSummary from '@/components/Dialogs/ValidationSummary';
import { Form, defineRule } from 'vee-validate';
import api from '../_api';
import errorSummary from '../../../_mixins/error_utilities';

export default {
  name: 'AnalyticsMandVMeasuresDetails',
  components: {
    'validation-summary': ValidationSummary,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
  },

  mixins: [errorSummary],

  data() {
    return {
      mode: {
        EDIT: 'edit',
        NEW: 'new',
      },
      server_errors: [],
      currentMode: {},
      measure: {},
      measures: [],
      measureTypes: [],
      installTypes: [],
      marketSectors: [],
      loading: false,
    };
  },

  created() {
    this.registerCustomValidators();
  },

  async mounted() {
    this.setCurrentMode();
    await this.getMeasureTypes();
    await this.getInstallTypes();
    await this.getMarketSectors();

    if (this.currentMode === this.mode.EDIT) {
      this.getMeasure();
    }
  },

  methods: {
    async getMeasure() {
      api
        .getMeasureById(this.$route.params.measuresId)
        .then((response) => {
          this.measure = response;
        })
        .catch((error) => {
          this.server_errors = this.errorSummary(error);
        });
    },

    getMeasureTypes() {
      api.getMeasureTypes().then((response) => {
        this.measureTypes = response;
      });
    },

    getInstallTypes() {
      api.getMeasureInstallTypes().then((response) => {
        this.installTypes = response;
      });
    },

    getMarketSectors() {
      api.getMeasureMarketSectors().then((response) => {
        this.marketSectors = response;
      });
    },

    async submit() {
      switch (this.currentMode) {
        case this.mode.EDIT:
          this.update();
          break;
        default:
          this.create();
          break;
      }
    },

    async create() {
      try {
        let result = await api.addMeasure(this.measure);
        this.$toast.show(
          `Measure added with id: ${result.id}`,
          null,
          'success'
        );
        this.$router.push({ name: 'AnalyticsMandVMeasuresIndex' });
      } catch (error) {
        this.$toast.show(error, null, 'error');
      }
    },

    async update() {
      try {
        let result = await api.updateMeasure(this.measure);
        this.$toast.show(
          `Measure with id ${result.id} updated`,
          null,
          'success'
        );
        this.$router.push({ name: 'AnalyticsMandVMeasuresIndex' });
      } catch (error) {
        this.$toast.show(error, null, 'error');
      }
    },

    registerCustomValidators() {
      defineRule('duplicate_name', (value, [field]) => {
        let valid = true;
        if (this.projects) {
          let existingNames = this.projects.map((a) => a.projectName);
          //filter project name out of list if in edit
          if (this.currentMode === this.mode.EDIT) {
            existingNames = existingNames.filter((n) => {
              return n !== this.projectName;
            });
          }
          valid = existingNames.indexOf(value) === -1;
          if (!valid) {
            return `${field} is already used by another measure.  Enter a different name.`;
          }
        }
        return valid;
      });
    },

    setCurrentMode() {
      this.currentMode =
        this.$route.params.measuresId === 'new' ||
        !this.$route.params.measuresId
          ? this.mode.NEW
          : this.mode.EDIT;
    },

    handleCancelCrud() {
      this.$router.push({ name: 'AnalyticsMandVMeasuresIndex' });
    },
  },
};
</script>
