<template>
  <v-card class="rounded-2 mt-4">
    <v-container fluid>
      <v-tabs align-tabs="start" v-model="activeTab" color="primary">
        <v-tab v-for="(tab, i) in tabs" :key="i" :value="tab.id">
          <span class="text-primary">
            {{ tab.title }}
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-window v-model="activeTab">
        <v-tabs-window-item
          v-for="(tab, i) in tabs"
          :key="`tab-item-${i}`"
          v-model="tab.id"
          :value="tab.id"
        >
          <component
            :is="tab.component"
            :summary="mappedSummary"
            :utilityBills="RoiUtilityBills"
            :siteCbecs="SiteCbecs"
          />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { markRaw } from 'vue';

import {
  dateTimeToUSFormatFactory,
  formatNumberToUS,
} from '@/Core.Patterns/Factory';

import ROIPredictionSummaryList from '@/Core.Service.Domain/Sites/ROIPredictions/Components/ROIPredictionSummaryList.vue';

import ROIPredictionUtilityBills from '@/Core.Service.Domain/Sites/ROIPredictions/Components/ROIPredictionUtilityBills.vue';

import ROIPredictionCbecs from '@/Core.Service.Domain/Sites/ROIPredictions/Components/ROIPredictionCbecs.vue';

export default {
  name: 'ROIPredictionSummary',

  components: {
    ROIPredictionSummaryList: markRaw(ROIPredictionSummaryList),
    ROIPredictionUtilityBills: markRaw(ROIPredictionUtilityBills),
    ROIPredictionCbecs: markRaw(ROIPredictionCbecs),
  },

  computed: {
    ...mapGetters({
      roiPredictionBySiteBySummaryId:
        'SitesStore/ROIPredictionsStore/roiPredictionBySiteBySummaryId',
    }),
  },

  created() {
    this.mapROIPredictionBySiteBySummaryId();
  },

  data() {
    return {
      activeTab: 0,
      isTherms: false,
      tabs: [
        {
          title: 'ROI Prediction Summary',
          id: 'summary',
          component: ROIPredictionSummaryList,
        },
        {
          title: 'Utility Bill Details',
          id: 'bills',
          component: ROIPredictionUtilityBills,
        },
        {
          title: 'CBECS',
          id: 'cbecs',
          component: ROIPredictionCbecs,
        },
      ],

      mappedSummary: {},
      RoiUtilityBills: [],
      SiteCbecs: [],

      labelMapping: {
        unitOfMeasure: { label: 'Unit Of Measure', format: 'value', group: 0 },
        state: { label: 'State', format: 'value', group: 0 },
        siteType: { label: 'Site Type', format: 'value', group: 0 },
        siteSquareFootage: {
          label: 'Site Square Footage',
          format: 'sq ft',
          group: 0,
        },
        utilityBillsTotalVolume: {
          label: 'Total Volume',
          format: '',
          group: 1,
        },
        utilityBillsTotalCost: {
          label: 'Total Cost',
          format: 'currency',
          group: 1,
          truncDecimals: true,
        },
        utilityBillsTotalUsagePerSquareFoot: {
          label: 'Total Usage Per Square Foot',
          format: '',
          group: 1,
        },
        utilityBillsTotalCostPerSquareFoot: {
          label: 'Total Cost Per Square Foot',
          format: 'currency',
          group: 1,
        },
        utilityBillsMonthlyUsageUtilityRateAverage: {
          label: '12-Month Utility Rate Average',
          format: 'currency-over-unit-of-measure',
          group: 1,
        },
        aggregateUsage: {
          label: 'Aggregate Usage',
          format: '',
          group: 2,
        },
        aggregateCost: {
          label: 'Aggregate Cost',
          format: 'currency-over-sf',
          group: 2,
        },
        medianUsage: { label: 'Median Usage', format: '', group: 2 },
        euiVsMedianEuiPercentage: {
          label: 'EUI Vs Median EUI',
          format: 'percentage',
          group: 3,
          roundPercentage: true,
        },
        savingsToAchieveMedianEuiPercentage: {
          label: 'Savings To Achieve Median EUI',
          format: 'percentage',
          group: 3,
          roundPercentage: true,
        },
        savingsToAchieveMedianEuiUsagePerYear: {
          label: 'Savings To Achieve Median EUI Usage Per Year',
          format: '',
          group: 3,
          truncDecimals: true,
        },
        savingsVsMedianEuiCostSavingsPerYear: {
          label: 'Savings Vs Median EUI Cost Savings Per Year',
          format: 'currency',
          group: 3,
          truncDecimals: true,
        },
        euiVsAverageEuiPercentage: {
          label: 'EUI Vs Average EUI',
          format: 'percentage',
          group: 4,
          roundPercentage: true,
        },
        archiveAverageEuiPercentage: {
          label: 'Achieve Average EUI',
          format: 'percentage',
          group: 4,
          roundPercentage: true,
        },
        archiveAverageEuiUsagePerYear: {
          label: 'Achieve Average EUI Usage Per Year',
          format: '',
          group: 4,
          truncDecimals: true,
        },
        savingsVsSimilarBuildingsCostSavingsPerYear: {
          label: 'Savings Vs Similar Buildings Cost Savings Per Year',
          format: 'currency',
          group: 4,
          truncDecimals: true,
        },
        benchmarkPercentile: {
          label: 'Benchmark Percentile',
          format: 'percentage',
          group: 5,
          roundPercentage: true,
        },
        aggressiveSavingsUsage: {
          label: 'Aggressive Savings Usage',
          format: '',
          group: 5,
          truncDecimals: true,
        },
        aggressiveSavingsCost: {
          label: 'Aggressive Savings Cost',
          format: 'currency',
          group: 5,
          truncDecimals: true,
        },
        conservativeSavingsUsage: {
          label: 'Conservative Savings Usage',
          format: '',
          group: 5,
          truncDecimals: true,
        },
        conservativeSavingsCost: {
          label: 'Conservative Savings Cost',
          format: 'currency',
          group: 5,
          truncDecimals: true,
        },
        roiPredictionLastModifiedDateTime: {
          label: 'Last Modified',
          format: 'datetime',
          group: 0,
        },
      },
    };
  },

  methods: {
    dateTimeToUSFormatFactory,
    formatNumberToUS,

    mapROIPredictionBySiteBySummaryId() {
      this.RoiUtilityBills = this.roiPredictionBySiteBySummaryId.utilityBills;
      this.SiteCbecs = this.roiPredictionBySiteBySummaryId.cbecs;

      this.mappedSummary = { ...{}, ...this.roiPredictionBySiteBySummaryId };

      this.labelMapping.savingsToAchieveMedianEuiUsagePerYear.format =
        this.formatUnitOfMeasureOverYear(this.mappedSummary.unitOfMeasure);

      this.labelMapping.utilityBillsTotalVolume.format =
        this.formatUnitOfMeasureOverYear(this.mappedSummary.unitOfMeasure);

      this.labelMapping.utilityBillsTotalUsagePerSquareFoot.format =
        this.formatUnitOfMeasureOverYear(this.mappedSummary.unitOfMeasure);

      this.labelMapping.aggregateUsage.format =
        this.formatUnitOfMeasureOverSquareFeetOverYear(
          this.mappedSummary.unitOfMeasure
        );

      this.labelMapping.medianUsage.format =
        this.formatUnitOfMeasureOverSquareFeetOverYear(
          this.mappedSummary.unitOfMeasure
        );

      this.labelMapping.aggressiveSavingsUsage.format =
        this.formatUnitOfMeasureOverYear(this.mappedSummary.unitOfMeasure);

      this.labelMapping.conservativeSavingsUsage.format =
        this.formatUnitOfMeasureOverYear(this.mappedSummary.unitOfMeasure);

      this.labelMapping.archiveAverageEuiUsagePerYear.format =
        this.formatUnitOfMeasureOverYear(this.mappedSummary.unitOfMeasure);

      delete this.mappedSummary.utilityBills;
      delete this.mappedSummary.cbecs;

      this.mappedSummary = this.formatObject(this.mappedSummary);
    },

    formatObject(originalObj) {
      const formattedObj = {};

      for (const key in originalObj) {
        if (this.labelMapping[key]) {
          formattedObj[key] = {
            label: this.labelMapping[key].label,
            value: this.formatValue(
              originalObj[key],
              this.labelMapping[key].format,
              this.labelMapping[key].truncDecimals,
              this.labelMapping[key].roundPercentage
            ),
            group: this.labelMapping[key].group,
          };
        }
      }

      return formattedObj;
    },

    formatValue(value, format, truncDecimals = false, roundPercentage = false) {
      switch (format) {
        case 'datetime':
          return this.dateTimeToUSFormatFactory(value, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          });
        case 'currency':
          return `$${this.formatNumberToUS(
            truncDecimals ? Math.trunc(value) : value
          )}`;
        case 'currency-over-sf':
          return `$${this.formatNumberToUS(
            truncDecimals ? Math.trunc(value) : value
          )}/sf`;
        case 'currency-over-unit-of-measure':
          return `$${this.formatNumberToUS(
            truncDecimals ? Math.trunc(value) : value
          )}/${this.formatUnitOfMeasure(this.mappedSummary.unitOfMeasure)}`;
        case 'percentage':
          return `${roundPercentage ? value.toFixed(1) : value}%`;
        case 'value':
          return value;
        default:
          return `${this.formatNumberToUS(
            truncDecimals ? Math.trunc(value) : value
          )} ${format}`;
      }
    },
    formatUnitOfMeasureOverYear(unitOfMeasure) {
      return `${this.formatUnitOfMeasure(unitOfMeasure)}/yr`;
    },
    formatUnitOfMeasureOverSquareFeetOverYear(unitOfMeasure) {
      return `${this.formatUnitOfMeasure(unitOfMeasure)}/sf/yr`;
    },
    formatUnitOfMeasure(unitOfMeasure) {
      switch (unitOfMeasure) {
        case 'Therms':
          return 'therm';
        case 'KWh':
          return 'kWh';
        default:
          return unitOfMeasure;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
}
</style>
