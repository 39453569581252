import { createApp } from 'vue';
import vuetify from './vuetify';

import ToastAlert from '@/Core.UI.Domain/Components/ToastAlert.vue';

import { ToastOptions } from '@/Core.UI.Domain/types/Toast';

const ToastPlugin = {
  install(app: {
    config: { globalProperties: { $toast: { show: unknown } } };
  }) {
    app.config.globalProperties.$toast = {
      show: (options: ToastOptions) => {
        const toastContainer = document.createElement('div');
        document.body.appendChild(toastContainer);

        const toastOptions: ToastOptions = {
          title: options.title,
          message: options.message,
          status: options.status || 'success',
          showToast: true,
        };

        const toastApp = createApp(ToastAlert, { toastOptions });
        toastApp.use(vuetify);
        toastApp.mount(toastContainer);

        setTimeout(() => {
          toastApp.unmount();
          document.body.removeChild(toastContainer);
        }, 15000);
      },
    };
  },
};

export default ToastPlugin;
