import { createApp } from 'vue';
import './plugins/axios';

import { useDisplay } from 'vuetify';
import vuetify from './plugins/vuetify';
// import './Core.UI.Domain/Styles/variables.scss';
// import './Core.UI.Domain/Styles/settings.scss';

import ToastPlugin from './plugins/toasts';
import ability from './plugins/abilities';
import { abilitiesPlugin } from '@casl/vue';

import './plugins/weather-icon-mapper';

import E360App from './E360App.vue';
import router from './router';
import { store } from './store';
import { globalConfig } from './configuration/config';
import { datadogRum } from '@datadog/browser-rum';
import { version } from '../package.json';

import VueTheMask from 'vue-the-mask';

const isProduction = import.meta.env.PROD;
const env = import.meta.env.MODE;

import HighchartsVue from 'highcharts-vue';

const app = createApp(E360App);

app.use(vuetify);
app.use(router);
app.use(store);
app.use(HighchartsVue);
app.use(VueTheMask);

app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
});
app.config.globalProperties.$appOldVersion = 'v1';
app.directive('blur', {
  mounted(el) {
    el.onfocus = (ev) => ev.target.blur();
  },
});
app.mixin({
  computed: {
    smallBreakpoint() {
      const { mobile } = useDisplay();
      return mobile.value;
    },
  },
});
app.use(ToastPlugin);

if (isProduction && globalConfig.get().DYNO_DD_APPLICATION_ID) {
  datadogRum.init({
    applicationId: globalConfig.get().DYNO_DD_APPLICATION_ID,
    clientToken: globalConfig.get().DYNO_DD_CLIENT_ID,
    site: 'us3.datadoghq.com',
    service: 'e360-web-app',
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

app.config.errorHandler = (err, vm, info) => {
  console.error('err =>', err);
  console.error('vm =>', vm);
  console.error('info =>', info);
};

app.mount('#app');
