export class createSiteContactMethodResourceModel {
  constructor(
    contactMethodId = 0,
    siteContactMethodValue = '',
    isPreferred = true
  ) {
    this.contactMethodId = contactMethodId;
    this.siteContactMethodValue = siteContactMethodValue;
    this.isPreferred = isPreferred;
  }
}
