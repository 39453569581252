<template>
  <v-dialog class="message-overlay" v-model="overlay" width="auto">
    <PopUpMessageTypeImage
      :slides="mappedMessages"
      @close:dialog="closeDialog"
    />
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import PopUpMessageTypeImage from '@/Core.Service.Domain/MessageCenter/Users/Components/PopUpMessageTypeImage.vue';

import { IUserMessage } from '@/Core.Service.Domain/MessageCenter/Users/types';

const props = defineProps<{
  messagesByUserList: IUserMessage[];
}>();

const overlay = ref(true);

const mappedMessages = computed(() => {
  return props.messagesByUserList.map((message) => ({
    messagingCenterMessageValue: message.messagingCenterMessageValue,
    messageCenterMessageLabel: message.messageCenterMessageLabel,
    messageCenterMessageTypeId: message.messageCenterMessageTypeId,
    userMessageCenterMessageId: message.userMessageCenterMessageId,
    messageCenterMessageId: message.messageCenterMessageId,
  }));
});
const route = useRoute();
watch(route, (v) => {
  if (v.name !== 'MessageCenterMyMessages') {
    overlay.value = true;
  } else {
    overlay.value = false;
  }
});

const closeDialog = () => {
  overlay.value = false;
};
</script>
<style lang="scss" scoped>
.message-overlay {
  z-index: 9999 !important;
}
</style>
