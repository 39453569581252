import encryptionService from '@/Core.Service.Domain/Controls/Controls.Common/Shared/encryptionService.js';

export const getControlById = async (controlType, controlId) => {
  let result = null;
  try {
    if (controlType === 'thermostat') {
      result = await window.axios.get(`/v1/thermostat/${controlId}`);
      return result.data.data;
    } else if (controlType === 'powercontrol') {
      result = await window.axios.get(`/v1/powercontrol/${controlId}`);
      return result.data.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateControl = async (controlType, updatedControl) => {
  let result = null;
  try {
    if (controlType === 'thermostat') {
      result = await window.axios.put('/v1/thermostat/update', updatedControl);
      return result.data.data;
    } else if (controlType === 'powercontrol') {
      result = await window.axios.put(
        '/v1/powercontrol/update',
        updatedControl
      );
      return result.data.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const deleteControl = async (controlType, controlId) => {
  let result = null;
  try {
    if (controlType === 'thermostat') {
      result = await window.axios.delete(`/v1/thermostat/delete/${controlId}`);
      return result.data.data;
    } else if (controlType === 'powercontrol') {
      result = await window.axios.delete(
        `/v1/powercontrol/delete/${controlId}`
      );
      return result.data.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const getControlsBySite = async (sites) => {
  const ids = sites.map((s) => s.id);
  const controlsResponse = await window.axios.get('/v1/controls/site', {
    params: { siteId: ids },
  });
  if (controlsResponse && controlsResponse.data) {
    return controlsResponse.data.data;
  } else {
    return null;
  }
};

export const getSitesWithControls = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/controls/sites');
    let sites = result.data.data;
    return sites;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const setThermostat = async (formData) => {
  let result = null;
  try {
    result = await window.axios.put(`/v1/thermostat/set`, formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const setPowerControl = async (formData) => {
  let result = null;
  try {
    result = await window.axios.put(`/v1/powercontrol/set`, formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const mapThermostatUsage = async (thermostatControls, sites) => {
  const ids = sites.map((c) => c.id);

  const response = await window.axios.get('/v1/thermostats/usage/latest', {
    params: { id: ids },
  });

  if (response && response.data && response.data.data) {
    let usages = response.data.data;

    const thermostats = thermostatControls.map((control) => {
      const usage = usages.find((u) => u.controlId === control.id);

      if (usage) {
        control.temperature = usage.temperature;
        control.co2Level = usage.co2Level;
        control.co2Setting = usage.co2Setting;
        control.coolSetting = usage.coolSetting;
        control.heatSetting = usage.heatSetting;
        control.runStatus = usage.runStatus;
        control.fanStatus = usage.fanStatus;
        control.mode = usage.mode;
        control.frontKeypad = usage.frontKeypad;
        control.maxHeatSetting = usage.maxHeatSetting;
        control.maxCoolSetting = usage.maxCoolSetting;
        control.minHeatSetting = usage.minHeatSetting;
        control.minCoolSetting = usage.minCoolSetting;
        control.thermostatId = usage.controlId;

        control = mapCommonProps(control, usage);
        control.lastUpdate = usage.timeSinceLastUpdate;
      }

      return control;
    });

    return thermostats;
  }
};

export const mapPowerControlUsage = async (powerControls, sites) => {
  const ids = sites.map((c) => c.id);

  const response = await window.axios.get('/v1/powercontrols/usage/latest', {
    params: { id: ids },
  });

  if (response && response.data && response.data.data) {
    let usages = response.data.data;

    const controls = powerControls.map((control) => {
      const usage = usages.find((u) => u.controlId === control.id);

      if (usage) {
        control.on = usage.on;
        control.setBy = usage.setBy;
        control.usageDate = usage.usageDate;
        control.powerControlId = usage.controlId;
        control = mapCommonProps(control, usage);
        control.lastUpdate = usage.timeSinceLastUpdate;
      }

      return control;
    });

    return controls;
  }
};

export const getAdminAccount = async (siteId) => {
  try {    
    const salt = encryptionService.generateSalt()    
    const result = await window.axios.get(`/v1/controlpermissions/site/${siteId}/admin-login?salt=${salt}`);
    const { data } = result.data;
    const decryptedPassword = await encryptionService.decrypt(data.password, salt);
    const endpoint = `https://${data.account}.officeclimatecontrol.net/login.cgi`;
    return {
      url: endpoint,
      account: data.account,
      user: data.userName,
      pass: decryptedPassword,
    };

  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSchedulerAccount = async (siteId) => {
  try {    
    const salt = encryptionService.generateSalt()    
    const result = await window.axios.get(`/v1/controlpermissions/site/${siteId}/scheduler-login?salt=${salt}`);
    const { data } = result.data;
    const decryptedPassword = await encryptionService.decrypt(data.password, salt);
    const endpoint = `https://${data.account}.officeclimatecontrol.net/login.cgi#_scheduleCalendarPage`;
    return {
      url: endpoint,
      account: data.account,
      user: data.userName,
      pass: decryptedPassword,
    };

  } catch (err) {
    return Promise.reject(err);
  }
};

export const getThermostatSystemModes = async () => {
  return ['Heat', 'Cool', 'Auto', 'Off'];
};

export const getThermostatFanModes = async () => {
  return ['On', 'Auto'];
};

const mapCommonProps = (control, usage) => {
  const usageDate = usage.usageDateTime;
  let setBy;

  if (usage.setBy) {
    switch (usage.setBy) {
      case 'Schedule':
        setBy = 'Following Schedule';
        break;
      case 'Station':
        setBy = 'Local Override';
        break;
      case 'Remote':
        setBy = 'Software Override';
        break;
      default:
        setBy = usage.setBy;
    }
  }

  return { ...control, usageDate, setBy };
};

export default {
  getControlById,
  updateControl,
  deleteControl,
};