<template>
  <Form>
    <Field :name="$attrs.label" :rules="$attrs.rules">
      <template v-slot="{ errors }">
        <v-text-field
          v-model="innerValue"
          :error-messages="errors"
          ref="text"
          outlined="outlined"
          :label="$attrs.label"
          :disabled="$attrs.disabled"
        ></v-text-field>
      </template>
    </Field>
  </Form>
</template>

<script>
import { Form, Field, defineRule } from 'vee-validate';

defineRule('required', (value) => {
  if (!value) {
    return 'This field is required';
  }
  return true;
});

defineRule('email', (value) => {
  if (!value) {
    return 'This field is required';
  }
  if (!value.includes('@')) {
    return 'This field must be a valid email';
  }
  return true;
});

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },

  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      innerValue: '',
    };
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },

  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },

    value(newVal) {
      this.innerValue = newVal;
    },
  },
};
</script>
