<template>
  <div>
    <BannerAlertVue
      v-if="!selectedSiteAnalyticsProjects.length && !isLoading"
      :message="bannerMessage"
    />
    <ProjectsListVue v-else :ProjectsList="selectedSiteAnalyticsProjects" />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProjectsListVue from '@/Core.Service.Domain/Analytics/Projects/Components/ProjectsList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

export default {
  name: 'ProjectsPortfolio',

  components: { ProjectsListVue, ProgressLinearLoaderVue, BannerAlertVue },

  computed: {
    ...mapGetters({
      selectedSiteAnalyticsProjects:
        'AnalyticsStore/AnalyticsProjectsStore/selectedSiteAnalyticsProjects',
      analyticsProjectsCurrentRoute:
        'AnalyticsStore/AnalyticsProjectsStore/analyticsProjectsCurrentRoute',
      isLoading:
        'AnalyticsStore/AnalyticsProjectsStore/selectedSiteAnalyticsProjectsIsLoading',
      defaultSite: 'session/defaultSite',
    }),

    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },
  },
};
</script>
