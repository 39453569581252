<template>
  <v-dialog
    activator="parent"
    v-model="dialogValue"
    width="50%"
    transition="slide-x-reverse-transition"
    content-class="map-dialog"
  >
    <v-card class="rounded-2 map-dialog__card">
      <v-container>
        <v-row>
          <v-col class="d-flex mb-n10">
            <v-toolbar-title class="text-primary font-weight-bold text-h5">
              {{ siteDetails.name }}
            </v-toolbar-title>
            <v-btn
              class="mt-n4"
              variant="plain"
              size="large"
              @click="dialogValue = false"
              :icon="true"
              color="primary"
            >
              <v-icon color="primary"> mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <span class="text-primary text-subtitle-2">
              {{ siteDetails.address }}
            </span>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <MapVue :siteDetails="siteDetails" class="mb-2" />
        <AccordionVue :siteDetails="siteDetails" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AccordionVue from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/Accordion';
import MapVue from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/Map';

export default {
  name: 'MapDialog',

  props: {
    siteDetails: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    markersList: { type: Array },
    mapPanCenter: { type: Object },
  },

  components: {
    AccordionVue,
    MapVue,
  },

  computed: {
    dialogValue: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
};
</script>
